import { SearchMessages } from "aderant-conflicts-models";
import { ProgressBar, ProgressBarProps } from "@aderant/aderant-react-components";
import React from "react";

export const ResolutionStatusProgressBar = (props: ProgressBarProps): JSX.Element => {
    return <ProgressBar {...props} />;
};

ResolutionStatusProgressBar.defaultProps = {
    completeText: SearchMessages.RESOLVED_PROGRESS_INDICATOR.getMessage()
};
