import { CustomField } from "aderant-conflicts-models";
import React from "react";
import { styles } from "./commonStyles";
interface CustomInformationProps {
    field: CustomField;
}
export const CustomInformation = (props: CustomInformationProps): JSX.Element => {
    const {
        field: { fieldLabel, value }
    } = props;
    return (
        <>
            <span style={{ ...styles.labelStyle, display: fieldLabel ? "block" : "none" }}>{fieldLabel}:</span>
            <div data-testid={`${fieldLabel}`} style={{ ...styles.valueStyle, gridColumn: fieldLabel ? "auto" : "span 2" }}>
                {value}
            </div>
        </>
    );
};
