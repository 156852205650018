import { BasicCurrentUserContext, LogContext, PermissionsContext } from "../ConflictsContextModel";
import { LoggedInUser, UserRole, aderantTenants } from "../User";
import { Direct, isNonEmpty, NonEmptyArray } from "../UtilityTypes";
import { ConflictsAction, UserPermissionsForRole } from "./UserPermission";

export type PermissionsConnectorService = {
    /**
     * Fetches whether the given action is allowed for the given user.
     * Implementations of this should cache results internally if relevant to performance (i.e. if making calls to an external service) - callers should not implement their own caching.
     * @param user Pick<LoggedInUser, "id" | "role">
     * @param action ConflictsAction
     * @returns true if action is allowed, false if it is not.
     */
    userHasPermission(user: Pick<LoggedInUser, "id" | "role">, action: ConflictsAction): Promise<boolean>; //this type just has the specific user function so implementors don't need to create more than necessary
    /**
     * Fetches whether all of the given actions is allowed for the given user.
     * Implementations of this should cache results internally if relevant to performance (i.e. if making calls to an external service) - callers should not implement their own caching.
     * @param user Pick<LoggedInUser, "id" | "role">
     * @param actions ConflictsAction[]
     * @returns true if all actions are allowed, false if any/all are not allowed.
     */
    userHasAllPermissions(user: Pick<LoggedInUser, "id" | "role">, actions: NonEmptyArray<ConflictsAction>): Promise<boolean>; //this type just has the specific user function so implementors don't need to create more than necessary
    /**
     * Fetches whether any of the given actions is allowed for the given user.
     * Implementations of this should cache results internally if relevant to performance (i.e. if making calls to an external service) - callers should not implement their own caching.
     * @param user Pick<LoggedInUser, "id" | "role">
     * @param actions ConflictsAction[]
     * @returns either an (always non empty) array of allowed actions, or false if there are none.
     */
    userHasAnyPermission(user: Pick<LoggedInUser, "id" | "role">, actions: NonEmptyArray<ConflictsAction>): Promise<NonEmptyArray<ConflictsAction> | false>; //this type just has the specific user function so implementors don't need to create more than necessary
    /**
     * Fetches whether the given user belongs to an Aderant tenant.
     * @param user Pick<LoggedInUser, "id" | "tenancy">
     * @returns true is user is a part of an Aderant tenant, false if not
     */
    userIsAderantUser(user: Pick<LoggedInUser, "id" | "tenancy">): Promise<boolean>;
};

export type PermissionsConnectorDirect = Direct<PermissionsConnectorService>;

export type PermissionsConnector = PermissionsConnectorService | PermissionsConnectorDirect;

/**
 * Dear post MSP Conflicts dev:
 * We will /eventually/ need this to call off to some external service or at least storage API because the state currently in UserPermissionsForRole will need to be customizable by end users.
 *
 * At that point this would need to move out of models to common (because models needs to be at the tippy top of the dependency stack, and currently has /no/ (non dev) dependencies, which is quite nice).
 *
 * This would also mean all the tests in models that test this current hardcoded set of permissions would need to be rethought a bit.
 * - Scott
 */
export class HardcodedPermissionsConnector implements PermissionsConnectorDirect {
    private canStandardUserPerformQuickSearch?: boolean;
    constructor(canPerformQuickSearch?: boolean) {
        this.canStandardUserPerformQuickSearch = canPerformQuickSearch;
    }

    userIsAderantUser(user: Pick<LoggedInUser, "id" | "tenancy">): boolean {
        return aderantTenants.some((tenant) => tenant === user.tenancy.uniqueName);
    }

    userHasAllPermissions(user: Pick<LoggedInUser, "id" | "role">, actions: ConflictsAction[]): boolean {
        return actions.every((action) => this.roleHasPermissionTo(user.role, action));
    }
    userHasAnyPermission(user: Pick<LoggedInUser, "id" | "role">, actions: ConflictsAction[]): NonEmptyArray<ConflictsAction> | false {
        const allowedActions = actions.filter((action) => this.roleHasPermissionTo(user.role, action));
        if (isNonEmpty(allowedActions)) {
            return allowedActions;
        } else {
            return false;
        }
    }
    userHasPermission(user: Pick<LoggedInUser, "id" | "role">, action: ConflictsAction): boolean {
        return this.roleHasPermissionTo(user.role, action);
    }
    /**
     * Is a user with the given role allowed to perform a given action.
     * NOTE: This only indicates whether a user is allowed to perform the action at all, it does NOT
     * check whether the action is valid based on the state of the data.  Use other validation as well.
     */
    private roleHasPermissionTo(userRole: UserRole, action: ConflictsAction) {
        if (this.hasRole(userRole, UserRole.Conflicts) && UserPermissionsForRole["Conflicts"].some((a) => a === action)) {
            return true;
        }
        if (this.hasRole(userRole, UserRole.Standard)) {
            if (action === ConflictsAction.ViewAndPerformQuickSearches) {
                return this.canStandardUserPerformQuickSearch ?? false;
            } else if (UserPermissionsForRole["Standard"].some((a) => a === action)) {
                return true;
            }
        }
        if (this.hasRole(userRole, UserRole.Admin) && UserPermissionsForRole["Admin"].some((a) => a === action)) {
            return true;
        }
        if (this.hasRole(userRole, UserRole.ApiAdmin) && UserPermissionsForRole["ApiAdmin"].some((a) => a === action)) {
            return true;
        }
        if (this.hasRole(userRole, UserRole.SecurityPlanExempt) && UserPermissionsForRole["SecurityPlanExempt"].some((a) => a === action)) {
            return true;
        }

        return false;
    }

    private hasRole(userRoles: UserRole, role: UserRole): boolean {
        return (userRoles & role) === role;
    }
}

/**Permissions connector to use in cases where all changes being made are done by our business logic on behalf of a user.
 * (e.g. setting a search to Searched after searching).
 *
 * IMPORTANT: Probably don't use this unless you know you need it, and there's no user input in the changes you're saving - cases where a user might not be allowed to do something, but we do want to automatically do it should be pretty rare.
 *
 * This is currently used as part of Search perform queue triggered functions as we do not have access to User roles and we are only making automatic changes.
 */
export class AlwaysYesPermissionsConnector implements PermissionsConnectorDirect {
    userIsAderantUser(): boolean {
        return true;
    }
    userHasPermission(): boolean {
        return true;
    }
    userHasAllPermissions(): boolean {
        return true;
    }
    userHasAnyPermission(_: Pick<LoggedInUser, "id" | "role">, actions: NonEmptyArray<ConflictsAction>): false | NonEmptyArray<ConflictsAction> {
        return actions;
    }
}
/**Permissions context to use in cases where all changes being made are done by our business logic on behalf of a user.
 * (e.g. setting a search to Searched after searching).
 *
 * IMPORTANT: Probably don't use this unless you know you need it, and there's no user input in the changes you're saving - cases where a user might not be allowed to do something, but we do want to automatically do it should be pretty rare.
 *
 * This is currently used as part of Search perform queue triggered functions as we do not have access to User roles and we are only making automatic changes.
 */
export function alwaysYesPermissionsContext(context: BasicCurrentUserContext & LogContext): PermissionsContext {
    return {
        currentUserId: context.currentUser.id,
        logger: context.logger,
        appInsightsClient: context.appInsightsClient,
        currentUserHasPermission: () => true,
        currentUserHasAllPermissions: () => true,
        currentUserHasAnyPermission: (actions: NonEmptyArray<ConflictsAction>) => actions,
        currentUserIsAderantUser: () => true
    };
}
