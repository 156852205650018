import { SearchVersion, QuickSearch, SearchVersionNew } from "aderant-conflicts-models";

const WebAppAction = {
    ViewDetailsTab: "ViewDetailsTab",
    ViewTermsTab: "ViewTermsTab",
    ViewAuditsTab: "ViewAuditsTab",
    ImportTerms: "ImportTerms",
    PrintReport: "PrintReport",
    ViewHitStatus: "ViewHitStatus",
    ChangeSearchStatus: "ChangeSearchStatus",
    ChangeAssignedToUser: "ChangeAssignedToUser",
    ViewHitOwner: "ViewHitOwner"
} as const;

const FeatureBySearchType = {
    FullSearch: [
        WebAppAction.ViewDetailsTab,
        WebAppAction.ViewTermsTab,
        WebAppAction.ViewAuditsTab,
        WebAppAction.ViewHitStatus,
        WebAppAction.ImportTerms,
        WebAppAction.PrintReport,
        WebAppAction.ViewHitOwner
    ],
    QuickSearch: [WebAppAction.ViewTermsTab, WebAppAction.PrintReport]
} as const;

/**
 * This object contains functions that help determine whether certain actions and/or tabs are visible depending on the type of search.
 * Some functions also narrow the search type that is passed in, for example: If the search allows for importing terms, we know for sure
 * that the search is not a quick search. This is useful as it prevents us from having to expand the search type in some pages/components and search actions that
 * are only relevant to certain search types.
 */
export const FeatureBySearchTypeHelper = {
    showDetailsTab: (search: SearchVersion | SearchVersionNew | QuickSearch | undefined): search is SearchVersion | SearchVersionNew => {
        if (search?.isQuickSearch) {
            return false;
        }
        return FeatureBySearchType.FullSearch.includes(WebAppAction.ViewDetailsTab);
    },

    showTermsTab: (search: SearchVersion | SearchVersionNew | QuickSearch | undefined) => {
        if (search?.isQuickSearch) {
            return FeatureBySearchType.QuickSearch.includes(WebAppAction.ViewTermsTab);
        }
        return FeatureBySearchType.FullSearch.includes(WebAppAction.ViewTermsTab);
    },

    showAuditsTab: (search: SearchVersion | SearchVersionNew | QuickSearch | undefined): search is SearchVersion => {
        if (search?.isQuickSearch) {
            return false;
        }
        return FeatureBySearchType.FullSearch.includes(WebAppAction.ViewAuditsTab);
    },

    showHitStatus: (search: SearchVersion | SearchVersionNew | QuickSearch | undefined): search is SearchVersion => {
        if (search?.isQuickSearch) {
            return false;
        }
        return FeatureBySearchType.FullSearch.includes(WebAppAction.ViewHitStatus);
    },

    canImportTerms: (search: SearchVersion | SearchVersionNew | QuickSearch | undefined): search is SearchVersion => {
        if (search?.isQuickSearch) {
            return false;
        }
        return FeatureBySearchType.FullSearch.includes(WebAppAction.ImportTerms);
    },

    showHitOwner: (search: SearchVersion | SearchVersionNew | QuickSearch | undefined): search is SearchVersion => {
        if (search?.isQuickSearch) {
            return false;
        }
        return FeatureBySearchType.FullSearch.includes(WebAppAction.ViewHitOwner);
    }
};
