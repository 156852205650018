import { BlobClientOptions, BlobStore } from "@aderant/azure-storage";
import {
    ConnectionContext,
    Forbidden,
    NotFound,
    PermissionsContext,
    Result,
    forbidden,
    notFoundWithMessage,
    ok,
    parseStorageAccountConnectionString,
    unexpectedError,
    EditableFieldValue,
    LogContext,
    FirmSettings
} from "aderant-conflicts-models";
import Bluebird from "bluebird";
import { APIs, isUserlessKeyAuthFunctionAppContext, MultiResponse, UserlessKeyAuthFunctionAppContext } from "../..";

async function getFirmSettingsBlobStorageClient(context: ConnectionContext): Promise<BlobStore> {
    const connectionInfo = parseStorageAccountConnectionString((await context.getBlobStorageSecrets()).connectionString);
    const clientOptions: BlobClientOptions = {
        accountName: connectionInfo.accountName,
        containerName: "firm-settings",
        useIdentity: true
    };
    return new BlobStore(clientOptions);
}

async function getBlobStore(context: ConnectionContext | UserlessKeyAuthFunctionAppContext, blobStoreInput?: Pick<BlobStore, "getBlobContent">): Promise<Pick<BlobStore, "getBlobContent">> {
    if (blobStoreInput) {
        return blobStoreInput;
    }
    if (isUserlessKeyAuthFunctionAppContext(context)) {
        throw unexpectedError("You must provide a BlobStore if your context is a UserlessKeyAuthFunctionAppContext.", "FirmSettingsConnector");
    }
    //It has to be a ConnectionContext as it's not a UserlessKeyAuthFunctionAppContext and those are the only 2 types allowed
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return await getFirmSettingsBlobStorageClient(context as ConnectionContext);
}

export async function getPageDataWithDefaults(
    context: ConnectionContext | UserlessKeyAuthFunctionAppContext,
    pageName: string,
    blobStoreInput?: Pick<BlobStore, "getBlobContent">
): Promise<Result<Record<string, Record<string, any>>, NotFound>> {
    context.logger.info("Fetching firm settings for page %s", pageName);
    const blobStore = await getBlobStore(context, blobStoreInput);

    const pageDefinition = FirmSettings.validateDefinitionExists(pageName);
    if (!ok(pageDefinition)) {
        return pageDefinition;
    }

    let pageBlob: Record<string, any>;
    let getResponse: any;
    try {
        context.logger.info("Querying blob with name %s.json", pageName);
        getResponse = await blobStore.getBlobContent(`${pageName}.json`);
        pageBlob = JSON.parse(getResponse.content);
    } catch (e: any) {
        if (e.statusCode === 404) {
            context.logger.info("No page data found for page %s, returning all defaults", pageName);
            pageBlob = {};
        } else {
            throw e;
        }
    }

    context.logger.info("pageblob:", JSON.stringify(pageBlob));
    pageDefinition.sections.forEach((sectionDefinition) => {
        if (!(sectionDefinition.name in pageBlob)) {
            pageBlob[sectionDefinition.name] = {};
        }
        pageBlob[sectionDefinition.name] = addDefaultsForMissingOptions(context, pageName, sectionDefinition.name, pageBlob[sectionDefinition.name]);
    });

    return pageBlob;
}

/**
 * Fetches field data for fieldPaths provided.
 * ONLY WORKS FOR BASIC SECTIONS.
 * Returns an array of data corresponding to the fieldPaths array provided.
 */
export async function getSettingsByFieldPaths<Paths extends FirmSettings.FieldPath[]>(
    context: ConnectionContext | UserlessKeyAuthFunctionAppContext,
    fieldPaths: Paths,
    blobStoreInput?: Pick<BlobStore, "getBlobContent">
): Promise<
    MultiResponse.Response<
        {
            value: EditableFieldValue;
        },
        200,
        NotFound
    >
> {
    const blobStore = await getBlobStore(context, blobStoreInput);

    const toProcess: MultiResponse.SuccessfulItem<string, 200>[] = fieldPaths.map((path) => {
        return {
            status: 200,
            id: path,
            body: path
        };
    });

    const splitPaths: MultiResponse.Item<{ pageName: string; sectionName: string; fieldName: string }, 200, NotFound>[] = MultiResponse.applyToEachSuccess(
        toProcess,
        (path): Result<{ pageName: string; sectionName: string; fieldName: string }, NotFound> => {
            const split = path.split("/");
            if (split.length === 3) {
                return { pageName: split[0], sectionName: split[1], fieldName: split[2] };
            } else {
                return notFoundWithMessage("No field at this path: field paths must have three sections separated by '/'");
            }
        }
    );

    const allPageNames = new Set(splitPaths.filter(MultiResponse.isSuccessItem).map((p) => p.body.pageName));

    const pageBlobs = Object.fromEntries(
        await Bluebird.Promise.map(allPageNames, async (pageName) => {
            return [pageName, await getPageDataWithDefaults(context, pageName, blobStore)];
        })
    );

    const fetchedValues: MultiResponse.Item<{ value: EditableFieldValue }, 200, NotFound>[] = MultiResponse.applyToEachSuccess(splitPaths, (path): Result<{ value: EditableFieldValue }, NotFound> => {
        const pageDefinition = FirmSettings.validateDefinitionExists(path.pageName, path.sectionName, path.fieldName);
        if (!ok(pageDefinition)) {
            return pageDefinition;
        } else {
            const pageBlob = pageBlobs[path.pageName];
            if (!pageBlob) {
                throw unexpectedError(
                    "pageBlob should have either been populated with saved data, defaults or be a NotFound error. Was undefined instead. This is probably a bug.",
                    "FirmSettingsConnector.getSettingsByFieldPaths"
                );
            }
            if (!ok(pageBlob)) {
                return pageBlob;
            }
            return { value: pageBlob[path.sectionName][path.fieldName] };
        }
    });

    return MultiResponse.response(fetchedValues);
}

/**
 * ONLY WORKS FOR BASIC SECTIONS.
 * Saves pageData to a blob in the firm's blob storage with name pageName. Overwrites current blob if it already exists.
 * Validates user has permission to update firm options.
 */
export async function savePageData(
    context: ConnectionContext & PermissionsContext,
    pageName: string,
    pageData: Record<string, Record<string, any>>,
    blobStoreInput?: Pick<BlobStore, "uploadBlob">
): Promise<Result<Record<string, Record<string, any>> & { etag: string }, Forbidden | NotFound>> {
    const currentPageDefinition = FirmSettings.validateDefinitionExists(pageName);
    if (!ok(currentPageDefinition)) {
        return currentPageDefinition;
    }

    if (!(await context.currentUserHasPermission(currentPageDefinition.requiredPermission))) {
        return forbidden(APIs.PermissionValidationMessages.PERMISSION_ACTION_FORBIDDEN.getMessage("The current user", `edit the page ${pageName}`));
    }

    if (currentPageDefinition.internalUsersOnly && !(await context.currentUserIsAderantUser())) {
        return forbidden(APIs.PermissionValidationMessages.PERMISSION_ACTION_FORBIDDEN.getMessage("External user", `edit the page ${pageName}`));
    }

    const blobStore = blobStoreInput ?? (await getFirmSettingsBlobStorageClient(context));

    //We only want to persist values that are part of the page definition, anything else should be ignored.
    const sanitizedPageData: Record<string, Record<string, any>> = {};
    currentPageDefinition.sections.forEach((sectionDefinition) => {
        if (sectionDefinition.type === "basic" && sectionDefinition.name in pageData) {
            sanitizedPageData[sectionDefinition.name] = {};
            sectionDefinition.fields.forEach((fieldDefinition) => {
                if (fieldDefinition.name in pageData[sectionDefinition.name]) {
                    sanitizedPageData[sectionDefinition.name][fieldDefinition.name] = pageData[sectionDefinition.name][fieldDefinition.name];
                }
            });
        }
    });

    let uploadResponse: any;
    try {
        uploadResponse = await blobStore.uploadBlob(`${pageName}.json`, JSON.stringify(sanitizedPageData), "application/json", true);
    } catch (e: any) {
        throw unexpectedError(e, "savePageData in FirmSettingsConnector");
    }

    return { ...pageData, etag: uploadResponse.etag };
}

/**
 * Adds default values for a section's settings data that are not currently stored in the page's data blob.
 */
function addDefaultsForMissingOptions(context: LogContext, pageName: string, sectionName: string, sectionData: Record<string, any>) {
    const sectionDataWithDefaults = { ...sectionData };
    const defaultSectionData = getDefaultSectionData(pageName, sectionName);
    if (defaultSectionData) {
        for (const [key, field] of Object.entries(defaultSectionData)) {
            if (!(key in sectionData)) {
                context.logger.info(`Defaulting ${key} in ${pageName}/${sectionName} to ${field.defaultValue}`);
                sectionDataWithDefaults[key] = field.defaultValue;
            } else if (typeof sectionData[key] !== field.type) {
                //Something has gone wrong with the stored data or we have changed the type of the field on the page definition
                context.logger.error(
                    `Firm setting ${pageName}/${sectionName}/${key} in storage is ${sectionData[key]} and of type ${typeof sectionData[key]} but expected type is ${field.type} and default value is ${
                        field.defaultValue
                    }`
                );
                sectionDataWithDefaults[key] = field.defaultValue;
            }
        }
    }
    return sectionDataWithDefaults;
}

/**
 * Extracts default data for each field in a section.
 * If the section is "basic", returns an object keyed by field name and value being the default value for that field.
 * If the section is "handwritten", returns an object keyed by section name and value being the default value for that section.
 * Returns undefined if section has no default data.
 */
function getDefaultSectionData(pageName: string, sectionName: string): Record<string, { defaultValue: any; type: string }> | undefined {
    const defaultSectionData: Record<string, { defaultValue: any; type: string }> = {};
    const currentPageDefinition = FirmSettings.validateDefinitionExists(pageName, sectionName);
    if (ok(currentPageDefinition)) {
        const currentSectionDefinition = FirmSettings.getSectionDefinition(currentPageDefinition, sectionName);
        if (ok(currentSectionDefinition)) {
            if (currentSectionDefinition.type === "basic") {
                //Get section default data if basic section
                currentSectionDefinition.fields.forEach((field) => {
                    defaultSectionData[field.name] = { defaultValue: field.defaultValue, type: field.type };
                });
            } else {
                //Get section default data if handwritten section
                defaultSectionData[currentSectionDefinition.name] = { defaultValue: currentSectionDefinition.defaultValue, type: typeof currentSectionDefinition.defaultValue };
            }
        }
    }
    if (Object.keys(defaultSectionData).length === 0) {
        //return undefined as section has no default data
        return undefined;
    }
    return defaultSectionData;
}
