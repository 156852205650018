//======================================================================================================================
//NO EXTERNAL REASON TO CHANGE
//======================================================================================================================
//======================================================================================================================
//UNLIKELY THAT EXTERNAL DEPENDENCY WILL FORCE A CHANGE
//(consider making these environment variables injected on deploy if this assumption turns out to be incorrect)
//======================================================================================================================

//This is the name that Identity uses for this product. Will be prefixed in front of environment names in auth tokens.
//This is *unlikely* to ever change so is *fairly* safe to hardcode.
export const IDENTITY_CONFLICTS_PRODUCT_NAME = "Conflicts";

//This is the name that IdentityDev uses for this product. Will be prefixed in front of environment names in auth tokens.
//This is *unlikely* to ever change so is *fairly* safe to hardcode.
export const IDENTITYDEV_CONFLICTS_PRODUCT_NAME = "ConflictsDev";

//This is the name we give the implicit 'main' environment for the single tenancy name that is always present in the
//groups claim. Identity refers to this as 'Production' in the UI and in the productenvironments claim in tokens
//This is *unlikely* to ever change so is *fairly* safe to hardcode.
export const IDENTITY_MAIN_ENVIRONMENT_NAME = `${IDENTITY_CONFLICTS_PRODUCT_NAME}.Production` as const;

//This is the name we give the implicit 'main' environment for the single tenancy name that is always present in the
//groups claim. Identity refers to this as 'Production' in the UI and in the productenvironments claim in tokens
//This is *unlikely* to ever change so is *fairly* safe to hardcode.
export const IDENTITYDEV_MAIN_ENVIRONMENT_NAME = `${IDENTITYDEV_CONFLICTS_PRODUCT_NAME}.Production` as const;
