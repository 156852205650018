import { UserField } from "aderant-conflicts-models";
import React from "react";
import { styles } from "./commonStyles";
interface UserInformationProps {
    field: UserField;
}
//ToDo: This might just be a network call to fetch the avatar via an http request throw it in the useEffect if needed.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getAvatar = (id: number | string): null => {
    return null; //Not yet implemented
};
export const UserInformation = (props: UserInformationProps): JSX.Element => {
    const {
        field: {
            fieldLabel,
            displayFormat,
            value: { userId, userName }
        }
    } = props;
    //Do we display user avatar
    const hasAvatar = displayFormat === "UserWithAvatar";

    const avatarStyle: React.CSSProperties = {
        width: 10,
        height: 10
    };
    return (
        <>
            <span style={styles.labelStyle}>{fieldLabel}:</span>
            <div style={{ display: "flex" }}>
                {hasAvatar && (
                    <div style={avatarStyle}>
                        <span>{getAvatar(userId)}</span>
                    </div>
                )}
                <span data-testid={`${fieldLabel}`} style={styles.valueStyle}>
                    {userName}
                </span>
            </div>
        </>
    );
};
