import { RequestTerm } from "aderant-conflicts-models";
import uuid from "uuid";

// These messages are tech debt, add new ones to Messages.ts so they can be translated in the future
export const messages = {
    required: "Required",
    searchTermAlreadyExists: "The entered search term already exists",
    requestTermAlreadyExists: "The entered request term already exists",
    affiliation: "Affiliation",
    invalidRequestTerm: "Invalid request term",
    partyStatus: "Party Status",
    term: "Term",
    delete: "Delete",
    emptyDataSourceMessage: "No search terms entered",
    discardRequestTerm: "Are you sure you would like to remove this request term and its search terms from this search request?",
    removeRequestTerm: "Remove Request Term?",
    discardQuickSearchTerm: "Are you sure you want to remove this term?",
    removeQuickSearchTerm: "Remove Term?",
    searchTerms: "Search Terms",
    enterHelpText: "Press Enter to add a search term",
    ctrlEnterAddRequestTerm: "Press Ctrl + Enter to add a request term",
    ctrlEnterAddTerm: "Press Ctrl + Enter to add a term",
    partySearchProfile: "Party Search Profile",
    cancel: "Cancel",
    save: "Save",
    search: "Search",
    requestTerm: "Request Term",
    requestTerms: "Request Terms",
    terms: "Terms",
    addARequestTerm: "Add a request term",
    addASearchTerm: "Add a search term",
    addATerm: "Add a term",
    enterSearchName: "Enter search name",
    enterRequestedByUser: "Enter requested by user",
    name: "Name",
    searchRequestNumber: "Search Request #",
    requestedBy: "Requested By",
    description: "Description",
    fuzzySearch: "Apply fuzzy search to each search term",
    reassignUser: "Reassign User",
    areYouSureYouWantToReassign: "Are you sure you want to reassign this search request?",
    reassignWarnThatUserCannotEditAfter: "Once the search request is reassigned you will no longer be able to edit it.",
    enterMessageToBeEmailedOnReassign: "Enter a message to be emailed to the assigned user",
    enterMessageToBeSavedToAudit: "Enter a message to be saved to the audit",
    enterMessageToBeEmailedOnStatusChange: "Enter a message to be emailed to the requesting user",
    messageTooLong: "Message must be less than 2,000 characters",
    yes: "Yes",
    message: "Message",
    reassignMessage: "Reassign Message",
    statusNotificationMessage: "Status Message",
    importTerms: "Import Terms",
    uploadFile: "Upload File",
    downloadTemplate: "Download template",
    addImportTerms: "+ Import Terms",
    uploadedFiles: "Uploaded File",
    uploadSuccessful: "Upload Successful",
    uploadFailed: "Upload Failed",
    warning: "Warning",
    searchTermsTemplate: "SearchTermsTemplate",
    requestTermsTemplate: "RequestTermsTemplate",
    excelErrorNoRequestTermSheet: "Your file does not contain a sheet named Request Terms.\nPlease upload a file containing a sheet named Request Terms.",
    excelErrorNoSearchTermSheet: "Your file does not contain a sheet named Search Terms.\nPlease upload a file containing a sheet named Search Terms.",
    excelErrorNoRequestTerms: "Your file does not contain any request terms.\nPlease upload a file with valid request terms.",
    excelErrorNoSearchTerms: "Your file does not contain any search terms.\nPlease upload a file with valid search terms.",
    excelErrorUploadFailure: "Your file could not be uploaded.\nPlease upload the file again.",
    excelErrorSearchTermsInWrongColumn: "Your file contains more than one column of data.\nPlease upload a file with the search terms in one column only.",
    excelErrorRequestTermsMissing: "A request term is missing from the following rows:\n",
    excelErrorTooManySearchTerms: "Your file contains more search terms than the 10,000 limit.\nPlease upload a file with less than 10,000 search terms",
    requestTermNotAllowed: "The entered request term is not allowed",
    termNotAllowed: "The entered term is not allowed",
    searchTermNotAllowed: "The entered search term is not allowed",
    seeAll: "See all"
};

export const configureTermToAdd = (term: RequestTerm): RequestTerm => {
    // The only item in the searchTerms collection should be the search term matching the request term if the add search terms button wasn't clicked
    if (term.searchTerms.length == 0 || !term.searchTerms) {
        term.searchTerms = [term.term?.trim() || ""];
    }
    return {
        id: uuid(),
        term: term.term?.trim(),
        searchTerms: term.searchTerms.map((t) => t.trim()),
        affiliation: term.affiliation ? term.affiliation : undefined,
        partyStatus: term.partyStatus ? term.partyStatus : undefined
    };
};

export const configureTermToUpdate = (term: RequestTerm): RequestTerm => {
    return {
        id: term.id,
        term: term.term?.trim(),
        searchTerms: term.searchTerms.map((t) => t.trim()),
        affiliation: term.affiliation ? term.affiliation : undefined,
        partyStatus: term.partyStatus ? term.partyStatus : undefined
    };
};
