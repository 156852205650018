import { Field } from "./Fields/DisplayFields/Fields";
import { RequestTerm } from "./SearchVersion";

/**
 * Information about a particular entity e.g. client/matter.
 * The entity can have a parent, and the parent can have a parent etc.
 */
export interface Entity {
    /**
     * entity id: should be the same for requested and fetched entity
     */
    id: string;
    /**
     * The name of the entity e.g. Client, Matter.
     */
    name: string;
    /**
     * The parent of the entity e.g. for Matter this would be Client.
     */
    parent: Entity | undefined;
    /**
     * Fields that represent the entity information.
     */
    fields: Field[];
    /**
     * The secureEntityId for the row. All entities have one of these for RLS lookup regardless of whether they are secured or not.
     * Entities that were ingested before RLS support was added will not have a value in this field, they should be treated as if unsecured.
     *
     * This is a compound id in the form of <entityType>:<entityId>;<parentEntityType>:<parentEntityId> e.g. 'matter:4;client:1'.
     * If any of the ids in the compound id are secured, the entity is secured and the user must have access to at least one of them.
     */
    secureEntityId?: string;
    /**
     * Prior search request terms.
     */
    searchRequestTerms?: RequestTerm[];
}

export interface EntityIdentifier {
    entityType: string;
    id: string;
}

/**
 * To find an entity based on a foreign key instead of it's own id.
 */
export interface ForeignEntityIdentifier extends EntityIdentifier {
    idFieldName: string;
}

export function isForeignEntityIdentifier(identifier: EntityIdentifier | ForeignEntityIdentifier): identifier is ForeignEntityIdentifier {
    // justification: need to cast to check field for type guard
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return !!(identifier as ForeignEntityIdentifier).idFieldName;
}
