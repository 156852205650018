import {
    DiagnosticInformation,
    Endpoint,
    FirmEntity,
    Forbidden,
    FunctionAppStatus,
    IntegrationStatus,
    NotFound,
    TenancyWithoutId,
    ValidationErrors,
    endpointDefinitions
} from "aderant-conflicts-models";
import { OkResponse } from "../../..";
import { AzureKeyAuthFunctionDefinition } from "../../AzureFunctionDefinition";
import { UserlessKeyAuthFunctionAppContext } from "../../ConflictsContext";
import { isDevelopmentEnvironment } from "../../Config/EnvironmentService";
import { getMonitorApiDetailsAsJSON } from "../SupportPortalService/Shared/KeyvaultConnector/KeyvaultConnector";

//IMPORTANT:
//Whenever changes are made to this file, ensure that it does not break the service in Support Portal

export const MonitoringApi = {
    getHealth: new AzureKeyAuthFunctionDefinition<
        undefined, //Input
        DiagnosticInformation, //Output
        OkResponse<DiagnosticInformation>, //Output with Http Status type
        never //possible error types
    >({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: () => "./api/Health"
    }),
    keyAuthFunctions: {
        getIngestionStatus: new AzureKeyAuthFunctionDefinition<
            {
                tenancy: TenancyWithoutId;
            },
            IntegrationStatus[],
            OkResponse<IntegrationStatus[]>,
            NotFound | Forbidden | ValidationErrors
        >({
            httpVerb: "POST",
            expectedErrors: ["ACCESS_DENIED"],
            getUrlEnd: () => `./api/KeyAuth/Monitoring/IngestionStatus`
        }),
        getFunctionAppStatuses: new AzureKeyAuthFunctionDefinition<FirmEntity, FunctionAppStatus[], OkResponse<FunctionAppStatus[]>, NotFound | Forbidden | ValidationErrors>({
            httpVerb: "POST",
            expectedErrors: ["ACCESS_DENIED"],
            getUrlEnd: () => `./api/KeyAuth/Monitoring/FunctionAppStatus`
        })
    }
};

export type MonitoringApiProxy = ReturnType<typeof getMonitoringApiProxy>;

function getDevelopmentUrl(name: Endpoint): string {
    const definition = endpointDefinitions.definitions[name];
    const urlTemplate = endpointDefinitions.templates.developmentUrlFormat;

    return urlTemplate.replace("{devPortNumber}", definition.devPortNumber.toString());
}

//justification: return type is necessarily defined by the function itself - to explicitly define it in the function signature
//would be unnecessarily verbose (just like this comment)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getMonitoringApiProxy(context: UserlessKeyAuthFunctionAppContext, regionResourceGroupName: string) {
    let baseURL: URL;
    let hostKey: string;
    if (isDevelopmentEnvironment()) {
        baseURL = new URL(getDevelopmentUrl("MonitoringApi"));
        hostKey = "";
    } else {
        const monitorApiDetails = await getMonitorApiDetailsAsJSON(regionResourceGroupName, context.logger);
        baseURL = new URL(monitorApiDetails.baseUrl);
        hostKey = monitorApiDetails.functionKey;
    }

    return {
        getIngestionStatus: MonitoringApi.keyAuthFunctions.getIngestionStatus.getKeyAuthProxyForMonitoring(baseURL, context.logger, hostKey),
        getFunctionAppStatuses: MonitoringApi.keyAuthFunctions.getFunctionAppStatuses.getKeyAuthProxyForMonitoring(baseURL, context.logger, hostKey)
    };
}
