import { unexpectedError } from "./Validation/Errors";

export type ConflictsSecretType = "entityStoreCosmos" | "searchStoreCosmos" | "cognitiveSearch" | "blobStorage" | "dataFactory" | "rlsStorage";

export type CosmosSecrets = {
    endpoint: string;
    key: string;
    databaseId: "conflicts";
};

export type CognitiveSearchSecrets = {
    name: string;
    adminKey: string;
    queryKey: string; //You shouldn't need both admin and query keys but not sure how to split it
};

export type BlobStorageSecrets = {
    connectionString: string;
};

export type RLSStorageConnectionInfo = {
    accountName: string;
};

export type DataFactorySecrets = {
    subscriptionId: string;
    resourceGroupName: string;
    factoryName: string;
};

export type ConflictsSecret = CosmosSecrets | CognitiveSearchSecrets | BlobStorageSecrets | DataFactorySecrets;

export function parseStorageAccountConnectionString(connectionString: string): { accountName: string } {
    const parts = connectionString //connection string is in the form 'key1=value1;key2=value2;key3=value3'
        .split(";") //split into individual key/value pairs
        .map((s) => s.split("=")) //split each key/value pair into a two item array
        .filter((s) => s.length === 2 /**ignore anything that wasn't formatted 'key=value' */);

    const valuesByKey = parts.reduce((dict: Record<string, string>, entry: string[]) => {
        dict[entry[0].toLowerCase()] = entry[1];
        return dict;
    }, {});

    const accountName = valuesByKey["accountname"];

    if (!accountName) {
        //it's safe to just blow up here - if we have a connection string with no account name something is seriously broken.
        throw unexpectedError("accountName was missing in storage account connection string", "parseStorageAccountConnectionString");
    }

    //avoid returning the actual key here - if we're doing key based auth we should be using the connection string directly.
    //this is just a stopgap to grab the accountName from our existing connection strings to use for managed auth instead.
    return { accountName: accountName };
}
