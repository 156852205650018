import { APIs, GlobalUserService, TokenAuthContext } from "aderant-conflicts-common";
import { User } from "aderant-conflicts-models";

export class UserService {
    globalUserService: GlobalUserService;
    constructor(private context: TokenAuthContext) {
        this.globalUserService = new GlobalUserService(context, APIs.getUserManagementProxy(context, context.logger));
    }

    async getUsers(): Promise<User[]> {
        this.context.logger.info(`UserService: Fetching users for tenancy id ${this.context.currentUser.tenancy.id}`);

        const users = await this.globalUserService.getUsers({ tenancyId: this.context.currentUser.tenancy.id, uniqueName: this.context.currentUser.tenancy.uniqueName });

        if (users.length > 0) {
            this.context.logger.info(`UserService: ${users.length} users fetched for tenancy id ${this.context.currentUser.tenancy.id}`);
        } else {
            this.context.logger.info(`UserService: Didn't find any users when fetching user list for tenancy id ${this.context.currentUser.tenancy.id}`);
        }

        return users;
    }
}
