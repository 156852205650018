import { EntityRelationshipConfigurationTemplate } from "..";

export const ExpertEntityRelationshipConfigurationTemplates: EntityRelationshipConfigurationTemplate[] = [
    {
        entityName: "client"
    },
    {
        entityName: "clientalias",
        parent: { entityName: "client", foreignKey: "clientId" },
        searchOnly: true
    },
    {
        entityName: "clientcontact",
        parent: { entityName: "client", foreignKey: "clientId" },
        relatedEntity: { entityName: "mattercontact", foreignKey: "id", relatedEntityForeignKey: "clientContactId" },
        searchOnly: true
    },
    {
        entityName: "clientcontactalias",
        parent: { entityName: "client", foreignKey: "clientId" },
        relatedEntity: { entityName: "mattercontact", foreignKey: "contactId", relatedEntityForeignKey: "clientContactId" },
        searchOnly: true
    },
    {
        entityName: "clientparty",
        parent: { entityName: "client", foreignKey: "clientId" }
    },
    {
        entityName: "clientpartyalias",
        parent: { entityName: "clientparty", foreignKey: "clientPartyId" },
        searchOnly: true
    },
    {
        entityName: "employee"
    },
    {
        entityName: "employeealias",
        parent: { entityName: "employee", foreignKey: "employeeId" },
        searchOnly: true
    },
    {
        entityName: "matter"
    },
    {
        entityName: "mattercontact",
        parent: { entityName: "matter", foreignKey: "matterId" },
        searchOnly: true
    },
    {
        entityName: "matterparty",
        parent: { entityName: "matter", foreignKey: "matterId" }
    },
    {
        entityName: "matterpartyalias",
        parent: { entityName: "matterparty", foreignKey: "matterPartyId" },
        searchOnly: true
    },
    {
        entityName: "vendor"
    },
    {
        entityName: "vendorcontact",
        parent: { entityName: "vendor", foreignKey: "vendorId" },
        searchOnly: true
    },
    {
        entityName: "vendorcontactalias",
        parent: { entityName: "vendor", foreignKey: "vendorId" },
        searchOnly: true
    }
];

export const ExpertLookups: string[] = ["affiliationlookup", "partystatuslookup"];
