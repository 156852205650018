import { getNumberDisplayValue, getSearchStatusDisplayValue, SearchStatus, SearchSummary, User } from "aderant-conflicts-models";
import { getSearchProgress } from "Functions/search";
import _ from "lodash";
import { getUserName } from "state/selectors/appSelectors";
import { UserNameAndId } from "pages/Shared/userUtils";

export function getProgressPercentage(progress: number, total: number): number {
    return total !== -1 ? (total === 0 ? 100 : Math.trunc((progress / total) * 100)) : -1;
}

/**
 * An object representing properties to be displayed in the search requests grid
 */
export class SearchRequestRow {
    constructor(searchSummary: SearchSummary, users: User[]) {
        const {
            searchEtag,
            assignedToUserId,
            createdByUserId,
            id,
            isQuickSearch,
            name,
            errored,
            number,
            requestedByUserId,
            versionId,
            version,
            status,
            lastModified,
            errorStatus,
            createdOn,
            searchDate
        } = searchSummary;
        this.assignedToUser = { name: users.length > 0 || !assignedToUserId ? getUserName(assignedToUserId ?? undefined, users) : "Retrieving user", id: assignedToUserId ?? undefined };
        this.createdByUser = { name: users.length > 0 ? getUserName(createdByUserId, users) : "Retrieving user", id: createdByUserId };
        this.createdOn = createdOn;
        this.currentTask = undefined;
        this.id = id;
        this.isQuickSearch = isQuickSearch;
        this.versionId = versionId;
        this.name = name ? name : "(Empty)";
        this.number = number || undefined;
        this.requestedBy = { name: users.length > 0 ? getUserName(requestedByUserId, users) : "Retrieving user", id: requestedByUserId };
        this.statusEnum = status;
        this.status = getSearchStatusDisplayValue(status);
        this.hitCountByStatus = searchSummary.summary?.hitCountByStatus;
        const progress = getSearchProgress(this.hitCountByStatus);
        this.progressedHits = progress.progress;
        this.totalHits = progress.total;
        this.progressPercentage = getProgressPercentage(progress.progress, progress.total);
        this.lastModified = lastModified;
        this.searchDate = searchDate;
        this.versionNumber = version;
        this._etag = searchEtag;
        //Errored is deprecated on the summary, however summaries can still exist with errored so check that for compability
        //This is undefined instead of false because we don't want a "No" option to show up on the Error refiner.
        this.errorStatus = errorStatus ? errorStatus : errored ? { isErrored: true } : undefined;
        //isErrored needs to exist as a boolean so that our searches grid can have a error refiner
        this.isErrored = this.errorStatus?.isErrored ? true : undefined;
        this.formattedNumber = getNumberDisplayValue(searchSummary);
    }

    /**
     * The user assigned to act on the search version;
     */
    assignedToUser?: UserNameAndId;
    /**
     * Date when the search is created;
     */
    createdOn?: Date;
    /**
     * The user who created the search;
     */
    createdByUser: UserNameAndId;
    /**
     * Current task pending on the search version
     */
    currentTask?: string;
    /**
     * _etag of the search document - used for concurrency checking
     */
    _etag?: string;
    /**
     * Id of the search
     */
    id?: string;
    /**
     * Determines whether the search is a quick search
     */
    isQuickSearch?: boolean;
    /**
     * The name of search version;
     */
    name: string;
    /**
     * Number used to identify the search;
     */
    number?: string;
    /**
     * Represents the hit counts by status.
     */
    hitCountByStatus?: { [HitStatus: string]: number };
    /**
     * The count of progressed hits (e.g. marked as conflict or no conflict).
     */
    progressedHits: number;
    /**
     * The count of total hits.
     */
    totalHits: number;
    /**
     * The date when the search was performed.
     */
    searchDate?: Date;
    /**
     * The percentage of hits that are in a 'Complete' status.
     */
    progressPercentage: number;
    /**
     * The user who requested the search version
     */
    requestedBy?: UserNameAndId;
    /**
     * Id of the search version
     */
    versionId: string;
    /**
     * The search version status display.
     */
    status: string;
    /**
     * versionNumber: number;
     * The searches version number
     */
    versionNumber: number;
    /**
     * The search version status Enum.
     */
    statusEnum: SearchStatus;
    /**
     * The search version type
     */
    type?: string;
    /**
     *
     */
    lastModified: Date;
    /**
     * The searches errorStatus
     */
    errorStatus?: { isErrored: boolean; errorCode?: string };
    /**
     * Boolean used for error refiner
     */
    isErrored?: boolean;
    /**
     * number and version combined into display string ('number.version' or 'number' or empty string depending on state of search)
     */
    formattedNumber: string;
}

/**
 * Creates search request rows based on the given list of search requests.
 * @param searchRequests The search requests to be display on the search requests grid
 */
export function createSearchRequestRows(searchRequests: SearchSummary[], users: User[]): SearchRequestRow[] {
    return _.orderBy(
        searchRequests.map((summary: SearchSummary) => new SearchRequestRow(summary, users)),
        "lastModified",
        "desc"
    );
}
