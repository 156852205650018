import React from "react";

export function convertStringWithLinebreaksToJSX(string: string) {
    const stringLineArray = string.split("\n");

    return (
        <p>
            {stringLineArray.map((line, index) => {
                return (
                    <span key={index}>
                        <>{line}</>
                        <>{index + 1 !== stringLineArray.length && <br />}</>
                    </span>
                );
            })}
        </p>
    );
}
