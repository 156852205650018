import { ValuesOf } from "aderant-web-fw-core";
import _ = require("lodash");
import { HitMessages, isVersionSearched, PermissionsContext, QuickSearch, removeHitsFromSearchVersion, SearchSummary, SearchVersion } from ".";
import { makeEditable, SearchVersionEdited, SearchVersionNew, SearchVersionUnedited } from "./SearchVersion";

/**
 * HitResultDocument used to store hits in a seperate container within Cosmos.
 * We need this to save searches that are too large.
 */
export interface HitResultDocument {
    readonly versionId: string;
    readonly id: string;
    readonly requestTermId: string;
    readonly searchDocumentType: "HitResult";
    hits: Hit[];
    ttl?: number;
}

const CompleteHitStatuses = {
    NoConflict: "NOCONFLICT",
    SeekingWaiver: "SEEKINGWAIVER",
    WaiverOnFile: "WAIVERONFILE",
    Conflict: "CONFLICT",
    NotApplicable: "NOTAPPLICABLE"
} as const;

export type CompleteHitStatus = ValuesOf<typeof CompleteHitStatuses>;

const UnresolvedHitStatuses = {
    Unactioned: "UNACTIONED",
    InProgress: "INPROGRESS",
    WaiverRequired: "WAIVERREQUIRED",
    Confirm: "CONFIRM"
} as const;

export type UnresolvedHitStatus = ValuesOf<typeof UnresolvedHitStatuses>;

export const HitStatuses = {
    ...CompleteHitStatuses,
    ...UnresolvedHitStatuses
} as const;
export type HitStatus = CompleteHitStatus | UnresolvedHitStatus;

export type HitIdentifier = {
    requestTermId: string;
    hitId: number;
};
export type Hit = {
    id: number;
    sourceType: string;
    sourceData: Record<string, any> & { secureEntityId?: string }; //todo: secureEntityId shouldn't be able to be undefined, need to handle this missing on historic searches - cleanup in connector?
    hitLocations: HitLocation[];
    status: HitStatus;
    hitOwnerId: string | null | undefined;
    isRedacted?: boolean;
    previousVersionStatus?: HitStatus;
    commentCount?: number;
};

export interface HitLocation {
    location: string;
    term: string;
    highlights: string[];
}

export type HitMassEditFields = Partial<Pick<Hit, "status" | "hitOwnerId">>;

/**
 *
 * @param status Hit Status
 * @returns Formatted hit status display value
 */
export const getHitStatusDisplayValue = (status: HitStatus): string => {
    switch (status) {
        case "CONFLICT":
            return HitMessages.HIT_STATUS_CONFLICT.getMessage();
        case "INPROGRESS":
            return HitMessages.HIT_STATUS_INPROGRESS.getMessage();
        case "NOCONFLICT":
            return HitMessages.HIT_STATUS_NOCONFLICT.getMessage();
        case "NOTAPPLICABLE":
            return HitMessages.HIT_STATUS_NOTAPPLICABLE.getMessage();
        case "SEEKINGWAIVER":
            return HitMessages.HIT_STATUS_SEEKINGWAIVER.getMessage();
        case "UNACTIONED":
            return HitMessages.HIT_STATUS_UNACTIONED.getMessage();
        case "WAIVERONFILE":
            return HitMessages.HIT_STATUS_WAIVERONFILE.getMessage();
        case "WAIVERREQUIRED":
            return HitMessages.HIT_STATUS_WAIVERREQUIRED.getMessage();
        case "CONFIRM":
            return HitMessages.HIT_STATUS_CONFIRM.getMessage();
    }
};
/**
 * Call this function to determine whether a hit status is considered "Complete"
 * @param status
 */
export function isCompleteHitStatus(status: HitStatus): status is CompleteHitStatus {
    return Object.values(CompleteHitStatuses).some((chs) => chs === status);
}

/**
 * Call this function to determine whether a hit status is valid
 * @param status
 */
export function isValidHitStatus(status: HitStatus): status is HitStatus {
    return Object.values(HitStatuses).some((s) => s === status);
}

/**
 * Call this function to determine whether a hit status is considered "Unresolved"
 * @param status
 */
export function isUnresolvedHitStatus(status: HitStatus): status is UnresolvedHitStatus {
    return Object.values(UnresolvedHitStatuses).some((chs) => chs === status);
}

export function getStatusesCurrentUserCanChangeHitsTo(context: PermissionsContext, searchVersion: SearchVersion | SearchSummary): HitStatus[] {
    if (!isVersionSearched(searchVersion) || searchVersion.assignedToUserId !== context.currentUserId) {
        return [];
    }
    const statusOptions = [...Object.values(HitStatuses)];
    if (searchVersion.version <= 1) {
        statusOptions.splice(statusOptions.indexOf(HitStatuses.Confirm, 0), 1);
    }

    if (searchVersion.status === "CONDITIONALAPPROVAL") {
        return [...Object.values(CompleteHitStatuses)];
    }

    if (searchVersion.status === "APPROVED" || searchVersion.status === "REJECTED") {
        return [];
    }

    return statusOptions;
}

export function attachHitResultsToSearchVersion(searchVersion: SearchVersionUnedited | SearchVersionEdited, hitResults: HitResultDocument[]): SearchVersionUnedited {
    if (hitResults.length === 0) {
        return { ...searchVersion, editState: "CURRENT" };
    }

    const editableSearchVersion = makeEditable(searchVersion);
    removeHitsFromSearchVersion(editableSearchVersion);

    hitResults.forEach((hitResult) => {
        editableSearchVersion.requestTerms.find((requestTerm) => requestTerm.id === hitResult.requestTermId)?.hits?.push(...hitResult.hits);
        hitResult.hits = [];
    });

    return { ...editableSearchVersion, editState: "CURRENT" };
}

export function attachHitResultsToQuickSearch(quickSearch: QuickSearch, hitResults: HitResultDocument[]): QuickSearch {
    if (hitResults.length === 0) {
        return quickSearch;
    }

    removeHitsFromSearchVersion(quickSearch);
    hitResults.forEach((hitResult) => {
        quickSearch.requestTerms.find((requestTerm) => requestTerm.id === hitResult.requestTermId)?.hits?.push(...hitResult.hits);
        hitResult.hits = [];
    });

    return quickSearch;
}

export function searchHasHits(searchVersion: SearchVersion | SearchVersionNew): boolean {
    let hasHits = false;
    searchVersion.requestTerms.forEach((requestTerm) => {
        if (requestTerm.hits && requestTerm.hits.length > 0) {
            hasHits = true;
        }
    });

    return hasHits;
}
