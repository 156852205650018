import { UserRole } from "aderant-conflicts-models";
import { EnvironmentService } from "..";

export const conflictsRoles = ["Conflicts.Standard", "Conflicts.User", "Conflicts.Admin", "Conflicts.ApiAdmin", "Conflicts.SecurityPlanExempt"] as const;
export const isConflictsRole = (role: string): role is ConflictsRole => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (conflictsRoles as unknown as string[]).includes(role);
};
export type ConflictsRole = typeof conflictsRoles[number];

/**
 *
 * @returns All Conflicts role scopes uris, for use when logging in to Conflicts.
 */
export function getAllConflictsRoleScopes(): string[] {
    const prefix = EnvironmentService.getAuthSettings().scopePrefix;
    return conflictsRoles.map((r) => `${prefix}${r}`);
}

/**
 *
 * @param roles Array of Conflicts role strings, from an access token/logged in account claims.
 * @returns UserRole bitflag enum value corresponding to passed in list of roles.
 */
export function rolesArrayToUserRole(roles: string[]): UserRole {
    let role = 0;
    if (roles.includes("Conflicts.Standard")) {
        role = role | UserRole.Standard;
    }
    if (roles.includes("Conflicts.User")) {
        role = role | UserRole.Conflicts;
    }
    if (roles.includes("Conflicts.Admin")) {
        role = role | UserRole.Admin;
    }
    if (roles.includes("Conflicts.ApiAdmin")) {
        role = role | UserRole.ApiAdmin;
    }
    if (roles.includes("Conflicts.SecurityPlanExempt")) {
        role = role | UserRole.SecurityPlanExempt;
    }
    return role;
}
