import { HttpBody } from "./HttpResponse";

export class HeaderBuilder {
    private headers: Record<string, string> = {};

    constructor(existingHeaders?: Record<string, string>) {
        this.headers = existingHeaders ?? {};
    }

    /**
     * Adds a cache header to the headers collection.
     * @param cacheInSeconds The number of seconds to allow cache for.
     * @returns The modified header collection.
     */
    public addCacheHeader(cacheInSeconds: number): this {
        this.headers["Cache-Control"] = `max-age=${cacheInSeconds}`;
        return this;
    }

    public addNoStoreCacheHeader(): this {
        this.headers["Cache-Control"] = `no-store`;
        return this;
    }

    public addContentTypeHeader(responseBody: HttpBody): this {
        if (typeof responseBody === undefined || typeof responseBody === null || typeof responseBody === "string") {
            this.headers["Content-Type"] = "text/plain; charset=utf-8";
        } else {
            this.headers["Content-Type"] = "application/json";
        }
        return this;
    }

    /**
     * Get the underlying collection of headers.
     * @returns Returns the underlying collection of headers.
     */
    public build(): Record<string, string> {
        return this.headers;
    }
}
