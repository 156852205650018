//justification: TSOA will only grab the correct schema name from interfaces, not types.
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Logger, OmitStrict } from "aderant-web-fw-core";
import _ from "lodash";
import { StatusCodes } from "../../Http";
import { Messages } from "../Message";
import { ConflictsError, InternalConflictsError } from "./ConflictsError";

export interface ValidationContext {
    type: string; //the type of thing being validated
    id: string | number; //the id of the thing being validated
}

export type ValidationMessage<MessageCode extends string = string> = {
    messageCode: MessageCode;
    message: string;
    context?: ValidationContext; //todo: do adding messages on ValidationErrors via a method, and push/pop the id of the thing currently being validated so context gets dealt with
};

export function addValidationContext(validationMessage: ValidationMessage, contextType: string, contextId: string): ValidationMessage {
    validationMessage.context = {
        type: contextType,
        id: contextId
    };
    return validationMessage;
}

export function logValidationError(validationMessage: ValidationMessage, logger: Logger): void {
    if (validationMessage.context) {
        logger.info(
            `Validation Error: Code: ${validationMessage.messageCode}. Message: ${validationMessage.message}. Context: ${validationMessage.context.type} with id: ${validationMessage.context.id}.`
        );
    } else {
        logger.info(`Validation Error: Code: ${validationMessage.messageCode}. Message: ${validationMessage.message}.`);
    }
}

export const basicConflictsErrorTypes = {
    Validation: "VALIDATION",
    NotFound: "NOT_FOUND",
    AccessDenied: "ACCESS_DENIED",
    Unauthorized: "UNAUTHORIZED",
    Unexpected: "UNEXPECTED",
    TooManyRequests: "TOO_MANY_REQUESTS"
} as const;

/**
 * @example {
    "_conflictserrortype": "VALIDATION",
    "errors": [
        {
            "messageCode": "VLD_NULLUNDEFEMPTY_FIELD",
            "message": "Input requestTerm[0].term is null, undefined or empty."
        },
        {
            "messageCode": "VLD_WRONGTYPE_FIELD",
            "message": "Expected input requestTerm[0].affiliationCode to be a string or undefined."
        },
        {
            "messageCode": "VLD_WRONGTYPE_FIELD",
            "message": "Expected input requestTerm[2].term to be a string."
        },
        {
            "messageCode": "VLD_SEARCH_TERM_NOT_STRING_OR_EMPTY",
            "message": "The search term at index position 0 for the request term at index position 3 is not a string or is an empty string"
        }
    ],
    "message": "Input requestTerm[0].term is null, undefined or empty.\r\nExpected input requestTerm[0].affiliationCode to be a string or undefined.\r\nExpected input requestTerm[2].term to be a string.\r\nThe search term at index position 0 for the request term at index position 3 is not a string or is an empty string"
}
 */
export interface ValidationErrors extends InternalConflictsError<400, "VALIDATION"> {
    errors: ValidationMessage[];
}

export function validationErrors(): ValidationErrors {
    return {
        _conflictserrortype: basicConflictsErrorTypes.Validation,
        httpStatusCode: 400,
        errors: [],
        message: "There were some validation issues with your request, see errors field for details."
    };
}

export function wrapValidationErrors(validationErrors: ValidationMessage<any>[]): ValidationErrors {
    return {
        _conflictserrortype: basicConflictsErrorTypes.Validation,
        httpStatusCode: 400,
        //map errors to new object to avoid sending irrelevant data over the wire.
        errors: validationErrors.map((e) => {
            if (e.context) {
                return {
                    messageCode: e.messageCode,
                    message: e.message,
                    context: e.context
                };
            }
            return {
                messageCode: e.messageCode,
                message: e.message
            };
        }),
        message: validationErrors.map((v) => v.message).join("\r\n")
    };
}

export interface EtagMismatch extends ValidationMessage, ConflictsError<412, "ETAG_MISMATCH"> {}

export function etagMismatch(message: string = Messages.VLD_ETAG_MISMATCH.getMessage()): EtagMismatch {
    return {
        _conflictserrortype: "ETAG_MISMATCH",
        httpStatusCode: 412,
        messageCode: Messages.VLD_ETAG_MISMATCH.messageCode,
        message: message
    };
}

export interface NotFound extends InternalConflictsError<404, "NOT_FOUND"> {}

const notFoundError = {
    _conflictserrortype: basicConflictsErrorTypes.NotFound,
    httpStatusCode: 404,
    message: "Not found"
} as const;

export function notFound(): NotFound {
    return notFoundError;
}

export function notFoundWithMessage(message: string): NotFound {
    return {
        _conflictserrortype: basicConflictsErrorTypes.NotFound,
        httpStatusCode: 404,
        message: message
    } as const;
}

export function isNotFound(error: ConflictsError): error is NotFound {
    //justification: get type safety on conflictserrortypevalue (will stop compiling if someone changes the string for NotFound)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (error as NotFound)._conflictserrortype === "NOT_FOUND";
}

export interface Forbidden extends InternalConflictsError<403, "ACCESS_DENIED"> {}

export function forbidden(message?: string): Forbidden {
    return {
        _conflictserrortype: basicConflictsErrorTypes.AccessDenied,
        httpStatusCode: 403,
        message: message || "Current User is not allowed to access the Conflicts application."
    };
}
export function isForbidden(error: ConflictsError): error is Forbidden {
    //justification: get type safety on conflictserrortypevalue (will stop compiling if someone changes the string for Forbidden)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (error as Forbidden)._conflictserrortype === "ACCESS_DENIED";
}

export interface TooManyRequests extends InternalConflictsError<429, "TOO_MANY_REQUESTS"> {}

export function tooManyRequests(message?: string): TooManyRequests {
    return {
        _conflictserrortype: basicConflictsErrorTypes.TooManyRequests,
        httpStatusCode: 429,
        message: message || "Too many requests sent"
    };
}

export interface Unauthorized extends InternalConflictsError<401, "UNAUTHORIZED"> {}

export function unauthorized(): Unauthorized {
    return {
        _conflictserrortype: basicConflictsErrorTypes.Unauthorized,
        httpStatusCode: 401,
        message: "" //empty message is intentional - we generally don't want to leak any info about *why* someones authorization is invalid.
        //                    This is less of a concern on 403s as at that point we know the user is at least in the system - they're probably not an external attacker.
    };
}

export interface JsonParseError extends ValidationMessage, InternalConflictsError<400, "JSON_PARSE"> {
    /**The root cause of the parse error. If it was caused by a caught exception, this should be that exception. This should have more detail about the exact parsing issue. (i.e. where the error occured in the string being parsed)*/
    rootCause: unknown;
    contextInfo: string;
}

export function jsonParseError(rootCause: unknown, contextInfo: string, message?: string): JsonParseError {
    return {
        _conflictserrortype: "JSON_PARSE",
        httpStatusCode: 400,
        messageCode: Messages.VLD_SYNTAX_JSON.messageCode,
        message: message || Messages.VLD_SYNTAX_JSON.getMessage(),
        contextInfo: contextInfo,
        rootCause: rootCause
    };
}

export function isJsonParseError(error: ConflictsError): error is JsonParseError {
    //justification: get type safety on conflictserrortypevalue (will stop compiling if someone changes the string for NotFound)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (error as JsonParseError)._conflictserrortype === "JSON_PARSE";
}

/**Error type to be used when throwing any unexpected errors out of function app implementations.
 * When created with @see {@link unexpectedError} will automatically wrap nested unexpectedError cause objects
 * so that context strings are stacked.
 *
 * Note that it has no httpStatusCode field - this is because it should always be thrown rather than returned from things,
 * All uncaught exceptions get returned with a 500 status code anyway, and this ensures that this type is not a valid
 * explicit return value on an API contract.
 *
 */
export interface UnexpectedError extends OmitStrict<InternalConflictsError<StatusCodes.Failure, typeof basicConflictsErrorTypes.Unexpected>, "httpStatusCode">, Error {
    _conflictserrortype: typeof basicConflictsErrorTypes.Unexpected;
    /** The status code of the root cause of an unexpected error, extracted and retained when an {@link UnexpectedError} is wrapped again with {@link unexpectedError}.*/
    rootCauseStatusCode?: number;
    /**The root cause of the unexpected error, extracted and retained when an {@link UnexpectedError} is wrapped again with {@link unexpectedError}.*/
    rootCause: unknown;
    context: string[];
    message: string;
    toString(): string;
}

export function isUnexpectedError(e: any): e is UnexpectedError {
    //justification: need to cast to check field for type guard
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (e as UnexpectedError)?._conflictserrortype === basicConflictsErrorTypes.Unexpected && !!e.context;
}

/**
 * Creates an {@link UnexpectedError}.
 *
 * @param cause - The unexpected thing that is the cause of this error. This can either be an error received from elsewhere that we did not expect, or just a string describing the problem if we are at the true root source of the issue.
 * Context, rootCause etc will be carried forward if it is also an {@link UnexpectedError}
 *
 * @param contextInfo - A string describing the circumstances the unexpected cause was encountered in. Will be combined with previous contextInfo values if cause is also an UnexpectedError.
 *
 * Note that this is done automatically in proxies generated by {@link AzureFunctionDefinition}, with the context string containing the URL of the function app that was called.
 *
 * @param mostRecentStatusCode - The HTTP status code, only relevant when cause came directly from the body of an HTTP response.
 * @returns an {@link UnexpectedError}
 */
export function unexpectedError(cause: unknown, contextInfo: string, mostRecentStatusCode?: number): UnexpectedError {
    let rootCause: unknown;
    let rootCauseStatusCode: number | undefined = undefined;
    let contexts: string[];
    if (isUnexpectedError(cause)) {
        rootCause = cause.rootCause;
        rootCauseStatusCode = cause.rootCauseStatusCode ?? mostRecentStatusCode;
        contexts = [contextInfo, ...cause.context];
    } else {
        rootCause = cause;
        rootCauseStatusCode = mostRecentStatusCode;
        contexts = [contextInfo];
    }

    const message = prettyPrintUnexpectedError(contexts, rootCause, rootCauseStatusCode);

    return {
        _conflictserrortype: basicConflictsErrorTypes.Unexpected,
        name: "Unexpected Error",
        rootCause: rootCause,
        rootCauseStatusCode: rootCauseStatusCode,
        context: contexts,
        message: message, //todo remove the message field from ConflictsError and just use toString so that expensive stuff like this only needs to be constructed when it's needed
        toString: () => message
    };
}

const replacer = function (k: any, v: any) {
    if (v === undefined) {
        return null;
    }
    return v;
};

function prettyPrintUnexpectedError(contexts: string[], rootCause: unknown, rootCauseStatusCode?: number): string {
    let message: string = contexts.join("\r\n");
    //Error type has no enumerable properties and so just spits out '{}' when you stringify it, use the message field instead.
    const rootCauseString = rootCause instanceof Error ? rootCause.message : JSON.stringify(rootCause, replacer);
    //App Insights seems to ignore newlines, so the weird formatting is so it's still readable on a single line.
    message = `Unexpected error encountered, see rootCause field for more details.
    | Context Stack:
        ${message}`;
    if (rootCauseStatusCode) {
        message = message += `
    | Root Cause Status Code: ${rootCauseStatusCode}`;
    }
    message = message += `| Root Cause:
${rootCauseString}`;

    return message;
}
