import React from "react";
import { conflictsPalette } from "styles/conflictsPalette";

export const SearchRequestsIcon = (): JSX.Element => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Artboard Copy 3</title>
            <defs>
                <path
                    d="M8.15420582,1.99988931 L2,2.0017533 L2,19.0017533 L18,19.0017533 L18,11.516 L18.162134,11.6524963 C18.7910298,12.1793453 19.4041102,12.6937391 20.0013751,13.1956777 L20,18.9017533 C20,20.0567533 19,21.0017533 17.7777778,21.0017533 L17.7777778,21.0017533 L2.22222222,21.0017533 C1,21.0017533 0,20.0567533 0,18.9017533 L0,18.9017533 L0,2.1017533 C0,0.946753303 1,0.00175330264 2.22222222,0.00175330264 L2.22222222,0.00175330264 L8.6001273,6.38378239e-16 C8.40284844,0.541617685 8.25420795,1.20824746 8.15420582,1.99988931 Z"
                    id="path-1"
                ></path>
                <path
                    d="M16,10.0017533 L4,10.0017533 L4,12.0017533 L16,12.0017533 L16,10.0017533 Z M8,6.0017533 L4,6.0017533 L4,8.0017533 L8,8.0017533 L8,6.0017533 Z M4,16.0017533 L16,16.0017533 L16,14.0017533 L4,14.0017533 L4,16.0017533 Z"
                    id="path-3"
                ></path>
                <path
                    d="M19.6412236,6.48603003 L19.0318982,6.48603003 L18.8159348,6.2777796 C19.5718067,5.39850002 20.0268725,4.25697915 20.0268725,3.01518955 C20.0268725,0.246230147 17.7823957,-1.9982467 15.0134362,-1.9982467 C12.2444768,-1.9982467 10,0.246230147 10,3.01518955 C10,5.78414896 12.2444768,8.0286258 15.0134362,8.0286258 C16.2552258,8.0286258 17.3967467,7.57356005 18.2760263,6.81768812 L18.4842767,7.03365153 L18.4842767,7.64297686 L22.3407662,11.4917533 L23.49,10.3425195 L19.6412236,6.48603003 Z M15.0134362,6.48603003 C13.0929045,6.48603003 11.5425958,4.93572128 11.5425958,3.01518955 C11.5425958,1.09465782 13.0929045,-0.455650928 15.0134362,-0.455650928 C16.933968,-0.455650928 18.4842767,1.09465782 18.4842767,3.01518955 C18.4842767,4.93572128 16.933968,6.48603003 15.0134362,6.48603003 Z"
                    id="path-5"
                ></path>
            </defs>
            <g id="Artboard-Copy-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(1.000000, 1.998247)">
                    <mask id="mask-2" fill={conflictsPalette.text.white}>
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <use id="Combined-Shape" fill={conflictsPalette.text.white} fillRule="nonzero" xlinkHref="#path-1"></use>
                    <path
                        d="M18,0.00175330264 L4,0.00175330264 C2.9,0.00175330264 2,0.901753303 2,2.0017533 L2,18.0017533 C2,19.1017533 2.9,20.0017533 4,20.0017533 L18,20.0017533 C19.1,20.0017533 20,19.1017533 20,18.0017533 L20,2.0017533 C20,0.901753303 19.1,0.00175330264 18,0.00175330264 Z"
                        id="Path"
                        fillRule="nonzero"
                        mask="url(#mask-2)"
                    ></path>
                    <polygon id="Path" fillRule="nonzero" mask="url(#mask-2)" points="18 18.0017533 4 18.0017533 4 2.0017533 6 2.0017533 16 2.0017533 18 2.0017533"></polygon>
                    <mask id="mask-4" fill={conflictsPalette.text.white}>
                        <use xlinkHref="#path-3"></use>
                    </mask>
                    <use id="icon/av/playlist_add_check_24px" fill={conflictsPalette.text.white} fillRule="nonzero" xlinkHref="#path-3"></use>
                    <mask id="mask-6" fill={conflictsPalette.text.white}>
                        <use xlinkHref="#path-5"></use>
                    </mask>
                    <use id="icon/action/search_24px-copy" fill={conflictsPalette.text.white} fillRule="nonzero" xlinkHref="#path-5"></use>
                </g>
            </g>
        </svg>
    );
};
