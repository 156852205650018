import { DataFactorySecrets } from "aderant-conflicts-models";
import { DataFactoryConnector } from "../DataFactoryConnector/DataFactoryConnector";

export async function getDataFactoryConnectorMock(): Promise<DataFactoryConnector> {
    const dataFactorySecrets: DataFactorySecrets = {
        subscriptionId: "22b22a2e-5499-4248-87aa-aec7cf261deb",
        resourceGroupName: "xxx",
        factoryName: "yyy"
    };
    const mock = await DataFactoryConnector.open(dataFactorySecrets);

    mock.getPipelineRunsByFactory = jest.fn();
    mock.getPipeline = jest.fn();
    mock.getPipelinesByFactory = jest.fn();
    mock.getTrigger = jest.fn();

    return mock;
}
