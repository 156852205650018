import { Lookup, RequestTerm, MAX_CHARACTERS_PER_TERM, MAX_SEARCH_TERMS, isTermSingleAsterisk } from "aderant-conflicts-models";
import { messages } from "pages/SearchEditPage/SearchUtils";
import { getExcelValidationFormat } from "./ExcelTemplates/ExcelUtils";
import { Workbook, WorkbookError } from "./ImportTermsModal";
import { ImportTermsWarning } from "./WarningMessages";

type ValidRequestTerms = {
    type: "ValidRequestTerms";
    requestTerms: RequestTerm[];
    warnings: ImportTermsWarning[];
};

type ValidSearchTerms = {
    type: "ValidSearchTerms";
    searchTerms: string[];
    warnings: ImportTermsWarning[];
};

type ValidationLists = {
    affiliations: Lookup[];
    partyStatuses: Lookup[];
};

type RequestTermSet = {
    term: string;
    affiliation: string | undefined;
    partyStatus: string | undefined;
    searchTerms: Set<string>;
};

function convertWorkbookToError(workbook: Workbook, errorMessage: string[]): WorkbookError {
    return { type: "WorkbookError", data: { title: workbook.data.title, errorMessages: errorMessage } };
}

export function processRequestTermsWorkbook(workbook: Workbook, validationLists: ValidationLists): ValidRequestTerms | WorkbookError {
    //Return if sheet name is incorrect
    const worksheet = workbook.data.getWorksheet(messages.requestTerms);
    if (!worksheet) {
        return convertWorkbookToError(workbook, [messages.excelErrorNoRequestTermSheet]);
    }

    let currentRequestTerm: RequestTermSet = { term: "", affiliation: undefined, partyStatus: undefined, searchTerms: new Set<string>() };
    const requestTerms: RequestTerm[] = [];
    const requestTermsMissing: number[] = [];
    const warnings: ImportTermsWarning[] = [];
    const errorMessages: string[] = [];
    const invalidRequestTerms: string[] = [];

    //Local warning variables
    const duplicateTerms: string[] = [];
    const invalidSearchTerms: string[] = [];
    const invalidAffiliations: string[] = [];
    const duplicateAffiliations: string[] = [];
    const invalidPartyStatuses: string[] = [];
    const duplicatePartyStatuses: string[] = [];
    let termsTooLong = 0;

    let searchTermCount = 0;

    //Find duplicates in AffiliationLookups.label
    const allAffiliationLookupLabels = validationLists.affiliations.map((lookup) => lookup.label);
    const duplicateAffiliationLookupLabels = new Set(allAffiliationLookupLabels.filter((item, index) => allAffiliationLookupLabels.indexOf(item) !== index));

    //Find duplicates in PartyStatusLookups.label
    const allPartyStatusLookupLabels = validationLists.partyStatuses.map((lookup) => lookup.label);
    const duplicatePartyStatusLookupLabels = new Set(allPartyStatusLookupLabels.filter((item, index) => allPartyStatusLookupLabels.indexOf(item) !== index));

    worksheet.eachRow((row, rowNumber) => {
        //skip header row
        if (rowNumber === 1) {
            return;
        }

        const item = {
            requestTerm: row.getCell(1).text.trim(),
            affiliation: row.getCell(2).text.trim(),
            partyStatus: row.getCell(3).text.trim()
        };

        let searchTerm = row.getCell(4).text.trim();

        const isAffiliationPartyStatusWithNoRequestTerm = !item.requestTerm && (item.affiliation || item.partyStatus);
        const isUnparentedSearchTerm = !item.requestTerm && !currentRequestTerm.term && searchTerm;
        if (isAffiliationPartyStatusWithNoRequestTerm || isUnparentedSearchTerm) {
            requestTermsMissing.push(rowNumber);
            return;
        }
        if (!isUnparentedSearchTerm && searchTerm && searchTerm.length > MAX_CHARACTERS_PER_TERM) {
            termsTooLong++;
            searchTerm = "";
        }

        //If this row has a request term, move the old current request term (if there is one) into requestTerms and start collecting searchTerms on this one.
        if (item.requestTerm) {
            !searchTerm && searchTermCount++;
            //Push the current request term
            if (currentRequestTerm.term.length) {
                if (isTermSingleAsterisk(currentRequestTerm.term)) {
                    invalidRequestTerms.push(currentRequestTerm.term);
                } else {
                    currentRequestTerm.term.length < MAX_CHARACTERS_PER_TERM ? requestTerms.push(convertSetToRequestTerm(currentRequestTerm)) : termsTooLong++;
                }
            }

            //Validate affiliation and party statuses
            if (item.affiliation) {
                const isDuplicateAffiliation = Array.from(duplicateAffiliationLookupLabels).includes(item.affiliation);
                if (isDuplicateAffiliation) {
                    duplicateAffiliations.push(item.requestTerm);
                    item.affiliation = "";
                } else {
                    item.affiliation = validateAndConvertLookup(item.affiliation, validationLists.affiliations);
                    if (!item.affiliation.length) {
                        invalidAffiliations.push(item.requestTerm);
                    }
                }
            }
            if (item.partyStatus) {
                const isDuplicatePartyStatus = Array.from(duplicatePartyStatusLookupLabels).includes(item.partyStatus);
                if (isDuplicatePartyStatus) {
                    duplicatePartyStatuses.push(item.requestTerm);
                    item.partyStatus = "";
                } else {
                    item.partyStatus = validateAndConvertLookup(item.partyStatus, validationLists.partyStatuses);
                    if (!item.partyStatus.length) {
                        invalidPartyStatuses.push(item.requestTerm);
                    }
                }
            }
            //Create a new request term
            currentRequestTerm = { ...item, term: item.requestTerm, searchTerms: new Set<string>() };
        }

        if (isTermSingleAsterisk(currentRequestTerm.term)) {
            return;
        }

        if (currentRequestTerm.term.length && searchTerm) {
            if (isTermSingleAsterisk(searchTerm)) {
                invalidSearchTerms.push(searchTerm);
            } else if (currentRequestTerm.searchTerms.has(searchTerm)) {
                duplicateTerms.push(searchTerm);
            } else {
                currentRequestTerm.searchTerms.add(searchTerm);
                searchTermCount++;
            }
        }
    });

    //Push the last request term
    if (currentRequestTerm.term.length) {
        if (isTermSingleAsterisk(currentRequestTerm.term)) {
            invalidRequestTerms.push(currentRequestTerm.term);
        } else {
            currentRequestTerm.term.length < MAX_CHARACTERS_PER_TERM ? requestTerms.push(convertSetToRequestTerm(currentRequestTerm)) : termsTooLong++;
        }
    }

    //Build warnings array from local warnings
    duplicateTerms.length && warnings.push({ type: "duplicateTerms", terms: duplicateTerms });
    invalidSearchTerms.length && warnings.push({ type: "invalidSearchTerms", terms: invalidSearchTerms });
    invalidAffiliations.length && warnings.push({ type: "invalidAffiliations", requestTerms: invalidAffiliations });
    duplicateAffiliations.length && warnings.push({ type: "duplicateAffiliations", requestTerms: duplicateAffiliations });
    invalidPartyStatuses.length && warnings.push({ type: "invalidPartyStatuses", requestTerms: invalidPartyStatuses });
    duplicatePartyStatuses.length && warnings.push({ type: "duplicatePartyStatuses", requestTerms: duplicatePartyStatuses });
    invalidRequestTerms.length && warnings.push({ type: "invalidRequestTerms", requestTerms: ["*"] });
    termsTooLong && warnings.push({ type: "termsTooLong", numberOfTerms: termsTooLong });

    //errors
    const NoRequestTerms = !requestTerms.length && !!errorMessages.push(messages.excelErrorNoRequestTerms);
    const RequestTermsMissing = !!requestTermsMissing.length && !!errorMessages.push(messages.excelErrorRequestTermsMissing + requestTermsMissing.join(", "));
    const TooManySearchTerms = searchTermCount > MAX_SEARCH_TERMS && !!errorMessages.push(messages.excelErrorTooManySearchTerms);
    if (NoRequestTerms && termsTooLong) {
        errorMessages.push(`All request terms were not imported because they are longer than the ${MAX_CHARACTERS_PER_TERM} maximum character count.`);
    }

    if (NoRequestTerms || RequestTermsMissing || TooManySearchTerms) {
        return convertWorkbookToError(workbook, errorMessages);
    } else {
        return { type: "ValidRequestTerms", requestTerms, warnings };
    }
}

export function processSearchTermsWorkbook(workbook: Workbook): ValidSearchTerms | WorkbookError {
    //Return if sheet name is incorrect
    const worksheet = workbook.data.getWorksheet(messages.searchTerms);
    if (!worksheet) {
        return convertWorkbookToError(workbook, [messages.excelErrorNoSearchTermSheet]);
    }

    const allTerms: string[] = [];
    const invalidTerms: string[] = [];
    const errorMessages: string[] = [];
    const maxSearchTerms = 10000;
    const warnings: ImportTermsWarning[] = [];
    let searchTermsTooLong = 0;
    let errorFlag = false;

    worksheet.eachRow((row, rowNumber) => {
        //skip header row
        if (rowNumber === 1) {
            return;
        }

        if (errorFlag) {
            return;
        }

        if (row.actualCellCount > 1) {
            errorFlag = true;
        }

        row.eachCell((cell) => {
            if (cell.text) {
                if (cell.text.trim().length > MAX_CHARACTERS_PER_TERM) {
                    searchTermsTooLong++;
                } else if (isTermSingleAsterisk(cell.text.trim())) {
                    invalidTerms.push(cell.text.trim());
                } else {
                    allTerms.push(cell.text.trim());
                }
            }
        });
    });

    //split terms into unique search terms and duplicates
    const searchTerms = [...new Set(allTerms)];
    const duplicateTerms = allTerms.filter((item, index) => allTerms.indexOf(item) !== index);

    //Build warnings array from local warnings
    duplicateTerms.length && warnings.push({ type: "duplicateTerms", terms: duplicateTerms });
    invalidTerms.length && warnings.push({ type: "invalidSearchTerms", terms: invalidTerms });
    searchTermsTooLong && warnings.push({ type: "termsTooLong", numberOfTerms: searchTermsTooLong });

    //errors
    const NoSearchTerms = !searchTerms.length && !!errorMessages.push(messages.excelErrorNoSearchTerms);
    const WrongColumn = errorFlag && !!errorMessages.push(messages.excelErrorSearchTermsInWrongColumn);
    const TooManySearchTerms = searchTerms.length > maxSearchTerms && !!errorMessages.push(messages.excelErrorTooManySearchTerms);

    if (NoSearchTerms && searchTermsTooLong) {
        errorMessages.push(`All search terms were not imported because they are longer than the ${MAX_CHARACTERS_PER_TERM} maximum character count.`);
    }

    if (NoSearchTerms || WrongColumn || TooManySearchTerms) {
        return convertWorkbookToError(workbook, errorMessages);
    } else {
        return { type: "ValidSearchTerms", searchTerms, warnings };
    }
}

function convertSetToRequestTerm(requestTermSet: RequestTermSet): RequestTerm {
    return { ...requestTermSet, searchTerms: Array.from(requestTermSet.searchTerms) };
}

function validateAndConvertLookup(lookup: string, lookupList: Lookup[]): string {
    //If value is in the form of 'label (code)' else 'label' else 'code' then return the code. Otherwise the entry is invalid.
    const validLookup =
        lookupList.find((listItem) => getExcelValidationFormat(listItem) === lookup) ||
        lookupList.find((listItem) => listItem.label === lookup) ||
        lookupList.find((listItem) => listItem.value === lookup);
    if (validLookup) {
        return validLookup.value;
    } else {
        return "";
    }
}
