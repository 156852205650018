import { Lookup } from "aderant-conflicts-models";
import { DataValidation } from "exceljs";

export const messages = {
    required: "Required",
    errorStyle: "error",
    errorTitle: "Error",
    dropdownValidationError: "Value must be in the dropdown list",
    requestTermsSheetName: "Request Terms",
    requestTermsFileName: "Request Terms Template",
    searchTermsSheetName: "Search Terms",
    searchTermsFileName: "Search Terms Template",
    sampleSheetName: "Sample",
    affiliationPartyStatusSheetName: "Affiliations and Party Statuses"
};

const populateAffiliationListDropdown = (affiliationLength: number): DataValidation => {
    return {
        type: "list",
        allowBlank: true,
        formulae: [`'${messages.affiliationPartyStatusSheetName}'!$A$1:$A$${affiliationLength}`],
        showErrorMessage: true,
        errorStyle: messages.errorStyle,
        errorTitle: messages.errorTitle,
        error: messages.dropdownValidationError
    };
};

const populatePartyStatusListDropdown = (partyStatusLength: number): DataValidation => {
    return {
        type: "list",
        allowBlank: true,
        formulae: [`'${messages.affiliationPartyStatusSheetName}'!$B$1:$B$${partyStatusLength}`],
        showErrorMessage: true,
        errorStyle: messages.errorStyle,
        errorTitle: messages.errorTitle,
        error: messages.dropdownValidationError
    };
};

export const ExcelUtils = {
    requestTermHeaders: [
        { header: "Request Terms", key: "requestTerms", width: 20 },
        { header: "Affiliation", key: "affiliation", width: 18 },
        { header: "Party Status", key: "partyStatus", width: 18 },
        { header: "Search Terms", key: "searchTerms", width: 28 }
    ],
    searchTermHeaders: [{ header: "Search Terms", key: "requestTerms", width: 20 }],
    getAffiliationListDropdown: populateAffiliationListDropdown,
    getPartyStatusListDropdown: populatePartyStatusListDropdown,
    fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    fileExtension: ".xlsx"
};

export function sampleInstructions(sheetName: string) {
    return [[`Guidelines when imorting ${sheetName.toLowerCase()}:`], [`The sheet must be named "${sheetName}"`]];
}

export function getExcelValidationFormat(Lookup: Lookup) {
    return Lookup.label + " (" + Lookup.value + ")";
}
