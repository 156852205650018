import { AzureKeyCredential, SearchIndex, SearchIndexClient, ComplexField, SimpleField } from "@azure/search-documents";
import { AzureSettings } from "./AzureSettings";

export function createSearchIndexClient(settings: AzureSettings): SearchIndexClient {
    return new SearchIndexClient(settings.getIndexesUrl(), new AzureKeyCredential(settings.getAdminApiKey()));
}

export function createSearchIndexClientWithVersion(settings: AzureSettings): SearchIndexClient {
    return new SearchIndexClient(settings.getBaseUrl(), new AzureKeyCredential(settings.getAdminApiKey()), { apiVersion: settings.getApiVersion() });
}

async function getAndFilterFields(searchIndexClient: SearchIndexClient, indexName: string, filterFunction: (searchIndex: SearchIndex) => string[]) {
    const indexFields: { [indexName: string]: string[] } = {};
    const searchIndexes = searchIndexClient.listIndexes();
    for await (const index of searchIndexes) {
        if (index.name === indexName) {
            indexFields[index.name] = filterFunction(index);
        }
    }
    return indexFields;
}

export async function doesIndexExist(searchIndexClient: SearchIndexClient, indexName: string): Promise<boolean> {
    const searchIndexNames = searchIndexClient.listIndexesNames();
    for await (const name of searchIndexNames) {
        if (name === indexName) {
            return true;
        }
    }
    return false;
}

export async function getSearchableFieldsForIndexName(indexName: string, searchIndexClient: SearchIndexClient): Promise<{ [indexName: string]: string[] }> {
    return getAndFilterFields(searchIndexClient, indexName, (searchIndex: SearchIndex) => {
        return searchIndex.fields.filter((f: ComplexField | SimpleField) => "searchable" in f && f.searchable).map((f) => f.name);
    });
}

export async function getAllFieldsForIndexName(indexName: string, searchIndexClient: SearchIndexClient): Promise<{ [indexName: string]: string[] }> {
    return getAndFilterFields(searchIndexClient, indexName, (searchIndex: SearchIndex): string[] => {
        return searchIndex.fields.map((f) => f.name);
    });
}
