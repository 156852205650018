import * as React from "react";

export const ConflictsLogo = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { color: "currentLoginLogoColor" | "newLoginLogoColor" }): JSX.Element => {
    const fillColor = props.color === "newLoginLogoColor" ? "#ffffff" : "#1f3c4f";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" viewBox="0 0 440.31 118.94" {...props}>
            <g id="Layer_1-2" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <path
                    id="fullLogo"
                    d="M199.4 41.77c-4.28-3.81-10.15-5.87-15.78-5.87-13.4 0-23.62 11.18-23.62 24.42s10.15 24.26 23.47 24.26c5.87 0 11.49-2.22 15.93-6.18v8.8c-4.6 2.69-10.47 4.2-15.7 4.2-17.04 0-31.16-13.56-31.16-30.84s14.19-31.47 31.79-31.47c5.23 0 10.54 1.27 15.06 4.04v8.64z"
                    fill={fillColor}
                ></path>
                <path
                    id="fullLogo-2"
                    d="M247.21 70.83c0 11.64-9.08 20.56-20.64 20.56s-20.64-8.92-20.64-20.56 9.08-20.56 20.64-20.56 20.64 8.92 20.64 20.56zm-33.85 0c0 7.27 5.86 13.46 13.21 13.46s13.21-6.19 13.21-13.46-5.86-13.46-13.21-13.46-13.21 6.11-13.21 13.46z"
                    fill={fillColor}
                ></path>
                <path
                    id="fullLogo-3"
                    d="M261.82 56.63h.17c2.31-4.13 6.94-6.36 11.64-6.36 10.9 0 13.71 7.35 13.71 16.76v23.04h-7.43V67.94c0-6.6-.91-11.06-8.5-11.06-9.58 0-9.58 8.42-9.58 15.44v17.75h-7.43V51.43h7.43v5.2z"
                    fill={fillColor}
                ></path>
                <path
                    fill={fillColor}
                    id="fullLogo-4"
                    d="M317.14 36.71c-1.49-.55-3.39-1.3-4.95-1.3-5.28 0-5.28 5.56-5.28 8.72v13.8h10.24v5.77h-10.24v26.37h-7.43V63.7h-3.39v-5.77h3.39V42.27c0-7 2.89-12.64 12.47-12.64 1.82 0 3.55.27 5.2.82v6.25z"
                ></path>
                <path fill={fillColor} id="fullLogo-5" d="M321.02 29.64H328.45V90.07H321.02z"></path>
                <path fill={fillColor} id="fullLogo-6" d="M349.92 34.84c0 2.81-2.31 5.04-5.12 5.04s-5.12-2.23-5.12-5.04 2.31-5.2 5.12-5.2 5.12 2.31 5.12 5.2z"></path>
                <path id="fullLogo-7" d="M341.09 51.43H348.52V90.07H341.09z" fill={fillColor}></path>
                <path
                    id="fullLogo-8"
                    d="M387.22 62.41c-2.97-3.39-6.61-5.2-11.06-5.2-7.27 0-12.88 6.44-12.88 13.54 0 7.93 5.53 13.71 13.38 13.71 4.29 0 7.76-1.98 10.57-5.12v9.5c-3.38 1.82-6.77 2.56-10.65 2.56-11.39 0-20.72-8.75-20.72-20.31s9.25-20.81 21.14-20.81c3.47 0 7.27.83 10.24 2.56v9.58z"
                    fill={fillColor}
                ></path>
                <path id="fullLogo-9" d="M404.15 90.07h-7.43V58.36h-4.05v-6.94h4.05V36.64h7.43v14.78h7.27v6.94h-7.27v31.71z" fill={fillColor}></path>
                <path
                    id="fullLogo-10"
                    d="M431.39 60.27c-.91-1.9-2.81-3.39-4.95-3.39s-4.05 1.65-4.05 3.8c0 3.39 4.46 4.87 9 6.94 4.54 2.06 8.92 4.87 8.92 10.98 0 7.35-6.11 12.8-13.29 12.8-5.61 0-11.31-3.22-13.38-8.67l6.36-2.89c1.57 2.89 3.47 4.95 7.02 4.95 3.14 0 5.86-2.15 5.86-5.45 0-2.06-.99-3.05-2.72-4.21l-7.27-3.88c-4.29-2.31-7.43-4.46-7.43-9.74 0-6.28 5.12-11.23 11.39-11.23 4.71 0 8.5 2.56 10.57 6.77l-6.03 3.22z"
                    fill={fillColor}
                ></path>
                <path
                    id="fullLogo-11"
                    d="M81.39 93.61l-8.26-19.96-13.45-32.32-13.79 32.33-.6 1.42c2.87-1.51 7.05-3.28 12.22-4.23 2.52-.46 5.06-.7 7.57-.7 1.17 0 2.37.05 3.55.15l.43.04-.07 1.86h-.43c-.14 0-14.16.48-30.86 20.92l-.18.42-.6.07h-9.83l32.79-74.88 31.96 74.88H81.39z"
                    fill={fillColor}
                ></path>
                <path
                    id="fullLogo-12"
                    d="M59.47 118.94C26.68 118.94 0 92.26 0 59.47S26.68 0 59.47 0s59.47 26.68 59.47 59.47-26.68 59.47-59.47 59.47zm0-111.31C30.88 7.62 7.62 30.88 7.62 59.47s23.26 51.84 51.84 51.84 51.84-23.26 51.84-51.84S88.05 7.62 59.47 7.62z"
                    fill={fillColor}
                ></path>
            </g>
        </svg>
    );
};
