import { CompositeApiKey, CurrentUserContext, StoredHashedApiKey } from "aderant-conflicts-models";
import { hash } from "./hash";
import { randomUUID } from "crypto";

export function generateKey(context: CurrentUserContext, uniqueKeyName: string): { encodedCompositeKey: string; keyToStore: StoredHashedApiKey } {
    const newKey = randomUUID();

    const compositeKey: CompositeApiKey = `${uniqueKeyName}:${newKey}:${context.currentUser.tenancy.uniqueName}`;
    const encodedCompositeKey = Buffer.from(compositeKey).toString("base64");

    const keyToStore: StoredHashedApiKey = {
        uniqueKeyName: uniqueKeyName,
        hashedKey: hash(newKey),
        uniqueTenantName: context.currentUser.tenancy.uniqueName,
        tenantId: context.currentUser.tenancy.id //use of "deprecated" field - deprecated to make sure it's not used for anything other than calling user management
        //                                         fine here because that's what it's needed for.
    };

    return { encodedCompositeKey, keyToStore };
}
