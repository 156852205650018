import { createHash } from "crypto";

export function hash(key: string): string {
    //note: If we were hashing passwords sha256 would not be a good choice - instead
    //something like pbkdf2 or scrypt would probably be appropriate.
    //However because we're always using full length GUIDs for these API keys instead of
    //potentially short passwords a brute force attack would be no easier than brute
    //forcing a GUID collision so using a faster hashing function is preferable so as to
    //not consume more resources on our end.
    //
    //Likewise salting would be a good idea for passwords, but also doesn't make sense for
    //random guids.
    return createHash("sha256").update(key).digest("hex");
}
