import {
    Audit,
    CreateHitCommentInput,
    DurableHitIdentifier,
    EditHitCommentInput,
    Entity,
    EntityIdentifier,
    Hit,
    HitComment,
    HitCommentCosmosId,
    HitIdentifier,
    QuickSearch,
    RequestTerm,
    SearchStatus,
    SearchSummary,
    SearchVersion,
    SearchVersionEdited,
    SearchVersionEtag,
    SearchVersionIdentifier,
    SearchVersionNew,
    SearchVersionUnedited
} from "aderant-conflicts-models";
import { History } from "history";
import { SearchUpdateDelta } from "services/storageService";
import { action, TypeConstant } from "typesafe-actions";
import uuid from "uuid";

function actionWithSearchActionId<T extends TypeConstant, P>(type: T, payload: P): { type: T; payload: P & { currentSearchActionId: string } } {
    return action(type, { ...payload, currentSearchActionId: uuid() });
}

export const SearchActionType = {
    SAVE_SEARCH: "SAVE_SEARCH",
    SAVE_SEARCH_SUCCESS: "SAVE_SEARCH_SUCCESS",
    SAVE_SEARCH_FAILURE: "SAVE_SEARCH_FAILURE",

    CLEAR_CURRENT_SEARCH: "CLEAR_CURRENT_SEARCH",
    FETCH_LATEST_SEARCH_VERSION: "FETCH_LATEST_SEARCH_VERSION",
    FETCH_LATEST_SEARCH_VERSION_SUCCESS: "FETCH_LATEST_SEARCH_VERSION_SUCCESS",
    FETCH_LATEST_SEARCH_VERSION_FAILURE: "FETCH_LATEST_SEARCH_VERSION_FAILURE",

    FETCH_SEARCH_SUMMARIES: "FETCH_SEARCH_SUMMARIES",
    FETCH_SEARCH_SUMMARIES_SUCCESS: "FETCH_SEARCH_SUMMARIES_SUCCESS",
    FETCH_SEARCH_SUMMARIES_FAILURE: "FETCH_SEARCH_SUMMARIES_FAILURE",
    UPDATE_SEARCH_SUMMARIES: "UPDATE_SEARCH_SUMMARIES",

    FETCH_SEARCH_SUMMARIES_BY_ID: "FETCH_SEARCH_SUMMARIES_BY_ID",
    FETCH_SEARCH_SUMMARIES_BY_ID_SUCCESS: "FETCH_SEARCH_SUMMARIES_BY_ID_SUCCESS",
    FETCH_SEARCH_SUMMARIES_BY_ID_FAILURE: "FETCH_SEARCH_SUMMARIES_BY_ID_FAILURE",

    ADD_SEARCH_COPY_FROM_ID: "ADD_SEARCH_COPY_FROM_ID",
    ADD_SEARCH_COPY_FROM_ID_SUCCESS: "ADD_SEARCH_COPY_FROM_ID_SUCCESS",
    ADD_SEARCH_COPY_FROM_ID_FAILURE: "ADD_SEARCH_COPY_FROM_ID_FAILURE",
    CLOSE_COPY_FAILURE_DIALOG: "CLOSE_COPY_FAILURE_DIALOG",

    ADD_SEARCH: "ADD_SEARCH",
    ADD_SEARCH_SUCCESS: "ADD_SEARCH_SUCCESS",
    ADD_SEARCH_FAILURE: "ADD_SEARCH_FAILURE",

    DELETE_SEARCHES: "DELETE_SEARCHES",
    DELETE_SEARCHES_SUCCESS: "DELETE_SEARCHES_SUCCESS",
    DELETE_SEARCHES_FAILURE: "DELETE_SEARCHES_FAILURE",

    SEARCH_TERM: "SEARCH_TERM",
    SEARCH_TERM_SUCCESS: "SEARCH_TERM_SUCCESS",
    SEARCH_TERM_FAILURE: "SEARCH_TERM_FAILURE",

    IS_CURRENT_SEARCH_PENDING_CHANGES: "IS_CURRENT_SEARCH_PENDING_CHANGES",
    IS_CURRENT_SEARCH_SAVING: "IS_CURRENT_SEARCH_SAVING",
    IS_NEW_SEARCH: "IS_NEW_SEARCH",
    IS_SEARCHING: "IS_SEARCHING",

    PERFORM_SEARCH: "PERFORM_SEARCH",
    PERFORM_SEARCH_SUCCESS: "PERFORM_SEARCH_SUCCESS",
    PERFORM_SEARCH_FAILURE: "PERFORM_SEARCH_FAILURE",

    SUBMIT_SEARCH: "SUBMIT_SEARCH",
    SUBMIT_SEARCH_SUCCESS: "SUBMIT_SEARCH_SUCCESS",
    SUBMIT_SEARCH_FAILURE: "SUBMIT_SEARCH_FAILURE",

    ADD_TERM_THEN_PERFORM_SEARCH: "ADD_TERM_THEN_PERFORM_SEARCH",
    ADD_TERM_THEN_PERFORM_SEARCH_SUCCESS: "ADD_TERM_THEN_PERFORM_SEARCH_SUCCESS",
    ADD_TERM_THEN_PERFORM_SEARCH_FAILURE: "ADD_TERM_THEN_PERFORM_SEARCH_FAILURE",

    ADD_TERM_THEN_SUBMIT_SEARCH: "ADD_TERM_THEN_SUBMIT_SEARCH",
    ADD_TERM_THEN_SUBMIT_SEARCH_SUCCESS: "ADD_TERM_THEN_SUBMIT_SEARCH_SUCCESS",
    ADD_TERM_THEN_SUBMIT_SEARCH_FAILURE: "ADD_TERM_THEN_SUBMIT_SEARCH_FAILURE",

    FOCUS_HIT: "FOCUS_HIT",
    UPDATE_HITS: "UPDATE_HITS",
    UPDATE_HITS_SUCCESS: "UPDATE_HITS_SUCCESS",
    UPDATE_HITS_FAILURE: "UPDATE_HITS_FAILURE",

    UPDATE_SEARCHES_ASSIGNED_TO: "UPDATE_SEARCHES_ASSIGNED_TO",
    UPDATE_SEARCHES_ASSIGNED_TO_SUCCESS: "UPDATE_SEARCHES_ASSIGNED_TO_SUCCESS",
    UPDATE_SEARCHES_ASSIGNED_TO_FAILURE: "UPDATE_SEARCHES_ASSIGNED_TO_FAILURE",

    UPDATE_SEARCHES_SEARCH_STATUS: "UPDATE_SEARCHES_SEARCH_STATUS",
    UPDATE_SEARCHES_SEARCH_STATUS_SUCCESS: "UPDATE_SEARCHES_SEARCH_STATUS_SUCCESS",
    UPDATE_SEARCHES_SEARCH_STATUS_FAILURE: "UPDATE_SEARCHES_SEARCH_STATUS_FAILURE",

    UPDATE_SEARCH: "UPDATE_SEARCH",
    UPDATE_SEARCH_SUCCESS: "UPDATE_SEARCH_SUCCESS",
    UPDATE_SEARCH_FAILURE: "UPDATE_SEARCH_FAILURE",

    FETCH_AUDITS: "FETCH_AUDITS",
    FETCH_AUDITS_SUCCESS: "FETCH_AUDITS_SUCCESS",
    FETCH_AUDITS_FAILURE: "FETCH_AUDITS_FAILURE",

    CLEAR_AUDITS: "CLEAR_AUDITS",
    FETCH_ENTITY_DETAILS: "FETCH_ENTITY_DETAILS",
    FETCH_ENTITY_DETAILS_SUCCESS: "FETCH_ENTITY_DETAILS_SUCCESS",
    FETCH_ENTITY_DETAILS_FAILURE: "FETCH_ENTITY_DETAILS_FAILURE",

    OPEN_RESULT_DETAIL_DIALOG: "OPEN_RESULT_DETAIL_DIALOG",
    CLOSE_RESULT_DETAIL_DIALOG: "CLOSE_RESULT_DETAIL_DIALOG",

    CREATE_NEW_SEARCH_VERSION: "CREATE_NEW_SEARCH_VERSION",
    CREATE_NEW_SEARCH_VERSION_SUCCESS: "CREATE_NEW_SEARCH_VERSION_SUCCESS",
    CREATE_NEW_SEARCH_VERSION_FAILURE: "CREATE_NEW_SEARCH_VERSION_FAILURE",

    CREATE_AND_PERFORM_NEW_SEARCH_VERSION: "CREATE_AND_PERFORM_NEW_SEARCH_VERSION",
    CREATE_AND_PERFORM_NEW_SEARCH_VERSION_SUCCESS: "CREATE_AND_PERFORM_NEW_SEARCH_VERSION_SUCCESS",
    CREATE_AND_PERFORM_NEW_SEARCH_VERSION_FAILURE: "CREATE_AND_PERFORM_NEW_SEARCH_VERSION_FAILURE",

    ADD_SEARCH_IDS_CURRENTLY_PROCESSING: "ADDSEARCHIDSCURRENTLYPROCESSING",
    REMOVE_SEARCH_IDS_CURRENTLY_PROCESSING: "REMOVESEARCHIDSCURRENTLYPROCESSING",

    CLEAN_UP_SEARCHING_SEARCHES: "CLEANUPSEARCHINGSEARCHES",
    CLEAN_UP_SEARCHING_SEARCHES_SUCCESS: "CLEANUPSEARCHINGSEARCHESSUCCESS",

    //Quick Search only action types
    SAVE_QUICK_SEARCH_SUCCESS: "SAVE_QUICK_SEARCH_SUCCESS",
    PERFORM_QUICK_SEARCH: "PERFORM_QUICK_SEARCH",
    PERFORM_QUICK_SEARCH_SUCCESS: "PERFORM_QUICK_SEARCH_SUCCESS",
    PERFORM_QUICK_SEARCH_FAILURE: "PERFORM_QUICK_SEARCH_FAILURE",
    PERFORM_QUICK_SEARCH_AGAIN: "PERFORM_QUICK_SEARCH_AGAIN",
    PERFORM_QUICK_SEARCH_AGAIN_FAILURE: "PERFORM_QUICK_SEARCH_AGAIN_FAILURE",

    CONVERT_TO_FULL_SEARCH: "CONVERT_TO_FULL_SEARCH",
    CONVERT_TO_FULL_SEARCH_SUCCESS: "CONVERT_TO_FULL_SEARCH_SUCCESS",
    CONVERT_TO_FULL_SEARCH_FAILURE: "CONVERT_TO_FULL_SEARCH_FAILURE",

    CLEAR_SEARCH_FOR_REPERFORMING: "CLEAR_SEARCH_FOR_REPERFORMING",

    FETCH_HIT_COMMENTS: "FETCH_HIT_COMMENTS",
    FETCH_HIT_COMMENTS_SUCCESS: "FETCH_HIT_COMMENTS_SUCCESS",
    FETCH_HIT_COMMENTS_FAILURE: "FETCH_HIT_COMMENTS_FAILURE",
    CREATE_HIT_COMMENT: "CREATE_HIT_COMMENT",
    CREATE_HIT_COMMENT_SUCCESS: "CREATE_HIT_COMMENT_SUCCESS",
    CREATE_HIT_COMMENT_FAILURE: "CREATE_HIT_COMMENT_FAILURE",
    EDIT_HIT_COMMENT: "EDIT_HIT_COMMENT",
    EDIT_HIT_COMMENT_SUCCESS: "EDIT_HIT_COMMENT_SUCCESS",
    DELETE_HIT_COMMENT: "DELETE_HIT_COMMENT",
    DELETE_HIT_COMMENT_SUCCESS: "DELETE_HIT_COMMENT_SUCCESS",
    UPDATE_HIT_COMMENT_FAILURE: "UPDATE_HIT_COMMENT_FAILURE",

    SEARCH_ACTION_FAILURE: "SEARCH_ACTION_FAILURE"
} as const;

export const SaveSearchActionTypes = {
    ADD_TERM: "ADD_TERM",
    ADD_TERMS: "ADD_TERMS",
    UPDATE_DETAILS: "UPDATE_DETAILS",
    DELETE_TERM: "DELETE_TERM",
    UPDATE_TERM: "UPDATE_TERM"
} as const;

export type SaveSearchActionInputs =
    | {
          actionType: typeof SaveSearchActionTypes.ADD_TERM;
          payload: { searchVersion: SearchVersion | SearchVersionNew | QuickSearch; term: RequestTerm };
      }
    | {
          actionType: typeof SaveSearchActionTypes.ADD_TERMS;
          payload: { searchVersion: SearchVersion | SearchVersionNew; terms: RequestTerm[] };
      }
    | {
          actionType: typeof SaveSearchActionTypes.UPDATE_DETAILS;
          payload: { searchVersion: SearchVersion | SearchVersionNew; reassignMessage?: string };
      }
    | {
          actionType: typeof SaveSearchActionTypes.DELETE_TERM;
          payload: { searchVersion: SearchVersion | SearchVersionNew | QuickSearch; termId: string };
      }
    | {
          actionType: typeof SaveSearchActionTypes.UPDATE_TERM;
          payload: { searchVersion: SearchVersion | SearchVersionNew | QuickSearch; term: RequestTerm };
      };

export const searchActions = {
    // #region FULL search only actions
    saveSearch: (payload: {
        saveSearchActionInput: SaveSearchActionInputs;
        doNotPersist?: boolean;
    }): { type: "SAVE_SEARCH"; payload: { saveSearchActionInput: SaveSearchActionInputs; doNotPersist?: boolean | undefined } & { currentSearchActionId: string } } =>
        actionWithSearchActionId(SearchActionType.SAVE_SEARCH, payload),
    saveSearchSuccess: (payload: {
        searchVersion: SearchVersionUnedited;
        initialSearchActionId: string;
    }): { type: "SAVE_SEARCH_SUCCESS"; payload: { searchVersion: SearchVersionUnedited; initialSearchActionId: string } } => action(SearchActionType.SAVE_SEARCH_SUCCESS, payload),
    saveSearchFailure: (saveSearchActionType: string, error: string): { type: "SAVE_SEARCH_FAILURE"; payload: string } =>
        action(SearchActionType.SAVE_SEARCH_FAILURE, saveSearchActionType + ": " + error),

    clearCurrentSearch: (): { type: "CLEAR_CURRENT_SEARCH" } => action(SearchActionType.CLEAR_CURRENT_SEARCH),
    searchActionFailure: (searchActionType: string, error: string): { type: "SEARCH_ACTION_FAILURE"; payload: string } =>
        action(SearchActionType.SEARCH_ACTION_FAILURE, searchActionType + ": " + error),

    fetchSearchSummaries: (): { type: "FETCH_SEARCH_SUMMARIES" } => action(SearchActionType.FETCH_SEARCH_SUMMARIES),
    fetchSearchSummariesSuccess: (searches: SearchSummary[]): { type: "FETCH_SEARCH_SUMMARIES_SUCCESS"; payload: SearchSummary[] } => action(SearchActionType.FETCH_SEARCH_SUMMARIES_SUCCESS, searches),
    fetchSearchSummariesFailure: (error: string): { type: "FETCH_SEARCH_SUMMARIES_FAILURE"; payload: string } => action(SearchActionType.FETCH_SEARCH_SUMMARIES_FAILURE, error),
    updateSearchSummaries: (search: SearchVersion | SearchVersionNew | QuickSearch): { type: "UPDATE_SEARCH_SUMMARIES"; payload: SearchVersion | SearchVersionNew | QuickSearch } =>
        action(SearchActionType.UPDATE_SEARCH_SUMMARIES, search),

    fetchSearchSummariesById: (payload: { searchIds: string[] }): { type: "FETCH_SEARCH_SUMMARIES_BY_ID"; payload: { searchIds: string[] } } =>
        action(SearchActionType.FETCH_SEARCH_SUMMARIES_BY_ID, payload),
    fetchSearchSummariesByIdSuccess: (searches: SearchSummary[]): { type: "FETCH_SEARCH_SUMMARIES_BY_ID_SUCCESS"; payload: SearchSummary[] } =>
        action(SearchActionType.FETCH_SEARCH_SUMMARIES_BY_ID_SUCCESS, searches),
    fetchSearchSummariesByIdFailure: (error: string): { type: "FETCH_SEARCH_SUMMARIES_BY_ID_FAILURE"; payload: string } => action(SearchActionType.FETCH_SEARCH_SUMMARIES_BY_ID_FAILURE, error),

    addSearchCopyFromId: (payload: {
        currentSearchId: string;
        newSearchId: string;
        history: History;
        setAsCurrentSearch?: boolean;
    }): {
        type: "ADD_SEARCH_COPY_FROM_ID";
        payload: { currentSearchId: string; newSearchId: string; history: History; setAsCurrentSearch?: boolean | undefined } & { currentSearchActionId: string };
    } => actionWithSearchActionId(SearchActionType.ADD_SEARCH_COPY_FROM_ID, payload),
    addSearchCopyFromIdSuccess: (payload: {
        newSearchVersion: SearchVersionNew | SearchVersionUnedited;
        initialSearchActionId: string;
    }): { type: "ADD_SEARCH_COPY_FROM_ID_SUCCESS"; payload: { newSearchVersion: SearchVersionNew | SearchVersionUnedited; initialSearchActionId: string } } =>
        action(SearchActionType.ADD_SEARCH_COPY_FROM_ID_SUCCESS, payload),
    addSearchCopyFromIdFailure: (payload: {
        originalSearchVersion: SearchVersion;
        error: string;
    }): { type: "ADD_SEARCH_COPY_FROM_ID_FAILURE"; payload: { originalSearchVersion: SearchVersion; error: string } } => action(SearchActionType.ADD_SEARCH_COPY_FROM_ID_FAILURE, payload),
    closeCopyFailureDialog: (): { type: "CLOSE_COPY_FAILURE_DIALOG" } => action(SearchActionType.CLOSE_COPY_FAILURE_DIALOG),

    deleteSearches: (payload: {
        searchVersionIdentifiers: SearchVersionIdentifier[];
    }): { type: "DELETE_SEARCHES"; payload: { searchVersionIdentifiers: SearchVersionIdentifier[] } & { currentSearchActionId: string } } =>
        actionWithSearchActionId(SearchActionType.DELETE_SEARCHES, payload),
    deleteSearchesSuccess: (searches: string[]): { type: "DELETE_SEARCHES_SUCCESS"; payload: string[] } => action(SearchActionType.DELETE_SEARCHES_SUCCESS, searches),
    deleteSearchesFailure: (error: string): { type: "DELETE_SEARCHES_FAILURE"; payload: string } => action(SearchActionType.DELETE_SEARCHES_FAILURE, error),

    isCurrentSearchPendingChanges: (isCurrentSearchPendingChanges: boolean): { type: "IS_CURRENT_SEARCH_PENDING_CHANGES"; payload: boolean } =>
        action(SearchActionType.IS_CURRENT_SEARCH_PENDING_CHANGES, isCurrentSearchPendingChanges),
    isCurrentSearchSaving: (isCurrentSearchSaving: boolean): { type: "IS_CURRENT_SEARCH_SAVING"; payload: boolean } => action(SearchActionType.IS_CURRENT_SEARCH_SAVING, isCurrentSearchSaving),
    isSearching: (isSearching: boolean): { type: "IS_SEARCHING"; payload: boolean } => action(SearchActionType.IS_SEARCHING, isSearching),
    isNewSearch: (isNewSearch: boolean): { type: "IS_NEW_SEARCH"; payload: boolean } => action(SearchActionType.IS_NEW_SEARCH, isNewSearch),

    performSearch: (payload: {
        searchVersion: SearchVersion | SearchVersionNew;
        history: History;
        updateIsSearchingWhenDone?: boolean;
    }): {
        type: "PERFORM_SEARCH";
        payload: { searchVersion: SearchVersion | SearchVersionNew; history: History; updateIsSearchingWhenDone?: boolean | undefined } & { currentSearchActionId: string };
    } => actionWithSearchActionId(SearchActionType.PERFORM_SEARCH, payload),
    performSearchSuccess: (searchVersion: SearchVersionUnedited): { type: "PERFORM_SEARCH_SUCCESS"; payload: SearchVersionUnedited } => action(SearchActionType.PERFORM_SEARCH_SUCCESS, searchVersion),
    performSearchFailure: (error: string): { type: "PERFORM_SEARCH_FAILURE"; payload: string } => action(SearchActionType.PERFORM_SEARCH_FAILURE, error),

    submitSearch: (payload: {
        searchVersion: SearchVersion | SearchVersionNew;
        history: History;
    }): { type: "SUBMIT_SEARCH"; payload: { searchVersion: SearchVersion | SearchVersionNew; history: History } & { currentSearchActionId: string } } =>
        actionWithSearchActionId(SearchActionType.SUBMIT_SEARCH, payload),
    submitSearchSuccess: (searchVersion: SearchVersionUnedited): { type: "SUBMIT_SEARCH_SUCCESS"; payload: SearchVersionUnedited } => action(SearchActionType.SUBMIT_SEARCH_SUCCESS, searchVersion),
    submitSearchFailure: (error: string): { type: "SUBMIT_SEARCH_FAILURE"; payload: string } => action(SearchActionType.SUBMIT_SEARCH_FAILURE, error),

    addTermThenPerformSearch: (payload: {
        searchVersion: SearchVersion | SearchVersionNew;
        history: History;
        requestTerm: RequestTerm;
        updateIsSearchingWhenDone?: boolean;
    }): {
        type: "ADD_TERM_THEN_PERFORM_SEARCH";
        payload: { searchVersion: SearchVersion | SearchVersionNew; history: History; requestTerm: RequestTerm; updateIsSearchingWhenDone?: boolean | undefined } & {
            currentSearchActionId: string;
        };
    } => actionWithSearchActionId(SearchActionType.ADD_TERM_THEN_PERFORM_SEARCH, payload),
    addTermThenPerformSearchSuccess: (searchVersion: SearchVersionUnedited): { type: "ADD_TERM_THEN_PERFORM_SEARCH_SUCCESS"; payload: SearchVersionUnedited } =>
        action(SearchActionType.ADD_TERM_THEN_PERFORM_SEARCH_SUCCESS, searchVersion),
    addTermThenPerformSearchFailure: (error: string): { type: "ADD_TERM_THEN_PERFORM_SEARCH_FAILURE"; payload: string } => action(SearchActionType.ADD_TERM_THEN_PERFORM_SEARCH_FAILURE, error),

    addTermThenSubmitSearch: (payload: {
        searchVersion: SearchVersion | SearchVersionNew;
        history: History;
        requestTerm: RequestTerm;
    }): {
        type: "ADD_TERM_THEN_SUBMIT_SEARCH";
        payload: { searchVersion: SearchVersion | SearchVersionNew; history: History; requestTerm: RequestTerm } & { currentSearchActionId: string };
    } => actionWithSearchActionId(SearchActionType.ADD_TERM_THEN_SUBMIT_SEARCH, payload),
    addTermThenSubmitSearchSuccess: (searchVersion: SearchVersionUnedited): { type: "ADD_TERM_THEN_SUBMIT_SEARCH_SUCCESS"; payload: SearchVersionUnedited } =>
        action(SearchActionType.ADD_TERM_THEN_SUBMIT_SEARCH_SUCCESS, searchVersion),
    addTermThenSubmitSearchFailure: (error: string): { type: "ADD_TERM_THEN_SUBMIT_SEARCH_FAILURE"; payload: string } => action(SearchActionType.ADD_TERM_THEN_SUBMIT_SEARCH_FAILURE, error),
    focusHit: (payload: SearchVersionIdentifier & { hitIdentifier: DurableHitIdentifier }): { type: "FOCUS_HIT"; payload: SearchVersionIdentifier & { hitIdentifier: DurableHitIdentifier } } =>
        actionWithSearchActionId(SearchActionType.FOCUS_HIT, payload),
    updateHits: (
        payload: SearchVersionIdentifier & SearchVersionEtag & { hitIds: HitIdentifier[]; change: Partial<Hit>; onComplete: () => void }
    ): { type: "UPDATE_HITS"; payload: SearchVersionIdentifier & SearchVersionEtag & { hitIds: HitIdentifier[]; change: Partial<Hit>; onComplete: () => void } & { currentSearchActionId: string } } =>
        actionWithSearchActionId(SearchActionType.UPDATE_HITS, payload),
    updateHitsSuccess: (payload: {
        searchVersion: SearchVersionUnedited;
        initialSearchActionId: string;
    }): { type: "UPDATE_HITS_SUCCESS"; payload: { searchVersion: SearchVersionUnedited; initialSearchActionId: string } } => action(SearchActionType.UPDATE_HITS_SUCCESS, payload),
    updateHitsFailure: (error: string): { type: "UPDATE_HITS_FAILURE"; payload: string } => action(SearchActionType.UPDATE_HITS_FAILURE, error),
    updateSearchesAssignedTo: (payload: {
        searchVersionIdentifiers: (SearchVersionIdentifier & SearchVersionEtag)[];
        change: Pick<SearchVersion, "assignedToUserId"> & { message?: string };
    }): {
        type: "UPDATE_SEARCHES_ASSIGNED_TO";
        payload: { searchVersionIdentifiers: (SearchVersionIdentifier & SearchVersionEtag)[]; change: Pick<SearchVersion, "assignedToUserId"> & { message?: string } } & {
            currentSearchActionId: string;
        };
    } => actionWithSearchActionId(SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO, payload),
    updateSearchesAssignedToSuccess: (payload: {
        versionIds: { versionId: string; etag?: string }[];
        change: Pick<SearchVersion, "assignedToUserId"> & { message?: string };
        initialSearchActionId: string;
    }): {
        type: "UPDATE_SEARCHES_ASSIGNED_TO_SUCCESS";
        payload: { versionIds: { versionId: string; etag?: string }[]; change: Pick<SearchVersion, "assignedToUserId"> & { message?: string }; initialSearchActionId: string };
    } => action(SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO_SUCCESS, payload),
    updateSearchesAssignedToFailure: (error: string): { type: "UPDATE_SEARCHES_ASSIGNED_TO_FAILURE"; payload: string } => action(SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO_FAILURE, error),

    updateSearchesSearchStatus: (payload: {
        searchVersionIdentifiers: (SearchVersionIdentifier & SearchVersionEtag)[];
        change: Pick<SearchVersion, "status">;
    }): {
        type: "UPDATE_SEARCHES_SEARCH_STATUS";
        payload: { searchVersionIdentifiers: (SearchVersionIdentifier & SearchVersionEtag)[]; change: Pick<SearchVersion, "status"> } & { currentSearchActionId: string };
    } => actionWithSearchActionId(SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS, payload),
    updateSearchesSearchStatusSuccess: (payload: {
        versionIds: { versionId: string; etag?: string }[];
        change: Pick<SearchVersion, "status">;
        initialSearchActionId: string;
    }): { type: "UPDATE_SEARCHES_SEARCH_STATUS_SUCCESS"; payload: { versionIds: { versionId: string; etag?: string }[]; change: Pick<SearchVersion, "status">; initialSearchActionId: string } } =>
        action(SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS_SUCCESS, payload),
    updateSearchesSearchStatusFailure: (error: string): { type: "UPDATE_SEARCHES_SEARCH_STATUS_FAILURE"; payload: string } => action(SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS_FAILURE, error),

    updateSearch: (
        payload: SearchVersionEtag & { searchVersionIdentifier: SearchVersionIdentifier; change: SearchUpdateDelta }
    ): { type: "UPDATE_SEARCH"; payload: SearchVersionEtag & { searchVersionIdentifier: SearchVersionIdentifier; change: SearchUpdateDelta } & { currentSearchActionId: string } } =>
        actionWithSearchActionId(SearchActionType.UPDATE_SEARCH, payload),
    updateSearchSuccess: (payload: {
        versionId: { id: string; etag?: string };
        change: SearchUpdateDelta;
        initialSearchActionId: string;
    }): {
        type: "UPDATE_SEARCH_SUCCESS";
        payload: {
            versionId: {
                id: string;
                etag?: string;
            };
            change: SearchUpdateDelta;
            initialSearchActionId: string;
        };
    } => action(SearchActionType.UPDATE_SEARCH_SUCCESS, payload),
    updateSearchFailure: (error: string): { type: "UPDATE_SEARCH_FAILURE"; payload: string } => action(SearchActionType.UPDATE_SEARCH_FAILURE, error),

    fetchAudits: (payload: { searchVersionId: string }): { type: "FETCH_AUDITS"; payload: { searchVersionId: string } & { currentSearchActionId: string } } =>
        actionWithSearchActionId(SearchActionType.FETCH_AUDITS, payload),
    fetchAuditsSuccess: (audits: Audit[]): { type: "FETCH_AUDITS_SUCCESS"; payload: Audit[] } => action(SearchActionType.FETCH_AUDITS_SUCCESS, audits),
    fetchAuditsFailure: (error: string): { type: "FETCH_AUDITS_FAILURE"; payload: string } => action(SearchActionType.FETCH_AUDITS_FAILURE, error),
    clearAudits: () => action(SearchActionType.CLEAR_AUDITS),
    fetchEntityDetails: (payload: EntityIdentifier): { type: "FETCH_ENTITY_DETAILS"; payload: EntityIdentifier } => action(SearchActionType.FETCH_ENTITY_DETAILS, payload),
    fetchEntityDetailsSuccess: (payload: { entity: Entity }): { type: "FETCH_ENTITY_DETAILS_SUCCESS"; payload: { entity: Entity } } => action(SearchActionType.FETCH_ENTITY_DETAILS_SUCCESS, payload),
    fetchEntityDetailsFailure: (payload: { error: string; requestedId: string }): { type: "FETCH_ENTITY_DETAILS_FAILURE"; payload: { error: string; requestedId: string } } =>
        action(SearchActionType.FETCH_ENTITY_DETAILS_FAILURE, payload),

    openResultDetailDialog: (): { type: "OPEN_RESULT_DETAIL_DIALOG" } => action(SearchActionType.OPEN_RESULT_DETAIL_DIALOG),
    closeResultDetailDialog: (): { type: "CLOSE_RESULT_DETAIL_DIALOG" } => action(SearchActionType.CLOSE_RESULT_DETAIL_DIALOG),

    createNewSearchVersion: (payload: { searchVersion: SearchVersionEdited }): { type: "CREATE_NEW_SEARCH_VERSION"; payload: { searchVersion: SearchVersionEdited } } =>
        action(SearchActionType.CREATE_NEW_SEARCH_VERSION, payload),
    createNewSearchVersionSuccess: (searchVersion: SearchVersionUnedited): { type: "CREATE_NEW_SEARCH_VERSION_SUCCESS"; payload: SearchVersionUnedited } =>
        action(SearchActionType.CREATE_NEW_SEARCH_VERSION_SUCCESS, searchVersion),
    createNewSearchVersionFailure: (error: string): { type: "CREATE_NEW_SEARCH_VERSION_FAILURE"; payload: string } => action(SearchActionType.CREATE_NEW_SEARCH_VERSION_FAILURE, error),

    createAndPerformNewVersion: (payload: {
        searchVersion: SearchVersionEdited;
        history: History;
        updateIsSearchingWhenDone?: boolean;
    }): { type: "CREATE_AND_PERFORM_NEW_SEARCH_VERSION"; payload: { searchVersion: SearchVersionEdited; history: History; updateIsSearchingWhenDone?: boolean | undefined } } =>
        action(SearchActionType.CREATE_AND_PERFORM_NEW_SEARCH_VERSION, payload),
    createAndPerformNewVersionSuccess: (searchVersion: SearchVersionUnedited): { type: "CREATE_AND_PERFORM_NEW_SEARCH_VERSION_SUCCESS"; payload: SearchVersionUnedited } =>
        action(SearchActionType.CREATE_AND_PERFORM_NEW_SEARCH_VERSION_SUCCESS, searchVersion),
    createAndPerformNewVersionFailure: (error: string): { type: "CREATE_AND_PERFORM_NEW_SEARCH_VERSION_FAILURE"; payload: string } =>
        action(SearchActionType.CREATE_AND_PERFORM_NEW_SEARCH_VERSION_FAILURE, error),

    addSearchIdsCurrentlyProcessing: (searchIds: string[]): { type: "ADDSEARCHIDSCURRENTLYPROCESSING"; payload: string[] } => action(SearchActionType.ADD_SEARCH_IDS_CURRENTLY_PROCESSING, searchIds),
    removeSearchIdsCurrentlyProcessing: (searchIds: string[]): { type: "REMOVESEARCHIDSCURRENTLYPROCESSING"; payload: string[] } =>
        action(SearchActionType.REMOVE_SEARCH_IDS_CURRENTLY_PROCESSING, searchIds),

    cleanUpSearchingSearches: (payload: {
        searchSummariesInSearching: SearchSummary[];
        previousStatus: SearchStatus;
    }): { type: "CLEANUPSEARCHINGSEARCHES"; payload: { searchSummariesInSearching: SearchSummary[]; previousStatus: SearchStatus } } => action(SearchActionType.CLEAN_UP_SEARCHING_SEARCHES, payload),
    cleanUpSearchingSearchesSuccess: (payload: {
        versionIds: { versionId: string; etag?: string }[];
        change: Pick<SearchVersion, "status">;
    }): { type: "CLEANUPSEARCHINGSEARCHESSUCCESS"; payload: { versionIds: { versionId: string; etag?: string }[]; change: Pick<SearchVersion, "status"> } } =>
        action(SearchActionType.CLEAN_UP_SEARCHING_SEARCHES_SUCCESS, payload),
    // #endregion

    // #region FULL & QUICK search actions
    fetchLatestSearchVersion: (payload: {
        searchId: string;
        fetchHitResults: boolean;
    }): { type: "FETCH_LATEST_SEARCH_VERSION"; payload: { searchId: string; fetchHitResults: boolean } & { currentSearchActionId: string } } =>
        actionWithSearchActionId(SearchActionType.FETCH_LATEST_SEARCH_VERSION, payload),
    fetchLatestSearchVersionSuccess: (searchVersion: SearchVersionUnedited | QuickSearch): { type: "FETCH_LATEST_SEARCH_VERSION_SUCCESS"; payload: SearchVersionUnedited | QuickSearch } =>
        action(SearchActionType.FETCH_LATEST_SEARCH_VERSION_SUCCESS, searchVersion),
    fetchLatestSearchVersionFailure: (payload: { error: string; searchId: string }): { type: "FETCH_LATEST_SEARCH_VERSION_FAILURE"; payload: { error: string; searchId: string } } =>
        action(SearchActionType.FETCH_LATEST_SEARCH_VERSION_FAILURE, payload),

    addSearch: (
        payload:
            | {
                  newSearchVersion: QuickSearch;
                  setAsCurrentSearch?: boolean;
                  doNotPersist: true;
              }
            | {
                  newSearchVersion: SearchVersionNew;
                  setAsCurrentSearch?: boolean;
                  doNotPersist?: boolean;
              }
    ): { type: "ADD_SEARCH"; payload: { newSearchVersion: SearchVersionNew | QuickSearch; setAsCurrentSearch?: boolean | undefined } & { currentSearchActionId: string } } =>
        actionWithSearchActionId(SearchActionType.ADD_SEARCH, payload),
    addSearchSuccess: (payload: {
        newSearchVersion: SearchVersionUnedited;
        initialSearchActionId: string;
    }): { type: "ADD_SEARCH_SUCCESS"; payload: { newSearchVersion: SearchVersionUnedited; initialSearchActionId: string } } => action(SearchActionType.ADD_SEARCH_SUCCESS, payload),
    addSearchFailure: (error: string): { type: "ADD_SEARCH_FAILURE"; payload: string } => action(SearchActionType.ADD_SEARCH_FAILURE, error),
    // #endregion

    // #region QUICK SEARCH only actions
    performQuickSearch: (payload: {
        quickSearch: QuickSearch;
        history: History;
        updateIsSearchingWhenDone?: boolean;
    }): {
        type: "PERFORM_QUICK_SEARCH";
        payload: { quickSearch: QuickSearch; history: History; updateIsSearchingWhenDone?: boolean | undefined } & { currentSearchActionId: string };
    } => actionWithSearchActionId(SearchActionType.PERFORM_QUICK_SEARCH, payload),

    performQuickSearchSuccess: (quickSearch: QuickSearch): { type: "PERFORM_QUICK_SEARCH_SUCCESS"; payload: QuickSearch } => action(SearchActionType.PERFORM_QUICK_SEARCH_SUCCESS, quickSearch),
    performQuickSearchFailure: (error: string): { type: "PERFORM_QUICK_SEARCH_FAILURE"; payload: string } => action(SearchActionType.PERFORM_QUICK_SEARCH_FAILURE, error),
    performQuickSearchAgain: (payload: {
        searchId: SearchVersionIdentifier;
        previousStatus: SearchStatus;
        history: History;
    }): { type: "PERFORM_QUICK_SEARCH_AGAIN"; payload: { searchId: SearchVersionIdentifier; previousStatus: SearchStatus; history: History } } =>
        action(SearchActionType.PERFORM_QUICK_SEARCH_AGAIN, payload),
    saveQuickSearchSuccess: (quickSearch: QuickSearch): { type: "SAVE_QUICK_SEARCH_SUCCESS"; payload: QuickSearch } => action(SearchActionType.SAVE_QUICK_SEARCH_SUCCESS, quickSearch),
    performQuickSearchAgainFailure: (payload: {
        searchId: SearchVersionIdentifier;
        previousStatus: SearchStatus;
        error: string;
    }): { type: "PERFORM_QUICK_SEARCH_AGAIN_FAILURE"; payload: { searchId: SearchVersionIdentifier; previousStatus: SearchStatus; error: string } } =>
        action(SearchActionType.PERFORM_QUICK_SEARCH_AGAIN_FAILURE, payload),

    convertToSearchRequest: (payload: { quickSearchId: string; newSearchId: string; history: History }) => action(SearchActionType.CONVERT_TO_FULL_SEARCH, payload),
    convertToSearchRequestSuccess: (payload: { quickSearchId: string; newSearchRequest: SearchVersionUnedited }) => action(SearchActionType.CONVERT_TO_FULL_SEARCH_SUCCESS, payload),
    convertToSearchRequestFailure: (error: string) => action(SearchActionType.CONVERT_TO_FULL_SEARCH_FAILURE, error),
    clearSearchForReperforming: (payload: { searchVersion: QuickSearch }): { type: "CLEAR_SEARCH_FOR_REPERFORMING"; payload: { searchVersion: QuickSearch } } =>
        action(SearchActionType.CLEAR_SEARCH_FOR_REPERFORMING, payload),

    fetchHitComments: (payload: { searchId: string; hitIdentifier: DurableHitIdentifier }): { type: "FETCH_HIT_COMMENTS"; payload: { searchId: string; hitIdentifier: DurableHitIdentifier } } =>
        action(SearchActionType.FETCH_HIT_COMMENTS, payload),
    fetchHitCommentsSuccess: (payload: {
        hitIdentifier: DurableHitIdentifier;
        hitComments: HitComment[];
    }): { type: "FETCH_HIT_COMMENTS_SUCCESS"; payload: { hitIdentifier: DurableHitIdentifier; hitComments: HitComment[] } } => action(SearchActionType.FETCH_HIT_COMMENTS_SUCCESS, payload),
    fetchHitCommentsFailure: (payload: {
        durableHitIdentifier: DurableHitIdentifier;
        error: string;
    }): { type: "FETCH_HIT_COMMENTS_FAILURE"; payload: { durableHitIdentifier: DurableHitIdentifier; error: string } } => action(SearchActionType.FETCH_HIT_COMMENTS_FAILURE, payload),
    createHitComment: (payload: { hitComment: CreateHitCommentInput }): { type: "CREATE_HIT_COMMENT"; payload: { hitComment: CreateHitCommentInput } } =>
        action(SearchActionType.CREATE_HIT_COMMENT, payload),
    createHitCommentSuccess: (payload: { hitComment: HitComment }): { type: "CREATE_HIT_COMMENT_SUCCESS"; payload: { hitComment: HitComment } } =>
        action(SearchActionType.CREATE_HIT_COMMENT_SUCCESS, payload),
    createHitCommentFailure: (payload: {
        durableHitIdentifier: DurableHitIdentifier;
        hitCommentText: string;
        error: string;
    }): { type: "CREATE_HIT_COMMENT_FAILURE"; payload: { durableHitIdentifier: DurableHitIdentifier; hitCommentText: string; error: string } } =>
        action(SearchActionType.CREATE_HIT_COMMENT_FAILURE, payload),
    editHitComment: (payload: {
        editHitCommentInput: EditHitCommentInput;
        durableHitIdentifier: DurableHitIdentifier;
    }): { type: "EDIT_HIT_COMMENT"; payload: { editHitCommentInput: EditHitCommentInput; durableHitIdentifier: DurableHitIdentifier } } => action(SearchActionType.EDIT_HIT_COMMENT, payload),
    editHitCommentSuccess: (payload: { hitComment: HitComment }): { type: "EDIT_HIT_COMMENT_SUCCESS"; payload: { hitComment: HitComment } } =>
        action(SearchActionType.EDIT_HIT_COMMENT_SUCCESS, payload),
    deleteHitComment: (payload: {
        hitCommentCosmosId: HitCommentCosmosId;
        durableHitIdentifier: DurableHitIdentifier;
    }): { type: "DELETE_HIT_COMMENT"; payload: { hitCommentCosmosId: HitCommentCosmosId; durableHitIdentifier: DurableHitIdentifier } } => action(SearchActionType.DELETE_HIT_COMMENT, payload),
    deleteHitCommentSuccess: (payload: {
        hitCommentCosmosId: HitCommentCosmosId;
        durableHitIdentifier: DurableHitIdentifier;
    }): { type: "DELETE_HIT_COMMENT_SUCCESS"; payload: { hitCommentCosmosId: HitCommentCosmosId; durableHitIdentifier: DurableHitIdentifier } } =>
        action(SearchActionType.DELETE_HIT_COMMENT_SUCCESS, payload),
    updateHitCommentFailure: (payload: {
        durableHitIdentifier: DurableHitIdentifier;
        hitCommentId: string;
        error: string;
    }): { type: "UPDATE_HIT_COMMENT_FAILURE"; payload: { durableHitIdentifier: DurableHitIdentifier; hitCommentId: string; error: string } } =>
        action(SearchActionType.UPDATE_HIT_COMMENT_FAILURE, payload)
    // #endregion
};
