import { Typography } from "@mui/material";
import { MAX_CHARACTERS_PER_TERM } from "aderant-conflicts-models";
import { messages } from "pages/SearchEditPage/SearchUtils";
import React from "react";
import { conflictsPalette } from "styles/conflictsPalette";

export type ImportTermsWarning =
    | { type: "duplicateTerms"; terms: string[] }
    | { type: "invalidAffiliations"; requestTerms: string[] }
    | { type: "duplicateAffiliations"; requestTerms: string[] }
    | { type: "invalidPartyStatuses"; requestTerms: string[] }
    | { type: "duplicatePartyStatuses"; requestTerms: string[] }
    | { type: "termsTooLong"; numberOfTerms: number }
    | { type: "invalidRequestTerms"; requestTerms: string[] }
    | { type: "invalidSearchTerms"; terms: string[] };

export function renderWarnings(warnings: ImportTermsWarning[], termsTemplate: string) {
    return warnings.map((w) => renderWarning(w, termsTemplate));
}

function renderWarning(warning: ImportTermsWarning, termsTemplate: string) {
    switch (warning.type) {
        case "duplicateTerms":
            return DuplicateTermsWarning(warning.terms);
        case "invalidAffiliations":
            return AffiliationWarning(warning.requestTerms);
        case "duplicateAffiliations":
            return DuplicateAffiliationWarning(warning.requestTerms);
        case "invalidPartyStatuses":
            return PartyStatusWarning(warning.requestTerms);
        case "duplicatePartyStatuses":
            return DuplicatePartyStatusWarning(warning.requestTerms);
        case "termsTooLong":
            return TooManyCharactersWarning(warning.numberOfTerms, termsTemplate);
        // we probably care about which terms are invalid, but since we are currently only validating for * we will just leave this as is
        case "invalidSearchTerms":
            return InvalidSearchTermsWarning();
        case "invalidRequestTerms":
            return InvalidRequestTermsWarning();
        default:
            console.log("Invalid warning");
    }
}

function DuplicateTermsWarning(duplicateTerms: string[]) {
    const duplicateTermsString = duplicateTerms.join("; ");
    const characterLimit = 370;

    return (
        <div data-testid="DuplicateTermsWarning">
            <Typography style={{ color: conflictsPalette.text.black }}>
                {duplicateTerms.length === 1 ? (
                    <>
                        This <b>duplicate</b> Search Term
                    </>
                ) : (
                    <>
                        These <b>{duplicateTerms.length} duplicate</b> Search Terms
                    </>
                )}{" "}
                will <b>not</b> be imported:
            </Typography>
            <br />
            {duplicateTerms && (duplicateTermsString.length < characterLimit ? duplicateTermsString : duplicateTermsString.slice(0, characterLimit) + "...")}
            <br />
            <br />
        </div>
    );
}

function AffiliationWarning(incorrectAffiliationRequestTerms: string[]) {
    const requestTermsString = incorrectAffiliationRequestTerms.join("; ");
    const characterLimit = 370;

    return (
        <div data-testid="InvalidAffiliationWarning">
            <Typography style={{ color: conflictsPalette.text.black }}>An invalid affiliation has been removed from the following request terms:</Typography>
            <br />
            {incorrectAffiliationRequestTerms && (requestTermsString.length < characterLimit ? requestTermsString : requestTermsString.slice(0, characterLimit) + "...")}
            <br />
            <br />
        </div>
    );
}

function DuplicateAffiliationWarning(duplicateAffiliationRequestTerms: string[]) {
    const requestTermsString = duplicateAffiliationRequestTerms.join("; ");
    const characterLimit = 370;

    return (
        <div data-testid="DuplicateAffiliationWarning">
            <Typography style={{ color: conflictsPalette.text.black }}>
                An affiliation without a code has been removed because there is more than one available with the same label. The following request terms were affected:
            </Typography>
            <br />
            {duplicateAffiliationRequestTerms && (requestTermsString.length < characterLimit ? requestTermsString : requestTermsString.slice(0, characterLimit) + "...")}
            <br />
            <br />
        </div>
    );
}

function PartyStatusWarning(incorrectPartyStatusRequestTerms: string[]) {
    const requestTermsString = incorrectPartyStatusRequestTerms.join("; ");
    const characterLimit = 370;

    return (
        <div data-testid="InvalidPartyStatusWarning">
            <Typography style={{ color: conflictsPalette.text.black }}>An invalid party status has been removed from the following request terms:</Typography>
            <br />
            {incorrectPartyStatusRequestTerms && (requestTermsString.length < characterLimit ? requestTermsString : requestTermsString.slice(0, characterLimit) + "...")}
            <br />
            <br />
        </div>
    );
}

function DuplicatePartyStatusWarning(duplicatePartyStatusRequestTerms: string[]) {
    const requestTermsString = duplicatePartyStatusRequestTerms.join("; ");
    const characterLimit = 370;

    return (
        <div data-testid="DuplicatePartyStatusWarning">
            <Typography style={{ color: conflictsPalette.text.black }}>
                A party status without a code has been removed because there is more than one available with the same label. The following request terms were affected:
            </Typography>
            <br />
            {duplicatePartyStatusRequestTerms && (requestTermsString.length < characterLimit ? requestTermsString : requestTermsString.slice(0, characterLimit) + "...")}
            <br />
            <br />
        </div>
    );
}

function TooManyCharactersWarning(quantity: number, termsTemplate: string) {
    const isOne = quantity === 1;
    return (
        <div data-testid="TooManyCharactersWarning">
            <Typography style={{ color: conflictsPalette.text.black }}>
                {quantity} {termsTemplate === messages.requestTermsTemplate && <>request or </>}search {isOne ? <>term</> : <>terms</>} will not be imported because{" "}
                {isOne ? <>it is</> : <>they are</>} longer than the {MAX_CHARACTERS_PER_TERM} maximum character length.
            </Typography>
            <br />
            <br />
        </div>
    );
}

function InvalidSearchTermsWarning() {
    return (
        <div data-testid="InvalidTermsWarning">
            <Typography style={{ color: conflictsPalette.text.black }}>This file contains a search term "*" which is not allowed. Please upload a file without the term "*"</Typography>
            <br />
            <br />
        </div>
    );
}

function InvalidRequestTermsWarning() {
    return (
        <div data-testid="InvalidRequestTermsWarning">
            <Typography style={{ color: conflictsPalette.text.black }}>This file contains a request term "*" which is not allowed. Please upload a file without the term "*"</Typography>
            <br />
            <br />
        </div>
    );
}
