import { CurrentUserContext, MAX_KEY_NAME_LENGTH, MAX_NUMBER_OF_KEYS, notFound, NotFound, ok, Result } from "aderant-conflicts-models";
import { keyLimitReached, KeyLimitReached, keyNameNotUnique, KeyNameNotUnique, keyNameTooLong, KeyNameTooLong } from "../../APIs/Admin/Errors";
import { generateKey } from "./Internal/generateKey";
import { FunctionKeySecretConnector } from "./FunctionKeySecretConnector";

/**Gets a list of all key metadata for the current users tenant)
 * @returns an array of @type { uniqueKeyName: string }
 */
export async function getKeyMetadata(context: CurrentUserContext, secretConnector: FunctionKeySecretConnector = new FunctionKeySecretConnector(context.logger)): Promise<{ uniqueKeyName: string }[]> {
    const existingKeys = await secretConnector.getOrEmptyStoredFunctionKeyInfoForTenant(context.currentUser.tenancy.uniqueName, true);

    return existingKeys.keys
        .filter((k) => k.uniqueKeyName)
        .map((k) => {
            return { uniqueKeyName: k.uniqueKeyName };
        });
}

/**Creates a new key for the current tenant with the given name and returns the encoded unhashed key
 * (should be given to the end user and not stored anywhere by Conflicts.)
 * @returns {type string} in the format expected by public api auth.
 */
export async function createKey(
    context: CurrentUserContext,
    uniqueKeyName: string,
    secretConnector: FunctionKeySecretConnector = new FunctionKeySecretConnector(context.logger)
): Promise<Result<string, KeyNameNotUnique | KeyNameTooLong | KeyLimitReached>> {
    if (uniqueKeyName.length > MAX_KEY_NAME_LENGTH) {
        return keyNameTooLong(MAX_KEY_NAME_LENGTH);
    }

    const result = generateKey(context, uniqueKeyName);
    if (!ok(result)) {
        return result;
    }
    const { encodedCompositeKey, keyToStore } = result;

    const existingKeys = await secretConnector.getOrEmptyStoredFunctionKeyInfoForTenant(context.currentUser.tenancy.uniqueName, true);

    if (existingKeys.keys.length >= MAX_NUMBER_OF_KEYS) {
        return keyLimitReached(MAX_NUMBER_OF_KEYS);
    }

    if (existingKeys.keys.find((k) => k.uniqueKeyName === uniqueKeyName)) {
        return keyNameNotUnique(uniqueKeyName);
    }

    existingKeys.keys.push(keyToStore);
    await secretConnector.setStoredFunctionKeyInfoForTenant(context.currentUser.tenancy.uniqueName, existingKeys);

    return encodedCompositeKey;
}

/**Revokes the key for the current tenant with the provided uniqueKeyName
 * or returns NotFound if no key is found with that name.
 */
export async function revokeKey(
    context: CurrentUserContext,
    uniqueKeyName: string,
    secretConnector: FunctionKeySecretConnector = new FunctionKeySecretConnector(context.logger)
): Promise<Result<void, NotFound>> {
    const existingKeys = await secretConnector.getOrEmptyStoredFunctionKeyInfoForTenant(context.currentUser.tenancy.uniqueName, true);

    const newKeys = { ...existingKeys, keys: existingKeys.keys.filter((k) => k.uniqueKeyName !== uniqueKeyName) };

    if (existingKeys.keys.length > newKeys.keys.length) {
        //if we have less keys than we did before we must have found the one being deleted
        await secretConnector.setStoredFunctionKeyInfoForTenant(context.currentUser.tenancy.uniqueName, newKeys);
        return;
    } else {
        //we didn't find the key that was asked for, so do nothing and return NotFound
        return notFound();
    }
}

/**Revokes the current key with the provided uniqueKeyName, and generates a new key with the same name
 * or returns NotFound if no key is found with that name.
 */
export async function regenerateKey(
    context: CurrentUserContext,
    uniqueKeyName: string,
    secretConnector: FunctionKeySecretConnector = new FunctionKeySecretConnector(context.logger)
): Promise<Result<string, NotFound>> {
    const result = generateKey(context, uniqueKeyName);
    if (!ok(result)) {
        return result;
    }
    const { encodedCompositeKey, keyToStore } = result;

    const storedKeys = await secretConnector.getOrEmptyStoredFunctionKeyInfoForTenant(context.currentUser.tenancy.uniqueName, true);

    const indexToReplace = storedKeys.keys.findIndex((k) => k.uniqueKeyName === uniqueKeyName);
    if (indexToReplace < 0) {
        return notFound();
    }
    storedKeys.keys[indexToReplace] = keyToStore;

    await secretConnector.setStoredFunctionKeyInfoForTenant(context.currentUser.tenancy.uniqueName, storedKeys);

    return encodedCompositeKey;
}
