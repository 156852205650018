import { Message } from "aderant-conflicts-models";
import { HitTypesForTiles } from "./HitCountTiles";

export const Messages: Record<HitTypesForTiles, Message> = {
    UNRESOLVED: new Message("UNRESOLVED", "Unresolved"),
    SEEKINGWAIVER: new Message("SEEKING_WAIVER", "Seeking Waiver"),
    WAIVERONFILE: new Message("WAIVER_ON_FILE", "Waiver On File"),
    CONFLICT: new Message("CONFLICTS", "Conflicts"),
    NOCONFLICT: new Message("NO_CONFLICTS", "No Conflicts"),
    NOTAPPLICABLE: new Message("NOT_APPLICABLE", "Not Applicable"),
    TOTAL: new Message("TOTAL", "Total")
};
