import { Message } from "aderant-conflicts-models";

export const Messages = {
    LEAVE_WITHOUT_SAVING: new Message("LEAVE_WITHOUT_SAVING", "There are unsaved changes that will be lost if you leave without saving."),
    LEAVE_WITHOUT_SAVING_TITLE: new Message("LEAVE_WITHOUT_SAVING_TITLE", "Leave Without Saving?"),

    BUTTON_SAVE: new Message("BUTTON_SAVE", "Save"),
    BUTTON_CANCEL: new Message("BUTTON_CANCEL", "Cancel"),
    BUTTON_UPDATE: new Message("BUTTON_UPDATE", "Update"),
    BUTTON_LEAVE: new Message("BUTTON_LEAVE", "Leave")
};
