import { QueueClient, QueueServiceClient } from "@azure/storage-queue";
import { ConnectionContext } from "aderant-conflicts-models";

export async function getQueueClient(context: ConnectionContext, storageConnectionString: string, queueName: string): Promise<QueueClient> {
    console.log("Getting or creating queue: ", queueName);
    const queueServiceClient = QueueServiceClient.fromConnectionString(storageConnectionString);
    const queueClient = queueServiceClient.getQueueClient(queueName);
    await queueClient.createIfNotExists();
    return queueClient;
}
