import {
    Result,
    Message,
    NotFound,
    notFoundWithMessage,
    AggregateFieldDefinition,
    BooleanFieldDefinition,
    DateFieldDefinition,
    CurrencyFieldDefinition,
    CustomFieldDefinition,
    FieldDefinition,
    HighlightStringFieldDefinition,
    NumberFieldDefinition,
    StringFieldDefinition,
    UserFieldDefinition,
    EntityConfigurationTemplate,
    EntityFlyoutConfigurationTemplate,
    EntityReference,
    RelatedEntityReference,
    ValidationMessage,
    ValidationErrors,
    wrapValidationErrors
} from "aderant-conflicts-models";
import { TypeValidationMessages, PermissionValidationMessages } from "../../APIs/Messages";
import { EntityConfiguration } from "./EntityConfiguration";
const messages = { ...TypeValidationMessages, ...PermissionValidationMessages };

export const validateInput = (input: { entityType: string }): Result<void, NotFound> => {
    if (!input?.entityType) {
        return notFoundWithMessage(new Message("ENTITY_TYPE_NOT_PROVIDED", "Entity type was not provided.").getMessage());
    }
};

const validateCurrencyField = (field: CurrencyFieldDefinition, fieldName: string, entityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!field.propertyName) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.propertyName`));
    }
    if (!field.symbol) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.symbol`));
    }
    if (!field.decimalPlaces) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.decimalPlaces`));
    }
    if (field.aggregateType) {
        if (field.aggregateType != "min" && field.aggregateType != "max" && field.aggregateType != "sum") {
            reasonsIsInvalid.push(messages.VLD_ENUM_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.aggregateType`, "min, max, sum"));
        }
    }
    return reasonsIsInvalid;
};
const validateCustomField = (field: CustomFieldDefinition, fieldName: string, entityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!field.value) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.value`));
    }
    return reasonsIsInvalid;
};

const validateGeneralField = (field: StringFieldDefinition | HighlightStringFieldDefinition | BooleanFieldDefinition, fieldName: string, entityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!field.propertyName) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.propertyName`));
    }
    return reasonsIsInvalid;
};

const validateDateField = (field: DateFieldDefinition, fieldName: string, entityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!field.propertyName && !field.propertyLabel?.includes("Last Updated")) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.propertyName`));
    }
    if (field.aggregateType) {
        if (field.aggregateType != "max" && field.aggregateType != "min") {
            reasonsIsInvalid.push(messages.VLD_ENUM_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.aggregateType`, "min, max"));
        }
    }
    return reasonsIsInvalid;
};

const validateNumberField = (field: NumberFieldDefinition, fieldName: string, entityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!field.propertyName) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.propertyName`));
    }
    if (field.aggregateType) {
        if (field.aggregateType != "count" && field.aggregateType != "sum" && field.aggregateType != "min" && field.aggregateType != "max") {
            reasonsIsInvalid.push(
                messages.VLD_ENUM_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.aggregateType`, "count, sum, min, max")
            );
        }
    }
    return reasonsIsInvalid;
};

const validateUserField = (field: UserFieldDefinition, fieldName: string, entityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!field.userId) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.userId`));
    }
    if (!field.userName) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields.${fieldName}.userName`));
    }
    return reasonsIsInvalid;
};

const validateField = (field: FieldDefinition, entityName: string, parentEntityName?: string | undefined): ValidationMessage[] => {
    const fullEntityName = parentEntityName ? `${parentEntityName}.${entityName}` : entityName;
    const reasonsIsInvalid: ValidationMessage[] = [];

    //Not all types of FieldDefinition have a propertyName, but want to use that property where it exists
    //Also this is not something that can fully be caught at compile time as we are validating a hand-crafted file at runtime
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const fieldName = (field as any).propertyName ?? field.propertyLabel ?? field.displayFormat;
    if (!field.displayFormat) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${fullEntityName}.fields.${fieldName}.displayFormat`));
    }
    if (!field.propertyLabel) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${fullEntityName}.fields.${fieldName}.propertyLabel`));
    }
    if (field.displayFormat) {
        switch (field.displayFormat) {
            case "Currency": {
                reasonsIsInvalid.push(...validateCurrencyField(field, fieldName, fullEntityName));
                break;
            }
            case "Custom": {
                reasonsIsInvalid.push(...validateCustomField(field, fieldName, fullEntityName));
                break;
            }
            case "Date":
            case "DateTime": {
                reasonsIsInvalid.push(...validateDateField(field, fieldName, fullEntityName));
                break;
            }
            case "Number": {
                reasonsIsInvalid.push(...validateNumberField(field, fieldName, fullEntityName));
                break;
            }
            case "Boolean":
            case "Highlight":
            case "String":
                reasonsIsInvalid.push(...validateGeneralField(field, fieldName, fullEntityName));
                break;
            case "User":
            case "UserWithAvatar":
                reasonsIsInvalid.push(...validateUserField(field, fieldName, fullEntityName));
                break;
            default:
                reasonsIsInvalid.push(
                    messages.VLD_ENUM_SOURCE_FIELD.asValidationMessage(
                        `${EntityConfiguration.FlyoutDefinition}:`,
                        `${fullEntityName}.fields.${fieldName}.displayFormat`,
                        `Boolean, Currency, Custom, Date, DateTime, Highlight, Number, String, User and UserWithAvatar`
                    )
                );
                break;
        }
    }
    return reasonsIsInvalid;
};

const validateEntityReference = (reference: EntityReference, entityName: string, referenceType: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!reference.entityName) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.${referenceType}.entityName`));
    }
    if (!reference.foreignKey) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.${referenceType}.foreignKey`));
    }
    return reasonsIsInvalid;
};

const validateRelatedEntityReference = (reference: RelatedEntityReference, entityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    reasonsIsInvalid.push(...validateEntityReference(reference, entityName, "related"));

    if (!reference.relatedEntityForeignKey) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.related.relatedEntityForeignKey`));
    }
    return reasonsIsInvalid;
};

const validateEntity = (template: EntityConfigurationTemplate, entityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!template.entityName) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.entityName`));
    }
    if (template.parent) {
        reasonsIsInvalid.push(...validateEntityReference(template.parent, template.entityName, "parent"));
    }
    if (template.relatedEntity) {
        reasonsIsInvalid.push(...validateRelatedEntityReference(template.relatedEntity, template.entityName));
    }
    return reasonsIsInvalid;
};

const validateChildField = (field: AggregateFieldDefinition, fieldName: string, childEntityName: string, parentEntityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    const fullEntityName = parentEntityName ? `${parentEntityName}.${childEntityName}` : childEntityName;
    reasonsIsInvalid.push(...validateField(field, childEntityName, parentEntityName));
    if (!field.aggregateType) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${fullEntityName}.fields.${fieldName}.aggregateType`));
    }
    if (field.conditionType) {
        if (field.conditionType != "equal") {
            reasonsIsInvalid.push(messages.VLD_ENUM_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${fullEntityName}.fields.${fieldName}.conditionType`, "equal"));
        }
        if (field.conditionValue === undefined) {
            reasonsIsInvalid.push(messages.VLD_NULLUNDEF_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${fullEntityName}.fields.${fieldName}.conditionValue`));
        }
    }
    return reasonsIsInvalid;
};

const validateChildEntityReference = (child: EntityReference & { fields: AggregateFieldDefinition[] }, parentEntityName: string): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    reasonsIsInvalid.push(...validateEntityReference(child, parentEntityName, "child"));

    if (!child.fields) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${parentEntityName}.child.fields`));
    } else if (child.fields.length === 0) {
        reasonsIsInvalid.push(messages.VLD_EMPTY_ARRAY_SOURCE.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${parentEntityName}.${child.entityName}.fields`));
    } else {
        child.fields.forEach((field) => {
            //definition is parsed from input file, so could fail type-checking at runtime
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const fieldName = (field as any).propertyName ?? field.propertyLabel ?? field.displayFormat;
            reasonsIsInvalid.push(...validateChildField(field, fieldName, child.entityName, parentEntityName));
        });
    }

    return reasonsIsInvalid;
};

const validateFlyoutConfigurationTemplate = (template: EntityFlyoutConfigurationTemplate): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    const entityName = template.entityName ?? template.entityDisplayName ?? "an entity";
    reasonsIsInvalid.push(...validateEntity(template, entityName));

    if (!template.entityDisplayName) {
        template.entityDisplayName = template.entityName;
    }
    if (!template.fields) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields`));
    } else if (template.fields.length === 0) {
        reasonsIsInvalid.push(messages.VLD_EMPTY_ARRAY_SOURCE.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, `${entityName}.fields`));
    } else {
        template.fields.forEach((field) => {
            reasonsIsInvalid.push(...validateField(field, entityName));
        });
    }

    if (template.children) {
        template.children.forEach((child) => {
            reasonsIsInvalid.push(...validateChildEntityReference(child, entityName));
        });
    }

    return reasonsIsInvalid;
};

export const validateTemplates = (templates: EntityFlyoutConfigurationTemplate[]): Result<EntityFlyoutConfigurationTemplate[], ValidationErrors> => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!templates) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEF_SOURCE_FIELD.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, "configurations"));
    } else if (templates.length === 0) {
        reasonsIsInvalid.push(messages.VLD_EMPTY_ARRAY_SOURCE.asValidationMessage(`${EntityConfiguration.FlyoutDefinition}:`, "configurations"));
    } else {
        templates.forEach((template) => {
            reasonsIsInvalid.push(...validateFlyoutConfigurationTemplate(template));
        });
    }
    if (reasonsIsInvalid.length == 0) {
        return templates;
    }
    return wrapValidationErrors(reasonsIsInvalid);
};
