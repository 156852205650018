import { APIs, MultiResponse } from "aderant-conflicts-common";
import {
    CreateHitCommentInput,
    DurableHitIdentifier,
    EditHitCommentInput,
    EntityIdentifier,
    EtagMismatch,
    Forbidden,
    Hit,
    HitCommentCosmosId,
    HitCommentMessages,
    HitIdentifier,
    isForbidden,
    notFound,
    NotFound,
    ok,
    QuickSearch,
    RequestTerm,
    Result,
    SearchErrors,
    SearchStatus,
    SearchSummary,
    SearchVersion,
    SearchVersionEdited,
    SearchVersionEtag,
    SearchVersionIdentifier,
    SearchVersionNew,
    SearchVersionUnedited,
    UnexpectedError,
    unexpectedError,
    updateSearchWithLatestEtag,
    ValidationErrors
} from "aderant-conflicts-models";
import { InvalidSearchType } from "aderant-conflicts-models/dist/Validation/Search/Errors";
import { SnackbarAction } from "@aderant/aderant-react-components";
import { FixedDelayStrategy, Logger, withRetry } from "aderant-web-fw-core";
import { getSearchProgress } from "Functions/search";
import { History } from "history";
import { RootState } from "MyTypes";
import { call, put, select } from "redux-saga/effects";
import { SearchService } from "services/searchService";
import { SearchUpdateDelta } from "services/storageService";
import { appActions, SaveSearchActionInputs, SaveSearchActionTypes, searchActions, SearchActionType } from "state/actions";
import { userAlertActions } from "state/actions/UserAlertActions";
import { getCreatedSearchesByIds, getCurrentSearch, getUser } from "state/selectors";
import store from "state/store/store";
import * as SagaContext from "../store/ConflictsSagaContext";
import { Action, Return, watch, parseError } from "./common";
import { Messages } from "./Messages";
import { PathMap } from "utilities/routingPathMap";

export const searchWatchers = [
    function* saveSearchWatcher() {
        yield* watch(SearchActionType.SAVE_SEARCH, saveSearch);
    },

    function* fetchLatestSearchVersionWatcher() {
        yield* watch(SearchActionType.FETCH_LATEST_SEARCH_VERSION, fetchLatestSearchVersion);
    },

    function* fetchSearchSummariesWatcher() {
        yield* watch(SearchActionType.FETCH_SEARCH_SUMMARIES, fetchSearchSummaries);
    },

    function* fetchSearchSummariesByIdWatcher() {
        yield* watch(SearchActionType.FETCH_SEARCH_SUMMARIES_BY_ID, fetchSearchSummariesById);
    },

    function* deleteSearchesWatcher() {
        yield* watch(SearchActionType.DELETE_SEARCHES, deleteSearches);
    },

    function* addSearchWatcher() {
        yield* watch(SearchActionType.ADD_SEARCH, addSearch);
    },

    function* submitSearchWatcher() {
        yield* watch(SearchActionType.SUBMIT_SEARCH, submitSearch);
    },

    function* performSearchWatcher() {
        yield* watch(SearchActionType.PERFORM_SEARCH, performSearch);
    },

    function* addTermThenPerformSearchWatcher() {
        yield* watch(SearchActionType.ADD_TERM_THEN_PERFORM_SEARCH, addTermThenPerformSearch);
    },

    function* addTermThenSubmitSearchWatcher() {
        yield* watch(SearchActionType.ADD_TERM_THEN_SUBMIT_SEARCH, addTermThenSubmitSearch);
    },

    function* updateHitStatusWatcher() {
        yield* watch(SearchActionType.UPDATE_HITS, updateHitStatus);
    },

    function* updateSearchesAssignedToWatcher() {
        yield* watch(SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO, updateSearchesAssignedTo);
    },

    function* updateSearchesSearchStatusWatcher() {
        yield* watch(SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS, updateSearchesSearchStatus);
    },

    function* updateSearchWatcher() {
        yield* watch(SearchActionType.UPDATE_SEARCH, updateSearch);
    },

    function* fetchAuditsWatcher() {
        yield* watch(SearchActionType.FETCH_AUDITS, fetchAudits);
    },

    function* fetchEntityWatcher() {
        yield* watch(SearchActionType.FETCH_ENTITY_DETAILS, fetchEntity);
    },

    function* createNewSearchVersionWatcher() {
        yield* watch(SearchActionType.CREATE_NEW_SEARCH_VERSION, createNewSearchVersion);
    },

    function* createAndPerformNewVersionWatcher() {
        yield* watch(SearchActionType.CREATE_AND_PERFORM_NEW_SEARCH_VERSION, createAndPerformNewVersion);
    },

    function* addSearchCopyFromIDWatcher() {
        yield* watch(SearchActionType.ADD_SEARCH_COPY_FROM_ID, addSearchCopyFromId);
    },

    function* cleanUpSearchingSearchesWatcher() {
        yield* watch(SearchActionType.CLEAN_UP_SEARCHING_SEARCHES, cleanUpSearchingSearches);
    },

    function* performQuickSearchWatcher() {
        yield* watch(SearchActionType.PERFORM_QUICK_SEARCH, performQuickSearch);
    },

    function* performQuickSearchAgainWatcher() {
        yield* watch(SearchActionType.PERFORM_QUICK_SEARCH_AGAIN, performQuickSearchAgain);
    },

    function* convertToSearchRequestWatcher() {
        yield* watch(SearchActionType.CONVERT_TO_FULL_SEARCH, convertToSearchRequest);
    },
    function* fetchHitCommentsWatcher() {
        yield* watch(SearchActionType.FETCH_HIT_COMMENTS, fetchHitComments);
    },
    function* createHitCommentWatcher() {
        yield* watch(SearchActionType.CREATE_HIT_COMMENT, createHitComment);
    },
    function* editHitCommentWatcher() {
        yield* watch(SearchActionType.EDIT_HIT_COMMENT, editHitComment);
    },
    function* deleteHitCommentWatcher() {
        yield* watch(SearchActionType.DELETE_HIT_COMMENT, deleteHitComment);
    }
];

function logAndAlert(
    context: string,
    error?:
        | Forbidden
        | UnexpectedError
        | ValidationErrors
        | SearchErrors.InvalidReassign
        | SearchErrors.InvalidStatusChange
        | NotFound
        | SearchErrors.InvalidId
        | EtagMismatch
        | SearchErrors.UserCannotViewSearch
        | SearchErrors.NotLatestSearchVersion
        | APIs.SearchNewVersionErrors
        | SearchErrors.NotAssignedTo
        | SearchErrors.InvalidSearchType
        | SearchErrors.NoWordOrPhrases
        | SearchErrors.InvalidVersionNumber
): void {
    if (error) {
        switch (error._conflictserrortype) {
            case "ACCESS_DENIED": {
                const forbidden: Forbidden = error;
                console.warn(`You do not have permission to ${context}.`, forbidden);
                window.alert(`You do not have permission to ${context}.`);
                return;
            }
            case "VALIDATION": {
                const validationErrors: ValidationErrors = error;
                console.warn(`${context}: received validation error response`, validationErrors);
                const prettyErrors = validationErrors.errors.map((e) => e.message).join("\n");
                window.alert(`${context}: failed with the following validation errors:\n${prettyErrors}`);
                return;
            }
            case "NOT_FOUND": {
                const notFound: NotFound = error;
                console.warn(`${context}: item was not found.`, notFound);
                window.alert(`${context}: item was not found.`);
                return;
            }
            case "INVALID_ID": {
                const invalidSearchId: SearchErrors.InvalidId = error;
                console.warn(`${context}: provided search ID was not valid.`, invalidSearchId);
                window.alert(`${context}: provided search ID was not valid: ${invalidSearchId.message}`);
                return;
            }
            case "ETAG_MISMATCH": {
                const etagMismatch: EtagMismatch = error;
                console.warn(`${context}: ${etagMismatch.message}.`, etagMismatch);
                window.alert(`${context}: ${etagMismatch.message}`);
                return;
            }
            case "USER_CANNOT_VIEW_SEARCH": {
                const userCannotViewSearch: SearchErrors.UserCannotViewSearch = error;
                console.warn(`${context}: User is not permitted to view the search.`, userCannotViewSearch);
                window.alert(`${context}: User is not permitted to view the search. ${userCannotViewSearch.message}`);
                return;
            }
            case "NOT_LATEST_SEARCH_VERSION": {
                const notLatestSearchVersion: SearchErrors.NotLatestSearchVersion = error;
                console.warn(`${context}: ${notLatestSearchVersion.message}.`, notLatestSearchVersion);
                window.alert(`${context}: ${notLatestSearchVersion.message}. ${notLatestSearchVersion.message}`);
                return;
            }
            case "NOT_ASSIGNED_TO": {
                const notAssignedTo: SearchErrors.NotAssignedTo = error;
                console.warn(`${context}: User is not permitted to view the search.`, notAssignedTo);
                window.alert(`${context}: User is not permitted to view the search. ${notAssignedTo.message}`);
                return;
            }
            case "INVALID_SEARCH_TYPE": {
                const invalidSearchType: SearchErrors.InvalidSearchType = error;
                console.warn(`${context}: Invalid search type.`, invalidSearchType);
                window.alert(`${context}: Invalid search type. ${invalidSearchType.message}`);
                return;
            }
            case "INVALID_STATUS": {
                const invalidStatus: SearchErrors.InvalidStatus = error;
                console.warn(`${context}: Invalid status.`, invalidStatus);
                window.alert(`${context}: Invalid status. ${invalidStatus.message}`);
                return;
            }
            case "INVALID_STATUS_CHANGE": {
                const invalidStatusChange: SearchErrors.InvalidStatusChange = error;
                console.warn(`${context}: Invalid status change.`, invalidStatusChange);
                window.alert(`${context}: Invalid status change. ${invalidStatusChange.message}`);
                return;
            }
            case "INVALID_REASSIGN": {
                const invalidReassign: SearchErrors.InvalidReassign = error;
                console.warn(`${context}: Invalid reassign.`, invalidReassign);
                window.alert(`${context}: Invalid reassign. ${invalidReassign.message}`);
                return;
            }
            case "UNEXPECTED": {
                const unexpectedError: UnexpectedError = error;
                console.warn(`${context}: Unexpected error.`, unexpectedError);
                window.alert(`${context}: Unexpected error. ${unexpectedError.message}`);
                return;
            }
        }
    } else {
        console.warn(`${context}: Unexpected error.`);
        window.alert(`${context}: Unexpected error.`);
        return;
    }
}

function attachLostTextMessageIfCreateOrEdit(action: "created" | "edited" | "deleted" | "viewed", error: string, lostText: string | undefined) {
    if (lostText && (action === "created" || action === "edited")) {
        return error + " " + HitCommentMessages.HITCOMMENT_TEXT_LOST.getMessage(lostText);
    }
    return error;
}

function getDefaultHitCommentErrorMessage(action: "created" | "edited" | "deleted" | "viewed", lostText: string | undefined) {
    if (action === "viewed") {
        return HitCommentMessages.HITCOMMENTS_VIEW_FAILED_RETRY.getMessage();
    }
    return attachLostTextMessageIfCreateOrEdit(action, HitCommentMessages.HITCOMMENT_ACTION_FAILED_RETRY.getMessage(action), lostText);
}

/**
 * With validation errors, this only looks at the first error. If there are multiple errors, it will only consider the first one.
 * This should be fine for almost all use cases since we typically return immediately after the first error (or the same type of error).
 * This was done to simplify the logic and avoid having to deal with multiple errors.
 */
function getUserFriendlyErrorMessageForHitComments(action: "created" | "edited" | "deleted" | "viewed", error?: Forbidden | ValidationErrors | NotFound, lostText?: string): string {
    if (!error) {
        return getDefaultHitCommentErrorMessage(action, lostText);
    }

    const actionPresentTenseName = action === "created" ? "add" : action === "edited" ? "edit" : action === "deleted" ? "delete" : "view";

    switch (error._conflictserrortype) {
        case "VALIDATION": {
            switch (error.errors[0].messageCode) {
                case "HITCOMMENT_INVALID_QUICKSEARCH":
                case "HITCOMMENT_INVALID_NEW_VERSION_SEARCH_STATUS":
                case "SEARCH_MUST_HAVE_BEEN_SEARCHED":
                case "HITCOMMENT_INVALID_SEARCH_STATUS":
                    return attachLostTextMessageIfCreateOrEdit(action, error.errors[0].message, lostText);
                case "HIT_COMMENT_PERMISSION_DENIED":
                    return attachLostTextMessageIfCreateOrEdit(action, HitCommentMessages.HITCOMMENT_PERMISSION_DENIED_SIMPLE.getMessage(actionPresentTenseName), lostText);
                case "REQUEST_TERM_ID_NOT_FOUND":
                case "ENTITY_ID_AND_TYPE_NOT_FOUND":
                    return attachLostTextMessageIfCreateOrEdit(action, HitCommentMessages.HITCOMMENT_NEW_VERSION_HIT_NOT_FOUND.getMessage(actionPresentTenseName), lostText);
                default:
                    return getDefaultHitCommentErrorMessage(action, lostText);
            }
        }
        case "NOT_FOUND":
            if (error.message === notFound().message) {
                return attachLostTextMessageIfCreateOrEdit(action, HitCommentMessages.HITCOMMENT_NOT_FOUND.getMessage(), lostText);
            }
            return getDefaultHitCommentErrorMessage(action, lostText);
        default:
            return getDefaultHitCommentErrorMessage(action, lostText);
    }
}

//we should probably refactor these a bit to use 'call' on all the api calls if the logic ever gets complicated enough to unit test more (see: https://redux-saga.js.org/docs/basics/DeclarativeEffects/)

function* fetchLatestSearchVersion(action: Action<typeof SearchActionType.FETCH_LATEST_SEARCH_VERSION, { searchId: string; fetchHitResults: boolean }>) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    try {
        // fetch the searches from the database
        const search: Return<typeof searchStorageService.getLatestSearchVersion> = yield searchStorageService.getLatestSearchVersion({ ...action.payload });
        if (!ok(search)) {
            logAndAlert("Fetching latest search version", search);
            yield put(searchActions.fetchLatestSearchVersionFailure({ error: search.message, searchId: action.payload.searchId }));
            return;
        }
        // dispatch the fetchSearchSuccess action
        yield put(searchActions.fetchLatestSearchVersionSuccess(search));
        return search;
    } catch (error) {
        logger.error("Unable to fetch Search", error);
        // dispatch the fetchSearchFailure action
        yield put(searchActions.fetchLatestSearchVersionFailure({ error: parseError(error), searchId: action.payload.searchId }));
    }
}

function* fetchSearchSummaries() {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    try {
        // fetch the searches from the database
        const searches: Return<typeof searchStorageService.getSearchSummaries> = yield searchStorageService.getSearchSummaries();
        if (!ok(searches)) {
            logAndAlert("Fetching search summaries", searches);
            yield put(searchActions.fetchSearchSummariesFailure(searches.message));
            return;
        }
        // dispatch the fetchSearchesSuccess action
        yield put(searchActions.fetchSearchSummariesSuccess(searches));
    } catch (error) {
        logger.error("Unable to fetch Search Summaries", error);
        // dispatch the fetchSearchesFailure action
        yield put(searchActions.fetchSearchSummariesFailure(parseError(error)));
    }
}

function* cleanUpSearchingSearches(action: Action<typeof SearchActionType.CLEAN_UP_SEARCHING_SEARCHES, { searchSummariesInSearching: SearchSummary[]; previousStatus: SearchStatus }>) {
    const searchStorageService = yield* SagaContext.getStorageService();
    const logger = yield* SagaContext.getLogger();
    const searchesToFail: (SearchVersionIdentifier & SearchVersionEtag)[] = [];
    const skipUnlessOlderThanHours = 4;
    action.payload.searchSummariesInSearching.map((searchSummary) => {
        if (searchSummary.searchDate && searchSummary.status === "SEARCHING") {
            const hour = 60 * 60 * 1000;
            const currentTimeMinus4Hours = new Date().getTime() - skipUnlessOlderThanHours * hour;
            if (searchSummary.searchDate.getTime() < currentTimeMinus4Hours) {
                searchesToFail.push({ versionId: searchSummary.versionId, searchId: searchSummary.id, _etag: searchSummary.searchEtag });
            }
        }
    });

    const searchIdsProcessing = searchesToFail.map((search) => search.searchId);
    try {
        yield put(searchActions.addSearchIdsCurrentlyProcessing(searchIdsProcessing));
        if (searchesToFail.length) {
            const updatedSearches: APIs.SearchesUpdateResults = yield searchStorageService.cleanUpSearchingSearches({
                searchVersionIdentifiers: searchesToFail,
                previousStatus: { status: action.payload.previousStatus },
                skipUnlessOlderThanHours: skipUnlessOlderThanHours
            });
            const successfulUpdates: { versionId: string; etag?: string }[] = [];
            const failedUpdates: string[] = [];
            updatedSearches.forEach((result) => {
                if (result.status === 200 && result.id) {
                    successfulUpdates.push({ versionId: result.id, etag: result.body._etag });
                } else {
                    failedUpdates.push(result.id);
                }
            });
            if (failedUpdates.length > 0 && successfulUpdates.length > 0) {
                yield put(userAlertActions.addUserAlert(`${successfulUpdates.length} requests failed to have their statuses changed to ${action.payload.previousStatus}`, { type: "error" }));
            }
            yield put(searchActions.cleanUpSearchingSearchesSuccess({ versionIds: successfulUpdates, change: { status: action.payload.previousStatus } }));
        }
    } catch (error) {
        logger.error("Unable to cancel searches that have been searching for over 4 hours", error);
    } finally {
        yield put(searchActions.removeSearchIdsCurrentlyProcessing(searchIdsProcessing));
    }
}

function* fetchSearchSummariesById(fetchAction: Action<typeof SearchActionType.FETCH_SEARCH_SUMMARIES_BY_ID, { searchIds: string[] }>) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    const user = yield select((state: RootState) => state.app.permissionsContext.currentUserId);
    try {
        const fetchedSearchSummaries: Return<typeof searchStorageService.getSearchSummariesById> = yield searchStorageService.getSearchSummariesById(fetchAction.payload);
        if (!ok(fetchedSearchSummaries)) {
            yield put(searchActions.fetchSearchSummariesByIdFailure(fetchedSearchSummaries.message));
            return;
        }
        //This cast is being used because we're explicitly filtering out everything except valid search summaries
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const successfullyFetchedSearchSummaries: SearchSummary[] = fetchedSearchSummaries
            .map((searchSummaryResponse) => searchSummaryResponse.body)
            .filter((searchSummary) => ok(searchSummary)) as SearchSummary[];

        const previouslyDraftSearchingSearches: SearchSummary[] = [];
        const previouslySubmittedSearchingSearches: SearchSummary[] = [];

        successfullyFetchedSearchSummaries.forEach((summary) => {
            if (summary.status === "SEARCHING") {
                if (summary.submittedDate || summary.version > 1) {
                    previouslySubmittedSearchingSearches.push(summary);
                } else {
                    previouslyDraftSearchingSearches.push(summary);
                }
            }
        });
        if (previouslyDraftSearchingSearches.length) {
            yield call(cleanUpSearchingSearches, {
                actionType: "CLEANUPSEARCHINGSEARCHES",
                payload: { searchSummariesInSearching: previouslyDraftSearchingSearches, previousStatus: "DRAFT" }
            });
        }

        if (previouslySubmittedSearchingSearches.length) {
            yield call(cleanUpSearchingSearches, {
                actionType: "CLEANUPSEARCHINGSEARCHES",
                payload: { searchSummariesInSearching: previouslySubmittedSearchingSearches, previousStatus: "SUBMITTED" }
            });
        }

        yield put(searchActions.fetchSearchSummariesByIdSuccess(successfullyFetchedSearchSummaries));
        const failedSearches = successfullyFetchedSearchSummaries.filter((searchSummary) => searchSummary.errored || searchSummary.errorStatus?.isErrored);
        for (const searchSummary of failedSearches) {
            if ((!searchSummary.assignedToUserId && searchSummary.createdByUserId === user) || searchSummary.assignedToUserId === user) {
                yield put(userAlertActions.addUserAlert(`Search request ${searchSummary.name} failed. Please search again`, { type: "error" }));
            }
        }
    } catch (error) {
        logger.error("Unable to fetch Search Summaries", error);
        yield put(searchActions.fetchSearchSummariesByIdFailure(parseError(error)));
    }
}

function* deleteSearches(deleteAction: Action<typeof SearchActionType.DELETE_SEARCHES, { searchVersionIdentifiers: SearchVersionIdentifier[] }>) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    const searchIds = deleteAction.payload.searchVersionIdentifiers.map((searchIds) => searchIds.searchId);
    try {
        // set searches as processing for immediate UI feedback
        yield put(searchActions.addSearchIdsCurrentlyProcessing(searchIds));
        // delete the search from the database
        const deletedSearches: APIs.SearchesDeleteResults = yield searchStorageService.deleteSearches(deleteAction.payload.searchVersionIdentifiers);
        const successfulDeletions: string[] = [];
        const failedDeletions: string[] = [];

        deletedSearches.forEach((value) => {
            if (value.status === 204 && value.id) {
                successfulDeletions.push(value.id);
            } else {
                failedDeletions.push(value.id);
            }
        });
        if (failedDeletions.length > 0) {
            yield put(userAlertActions.addUserAlert(`${failedDeletions.length} request(s) failed to delete`, { type: "error" }));
        }
        // dispatch the deleteSearchSuccess action
        yield put(searchActions.deleteSearchesSuccess(successfulDeletions));
    } catch (error) {
        logger.error("Unable to Delete Searches", error);
        const failedDeletes: SearchSummary[] = yield select((state: RootState) => getCreatedSearchesByIds(state, searchIds));
        for (const searchSummary of failedDeletes) {
            yield put(userAlertActions.addUserAlert(`Search request ${searchSummary.name !== "" ? searchSummary.name : "(Empty)"} failed to delete`, { type: "error" }));
        }
        // dispatch the deleteSearchFailure action
        yield put(searchActions.deleteSearchesFailure(parseError(error)));
    } finally {
        //clear searches from processing list
        yield put(searchActions.removeSearchIdsCurrentlyProcessing(searchIds));
    }
}

function* addSearch(
    search: Action<
        typeof SearchActionType.ADD_SEARCH,
        | { newSearchVersion: SearchVersionNew; setAsCurrentSearch?: boolean; currentSearchActionId: string; doNotPersist?: boolean }
        | { newSearchVersion: QuickSearch; setAsCurrentSearch?: boolean; currentSearchActionId: string; doNotPersist: true }
    >
) {
    const { newSearchVersion, currentSearchActionId, doNotPersist } = search.payload;
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    try {
        if (doNotPersist) {
            logger.info(`Skipped addSearch for search id ${newSearchVersion.searchId}`);
            return;
        }
        const savedSearch: Return<typeof searchStorageService.addSearch> = yield searchStorageService.addSearch(newSearchVersion);
        if (!ok(savedSearch)) {
            logAndAlert("Creating new search", savedSearch);
            yield put(searchActions.addSearchFailure(savedSearch.message));
            return;
        }
        // dispatch the addSearchSuccess action
        yield put(searchActions.addSearchSuccess({ newSearchVersion: savedSearch, initialSearchActionId: currentSearchActionId }));
    } catch (error) {
        logger.error("Unable to Add Search", error);
        // dispatch the addSearchFailure action
        yield put(searchActions.addSearchFailure(parseError(error)));
    } finally {
        yield put(searchActions.isNewSearch(false));
    }
}

function* addSearchCopyFromId(
    action: Action<
        typeof SearchActionType.ADD_SEARCH_COPY_FROM_ID,
        { currentSearchId: string; newSearchId: string; history: History<unknown>; setAsCurrentSearch?: boolean; currentSearchActionId: string }
    >
) {
    const originalSearchVersion: SearchVersion = yield select((state: RootState) => state.search.currentSearch.searchVersion);
    yield put(searchActions.clearCurrentSearch());
    const { history, currentSearchActionId, newSearchId } = action.payload;
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    const user = yield select((state: RootState) => state.app.permissionsContext.currentUserId);
    try {
        // push the blank search with the correct ID so the user has instant feedback
        history.push(`/search/${newSearchId}`);
        // fetch the search to copy from the database
        const search: Return<typeof searchStorageService.getLatestSearchVersion> = yield searchStorageService.getLatestSearchVersion({
            searchId: action.payload.currentSearchId,
            fetchHitResults: false
        });
        // copy the relevant information to the new blank search
        if (ok(search)) {
            if (search.isQuickSearch) {
                throw "Unexpected search type retrieved. The retrieved search was a quick search and a copy cannot be created from a quick search.";
            }
            const copiedSearch: Return<typeof searchStorageService.copySearch> = yield searchStorageService.copySearch(search, user, newSearchId);
            yield put(searchActions.addSearchCopyFromIdSuccess({ newSearchVersion: copiedSearch, initialSearchActionId: currentSearchActionId }));
        } else {
            yield put(searchActions.addSearchCopyFromIdFailure({ originalSearchVersion: originalSearchVersion, error: search.message }));
        }
    } catch (error) {
        logger.error("Unexpected error copying search", error);
        //Stop trying to fetch the search
        yield put(searchActions.addSearchCopyFromIdFailure({ originalSearchVersion: originalSearchVersion, error: parseError(error) }));
    }
}

function* submitSearch(search: Action<typeof SearchActionType.SUBMIT_SEARCH, { searchVersion: SearchVersion; history: History<unknown> }>) {
    const { searchVersion, history } = search.payload;
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    try {
        yield put(searchActions.isCurrentSearchSaving(true));
        // save the search to the database
        const savedSearch: Return<typeof searchStorageService.addSearch> = yield searchStorageService.submitSearch(searchVersion);
        // dispatch the addSearchSuccess action
        if (ok(savedSearch)) {
            yield put(searchActions.submitSearchSuccess(savedSearch));
            history.push(`/search-requests/`);
        } else {
            //we want to be silent on network timeouts, 500s etc and wait to inform the user if it still fails when they try to perform the search
            //but if we're getting a validation error on saving a new blank search that suggests a bug that needs to be fixed.
            //so lets be noisy about it.
            logAndAlert("Submitting search", savedSearch);
            yield put(searchActions.addSearchFailure(savedSearch.message));
        }
    } catch (error) {
        logger.error("Unable to submit Search", error);
        //display an error message to the user
        yield put(appActions.actionFailure(Messages.SubmitSearchError.getMessage()));
        // dispatch the addSearchFailure action
        yield put(searchActions.addSearchFailure(parseError(error)));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* addTerm(action: Action<typeof SaveSearchActionTypes.ADD_TERM, { searchVersion: SearchVersion | SearchVersionNew; term: RequestTerm; currentSearchActionId: string }>) {
    const { searchVersion, term, currentSearchActionId } = action.payload;
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();

    try {
        // disable Search Button before calling addTerm
        yield put(searchActions.isCurrentSearchSaving(true));
        // save the term to the database
        const savedSearch: Return<typeof searchStorageService.addTerm> = yield searchStorageService.addTerm(searchVersion, term);
        if (ok(savedSearch)) {
            const requestTerm = savedSearch.requestTerms.find((t) => t.id === term.id);
            if (requestTerm === undefined) {
                //if this happens there's a bug
                throw unexpectedError(`Received a response for adding a request term that did not contain the request term that was updated. id: ${savedSearch.id}`, "StorageService.UpdateTerm");
            }
            yield put(searchActions.saveSearchSuccess({ searchVersion: savedSearch, initialSearchActionId: currentSearchActionId }));
        } else {
            const result: ValidationErrors | NotFound | InvalidSearchType | EtagMismatch = savedSearch;
            yield put(searchActions.saveSearchFailure(action.actionType, result.message));
        }

        return savedSearch;
    } catch (error) {
        logger.error("Unable to Add Term", error);
        // dispatch the addTermFailure action
        yield put(searchActions.saveSearchFailure(action.actionType, parseError(error)));
    } finally {
        // enable Search Button after calling addTerm
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* addTerms(action: Action<typeof SaveSearchActionTypes.ADD_TERMS, { searchVersion: SearchVersion | SearchVersionNew; terms: RequestTerm[]; currentSearchActionId: string }>) {
    const { searchVersion, terms, currentSearchActionId } = action.payload;
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();

    try {
        // disable Search Button before calling addTerm
        yield put(searchActions.isCurrentSearchSaving(true));
        // save the terms to the database
        const savedSearch: Return<typeof searchStorageService.addTerms> = yield searchStorageService.addTerms(searchVersion, terms);
        if (ok(savedSearch)) {
            const requestTerm = savedSearch.requestTerms.find((t) => t.id === terms[0].id);
            if (requestTerm === undefined) {
                //if this happens there's a bug
                throw unexpectedError(`Received a response for adding a request term that did not contain the request term that was updated. id: ${savedSearch.id}`, "StorageService.UpdateTerm");
            }
            yield put(searchActions.saveSearchSuccess({ searchVersion: savedSearch, initialSearchActionId: currentSearchActionId }));
        } else {
            const result: ValidationErrors | NotFound | EtagMismatch | InvalidSearchType = savedSearch;
            yield put(searchActions.saveSearchFailure(action.actionType, result.message));
        }

        return savedSearch;
    } catch (error) {
        logger.error("Unable to Add Term", error);
        // dispatch the addTermFailure action
        yield put(searchActions.saveSearchFailure(action.actionType, parseError(error)));
    } finally {
        // enable Search Button after calling addTerm
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* updateTerm(action: Action<typeof SaveSearchActionTypes.UPDATE_TERM, { searchVersion: SearchVersion | SearchVersionNew; term: RequestTerm; currentSearchActionId: string }>) {
    const { searchVersion, term, currentSearchActionId } = action.payload;
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    try {
        // disable Search Button before calling updateTerm
        yield put(searchActions.isCurrentSearchSaving(true));
        // save the term to the database
        const savedSearch: Return<typeof searchStorageService.updateTerm> = yield searchStorageService.updateTerm(searchVersion, term);
        if (ok(savedSearch)) {
            const requestTerm = savedSearch.requestTerms.find((t) => t.id === term.id);
            if (requestTerm === undefined) {
                //if this happens there's a bug
                throw unexpectedError(`Received a response for updating a request term that did not contain the request term that was updated. id: ${savedSearch.id}`, "StorageService.UpdateTerm");
            }
            yield put(searchActions.saveSearchSuccess({ searchVersion: savedSearch, initialSearchActionId: currentSearchActionId }));
        } else {
            const result: ValidationErrors | InvalidSearchType | NotFound | EtagMismatch = savedSearch;
            yield put(searchActions.saveSearchFailure(action.actionType, result.message));
        }
        return savedSearch;
    } catch (error) {
        logger.error("Unable to Update Term", error);
        // dispatch the updateTermFailure action
        yield put(searchActions.saveSearchFailure(action.actionType, parseError(error)));
    } finally {
        // enable Search Button after calling updateTerm
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* deleteTerm(action: Action<typeof SaveSearchActionTypes.DELETE_TERM, { searchVersion: SearchVersion | SearchVersionNew; termId: string; currentSearchActionId: string }>) {
    const { searchVersion, termId, currentSearchActionId } = action.payload;
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    try {
        // disable Search Button before calling deleteTerm
        yield put(searchActions.isCurrentSearchSaving(true));
        // delete the term from the database
        const result: Return<typeof searchStorageService.deleteTerm> = yield searchStorageService.deleteTerm(searchVersion, termId);
        if (ok(result)) {
            yield put(searchActions.saveSearchSuccess({ searchVersion: result, initialSearchActionId: currentSearchActionId }));
        } else {
            yield put(searchActions.saveSearchFailure(action.actionType, result.message));
        }
        return result;
    } catch (error) {
        logger.error("Unable to Delete Term", error);
        // dispatch the deleteTermFailure action
        yield put(searchActions.saveSearchFailure(action.actionType, parseError(error)));
    } finally {
        // enable Search Button after calling deleteTerm
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function withRetry3Times<FArgs extends Array<any>, FOut>(f: (...args: FArgs) => Promise<FOut>, logger: Logger): (...args: FArgs) => Promise<FOut> {
    return withRetry(f, { strategy: new FixedDelayStrategy(200), maxAttempts: 3 }, logger);
}

function* performSearch(
    action: Action<typeof SearchActionType.PERFORM_SEARCH, { searchVersion: SearchVersion | SearchVersionNew | QuickSearch; history: History; updateIsSearchingWhenDone?: boolean }>
) {
    const searchService = yield* SagaContext.getSearchService();
    const searchStorageService = yield* SagaContext.getStorageService();
    const logger = yield* SagaContext.getLogger();
    const addSearchWithRetry = withRetry3Times((searchVersion: SearchVersionNew) => searchStorageService.addSearch(searchVersion), logger);
    const updateSearchWithRetry = withRetry3Times((searchVersion: SearchVersion | QuickSearch) => searchStorageService.updateUnperformedSearch(searchVersion), logger);
    let searchVersion = action.payload.searchVersion;
    const history = action.payload.history;
    try {
        if (searchVersion.editState === "NEW") {
            //if the search hasn't been saved yet we need to save it now
            const savedSearch: Return<typeof addSearchWithRetry> = yield addSearchWithRetry(searchVersion);
            if (!ok(savedSearch)) {
                logAndAlert("Saving Search", savedSearch);
                return;
            }
            searchVersion = savedSearch;
        }
        if (searchVersion.editState === "UNSAVED") {
            //search has unsaved edits
            const savedSearch: Return<typeof updateSearchWithRetry> = yield updateSearchWithRetry(searchVersion);
            if (!ok(savedSearch)) {
                logAndAlert("Saving changes to Search", savedSearch);
                return;
            }
            searchVersion = savedSearch;
        }

        const performedSearch: Return<typeof searchService.executeSearch> = yield searchService.executeSearch(searchVersion.searchId, searchVersion.id, searchVersion.requestTerms);
        yield put(searchActions.performSearchSuccess(performedSearch));
        const searchProgress = getSearchProgress(performedSearch.summary?.hitCountByStatus);
        const actions: SnackbarAction[] = [{ text: "Profile", onClick: () => store.dispatch(searchActions.openResultDetailDialog()) }];
        if (searchProgress.progress === searchProgress.total) {
            yield put(userAlertActions.addUserAlert(`All hits resolved`, { type: "success", actions: actions }));
        }
        history.push("/search-requests/");
        return performedSearch;
    } catch (error) {
        window.alert(Messages.SearchCannotBeSavedBeforePerform.getMessage());
        logger.error("Unexpected Error Saving Search:", error);
        yield put(searchActions.performSearchFailure(parseError(error)));
    } finally {
        if (action.payload.updateIsSearchingWhenDone) {
            yield put(searchActions.isSearching(false));
        }
    }
}

function* addTermThenPerformSearch(
    action: Action<
        typeof SearchActionType.ADD_TERM_THEN_PERFORM_SEARCH,
        { searchVersion: SearchVersion | SearchVersionNew | QuickSearch; history: History; requestTerm: RequestTerm; updateIsSearchingWhenDone?: boolean; currentSearchActionId: string }
    >
) {
    const logger = yield* SagaContext.getLogger();
    try {
        const { searchVersion, history, requestTerm, updateIsSearchingWhenDone, currentSearchActionId } = action.payload;
        //Call add term
        const savedSearchWithAddedTerm: SearchVersionUnedited = yield call(saveSearch, {
            actionType: SearchActionType.SAVE_SEARCH,
            payload: { saveSearchActionInput: { actionType: "ADD_TERM", payload: { searchVersion, term: requestTerm } }, currentSearchActionId: currentSearchActionId }
        });

        yield put(searchActions.isCurrentSearchSaving(true));
        //TODO(1121): addTerm should return an error state instead of undefined
        if (savedSearchWithAddedTerm) {
            //Call perform search
            const performedSearch: Return<SearchService["executeSearch"]> = yield call(performSearch, {
                actionType: SearchActionType.PERFORM_SEARCH,
                payload: { searchVersion, history, updateIsSearchingWhenDone }
            });
            yield put(searchActions.addTermThenPerformSearchSuccess(performedSearch));
        }
    } catch (error) {
        logger.error("Unable to Update/Perform Search", error);
        yield put(searchActions.addTermThenPerformSearchFailure(parseError(error)));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* addTermThenSubmitSearch(
    action: Action<typeof SearchActionType.ADD_TERM_THEN_SUBMIT_SEARCH, { searchVersion: SearchVersion | SearchVersionNew; history: History; requestTerm: RequestTerm; currentSearchActionId: string }>
) {
    const { searchVersion, history, requestTerm, currentSearchActionId } = action.payload;
    const logger = yield* SagaContext.getLogger();
    try {
        //Call add term
        const savedSearchWithAddedTerm: SearchVersionUnedited = yield call(saveSearch, {
            actionType: SearchActionType.SAVE_SEARCH,
            payload: { saveSearchActionInput: { actionType: "ADD_TERM", payload: { searchVersion, term: requestTerm } }, currentSearchActionId: currentSearchActionId }
        });

        yield put(searchActions.isCurrentSearchSaving(true));
        //TODO(1121): addTerm should return an error state instead of undefined
        if (savedSearchWithAddedTerm) {
            //Call submit search
            const submittedSearch = yield call(submitSearch, {
                actionType: SearchActionType.SUBMIT_SEARCH,
                payload: { searchVersion: savedSearchWithAddedTerm, history: history }
            });

            history.push(`/search-requests/`);
            yield put(searchActions.addTermThenSubmitSearchSuccess(submittedSearch));
        }
    } catch (error) {
        logger.error("Unable to Update/Perform Search", error);
        yield put(searchActions.addTermThenPerformSearchFailure(parseError(error)));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* performQuickSearch(action: Action<typeof SearchActionType.PERFORM_QUICK_SEARCH, { quickSearch: QuickSearch; history: History; updateIsSearchingWhenDone?: boolean }>) {
    const searchService = yield* SagaContext.getSearchService();
    const searchStorageService = yield* SagaContext.getStorageService();
    const logger = yield* SagaContext.getLogger();
    const addQuickSearchWithRetry = withRetry3Times((quickSearch: QuickSearch) => searchStorageService.createQuickSearch(quickSearch), logger);
    const saveQuickSearchWithRetry = withRetry3Times((quickSearch: QuickSearch) => searchStorageService.saveQuickSearch(quickSearch), logger);
    const history = action.payload.history;
    try {
        let quickSearch: Result<QuickSearch, EtagMismatch | Forbidden | SearchErrors.InvalidSearchType | ValidationErrors | NotFound> = action.payload.quickSearch;
        quickSearch.status = "DRAFT";
        // search should have an etag if it has already been added
        if (quickSearch._etag) {
            quickSearch = yield saveQuickSearchWithRetry(quickSearch);
            if (!ok(quickSearch)) {
                logAndAlert("Saving quickSearch", quickSearch);
                return;
            }
        } else {
            quickSearch = yield addQuickSearchWithRetry(quickSearch);
            if (!ok(quickSearch)) {
                if (isForbidden(quickSearch)) {
                    logAndAlert("create a quick search", quickSearch);
                } else {
                    logAndAlert("Creating quickSearch", quickSearch);
                }
                return;
            }
        }

        yield put(searchActions.saveQuickSearchSuccess(quickSearch));
        const performedQuickSearch: Return<typeof searchService.executeQuickSearch> = yield searchService.executeQuickSearch(quickSearch.searchId, quickSearch.id);
        if (!ok(performedQuickSearch)) {
            logAndAlert("Performing quickSearch", performedQuickSearch);
            return;
        }

        yield put(searchActions.performQuickSearchSuccess(performedQuickSearch));
        history.push("/quick-searches/");
    } catch (error) {
        window.alert(Messages.SearchCannotBeSavedBeforePerform.getMessage());
        logger.error("Unexpected error searching QuickSearch:", error);
        yield put(searchActions.performQuickSearchFailure(parseError(error)));
    } finally {
        if (action.payload.updateIsSearchingWhenDone) {
            yield put(searchActions.isSearching(false));
        }
    }
}

function* performQuickSearchAgain(action: Action<typeof SearchActionType.PERFORM_QUICK_SEARCH_AGAIN, { searchId: SearchVersionIdentifier; previousStatus: SearchStatus; history: History }>) {
    const searchService = yield* SagaContext.getSearchService();
    const logger = yield* SagaContext.getLogger();
    const history = action.payload.history;
    try {
        history.push("/quick-searches/");
        const performedQuickSearch: Return<typeof searchService.executeQuickSearch> = yield searchService.executeQuickSearch(action.payload.searchId.searchId, action.payload.searchId.versionId);
        if (!ok(performedQuickSearch)) {
            logAndAlert("Searching quickSearch again", performedQuickSearch);
            return;
        }
        yield put(searchActions.performQuickSearchSuccess(performedQuickSearch));
    } catch (error) {
        window.alert(Messages.SearchCannotBeSavedBeforePerform.getMessage());
        logger.error("Unexpected error searching QuickSearch again:", error);
        yield put(searchActions.performQuickSearchAgainFailure({ searchId: action.payload.searchId, previousStatus: action.payload.previousStatus, error: parseError(error) }));
    }
}

function* updateDetails(action: Action<typeof SaveSearchActionTypes.UPDATE_DETAILS, { searchVersion: SearchVersion | SearchVersionNew; currentSearchActionId: string; reassignMessage?: string }>) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();

    try {
        yield put(searchActions.isCurrentSearchSaving(true));
        const updatedSearchVersion: Result<SearchVersionUnedited, ValidationErrors | NotFound | EtagMismatch> =
            action.payload.searchVersion.editState === "NEW"
                ? yield searchStorageService.addSearch(action.payload.searchVersion)
                : yield searchStorageService.updateUnperformedSearch(action.payload.searchVersion, action.payload.reassignMessage);

        if (ok(updatedSearchVersion)) {
            yield put(searchActions.saveSearchSuccess({ searchVersion: updatedSearchVersion, initialSearchActionId: action.payload.currentSearchActionId }));
        } else if (updatedSearchVersion._conflictserrortype === "VALIDATION") {
            yield put(searchActions.saveSearchFailure(action.actionType, updatedSearchVersion.errors.map((e) => e.message).join("\n")));
        } else {
            yield put(searchActions.saveSearchFailure(action.actionType, updatedSearchVersion.message));
        }

        return updatedSearchVersion;
    } catch (error) {
        logger.error("Unable to update Search Details", error);
        yield put(searchActions.saveSearchFailure(action.actionType, parseError(error)));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* updateHitStatus(
    action: Action<
        typeof SearchActionType.UPDATE_HITS,
        SearchVersionIdentifier & SearchVersionEtag & { hitIds: HitIdentifier[]; change: Partial<Hit>; onComplete: () => void; currentSearchActionId: string }
    >
) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    const actions: SnackbarAction[] = [{ text: "Profile", onClick: () => store.dispatch(searchActions.openResultDetailDialog()) }];
    const updateHitsWithRetry = withRetry3Times(
        (payload: APIs.WithEtag<SearchVersionIdentifier & { hitIds: HitIdentifier[]; change: Partial<Hit> }>) => searchStorageService.updateHits(payload),
        logger
    );
    const searchId = [action.payload.searchId];
    try {
        // set search as processing for immediate UI feedback
        yield put(searchActions.addSearchIdsCurrentlyProcessing(searchId));
        yield put(searchActions.isCurrentSearchSaving(true));
        const searchVersion: Return<typeof searchStorageService.updateHits> = yield updateHitsWithRetry(action.payload);
        if (!ok(searchVersion)) {
            logAndAlert("Updating Hit Status", searchVersion);
            yield put(searchActions.updateHitsFailure(searchVersion.message));
            return;
        }
        yield put(searchActions.updateHitsSuccess({ searchVersion: searchVersion, initialSearchActionId: action.payload.currentSearchActionId }));
        const searchProgress = getSearchProgress(searchVersion.summary?.hitCountByStatus);
        if (searchProgress.progress === searchProgress.total) {
            yield put(userAlertActions.addUserAlert(`All hits resolved`, { type: "success", actions: actions }));
        }
    } catch (error) {
        logger.error("Unable to Update Hit Status", error);
        yield put(userAlertActions.addUserAlert(`${action.payload.hitIds.length} hits failed to have their statuses changed to ${action.payload.change.status}`, { type: "error" }));
        yield put(searchActions.updateHitsFailure(parseError(error)));
    } finally {
        action.payload.onComplete();
        yield put(searchActions.removeSearchIdsCurrentlyProcessing(searchId));
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* addReassignErrorAlert(assignedToUserId: string | null | undefined, failedRequestCount: number) {
    if (!assignedToUserId) {
        yield put(userAlertActions.addUserAlert(`Failed to unassign ${failedRequestCount} requests`, { type: "error" }));
    } else {
        const user = yield select((state: RootState) => getUser(state, assignedToUserId));
        yield put(userAlertActions.addUserAlert(`${failedRequestCount} requests failed to be reassigned to ${user?.name}`, { type: "error" }));
    }
}

function* updateSearchesAssignedTo(
    action: Action<
        typeof SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO,
        { searchVersionIdentifiers: (SearchVersionIdentifier & SearchVersionEtag)[]; change: Pick<SearchVersion, "assignedToUserId">; currentSearchActionId: string }
    >
) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    const searchIds = action.payload.searchVersionIdentifiers.map((searchIds) => searchIds.searchId);
    const assignedToUserId = action.payload.change.assignedToUserId;
    try {
        // set searches as processing for immediate UI feedback
        yield put(searchActions.addSearchIdsCurrentlyProcessing(searchIds));
        yield put(searchActions.isCurrentSearchSaving(true));
        const results: APIs.SearchesUpdateResults = yield searchStorageService.updateSearchesAssignedTo(action.payload);
        // Sorting successful updates so only update those in the store
        const successfulUpdates: { versionId: string; etag?: string }[] = [];
        // Sorting failed updates.
        const failedUpdates: string[] = [];
        results.forEach((value) => {
            if (value.status === 200 && value.id) {
                successfulUpdates.push({ versionId: value.id, etag: value.body._etag });
            } else {
                failedUpdates.push(value.id);
            }
        });
        const etagMismatch = results.find((result) => result.status === 412);
        if (etagMismatch) {
            etagMismatch.status === 412 && logAndAlert("Reassign search", etagMismatch.body);
        }
        if (failedUpdates.length > 0 && successfulUpdates.length > 0) {
            yield call(addReassignErrorAlert, assignedToUserId, failedUpdates.length);
        }
        yield put(searchActions.updateSearchesAssignedToSuccess({ versionIds: successfulUpdates, change: action.payload.change, initialSearchActionId: action.payload.currentSearchActionId }));
    } catch (error) {
        logger.error("Unable to Update Assigned To", error);
        yield call(addReassignErrorAlert, assignedToUserId, action.payload.searchVersionIdentifiers.length);
        yield put(searchActions.updateSearchesAssignedToFailure(parseError(error)));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
        yield put(searchActions.removeSearchIdsCurrentlyProcessing(searchIds));
    }
}

function* updateSearchesSearchStatus(
    action: Action<
        typeof SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS,
        { searchVersionIdentifiers: (SearchVersionIdentifier & SearchVersionEtag)[]; change: Pick<SearchVersion, "status">; currentSearchActionId: string }
    >
) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    const searchIds = action.payload.searchVersionIdentifiers.map((searchIds) => searchIds.searchId);
    try {
        // set searches as processing for immediate UI feedback
        yield put(searchActions.addSearchIdsCurrentlyProcessing(searchIds));
        yield put(searchActions.isCurrentSearchSaving(true));
        const results: APIs.SearchesUpdateResults = yield searchStorageService.updateSearchesSearchStatus(action.payload);
        // Sorting successful updates so only update those in the store
        const successfulUpdates: { versionId: string; etag?: string }[] = [];
        // Sorting failed updates.
        const failedUpdates: string[] = [];
        results.forEach((value) => {
            if (value.status === 200 && value.id) {
                successfulUpdates.push({ versionId: value.id, etag: value.body._etag });
            } else {
                failedUpdates.push(value.id);
            }
        });
        const etagMismatch = results.find((result) => result.status === 412);
        if (etagMismatch) {
            etagMismatch.status === 412 && logAndAlert("Updating search status", etagMismatch.body);
        }
        if (failedUpdates.length > 0 && successfulUpdates.length > 0) {
            yield put(userAlertActions.addUserAlert(`${failedUpdates.length} requests failed to have their statuses changed to ${action.payload.change.status}`, { type: "error" }));
        }
        yield put(searchActions.updateSearchesSearchStatusSuccess({ versionIds: successfulUpdates, change: action.payload.change, initialSearchActionId: action.payload.currentSearchActionId }));
    } catch (error) {
        logger.error("Unable to Update Search Status", error);
        yield put(searchActions.updateSearchesSearchStatusFailure(parseError(error)));
        yield put(
            userAlertActions.addUserAlert(`${action.payload.searchVersionIdentifiers.length} requests failed to have their statuses changed to ${action.payload.change.status}`, { type: "error" })
        );
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
        yield put(searchActions.removeSearchIdsCurrentlyProcessing(searchIds));
    }
}

function* updateSearch(
    action: Action<typeof SearchActionType.UPDATE_SEARCH, SearchVersionEtag & { searchVersionIdentifier: SearchVersionIdentifier; change: SearchUpdateDelta; currentSearchActionId: string }>
) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    const searchId = [action.payload.searchVersionIdentifier.searchId];
    try {
        // set searches as processing for immediate UI feedback
        yield put(searchActions.addSearchIdsCurrentlyProcessing(searchId));
        yield put(searchActions.isCurrentSearchSaving(true));
        const results: Return<typeof searchStorageService.updateSearch> = yield searchStorageService.updateSearch(action.payload);
        if (!ok(results)) {
            logAndAlert("Updating search", results);
            yield put(searchActions.updateSearchFailure(results.message));
            return;
        }
        //We must always ever get one result
        const value = results;
        if (value.status === 200 && value.id) {
            yield put(
                searchActions.updateSearchSuccess({ versionId: { id: value.id, etag: value.body._etag }, change: action.payload.change, initialSearchActionId: action.payload.currentSearchActionId })
            );
        } else if (value.status !== 200) {
            logAndAlert("Updating search", value.body);
        }
    } catch (error) {
        logger.error("Unable to Update Search Status or Assigned to User", error);
        yield put(searchActions.updateSearchFailure(parseError(error)));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
        yield put(searchActions.removeSearchIdsCurrentlyProcessing(searchId));
    }
}

function* fetchAudits(action: Action<typeof SearchActionType.FETCH_AUDITS, { searchVersionId: string }>) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    try {
        const audits: Return<typeof searchStorageService.getAudits> = yield searchStorageService.getAudits(action.payload.searchVersionId);
        if (!ok(audits)) {
            logAndAlert("Fetching audits", audits);
            yield put(searchActions.fetchAuditsFailure(audits.message));
            return;
        }
        yield put(searchActions.fetchAuditsSuccess(audits));
    } catch (error) {
        logger.error("Unable to fetch Audits", error);
        yield put(searchActions.fetchAuditsFailure(parseError(error)));
    }
}

function* fetchEntity(action: Action<typeof SearchActionType.FETCH_ENTITY_DETAILS, EntityIdentifier>) {
    const logger = yield* SagaContext.getLogger();
    const entityStorageService = yield* SagaContext.getEntityStorageService();

    try {
        const entity = yield entityStorageService.getEntityDetails(action.payload, undefined);
        if (ok(entity)) {
            yield put(searchActions.fetchEntityDetailsSuccess({ entity }));
        } else {
            throw entity;
        }
    } catch (error) {
        logger.error("Unable to fetch Entities", error);
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        yield put(searchActions.fetchEntityDetailsFailure({ error: error as string, requestedId: action.payload.id }));
    }
}

function* createNewSearchVersion(action: Action<typeof SearchActionType.CREATE_NEW_SEARCH_VERSION, { searchVersion: SearchVersionEdited }>) {
    const searchStorageService = yield* SagaContext.getStorageService();
    const logger = yield* SagaContext.getLogger();

    const searchVersion = action.payload.searchVersion;

    try {
        yield put(searchActions.isCurrentSearchSaving(true));
        const createdVersion: Return<typeof searchStorageService.createNewVersion> = yield searchStorageService.createNewVersion(searchVersion);
        if (ok(createdVersion)) {
            yield put(searchActions.createNewSearchVersionSuccess(createdVersion));
        } else if (!ok(createdVersion)) {
            logAndAlert("Unable to create new search version", createdVersion);
        }
        return createdVersion;
    } catch (error) {
        logger.error("Unable to create new version", error);
        yield put(searchActions.createNewSearchVersionFailure(parseError(error)));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* createAndPerformNewVersion(
    action: Action<typeof SearchActionType.CREATE_AND_PERFORM_NEW_SEARCH_VERSION, { searchVersion: SearchVersionEdited; history: History; updateIsSearchingWhenDone?: boolean }>
) {
    const logger = yield* SagaContext.getLogger();
    const { searchVersion, history, updateIsSearchingWhenDone } = action.payload;
    try {
        const newVersion: Result<SearchVersionUnedited, APIs.SearchNewVersionErrors> = yield call(createNewSearchVersion, {
            actionType: SearchActionType.CREATE_NEW_SEARCH_VERSION,
            payload: { searchVersion: searchVersion }
        });
        yield put(searchActions.isCurrentSearchSaving(true));
        if (ok(newVersion)) {
            const performedNewVersion: Return<SearchService["executeSearch"]> = yield call(performSearch, {
                actionType: SearchActionType.PERFORM_SEARCH,
                payload: { searchVersion: newVersion, history, updateIsSearchingWhenDone }
            });
            yield put(searchActions.createAndPerformNewVersionSuccess(performedNewVersion));
        } else {
            yield put(searchActions.createAndPerformNewVersionFailure(newVersion.message));
            logAndAlert("Failed to create new search version", newVersion);
        }
    } catch (error) {
        logger.error("Unexpected error creating a new search version: ", error);
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        yield put(searchActions.createAndPerformNewVersionFailure(error as string));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* convertToSearchRequest(action: Action<typeof SearchActionType.CONVERT_TO_FULL_SEARCH, { quickSearchId: string; newSearchId: string; history: History }>) {
    const logger = yield* SagaContext.getLogger();
    const searchStorageService = yield* SagaContext.getStorageService();
    const { quickSearchId, newSearchId, history } = action.payload;

    try {
        history.push(`${PathMap.searchRequest}${newSearchId}`);
        yield put(searchActions.isCurrentSearchSaving(true));
        const convertQuickSearchResponse: Return<typeof searchStorageService.convertQuickSearch> = yield searchStorageService.convertQuickSearch({ newSearchId, quickSearchId });
        if (!ok(convertQuickSearchResponse)) {
            yield put(searchActions.convertToSearchRequestFailure(convertQuickSearchResponse.message));
            logAndAlert("The quick search could not be converted. Please try again", convertQuickSearchResponse);
            history.goBack();
        } else {
            const savedSearchResponse = convertQuickSearchResponse.find((response) => response.id === newSearchId);
            if (savedSearchResponse && MultiResponse.isSuccessItem(savedSearchResponse) && savedSearchResponse.body) {
                yield put(searchActions.convertToSearchRequestSuccess({ quickSearchId: quickSearchId, newSearchRequest: savedSearchResponse.body }));
            }

            const deleteQuickSearchResponse = convertQuickSearchResponse.find((response) => response.id === quickSearchId);
            if (deleteQuickSearchResponse && !MultiResponse.isSuccessItem(deleteQuickSearchResponse)) {
                logAndAlert("The search request was successfully created, but the original quick search could not be deleted.", deleteQuickSearchResponse.body);
            }
        }
    } catch (error) {
        logger.error("Unexpected error converting a quick search to a search request: ", error);
        logAndAlert("The quick search could not be converted. Please try again");
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        yield put(searchActions.convertToSearchRequestFailure(error as string));
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* saveSearch(
    action: Action<typeof SearchActionType.SAVE_SEARCH, { saveSearchActionInput: SaveSearchActionInputs; currentSearchActionId: string; doNotPersist?: boolean; reassignMessage?: string }>
) {
    const searchAction = action.payload.saveSearchActionInput;
    const logger = yield* SagaContext.getLogger();
    // when editing only local state we do not want to save - in this case, saveSearch will be called with noNotPersist = true
    if (action.payload.doNotPersist || searchAction.payload.searchVersion.isQuickSearch) {
        logger.info(`Skipped saveSearch for search id ${action.payload.saveSearchActionInput.payload.searchVersion.searchId}`);
        return;
    }
    let savedSearch: Result<SearchVersionUnedited, EtagMismatch | NotFound | ValidationErrors>;
    const currentSearch: SearchVersionEdited = yield select(getCurrentSearch);
    try {
        yield put(searchActions.isCurrentSearchSaving(true));
        switch (searchAction.actionType) {
            case SaveSearchActionTypes.ADD_TERM: {
                const searchToSend = updateSearchWithLatestEtag(searchAction.payload.searchVersion, currentSearch._etag);
                savedSearch = yield call(addTerm, {
                    actionType: searchAction.actionType,
                    payload: { ...searchAction.payload, searchVersion: searchToSend, currentSearchActionId: action.payload.currentSearchActionId }
                });
                break;
            }
            case SaveSearchActionTypes.ADD_TERMS: {
                const searchToSend = updateSearchWithLatestEtag(searchAction.payload.searchVersion, currentSearch._etag);
                savedSearch = yield call(addTerms, {
                    actionType: searchAction.actionType,
                    payload: { ...searchAction.payload, searchVersion: searchToSend, currentSearchActionId: action.payload.currentSearchActionId }
                });
                break;
            }
            case SaveSearchActionTypes.UPDATE_DETAILS: {
                const searchToSend = updateSearchWithLatestEtag(searchAction.payload.searchVersion, currentSearch._etag);
                savedSearch = yield call(updateDetails, {
                    actionType: searchAction.actionType,
                    payload: { ...searchAction.payload, searchVersion: searchToSend, currentSearchActionId: action.payload.currentSearchActionId }
                });
                break;
            }
            case SaveSearchActionTypes.DELETE_TERM: {
                const searchToSend = updateSearchWithLatestEtag(searchAction.payload.searchVersion, currentSearch._etag);
                savedSearch = yield call(deleteTerm, {
                    actionType: searchAction.actionType,
                    payload: { ...searchAction.payload, searchVersion: searchToSend, currentSearchActionId: action.payload.currentSearchActionId }
                });
                break;
            }
            case SaveSearchActionTypes.UPDATE_TERM: {
                const searchToSend = updateSearchWithLatestEtag(searchAction.payload.searchVersion, currentSearch._etag);
                savedSearch = yield call(updateTerm, {
                    actionType: searchAction.actionType,
                    payload: { ...searchAction.payload, searchVersion: searchToSend, currentSearchActionId: action.payload.currentSearchActionId }
                });
                break;
            }
            default:
                return;
        }
        if (ok(savedSearch)) {
            yield put(searchActions.saveSearchSuccess({ searchVersion: savedSearch, initialSearchActionId: action.payload.currentSearchActionId }));
            return savedSearch;
        } else {
            const error: EtagMismatch | NotFound | ValidationErrors = savedSearch;
            if (searchAction.actionType === SaveSearchActionTypes.UPDATE_DETAILS && error._conflictserrortype === "NOT_FOUND") {
                console.warn(`UPDATE_DETAILS: item was not found. Handling this silently as the search had probably been deleted by the current user`);
            } else {
                logAndAlert(searchAction.actionType, error);
            }
            yield put(searchActions.saveSearchFailure(searchAction.actionType, error.message));
        }
    } catch (error) {
        logger.error("Unexpected error saving search", error);
    } finally {
        yield put(searchActions.isCurrentSearchSaving(false));
    }
}

function* fetchHitComments(action: Action<typeof SearchActionType.FETCH_HIT_COMMENTS, { searchId: string; hitIdentifier: DurableHitIdentifier }>) {
    const searchStorageService = yield* SagaContext.getStorageService();
    const logger = yield* SagaContext.getLogger();

    try {
        const fetchedHitComments: Return<typeof searchStorageService.getHitComments> = yield searchStorageService.getHitComments(action.payload);
        if (ok(fetchedHitComments)) {
            yield put(searchActions.fetchHitCommentsSuccess({ hitComments: fetchedHitComments, hitIdentifier: action.payload.hitIdentifier }));
        } else {
            logger.error(HitCommentMessages.HITCOMMENTS_VIEW_FAILED.getMessage(fetchedHitComments.message));
            yield put(appActions.actionFailure(getUserFriendlyErrorMessageForHitComments("viewed", fetchedHitComments)));
            yield put(searchActions.fetchHitCommentsFailure({ durableHitIdentifier: action.payload.hitIdentifier, error: parseError(fetchedHitComments) }));
        }
    } catch (error) {
        logger.error(HitCommentMessages.HITCOMMENTS_VIEW_FAILED.getMessage(parseError(error)));
        yield put(appActions.actionFailure(getUserFriendlyErrorMessageForHitComments("viewed")));
        yield put(searchActions.fetchHitCommentsFailure({ durableHitIdentifier: action.payload.hitIdentifier, error: parseError(error) }));
    }
}

function* createHitComment(action: Action<typeof SearchActionType.CREATE_HIT_COMMENT, { hitComment: CreateHitCommentInput }>) {
    const searchStorageService = yield* SagaContext.getStorageService();
    const logger = yield* SagaContext.getLogger();

    try {
        const newHitCommentResponse: Return<typeof searchStorageService.createHitComment> = yield searchStorageService.createHitComment(action.payload);
        if (ok(newHitCommentResponse)) {
            yield put(searchActions.createHitCommentSuccess({ hitComment: newHitCommentResponse }));
        } else {
            logger.error(HitCommentMessages.HITCOMMENT_ACTION_FAILED.getMessage("created", newHitCommentResponse.message));
            yield put(appActions.actionFailure(getUserFriendlyErrorMessageForHitComments("created", newHitCommentResponse, action.payload.hitComment.text)));
            yield put(
                searchActions.createHitCommentFailure({
                    durableHitIdentifier: action.payload.hitComment.durableHitIdentifier,
                    hitCommentText: action.payload.hitComment.text,
                    error: parseError(newHitCommentResponse)
                })
            );
        }
    } catch (error) {
        logger.error(HitCommentMessages.HITCOMMENT_ACTION_FAILED.getMessage("created", parseError(error)));
        yield put(appActions.actionFailure(getUserFriendlyErrorMessageForHitComments("created", undefined, action.payload.hitComment.text)));
        yield put(
            searchActions.createHitCommentFailure({
                durableHitIdentifier: action.payload.hitComment.durableHitIdentifier,
                hitCommentText: action.payload.hitComment.text,
                error: parseError(error)
            })
        );
    }
}

function* editHitComment(action: Action<typeof SearchActionType.EDIT_HIT_COMMENT, { editHitCommentInput: EditHitCommentInput; durableHitIdentifier: DurableHitIdentifier }>) {
    const searchStorageService = yield* SagaContext.getStorageService();
    const logger = yield* SagaContext.getLogger();

    try {
        const editHitCommentResponse: Return<typeof searchStorageService.editHitComment> = yield searchStorageService.editHitComment(action.payload);
        if (ok(editHitCommentResponse)) {
            yield put(searchActions.editHitCommentSuccess({ hitComment: editHitCommentResponse }));
        } else {
            logger.error(HitCommentMessages.HITCOMMENT_ACTION_FAILED.getMessage("edited", editHitCommentResponse.message));
            yield put(appActions.actionFailure(getUserFriendlyErrorMessageForHitComments("edited", editHitCommentResponse, action.payload.editHitCommentInput.newHitCommentText)));
            yield put(
                searchActions.updateHitCommentFailure({
                    durableHitIdentifier: action.payload.durableHitIdentifier,
                    hitCommentId: action.payload.editHitCommentInput.hitCommentId,
                    error: parseError(editHitCommentResponse)
                })
            );
        }
    } catch (error) {
        logger.error(HitCommentMessages.HITCOMMENT_ACTION_FAILED.getMessage("edited", parseError(error)));
        yield put(appActions.actionFailure(getUserFriendlyErrorMessageForHitComments("edited", undefined, action.payload.editHitCommentInput.newHitCommentText)));
        yield put(
            searchActions.updateHitCommentFailure({
                durableHitIdentifier: action.payload.durableHitIdentifier,
                hitCommentId: action.payload.editHitCommentInput.hitCommentId,
                error: parseError(error)
            })
        );
    }
}

function* deleteHitComment(action: Action<typeof SearchActionType.DELETE_HIT_COMMENT, { hitCommentCosmosId: HitCommentCosmosId; durableHitIdentifier: DurableHitIdentifier }>) {
    const searchStorageService = yield* SagaContext.getStorageService();
    const logger = yield* SagaContext.getLogger();

    try {
        const deleteHitCommentResponse: Return<typeof searchStorageService.deleteHitComment> = yield searchStorageService.deleteHitComment(action.payload);
        if (ok(deleteHitCommentResponse)) {
            yield put(searchActions.deleteHitCommentSuccess({ hitCommentCosmosId: action.payload.hitCommentCosmosId, durableHitIdentifier: action.payload.durableHitIdentifier }));
        } else {
            logger.error(HitCommentMessages.HITCOMMENT_ACTION_FAILED.getMessage("deleted", deleteHitCommentResponse.message));
            yield put(appActions.actionFailure(getUserFriendlyErrorMessageForHitComments("deleted", deleteHitCommentResponse)));
            yield put(
                searchActions.updateHitCommentFailure({
                    durableHitIdentifier: action.payload.durableHitIdentifier,
                    hitCommentId: action.payload.hitCommentCosmosId.hitCommentId,
                    error: parseError(deleteHitCommentResponse)
                })
            );
        }
    } catch (error) {
        logger.error(HitCommentMessages.HITCOMMENT_ACTION_FAILED.getMessage("deleted", parseError(error)));
        yield put(appActions.actionFailure(getUserFriendlyErrorMessageForHitComments("deleted")));
        yield put(
            searchActions.updateHitCommentFailure({
                durableHitIdentifier: action.payload.durableHitIdentifier,
                hitCommentId: action.payload.hitCommentCosmosId.hitCommentId,
                error: parseError(error)
            })
        );
    }
}
