import { CognitiveSearchSecrets, CosmosSecrets } from "../ConnectionDetails";

export function getTestCosmosCredentials(secret?: Partial<CosmosSecrets>): CosmosSecrets {
    return {
        endpoint: secret?.endpoint || "",
        key: secret?.key || "",
        databaseId: secret?.databaseId || "conflicts"
    };
}

export function getTestCognitiveSearchCredentials(secret?: Partial<CognitiveSearchSecrets>): CognitiveSearchSecrets {
    return {
        name: secret?.name || "",
        adminKey: secret?.adminKey || "",
        queryKey: secret?.queryKey || ""
    };
}
