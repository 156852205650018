import { Message } from "aderant-conflicts-models";

export const Messages = {
    BUTTON_ADD_SYNONYM_TERM: new Message("BUTTON_ADD_SYNONYM_TERM", "Add Synonym Term"),
    BUTTON_UPDATE_SYNONYM_MAP: new Message("BUTTON_UPDATE_SYNONYM_MAP", "Update Synonym Map"),
    BUTTON_LEAVE_WITHOUT_UPDATING: new Message("BUTTON_LEAVE_WITHOUT_UPDATING", "Leave Without Updating"),

    ADD_AND_REMOVE_TERMS: new Message("ADD_AND_REMOVE_TERMS", "Add and Remove Terms"),
    TERMS: new Message("TERMS", "Terms"),
    MAX_TERMS: new Message("MAX_TERMS", "Max terms"),
    ENTER_NEW_SYNONYM_TERM: new Message("ENTER_NEW_SYNONYM_TERM", "Enter new synonym term"),
    ENTER_TEXT_TO_FILTER_OR_ADD_NEW: new Message("ENTER_TEXT_TO_FILTER_OR_ADD_NEW", "Enter text to filter or add new"),

    REMOVE_SET_TITLE: new Message("REMOVE_SET_TITLE", "Remove Synonym Set"),
    REMOVE_SET_MESSAGE: new Message<[number, string]>("REMOVE_SET_MESSAGE", "The synonym set containing the following {0} terms will be removed: {1}"),
    UPDATE_SYNONYM_MAP_TITLE: new Message("UPDATE_SYNONYM_MAP_TITLE", "Update Synonym Map"),
    UPDATE_SYNONYM_MAP_MESSAGE: new Message("UPDATE_SYNONYM_MAP_MESSAGE", "Are you sure you want to update this synonym map? Any search requests currently being searched may be delayed."),
    CANCEL_CHANGES_TITLE: new Message("CANCEL_CHANGES_TITLE", "Cancel Changes"),
    CANCEL_CHANGES_MESSAGE: new Message("CANCEL_CHANGES_MESSAGE", "Are you sure you want to cancel your changes?"),
    INCLUDE_SYNONYMS_IN_ALL_SEARCHES: new Message("INCLUDE_SYNONYMS_IN_ALL_SEARCHES", "Include synonyms in all searches")
};
