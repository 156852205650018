import { Logger, MockLogger } from "aderant-web-fw-core";
import { SearchIndexer } from "../SearchDataIngestion/SearchIndexConnector";

const FieldsForIndex = [
    "id",
    "entityType",
    "name",
    "note",
    "clientId",
    "contactId",
    "clientPartyId",
    "employeeId",
    "fileOpeningDescription",
    "description",
    "longName",
    "comments",
    "claimNumber",
    "matterId",
    "matterPartyId",
    "vendorId",
    "requestTerms",
    "searchTerms"
];

const SearchableFieldsForIndex = ["name", "note", "fileOpeningDescription", "description", "longName", "comments", "claimNumber", "requestTerms", "searchTerms"];

export class SearchIndexConnectorMock implements SearchIndexer {
    indexRecords = jest.fn((records: any[], indexName: string): Promise<void> => {
        return Promise.resolve();
    });
    removeIndexedRecords = jest.fn((recordIds: any[], indexName: string) => {
        return Promise.resolve();
    });
    getFieldsForIndex = jest.fn((tenancyIndexName: string): Promise<string[]> => {
        return Promise.resolve(FieldsForIndex);
    });
    getSearchableFieldsForIndex = jest.fn((tenancyIndexName: string): Promise<string[]> => {
        return Promise.resolve(SearchableFieldsForIndex);
    });
}

export function getSearchIndexConnectorMock(logger?: Logger) {
    logger = logger ?? new MockLogger();
    const mock = new SearchIndexConnectorMock();
    return mock;
}
