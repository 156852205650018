import { ConflictsAction } from "../../Permissions/UserPermission";
import { satisfies } from "../../UtilityTypes";
import { Messages } from "../Messages";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./InternalTypes/FirmSettingsPageDefinition";
/**
 * "Information Barriers" is the UI name for row level security config.  This section has the bit that lets users select which fields to un-redact on specific rows
 */
const informationBarriersSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "information-barriers",
    displayName: Messages.INFORMATION_BARRIERS,
    defaultValue: {},
    type: "handwritten",
    internalUsersOnly: false
} as const);

export const InformationBarriersDefinition = satisfies<FirmSettingsPageDefinition>()({
    name: "information-barriers",
    displayName: Messages.INFORMATION_BARRIERS.getMessage(),
    sections: [informationBarriersSection],
    requiredPermission: ConflictsAction.ViewAndUpdateRlsColumnVisibility,
    internalUsersOnly: false,
    dataToLoad: ["RLS_COLUMN_VISIBILITY"]
} as const);
