import { Lookup, RawLookup } from "../../Lookup";
import { unexpectedError } from "../Errors/BasicErrors";

export function validateLookupList(rawLookupList: any): Lookup[] {
    let validLookupList: Lookup[] = [];

    if (rawLookupList === null || rawLookupList === undefined || !Array.isArray(rawLookupList)) {
        throw unexpectedError(`${rawLookupList} is not a valid lookup object. Expected an array of lookups.`, "validateLookupList");
    } else {
        validLookupList = createValidLookupList(rawLookupList);
    }

    return validLookupList;
}

function createValidLookupList(rawLookupList: RawLookup[]): Lookup[] {
    const idlessLookups: RawLookup[] = rawLookupList.filter((lookup: RawLookup) => !lookup.id);

    if (idlessLookups.length > 0) {
        const idlessLookupsStringified = idlessLookups.map((c) => JSON.stringify(c)).join(", ");
        throw unexpectedError(`Expected an array of lookups containing 'id'. The following are not valid lookup objects: ${idlessLookupsStringified}`, "validateLookupList");
    } else {
        return rawLookupList.map((lookup) => {
            return { label: lookup.description ?? lookup.id!, value: lookup.id! };
        });
    }
}
