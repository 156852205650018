import { ConflictsTenantBlobStorage, ok, Result, ValidationMessage, ValidationErrors, wrapValidationErrors, isNotFound, isJsonParseError, unexpectedError } from "aderant-conflicts-models";
import { KeyAuthFunctionAppContext, UserlessKeyAuthFunctionAppContext } from "../../ConflictsContext";
import { EntityConfigurationService } from "../EntityConfiguration/EntityConfigurationService";
import { BlobStorageConnector } from "../../BlobStorageConnector/BlobStorageConnector";
import { validateTemplates as validateEntityTemplates } from "../EntityConfiguration/inputValidator";
import { emptyOkResponse, EmptyOkResponse } from "../../Http/HttpResponse";
import { TypeValidationMessages as messages } from "../../APIs/Messages";
import { EntityConfiguration } from "../EntityConfiguration/EntityConfiguration";
import { GridConfigurationService } from "../GridConfiguration/GridConfigurationService";
import { validateGridDefinitions } from "../GridConfiguration/validateGridDefinitions";
import { validateIndexDefinitionAgainstSchema } from "../../SchemaValidation/validateSchema";
// No types file exists for this library so we must use it as is
// eslint-disable-next-line @typescript-eslint/no-var-requires
const avro = require("avro-js");

/**
 * This function validates all definitions in the customization-wip container.
 */
export async function validateCustomizationWip(
    context: UserlessKeyAuthFunctionAppContext | KeyAuthFunctionAppContext,
    input: {
        tenancyName: string;
    },
    entityConfigurationService: EntityConfigurationService,
    gridConfigurationService: GridConfigurationService
): Promise<Result<EmptyOkResponse, ValidationErrors>> {
    context.logger.info("Validating Customization WIP.");
    context.logger.debug("Checking validity of tenancyName");

    if (!input.tenancyName || input.tenancyName.trim() === "") {
        throw unexpectedError(`Tenancy name '${input.tenancyName}' is invaild`, "validateCustomizationWip");
    }

    const reasonsIsInvalid: ValidationMessage[] = [];
    const blobConnector = await BlobStorageConnector.open((await context.getBlobStorageSecrets(input.tenancyName)).connectionString);

    const customizationWipExists = await blobConnector.doesContainerExist(ConflictsTenantBlobStorage.CustomizationWip.containerName);
    if (!customizationWipExists) {
        return emptyOkResponse();
    }

    //Validate the entity flyout definition
    const entityFlyoutValidationErrors = await validateEntityFlyoutDefinition(context, input.tenancyName, blobConnector, entityConfigurationService);
    if (entityFlyoutValidationErrors.length > 0) {
        reasonsIsInvalid.push(...entityFlyoutValidationErrors);
    }
    //Validate the hit results grid definition
    const hitResultGridValidationErrors = await validateHitResultGridDefinition(context, input.tenancyName, blobConnector, gridConfigurationService);
    if (hitResultGridValidationErrors.length > 0) {
        reasonsIsInvalid.push(...hitResultGridValidationErrors);
    }

    //Validate the avro schemas
    const avroSchemaValidationErrors = await validateAvroSchemas(blobConnector);
    reasonsIsInvalid.push(...avroSchemaValidationErrors);

    //Validate the SQL queries
    const sqlQueryValidationErrors = await validateSqlQueries(blobConnector);
    reasonsIsInvalid.push(...sqlQueryValidationErrors);

    //Validate the index definition
    const indexDefinitionValidationErrors = await validateIndexDefinition(context, blobConnector, input.tenancyName);
    reasonsIsInvalid.push(...indexDefinitionValidationErrors);

    if (reasonsIsInvalid.length > 0) {
        return wrapValidationErrors(reasonsIsInvalid);
    }
    return emptyOkResponse();
}

async function validateAvroSchemas(blobConnector: BlobStorageConnector): Promise<ValidationMessage[]> {
    const schemaBlobHeaders = await blobConnector.getBlobFileHeaders(ConflictsTenantBlobStorage.CustomizationWip.containerName, "schemas");
    const errors: ValidationMessage[] = [];
    for (let i = 0; i < schemaBlobHeaders.length; i++) {
        let schemaBlobBuffer;
        try {
            schemaBlobBuffer = await blobConnector.getBlobContentAsBuffer(ConflictsTenantBlobStorage.CustomizationWip.containerName, schemaBlobHeaders[i].name);
        } catch (error: any) {
            throw unexpectedError(error, `Failed to read blob content buffer (in "validateCustomizationWip/validateAvroSchemas")`);
        }

        let unreadSchema;
        try {
            unreadSchema = schemaBlobBuffer.toString();
        } catch (error: any) {
            throw unexpectedError(error, `Failed to read blob content buffer to output string (in "validateCustomizationWip/validateAvroSchemas")`);
        }

        let readSchema;
        try {
            readSchema = JSON.parse(unreadSchema);
        } catch (error: any) {
            errors.push({
                message: `Failed to parse AVRO schema '${schemaBlobHeaders[i].name}':  Schema is not valid JSON.  Error message: ${error?.message || error?.errorMessage || JSON.stringify(error)}`,
                messageCode: "400"
            });
            continue;
        }

        try {
            avro.parse(readSchema);
        } catch (error: any) {
            errors.push({
                message: `Failed to parse AVRO schema '${schemaBlobHeaders[i].name}':  Schema is not a valid AVRO schema.  Error message: ${
                    error?.message || error?.errorMessage || JSON.stringify(error)
                }`,
                messageCode: "400"
            });
            continue;
        }
    }
    return errors;
}

async function validateSqlQueries(blobConnector: BlobStorageConnector): Promise<ValidationMessage[]> {
    const queryBlobHeaders = await blobConnector.getBlobFileHeaders(ConflictsTenantBlobStorage.CustomizationWip.containerName, "query");
    const errors: ValidationMessage[] = [];
    for (let i = 0; i < queryBlobHeaders.length; i++) {
        let queryBlobBuffer;
        try {
            queryBlobBuffer = await blobConnector.getBlobContentAsBuffer(ConflictsTenantBlobStorage.CustomizationWip.containerName, queryBlobHeaders[i].name);
        } catch (error: any) {
            throw unexpectedError(error, `Failed to read blob content buffer (in "validateCustomizationWip/validateSqlQueries")`);
        }

        let unreadQueryObj;
        try {
            unreadQueryObj = queryBlobBuffer.toString();
        } catch (error: any) {
            throw unexpectedError(error, `Failed to read blob content buffer to output string (in "validateCustomizationWip/validateSqlQueries")`);
        }

        let query: any;
        try {
            query = JSON.parse(unreadQueryObj);
        } catch (error: any) {
            errors.push({
                message: `Failed to parse query file '${queryBlobHeaders[i].name}':  File is not valid JSON.  Error message: ${error?.message || error?.errorMessage || JSON.stringify(error)}`,
                messageCode: "400"
            });
            continue;
        }

        if (!query.query) {
            errors.push({
                message: `No 'query' parameter present on query file '${queryBlobHeaders[i].name}'`,
                messageCode: "400"
            });
            continue;
        }
    }
    return errors;
}

async function validateIndexDefinition(context: UserlessKeyAuthFunctionAppContext | KeyAuthFunctionAppContext, blobConnector: BlobStorageConnector, tenancyName: string): Promise<ValidationMessage[]> {
    const indexDefinitionBlob = await blobConnector.getBlob(ConflictsTenantBlobStorage.CustomizationWip.containerName, ConflictsTenantBlobStorage.CustomizationWip.indexDefinition);
    if (!ok(indexDefinitionBlob)) {
        if (indexDefinitionBlob.httpStatusCode == 404) {
            //Assuming here that the index definition in configuration is valid.
            context.logger.debug("No index definition in Customization WIP container.  Skipping validation, as this will not be copied");
            return [];
        }
        throw unexpectedError(indexDefinitionBlob, `Could not load index definition blob due to an error  (in "validateCustomizationWip/validateIndexDefinition")`);
    }
    let indexDefinitionBufferContents;
    try {
        indexDefinitionBufferContents = (await blobConnector.streamToBuffer(indexDefinitionBlob.readableStreamBody!)).toString();
    } catch (error: any) {
        throw unexpectedError(error, `Failed to read blob content buffer (in "validateCustomizationWip/validateIndexDefinition")`);
    }

    let indexDefinition;
    try {
        indexDefinition = JSON.parse(indexDefinitionBufferContents);
    } catch (error: any) {
        throw unexpectedError(error, `Failed to parse index definition as JSON (in "validateCustomizationWip/validateIndexDefinition")`);
    }

    return validateIndexDefinitionAgainstSchema(indexDefinition, tenancyName);
}

async function validateEntityFlyoutDefinition(
    context: UserlessKeyAuthFunctionAppContext | KeyAuthFunctionAppContext,
    tenancyName: string,
    blobConnector: BlobStorageConnector,
    entityConfigurationService: EntityConfigurationService
): Promise<ValidationMessage[]> {
    context.logger.info("Validating Entity Flyout Definition in Customization WIP.");
    const validationErrors: ValidationMessage[] = [];

    const flyoutConfigurationTemplates = await entityConfigurationService.getCustomEntityFlyoutConfigurationTemplates(
        context,
        tenancyName,
        blobConnector,
        ConflictsTenantBlobStorage.CustomizationWip.containerName
    );
    if (!ok(flyoutConfigurationTemplates)) {
        if (isNotFound(flyoutConfigurationTemplates)) {
            context.logger.info("No Custom Entity Flyout Definition found in Customization WIP. Nothing to validate.");
            return validationErrors;
        } else if (isJsonParseError(flyoutConfigurationTemplates)) {
            //The JSON in the file is malformed
            // The rootCause is an error, so is of unknown type. It most likely has a message property, but it's not guaranteed.
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            validationErrors.push(messages.VLD_SYNTAX_JSON.asValidationMessage(EntityConfiguration.FlyoutDefinition, (flyoutConfigurationTemplates.rootCause as any)?.message || ""));
            return validationErrors;
        }
    }
    const flyoutTemplateValidation = validateEntityTemplates(flyoutConfigurationTemplates.configurations);
    if (!ok(flyoutTemplateValidation)) {
        validationErrors.push(...flyoutTemplateValidation.errors);
    }

    return validationErrors;
}

async function validateHitResultGridDefinition(
    context: UserlessKeyAuthFunctionAppContext | KeyAuthFunctionAppContext,
    tenancyName: string,
    blobConnector: BlobStorageConnector,
    gridConfigurationService: GridConfigurationService
): Promise<ValidationMessage[]> {
    context.logger.info("Validating Hit Result Grid Definition in Customization WIP.");
    const validationErrors: ValidationMessage[] = [];
    const hitResultGridReservedWords: string[] = [
        "version",
        "entityId",
        "hitStatusDisplayValue",
        "previousVersionStatusDisplayValue",
        "previousVersionStatus",
        "requestTerm",
        "sourceType",
        "descriptionFieldName",
        "affiliation",
        "searchRequestPartyStatus",
        "conflictReasonClientParty",
        "conflictReasonMatterParty"
    ];

    const resultGridConfigurationTemplates = await gridConfigurationService.getCustomGridConfiguration(
        context,
        tenancyName,
        blobConnector,
        ConflictsTenantBlobStorage.CustomizationWip.hitResultGridDefinition
    );
    if (!ok(resultGridConfigurationTemplates)) {
        if (isNotFound(resultGridConfigurationTemplates)) {
            context.logger.info("No Custom Hit Result Grid Definition found in Customization WIP. Nothing to validate.");
            return validationErrors;
        } else if (isJsonParseError(resultGridConfigurationTemplates)) {
            //The JSON in the file is malformed
            // The rootCause is an error, so is of unknown type. It most likely has a message property, but it's not guaranteed.
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            validationErrors.push(messages.VLD_SYNTAX_JSON.asValidationMessage("Hit Result Grid Definition", (resultGridConfigurationTemplates.rootCause as any)?.message || ""));
            return validationErrors;
        }
    }
    const gridDefinitionValidation = validateGridDefinitions(resultGridConfigurationTemplates.configurations, "Hit Result Grid Definition", hitResultGridReservedWords);
    if (!ok(gridDefinitionValidation)) {
        validationErrors.push(...gridDefinitionValidation.errors);
    }

    return validationErrors;
}
