import { Message } from "./Validation/Message";

/**
 * Used to identify the hit across search versions.
 *
 * The entity id and type are stored instead of the hit id. This is to simplify carrying comments between versions.
 */
export type DurableHitIdentifier = {
    /**
     * The id of the request term in the search version.
     */
    requestTermId: string;
    /**
     * The id of the hit entity.
     */
    hitEntityId: string;
    /**
     * The hit entity type.
     */
    hitEntityType: string;
};

/**
 * Information about a single hit comment.
 */
export interface HitComment {
    /**
     * guid - hit comment id
     */
    id: string;
    /**
     * Used to identify the hit across search versions.
     */
    durableHitIdentifier: DurableHitIdentifier;
    /**
     * searchId: The search id of the search version that the hit is attached to.
     */
    searchId: string;
    /**
     * versionId: The version id of the search version that the hit was attached to when the comment was first made.
     */
    versionId: string;
    /**
     * The comment text.
     */
    text: string;
    /**
     * guid - The user who made the comment.
     */
    createdByUserId: string;
    /**
     * The date the comment was first created.
     */
    createdOn: Date;
    /**
     * The date the comment was last edited, matches the createdOn date when first created.
     */
    lastModified: Date;
    /**
     * The type of document.
     */
    documentType: "HitComment";
}

export type CreateHitCommentInput = Pick<HitComment, "searchId" | "text" | "durableHitIdentifier"> &
    Partial<Pick<HitComment, "id" | "documentType" | "createdByUserId" | "createdOn" | "lastModified" | "versionId">>;

export type HitCommentCosmosId = { hitCommentId: string; searchId: string };

export type EditHitCommentInput = HitCommentCosmosId & { newHitCommentText: string };

export const HitCommentMessages = {
    HITCOMMENT_INVALID_SEARCH_STATUS: new Message<[searchStatus: string, crudAction: "created" | "edited" | "deleted"]>(
        "HITCOMMENT_INVALID_SEARCH_STATUS",
        "The search request's status {0} does not allow hit comments to be {1}."
    ),
    HITCOMMENT_INVALID_NEW_VERSION_SEARCH_STATUS: new Message<[crudAction: "viewed" | "created" | "edited" | "deleted"]>(
        "HITCOMMENT_INVALID_NEW_VERSION_SEARCH_STATUS",
        "Hit comments on this version can no longer be {0} as a new version of the search has been created. Try again on the new version of the search."
    ),
    HITCOMMENT_INVALID_QUICKSEARCH: new Message<[crudAction: "viewed" | "created" | "edited" | "deleted"], string>("HITCOMMENT_INVALID_QUICKSEARCH", "Hit comments cannot be {0} on a quick search."),
    REQUEST_TERM_ID_NOT_FOUND: new Message<[requestTermId: string]>("REQUEST_TERM_ID_NOT_FOUND", "The request term id {0} was not found in the search version."),
    ENTITY_ID_AND_TYPE_NOT_FOUND: new Message<[entityId: string, entityType: string, requestTermId: string]>(
        "ENTITY_ID_AND_TYPE_NOT_FOUND",
        "The entity id {0} for entity type {1} was not found in the request term {2}."
    ),
    SEARCH_MUST_HAVE_BEEN_SEARCHED: new Message("SEARCH_MUST_HAVE_BEEN_SEARCHED", "Search that has not been searched should not have hits and so shouldn't have hit comments."),
    SEARCH_NOT_FOUND: new Message<[searchId: string]>("SEARCH_NOT_FOUND", "Search {0} was not found."),
    HITCOMMENT_ACTION_FAILED_RETRY: new Message<[crudAction: "created" | "edited" | "deleted"]>("HITCOMMENT_ACTION_FAILED_RETRY", "The comment could not be {0}. Please try again."),
    HITCOMMENTS_VIEW_FAILED_RETRY: new Message("HITCOMMENTS_VIEW_FAILED_RETRY", "The comments could not be loaded. Refresh the page and try again."),
    HITCOMMENT_ACTION_FAILED: new Message<[crudAction: "created" | "edited" | "deleted", detailedError: string]>("HITCOMMENT_ACTION_FAILED", "The comment could not be {0}. {1}"),
    HITCOMMENTS_VIEW_FAILED: new Message<[detailedError: string]>("HITCOMMENTS_VIEW_FAILED", "The comments could not be loaded. {0}"),
    HIT_COMMENT_PERMISSION_DENIED: new Message<[entityId: string, entityType: string, requestTermId: string, searchId: string, action: string]>(
        "HIT_COMMENT_PERMISSION_DENIED",
        "You do not have permission to {4} a comment on the hit with entity id {0} of entity type {1} for the request term {2} of search {3}."
    ),
    HITCOMMENT_PERMISSION_DENIED_SIMPLE: new Message<[action: string]>("HITCOMMENT_PERMISSION_DENIED_SIMPLE", "You do not have permission to {4} a comment on the hit."),
    HITCOMMENT_TEXT_LOST: new Message<[hitCommentText: string]>(
        "HITCOMMENT_TEXT_LOST",
        "The comment will be lost when this dialog is closed. We recommend copying the text if you wish to try posting again later. The comment text was: {0}"
    ),
    HITCOMMENT_NEW_VERSION_HIT_NOT_FOUND: new Message<[action: string]>(
        "HITCOMMENT_NEW_VERSION_HIT_NOT_FOUND",
        "A new version for this search request has been created and this hit has been removed, so you can no longer {0} a comment on it."
    ),
    HITCOMMENT_NOT_FOUND: new Message("HITCOMMENT_NOT_FOUND", "Comment was not found.")
};
