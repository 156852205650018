import { SearchRequestMessageStatus, SearchVersionIdentifier } from "aderant-conflicts-models";
import { AzureQueueFunctionDefinition } from "../../AzureFunctionDefinition";

export type ProcessSearchResultsMessage = {
    searchId: SearchVersionIdentifier;
    status: "COMPLETE" | "ERRORED";
    messages: SearchRequestMessageStatus[];
};

export const FirmDataService = {
    queueTriggerFunctions: {
        processSearchResults: new AzureQueueFunctionDefinition<ProcessSearchResultsMessage>({ queueName: "process-search-results-messages", bindingVariableName: "processSearchResultsMessage" }),
        processSearchResultsPoison: new AzureQueueFunctionDefinition<ProcessSearchResultsMessage>({ queueName: "process-search-results-messages", bindingVariableName: "processSearchResultsMessage" })
    }
};
