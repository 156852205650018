import { ListRefinerGroup, RefinerGroup, RefinerOption } from "@aderant/aderant-react-components";
import _ from "lodash";
import { RootState } from "MyTypes";
import { ViewByRefiner } from "state/reducers/ReducerStateTypes";
import { RefinerState } from "state/reducers/refinerReducers";

export const allRefinersSelector = (state: RootState): RefinerGroup[] => state.refiners.refiners;
export const refinerGroupSelector = (state: RefinerState, path: string): RefinerGroup | undefined => state.refiners.find((g) => g.path == path);
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const refinerOptionSelector = (refiners: ListRefinerGroup[], path: string, value: any): RefinerOption | undefined =>
    refiners.find((g) => g.path == path)?.refinerOptions.find((o) => o.value == value);
export const orderedRefinersSelector = (state: RootState): RefinerGroup[] => {
    const refiners = _.cloneDeep(state.refiners.refiners);
    refiners.sort((r1, r2) => state.refiners.orderedRefinerPaths.indexOf(r1.path) - state.refiners.orderedRefinerPaths.indexOf(r2.path));
    return refiners;
};
export const viewByRefinerSelector = (state: RootState): ViewByRefiner[] => state.refiners.viewByRefiners;
