import { Logger } from "aderant-web-fw-core";
import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpBody } from "../../../..";
import { HttpVerb } from "../../../Http/HttpVerb";
import { DateReviverOptions, getResponseTransformer } from "../../../Http/transformHttpResponse";

export type HttpRequest = {
    url: string;
    httpVerb: HttpVerb;
    headers?: Record<string, string>;
    body?: HttpBody;
    params?: HttpQueryParams;
};

export type HttpQueryParams = Record<string, string | number | boolean>;

export type HttpRequestOptions = {
    /**Overrides the axios maxBodyLength field (axios default is 10mb) */
    maxRequestSizeBytes?: number;
    /**Overrides the axios maxContentLength field (axios default is 10mb) */
    maxResponseSizeBytes?: number;
};

export async function sendRequest<Out>(logger: Logger, request: HttpRequest, options: HttpRequestOptions = {}, dateReviverOptions?: DateReviverOptions): Promise<AxiosResponse<Out>> {
    logger.debug("%s: Sending %s request to %s", new Date().toISOString(), request.httpVerb, request.url);

    let result: AxiosResponse<Out>;
    const config: AxiosRequestConfig = { headers: request.headers, params: request.params, maxBodyLength: options.maxRequestSizeBytes, maxContentLength: options.maxResponseSizeBytes };
    switch (request.httpVerb) {
        case "POST": {
            result = await Axios.post<Out>(request.url, request.body, {
                ...config,
                transformResponse: getResponseTransformer(logger, dateReviverOptions)
            });
            break;
        }
        case "GET": {
            result = await Axios.get<Out>(request.url, {
                ...config,
                data: request.body,
                transformResponse: getResponseTransformer(logger, dateReviverOptions)
            });
            break;
        }
        case "PATCH": {
            result = await Axios.patch<Out>(request.url, request.body, {
                ...config,
                transformResponse: getResponseTransformer(logger, dateReviverOptions)
            });
            break;
        }
        case "PUT": {
            result = await Axios.put<Out>(request.url, request.body, {
                ...config,
                transformResponse: getResponseTransformer(logger, dateReviverOptions)
            });
            break;
        }
        case "DELETE": {
            result = await Axios.delete<Out>(request.url, { ...config, data: request.body });
            break;
        }
    }

    logger.debug("%s: Received response for %s request to %s with status %s", new Date().toISOString(), request.httpVerb, request.url, result.status);

    return result;
}
