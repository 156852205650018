import { Field, HitMassEditFields, HitStatus, QuickSearch, SearchVersion, User } from "aderant-conflicts-models";
import { ComboBoxItem } from "@aderant/aderant-react-components";
import { HitStatusComboBox } from "components/HitStatusComboBox/HitStatusComboBox";
import { Messages } from "components/Tile/Tiles.Messages";
import { splitToUserFriendlyString, titleCase } from "Functions/strings";
import _ from "lodash";
import { getAssignedToUser } from "pages/Shared/userUtils";
import React from "react";
import { useSelector } from "react-redux";
import { getAllUsers } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import { StyledFieldOverrides } from "../DynamicFields/DisplayFields/commonStyles";
import { Tile } from "./Tile";
import { HitResult } from "pages/ResultsPage/HitResult";
import { FeatureBySearchTypeHelper } from "pages/Shared/FeatureBySearchType";
import { UserChangePermissions } from "pages/ResultsPage/ResultsPage";
import { isUrlParamFeatureFlagOn } from "aderant-conflicts-common";
import { AssignedToUserPickList } from "components/AssignedToUserPickList/AssignedToUserPickList";

export type HitInformationTileProps = {
    user?: User;
    data?: HitResult;
    userChangePermissions: UserChangePermissions;
} & HitInformationTilePropsBySearchType;

type HitInformationTilePropsBySearchType =
    | {
          searchVersion?: QuickSearch;
          onHitChange?: never;
          searchReadOnly?: true;
      }
    | {
          searchVersion?: SearchVersion;
          onHitChange?: (status?: HitStatus, hitOwnerId?: string | null) => void;
          searchReadOnly?: boolean;
      };

export const HitInformationTile = (props: HitInformationTileProps): JSX.Element => {
    const { data, onHitChange, searchVersion, searchReadOnly, userChangePermissions } = props;
    const allUsers = useSelector(getAllUsers);


    const onHitChangeInFlyout = (hitChange: HitMassEditFields) => {
        onHitChange && onHitChange(hitChange.status, hitChange.hitOwnerId);
    };

    const handleStatusChange = (item: ComboBoxItem<HitStatus>) => {
        //Return if the change and the current hit are the same
        if (item.value === data?.hitStatus) {
            return;
        }
        onHitChangeInFlyout({ status: item.value });
    };

    const handleOwnerChange = (user: User | null | undefined) => {
        //Return if the change and the current hit are the same
        if ((user && data?.hitOwner && user.id === data.hitOwner.id) || (user === null && data?.hitOwner === null)) {
            return;
        }
        onHitChangeInFlyout({ hitOwnerId: user ? user.id : user });
    };

    //FEATURE-FLAG: hitOwner
    const isHitOwnerEnabled = isUrlParamFeatureFlagOn("hitOwner");

    const fields: (Field & StyledFieldOverrides)[] = [];

    const picklistStyle = { marginLeft: "-10px", marginRight: "50px", marginBottom: "-10px" };

    if (FeatureBySearchTypeHelper.showHitStatus(searchVersion)) {
        const requestStatusComboBox = (): JSX.Element => {
            return data?.hitStatus ? (
                <HitStatusComboBox
                    disabled={searchReadOnly || !userChangePermissions.hitStatus}
                    hitStatus={data.hitStatus}
                    searchVersion={searchVersion}
                    onItemSelected={handleStatusChange}

                />
            ) : (
                <></>
            );
        };
        fields.push({ fieldLabel: Messages.HIT_STATUS_LABEL.getMessage(), displayFormat: "Custom", value: requestStatusComboBox() });
    }
    if (FeatureBySearchTypeHelper.showHitOwner(searchVersion)) {
        if (!isHitOwnerEnabled) {
            fields.push({
                fieldLabel: Messages.ASSIGNED_TO_LABEL.getMessage(),
                displayFormat: "User",
                value: { userId: 1, userName: `${getAssignedToUser(allUsers, searchVersion?.assignedToUserId)}` }
            });
        } else {
            const hitOwnerDropdown = (): JSX.Element => {
                return (
                    <AssignedToUserPickList
                        unassignedWhenEmpty={false}
                        label={""}
                        style={picklistStyle}
                        key="flyout-hit-owner-picklist"
                        id={"flyout-hit-owner-picklist"}
                        disabled={searchReadOnly || !userChangePermissions.hitOwner}
                        onUserChanged={(user: User | null | undefined) => {
                            handleOwnerChange(user);
                        }}
                        selectedUserId={data?.hitOwner === null ? null : data?.hitOwner?.id}
                    />
                );
            };
            fields.push({
                fieldLabel: Messages.HIT_OWNER_LABEL.getMessage(),
                displayFormat: "Custom",
                value: hitOwnerDropdown()
            });
        }
    }

    _(data?.hit.hitLocations)
        .groupBy((hitLocation) => hitLocation.location)
        .forEach((groupedLocations, location) => {
            if (fields.length) {
                fields.push({
                    fieldLabel: "",
                    displayFormat: "Custom",
                    value: <hr style={{ borderWidth: 0, borderStyle: "solid", borderColor: conflictsPalette.divider, borderBottomWidth: "thin", margin: "0 0 7px 0" }} />,
                    containerStyle: { marginBottom: "-6.5" }
                });
            }
            fields.push(
                {
                    fieldLabel: Messages.HIT_LOCATION_LABEL.getMessage(),
                    displayFormat: "String",
                    value: `${titleCase(splitToUserFriendlyString(location)) ?? "-"}`,
                    ...(fields.length && { containerStyle: { marginTop: "0" } })
                },
                { fieldLabel: Messages.HIT_TERM_LABEL.getMessage(), displayFormat: "String", value: groupedLocations.map((l) => l.term).join("; ") },
                {
                    fieldLabel: Messages.HIT_TEXT_LABEL.getMessage(),
                    displayFormat: "Highlight",
                    value: groupedLocations.flatMap((l) => l.highlights)
                }
            );
        });

    return <Tile title={Messages.HIT_INFORMATION_TILE_TITLE.getMessage()} fields={fields} maxHeightCollapsed={300} />;
};
