import {
    Awaited,
    BasicCurrentUserContext,
    ConnectionContext,
    DiagnosticInformation,
    Entity,
    EntityDocument,
    EntityIdentifier,
    Forbidden,
    ForeignEntityIdentifier,
    Lookup,
    LookupType,
    NotFound,
    SearchErrors
} from "aderant-conflicts-models";
import { MultiResponse, OkResponse, OkResponseWithHeaders } from "../../..";
import { AzureFunctionDefinition, AzureKeyAuthFunctionDefinition } from "../../AzureFunctionDefinition";
import { getFunctionAppUrl } from "../../Config/EnvironmentService";
import { TokenAuthContext, UserlessKeyAuthFunctionAppContext } from "../../ConflictsContext";

export const Surface = {
    getEntityDetails: new AzureFunctionDefinition<
        EntityIdentifier,
        Entity,
        OkResponseWithHeaders<Entity>,
        Forbidden | SearchErrors.InvalidId | NotFound | SearchErrors.InvalidSearchType | SearchErrors.InvalidStatus
    >({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND", "INVALID_SEARCH_TYPE", "INVALID_STATUS"],
        getUrlEnd: (input) => `./api/Entities/${input.entityType}/${input.id}`
    }),
    getLookup: new AzureFunctionDefinition<{ lookupType: LookupType }, Lookup[], OkResponse<Lookup[]>, NotFound>({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND"],
        getUrlEnd: (input) => `./api/Lookups/${input.lookupType}`
    }),
    getHealth: new AzureKeyAuthFunctionDefinition<undefined, DiagnosticInformation, OkResponse<DiagnosticInformation>, never>({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: () => `./api/Health`
    }),
    keyAuthFunctions: {
        getEntityDocuments: new AzureKeyAuthFunctionDefinition<(EntityIdentifier | ForeignEntityIdentifier)[], GetEntityDocumentsResults, GetEntityDocumentsResponse, never>({
            httpVerb: "GET",
            expectedErrors: [],
            getUrlEnd: () => `./api/KeyAuth/EntityDocuments/`
        })
    }
};
export type GetEntityDocumentsResponse = MultiResponse.ResponseWithHeaders<EntityDocument, 200, Forbidden | NotFound>;
export type GetEntityDocumentsResults = MultiResponse.Item<EntityDocument, 200, Forbidden | NotFound>[];

export type EntityStoreProxy = ReturnType<typeof getEntityStoreProxy>;
//justification: this return type is necessarily defined by getProxy - to explicitly define it in the function signature
//would be unwieldy
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getEntityStoreProxy(context: TokenAuthContext) {
    const baseURL: URL = getFunctionAppUrl("EntityStoreApi");

    return {
        getEntityDetails: Surface.getEntityDetails.getProxy(baseURL, context),
        getLookup: Surface.getLookup.getProxy(baseURL, context)
    };
}

export type EntityStoreKeyAuthProxy = Awaited<ReturnType<typeof getEntityStoreKeyAuthProxy>>;

export async function getEntityStoreKeyAuthProxy(context: ConnectionContext & BasicCurrentUserContext) {
    const baseURL: URL = getFunctionAppUrl("EntityStoreApi");
    const hostKey = await context.getSharedFunctionHostKey("EntityStoreApi");

    return {
        getEntityDocuments: Surface.keyAuthFunctions.getEntityDocuments.getProxy(baseURL, context, hostKey)
    };
}

export type EntityStoreUserlessKeyAuthProxy = Awaited<ReturnType<typeof getEntityStoreUserlessKeyAuthProxy>>;

export async function getEntityStoreUserlessKeyAuthProxy(context: UserlessKeyAuthFunctionAppContext) {
    const baseURL: URL = getFunctionAppUrl("EntityStoreApi");
    const hostKey = await context.getSharedFunctionHostKey("EntityStoreApi");

    return {
        getHealth: Surface.getHealth.getKeyAuthProxyForMonitoring(baseURL, context.logger, hostKey)
    };
}
