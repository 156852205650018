import { ConflictsError, isConflictsError, isUnexpectedError, StatusCodes, UnexpectedError } from "aderant-conflicts-models";
import Axios, { AxiosResponse } from "axios";
import { EnvironmentService } from "../../../..";
import { narrowError } from "../errorHandling";

export function processOrThrowErrorResponse<Err extends ConflictsError>(err: unknown, expectedErrorTypes: Set<Err["_conflictserrortype"]>, errorContext: string): Err {
    let result: unknown;
    let response: AxiosResponse | undefined;
    if (Axios.isAxiosError(err)) {
        if (err.response) {
            response = err.response;
            if (err.response.data !== null && err.response.data !== undefined && err.response.data !== "") {
                if (isConflictsError(err.response.data)) {
                    // Justification: the status code was stripped off the body on the sending end
                    // so that there was only one source of truth for the receiver (the actual response status code).
                    // it's safe to assume that the actual response status is of the same allowed subset that the original ConflictsError had on it.
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const rehydrated: ConflictsError = { ...err.response.data, httpStatusCode: err.response.status as StatusCodes.Failure };
                    result = rehydrated;
                } else {
                    result = err.response.data; //just include body to avoid adding potentially sensitive information from URL/Headers.
                }
            } else {
                if (err.response.status === 500 && !EnvironmentService.isDevelopmentEnvironment()) {
                    //Azure returns empty text body with 500 errors regardless of what was in the thrown error server side for security reasons.
                    //localhost environments don't leave this out and provide full context so we don't need to include the context message.
                    //(If a localhost hosted API is returning empty 500s that is likely a bug, and we shouldn't hide that)
                    result = "500 status code with no body was received, context information was left out for security reasons. Check server logs for more information.";
                } else {
                    result = "Empty Body";
                }
            }
        } else {
            //we have some Axios error without a response - this usually means a timeout, or some issue with sending the request on the sending device.
            result = err.message;
        }
    } else {
        result = err;
    }

    //narrow here - if the error isn't one of our expected types do the UnexpectedError wrapping thing so nested API calls bubble unexpected errors
    //with context info/root cause automatically
    const error: Err | UnexpectedError = narrowError(result, response?.status, expectedErrorTypes, errorContext);
    if (isUnexpectedError(error)) {
        throw error;
    }
    return error;
}
