import { Message } from "./Validation/Message";

export const SearchMessages = {
    HIT_TYPE: new Message("HIT_TYPE", "Hit Type"),
    SEARCH_STATUS_DRAFT: new Message("SEARCH_STATUS_DRAFT", "Draft"),
    SEARCH_STATUS_APPROVED: new Message("SEARCH_STATUS_APPROVED", "Approved"),
    SEARCH_STATUS_INREVIEW: new Message("SEARCH_STATUS_INREVIEW", "In Review"),
    SEARCH_STATUS_CONDITIONALAPPROVAL: new Message("SEARCH_STATUS_CONDITIONALAPPROVAL", "Conditional Approval"),
    SEARCH_STATUS_SUBMITTED: new Message("SEARCH_STATUS_SUBMITTED", "Submitted"),
    SEARCH_STATUS_SEARCHING: new Message("SEARCH_STATUS_SEARCHING", "Searching"),
    SEARCH_STATUS_ERROR: new Message("SEARCH_STATUS_ERROR", "Error"),
    SEARCH_STATUS_SEARCHED: new Message("SEARCH_STATUS_SEARCHED", "Searched"),
    SEARCH_STATUS_REJECTED: new Message("SEARCH_STATUS_REJECTED", "Rejected"),
    SEARCH_REQUEST_ID: new Message("SEARCH_REQUEST_ID", "Search Request ID"),
    SEARCH_REQUEST_NUMBER: new Message("SEARCH_REQUEST_NUMBER", "Search Request #"),
    SEARCH_ID: new Message("SEARCH_ID", "Search Id"),
    VERSION_ID: new Message("VERSION_ID", "Version Id"),
    USER_ID: new Message("USER_ID", "User Id"),
    VERSION_NUMBER: new Message("VERSION_NUMBER", "Version #"),
    CREATED_ON: new Message("CREATED_ON", "Created On"),
    REQUESTED_BY: new Message("REQUESTED_BY", "Requested By"),
    CREATED_BY: new Message("CREATED_BY", "Created By"),
    APPROVER: new Message("APPROVER", "Approver"),
    DESCRIPTION: new Message("DESCRIPTION", "Description"),
    REQUEST_STATUS: new Message("REQUEST_STATUS", "Request Status"),
    ASSIGNED_TO: new Message("ASSIGNED_TO", "Assigned To"),
    SEARCH_REQUEST: new Message("SEARCH_REQUEST", "Search Request"),
    SEARCH_MODIFIERS: new Message("SEARCH_MODIFIERS", "Search Modifiers"),
    NEW_SEARCH_VERSION: new Message("NEW_SEARCH_VERSION", "New Version"),
    REPORT: new Message("REPORT", "Report"),
    RESOLVED_PROGRESS_INDICATOR: new Message("RESOLVED_PROGRESS_INDICATOR", "Resolved"),

    // search result fields for columns
    REQUEST_TERM: new Message("REQUEST_TERM", "Request Term"),
    SEARCH_TERMS: new Message("SEARCH_TERMS", "Search Terms"),
    TERM: new Message("TERM", "Term"),
    NAME: new Message("NAME", "Name"),
    HIT_ID: new Message("HIT_ID", "Hit ID"),
    HIT_STATUS: new Message("HIT_STATUS", "Hit Status"),
    PREVIOUS_HIT_STATUS: new Message("PREVIOUS_HIT_STATUS", "Previous Hit Status"),
    HIT_LOCATION: new Message("HIT LOCATION", "Hit Location"),
    HIT_TERM: new Message("HIT_TERM", "Hit Term"),
    HIT_OWNER: new Message("HIT_OWNER", "Hit Owner"),
    COMMENT: new Message("COMMENT", "Comment"),
    CLIENT: new Message("CLIENT", "Client"),
    MATTER: new Message("MATTER", "Matter"),
    AFFILIATION: new Message("AFFILIATION", "Affiliation"),
    PARTY_STATUS: new Message("PARTY_STATUS", "Party Status"),
    STATUS: new Message("STATUS", "Status"),
    EMPLOYEE: new Message("EMPLOYEE", "Employee"),
    START_DATE: new Message("START_DATE", "Start Date"),
    END_DATE: new Message("END_DATE", "End Date"),
    DEPARTMENT: new Message("DEPARTMENT", "Department"),
    OFFICE: new Message("OFFICE", "Office"),
    PROFIT_CENTER: new Message("PROFIT_CENTER", "Profit Center"),
    TYPE: new Message("TYPE", "Type"),
    CATEGORY: new Message("CATEGORY", "Category"),
    CONFLICT_CLIENT_PARTY: new Message("CONFLICT_CLIENT_PARTY", "Conflict - Client Party"),
    PARENT_MATTER_MATTER: new Message("PARENT_MATTER_MATTER", "Parent Matter - Matter"),
    LEAD_MATTER_MATTER: new Message("LEAD_MATTER_MATTER", "Lead Matter - Matter"),
    CONFLICT_MATTER_PARTY: new Message("CONFLICT_MATTER_PARTY", "Conflict - Matter Party"),
    MATTER_CLASS: new Message("MATTER_CLASS", "Matter Class"),
    HIT_TEXT: new Message("HIT_TEXT", "Hit Text")
};
