import { SearchMessages } from "../Search.messages";
import { GridColumnConfiguration } from "./GridConfiguration";

export const DefaultHitResultGridColumnConfigurations: GridColumnConfiguration[] = [
    {
        columnName: SearchMessages.REQUEST_TERM.getMessage(),
        propertyName: "requestTermId",
        isVisible: false,
        isVisibleOnRedactedRows: true
    },
    {
        columnName: SearchMessages.NAME.getMessage(),
        propertyName: "name",
        isVisibleOnRedactedRows: true
    },
    {
        columnName: SearchMessages.HIT_ID.getMessage(),
        propertyName: "id",
        isVisibleOnRedactedRows: true
    },
    {
        columnName: SearchMessages.HIT_STATUS.getMessage(),
        propertyName: "hitStatus"
    },
    {
        columnName: SearchMessages.HIT_OWNER.getMessage(),
        propertyName: "hitOwner",
        isVisible: false
    },
    {
        columnName: SearchMessages.HIT_TYPE.getMessage(),
        propertyName: "sourceTypeDisplayValue"
    },
    {
        columnName: SearchMessages.HIT_LOCATION.getMessage(),
        propertyName: "hitLocationsCommaSeparated"
    },
    {
        columnName: SearchMessages.HIT_TERM.getMessage(),
        propertyName: "searchTermsCommaSeparated"
    },
    {
        columnName: SearchMessages.CLIENT.getMessage(),
        propertyName: "client"
    },
    {
        columnName: SearchMessages.MATTER.getMessage(),
        propertyName: "matter"
    },
    {
        columnName: SearchMessages.AFFILIATION.getMessage(),
        propertyName: "sourceAffiliation"
    },
    {
        columnName: SearchMessages.PARTY_STATUS.getMessage(),
        propertyName: "partyStatus"
    },
    {
        columnName: SearchMessages.STATUS.getMessage(),
        propertyName: "status"
    },
    {
        columnName: SearchMessages.EMPLOYEE.getMessage(),
        propertyName: "responsibleEmployee"
    },
    {
        columnName: SearchMessages.START_DATE.getMessage(),
        propertyName: "startDate",
        align: "right"
    },
    {
        columnName: SearchMessages.END_DATE.getMessage(),
        propertyName: "endDate",
        align: "right"
    },
    {
        columnName: SearchMessages.DESCRIPTION.getMessage(),
        propertyName: "description",
        displayFormat: "Highlight"
    },
    {
        columnName: SearchMessages.DEPARTMENT.getMessage(),
        propertyName: "department"
    },
    {
        columnName: SearchMessages.OFFICE.getMessage(),
        propertyName: "office"
    },
    {
        columnName: SearchMessages.PROFIT_CENTER.getMessage(),
        propertyName: "profitCenter"
    },
    {
        columnName: SearchMessages.TYPE.getMessage(),
        propertyName: "type"
    },
    {
        columnName: SearchMessages.CATEGORY.getMessage(),
        propertyName: "category",
        isVisible: false
    },
    {
        columnName: SearchMessages.PARENT_MATTER_MATTER.getMessage(),
        propertyName: "parentMatter",
        isVisible: false
    },
    {
        columnName: SearchMessages.LEAD_MATTER_MATTER.getMessage(),
        propertyName: "leadMatter",
        isVisible: false
    },
    {
        columnName: SearchMessages.MATTER_CLASS.getMessage(),
        propertyName: "matterClass",
        isVisible: false
    },
    {
        columnName: SearchMessages.HIT_TEXT.getMessage(),
        propertyName: "hitText",
        displayFormat: "Highlight",
        isVisible: false
    },
    // #region These columns are included in the list of columns (although not visible) because this information is needed when actioning rows
    {
        columnName: "HitId",
        propertyName: "hitId",
        isVisible: false
    }
    //#endregion
];
