import { RequestTerm, isTermSingleAsterisk } from "aderant-conflicts-models";
import { messages } from "./SearchUtils";

/**
 * Determines if a search term is valid, i.e. unique within the request term.
 */
export const isSearchTermDuplicate = (searchTerm: string, searchRequest: RequestTerm): boolean => {
    return searchRequest.searchTerms.filter((s) => s.toLowerCase().trim() == searchTerm?.toLowerCase().trim())?.length > 0;
};

export enum FieldValidity {
    Valid,
    Missing, //The field is marked as required but there is no value
    Error //There is a problem with the value entered
}

export type ValidationResult = {
    status: FieldValidity;
    message?: string;
};

export const validateRequestTerm = (requestTermToValidate: RequestTerm, isQuickSearch?: boolean): ValidationResult => {
    if (!requestTermToValidate || !requestTermToValidate.term) {
        return { status: FieldValidity.Missing, message: messages.addARequestTerm };
    } else if (!requestTermToValidate.term || !requestTermToValidate.term.trim()) {
        return { status: FieldValidity.Missing, message: messages.addARequestTerm };
    } else if (requestTermToValidate.term && isTermSingleAsterisk(requestTermToValidate.term)) {
        return { status: FieldValidity.Error, message: isQuickSearch ? messages.termNotAllowed : messages.requestTermNotAllowed };
    }
    return { status: FieldValidity.Valid };
};
