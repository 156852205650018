import { DisplayFormatTypes, GridColumnConfiguration, Result, ValidationErrors, ValidationMessage, wrapValidationErrors } from "aderant-conflicts-models";
import { TypeValidationMessages as messages } from "../../APIs/Messages";

const isReservedWord = (propertyName: string, reservedWords?: string[]) => {
    if (propertyName && reservedWords && reservedWords.includes(propertyName)) {
        return true;
    }
    return false;
};

const isValidIsVisibleValue = (isVisible: boolean | undefined): isVisible is GridColumnConfiguration["isVisible"] => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    switch (isVisible as GridColumnConfiguration["isVisible"]) {
        case true:
        case false:
        case undefined:
            return true;
        default:
            return false;
    }
};

const isValidAlignValue = (align: string | undefined): align is GridColumnConfiguration["align"] => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    switch (align as GridColumnConfiguration["align"]) {
        case "left":
        case "right":
        case "center":
        case undefined:
            return true;
        default:
            return false;
    }
};

const isValidDisplayFormat = (displayFormat: string | undefined): displayFormat is GridColumnConfiguration["displayFormat"] => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    switch (displayFormat as GridColumnConfiguration["displayFormat"]) {
        case "Boolean":
        case "Currency":
        case "Date":
        case "DateTime":
        case "Number":
        case "String":
        case "User":
        case "UserWithAvatar":
        case "Highlight":
        case undefined:
            return true;
        default:
            return false;
    }
};

const validateGridColumnConfiguration = (columnConfiguration: GridColumnConfiguration, gridName: string, reservedWords?: string[]): ValidationMessage[] => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!columnConfiguration.propertyName) {
        reasonsIsInvalid.push(
            messages.VLD_NULLUNDEFEMPTY_SOURCE_FIELD.asValidationMessage(`${gridName}:`, `propertyName from the following column configuration ${JSON.stringify(columnConfiguration)}`)
        );
        return reasonsIsInvalid;
    } else if (typeof columnConfiguration.propertyName !== "string") {
        reasonsIsInvalid.push(messages.VLD_WRONGTYPE_SOURCE_FIELD.asValidationMessage(`${gridName}:`, `${columnConfiguration.propertyName}.propertyName`, "string"));
    } else if (isReservedWord(columnConfiguration.propertyName, reservedWords)) {
        reasonsIsInvalid.push(messages.VLD_RES_WORD_FIELD.asValidationMessage(`${gridName}: ${columnConfiguration.propertyName}.propertyName`, columnConfiguration.propertyName));
    }
    if (columnConfiguration.columnName === null || columnConfiguration.columnName === undefined) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEF_SOURCE_FIELD.asValidationMessage(`${gridName}:`, `${columnConfiguration.propertyName}.columnName`));
    } else if (typeof columnConfiguration.columnName !== "string") {
        reasonsIsInvalid.push(messages.VLD_WRONGTYPE_SOURCE_FIELD.asValidationMessage(`${gridName}:`, `${columnConfiguration.propertyName}.columnName`, "string"));
    }
    if (!isValidIsVisibleValue(columnConfiguration.isVisible)) {
        reasonsIsInvalid.push(messages.VLD_ENUM_SOURCE_FIELD.asValidationMessage(`${gridName}:`, `${columnConfiguration.propertyName}.isVisible`, "true, false"));
    }
    if (!isValidAlignValue(columnConfiguration.align)) {
        reasonsIsInvalid.push(messages.VLD_ENUM_SOURCE_FIELD.asValidationMessage(`${gridName}:`, `${columnConfiguration.propertyName}.align`, "left, center, right"));
    }
    if (!isValidDisplayFormat(columnConfiguration.displayFormat)) {
        reasonsIsInvalid.push(messages.VLD_ENUM_SOURCE_FIELD.asValidationMessage(`${gridName}:`, `${columnConfiguration.propertyName}.displayFormat`, DisplayFormatTypes.join(", ")));
    }

    return reasonsIsInvalid;
};

const validateDuplicates = (configurations: GridColumnConfiguration[], gridName: string) => {
    const propertyNames = configurations.map((configuration) => configuration.propertyName).filter(Boolean);
    const duplicateValues: string[] = [];
    for (const name of propertyNames) {
        if (propertyNames.indexOf(name) !== propertyNames.lastIndexOf(name)) {
            duplicateValues.push(name);
        }
    }

    if (duplicateValues.length > 0) {
        return messages.VLD_DUP_PROPERTY.asValidationMessage([...new Set(duplicateValues)].join(", "), `${gridName}: propertyName`);
    }

    return undefined;
};

/**
 * Validates grid column configurations
 * @param configurations configurations to validate @type GridColumnConfiguration[]
 * @param gridName Name of grid with configurations to validate. Only used for log and validation messages: ie. Hit Result Grid @type string
 */
export const validateGridDefinitions = (configurations: GridColumnConfiguration[], gridName: string, reservedWords?: string[]): Result<GridColumnConfiguration[], ValidationErrors> => {
    const reasonsIsInvalid: ValidationMessage[] = [];
    if (!configurations) {
        reasonsIsInvalid.push(messages.VLD_NULLUNDEF_SOURCE_FIELD.asValidationMessage(`${gridName}:`, "configurations"));
    } else if (configurations.length === 0) {
        reasonsIsInvalid.push(messages.VLD_EMPTY_ARRAY_SOURCE.asValidationMessage(`${gridName}:`, "configurations"));
    } else {
        configurations.forEach((column: GridColumnConfiguration) => {
            reasonsIsInvalid.push(...validateGridColumnConfiguration(column, gridName, reservedWords));
        });

        const duplicateValidation = validateDuplicates(configurations, gridName);
        if (duplicateValidation) {
            reasonsIsInvalid.push(duplicateValidation);
        }
    }
    if (reasonsIsInvalid.length == 0) {
        return configurations;
    }
    return wrapValidationErrors(reasonsIsInvalid);
};
