import React from "react";
import { FirmSettingsSection } from "./FirmSettingsSections/FirmSettingsSection";
import { conflictsPalette } from "styles/conflictsPalette";
import { FirmSettingsPageFooter } from "./FirmSettingsPageFooter";
import PageContent from "components/PageContainer/PageContent";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, appActions } from "state/actions";
import { getIsFirmSettingsPagePersisting, getIsFirmSettingsPageEdited, getIsFirmSettingsPageLoaded } from "state/selectors/adminSelectors";
import { RootState } from "MyTypes";
import { FirmSettings } from "aderant-conflicts-models";
import { Backdrop, CircularProgress } from "@mui/material";
import { DataLoadedState, isAllPageDataLoadedOrNotErrored } from "state/reducers/adminReducers";

type FirmSettingsPageComponentProps = {
    pageName: FirmSettings.PageDefinitionName;
    isPageBasic: boolean;
    sections: FirmSettingsSection[];
    data: Record<string, Record<string, any>>;
    getData: () => void;
    role?: string;
    "aria-labelledby"?: string;
    id?: string;
};

export const FirmSettingsPageComponent = (props: FirmSettingsPageComponentProps): JSX.Element => {
    //These are only really consumed for basic pages since handwritten sections currently grab these values from selectors directly
    const isBasicPageEdited = useSelector((rootState: RootState) => getIsFirmSettingsPageEdited(rootState, props.pageName));
    const isBasicPagePersisting: boolean = useSelector((rootState: RootState) => getIsFirmSettingsPagePersisting(rootState, props.pageName));
    const isPageLoaded: DataLoadedState[] = useSelector((rootState: RootState) => getIsFirmSettingsPageLoaded(rootState, props.pageName));
    const isAllPageDataNotLoaded = !isPageLoaded.every((d) => d.isLoaded === true);
    const dispatch = useDispatch();
    const onSave = () => {
        dispatch(adminActions.saveFirmSettingsPageData({ pageName: props.pageName, pageData: props.data }));
    };

    const onCancel = () => {
        props.getData();
    };
    if (!props.isPageBasic || (props.isPageBasic && isAllPageDataLoadedOrNotErrored(isPageLoaded))) {
        return (
            <PageContent style={{ display: "flex", flexDirection: "column", overflow: "auto", position: "relative" }} role={props.role} id={props.id} aria-labelledby={props["aria-labelledby"]}>
                <Backdrop
                    style={{ backgroundColor: conflictsPalette.background.white, color: conflictsPalette.primary.R2D2, height: "100%", width: "100%", position: "absolute", zIndex: 101 }}
                    open={isAllPageDataNotLoaded}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {props.sections.map((section) => {
                    //Despite how strange this looks, it works fine.  Make sure that the order of components stays the same, or you will lose state
                    return (
                        <React.Fragment key={section.id.sectionName}>
                            <section.component
                                key={`${section.id.sectionName}-component`}
                                data={props.data ? (props.data[section.id.sectionName] ? props.data[section.id.sectionName] : {}) : {}}
                                onSectionChange={section.onSectionChange}
                                isPagePersisting={isBasicPagePersisting}
                            />
                            {/*Only render divider after a basic section */}
                            {section.sectionType === "basic" && <div style={{ borderTop: `1px solid ${conflictsPalette.border}` }}></div>}
                        </React.Fragment>
                    );
                })}
                {props.sections.every((section) => section.sectionType === "basic") && (
                    <FirmSettingsPageFooter onCancel={onCancel} onSave={onSave} disabled={!isBasicPageEdited || isBasicPagePersisting} />
                )}
            </PageContent>
        );
    } else {
        const displayPageName = FirmSettings.getDisplayNameFromPageName(props.pageName, FirmSettings.sortedPageDefinitions);
        dispatch(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(displayPageName)));
        return <></>;
    }
};
