import { SearchField, SearchIndex } from "@azure/search-documents";
import { ValidationMessage } from "aderant-conflicts-models";
import { Schema, Validator } from "jsonschema";
import * as SearchIndexSchema from "../Schemas/SearchIndex.json";

export function validateAgainstSchema(objectToValidate: any, schema: Schema): ValidationMessage[] {
    const validator = new Validator();
    const result = validator.validate(objectToValidate, schema);
    if (result.errors?.length > 0) {
        const errors: ValidationMessage[] = [];
        result.errors.forEach((e) =>
            errors.push({
                message: e.stack,
                messageCode: "400"
            })
        );

        return errors;
    }
    return [];
}

/**
 * Checks specific things not covered by the JSON schema validation, such as if the name template has been substituted
 * @param indexDefinition
 */
function validateIndexDefinition(indexDefinition: SearchIndex, tenancyName: string): ValidationMessage[] {
    const errors = [];
    if (indexDefinition.name.search("tenancyName") !== -1) {
        errors.push("Index definition contains un-substituted [tenancyName]", "validateIndexDefinition");
    } else if (!indexDefinition.name.startsWith(tenancyName)) {
        errors.push(`Index definition name is incorrect.  Name '${indexDefinition.name}'`, "validateIndexDefinition");
    }
    if (indexDefinition.fields.length === 0) {
        errors.push("No fields on index definition", "validateIndexDefinition");
    }
    if (
        !indexDefinition.fields.some((f: SearchField) => {
            return f.name === "id" && f.type === "Edm.String";
        })
    ) {
        errors.push("Index definition does not contain a valid 'id' field.  Check that it is present and that the type is 'Edm.String'", "validateIndexDefinition");
    }
    if (errors.length > 0) {
        const validationErrors: ValidationMessage[] = [];
        errors.forEach((e: string) =>
            validationErrors.push({
                message: e,
                messageCode: "400"
            })
        );
        return validationErrors;
    }
    return [];
}

/**
 * Validates an index definition, checking compliance with the schema as well as other properties
 * @param indexDefinition
 * @param tenancyName
 * @returns
 */
export function validateIndexDefinitionAgainstSchema(indexDefinition: any, tenancyName: string): ValidationMessage[] {
    try {
        const errors = validateAgainstSchema(indexDefinition, SearchIndexSchema);
        if (errors.length > 0) {
            //Don't bother doing the rest of the validation if the object doesn't even match the schema
            return errors;
        }
        return validateIndexDefinition(indexDefinition, tenancyName);
    } catch (e: any) {
        return [
            {
                message: `Exception encountered validating index definition schema.  Exception message: ${e?.message || e?.errorMessage || JSON.stringify(e)}`,
                messageCode: "400"
            }
        ];
    }
}
