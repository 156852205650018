import { BasicPageDefinition, BasicSectionDefinition, PageDefinition, SectionDefinition, sortedPageDefinitions } from ".";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./PageDefinitions/InternalTypes/FirmSettingsPageDefinition";
import { NotFound, Result, notFound, notFoundWithMessage, ok } from "../Validation/Errors";
import { EditableFieldDefinition } from "../Fields/EditableFields/FieldDefinitions";

export function getDefinitionDisplayName(definition: Pick<FirmSettingsPageDefinition, "displayName"> | Pick<FirmSettingsSectionDefinition, "displayName">): string {
    if (typeof definition.displayName === "string") {
        return definition.displayName;
    } else {
        return definition.displayName.getMessage();
    }
}

export function getDisplayNameFromPageName(pageName: string, definitions: FirmSettingsPageDefinition[]): string {
    const definition = definitions.find((x) => x.name === pageName);
    if (definition) {
        return getDefinitionDisplayName(definition);
    }
    return pageName;
}

export function getSectionDefinition(pageDefinition: PageDefinition, sectionName: string): Result<SectionDefinition, NotFound> {
    const sections = pageDefinition.sections.map((section) => {
        return { name: section.name, sectionDefinition: section };
    });
    const currentSectionDefinition = sections?.find((section) => section.name === sectionName);
    if (currentSectionDefinition === undefined) {
        return notFoundWithMessage(`No section is defined at path '${pageDefinition.name}/${sectionName}'`);
    }

    return currentSectionDefinition?.sectionDefinition;
}

function getField(sectionDefinition: BasicSectionDefinition, fieldName: string, pageName: string): Result<EditableFieldDefinition, NotFound> {
    const fields = sectionDefinition.fields.map((field) => {
        return { name: field.name, field: field };
    });
    const field = fields?.find((field) => field.name === fieldName);
    if (field === undefined) {
        return notFoundWithMessage(`No field is defined at path '${pageName}/${sectionDefinition.name}/${fieldName}'`);
    }
    return field.field;
}
export function getBasicPageDefaultSettings(definition: BasicPageDefinition) {
    const defaultSettings: any = {};
    definition.sections.map((section) => {
        section.fields.map((field) => {
            const path = `${definition.name}/${section.name}/${field.name}`;
            defaultSettings[path] = field.defaultValue;
        });
    });
    return defaultSettings;
}

export function getBasicPagePaths(definition: BasicPageDefinition) {
    const paths: string[] = [];
    definition.sections.map((section) => {
        section.fields.map((field) => {
            const path = `${definition.name}/${section.name}/${field.name}`;
            paths.push(path);
        });
    });
    return paths;
}

export function validateDefinitionExists(pageName: string, sectionName?: string, fieldName?: string): Result<PageDefinition, NotFound> {
    const currentPageDefinition = sortedPageDefinitions.find((page) => page.name === pageName);
    if (!currentPageDefinition) {
        return notFound();
    }

    if (sectionName) {
        // validate section exists
        const currentSectionDefinition = getSectionDefinition(currentPageDefinition, sectionName);
        if (!ok(currentSectionDefinition)) {
            return currentSectionDefinition;
        }
        if (fieldName) {
            // validate field exists
            if (currentSectionDefinition.type === "basic") {
                const currentField = getField(currentSectionDefinition, fieldName, pageName);
                if (!ok(currentField)) {
                    return currentField;
                }
            }
        }
    }
    return currentPageDefinition;
}
