import { UserResponse } from "../APIs/UserManagement/Surface";
import { AzureFunctionProxy } from "../AzureFunctionDefinition/shared/proxy";

export type TenancyUsers = {
    id: string;
    users: UserResponse[];
};

export function getUserManagementMock(tenancyUsers: TenancyUsers[]) {
    const userManagement: {
        tenancyUsers: TenancyUsers[];
        getUsers: AzureFunctionProxy<
            {
                tenancyId: string;
            },
            UserResponse[],
            never
        >;
    } = {
        tenancyUsers: tenancyUsers,
        getUsers: async (param: { tenancyId: string }) => {
            const tenancy = tenancyUsers.filter((tenancy) => {
                return tenancy.id == param.tenancyId;
            });
            if (tenancy && tenancy.length > 0) {
                return Promise.resolve(tenancy[0].users);
            }
            return Promise.resolve([]);
        }
    };
    return userManagement;
}
