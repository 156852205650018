import { combineReducers } from "redux";
import { adminReducer } from "./adminReducers";
import { appReducer } from "./appReducers";
import { checkActionId, reducerWithCheck } from "./checkReducer";
import { refinerReducer } from "./refinerReducers";
import { searchReducer } from "./searchReducers";
import { userAlertReducer } from "./userAlertReducers";

const rootReducer = combineReducers({
    search: reducerWithCheck(checkActionId, searchReducer),
    refiners: refinerReducer,
    app: appReducer,
    userAlert: userAlertReducer,
    admin: adminReducer
});

export default rootReducer;
