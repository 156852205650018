import { Lookup } from "aderant-conflicts-models";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { messages, ExcelUtils, sampleInstructions, getExcelValidationFormat } from "./ExcelUtils";

export async function saveRequestTermsTemplate(affiliationList: Lookup[], partyStatusList: Lookup[]) {
    //create workbook with initial properties
    const workbook = new Excel.Workbook();
    workbook.creator = "Aderant";
    workbook.created = new Date();

    //create sheets
    const requestTermsSheet = workbook.addWorksheet(messages.requestTermsSheetName);
    const sampleSheet = workbook.addWorksheet(messages.sampleSheetName);
    const affiliationPartyStatusSheet = workbook.addWorksheet(messages.affiliationPartyStatusSheetName);

    //Freeze first row for both sheets
    requestTermsSheet.views = [{ state: "frozen", xSplit: 0, ySplit: 1 }];
    sampleSheet.views = [{ state: "frozen", xSplit: 0, ySplit: 1 }];

    //Add header row
    requestTermsSheet.columns = ExcelUtils.requestTermHeaders;
    sampleSheet.columns = ExcelUtils.requestTermHeaders;

    //Create rows
    const sampleSheetRows = [
        ["Alex Smith", "Opposing", "Plaintiff", "Alex Smith"],
        ["", "", "", "Alexander Smith"],
        ["Brittany Jones", "Client", "Defendant", "Brittany Jones"],
        ["", "", "", "Britney Jones"],
        ["", "", "", "Brit Jones"],
        [],
        [],
        ...sampleInstructions(messages.requestTermsSheetName),
        ["The order of the columns must match this sheet"]
    ];

    //Add rows
    sampleSheet.addRows(sampleSheetRows);

    //Make first rows bold and underline instructions title
    requestTermsSheet.getRow(1).font = { bold: true };
    sampleSheet.getRow(1).font = { bold: true };
    sampleSheet.getRow(9).font = { underline: true };

    //Populate the cells in column A with affiliations and B with party statuses
    //This is to avoid the 255 total character limit when using a string to create the validation.
    for (let i = 0; i < affiliationList.length; i++) {
        affiliationPartyStatusSheet.getCell(`A${i + 1}`).value = getExcelValidationFormat(affiliationList[i]);
    }
    for (let i = 0; i < partyStatusList.length; i++) {
        affiliationPartyStatusSheet.getCell(`B${i + 1}`).value = getExcelValidationFormat(partyStatusList[i]);
    }

    //Add dropdown list to affiliation and party status
    for (let i = 2; i < 10000; i++) {
        requestTermsSheet.getCell(`B${i}`).dataValidation = ExcelUtils.getAffiliationListDropdown(affiliationList.length);
        requestTermsSheet.getCell(`C${i}`).dataValidation = ExcelUtils.getPartyStatusListDropdown(partyStatusList.length);
        sampleSheet.getCell(`B${i}`).dataValidation = ExcelUtils.getAffiliationListDropdown(affiliationList.length);
        sampleSheet.getCell(`C${i}`).dataValidation = ExcelUtils.getPartyStatusListDropdown(partyStatusList.length);
    }

    //Hide the affiliations and party statuses sheet from the user
    affiliationPartyStatusSheet.state = "veryHidden";

    //Create excel sheet
    //Note: We are unable to save directly to a file in a web-app client so are saving to a buffer first.
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = ExcelUtils.fileType;
    const fileExtension = ExcelUtils.fileExtension;
    const fileName = messages.requestTermsFileName;

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, fileName + fileExtension);
}
