import React from "react";

export const QuickSearchesIcon = (): JSX.Element => {
    return (
        <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6206 17.0624L15.3868 16.1898L15.6021 15.8006C17.1513 16.5457 18.9607 16.7594 20.739 16.2829C24.7043 15.2203 27.0573 11.1449 25.9948 7.17955C24.9323 3.21423 20.8568 0.86125 16.8915 1.92376C12.9262 2.98626 10.5732 7.06174 11.6357 11.0271C12.1122 12.8054 13.2019 14.2655 14.6218 15.2346L14.3924 15.6157L13.5198 15.8495L9.48793 22.8491L11.5747 24.0539L15.6206 17.0624Z" fill="white"/>
            <path d="M1 4.24744H9.5" stroke="white" strokeWidth="1.6" strokeLinecap="round"/>
            <path d="M2.3999 7.24744H7.99991" stroke="white" strokeWidth="1.6" strokeLinecap="round"/>
            <path d="M3.80005 10.2474H8.00005" stroke="white" strokeWidth="1.6" strokeLinecap="round"/>
            <circle cx="19" cy="9" r="5" fill="#1F3C4F"/>
        </svg>

    );
};
