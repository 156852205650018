import { CircularProgress } from "@mui/material";
import { Forbidden, Message, UnexpectedError, ValidationErrors } from "aderant-conflicts-models";
import { Button, LoginPage as WCLoginPage, aderantColors } from "@aderant/aderant-react-components";
import { AderantConflictsIcon } from "components/Icons/AderantConflictsIcon";
import { ConflictsLogo } from "components/Logo/ConflictsLogo";
import React, { CSSProperties } from "react";
import { isUrlParamFeatureFlagOn } from "aderant-conflicts-common";

type ComponentProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSignIn: any;
    lastSignInError?: ValidationErrors | UnexpectedError | Forbidden;
    isAcquiringToken: boolean;
    isAppLoading: boolean;
    setErrorMessage: (message: string) => void;
    subscriptions: string[];
    onSelectSubscription: (subscription: string) => void;
    selectedSubscription: string | undefined;
    isLoggedIn: boolean;
    loginHint?: string;
};

const appDeveloper = "Aderant";
const appName = "Conflicts";

export const LoginMessages = {
    WELCOME_TO_CONFLICTS: new Message("WELCOME_TO_CONFLICTS", `Welcome to ${appDeveloper} ${appName}`),
    SIGN_IN: new Message("SIGN_IN", "Sign In"),
    UNEXPECTED_ERROR_USER_TEXT: new Message("UNEXPECTED_ERROR_USER_TEXT", "Error when signing in. Check the browser console for more information."),
    UNAUTHORISED_ERROR_USER_TEXT: new Message("UNAUTHORISED_ERROR_USER_TEXT", `You are not authorized to access ${appDeveloper} ${appName}. Please contact your system administrator.`),
    APP_DESCRIPTION: new Message(
        "APP_DESCRIPTION",
        `Welcome to ${appName}. ${appDeveloper}'s easy-to-use cloud native risk management solution providing comprehensive conflicts review and clearance capabilities.`
    )
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ErrorDisplay(props: any) {
    return (
        <div style={{ color: "red" }}>
            <i>{props.children}</i>
        </div>
    );
}

export default function LoginPage(props: ComponentProps): JSX.Element {
    const contentStyle: CSSProperties = {
        textAlign: "center",
        fontSize: "18px"
    };

    const isLoginHintFeatureEnabled = isUrlParamFeatureFlagOn("loginHint");

    const getErrorDisplay = (message: string) => {
        if (message === "") {
            return <div />;
        }
        return <ErrorDisplay>{message}</ErrorDisplay>;
    };

    const getErrorMessage = () => {
        if (!props.lastSignInError) {
            return "";
        }

        switch (props.lastSignInError?._conflictserrortype) {
            case "ACCESS_DENIED":
                return LoginMessages.UNAUTHORISED_ERROR_USER_TEXT.getMessage();
            default:
                return LoginMessages.UNEXPECTED_ERROR_USER_TEXT.getMessage();
        }
    };

    if (isLoginHintFeatureEnabled) {
        return (
            <>
                <WCLoginPage
                    appLogo={<ConflictsLogo color="newLoginLogoColor" />}
                    appName={appName}
                    appColor={aderantColors.R2D2}
                    appDescription={LoginMessages.APP_DESCRIPTION.getMessage()}
                    errorMessage={getErrorMessage()}
                    setErrorMessage={props.setErrorMessage}
                    isAcquiringToken={props.isAcquiringToken}
                    isAppLoading={props.isAppLoading}
                    isLoggedIn={props.isLoggedIn}
                    onSelectSubscription={props.onSelectSubscription}
                    onSignIn={props.onSignIn}
                    subscriptions={props.subscriptions}
                    selectedSubscription={props.selectedSubscription}
                    loginHint={props.loginHint}
                />
            </>
        );
    } else {
        return (
            <div className="page">
                <div className="page-header">
                    <AderantConflictsIcon style={{ width: "4em", padding: "0.5em" }} />
                </div>
                <div style={contentStyle}>
                    <ConflictsLogo color="currentLoginLogoColor" style={{ height: "94px", marginTop: "26px" }} />
                    <p>{LoginMessages.WELCOME_TO_CONFLICTS.getMessage()}</p>
                    <div style={{ margin: "14px auto", minWidth: "80px" }}>
                        {props.isAcquiringToken || props.isAppLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button size="medium" onClick={() => props.onSignIn()} text={LoginMessages.SIGN_IN.getMessage()} color="primary" />
                        )}
                    </div>
                    {getErrorDisplay(getErrorMessage())}
                </div>
            </div>
        );
    }
}
