import { Highlighter } from "components/Highlighter/Highlighter";
import { HighlightStringField } from "aderant-conflicts-models";
import React from "react";
import { styles } from "./commonStyles";
interface HighlightStringInformationProps {
    field: HighlightStringField;
}
export const HighlightStringInformation = (props: HighlightStringInformationProps): JSX.Element => {
    const {
        field: { fieldLabel, value: highlights }
    } = props;

    const renderHighlights = (): React.ReactNode[] | null => {
        if (!highlights || highlights.length === 0) {
            return null;
        }
        return highlights.map((highlight, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
                <Highlighter text={highlight} />
            </div>
        ));
    };

    return (
        <>
            <span style={styles.labelStyle}>{fieldLabel}:</span>
            <span data-testid={`${fieldLabel}`} style={styles.valueStyle}>
                {renderHighlights()}
            </span>
        </>
    );
};
