import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ConflictsAction } from "aderant-conflicts-models";
import { Button } from "@aderant/aderant-react-components";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { refinerActions } from "state/actions/RefinerActions";
import { VIEW_BY_FILTERS } from "state/reducers/ReducerStateTypes";
import { usePermissionsContext } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import ViewByCheckboxes from "./ViewByCheckboxes";
import ViewByPickLists from "./ViewByPickLists";

const ViewByRefinerContainer = ({ currentUserId, disabled }: { currentUserId: string; disabled: boolean }): JSX.Element | null => {
    const [isRefinerOpen, setIsRefinerOpen] = useState(true);
    const dispatch = useDispatch();
    const permissions = usePermissionsContext();

    const canViewOwnSearches = permissions.currentUserHasPermission(ConflictsAction.ViewOwnSearches);
    const canViewOthersSearches = permissions.currentUserHasPermission(ConflictsAction.ViewOthersSearches);

    useEffect(() => {
        VIEW_BY_FILTERS.forEach((obj) => dispatch(refinerActions.updateViewByRefiners({ viewByRefiner: { name: obj.name, path: obj.path, ids: [currentUserId] } })));
    }, []);

    return canViewOwnSearches || canViewOthersSearches ? (
        <div
            data-testid={"viewBy-refiner"}
            style={{ padding: "5px 15px 10px 2px", backgroundColor: conflictsPalette.background.white, borderBottom: `1px solid ${conflictsPalette.border}`, opacity: disabled ? 0.4 : 1 }}
        >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h2 style={{ fontSize: "18px", color: conflictsPalette.text.black, fontWeight: 400, margin: "10px 15px" }}>View By</h2>
                <Button
                    iconButton
                    startIcon={isRefinerOpen ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => setIsRefinerOpen(!isRefinerOpen)}
                    aria-label="Expand less viewBy"
                    variant="plain"
                    rounded
                    disabled={disabled}
                />
            </div>
            {isRefinerOpen ? (
                canViewOthersSearches ? (
                    <ViewByPickLists disabled={disabled} />
                ) : canViewOwnSearches ? (
                    <ViewByCheckboxes currentUserId={currentUserId} disabled={disabled} />
                ) : null
            ) : null}
        </div>
    ) : null;
};

export default ViewByRefinerContainer;
