import { SingleSelectValue } from "@aderant/aderant-react-components";
import { AssignedToUserPickList } from "components/AssignedToUserPickList/AssignedToUserPickList";
import { RootState } from "MyTypes";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { refinerActions } from "state/actions/RefinerActions";
import { ViewByRefiner, VIEW_BY_FILTERS } from "state/reducers/ReducerStateTypes";
import { getAllUsers, getCurrentUser, viewByRefinerSelector } from "state/selectors";
import { Messages } from "./Messages";

const ViewByPickLists = ({ disabled }: { disabled: boolean }) => {
    const dispatch = useDispatch();

    const viewByRefiners: ViewByRefiner[] = useSelector((rootState: RootState) => {
        return viewByRefinerSelector(rootState);
    });
    const users = useSelector(getAllUsers, shallowEqual);

    const currentUser = useSelector(getCurrentUser);
    const selectOptions: SingleSelectValue[] = users
        ? users.map((u) => {
              return { label: u.name, value: u.id };
          })
        : currentUser
        ? [{ label: currentUser.name, value: currentUser.id }]
        : [];

    const prepValues = (key: string): SingleSelectValue[] => {
        const ids = viewByRefiners.find((viewBy: ViewByRefiner) => viewBy.name == key)?.ids ?? [];
        const values = selectOptions.filter((option) => option.value && ids.includes(option.value));
        return values;
    };

    return (
        <>
            {VIEW_BY_FILTERS.map((view) => {
                return (
                    <AssignedToUserPickList
                        multiSelect
                        onUserChanged={(userIds?: (string | null)[] | null): void => {
                            const ids: (string | null | undefined)[] = userIds ?? [];
                            //If null is a selected value in the picklist, we want to filter with both null and undefined values
                            if (userIds?.includes(null)) {
                                ids.push(undefined);
                            }
                            dispatch(refinerActions.updateViewByRefiners({ viewByRefiner: { name: view.name, path: view.path, ids } }));
                        }}
                        label={Messages[view.name].getMessage()}
                        selectedUserIds={viewByRefiners.find((viewBy: ViewByRefiner) => viewBy.name === view.name)?.ids ?? []}
                        disabled={disabled}
                        labelProps={{ position: "top", width: "180px" }}
                        key={view.name}
                    />
                );
            })}
        </>
    );
};

export default React.memo(ViewByPickLists);
