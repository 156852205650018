import { ValidationMessage } from "../Errors/BasicErrors";
import { ConflictsError } from "../Errors/ConflictsError";
import { Messages } from "./Messages";

export interface NoWordOrPhrases extends ConflictsError<400, "NO_WOPS"> {
    httpStatusCode: 400;
    message: "No Words or Phrases were provided in the Search request.";
}

export function noWordOrPhrases(): NoWordOrPhrases {
    return {
        _conflictserrortype: "NO_WOPS",
        httpStatusCode: 400,
        message: "No Words or Phrases were provided in the Search request."
    };
}

export interface InvalidId extends ValidationMessage, ConflictsError<400, "INVALID_ID"> {
    httpStatusCode: 400;
    searchId: string | undefined;
    versionId: string | undefined;
    message: string;
}

export function invalidId(messageCode: string, message: string, searchId?: string, versionId?: string): InvalidId {
    return {
        _conflictserrortype: "INVALID_ID",
        httpStatusCode: 400,
        searchId: searchId,
        versionId: versionId,
        messageCode: messageCode,
        message: message
    };
}

export interface InvalidVersionNumber extends ValidationMessage, ConflictsError<400, "INVALID_VERSION_NUMBER"> {
    httpStatusCode: 400;
    searchId: string;
    message: string;
}

export function invalidVersionNumber(searchId: string): InvalidVersionNumber {
    return {
        _conflictserrortype: "INVALID_VERSION_NUMBER",
        httpStatusCode: 400,
        searchId: searchId,
        messageCode: Messages.VLD_INVALID_VERSION_NUMBER.messageCode,
        message: Messages.VLD_INVALID_SEARCH_VERSION.getMessage()
    };
}

export interface UserCannotViewSearch extends ValidationMessage, ConflictsError<403, "USER_CANNOT_VIEW_SEARCH"> {
    httpStatusCode: 403;
    message: string;
}
export function userCannotViewSearch(): UserCannotViewSearch {
    return {
        _conflictserrortype: "USER_CANNOT_VIEW_SEARCH",
        httpStatusCode: 403,
        messageCode: Messages.VLD_USER_CANNOT_VIEW_SEARCH.messageCode,
        message: Messages.VLD_USER_CANNOT_VIEW_SEARCH.getMessage()
    };
}

export interface NotAssignedTo extends ValidationMessage, ConflictsError<400, "NOT_ASSIGNED_TO"> {
    httpStatusCode: 400;
    message: string;
}
export function notAssignedTo(): NotAssignedTo {
    return {
        _conflictserrortype: "NOT_ASSIGNED_TO",
        httpStatusCode: 400,
        messageCode: Messages.VLD_ASSIGNED_TO_USER.messageCode,
        message: Messages.VLD_ASSIGNED_TO_USER.getMessage()
    };
}

export interface InvalidStatusChange extends ValidationMessage, ConflictsError<400, "INVALID_STATUS_CHANGE"> {
    httpStatusCode: 400;
    message: string;
    oldStatus: string;
    newStatus: string;
}
export function invalidStatusChange(oldStatus: string, newStatus: string): InvalidStatusChange {
    return {
        _conflictserrortype: "INVALID_STATUS_CHANGE",
        httpStatusCode: 400,
        oldStatus: oldStatus,
        newStatus: newStatus,
        messageCode: Messages.VLD_INVALID_STATUS_CHANGE.messageCode,
        message: Messages.VLD_INVALID_STATUS_CHANGE.getMessage(oldStatus, newStatus)
    };
}

export interface NotLatestSearchVersion extends ValidationMessage, ConflictsError<400, "NOT_LATEST_SEARCH_VERSION"> {
    httpStatusCode: 400;
    message: string;
}
export function notLatestSearchVersion(): NotLatestSearchVersion {
    return {
        _conflictserrortype: "NOT_LATEST_SEARCH_VERSION",
        httpStatusCode: 400,
        messageCode: Messages.VLD_INVALID_SEARCH_VERSION.messageCode,
        message: Messages.VLD_INVALID_SEARCH_VERSION.getMessage()
    };
}

export interface InvalidReassign extends ValidationMessage, ConflictsError<400, "INVALID_REASSIGN"> {
    httpStatusCode: 400;
    message: string;
}
export function invalidReassign(): InvalidReassign {
    return {
        _conflictserrortype: "INVALID_REASSIGN",
        httpStatusCode: 400,
        messageCode: Messages.VLD_INVALID_REASSIGN.messageCode,
        message: Messages.VLD_INVALID_REASSIGN.getMessage()
    };
}

export interface InvalidStatus extends ValidationMessage, ConflictsError {
    _conflictserrortype: "INVALID_STATUS";
    httpStatusCode: 400;
    message: string;
    status: string;
}

export function invalidStatus(status: string): InvalidStatus {
    return {
        _conflictserrortype: "INVALID_STATUS",
        httpStatusCode: 400,
        status: status,
        messageCode: Messages.VLD_INVALID_STATUS.messageCode,
        message: Messages.VLD_INVALID_STATUS.getMessage(status)
    };
}

export interface InvalidSearchType extends ValidationMessage, ConflictsError<400, "INVALID_SEARCH_TYPE"> {
    _conflictserrortype: "INVALID_SEARCH_TYPE";
    httpStatusCode: 400;
    message: string;
    searchType: string;
}

export function invalidSearchType(searchType: string): InvalidSearchType {
    return {
        _conflictserrortype: "INVALID_SEARCH_TYPE",
        httpStatusCode: 400,
        searchType: searchType,
        messageCode: Messages.VLD_INVALID_SEARCH_TYPE.messageCode,
        message: Messages.VLD_INVALID_SEARCH_TYPE.getMessage(searchType)
    };
}

export interface InvalidSearchQuery extends ValidationMessage, ConflictsError<400, "INVALID_SEARCH_QUERY"> {
    _conflictserrortype: "INVALID_SEARCH_QUERY";
    httpStatusCode: 400;
    queryString: string;
    message: string;
}

export function invalidSearchQuery(queryString: string): InvalidSearchQuery {
    return {
        _conflictserrortype: "INVALID_SEARCH_QUERY",
        httpStatusCode: 400,
        queryString: queryString,
        messageCode: Messages.VLD_INVALID_SEARCH_QUERY.messageCode,
        message: Messages.VLD_INVALID_SEARCH_QUERY.getMessage(queryString)
    };
}
