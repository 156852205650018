import { BooleanField } from "aderant-conflicts-models";
import React from "react";
import { styles } from "./commonStyles";
interface BooleanInformationProps {
    field: BooleanField;
}
export const BooleanInformation = (props: BooleanInformationProps): JSX.Element => {
    const {
        field: { fieldLabel, value }
    } = props;
    //Text to be displayed based on value provided
    const textValueToBeDisplayed = value === true ? "Yes" : "No";

    return (
        <>
            <span style={styles.labelStyle}>{fieldLabel}:</span>
            <span data-testid={`${fieldLabel}`} style={styles.valueStyle}>
                {textValueToBeDisplayed}
            </span>
        </>
    );
};
