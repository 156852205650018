import { Result, Unauthorized, unauthorized, ok } from "aderant-conflicts-models";
import { Logger } from "aderant-web-fw-core";
import { timingSafeEqual } from "crypto";
import { FunctionKeySecretConnector } from "./FunctionKeySecretConnector";
import { decodeUserProvidedKey } from "./Internal/decodeUserProvidedKey";
import { hash } from "./Internal/hash";

export type KeyTenantInfo = { uniqueTenantName: string; tenantId: string };

export async function validateConflictsAPIKey(
    logger: Logger,
    secretConnector: Pick<FunctionKeySecretConnector, "getStoredFunctionKeyInfoForTenant">,
    base64EncodedCompositeKey: string | undefined
): Promise<Result<{ uniqueTenantName: string; tenantId: string }, Unauthorized>> {
    logger.debug("validateConflictsAPIKey - Validating callers Conflicts API Key.");
    if (typeof base64EncodedCompositeKey !== "string") {
        logger.error("validateConflictsAPIKey - Type of key was not string, returning unauthorized.");
        return unauthorized();
    }
    const decodedUserProvidedKey = decodeUserProvidedKey(base64EncodedCompositeKey);
    if (!ok(decodedUserProvidedKey)) {
        logger.error("validateConflictsAPIKey - Key was not in expected format, returning unauthorized.");
        return decodedUserProvidedKey;
    }

    const internalKeys = await secretConnector.getStoredFunctionKeyInfoForTenant(decodedUserProvidedKey.uniqueTenantName);
    if (!ok(internalKeys)) {
        logger.error("validateConflictsAPIKey - There were no keys for the passed in tenant, returning unauthorized.");
        return unauthorized(); //if there are no keys yet for the current tenant, the provided one must be invalid
    }
    const internalKey = internalKeys.keys.find((k) => k.uniqueKeyName === decodedUserProvidedKey.uniqueKeyName);
    const hashedUserProvidedKey = hash(decodedUserProvidedKey.unhashedApiKey);

    if (internalKey) {
        const bufferedHashedUserProvidedKey = Buffer.from(hashedUserProvidedKey);
        const bufferedInternalHashedKey = Buffer.from(internalKey?.hashedKey);
        if (!timingSafeEqual(bufferedHashedUserProvidedKey, bufferedInternalHashedKey)) {
            logger.error("validateConflictsAPIKey - Passed in key did not match its stored hashed counterpart, returning unauthorized.");
            return unauthorized();
        }
    } else {
        logger.error("validateConflictsAPIKey - Passed in key did not have a stored hashed counterpart, returning unauthorized.");
        return unauthorized();
    }

    logger.debug("validateConflictsAPIKey - Key was valid, returning tenant info.");
    //important: make sure this return value is just the values that need to be here,
    //*not* the key itself - we want to keep access to the hashed key to this file only.
    return {
        uniqueTenantName: internalKey.uniqueTenantName,
        tenantId: internalKey.tenantId
    };
}
