import { Message } from "../Validation/Message";

export const Messages = {
    FIRM_SETTINGS: new Message("FIRM_SETTINGS", "Firm Settings"),
    EXPERT_INTEGRATION: new Message("EXPERT_INTEGRATION", "Expert"),
    INTEGRATIONS: new Message("INTEGRATIONS", "Integrations"),
    FIRM_OPTIONS: new Message("FIRM_OPTIONS", "Firm Options"),
    FEATURE_FLAGS: new Message("FEATURE_FLAGS", "Feature Flags"),
    SYNONYM_MANAGEMENT: new Message("SYNONYM_MANAGEMENT", "Synonym Management"),
    INFORMATION_BARRIERS: new Message("INFORMATION_BARRIERS", "Information Barriers"),
    API_KEY_MANAGEMENT: new Message("API_KEY_MANAGEMENT", "API Keys"),
    GENERAL: new Message("GENERAL", "General"),
    ALLOW_APPROVERS_TO_BE_DESIGNATED: new Message("ALLOW_APPROVERS_TO_BE_DESIGNATED", "Allow approvers to be designated"),
    ALLOW_STANDARD_USERS_TO_USE_QUICK_SEARCH: new Message("ALLOW_STANDARD_USERS_TO_USE_QUICK_SEARCH", "Allow Standard users to use quick searches"),
    QUICK_SEARCHES: new Message("QUICK_SEARCHES", "Quick Searches"),
    ENABLE_FUZZY_ON_QUICK_SEARCHES: new Message("ENABLE_FUZZY_ON_QUICK_SEARCHES", "Always enable fuzzy search for quick searches"),
    SEARCH_DEFAULTS: new Message("SEARCH_DEFAULTS", "Search Defaults"),
    INCLUDE_DATA_FROM_PRIOR_SEARCHES: new Message("INCLUDE_DATA_FROM_PRIOR_SEARCHES", "Include data from prior searches"),
    FIRM_SETTINGS_LOAD_FAILED: new Message(
        "FIRM_SETTINGS_LOAD_FAILED",
        "The firm settings failed to load, so the defaults are being used instead. Please refresh the page.\r\nIf the problem persists, contact the system administrator."
    ),
    FIRM_SETTINGS_PAGE_LOAD_FAILED: new Message<[string]>(
        "FIRM_SETTINGS_PAGE_LOAD_FAILED",
        "The {0} settings failed to load. If you want to manage the {0} settings, please refresh the page.\r\nIf the problem persists, contact the system administrator."
    ),
    FIRM_SETTINGS_SAVE_FAILED: new Message("FIRM_SETTINGS_SAVE_FAILED", "The settings failed to save. Please try again.")
};
