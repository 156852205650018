import { formatShortDateTimeDisplay, formatShortDateDisplay } from "@aderant/aderant-react-components";
import { DateField } from "aderant-conflicts-models";
import React from "react";
import { styles } from "./commonStyles";
interface DateInformationProps {
    field: DateField;
}
const getFormattedDate = (hasTimeComponent: boolean, date: Date | null | undefined): string => {
    if (!date) {
        return "-";
    }
    return hasTimeComponent ? formatShortDateTimeDisplay(date) : formatShortDateDisplay(date);
};
export const DateInformation = (props: DateInformationProps): JSX.Element => {
    const {
        field: { displayFormat, fieldLabel, value }
    } = props;
    //check if time component needs to be displayed
    const hasTimeComponent = displayFormat === "DateTime";
    return (
        <>
            <span style={styles.labelStyle}>{fieldLabel}:</span>
            <span data-testid={`${fieldLabel}`} style={styles.valueStyle}>
                {getFormattedDate(hasTimeComponent, value)}
            </span>
        </>
    );
};
