import { HttpRequestParams } from "@azure/functions";
import { isString } from "aderant-conflicts-models";
import { ClientAppContext, FunctionAppContext } from "../FunctionApps/ConflictsContext";

export function getSubscriptionFromUrlQueryParams(urlQueryParams: HttpRequestParams | undefined): string | undefined {
    if (!urlQueryParams) {
        return undefined;
    }
    const urlParams = new URLSearchParams(urlQueryParams);
    if (!urlParams) {
        return undefined;
    }
    //Dot was changed to hyphen in the url. Change it back to dot.
    const subscription = urlParams.get("subscription")?.replace(/-/g, ".");
    if (!subscription) {
        return undefined;
    }
    if (!isString(subscription)) {
        return undefined;
    }
    return subscription;
}

export function getUrlFriendlySubscriptionFromUserContext(context: Pick<ClientAppContext | FunctionAppContext, "currentUser">): string | undefined {
    const subscription = context.currentUser.tenancy.subscription;
    if (subscription?.id) {
        //Replace all dot with hyphen to avoid issues with Azure Function URL routing assuming the . is a file extension
        return subscription.id.replace(/\./g, "-");
    }
    return undefined;
}
