import { Clear } from "@mui/icons-material";
import { QuickSearch, RequestTerm, SearchVersion, SearchVersionNew } from "aderant-conflicts-models";
import {
    AddIcon,
    Button,
    EditableCellProps,
    EditableGrid,
    EditableGridColumnDefinition,
    GridCellValue,
    GridRowInstance,
    InputText,
    MessageDialog,
    PickList,
    SingleSelectValue
} from "@aderant/aderant-react-components";
import { getAffiliationDisplayName, getPartyStatusDisplayName } from "dataProviders/lookupList";
import { RootState } from "MyTypes";
import React, { ChangeEvent, CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SaveSearchActionTypes, searchActions } from "state/actions";
import { conflictsPalette } from "styles/conflictsPalette";
import { Messages } from "./Messages";
import { PartySearchProfile } from "./PartySearchProfile";
import { FieldValidity, validateRequestTerm, ValidationResult } from "./SearchRequestTermValidations";
import { configureTermToAdd, configureTermToUpdate, messages } from "./SearchUtils";

export interface SearchEditPageGridProps {
    searchVersion: SearchVersion | SearchVersionNew | QuickSearch;
    setDraftRequestTerm: (requestTerm: RequestTerm) => void;
    role?: string;
    "aria-labelledby"?: string;
    readonly?: boolean;
    isNewVersion?: boolean;
    requestTermsLimit?: number;
}

export default function SearchEditPageGrid(props: SearchEditPageGridProps): JSX.Element {
    const { requestTerms, id: searchVersionId } = props.searchVersion;
    const { isNewVersion = false, requestTermsLimit } = props;
    const dispatch = useDispatch();
    const [updateTermDialogOpen, setUpdateTermDialogOpen] = useState(false);
    const [rowData, setRowData] = useState<RequestTerm>({ term: "", searchTerms: [] });
    const isQuickSearch = props.searchVersion && props.searchVersion.isQuickSearch;
    const defaultRequestTermValues: RequestTerm = { id: undefined, term: "", searchTerms: [], affiliation: undefined, partyStatus: undefined };
    const termLimitReached = !!requestTermsLimit && requestTerms.length >= requestTermsLimit;

    //Get Lookup lists
    const affiliationList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.affiliationList;
    });

    const partyStatusList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.partyStatusList;
    });

    const requestTermInputRef = useRef<HTMLInputElement | null>(null);

    const handleAddNewTerm = (requestTerm: RequestTerm): Promise<RequestTerm> => {
        const isValid = !!requestTerm.term && validateRequestTerm(requestTerm, isQuickSearch).status === FieldValidity.Valid;
        if (isValid) {
            const termToAdd = configureTermToAdd(requestTerm);
            dispatch(
                searchActions.saveSearch({
                    saveSearchActionInput: { actionType: SaveSearchActionTypes.ADD_TERM, payload: { searchVersion: props.searchVersion, term: termToAdd } },
                    doNotPersist: isNewVersion || isQuickSearch
                })
            );
            requestTermInputRef?.current?.focus();
            return Promise.resolve(requestTerm);
        } else {
            return Promise.reject("Validation of row failed.");
        }
    };

    const handleDeleteTerm = (rowIndex: number, originalRow?: RequestTerm) => {
        if (originalRow?.id) {
            dispatch(
                searchActions.saveSearch({
                    saveSearchActionInput: { actionType: SaveSearchActionTypes.DELETE_TERM, payload: { searchVersion: props.searchVersion, termId: originalRow.id } },
                    doNotPersist: isNewVersion || isNewVersion
                })
            );
        } else {
            const rowToDelete = requestTerms[rowIndex];
            //TODO: related to 2236
            dispatch(
                searchActions.saveSearch({
                    saveSearchActionInput: { actionType: SaveSearchActionTypes.DELETE_TERM, payload: { searchVersion: props.searchVersion, termId: rowToDelete.id! } },
                    doNotPersist: isNewVersion || isNewVersion
                })
            );
        }
    };

    const handleUpdatingTerm = (requestTerm: RequestTerm): void => {
        const termToUpdate = configureTermToUpdate(requestTerm);
        dispatch(
            searchActions.saveSearch({
                saveSearchActionInput: { actionType: SaveSearchActionTypes.UPDATE_TERM, payload: { searchVersion: props.searchVersion, term: termToUpdate } },
                doNotPersist: isNewVersion || isQuickSearch
            })
        );
    };

    const renderRequestTermInputText = ({ column: { id }, value: cellValue, addRow, updateRow, state: { addNewRowData } }: EditableCellProps<RequestTerm>) => {
        const [value, setValue] = useState(cellValue);
        const [requestTermValidation, setRequestTermValidation] = useState<ValidationResult | undefined>(undefined);
        const [addTermDialogOpen, setAddTermDialogOpen] = useState(false);
        useEffect(() => {
            setValue(cellValue);
        }, [cellValue]);

        const updateGridState = (value: string) => {
            const validation = validateRequestTerm({ id: undefined, term: value, searchTerms: [], affiliation: undefined, partyStatus: undefined }, isQuickSearch);
            setRequestTermValidation(validation);
            updateRow(id, value);
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onChange = (e: ChangeEvent, inputValue: any, reason: string | undefined) => {
            if (reason == "blur") {
                setValue(inputValue?.trim());
                updateGridState(inputValue);
            } else {
                //Only update the grid state onChange when the value changes from empty to string with value and vice versa
                //justification: This is needed for the add new term button to be disabled when the Request term input text is empty and vice versa
                if ((inputValue && !value) || (inputValue && value && inputValue.length !== value.length)) {
                    updateGridState(inputValue);
                } else if (!inputValue) {
                    updateGridState(inputValue);
                }
                setValue(inputValue);
            }
        };

        function requestTermsPlaceholder() {
            if (termLimitReached) {
                return `Maximum number of terms reached`;
            }
            if (isQuickSearch) {
                return messages.ctrlEnterAddTerm;
            }
            return messages.ctrlEnterAddRequestTerm;
        }

        return (
            <>
                <div
                    data-testid="searchEditPageDesktopGrid-requestTerm"
                    style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <InputText
                        value={value || ""}
                        required={requestTerms.length == 0}
                        requiredMessage={isQuickSearch ? messages.addATerm : messages.addARequestTerm}
                        hasError={(requestTermValidation?.status ?? FieldValidity.Valid) === FieldValidity.Error}
                        errorMessage={requestTermValidation?.message ?? ""}
                        onChange={onChange}
                        inputRef={requestTermInputRef}
                        disabled={props.readonly || termLimitReached}
                        id="request-terms-grid-request-term"
                        //1rem padding on top because the text box has 1rem of space always taken up below it for error/required text, so we need it on top too to center the field
                        style={{ flexGrow: 2, paddingRight: "9.1px", paddingTop: "1rem", alignSelf: "center", lineHeight: 1.6, justifyContent: "flex-start" }}
                        aria-label="Request Term"
                        placeholder={requestTermsPlaceholder()}
                    />
                    {!isQuickSearch && (
                        <Button
                            iconButton
                            rounded
                            disabled={!value || !(requestTermValidation?.status === FieldValidity.Valid)}
                            size="small"
                            startIcon={<AddIcon fontSize="small" />}
                            variant="outlined"
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                                setAddTermDialogOpen(true);
                            }}
                            aria-label={Messages.ADD_SEARCH_REQUEST_BUTTON.getMessage()}
                            title={Messages.ADD_SEARCH_REQUEST_BUTTON.getMessage()}
                            style={{ alignSelf: "center", marginBottom: "3px" }}
                        />
                    )}
                </div>
                <PartySearchProfile
                    open={addTermDialogOpen}
                    onClose={() => setAddTermDialogOpen(false)}
                    onSave={addRow}
                    rowData={{ ...addNewRowData, ...{ searchTerms: [addNewRowData.term] } }}
                    searchVersionId={searchVersionId}
                    requestTerms={requestTerms}
                    affiliationList={affiliationList}
                    partyStatusList={partyStatusList}
                />
            </>
        );
    };

    const renderAffiliationPickList = ({ value: cellValue, updateRow, column: { id } }: EditableCellProps<RequestTerm>) => {
        const [value, setValue] = useState(cellValue ? cellValue : null);
        useEffect(() => {
            const valObject = affiliationList.find((affiliation) => affiliation.value == cellValue);
            setValue(valObject || null);
        }, [cellValue]);

        const onChange = (e: ChangeEvent, inputValue?: SingleSelectValue | null) => {
            const newValue = inputValue ? inputValue.value : inputValue;
            updateRow(id, newValue);
        };
        return (
            //"1rem top padding to match Request term input"
            <div style={{ lineHeight: 1.6, paddingTop: "1rem" }}>
                <PickList id="request-terms-grid-affiliation" selectOptions={affiliationList} value={value} onChange={onChange} aria-label="Affiliation" disabled={props.readonly} />
            </div>
        );
    };

    const renderPartyStatusPickList = ({ value: cellValue, updateRow, column: { id } }: EditableCellProps<RequestTerm>) => {
        const [value, setValue] = useState(cellValue ? cellValue : null);
        useEffect(() => {
            const valObject = partyStatusList.find((partyStatus) => partyStatus.value == cellValue);
            setValue(valObject || null);
        }, [cellValue]);
        const onChange = (e: ChangeEvent, inputValue?: SingleSelectValue | null) => {
            const newValue = inputValue ? inputValue.value : inputValue;
            updateRow(id, newValue);
        };
        return (
            //"1rem top padding to match Request term input"
            <div style={{ lineHeight: 1.6, paddingTop: "1rem" }}>
                <PickList id="request-terms-grid-party-status" selectOptions={partyStatusList} value={value} onChange={onChange} aria-label="Party Status" disabled={props.readonly} />
            </div>
        );
    };

    const renderDeleteRequestTermButton = ({ deleteRow, row: { index, original } }: EditableCellProps<RequestTerm>) => {
        const [discardRequestTermDialogOpen, setDiscardRequestTermDialogOpen] = useState(false);
        return (
            <div onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
                <Button
                    iconButton
                    rounded
                    size="small"
                    variant="plain"
                    startIcon={<Clear fontSize="small" />}
                    onClick={() => {
                        setDiscardRequestTermDialogOpen(true);
                    }}
                    aria-label={`Delete request term ${original.term}`}
                    data-testid="delete-request-term-button"
                    style={{ padding: "2px" /*needed to avoid chopping off drop shadow*/ }}
                />

                <MessageDialog
                    data-testid="discard-request-term-dialog"
                    open={discardRequestTermDialogOpen}
                    onClose={() => {
                        setDiscardRequestTermDialogOpen(false);
                    }}
                    title={isQuickSearch ? messages.removeQuickSearchTerm : messages.removeRequestTerm}
                    message={isQuickSearch ? messages.discardQuickSearchTerm : messages.discardRequestTerm}
                    footer={
                        <>
                            <Button
                                data-testid="discard-request-term-dialog-no"
                                text="No"
                                color="secondary"
                                onClick={() => {
                                    setDiscardRequestTermDialogOpen(false);
                                }}
                                size="medium"
                            />
                            <Button
                                data-testid="discard-request-term-dialog-yes"
                                text="Yes"
                                onClick={() => {
                                    setDiscardRequestTermDialogOpen(false);
                                    deleteRow(index, original);
                                }}
                                size="medium"
                            />
                        </>
                    }
                />
            </div>
        );
    };

    const renderAddRequestTermButton = ({ addRow, state: { addNewRowData } }: EditableCellProps<RequestTerm>) => {
        useEffect(() => {
            props.setDraftRequestTerm({ ...addNewRowData, ...{ searchTerms: [addNewRowData.term] } });
        }, [addNewRowData]);

        return (
            <Button
                color="primary"
                rounded
                iconButton
                startIcon={<AddIcon fontSize="small" />}
                onClick={() => addRow()}
                disabled={!addNewRowData.term || validateRequestTerm(addNewRowData, isQuickSearch).status !== FieldValidity.Valid}
                aria-label={Messages.ADD_REQUEST_TERM_BUTTON.getMessage()}
                title={Messages.ADD_REQUEST_TERM_BUTTON.getMessage()}
                style={{ marginBottom: "2px", padding: "2px" /*needed to avoid chopping off drop shadow*/ }}
            />
        );
    };
    const cellStyle: { cellEditStyle: CSSProperties } = {
        cellEditStyle: {
            borderRight: `1px solid ${conflictsPalette.border}`,
            paddingRight: "21.1px" //12px (I think) of space for the error icon (it's relative to the font size (currently 20), which is not the same as our root font size so we can't use rems), and 9.1px of padding. We want to have the same amount of empty space on the rhs so pad here
            //                       should this just be in the control? Probably don't always want it so would have to be optional, but it would at least know how wide it needs to be
        }
    };
    const getSearchTermsDisplay = (searchTerms: string[]): string => {
        return searchTerms.join("; ");
    };

    const requestTermDisplayCell = ({ value, row: { original } }: EditableCellProps<RequestTerm>) => {
        if (isQuickSearch) {
            return <span>{value}</span>;
        } else {
            return (
                <span>
                    <b>{value} </b>({getSearchTermsDisplay(original.searchTerms)})
                </span>
            );
        }
    };

    const getColumnName = () => {
        if (requestTermsLimit) {
            return `${isQuickSearch ? messages.term : messages.requestTerm} (${requestTerms.length} of ${requestTermsLimit})`;
        } else {
            return `${isQuickSearch ? messages.term : messages.requestTerm} (${requestTerms.length})`;
        }
    };

    const columns: EditableGridColumnDefinition<RequestTerm>[] = useMemo(
        () => [
            {
                columnName: getColumnName(),
                path: "term",
                CellEdit: renderRequestTermInputText,
                Cell: requestTermDisplayCell,
                flexGrow: 2,
                ...cellStyle
            },
            {
                columnName: "searchTerms",
                path: "searchTerms",
                //This is included in the list of columns (although it is not visible) because we are editing/adding data with search terms in the grid.
                //The grid requires a column definition for properties on edit/add so that it knows how to transform it back to SearchRequestTerm
                isVisible: false
            },
            {
                columnName: "Affiliation",
                path: (originalRow: RequestTerm) => (originalRow.affiliation ? getAffiliationDisplayName(originalRow.affiliation, affiliationList) : undefined),
                id: "affiliation",
                transformCellValueOnRowEdit: (cellValue: GridCellValue, originalRow: RequestTerm) => {
                    originalRow.affiliation = cellValue?.toString();
                    return originalRow;
                },
                CellEdit: renderAffiliationPickList,
                flexGrow: 1,
                ...cellStyle
            },
            {
                columnName: "Party Status",
                path: (originalRow: RequestTerm) => (originalRow.partyStatus ? getPartyStatusDisplayName(originalRow.partyStatus, partyStatusList) : undefined),
                id: "partyStatus",
                transformCellValueOnRowEdit: (cellValue: GridCellValue, originalRow: RequestTerm) => {
                    originalRow.partyStatus = cellValue?.toString();
                    return originalRow;
                },
                CellEdit: renderPartyStatusPickList,
                flexGrow: 1,
                ...cellStyle
            },
            {
                columnName: "",
                id: "action",
                isDataProperty: false,
                Cell: renderDeleteRequestTermButton,
                CellEdit: renderAddRequestTermButton,
                isVisible: !props.readonly,
                width: 50
            }
        ],
        [requestTerms.length]
    );

    const rowMetadata = {
        //rowData.id should always have an id by the time it is added as a row as we stamp on an id in handleAddNewTerm
        getRowId: useCallback((rowData: RequestTerm) => rowData.id!, []),
        getRowProps: useCallback(
            (row: GridRowInstance<RequestTerm>) => {
                return {
                    onClick: () => {
                        if (!props.readonly) {
                            setRowData(row.original);
                            setUpdateTermDialogOpen(true);
                        }
                    }
                };
            },
            [props.readonly]
        )
    };

    return (
        <>
            <EditableGrid<RequestTerm>
                data={requestTerms}
                columnDefinitions={columns}
                editableProps={{
                    defaultNewRow: defaultRequestTermValues,
                    onRowAdd: async (newData: RequestTerm): Promise<RequestTerm> => handleAddNewTerm(newData),
                    onRowDelete: async (rowIndex: number, originalRow?: RequestTerm) => handleDeleteTerm(rowIndex, originalRow)
                }}
                rowMetadata={rowMetadata}
                options={{
                    allowMoveColumns: false
                }}
                style={{
                    grid: {
                        flex: "1 1 auto"
                    },
                    rows: {
                        editableRowStyle: {
                            backgroundColor: conflictsPalette.background.input
                        },
                        displayRowStyle: {
                            backgroundColor: conflictsPalette.background.white,
                            color: conflictsPalette.text.hyperlink
                        }
                    }
                }}
            />
            <PartySearchProfile
                data-testid="edit-party-search-profile"
                open={updateTermDialogOpen}
                onClose={() => setUpdateTermDialogOpen(false)}
                onSave={handleUpdatingTerm}
                rowData={rowData}
                searchVersionId={searchVersionId}
                requestTerms={requestTerms}
                affiliationList={affiliationList}
                partyStatusList={partyStatusList}
                isQuickSearch={isQuickSearch}
            />
        </>
    );
}
