import { ArrowBackIos } from "@mui/icons-material";
import { currentUserCanChangeAssignedToDirect, QuickSearch, RequestTerm, SearchVersion, SearchVersionNew } from "aderant-conflicts-models";
import { AddIcon, Button, Tab, Tabs } from "@aderant/aderant-react-components";
import { Logger } from "aderant-web-fw-core";
import { ImportTermsModal } from "components/ImportTermsModal/ImportTermsModal";
import PageContent from "components/PageContainer/PageContent";
import SearchEditPageFooter from "pages/SearchEditPage/SearchEditPageFooter";
import { FeatureBySearchTypeHelper } from "pages/Shared/FeatureBySearchType";
import React, { CSSProperties, useEffect, useState } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { conflictsPalette } from "styles/conflictsPalette";
import { PathMap } from "utilities/routingPathMap";
import { searchActions } from "../../state/actions";
import { usePermissionsContext } from "../../state/selectors/appSelectors";
import { Messages } from "./Messages";
import SearchEditPageDetails from "./SearchEditPageDetails";
import SearchEditPageGrid from "./SearchEditPageGrid";
import { getSearchEditPageState } from "./SearchEditPageState";
import { FieldValidity, validateRequestTerm } from "./SearchRequestTermValidations";
import { configureTermToAdd } from "./SearchUtils";
import { isSearchNameValid } from "./SearchValidation";

type SearchEditPageTabNames = "Details" | "Terms";

export type SearchEditPageDesktopProps = {
    searchVersion: SearchVersion | SearchVersionNew | QuickSearch | undefined;
    logger: Logger;
    pageDetails: {
        pageTitle: string;
        isNewVersion?: boolean;
        requestTermsLimit?: number;
        searchAction: (searchVersion: SearchVersion | SearchVersionNew | QuickSearch, history, updateIsSearchingWhenDone: boolean) => void;
        addTermThenPerformSearchAction: (searchVersion: SearchVersion | SearchVersionNew | QuickSearch, history, requestTerm: RequestTerm, updateIsSearchingWhenDone: boolean) => void;
        suppressUnsavedDataHandling: React.MutableRefObject<boolean>;
    };
    children?: React.ReactNode;
};

const pageTabs: CSSProperties = {
    borderBottom: `1px solid ${conflictsPalette.primary.Ahsoka}`,
    marginBottom: "5px"
};

const tabAccessibilityProps = (index: number): Record<string, string> => {
    return {
        id: `search-request-tab-${index}`,
        "aria-controls": `search-request-tabpanel-${index}`
    };
};

const tabPanelAccessibilityProps = (index: number): Record<string, string> => {
    return {
        role: "tabpanel",
        id: `search-request-tabpanel-${index}`,
        "aria-labelledby": `search-request-tab-${index}`
    };
};

const resetTabs = (tabs: SearchEditPageTabNames[], isDetailsTabVisible: boolean, isTermsTabVisible: boolean) => {
    tabs = [];
    if (isDetailsTabVisible) {
        tabs.push("Details");
    }
    if (isTermsTabVisible) {
        tabs.push("Terms");
    }
    return tabs;
};

export default function SearchEditPage(props: SearchEditPageDesktopProps): JSX.Element {
    const { searchVersion, logger, pageDetails } = props;
    const isDetailsTabVisible = FeatureBySearchTypeHelper.showDetailsTab(searchVersion);
    const isTermsTabVisible = FeatureBySearchTypeHelper.showTermsTab(searchVersion);
    let tabs: SearchEditPageTabNames[] = resetTabs([], isDetailsTabVisible, isTermsTabVisible);
    const [draftRequestTerm, setDraftRequestTerm] = useState<RequestTerm>({ id: undefined, term: "", searchTerms: [], affiliation: undefined, partyStatus: undefined });
    const [selectedTab, setSelectedTab] = useState<SearchEditPageTabNames>(tabs[0]);
    const [isImportTermsDialogOpen, setIsImportTermsDialogOpen] = useState<boolean>(false);
    const pageState = { ...getSearchEditPageState(searchVersion) };
    const permissions = usePermissionsContext();
    const isOnlyOneTabVisible = (isDetailsTabVisible ? 1 : 0) + (isTermsTabVisible ? 1 : 0) === 1;

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        //reset tabs
        tabs = resetTabs(tabs, isDetailsTabVisible, isTermsTabVisible);
        setSelectedTab(tabs[0]);
    }, [isDetailsTabVisible, isTermsTabVisible]);

    //need to use refs for these two (I think) because we're jumping around multiple functions that all set/use these
    //values in the same render.
    //might be cool to encapsulate this pattern in a hook if we use it anywhere else.
    const detailsAreRequired: boolean = tabs.includes("Details") && (isSearchNameValid(searchVersion?.name).status !== FieldValidity.Valid || !searchVersion?.requestedByUserId);
    const termsAreRequired: boolean = tabs.includes("Terms") && searchVersion?.requestTerms.length === 0;

    const cancel = () => {
        history.push(searchVersion?.isQuickSearch ? PathMap.quickSearches : "/");
    };

    const handleKeyPress = (key: string) => {
        switch (key) {
            case "esc":
                cancel();
                break;
        }
    };

    const handleTabChange = (event: Event, newValue: SearchEditPageTabNames) => {
        setSelectedTab(newValue);
    };

    const updateDraftRequestTerm = (requestTerm: RequestTerm) => {
        setDraftRequestTerm(requestTerm);
    };

    const performSearch = (searchVersion: SearchVersion | SearchVersionNew | QuickSearch, termInAddNewRow: RequestTerm | undefined) => {
        const updateIsSearchingWhenDone = true;
        dispatch(searchActions.isSearching(true));
        if (termInAddNewRow && validateRequestTerm(termInAddNewRow, searchVersion.isQuickSearch).status === FieldValidity.Valid) {
            pageDetails.addTermThenPerformSearchAction(searchVersion, history, termInAddNewRow, updateIsSearchingWhenDone);
        } else {
            //otherwise just perform the search with the rows that *have* been added
            pageDetails.searchAction(searchVersion, history, updateIsSearchingWhenDone);
        }
    };

    const submitSearch = (searchVersion: SearchVersion | SearchVersionNew, termInAddNewRow: RequestTerm | undefined) => {
        pageDetails.suppressUnsavedDataHandling.current = true;
        if (termInAddNewRow && validateRequestTerm(termInAddNewRow, searchVersion.isQuickSearch).status === FieldValidity.Valid) {
            dispatch(searchActions.addTermThenSubmitSearch({ searchVersion, history, requestTerm: termInAddNewRow }));
        } else {
            //otherwise just submit the search with the rows that *have* been added
            dispatch(searchActions.submitSearch({ searchVersion, history }));
        }
    };

    const getPageFooterAction = (): ((e: React.MouseEvent) => void) => {
        if (!searchVersion) {
            return () => {
                console.info("No footer action for null search version");
            };
        }

        //if the user has entered a term in the new row input but hasn't added it we want to include it in the search being performed.
        const termInAddNewRow: RequestTerm | undefined = draftRequestTerm && draftRequestTerm.term ? configureTermToAdd(draftRequestTerm) : undefined;

        switch (pageState.pageFooterAction) {
            case "SEARCH":
                return (e: React.MouseEvent<Element, MouseEvent>) => {
                    performSearch(searchVersion, termInAddNewRow);
                };
            case "SUBMIT":
                return () => !searchVersion.isQuickSearch && submitSearch(searchVersion, termInAddNewRow);
            case "DISABLED":
            default:
                return () => {
                    console.error("Footer button should not be enabled, click action does nothing");
                };
        }
    };

    function onBeforeUnload(event: Event) {
        if (searchVersion?.editState !== "CURRENT") {
            event.preventDefault(); //this will cause the "You have unsaved changes" dialog to pop up on refresh/close/back (only on back if previous page was not in the app).
            event.returnValue = false; //deprecated in favor of preventDefault, using for old browser compat
        }
    }

    useEffect(() => {
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => window.removeEventListener("beforeunload", onBeforeUnload);
    }, [searchVersion]);

    const pageFooterAction = getPageFooterAction();

    function tabInformationRequired(tabName: SearchEditPageTabNames) {
        switch (tabName) {
            case "Details":
                return detailsAreRequired;
            case "Terms":
                return termsAreRequired;
            default:
                console.error("Specified tab name is incorrect");
        }
    }

    return (
        <>
            {searchVersion && FeatureBySearchTypeHelper.canImportTerms(searchVersion) && (
                <ImportTermsModal
                    termsTemplate="RequestTermsTemplate"
                    open={isImportTermsDialogOpen}
                    onCancel={() => {
                        setIsImportTermsDialogOpen(false);
                    }}
                    onAccept={() => {
                        setIsImportTermsDialogOpen(false);
                    }}
                    searchVersion={searchVersion}
                    isNewVersion={pageDetails.isNewVersion}
                />
            )}
            <div style={{ display: "flex", margin: "0 10%", position: "relative" }}>
                <Button onClick={cancel} startIcon={<ArrowBackIos />} text="Back" rounded color="secondary" style={{ height: "fit-content", alignSelf: "center", position: "absolute" }} />
                <h1 style={{ fontWeight: 300, margin: "1rem auto", fontSize: "1.5rem" }}>{pageDetails.pageTitle}</h1>
            </div>
            <PageContent style={{ margin: "0 10% 30px 10%" }}>
                <KeyboardEventHandler handleKeys={["space", "esc"]} onKeyEvent={(key: string) => handleKeyPress(key)} />
                <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={pageTabs}>
                        <Tabs value={selectedTab} onChange={handleTabChange} centered aria-label="search request tabs">
                            {tabs.map((tab, index) => {
                                return <Tab key={index} label={tab} value={tab} required={tabInformationRequired(tab)} {...tabAccessibilityProps(index)} disabled={isOnlyOneTabVisible} />;
                            })}
                            {selectedTab === "Terms" && !pageState.isReadOnly && FeatureBySearchTypeHelper.canImportTerms(searchVersion) && (
                                <div style={{ position: "absolute", right: "10px", top: "10px", display: "flex", alignItems: "center" }}>
                                    <div data-testid="import-terms-label" style={{ marginRight: "10px", color: conflictsPalette.text.grey }}>
                                        {Messages.IMPORT_TERMS_LABEL.getMessage()}
                                    </div>
                                    <Button
                                        iconButton
                                        rounded
                                        size="small"
                                        startIcon={<AddIcon fontSize="small" />}
                                        variant="outlined"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                            setIsImportTermsDialogOpen(true);
                                        }}
                                        aria-label={Messages.IMPORT_TERMS_BUTTON.getMessage()}
                                        title={Messages.IMPORT_TERMS_BUTTON.getMessage()}
                                        margin-left="10px"
                                    />
                                </div>
                            )}
                        </Tabs>
                    </div>

                    <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
                        {selectedTab === "Details" && FeatureBySearchTypeHelper.showDetailsTab(searchVersion) && (
                            <SearchEditPageDetails
                                searchVersion={searchVersion}
                                logger={logger}
                                {...tabPanelAccessibilityProps(tabs.indexOf("Details"))}
                                disableFuzzySearch={!pageState.isFuzzySearchButtonVisible}
                                readonly={pageState.isReadOnly || searchVersion === undefined}
                                isNewVersion={pageDetails.isNewVersion}
                                userCanReassign={!!(searchVersion && searchVersion.editState !== "NEW" && permissions && currentUserCanChangeAssignedToDirect(permissions, searchVersion))}
                                suppressUnsavedDataHandling={pageDetails.suppressUnsavedDataHandling}
                            />
                        )}
                        {selectedTab === "Terms" && !!searchVersion && (
                            <SearchEditPageGrid
                                setDraftRequestTerm={updateDraftRequestTerm}
                                searchVersion={searchVersion}
                                {...tabPanelAccessibilityProps(tabs.indexOf("Terms"))}
                                readonly={pageState.isReadOnly}
                                isNewVersion={pageDetails.isNewVersion}
                                requestTermsLimit={pageDetails.requestTermsLimit}
                            />
                        )}
                    </div>
                    <SearchEditPageFooter
                        action={pageFooterAction}
                        searchVersion={searchVersion}
                        fieldsAreRequired={detailsAreRequired || termsAreRequired}
                        actionButtonText={pageState.pageFooterButtonText}
                        alwaysDisabled={!pageState.isPageFooterButtonEnabled}
                    />
                </div>
                {props.children}
            </PageContent>
        </>
    );
}
