import { SendGridSettings } from "aderant-conflicts-environment";
import {
    BasicCurrentUserContext,
    EmailErrors,
    EmailWithContext,
    isEmailSearchStatus,
    isSearchedStatus,
    LoggedInUser,
    SearchStatuses,
    SearchVersion,
    SearchVersionChanges,
    SearchVersionNew
} from "aderant-conflicts-models";
import { EnvironmentService } from "../../index";

function getEmailContext(type: string, id: string | number, emailTo: string): EmailErrors.EmailValidationContext {
    return {
        type: type,
        id: id,
        emailTo: emailTo
    };
}

function buildEmailAssignSearch(
    context: BasicCurrentUserContext,
    message: string,
    searchRequest: SearchVersion | SearchVersionNew,
    emailSettings: SendGridSettings,
    openSearchUrl: string
): EmailWithContext {
    const searchNumber = !searchRequest.number ? "" : searchRequest.version < 2 ? searchRequest.number : `${searchRequest.number}.${searchRequest.version}`;
    const subjectPrefix = !searchRequest.number ? "A search request" : `Search request ${searchNumber}`;
    const emailWithTemplate: EmailWithContext["email"] = {
        from: emailSettings.fromEmailAddress,
        personalizations: [
            {
                //We put the userId into 'to' here - the enQueueEmail will lookup the email address and replace it
                to: searchRequest.assignedToUserId ?? "",
                dynamicTemplateData: {
                    subjectPrefix: subjectPrefix,
                    openSearchUrl: openSearchUrl,
                    searchNumber: searchNumber,
                    searchId: searchRequest.searchId,
                    //TODO(4855) cast can go away when this WI is completed - need to either just stick user name in the stuff that always goes to KeyAuth/QueueTrigger functions, or make it an input param here and acquire it outside of this code.
                    //justification: code will run fine if context.currentUser isn't a LoggedInUser, will just grab the id instead.
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    assignedBy: (context.currentUser as LoggedInUser).name ?? context.currentUser.id,
                    message: message
                }
            }
        ],
        customArgs: {
            tenancy: context.currentUser.tenancy,
            sourceApp: "Conflicts",
            emailType: "Search Request assigned"
        },
        templateId: emailSettings.assignSearchTemplateId
    };
    const emailWithContext: EmailWithContext = {
        email: emailWithTemplate,
        context: getEmailContext("searchRequest", searchRequest.id, searchRequest.assignedToUserId ?? "")
    };
    return emailWithContext;
}

function buildEmailCompleteSearch(
    context: BasicCurrentUserContext,
    message: string,
    searchRequest: SearchVersion | SearchVersionNew,
    emailSettings: SendGridSettings,
    openSearchUrl: string
): EmailWithContext {
    const searchNumber = !searchRequest.number ? "" : searchRequest.version < 2 ? searchRequest.number : `${searchRequest.number}.${searchRequest.version}`;
    const subjectPrefix = !searchRequest.number ? "A search request" : `Search request ${searchNumber}`;
    const emailWithTemplate: EmailWithContext["email"] = {
        from: emailSettings.fromEmailAddress,
        personalizations: [
            {
                //We put the userId into 'to' here - the enQueueEmail will lookup the email address and replace it
                to: searchRequest.requestedByUserId ?? "",
                dynamicTemplateData: {
                    subjectPrefix: subjectPrefix,
                    openSearchUrl: openSearchUrl,
                    searchNumber: searchNumber,
                    searchId: searchRequest.searchId,
                    status: searchRequest.status == SearchStatuses.ConditionalApproval ? "conditionally approved" : searchRequest.status.toLocaleLowerCase(),
                    //TODO(4855) cast can go away when this WI is completed - need to either just stick user name in the stuff that always goes to KeyAuth/QueueTrigger functions, or make it an input param here and acquire it outside of this code.
                    //justification: code will run fine if context.currentUser isn't a LoggedInUser, will just grab the id instead.
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    completedBy: (context.currentUser as LoggedInUser).name ?? context.currentUser.id,
                    message: message
                }
            }
        ],
        customArgs: {
            tenancy: context.currentUser.tenancy,
            sourceApp: "Conflicts",
            emailType: "Search Request completed"
        },
        templateId: emailSettings.completeSearchTemplateId
    };
    const emailWithContext: EmailWithContext = {
        email: emailWithTemplate,
        context: getEmailContext("searchRequest", searchRequest.id, searchRequest.requestedByUserId ?? "")
    };
    return emailWithContext;
}

export function buildEmails(
    context: BasicCurrentUserContext,
    reassignMessage: string,
    statusNotificationMessage: string,
    searchRequest: SearchVersion | SearchVersionNew,
    searchChanges: SearchVersionChanges
): EmailWithContext[] {
    const settings = EnvironmentService.getSendGridSettings();
    if (!settings) {
        context.logger.error("No SendGrid settings found in configuration. No emails will be sent.");
        return [];
    }
    if (!settings.fromEmailAddress) {
        context.logger.error("No SendGrid settings for from email address found in configuration. No emails will be sent.");
        return [];
    }
    const webAppUrl = EnvironmentService.getClientWebAppUrl();
    let openSearchUrl = "";
    if (isSearchedStatus(searchRequest.status)) {
        openSearchUrl = `${webAppUrl}/results/${searchRequest.searchId}`;
    } else {
        openSearchUrl = `${webAppUrl}/search/${searchRequest.searchId}`;
    }
    const emails: EmailWithContext[] = [];
    if (searchChanges.assignedToUserId && searchRequest.assignedToUserId) {
        if (settings.assignSearchTemplateId) {
            const email = buildEmailAssignSearch(context, reassignMessage, searchRequest, settings, openSearchUrl);
            emails.push(email);
        } else {
            context.logger.error("No SendGrid setting for assigned search template id found in configuration. Assign search email not sent.");
        }
    }
    if (searchChanges.status && isEmailSearchStatus(searchRequest.status)) {
        if (settings.completeSearchTemplateId) {
            const email = buildEmailCompleteSearch(context, statusNotificationMessage, searchRequest, settings, openSearchUrl);
            emails.push(email);
        } else {
            context.logger.error("No SendGrid setting for complete search template id found in configuration. Complete search email not sent.");
        }
    }
    return emails;
}
