import { ValidationMessage } from "./Errors/BasicErrors";
export class Message<Params extends Array<string | number | boolean | string[] | number[]> = [], MessageCode extends string = string> {
    public readonly messageCode: MessageCode;
    private readonly message: string;

    constructor(messageCode: MessageCode, message: string) {
        this.messageCode = messageCode;
        this.message = message;
    }

    public getMessage(...params: Params): string {
        let message: string = this.message;
        if (params) {
            for (let i = 0; i < params.length; i++) {
                let param = params[i];
                if (Array.isArray(param)) {
                    param = param.join(", ");
                }
                if (typeof param !== "string" && param !== null && param !== undefined) {
                    param = param.toString();
                }
                if (typeof param === "string") {
                    if (param.toLowerCase() === "null" || param.toLowerCase() === "undefined") {
                        //print strings containing an actual value of 'null' or 'undefined' with some extra context
                        //so that we can differentiate between that case and actual null/undefined values
                        //(unlikely edge case, but it seems worth handling)
                        param = `(non null/undefined string with value '${param}')`;
                    }
                }
                message = message.replace(new RegExp(`\\{${i}\\}`, "g"), param);
            }
        }
        return message;
    }

    public asValidationMessage(...params: Params): ValidationMessage<MessageCode> {
        return { messageCode: this.messageCode, message: this.getMessage(...params) };
    }
}

export const Messages = {
    VLD_ETAG_MISMATCH: new Message("VLD_ETAG_MISMATCH", "Updates have been made to the document you are trying to save. Please refresh the page to fetch the latest updates and try again."),
    VLD_SYNTAX_JSON: new Message("VLD_SYNTAX_JSON", "JSON syntax is invalid. See root cause for more detail.")
};
