export const DEFAULT_TERMS_PER_MESSAGE = 64;

//Import Terms
export const MAX_CHARACTERS_PER_TERM = 255;
export const MAX_SEARCH_TERMS = 10000;

//Function Key Secret CRUD
export const MAX_KEY_NAME_LENGTH = 20;
export const MAX_NUMBER_OF_KEYS = 20;

//Quick Search
export const QUICK_SEARCH_TERMS_LIMIT = 20;
