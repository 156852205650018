import { EditableBooleanFieldDefinition } from "../../Fields/EditableFields/FieldDefinitions";
import { ConflictsAction } from "../../Permissions";
import { satisfies } from "../../UtilityTypes";
import { Messages } from "../Messages";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./InternalTypes/FirmSettingsPageDefinition";

// Leaving this here as a template, this variable needs to be added to the FeatureFlagSection fields array.
// const isPriorSearchesFeatureEnabled = satisfies<EditableBooleanFieldDefinition>()({
//     type: "boolean",
//     defaultValue: false,
//     name: "isPriorSearchesFeatureEnabled",
//     label: "Prior Searches"
// } as const);

const isTaggingFeatureEnabled = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "isTaggingFeatureEnabled",
    label: "Tagging"
} as const);

const FeatureFlagSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "feature-flags",
    displayName: Messages.FEATURE_FLAGS.getMessage(),
    fields: [isTaggingFeatureEnabled],
    type: "basic",
    internalUsersOnly: true
} as const);

export const FeatureFlagsDefinition = satisfies<FirmSettingsPageDefinition>()({
    name: "feature-flags",
    displayName: Messages.FEATURE_FLAGS.getMessage(),
    sections: [FeatureFlagSection],
    requiredPermission: ConflictsAction.ViewAndEditFirmOptions,
    internalUsersOnly: true,
    dataToLoad: ["FIRM_SETTINGS_PAGE_DATA"]
} as const);
