import AddIcon from "@mui/icons-material/Add";
import { AppBar, Tab, Tabs, Toolbar, styled } from "@mui/material";
import { Button } from "@aderant/aderant-react-components";
import { QuickSearchesIcon } from "components/Icons/QuickSearchesIcon";
import { UserOptionPanel } from "components/UserOptionPanel/UserOptionPanel";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { searchActions } from "state/actions";
import { getIsQuickSearchEnabled } from "state/selectors";
import { getIsCurrentSearchQuickSearch } from "state/selectors/searchSelectors";
import { conflictsPalette } from "styles/conflictsPalette";
import uuid from "uuid/v4";
import { SearchRequestsIcon } from "../../components/Icons/SearchRequestsIcon";
import { MenuNameMap, MenuNameMessages, PathMap, TabNameMap, TabNameMessages } from "../../utilities/routingPathMap";
import { HeaderMenu, MenuOptionType } from "../HeaderMenu/HeaderMenu";
import { AderantConflictsIcon } from "../Icons/AderantConflictsIcon";

const tabHoverColor = conflictsPalette.background.hover;
const ConflictsAppBar = styled(AppBar)(({ theme }) => ({
    maxHeight: 64,
    height: 64,
    backgroundColor: conflictsPalette.background.header,
    "& .MuiTabs-root": {
        height: "100%",
        "& .MuiTabs-flexContainer": {
            height: "100%"
        }
    },
    "& .MuiTabs-indicator": {
        backgroundColor: conflictsPalette.background.white,
        height: ".25rem"
    },
    "& .MuiTab-root": {
        minHeight: 0, //This overrides the min-height set by material of 72px
        height: "100%",
        fontWeight: "400",
        fontSize: "1rem",
        minWidth: 10,
        flexDirection: "row",
        color: conflictsPalette.text.white,
        margin: "0px 10px 0px 10px",
        padding: "15px 10px 0px 10px",
        transition: "border-bottom .3s cubic-bezier(.17,.79,.79,.39)",
        backfaceVisibility: "hidden",
        justifyContent: "center",
        "& > svg, & > img": {
            width: 24,
            paddingRight: 5,
            marginBottom: 0
        },
        "&:hover": {
            backgroundColor: conflictsPalette.background.header
        },
        "&.Mui-selected": {
            color: conflictsPalette.text.white,
            backgroundColor: conflictsPalette.background.header,
            "& > svg, & > img": {
                color: conflictsPalette.text.white
            }
        },
        "&:hover:not(&.Mui-selected)": {
            color: `${tabHoverColor}`,
            fontWeight: 500,
            boxShadow: `0px -.25rem 0px 0px ${tabHoverColor} inset`,
            "& svg g, & svg path": {
                fill: tabHoverColor
            }
        }
    }
}));

const classes = {
    appSpacer: "appSpacer"
};

const AppSpacer = styled("div")(({ theme }) => ({
    [`&.${classes.appSpacer}`]: {
        ...theme.mixins.toolbar,
        height: "1rem"
    }
}));

type TabOptionType = { key: TabNameMap; title: string; icon: JSX.Element; path: PathMap };
interface HeaderProps {
    onSignOut: () => void;
}

export const Header = (props: HeaderProps): JSX.Element => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [tabValue, setTabValue] = React.useState<number | boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const isQuickSearch = useSelector(getIsCurrentSearchQuickSearch);
    const isQuickSearchEnabled = useSelector(getIsQuickSearchEnabled);
    //Menu object
    const [menuOptions, setMenuOptions] = useState<MenuOptionType[]>([
        { key: MenuNameMap.searchRequest, title: MenuNameMessages[MenuNameMap.searchRequest].getMessage(), path: PathMap.searchRequest }
    ]);
    //Tab object
    const [tabOptions, setTabOptions] = useState<TabOptionType[]>([
        { key: TabNameMap.searchRequests, title: TabNameMessages[TabNameMap.searchRequests].getMessage(), icon: <SearchRequestsIcon />, path: PathMap.searchRequests }
    ]);

    useEffect(() => {
        if (isQuickSearchEnabled) {
            setMenuOptions([
                { key: MenuNameMap.searchRequest, title: MenuNameMessages[MenuNameMap.searchRequest].getMessage(), path: PathMap.searchRequest },
                { key: MenuNameMap.quickSearch, title: MenuNameMessages[MenuNameMap.quickSearch].getMessage(), path: PathMap.quickSearch }
            ]);
            setTabOptions([
                { key: TabNameMap.searchRequests, title: TabNameMessages[TabNameMap.searchRequests].getMessage(), icon: <SearchRequestsIcon />, path: PathMap.searchRequests },
                { key: TabNameMap.quickSearches, title: TabNameMessages[TabNameMap.quickSearches].getMessage(), icon: <QuickSearchesIcon />, path: PathMap.quickSearches }
            ]);
        } else {
            setMenuOptions([{ key: MenuNameMap.searchRequest, title: MenuNameMessages[MenuNameMap.searchRequest].getMessage(), path: PathMap.searchRequest }]);
            setTabOptions([{ key: TabNameMap.searchRequests, title: TabNameMessages[TabNameMap.searchRequests].getMessage(), icon: <SearchRequestsIcon />, path: PathMap.searchRequests }]);
        }
    }, [isQuickSearchEnabled]);

    //Set tab based on pathname
    useEffect(() => {
        if (history.location.pathname.includes(PathMap.results)) {
            if (isQuickSearch === true) {
                setTabValue(1);
            } else if (isQuickSearch === false) {
                setTabValue(0);
            }
            return;
        } else if (history.location.pathname.includes(PathMap.searchRequest)) {
            if (history.location.pathname.includes(PathMap.quickSearch)) {
                setTabValue(1);
            } else {
                setTabValue(0);
            }
        } else if (history.location.pathname.includes(PathMap.firmSettings)) {
            setTabValue(false);
            return;
        } else if (history.location.pathname === PathMap.home) {
            setTabValue(0);
            return;
        } else if (history.location.pathname === PathMap.searchRequests) {
            setTabValue(0);
            return;
        } else if (history.location.pathname === PathMap.quickSearches) {
            setTabValue(1);
            return;
        }
    }, [history.location.pathname, isQuickSearch, tabValue]);

    const handleTabChange = (e: React.SyntheticEvent, value: number) => {
        setTabValue(value);
    };
    const handleClick = () => {
        setOpen(true);
    };
    const handleMenuClose = () => {
        setOpen(false);
    };
    const handleMenuClick = (optionKey: string) => {
        dispatch(searchActions.isNewSearch(true));
        switch (optionKey) {
            case MenuNameMap.searchRequest:
                history.push(`/search/${uuid()}`);
                break;
            case MenuNameMap.quickSearch:
                history.push(`/search/${uuid()}/quick-search`);
                break;
            default:
                history.push(`/search/${uuid()}`);
        }
        handleMenuClose();
    };

    return (
        <div>
            <ConflictsAppBar position="fixed">
                <Toolbar style={{ height: "100%" }} disableGutters>
                    <div style={{ width: "4em", margin: "1em", marginTop: "1.5em", marginRight: "1.5em" }}>
                        <AderantConflictsIcon />
                    </div>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        {tabOptions.map((option: TabOptionType) => (
                            <Tab key={option.key} disableRipple icon={option.icon} label={option.title} component={Link} to={option.path} />
                        ))}
                    </Tabs>
                    <div style={{ marginLeft: "auto", display: "flex" }}>
                        <Button
                            iconButton={true}
                            startIcon={<AddIcon />}
                            aria-label="New Search"
                            aria-owns={anchorRef ? "new-options" : undefined}
                            aria-haspopup={"true"}
                            color="secondary"
                            rounded={true}
                            size="medium"
                            style={{ marginRight: 7 }}
                            muiButtonRef={anchorRef}
                            onClick={handleClick}
                            showActiveState={open ? true : false}
                        />
                        <HeaderMenu open={open} anchorRef={anchorRef} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} menuOptions={menuOptions} />
                        <UserOptionPanel onSignOut={props.onSignOut} style={{ marginRight: 25, display: "flex" }} />
                    </div>
                </Toolbar>
            </ConflictsAppBar>
            <AppSpacer className={classes.appSpacer} />
        </div>
    );
};
