import { RefinerGroup, setupDefaultRefinerGroup, createRefinerOptions, RefinerGroupType, getListRefinerGroups } from "@aderant/aderant-react-components";
import { HitResult } from "./HitResult";
import { HitMessages, SearchMessages } from "aderant-conflicts-models";
import { Messages } from "components/DataGridWithRefiner/Messages";

const refinerOptionsWithSecondaryText = {
    unresolvedHitStatuses: {
        [HitMessages.HIT_STATUS_UNACTIONED.getMessage()]: Messages.SECONDARY_TEXT_HIT_UNRESOLVED.getMessage(),
        [HitMessages.HIT_STATUS_INPROGRESS.getMessage()]: Messages.SECONDARY_TEXT_HIT_UNRESOLVED.getMessage(),
        [HitMessages.HIT_STATUS_WAIVERREQUIRED.getMessage()]: Messages.SECONDARY_TEXT_HIT_UNRESOLVED.getMessage(),
        [HitMessages.HIT_STATUS_CONFIRM.getMessage()]: Messages.SECONDARY_TEXT_HIT_UNRESOLVED.getMessage()
    }
};

const ResultRefinerMapping = (results: HitResult[], displayPreviousHitStatusRefiner: boolean, displayHitStatusRefiner: boolean, displayHitOwnerRefiner: boolean): RefinerGroup[] => {
    //TODO create refiner groups based off grid/refiner firm mappings.
    //ToDo implement and enable DateRangeRefiner : Task#1254

    const refinerGroups: RefinerGroup[] = [
        setupDefaultRefinerGroup({ name: "Request Term", path: "requestTerm", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Hit Type", path: "sourceType", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Name", path: "name", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({
            name: "Hit Status",
            path: "hitStatusDisplayValue",
            refinerGroupType: RefinerGroupType.ListRefiner,
            secondaryTextMapping: refinerOptionsWithSecondaryText.unresolvedHitStatuses
        }),
        setupDefaultRefinerGroup({ name: "Conflicting Party (!)", path: "conflictingParty", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Secured Hit", path: "isRedacted", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Status", path: "status", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Affiliation", path: "sourceAffiliation", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Party Status", path: "partyStatus", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Employee", path: "responsibleEmployee", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Hit Owner", path: "hitOwnerDisplayValue", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Start Date", path: "startDate", refinerGroupType: RefinerGroupType.DateRange }),
        setupDefaultRefinerGroup({ name: "End Date", path: "endDate", refinerGroupType: RefinerGroupType.DateRange })
    ];

    if (!displayHitStatusRefiner) {
        refinerGroups.splice(
            refinerGroups.findIndex((group) => group.name === SearchMessages.HIT_STATUS.getMessage()),
            1
        );
    } else {
        if (displayPreviousHitStatusRefiner) {
            const previousHitStatusRefinerGroup = setupDefaultRefinerGroup({
                name: "Previous Hit Status",
                path: "previousVersionStatusDisplayValue",
                refinerGroupType: RefinerGroupType.ListRefiner,
                secondaryTextMapping: refinerOptionsWithSecondaryText.unresolvedHitStatuses
            });
            refinerGroups.splice(refinerGroups.findIndex((group) => group.name === SearchMessages.HIT_STATUS.getMessage()) + 1, 0, previousHitStatusRefinerGroup);
        }
    }

    if (!displayHitOwnerRefiner) {
        refinerGroups.splice(
            refinerGroups.findIndex((group) => group.name === SearchMessages.HIT_OWNER.getMessage()),
            1
        );
    }

    createRefinerOptions(getListRefinerGroups(refinerGroups), results);
    return refinerGroups;
};

export default ResultRefinerMapping;
