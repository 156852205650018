import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider as ReduxProvider } from "react-redux";
import store from "./state/store/store";
import { CssBaseline, ThemeProvider, StyledEngineProvider } from "@mui/material";
import "./styles.css";
import { AderantTheme } from "@aderant/aderant-react-components";
import { MsalProvider } from "@azure/msal-react";
import { msalApp } from "AuthUtils";

class Index extends React.Component {
    render() {
        return (
            <MsalProvider instance={msalApp}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={AderantTheme}>
                        <CssBaseline />
                        <Router>
                            <ReduxProvider store={store}>
                                <App />
                            </ReduxProvider>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
            </MsalProvider>
        );
    }
}

serviceWorker.unregister();
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<Index />);
