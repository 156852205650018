import { User } from "aderant-conflicts-models";
import { PickList, PickListProps, SingleSelectValue } from "@aderant/aderant-react-components";
import { OmitForUnion } from "aderant-web-fw-core";
import { RootState } from "MyTypes";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getAllUsers, getUser } from "state/selectors/appSelectors";

export type UserPicklistProps = OmitForUnion<PickListProps, "onChange" | "value" | "multiSelect" | "selectOptions"> & {
    onUserChanged: (user?: User) => void;
    selectedUserId?: string;
};

UserPicklist.defaultProps = {
    disabled: false,
    required: false,
    labelProps: {
        width: "180px",
        position: "left"
    },
    hasError: false
};

export const unassignedOption: SingleSelectValue = { label: "(Unassigned)", value: null };

export function UserPicklist(props: UserPicklistProps) {
    const { onUserChanged, selectedUserId } = props;
    const users = useSelector(getAllUsers, shallowEqual);

    const selectedUser = useSelector((rootState: RootState) => {
        if (selectedUserId) {
            return getUser(rootState, selectedUserId);
        }
    });

    const selectOptions: SingleSelectValue[] = getUserSelectOptions(users);

    const onChange = (event: React.ChangeEvent<HTMLElement>, value?: SingleSelectValue | null, reason?: string): void => {
        const userId = value?.value ?? value;
        if (!userId || !value || !users) {
            onUserChanged(undefined);
            return;
        }
        onUserChanged(users.find((u) => u.id == userId));
    };

    return <PickList {...props} onChange={onChange} selectOptions={selectOptions} value={selectedUser ? { label: selectedUser.name, value: selectedUser.id } : null} />;
}

export function getUserSelectOptions(users?: User[], currentUser?: User): SingleSelectValue[] {
    let selectOptions: SingleSelectValue[] = [unassignedOption];
    selectOptions = selectOptions.concat(
        users
            ? users.map((u, i, array) => {
                  //Duplicate check.  Duplicate labels cause bugs with autoselect, this de-duplicates them by including email if necessary
                  if (isDuplicateUserName(u, array)) {
                      return { label: `${u.name} (${u.email})`, value: u.id };
                  }
                  return { label: u.name, value: u.id };
              })
            : currentUser
            ? [{ label: currentUser.name, value: currentUser.id }]
            : []
    );

    //Secondary check for duplicates.  If two user entries share name and email then we de-duplicate here too, but more roughly.  This should never happen in prod but has in QA and Dev
    //Won't append anything to the first duplicate found, this is fine
    const duplicateSets: Record<string, SingleSelectValue[]> = {};
    selectOptions.forEach((optionToCheck, index, otherOptions) => {
        for (let i = 0; i < index; i++) {
            const otherOption = otherOptions[i];
            if (otherOption.label == optionToCheck.label) {
                if (duplicateSets[optionToCheck.label]) {
                    duplicateSets[optionToCheck.label].push(optionToCheck);
                } else {
                    duplicateSets[optionToCheck.label] = [optionToCheck];
                }
                break;
            }
        }
    });
    Object.keys(duplicateSets).forEach((key) => {
        const duplicateSet = duplicateSets[key];
        for (let i = 0; i < duplicateSet.length; i++) {
            duplicateSet[i].label = `${duplicateSet[i].label} (${i + 1})`;
        }
    });

    return selectOptions;
}

function isDuplicateUserName(userToCheck: User, users: User[]) {
    return users.some((u) => u.name === userToCheck.name && u.id !== userToCheck.id);
}
