import { assertUnreachable } from "aderant-web-fw-core";
import { AggregateFieldDefinition, aggregateValuesByType, convertFieldDefinitions, Entity, evaluateConditionType, Field, hasValue, mapFieldDefinitionToField, RequestTerm } from ".";
import { EntityFlyoutConfigurationTemplate } from "./DefaultEntityConfigurations/EntityConfigurationTemplate";
import _ = require("lodash");

export interface EntityDocument {
    id: string;
    entityType: string;
    entityRelationships?: Record<string, any>;
    secureEntityId?: string; //TODO: if we're making this required, we need to make sure we handle existing ingested data somehow because it won't have this.
    searchRequestTerms?: RequestTerm[];
    [key: string]: any;
    /**
     * This property tells us when the cosmosDB document was created by deltaService
     */
    deltaServiceTimeStamp?: number;
    /**
     * This property tells us when the cosmosDB document for this entity was last modified (derived from the cosmos system property _ts)
     */
    lastModified?: Date;
}

/**
 * Creates an entity object with its parent and children aggregated as specified in the flyout template provided.
 * @param entityFlyoutConfigurationTemplate flyout configuration template that defines how fields will be generated
 * @param document the document the entity will be based on
 * @param parentEntity parent of the main entity to be generated
 * @param childDocuments child documents of the entity
 * @returns
 */
export async function convertDocumentToEntity(
    entityFlyoutConfigurationTemplate: EntityFlyoutConfigurationTemplate,
    document: EntityDocument,
    parentEntity: Entity | undefined,
    childDocuments: { [key: string]: EntityDocument[] }
): Promise<Entity> {
    const entity: Entity = document.secureEntityId
        ? {
              id: document.id,
              name: entityFlyoutConfigurationTemplate.entityDisplayName,
              parent: parentEntity,
              secureEntityId: document.secureEntityId,
              searchRequestTerms: document.searchRequestTerms,
              fields: convertFieldDefinitions(document, entityFlyoutConfigurationTemplate.fields).filter((field) => hasValue(field))
          }
        : {
              id: document.id,
              name: entityFlyoutConfigurationTemplate.entityDisplayName,
              parent: parentEntity,
              searchRequestTerms: document.searchRequestTerms,
              fields: convertFieldDefinitions(document, entityFlyoutConfigurationTemplate.fields).filter((field) => hasValue(field))
          };

    //Aggregate childDocuments depending on entityFlyoutConfigurationTemplate.children.field.aggregateType
    if (childDocuments) {
        entityFlyoutConfigurationTemplate.children?.forEach((childTemplate) => {
            const childDocsByEntity = childDocuments[childTemplate.entityName];
            //Append aggregated child fields onto entity.fields
            entity.fields = entity.fields.concat(aggregateChildren(childDocsByEntity ?? [], childTemplate.fields).filter((field) => hasValue(field)));
        });
    }
    return entity;
}

/**
 * Aggregates documents based on the aggregate field definitions
 * @param documents
 * @param aggregateFields
 */
function aggregateChildren(documents: EntityDocument[], aggregateFields: AggregateFieldDefinition[]): Field[] {
    return aggregateFields.map((aggregateField: AggregateFieldDefinition) => {
        switch (aggregateField.displayFormat) {
            case "Currency": {
                //Retrieve values relevant to the aggregate field
                let values = documents.map((doc) => mapFieldDefinitionToField(aggregateField, doc).value);
                //Filter out values that do not match the specified condition
                const conditionType = aggregateField.conditionType;
                if (conditionType) {
                    values = values.filter((val) => evaluateConditionType<typeof val>(val, conditionType, aggregateField.conditionValue));
                }
                const distinctSymbols = _.uniq(values.map((d) => d.symbol));
                if (distinctSymbols.length > 1) {
                    return {
                        displayFormat: "String",
                        fieldLabel: aggregateField.propertyLabel,
                        value: "Mixed currencies"
                    };
                } else {
                    const distinctDecimalPlaces = _.uniq(values.map((d) => d.decimalPlaces));
                    return {
                        displayFormat: aggregateField.displayFormat,
                        fieldLabel: aggregateField.propertyLabel,
                        value: {
                            amount: aggregateValuesByType(
                                values.map((v) => v.amount),
                                aggregateField.aggregateType
                            ),
                            symbol: distinctSymbols[0] ?? "",
                            decimalPlaces: _.max(distinctDecimalPlaces) ?? 2 //Default to 2 decimal places
                        }
                    };
                }
            }
            case "Custom":
            case "Boolean":
            case "Date":
            case "DateTime":
            case "Highlight":
            case "Number":
            case "String":
            case "User":
            case "UserWithAvatar": {
                let values = documents.map((doc) => mapFieldDefinitionToField(aggregateField, doc).value);
                const conditionType = aggregateField.conditionType;
                if (conditionType) {
                    values = values.filter((val) => evaluateConditionType<typeof val>(val, conditionType, aggregateField.conditionValue));
                }
                //Compute aggregated value based on aggregate type
                const aggregatedValue = aggregateValuesByType(values, aggregateField.aggregateType);
                return {
                    displayFormat: aggregateField.displayFormat,
                    fieldLabel: aggregateField.propertyLabel,
                    value: aggregatedValue
                };
            }
            default:
                assertUnreachable(aggregateField);
        }
    });
}
