import { AllLookups, LookupType } from "aderant-conflicts-models";
import { EntityConnector } from "../APIs/EntityStore/EntityConnector/EntityConnector";
import { LookupConnector } from "../APIs/EntityStore/EntityConnector/LookupConnector";
/* eslint-disable @typescript-eslint/consistent-type-assertions */
export function getMockEntityConnector(): EntityConnector {
    return {
        getEntityDocument: jest.fn(),
        getEntityDocumentsByCustomProperty: jest.fn(),
        getEntityDocuments: jest.fn()
    };
}

export function getMockLookupConnector(): LookupConnector {
    return {
        getLookup: jest.fn(async (lookupType: LookupType) => {
            if (lookupType === "Affiliation") {
                return [
                    { id: "A", description: "Adverse" },
                    { id: "C", description: "Client" },
                    { id: "N", description: "Neutral" },
                    { id: "U", description: "Unknown" },
                    { id: "O", description: "Opposing" }
                ];
            }

            if (lookupType === "PartyStatus") {
                return [
                    { id: "AFFL", description: "Affiliate" },
                    { id: "CODEF", description: "Co-Defendant" },
                    { id: "COMP", description: "Complainant" },
                    { id: "DEF", description: "Defendant" },
                    { id: "DR", description: "Doctor" }
                ];
            }
            return [];
        })
    };
}

export const testLookups: AllLookups = {
    affiliationList: [
        { label: "Adverse", value: "A" },
        { label: "Client", value: "C" },
        { label: "Neutral", value: "N" },
        { label: "Unknown", value: "U" },
        { label: "Opposing", value: "O" }
    ],
    partyStatusList: [
        { label: "Affiliate", value: "AFFL" },
        { label: "Co-Defendant", value: "CODEF" },
        { label: "Complainant", value: "COMP" },
        { label: "Defendant", value: "DEF" },
        { label: "Doctor", value: "DR" }
    ]
};
