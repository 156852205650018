import { EnvironmentService } from "aderant-conflicts-common";
import { LoggedInUser, PermissionsContextDirect } from "aderant-conflicts-models";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { appActions } from "state/actions/AppActions";
import { initSagas } from "../initSagas";
import rootReducer from "../reducers";
import { ConflictsSagaContext } from "./ConflictsSagaContext";

const sagaMiddleware = createSagaMiddleware();

// We only want this in development mode, an import above will bring it in all the time.
/* eslint-disable @typescript-eslint/no-var-requires */
const middleware = [...(EnvironmentService.isDevelopmentEnvironment() ? [require("redux-immutable-state-invariant").default()] : []), sagaMiddleware];
/* eslint-enable @typescript-eslint/no-var-requires */

// This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(...middleware)));
initSagas(sagaMiddleware);

export const initializeAppContext = (context: ConflictsSagaContext, loggedInUser: LoggedInUser, permissionsContext: PermissionsContextDirect): void => {
    sagaMiddleware.setContext({ conflictsSagaContext: context });
    store.dispatch(appActions.initializeContext({ conflictsSagaContext: context, loggedInUser: loggedInUser, permissionsContext: permissionsContext }));
};

export default store;
