import { RefinerGroup, RefinerOption, updateRefinerCounts, RefinerGroupType, DateRange, createRefinerOptions, ListRefinerGroup } from "@aderant/aderant-react-components";
import * as MyTypes from "MyTypes";
import { refinerActionTypes } from "state/actions/RefinerActions";
import { HitResult } from "../../pages/ResultsPage/HitResult";
import { SearchRequestRow } from "../../pages/SearchesPage/SearchRequestRow";
import { PageRefinerPayload, RefinedResults, ViewByRefiner, VIEW_BY_FILTERS } from "./ReducerStateTypes";

export interface RefinerState {
    orderedRefinerPaths: string[];
    refiners: RefinerGroup[];
    errors: string[];
    refinedResults: {
        searchesPage: SearchRequestRow[];
        resultsPage: HitResult[];
    };
    viewByRefiners: ViewByRefiner[];
}

interface PageRefiner{
    searchesPage: SearchRequestRow[]; 
    resultsPage: HitResult[];
}

export const initialState: RefinerState = {
    orderedRefinerPaths: [],
    refiners: [],
    errors: [],
    refinedResults: {
        searchesPage: [],
        resultsPage: []
    },
    viewByRefiners: VIEW_BY_FILTERS.map((obj) => {
        return { name: obj.name, path: obj.path, ids: [] };
    })
};

const refinedResultsUpdate = (state: RefinerState, payload: PageRefinerPayload, refinersCopy: RefinerGroup[], path?: string): PageRefiner => {
    
    let changedRefinerGroup: RefinerGroup | undefined;
    //If there is a path then that refiner group should be specifically selected to allow it to be sorted to the top (BUG)
    if(path) {
        changedRefinerGroup = refinersCopy.find((r) => r.path == path);
    } else {
        //If there are any refiners then update the counts and refinedresults using the first refiner group to make sorting irrelevant
        if(refinersCopy.length > 0) {
            changedRefinerGroup = refinersCopy[0];
        }
    }

    let refinedResults: { [key: string]: any }[] = [];    
    if (changedRefinerGroup) {
        refinedResults = updateRefinerCounts(payload.results, refinersCopy, changedRefinerGroup);
    }  else {
        //If there are no refiners then pass through the results which should be empty.
        refinedResults = payload.results;
    }

    switch (payload.refinedResultKey) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        case RefinedResults.RESULTS_PAGE: return { ...state.refinedResults, resultsPage: refinedResults as HitResult[]};
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        case RefinedResults.SEARCHES_PAGE: return { ...state.refinedResults, searchesPage: refinedResults as SearchRequestRow[]};
        default: return {...state.refinedResults}
    }
}

export const refinerReducer = (state: RefinerState = initialState, action: MyTypes.RootRefinerAction): RefinerState => {
    switch (action.type) {
        case refinerActionTypes.SET_REFINER_GROUPS: {
            return {
                ...state,
                refiners: action.payload,
                orderedRefinerPaths: action.payload.map((r) => r.path)
            };

        }
        case refinerActionTypes.CLEAR_SELECTED_REFINERS: {
            const refinersCopy: RefinerGroup[] = state.refiners.map((r) => {
                switch (r.refinerGroupType) {
                    case RefinerGroupType.ListRefiner:
                        return Object.assign({}, r, {
                            selectedSequence: -1,
                            refinerOptions: r.refinerOptions.map((ro) => {
                                return Object.assign({}, ro, { isSelected: false });
                            })
                        });
                    case RefinerGroupType.DateRange:
                        return Object.assign({}, r, {
                            selectedSequence: -1,
                            dateRange: Object.assign({}, r.dateRange, {
                                fromDate: null,
                                toDate: null
                            })
                        });
                }
            });
            return {
                ...state,
                refiners: refinersCopy,
                refinedResults: refinedResultsUpdate(state, action.payload, refinersCopy)
            };
        }
        case refinerActionTypes.UPDATE_REFINER_SELECTION: {
            const { refinerOption, path, isSelected } = action.payload;
            const refinersCopy: RefinerGroup[] = state.refiners.map((r) => {
                switch (r.refinerGroupType) {
                    case RefinerGroupType.ListRefiner:
                        return Object.assign({}, r, {
                            refinerOptions: r.refinerOptions.map((ro) => {
                                const optionCopy: RefinerOption = Object.assign({}, ro);
                                // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
                                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                if (r.path == path && optionCopy.value == (refinerOption as RefinerOption).value) {
                                    optionCopy.isSelected = isSelected;
                                }
                                return optionCopy;
                            })
                        });
                    case RefinerGroupType.DateRange:
                        if (r.path === path) {
                            return Object.assign({}, r, {
                                dateRange: Object.assign({}, r.dateRange, {
                                    // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
                                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                    fromDate: isSelected ? (refinerOption as DateRange).fromDate : null,
                                    // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
                                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                    toDate: isSelected ? (refinerOption as DateRange).toDate : null
                                })
                            });
                        }
                        return Object.assign({}, r, {
                            dateRange: Object.assign({}, r.dateRange, {
                                fromDate: r.dateRange?.fromDate,
                                toDate: r.dateRange?.toDate
                            })
                        });
                }
            });

            return {
                ...state,
                refiners: refinersCopy,
                refinedResults: refinedResultsUpdate(state, action.payload.pageRefiner, refinersCopy, path)
            };
        }

        case refinerActionTypes.REFRESH_REFINER_SELECTION: { 
            const listRefiners: ListRefinerGroup[] = [];
            const refinersCopy: RefinerGroup[] = state.refiners.map((r) => {
                switch (r.refinerGroupType) {
                    case RefinerGroupType.ListRefiner:
                        const listRefinerGroupCopy: ListRefinerGroup = Object.assign({}, r, {
                            refinerOptions: r.refinerOptions.map((ro) => {
                                const optionCopy: RefinerOption = Object.assign({}, ro);
                                return optionCopy;
                            })
                        });
                        listRefiners.push(listRefinerGroupCopy);
                        return listRefinerGroupCopy;
                    case RefinerGroupType.DateRange:
                        return Object.assign({}, r, {
                            dateRange: Object.assign({}, r.dateRange, {
                                fromDate: r.dateRange?.fromDate,
                                toDate: r.dateRange?.toDate
                            })
                        });
                }
            });
            //Create the new refineroptions
            createRefinerOptions(listRefiners, action.payload.results);

            return {
                ...state,
                refiners: refinersCopy,
                refinedResults: refinedResultsUpdate(state, action.payload, refinersCopy)
            };
        }

        case refinerActionTypes.CLEAR_REFINED_RESULTS: {
            return {
                ...state,
                refinedResults: { ...state.refinedResults, searchesPage: [], resultsPage: [] }
            };
        }
        case refinerActionTypes.UPDATE_VIEW_BY_REFINERS: {
            return {
                ...state,
                viewByRefiners: state.viewByRefiners.map((view: ViewByRefiner) => {
                    if (view.name == action.payload.viewByRefiner.name) {
                        return {
                            ...view,
                            ids: action.payload.viewByRefiner.ids
                        };
                    } else {
                        return view;
                    }
                })
            };
        }
        default:
            return state;
    }
};
