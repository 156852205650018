import { Container, CosmosClient, JSONValue, SqlParameter } from "@azure/cosmos";
import { CosmosSecrets, EntityDocument, EntityIdentifier, LookupType, NotFound, notFound, RawLookup, Result, tooManyRequests, TooManyRequests, unexpectedError } from "aderant-conflicts-models";
import { Logger } from "aderant-web-fw-core";
import _ from "lodash";
import { CosmosConnector } from "../../../CosmosConnector/CosmosConnector";
import { MultiResponse } from "../../../Http/HttpResponse";
import { GetEntityDocumentsResults } from "../Surface";
import { EntityConnector } from "./EntityConnector";
import { LookupConnector } from "./LookupConnector";

export class CosmosEntityConnector extends CosmosConnector implements EntityConnector, LookupConnector {
    private static entityContainerId = "EntityLookup";
    private static entityContainerPartitionKey = "/entityType";
    private entityContainer: Container;

    private constructor(logger: Logger, entityContainer: Container) {
        super(logger);
        this.entityContainer = entityContainer;
    }

    static async openConnection(logger: Logger, firmSettings: CosmosSecrets, client?: CosmosClient, entityContainer?: Container): Promise<CosmosEntityConnector> {
        logger.info("Opening connector: Cosmos");
        try {
            const cosmosClient = client ?? this.createCosmosClient(logger, firmSettings);
            entityContainer = entityContainer ?? (await this.getContainer(cosmosClient, firmSettings, { id: this.entityContainerId, partitionKey: this.entityContainerPartitionKey }));
            return new CosmosEntityConnector(logger, entityContainer);
        } catch (e) {
            throw unexpectedError(e, "Unexpected error when opening Cosmos entity connector.");
        }
    }

    async getEntityDocument(id: string, entityType: string): Promise<Result<EntityDocument, NotFound | TooManyRequests>> {
        try {
            const item = this.entityContainer.item(id, entityType);
            const response = await item.read();

            if (response.statusCode === 200) {
                if (response.resource === undefined || response.resource === null) {
                    throw unexpectedError(response, "Cosmos container responded with 200, but did not supply an entity");
                }
                return excludeCosmosSysProps(response.resource);
            } else if (response.statusCode === 404) {
                return notFound();
            } else if (response.statusCode === 429) {
                return tooManyRequests();
            } else {
                throw unexpectedError(response, "Unexpected error retrieving entity details from Cosmos DB.");
            }
        } catch (e) {
            throw unexpectedError(e, "Unexpected error encountered in Cosmos Entity Connector.");
        }
    }

    private async queryEntitiesByType<T>(ids: string[], entityType: string) {
        try {
            const results: T[] = [];
            const query = this.entityContainer.items.query<T>(
                {
                    query: `SELECT * FROM items s where ARRAY_CONTAINS(@ids, s.id)`,
                    parameters: [{ name: "@ids", value: ids }]
                },
                { partitionKey: entityType }
            );

            while (query.hasMoreResults()) {
                const continuationResults = await query.fetchNext();
                results.push(...continuationResults.resources);
            }

            return results;
        } catch (e) {
            this.wrapWithUnexpectedErrorAndThrow(e);
        }
    }

    async getEntityDocuments(entityIdentifiers: EntityIdentifier[]): Promise<GetEntityDocumentsResults> {
        try {
            const entitiesByGroup = _.groupBy(entityIdentifiers, (h) => h.entityType);
            const responses: MultiResponse.Item<EntityDocument, 200, NotFound>[] = [];
            const entityTypes = Object.keys(entitiesByGroup);

            for (const entityType of entityTypes) {
                const entityIds: string[] = entitiesByGroup[entityType].map((entId: EntityIdentifier) => entId.id);
                const cosmosResponse = await this.queryEntitiesByType<EntityDocument & { _ts: number }>(entityIds, entityType);
                const entityDocumentById = new Map<string, EntityDocument & { _ts: number }>(cosmosResponse.map((entityDoc) => [entityDoc.id, entityDoc]));

                const entityResults: MultiResponse.Item<EntityDocument, 200, NotFound>[] = entityIds.map((id: string) => {
                    const entity = entityDocumentById.has(id) ? entityDocumentById.get(id) : undefined;
                    if (entity) {
                        return {
                            id: id,
                            status: 200,
                            body: excludeCosmosSysProps(entity),
                            entityType: entityType
                        };
                    }
                    return {
                        id: id,
                        status: 404,
                        body: notFound(),
                        entityType: entityType
                    };
                });
                responses.push(...entityResults);
            }
            return responses;
        } catch (e) {
            this.wrapWithUnexpectedErrorAndThrow(e);
        }
    }

    isArray(propertyValues: JSONValue | JSONValue[]): propertyValues is JSONValue[] {
        return !!Array.isArray(propertyValues);
    }

    async getEntityDocumentsByCustomProperty(propertyName: string, propertyValues: JSONValue | JSONValue[], entityType: string): Promise<EntityDocument[]> {
        try {
            //This could potentially be large once we start persisting larger documents and we may end up
            //reaching the 4MB limit per request, in which case, we'll need to either use fetchNext or paginate server side
            if (!propertyValues) {
                return [];
            }

            const queries: string[] = [];
            const parameters: SqlParameter[] = [];
            (this.isArray(propertyValues) ? propertyValues : [propertyValues]).forEach((p, index) => {
                queries.push(`s.${propertyName} = @propertyValue${index}`);
                parameters.push({ name: `@propertyValue${index}`, value: p });
            });

            const response = await this.entityContainer.items
                .query(
                    {
                        query: `SELECT * FROM items s where ${queries.join(" or ")}`,
                        parameters: parameters
                    },
                    { partitionKey: entityType }
                )
                .fetchAll();
            return response.resources.map((doc) => excludeCosmosSysProps(doc));
        } catch (e) {
            throw unexpectedError(e, "Unexpected error encountered in Cosmos Entity Connector.");
        }
    }

    async getLookup(lookupType: LookupType): Promise<RawLookup[]> {
        try {
            const response = await this.entityContainer.items
                .query(
                    {
                        query: `SELECT s.id, s.description FROM items s`
                    },
                    { partitionKey: `${lookupType.toLowerCase()}lookup` }
                )
                .fetchAll();
            return response.resources;
        } catch (e) {
            throw unexpectedError(e, "Unexpected error encountered in Cosmos Entity Connector.");
        }
    }
}

/**
 * Removes the cosmos system properties from the entity document, with the exception of the _ts (timestamp).
 */
function excludeCosmosSysProps(entityDocument: EntityDocument & { _ts: number }): EntityDocument {
    const { _rid, _self, _etag, _attachments, _ts, ...rest } = entityDocument;
    return { ...rest, lastModified: new Date(_ts * 1000) };
}
