import { EntityDocument, unexpectedError } from "aderant-conflicts-models";
import { IngestedEntityDocument } from "./Types";

type relatedEntities = {
    fieldName: string;
    entityType: string;
    distance: number;
}[];

/**
 * This defines the relationships between entity types and their related fields.
 * fieldName refers to the Id field of the related field
 * distance describes the number of parentlike relationships between the entity and the related field
 */
export const relatedEntitiesMap: Record<string, relatedEntities> = {
    client: [
        {
            fieldName: "assignedEmployeeId",
            entityType: "employee",
            distance: 1
        },
        {
            fieldName: "billingEmployeeId",
            entityType: "employee",
            distance: 1
        },
        {
            fieldName: "responsibleEmployeeId",
            entityType: "employee",
            distance: 1
        }
    ],
    clientAlias: [{ fieldName: "clientId", entityType: "client", distance: 1 }],
    clientContact: [{ fieldName: "clientId", entityType: "client", distance: 1 }],
    clientContactAlias: [
        {
            fieldName: "contactId",
            entityType: "clientContact",
            distance: 1
        },
        {
            fieldName: "clientId",
            entityType: "client",
            distance: 2
        }
    ],
    clientParty: [
        { fieldName: "clientId", entityType: "client", distance: 1 },
        {
            fieldName: "responsibleEmployeeId",
            entityType: "employee",
            distance: 1
        }
    ],
    clientPartyAlias: [
        {
            fieldName: "clientPartyId",
            entityType: "clientParty",
            distance: 1
        },
        {
            fieldName: "clientId",
            entityType: "client",
            distance: 2
        }
    ],
    employeeAlias: [{ fieldName: "employeeId", entityType: "employee", distance: 1 }],
    matter: [
        { fieldName: "clientId", entityType: "client", distance: 1 },
        {
            fieldName: "assignedEmployeeId",
            entityType: "employee",
            distance: 1
        },
        {
            fieldName: "billingEmployeeId",
            entityType: "employee",
            distance: 1
        },
        {
            fieldName: "responsibleEmployeeId",
            entityType: "employee",
            distance: 1
        }
    ],
    matterContact: [
        { fieldName: "matterId", entityType: "matter", distance: 1 },
        {
            fieldName: "clientContactId",
            entityType: "clientContact",
            distance: 1
        }
    ],
    matterParty: [
        { fieldName: "matterId", entityType: "matter", distance: 1 },
        { fieldName: "clientId", entityType: "client", distance: 1 },
        {
            fieldName: "responsibleEmployeeId",
            entityType: "employee",
            distance: 1
        }
    ],
    matterPartyAlias: [
        {
            fieldName: "matterPartyId",
            entityType: "matterParty",
            distance: 1
        },
        {
            fieldName: "matterId",
            entityType: "matter",
            distance: 2
        }
    ],
    vendorContact: [{ fieldName: "vendorId", entityType: "vendor", distance: 1 }],
    vendorContactAlias: [
        {
            fieldName: "contactId",
            entityType: "clientContact",
            distance: 1
        },
        {
            fieldName: "vendorId",
            entityType: "vendor",
            distance: 2
        }
    ]
};

export type EntityRelationship = {
    entityType: string;
    id: string;
    relationshipName: string;
    distance: number;
};

/**
 * This function checks that the relationshipFieldName passed in ends with Id
 * to ensure that the data stored in the entityRelationship for the id field is
 * in fact an id. This function returns the fieldName with the "Id" removed so that
 * it refers to the value of the data rather than the id.
 */
function getRelationshipName(relationshipFieldName: string) {
    if (relationshipFieldName.slice(-2) === "Id") {
        return relationshipFieldName.slice(0, -2);
    }
    throw unexpectedError(`relationshipFieldName ${relationshipFieldName} does not end with "Id"`, "");
}

export function createEntityRelationships(record: EntityDocument | IngestedEntityDocument): EntityRelationship[] | undefined {
    const relatedEntities = relatedEntitiesMap[record.entityType];
    if (!relatedEntities) {
        return undefined;
    }

    const relevantRelatedEntities = relatedEntities.filter((relationship) => {
        return !!record[relationship.fieldName];
    });
    const entityRelationships: EntityRelationship[] = relevantRelatedEntities.map((relationship) => {
        return {
            entityType: relationship.entityType,
            id: record[relationship.fieldName].toString(),
            relationshipName: getRelationshipName(relationship.fieldName),
            distance: relationship.distance
        };
    });

    if (entityRelationships.length === 0) {
        return undefined;
    }

    return entityRelationships;
}
