import _ from "lodash";
import * as MyTypes from "MyTypes";
import { userAlertActionTypes } from "state/actions/UserAlertActions";
import { UserAlert } from "state/models/UserAlert.model";
import uuid from "uuid";

export interface UserAlertState {
    userAlerts: UserAlert[];
}

export const initialState: UserAlertState = {
    userAlerts: []
};

export const userAlertReducer = (state: UserAlertState = initialState, action: MyTypes.RootUserAlertAction): UserAlertState => {
    switch (action.type) {
        case userAlertActionTypes.ADD_ALERT: {
            const alertToAdd: UserAlert = {
                id: uuid(),
                message: action.payload.message,
                isDisplayed: true,
                data: action.payload.data
            };

            return {
                userAlerts: _.concat(state.userAlerts, alertToAdd)
            };
        }
        case userAlertActionTypes.HIDE_ALERT: {
            const userAlerts = _.cloneDeep(state.userAlerts);
            const alertToUpdate = _.find(userAlerts, (a) => a.id === action.payload);
            if (alertToUpdate) {
                alertToUpdate.isDisplayed = false;
            }
            return {
                userAlerts: userAlerts
            };
        }
        default:
            return state;
    }
};
