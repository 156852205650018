import { QueueClient, QueueSendMessageOptions, QueueSendMessageResponse } from "@azure/storage-queue";
import { EmailWithContext } from "aderant-conflicts-models";
import { AzureQueueFunctionDefinition } from "../AzureFunctionDefinition/AzureQueueFunctionDefinition";
import { FunctionAppContext } from "../ConflictsContext";
import { EmailProvider } from "../Email/EmailProvider";
import { GlobalUserService } from "../GlobalUserService/GlobalUserService";

export class EmailServiceMock {
    public queueName = "email-queue-items";

    //This is the default sendMessage function. You can overwrite it by passing one in to the constructor
    private sendMessage = (message: string, options?: QueueSendMessageOptions | undefined): Promise<QueueSendMessageResponse> => {
        // Justification: we don't need the response for the test
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return Promise.resolve({} as QueueSendMessageResponse);
    };

    public sendMessageMock: (message: string, options?: QueueSendMessageOptions | undefined) => Promise<QueueSendMessageResponse>;
    public queueMock: Pick<QueueClient, "sendMessage">;

    public globalUserService: GlobalUserService;

    private getSendMessageMock = (sendMessage: (message: string, options?: QueueSendMessageOptions | undefined) => Promise<QueueSendMessageResponse>) => {
        return jest.fn(sendMessage);
    };

    public queueTriggerFunctions: {
        processEmailMessage: AzureQueueFunctionDefinition<EmailWithContext>;
    };

    constructor(globalUserService: GlobalUserService, sendMessage?: (message: string, options?: QueueSendMessageOptions | undefined) => Promise<QueueSendMessageResponse>) {
        this.globalUserService = globalUserService;
        if (sendMessage) {
            this.sendMessage = sendMessage;
        }
        this.sendMessageMock = this.getSendMessageMock(this.sendMessage);
        this.queueMock = {
            sendMessage: this.sendMessageMock
        };
        // Justification: we don't need a whole queueClient object - code only uses the sendMessage function
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const queueMock = this.queueMock as QueueClient;
        this.queueTriggerFunctions = {
            processEmailMessage: new AzureQueueFunctionDefinition<EmailWithContext>({ queueName: this.queueName, bindingVariableName: "emailQueueItem" }, queueMock)
        };
    }

    public async getEmailServiceProxy(context: FunctionAppContext) {
        const enqueueEmail = await this.queueTriggerFunctions.processEmailMessage.getAppEnqueueMessage(context);
        const emailProvider = new EmailProvider(context, enqueueEmail, this.globalUserService);
        return emailProvider;
    }
}
