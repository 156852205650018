import { CompositeApiKey, DecodedCompositeApiKey, Result, Unauthorized, unauthorized } from "aderant-conflicts-models";

export function decodeUserProvidedKey(base64EncodedCompositeKey: string): Result<DecodedCompositeApiKey, Unauthorized> {
    const base64DecodedString = Buffer.from(base64EncodedCompositeKey, "base64").toString();
    if (!isCompositeApiKey(base64DecodedString)) {
        return unauthorized();
    } else {
        const split = base64DecodedString.split(":");
        return {
            uniqueKeyName: split[0],
            unhashedApiKey: split[1],
            uniqueTenantName: split[2]
        };
    }
}

function isCompositeApiKey(value: string): value is CompositeApiKey {
    return value.split(":").length === 3;
}
