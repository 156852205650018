import { CheckCircleOutlined } from "@mui/icons-material";
import {
    ConflictsAction,
    Direct,
    getSearchStatus,
    getSearchStatusDisplayValue,
    getStatusesUsersCanManuallyChangeSearchToDirect,
    isSearchedStatus,
    PermissionsContextService,
    QuickSearch,
    SearchMassEditFields,
    SearchStatus,
    SearchStatuses,
    SearchVersion,
    sortSearchStatuses,
    User
} from "aderant-conflicts-models";
import {
    Button,
    DataGridColumnDefinition,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    GlobalHotkey,
    MessageDialog,
    S,
    SingleSelectValue,
    Tab,
    Tabs
} from "@aderant/aderant-react-components";
import { HitCountTiles } from "components/HitCountTiles/HitCountTiles";
import { isMessageInvalid } from "components/ReassignUserModal/ReassignUser";
import { getSearchProgress } from "Functions/search";
import { FeatureBySearchTypeHelper } from "pages/Shared/FeatureBySearchType";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchActions } from "state/actions";
import { getAllUsers, getCurrentSearchTerms, RequestTermSummary, usePermissionsContext } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import { Messages as SharedMessages } from "../Shared/Messages";
import { AuditGrid } from "./AuditGrid";
import { DetailsSelection } from "./DetailsSelection";
import { Messages } from "./Messages";
import { fetchTermsGridColumns, TermsGrid } from "./TermsGrid";
import { getUser as getSingleUser } from "pages/Shared/userUtils";
import { RootState } from "MyTypes";
import { TrimmedHeader } from "./TrimmedHeader";

export interface ResultDetailDialogProps {
    open: boolean;
    onClose: () => void;
    searchVersion: SearchVersion | QuickSearch;
    canChangeAssignedTo: boolean;
    currentUserId: string;
    focusedPropertyName?: string;
}

export interface DataChanged {
    searchStatus: SingleSelectValue | null;
    assignedToUserId?: User | null;
    reassignMessage?: string;
    statusNotificationMessage?: string;
}

const tabAccessibilityProps = (tab: string): Record<string, string> => {
    return {
        id: `search-details-tab-${tab.toLowerCase()}`,
        "aria-controls": `search-details-tabpanel-${tab.toLowerCase()}`,
        value: tab
    };
};

const tabPanelAccessibilityProps = (tab: string): Record<string, string> => {
    return {
        role: "tabpanel",
        id: `search-details-tabpanel-${tab.toLowerCase()}`,
        "aria-labelledby": `search-details-tab-${tab.toLowerCase()}`
    };
};

function getSearchStatusDropdownValues(
    permissions: Direct<PermissionsContextService<ConflictsAction>> | undefined,
    searchVersion: SearchVersion | QuickSearch,
    changedData: DataChanged
): SearchStatus[] {
    if (!permissions || searchVersion.isQuickSearch) {
        return [];
    }
    const statuses = getStatusesUsersCanManuallyChangeSearchToDirect(permissions, searchVersion, changedData.assignedToUserId?.id);
    if (changedData.searchStatus && searchVersion.status !== changedData.searchStatus?.value) {
        statuses.push(searchVersion.status);
    } else if (changedData.searchStatus) {
        statuses.push(getSearchStatus(changedData.searchStatus.value || ""));
    }
    sortSearchStatuses(statuses);
    return statuses;
}

export const ResultDetailDialog = ({ focusedPropertyName, open, onClose, searchVersion, canChangeAssignedTo }: ResultDetailDialogProps) => {
    const isDetailsTabVisible = FeatureBySearchTypeHelper.showDetailsTab(searchVersion);
    const isTermsTabVisible = FeatureBySearchTypeHelper.showTermsTab(searchVersion);
    const isAuditsTabVisible = FeatureBySearchTypeHelper.showAuditsTab(searchVersion);
    const defaultTab = isDetailsTabVisible ? "Details" : isTermsTabVisible ? "Terms" : isAuditsTabVisible ? "Audit" : "";
    const isOnlyOneTabVisible = (isDetailsTabVisible ? 1 : 0) + (isTermsTabVisible ? 1 : 0) + (isAuditsTabVisible ? 1 : 0) === 1;
    const [selectedTab, setSelectedTab] = useState(defaultTab);
    const [disabled, setDisabled] = useState(true);
    //Fetch all users
    const allUsers = useSelector(getAllUsers);
    //Initial data for picklists on load.
    const initialData: DataChanged = {
        assignedToUserId: searchVersion.assignedToUserId && allUsers ? getSingleUser(allUsers, searchVersion.assignedToUserId) : undefined,
        searchStatus: { label: getSearchStatusDisplayValue(searchVersion.status), value: searchVersion.status }
    };

    const [changedData, setChangedData] = useState<DataChanged>(initialData);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const requestTerms: RequestTermSummary[] = useSelector((rootState: RootState) => {
        return getCurrentSearchTerms(rootState);
    });
    //Get Lookup lists
    const affiliationList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.affiliationList;
    });
    const partyStatusList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.partyStatusList;
    });

    const termsGridColumns: DataGridColumnDefinition<RequestTermSummary>[] = useMemo(
        () => fetchTermsGridColumns(requestTerms, affiliationList, partyStatusList, searchVersion.isQuickSearch),
        [affiliationList, partyStatusList, searchVersion.isQuickSearch, requestTerms.length]
    );

    const dispatch = useDispatch();
    const permissions = usePermissionsContext();

    const isUserChanged = (user?: User | null) => (user && searchVersion.assignedToUserId === user.id ? false : !user && !searchVersion.assignedToUserId ? false : true);
    const isStatusChanged = (status?: SingleSelectValue | null) => (status && searchVersion.status === status.value ? false : true);
    // Request Status picklist options and picklist state based on options.
    const statusOptions = getSearchStatusDropdownValues(permissions, searchVersion, changedData);
    const isStatusChangeAllowed = statusOptions.length > 1 || searchVersion.status !== statusOptions[0];

    useEffect(() => {
        //If the searchversion status changes with a side effect then immediately update the changed data status.
        setChangedData({ ...changedData, searchStatus: { label: getSearchStatusDisplayValue(searchVersion.status), value: searchVersion.status } });
    }, [searchVersion]);

    useEffect(() => {
        //This allows the assignedTo user to be set correctly when allUsers populates.
        if (allUsers) {
            const dataWithAssignedToUser: DataChanged = {
                ...changedData,
                assignedToUserId: searchVersion.assignedToUserId ? getSingleUser(allUsers, searchVersion.assignedToUserId) : undefined
            };
            setChangedData(dataWithAssignedToUser);
        }
    }, [allUsers]);

    function isStatusNotificationMessageVisible(): boolean {
        if (changedData.searchStatus?.value) {
            const changedStatus = changedData.searchStatus.value;
            const initialStatus = initialData.searchStatus?.value;
            return (
                // isSearchStatus includes SEARCHED which we do not need a message for, but a search can not be manually changed to SEARCHED so should not be an issue
                // For whatever reason we're using SingleSelectValues in the changed data.  The value will always be a search status
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                changedStatus !== initialStatus && isSearchedStatus(changedStatus as SearchStatus)
            );
        } else {
            return false;
        }
    }

    const isDetailsFormValid = (): boolean => {
        if (
            selectedTab === "Details" &&
            ((isUserChanged(changedData.assignedToUserId) && isMessageInvalid(changedData.reassignMessage)) ||
                (isStatusNotificationMessageVisible() && isMessageInvalid(changedData.statusNotificationMessage)))
        ) {
            return false;
        }
        return true;
    };

    const handleTabChange = (newValue: string): void => {
        setSelectedTab(newValue);
    };

    //Callback for handling dirty status (on picklist value changed)
    const handleDirty = (value: DataChanged) => {
        const wasDisabled = disabled;
        setChangedData(value);
        const isNowDisabled = !isUserChanged(value.assignedToUserId) && !isStatusChanged(value.searchStatus);
        setDisabled(isNowDisabled);
        if (wasDisabled && !isNowDisabled) {
            //Clear both messages when showing one
            setChangedData({ ...value, reassignMessage: "", statusNotificationMessage: "" });
        }
    };
    //dispatch actions only when the user clicks save button or hits Ctrl+S. Hotkey should only dispatch when Save button is enabled.
    const handleSave = (): void => {
        setSelectedTab(defaultTab);
        if (!changedData || !changedData.searchStatus || disabled) {
            return;
        }
        let changeData: SearchMassEditFields = { assignedToUserId: undefined, status: undefined };
        //Both user and status changed
        if (isUserChanged(changedData.assignedToUserId) && isStatusChanged(changedData.searchStatus)) {
            changeData = {
                assignedToUserId: changedData.assignedToUserId ? changedData.assignedToUserId.id : null,
                // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                status: changedData.searchStatus?.value as SearchStatus,
                reassignMessage: changedData.reassignMessage,
                statusNotificationMessage: changedData.statusNotificationMessage
            };
        }
        //User changed, status unchanged
        if (isUserChanged(changedData.assignedToUserId) && !isStatusChanged(changedData.searchStatus)) {
            changeData = {
                assignedToUserId: changedData.assignedToUserId?.id ?? null,
                reassignMessage: changedData.reassignMessage
            };
        }
        //User unchanged and status changed
        if (isStatusChanged(changedData.searchStatus) && !isUserChanged(changedData.assignedToUserId)) {
            changeData = {
                // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                status: changedData.searchStatus?.value as SearchStatus,
                statusNotificationMessage: changedData.statusNotificationMessage
            };
        }
        dispatch(
            searchActions.updateSearch({
                searchVersionIdentifier: { searchId: searchVersion.searchId, versionId: searchVersion.id },
                change: changeData,
                _etag: searchVersion._etag
            })
        );
        setDisabled(true);
    };
    const cancelAndClose = (): void => {
        setIsConfirmationOpen(false);
        setChangedData(initialData);
        setDisabled(true);
        onClose();
    };

    const handleClose = (): void => {
        if (isUserChanged(changedData.assignedToUserId) || isStatusChanged(changedData.searchStatus)) {
            setIsConfirmationOpen(true);
            return;
        }
        cancelAndClose();
        setSelectedTab(defaultTab);
    };

    const headerText = () => {
        const searchProgress = getSearchProgress(searchVersion.summary?.hitCountByStatus);
        const headerMessage = `${Messages.PROFILE_DIALOG_HEADER.getMessage()} - ${searchVersion.name}`;
        if (searchProgress.progress === searchProgress.total && !searchVersion.isQuickSearch) {
            return (
                <>
                    <CheckCircleOutlined fontSize="large" style={{ color: conflictsPalette.status.green, marginRight: "10px" }} />
                    <DialogHeader>{headerMessage}</DialogHeader>
                </>
            );
        }
        return <DialogHeader>{headerMessage}</DialogHeader>;
    };

    const sendMessageOnSave =
        (changedData.assignedToUserId && isUserChanged(changedData.assignedToUserId)) ||
        // If the status is set to InReview then the EmailBuilder will not send a message.
        (isStatusNotificationMessageVisible() && changedData.searchStatus && changedData.searchStatus.value !== SearchStatuses.InReview);

    return (
        <>
            <Dialog open={open} onClose={handleClose} size="lg">
                <TrimmedHeader closeButton onClose={handleClose}>
                    <div style={{ display: "flex", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{headerText()}</div>
                </TrimmedHeader>
                <DialogContent>
                    <GlobalHotkey hotkey={S} control onDown={handleSave}>
                        <div style={{ height: "100%", display: "flex", flexDirection: "column", backgroundColor: conflictsPalette.background.white, border: `1px solid ${conflictsPalette.border}` }}>
                            <div style={{ borderBottom: `1px solid ${conflictsPalette.primary.Ahsoka}`, marginBottom: "5px" }}>
                                <Tabs value={selectedTab} onChange={(e, v) => handleTabChange(v)} centered aria-label={Messages.PROFILE_DIALOG_TABS_AL.getMessage()}>
                                    {isDetailsTabVisible && <Tab label={Messages.PROFILE_DIALOG_TAB_DETAILS.getMessage()} {...tabAccessibilityProps("Details")} disabled={isOnlyOneTabVisible} />}
                                    {isTermsTabVisible && <Tab label={Messages.PROFILE_DIALOG_TAB_TERMS.getMessage()} {...tabAccessibilityProps("Terms")} disabled={isOnlyOneTabVisible} />}
                                    {isAuditsTabVisible && <Tab label={Messages.PROFILE_DIALOG_TAB_AUDIT.getMessage()} {...tabAccessibilityProps("Audit")} disabled={isOnlyOneTabVisible} />}
                                </Tabs>
                            </div>
                            <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", minHeight: "500px" }}>
                                {selectedTab === "Details" && isDetailsTabVisible && (
                                    <>
                                        <div {...tabPanelAccessibilityProps("Details")}>
                                            <HitCountTiles searchVersion={searchVersion} />
                                            {/* Picklists for Assigned to user and request status, and an optional reassign message */}
                                            <DetailsSelection
                                                canChangeAssignedTo={canChangeAssignedTo}
                                                canChangeRequestStatus={isStatusChangeAllowed}
                                                searchVersion={searchVersion}
                                                onChange={handleDirty}
                                                searchStatusOptions={statusOptions.map((s) => {
                                                    return { label: getSearchStatusDisplayValue(s), value: s };
                                                })}
                                                newAssignedToUser={changedData.assignedToUserId}
                                                newSearchRequestStatus={changedData.searchStatus}
                                                focusedPropertyName={focusedPropertyName}
                                                reassignMessage={changedData.reassignMessage}
                                                statusNotificationMessage={changedData.statusNotificationMessage}
                                                isReassignMessageFieldVisible={isUserChanged(changedData.assignedToUserId)}
                                                isStatusNotificationMessageVisible={isStatusNotificationMessageVisible()}
                                            />
                                        </div>
                                    </>
                                )}
                                {selectedTab === "Terms" && isTermsTabVisible && (
                                    <div {...tabPanelAccessibilityProps("Terms")} style={{ height: "500px" }}>
                                        <TermsGrid columns={termsGridColumns} requestTerms={requestTerms} />
                                    </div>
                                )}
                                {selectedTab === "Audit" && isAuditsTabVisible && (
                                    <div {...tabPanelAccessibilityProps("Audit")} style={{ height: "500px" }}>
                                        <AuditGrid searchId={searchVersion.searchId} searchVersionNumber={searchVersion.number ?? ""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </GlobalHotkey>
                </DialogContent>
                <DialogFooter>
                    <Button text={Messages.PROFILE_DIALOG_CLOSE_BUTTON.getMessage()} onClick={handleClose} size="medium" />
                    {isDetailsTabVisible && (
                        <Button
                            disabled={disabled || !isDetailsFormValid()}
                            text={sendMessageOnSave ? Messages.PROFILE_DIALOG_SAVE_SEND_BUTTON.getMessage() : Messages.PROFILE_DIALOG_SAVE_BUTTON.getMessage()}
                            onClick={handleSave}
                            size="medium"
                        />
                    )}
                </DialogFooter>
            </Dialog>
            <MessageDialog
                open={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                title={SharedMessages.LEAVE_WITHOUT_SAVING_TITLE.getMessage()}
                message={SharedMessages.LEAVE_WITHOUT_SAVING.getMessage()}
                footer={
                    <>
                        <Button text="No" color="secondary" onClick={() => setIsConfirmationOpen(false)} size="medium" />
                        <Button text="Yes" onClick={cancelAndClose} size="medium" />
                    </>
                }
            />
        </>
    );
};
