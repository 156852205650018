import Excel from "exceljs";
import { saveAs } from "file-saver";
import { ExcelUtils, messages, sampleInstructions } from "./ExcelUtils";

export async function saveSearchTermsTemplate() {
    //create workbook with initial properties
    const workbook = new Excel.Workbook();
    workbook.creator = "Aderant";
    workbook.created = new Date();

    //create sheets
    const searchTermsSheet = workbook.addWorksheet(messages.searchTermsSheetName);
    const sampleSheet = workbook.addWorksheet(messages.sampleSheetName);

    //Freeze first row for both sheets
    searchTermsSheet.views = [{ state: "frozen", xSplit: 0, ySplit: 1 }];
    sampleSheet.views = [{ state: "frozen", xSplit: 0, ySplit: 1 }];

    //Add header row
    searchTermsSheet.columns = ExcelUtils.searchTermHeaders;
    sampleSheet.columns = ExcelUtils.searchTermHeaders;

    //Create rows
    const sampleSheetRows = [["Brittany Jones"], ["Britney Jones"], ["Brit Jones"], [], [], ...sampleInstructions(messages.searchTermsSheetName), ["There must be only one column of data"]];

    //Add rows
    sampleSheet.addRows(sampleSheetRows);

    //Make first rows bold and underline instructions title
    searchTermsSheet.getRow(1).font = { bold: true };
    sampleSheet.getRow(1).font = { bold: true };
    sampleSheet.getRow(7).font = { underline: true };

    //Create excel sheet
    //Note: We are unable to save directly to a file in a web-app client so are saving to a buffer first.
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = ExcelUtils.fileType;
    const fileExtension = ExcelUtils.fileExtension;
    const fileName = messages.searchTermsFileName;

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, fileName + fileExtension);
}
