import { ok, Result, NotFound, notFound, StoredHashedApiKeys, ManagedIdentityConfig } from "aderant-conflicts-models";
import { Logger } from "aderant-web-fw-core";
import { getFunctionKeyKeyVaultName } from "../../Config/FunctionAppEnvironmentService";

import azSecrets from "@aderant/azure-key-vault-secrets";

/**This is intentionally not exported in the index of the folder it lives in - nothing should need access to these raw values except for the functions in FunctionKeySecretCRUD.ts
 * and passing them around unnecessarily could be a security risk.
 */
export class FunctionKeySecretConnector {
    constructor(private logger: Logger, private azureSecrets: typeof azSecrets = azSecrets) {}

    /**
     * Gets key info for the provided tenant, if no key info exists for the provided tenant yet will return with an empty keys array.
     * @param tenantName The unique Tenant name to get keys for.
     * @param bustCache Whether or not to clear the cache before getting secrets.
     * @returns @type {StoredHashedApiKeys}
     */
    async getOrEmptyStoredFunctionKeyInfoForTenant(tenantName: string, bustCache?: boolean): Promise<StoredHashedApiKeys> {
        const keys = await this.getStoredFunctionKeyInfoForTenant(tenantName, bustCache);
        if (ok(keys)) {
            return keys;
        } else {
            return { keys: [] };
        }
    }

    /**
     * Gets key info for the provided tenant, if no key info exists for the provided tenant will return @type {NotFound}.
     * @param tenantName The unique Tenant name to get keys for.
     * @param bustCache Whether or not to clear the cache before getting secrets.
     * @returns @type {StoredHashedApiKeys | NotFound}
     */
    async getStoredFunctionKeyInfoForTenant(tenantName: string, bustCache?: boolean): Promise<Result<StoredHashedApiKeys, NotFound>> {
        this.logger.debug(`getTenantFunctionKeySecretValueAsJSON, tenancyName: ${tenantName}`);

        const secretName = tenantName;
        const keyVaultName = getFunctionKeyKeyVaultName();

        this.logger.debug(`getSecretValueAsJSON - keyVaultName: ${keyVaultName}, secretName: ${secretName}`);

        try {
            if (bustCache) {
                this.azureSecrets.bustCacheForKey(keyVaultName, secretName);
            }
            const secretString: string = await this.azureSecrets.get(keyVaultName, secretName);
            if (!secretString || secretString === "") {
                throw "Value of secret object is null";
            }

            return JSON.parse(secretString);
        } catch (e: any) {
            if (e.statusCode === 404) {
                this.logger.debug("Was given a key for a tenant name (%s) that doesn't exist or doesn't have any keys yet, returning NotFound error", secretName);
                return notFound();
            } else {
                throw e;
            }
        }
    }

    /**
     * Sets the provided key information on the secret for the provided tenant.
     * @param tenantName The unique Tenant name to set keys for.
     * @param keys The keys to set.
     */
    async setStoredFunctionKeyInfoForTenant(tenantName: string, keys: StoredHashedApiKeys): Promise<void> {
        const secretName = tenantName;
        const keyVaultName = getFunctionKeyKeyVaultName();

        await this.azureSecrets.set(keyVaultName, secretName, JSON.stringify(keys));
    }

    async getIdentityConfig(): Promise<Result<ManagedIdentityConfig, NotFound>> {
        const keyVaultName = getFunctionKeyKeyVaultName();
        const secretName = "managed-identity-config";
        try {
            const secretString: string = await this.azureSecrets.get(keyVaultName, secretName);
            if (!secretString || secretString === "") {
                throw "Value of secret object is null";
            }

            return JSON.parse(secretString);
        } catch (e: any) {
            if (e.statusCode === 404) {
                this.logger.error("Could not retrieve Managed Identity Configuration values, returning NotFound error");
                return notFound();
            } else {
                throw e;
            }
        }
    }
}
