import { Hit, HitResultDocument, HitStatuses, isVersionSearched, makeEditable, QuickSearch, RequestTerm, SearchVersion, SearchVersionEdited, SearchVersionNew } from ".";
import { v4 } from "uuid";
import { Logger } from "aderant-web-fw-core";
import _ from "lodash";

// this is 1.8mb, max should be just under 2mb, just leaving a nice amount of wiggle room
const maxBytes = 1800000;

export function createHitResultDocuments(searchVersion: SearchVersionEdited | SearchVersionNew | QuickSearch): HitResultDocument[] {
    const hitResults: HitResultDocument[] = [];
    searchVersion.requestTerms.forEach((requestTerm) => {
        if (requestTerm.hits?.length) {
            const hitChunks = recursivelySplitHits(requestTerm.hits, maxBytes);
            hitChunks.forEach((chunk) => {
                hitResults.push({
                    versionId: searchVersion.id,
                    id: v4(),
                    requestTermId: requestTerm.id ? requestTerm.id : "",
                    searchDocumentType: "HitResult",
                    hits: chunk
                });
            });
        }
    });

    return hitResults;
}

export function recursivelySplitHits(hits: Hit[], maxBytes: number): Hit[][] {
    if (JSON.stringify(hits).length > maxBytes) {
        return recursivelySplitHits(hits.slice(0, Math.ceil(hits.length / 2)), maxBytes).concat(recursivelySplitHits(hits.slice(Math.ceil(hits.length / 2)), maxBytes));
    } else {
        return [hits];
    }
}
export function removeHitsFromSearchVersion(searchVersion: SearchVersionEdited | SearchVersionNew | QuickSearch): void {
    searchVersion.requestTerms.forEach((requestTerm) => {
        requestTerm.hits = [];
    });
}

export function updateHitStatusSummary(searchVersion: QuickSearch, logger: Logger): QuickSearch;
export function updateHitStatusSummary(searchVersion: SearchVersion, logger: Logger): SearchVersionEdited;
export function updateHitStatusSummary(searchVersion: SearchVersionNew, logger: Logger): SearchVersionNew;
export function updateHitStatusSummary(searchVersion: SearchVersion | SearchVersionNew | QuickSearch, logger: Logger): SearchVersionEdited | SearchVersionNew | QuickSearch;
export function updateHitStatusSummary(searchVersion: SearchVersion | SearchVersionNew | QuickSearch, logger: Logger): SearchVersionEdited | SearchVersionNew | QuickSearch {
    if (searchVersion.editState === "CURRENT") {
        searchVersion = makeEditable(searchVersion);
    }

    const originalSummary = searchVersion.summary;

    if (!isVersionSearched(searchVersion)) {
        searchVersion = { ...searchVersion, summary: undefined };
        return searchVersion;
    }

    const hitCountByStatus: { [id: string]: number } = {};
    //Initialise object
    Object.values(HitStatuses).forEach((status) => {
        hitCountByStatus[status] = 0;
    });
    searchVersion.requestTerms.forEach((term: RequestTerm) => {
        const hits = term.hits;
        if (!hits) {
            return;
        }
        const statusCounts = _.countBy(hits.map((hit: Hit) => hit.status));
        Object.keys(statusCounts).forEach((status: string) => {
            hitCountByStatus[status] += statusCounts[status];
        });
    });

    if (originalSummary && !searchVersion.isQuickSearch) {
        const originalCount = getTotalCount(originalSummary.hitCountByStatus);
        const newCount = getTotalCount(hitCountByStatus);

        const difference = originalCount - newCount;
        if (difference > 0) {
            hitCountByStatus[HitStatuses.Unactioned] += difference;
            logger.error(
                "Encountered issue with missing hits compared to original total on search. Retaining original count in summary to ensure search cannot be completed. (Please tell dev if you see this error)"
            );
        }
    }

    const summary = {
        hitCountByStatus: hitCountByStatus
    };
    return { ...searchVersion, summary: summary };
}

function getTotalCount(hitCountByStatus: { [HitStatus: string]: number }): number {
    return Object.values(hitCountByStatus).reduce((acc, current) => {
        return acc + current;
    }, 0);
}
