import { Grid } from "@mui/material";
import { Lookup, RequestTerm, isTermSingleAsterisk } from "aderant-conflicts-models";
import {
    AddIcon,
    Button,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    Enter,
    GlobalHotkey,
    Hotkey,
    InputText,
    ListBox,
    MessageDialog,
    PickList,
    S,
    SingleSelectValue
} from "@aderant/aderant-react-components";
import { ImportTermsModal } from "components/ImportTermsModal/ImportTermsModal";
import _ from "lodash";
import React, { ChangeEvent, CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { conflictsPalette } from "styles/conflictsPalette";
import { Messages as SharedMessages } from "../Shared/Messages";
import { Messages } from "./Messages";
import { FieldValidity, isSearchTermDuplicate, validateRequestTerm, ValidationResult } from "./SearchRequestTermValidations";
import { messages } from "./SearchUtils";

const searchInputContainer: CSSProperties = {
    backgroundColor: conflictsPalette.background.input,
    alignItems: "center",
    display: "flex",
    borderBottom: `1px solid ${conflictsPalette.border}`,
    padding: "15px 0 5px"
};

const listContainerPartialBorder: CSSProperties = {
    border: `1px solid ${conflictsPalette.border}`,
    borderRight: "none",
    padding: "10px 5px"
};

const quickSearchContainer: CSSProperties = {
    border: `1px solid ${conflictsPalette.border}`,
    padding: "10px 5px"
};

const searchTermInput: CSSProperties = {
    flex: "1 1 auto",
    marginLeft: "8px"
};

const PartySearchProfile = (props: {
    open: boolean;
    rowData: RequestTerm;
    onClose: () => void;
    onSave: (searchRequestTerm: RequestTerm) => void;
    searchVersionId: string;
    requestTerms: RequestTerm[];
    affiliationList: Lookup[];
    partyStatusList: Lookup[];
    isQuickSearch?: boolean;
    importTermsModal?: typeof ImportTermsModal;
}): JSX.Element => {
    const ImportModal = props.importTermsModal ?? ImportTermsModal;
    const [data, setData] = useState(props.rowData);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [newSearchTerm, setNewSearchTerm] = useState("");
    const [isFormSubmittable, setIsFormSubmittable] = useState(false);
    const [canAddSearchTerm, setCanAddSearchTerm] = useState(false);
    const [originalData, setOriginal] = useState(props.rowData);
    const [requestTermValidation, setRequestTermValidation] = useState<ValidationResult>({ status: FieldValidity.Valid });
    const [searchTermValidation, setSearchTermValidation] = useState<ValidationResult>({ status: FieldValidity.Valid });
    const [isImportTermsDialogOpen, setIsImportTermsDialogOpen] = useState<boolean>(false);
    const [importedSearchTerms, setImportedSearchTerms] = useState<string[]>();
    const isQuickSearch = props.isQuickSearch;
    const labelWidth = "105px";

    const searchTermInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setData(props.rowData);
        setOriginal(props.rowData);
        setNewSearchTerm("");
        setRequestTermValidation({ status: FieldValidity.Valid });
        setSearchTermValidation({ status: FieldValidity.Valid });
        if (props.open) {
            const areRequiredFieldsPresent = props.rowData.searchTerms.length >= 1 && props.rowData.term?.length > 0;
            const noInvalidTerms = areRequiredFieldsPresent && !data.searchTerms.some((term) => isTermSingleAsterisk(term) || isSearchTermDuplicate(term, data));
            setIsFormSubmittable(noInvalidTerms);
        }
    }, [props.rowData]);

    useEffect(() => {
        const areRequiredFieldsPresent = data.searchTerms.length >= 1 && data.term?.trim().length > 0;
        const noInvalidTerms = areRequiredFieldsPresent && !data.searchTerms.some((term) => isTermSingleAsterisk(term));
        setIsFormSubmittable(noInvalidTerms);
    }, [data.term, data.searchTerms]);

    const validateSearchTerm = (searchTerm?: string): ValidationResult => {
        if (searchTerm) {
            const isDuplicate = isSearchTermDuplicate(searchTerm, data);
            if (isDuplicate) {
                return { status: FieldValidity.Error, message: messages.searchTermAlreadyExists };
            }
            if (isTermSingleAsterisk(searchTerm)) {
                return { status: FieldValidity.Error, message: messages.searchTermNotAllowed };
            }
        } else if (!searchTerm && data.searchTerms.length < 1) {
            return { status: FieldValidity.Missing, message: messages.addASearchTerm };
        }
        return { status: FieldValidity.Valid };
    };

    const handleChange = (e: ChangeEvent, value, key): void => {
        setData((oldData) => {
            return { ...oldData, [key]: value };
        });
    };

    const handlePickListChange = (e: ChangeEvent, value: SingleSelectValue | null, key: string): void => {
        const newValue = value ? value.value : value;
        setData((oldData) => {
            return { ...oldData, [key]: newValue };
        });
    };

    const onSearchTermChange = (e, value): void => {
        setNewSearchTerm(value);
        const validation = validateSearchTerm(value);
        setSearchTermValidation(validation);
        if (validation.status === FieldValidity.Valid) {
            setCanAddSearchTerm(true);
        } else {
            setCanAddSearchTerm(false);
        }
    };

    const onRequestTermChange = (e: ChangeEvent, value, reason: string | undefined): void => {
        if (reason === "blur" && typeof value === "string") {
            value = value.trim();
        }
        handleChange(e, value, "term");
        if (isQuickSearch) {
            handleChange(e, [value], "searchTerms");
        }
        const validation = validateRequestTerm({ ...data, term: value }, isQuickSearch);
        setRequestTermValidation(validation);
    };

    const onAddSearchTerm = (): void => {
        if (canAddSearchTerm) {
            const trimmedSearchTerm = newSearchTerm.trim();
            if (trimmedSearchTerm != "") {
                setData((oldData) => {
                    return { ...oldData, searchTerms: [trimmedSearchTerm, ...oldData.searchTerms] };
                });
            }
            setNewSearchTerm("");
            searchTermInputRef?.current?.focus();
        }
    };

    const cancelAndClose = (): void => {
        setIsConfirmationOpen(false);
        setRequestTermValidation({ status: FieldValidity.Valid });
        setSearchTermValidation({ status: FieldValidity.Valid });
        setNewSearchTerm("");
        props.onClose();
        setData(props.rowData);
    };

    const handleTermUpdate = (values: string[]) => {
        const newData = { ...data, searchTerms: values.filter((term, index) => values.indexOf(term) === index) };
        setData(newData);
        if (newData.searchTerms.length < 1) {
            setSearchTermValidation({ status: FieldValidity.Missing, message: messages.addASearchTerm });
            setCanAddSearchTerm(false);
        }

        if (newData.searchTerms.some((term) => isTermSingleAsterisk(term))) {
            setSearchTermValidation({ status: FieldValidity.Error, message: messages.searchTermNotAllowed });
            setCanAddSearchTerm(false);
        }
    };

    const onSave = useCallback(() => {
        return new Promise((resolve) => {
            if (isFormSubmittable) {
                let newData = data;
                if (newSearchTerm !== "" && !searchTermValidation) {
                    newData = { ...data, searchTerms: [newSearchTerm, ...data.searchTerms] };
                    setNewSearchTerm("");
                }
                props.onSave(newData);
                setIsConfirmationOpen(false);
                props.onClose();
                resolve(newData);
            }
        });
    }, [isFormSubmittable, data, newSearchTerm, searchTermValidation]);

    const onCancel = (): void => {
        const isEqual = _.isEqual(originalData, data) && _.isEqual(originalData.searchTerms, data.searchTerms);

        if (isEqual) {
            cancelAndClose();
        } else setIsConfirmationOpen(true);
    };

    useEffect(() => {
        if (importedSearchTerms) {
            //Validate each individual search term
            const validatedSearchTerms: string[] = [];
            importedSearchTerms.map((searchTerm) => {
                const validation = validateSearchTerm(searchTerm);
                if (validation.status === FieldValidity.Valid) {
                    const trimmedSearchTerm = searchTerm.trim();
                    if (trimmedSearchTerm != "") {
                        validatedSearchTerms.push(searchTerm);
                    }
                } else {
                    console.log("Unable to add search term: ", searchTerm);
                }
            });
            //Add the valid search terms to the current request
            validatedSearchTerms.length &&
                setData((oldData) => {
                    return { ...oldData, searchTerms: [...validatedSearchTerms, ...oldData.searchTerms] };
                });
            setImportedSearchTerms(undefined);
        }
    }, [importedSearchTerms]);

    return (
        <>
            <ImportModal
                open={isImportTermsDialogOpen}
                termsTemplate="SearchTermsTemplate"
                onCancel={() => {
                    setIsImportTermsDialogOpen(false);
                }}
                onAccept={(searchTermsToAdd) => {
                    setImportedSearchTerms(searchTermsToAdd);
                    setIsImportTermsDialogOpen(false);
                }}
            />
            <Dialog open={props.open} onClose={onCancel} size={isQuickSearch ? "sm" : "md"} aria-labelledby="party-search-profile-id">
                <DialogTitle closeButton onClose={onCancel}>
                    <DialogHeader id="party-search-profile-id">{messages.partySearchProfile}</DialogHeader>
                </DialogTitle>
                <DialogContent>
                    <GlobalHotkey hotkey={S} control triggerBlur onDown={onSave}>
                        <Grid container style={{ backgroundColor: conflictsPalette.background.white }}>
                            <Grid item xs={isQuickSearch ? 12 : 5} style={isQuickSearch ? quickSearchContainer : listContainerPartialBorder}>
                                <InputText
                                    label={isQuickSearch ? messages.term : messages.requestTerm}
                                    labelProps={{ width: labelWidth, position: "left" }}
                                    value={data.term}
                                    onChange={onRequestTermChange}
                                    required
                                    requiredMessage={isQuickSearch ? messages.addATerm : messages.addARequestTerm}
                                    hasError={requestTermValidation.status === FieldValidity.Error}
                                    errorMessage={requestTermValidation.message ?? ""}
                                    autofocus
                                    id="search-edit-dialog-request-term"
                                    style={{ width: "100%", paddingRight: "13px" }}
                                />
                                <PickList
                                    label={messages.affiliation}
                                    labelProps={{ width: labelWidth, position: "left" }}
                                    selectOptions={props.affiliationList}
                                    value={props.affiliationList.find((afil) => afil.value == data.affiliation) || null}
                                    onChange={(e, value) => handlePickListChange(e, value, "affiliation")}
                                    id="search-edit-dialog-affiliation"
                                    style={{ width: "100%", paddingRight: "13px" }}
                                />
                                <PickList
                                    label={messages.partyStatus}
                                    labelProps={{ width: labelWidth, position: "left" }}
                                    selectOptions={props.partyStatusList}
                                    value={props.partyStatusList.find((party) => party.value == data.partyStatus) || null}
                                    onChange={(e, value) => handlePickListChange(e, value, "partyStatus")}
                                    id="search-edit-dialog-party-status"
                                    style={{ width: "100%", paddingRight: "13px" }}
                                />
                            </Grid>
                            {!isQuickSearch && (
                                <Grid item xs={7} style={{ border: `1px solid ${conflictsPalette.border}` }}>
                                    <>
                                        <Hotkey hotkey={Enter} onDown={onAddSearchTerm}>
                                            <div style={searchInputContainer} className="search-container">
                                                <div style={{ width: "100%" }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2px" }}>
                                                        <span style={{ marginLeft: "25px" }}>{`${messages.searchTerms} (${data.searchTerms.length})`}</span>
                                                        <button
                                                            title={messages.addImportTerms}
                                                            style={{ marginRight: "58px", borderStyle: "none", color: conflictsPalette.text.hyperlink, cursor: "pointer", display: "block" }}
                                                            onClick={() => setIsImportTermsDialogOpen(true)}
                                                        >
                                                            {messages.addImportTerms}
                                                        </button>
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <InputText
                                                            onChange={onSearchTermChange}
                                                            value={newSearchTerm}
                                                            required={data.searchTerms.length < 1}
                                                            requiredMessage={messages.addASearchTerm}
                                                            hasError={searchTermValidation.status === FieldValidity.Error}
                                                            errorMessage={searchTermValidation.message ?? ""}
                                                            id="search-edit-dialog-search-term"
                                                            inputRef={searchTermInputRef}
                                                            aria-label="Search Term"
                                                            style={searchTermInput}
                                                            placeholder={messages.enterHelpText}
                                                        />
                                                        <Button
                                                            iconButton
                                                            startIcon={<AddIcon style={{ fontSize: "large", borderRadius: "50%" }} />}
                                                            aria-label={Messages.ADD_SEARCH_TERM_BUTTON.getMessage()}
                                                            title={Messages.ADD_SEARCH_TERM_BUTTON.getMessage()}
                                                            onClick={onAddSearchTerm}
                                                            color="primary"
                                                            rounded
                                                            disabled={!canAddSearchTerm || newSearchTerm?.trim() == ""}
                                                            style={{ alignSelf: "flex-start", margin: "4px 17px 0 15px", height: "fit-content" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Hotkey>
                                        <ListBox contents={data.searchTerms} onChange={handleTermUpdate} style={{ height: "350px", overflowY: "auto", padding: "0 0 15px 10px" }} />
                                    </>
                                </Grid>
                            )}
                        </Grid>
                    </GlobalHotkey>
                </DialogContent>
                <DialogFooter>
                    <>
                        <Button text={messages.cancel} title={Messages.PARTY_SEARCH_PROFILE_CANCEL_BUTTON.getMessage()} color="secondary" onClick={onCancel} size="medium" />
                        <Button
                            data-testid="PartySearchProfile-save-button"
                            title={Messages.PARTY_SEARCH_PROFILE_SAVE_BUTTON.getMessage()}
                            text={messages.save}
                            onClick={onSave}
                            size="medium"
                            disabled={!isFormSubmittable || requestTermValidation.status !== FieldValidity.Valid}
                        />
                    </>
                </DialogFooter>
            </Dialog>
            <MessageDialog
                open={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                title={SharedMessages.LEAVE_WITHOUT_SAVING_TITLE.getMessage()}
                message={SharedMessages.LEAVE_WITHOUT_SAVING.getMessage()}
                footer={
                    <>
                        <Button text="No" color="secondary" onClick={() => setIsConfirmationOpen(false)} size="medium" />
                        <Button text="Yes" onClick={cancelAndClose} size="medium" />
                    </>
                }
            />
        </>
    );
};

export { PartySearchProfile };
