import { FileDownload } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { RequestTerm, SearchVersion, SearchVersionNew } from "aderant-conflicts-models";
import { AderantTheme, Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@aderant/aderant-react-components";
import Excel from "exceljs";
import { convertStringWithLinebreaksToJSX } from "Functions/convertStringToJSX";
import { RootState } from "MyTypes";
import { Messages } from "pages/SearchEditPage/Messages";
import { configureTermToAdd, messages } from "pages/SearchEditPage/SearchUtils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SaveSearchActionTypes, searchActions } from "state/actions";
import { conflictsPalette } from "styles/conflictsPalette";
import { saveRequestTermsTemplate } from "./ExcelTemplates/saveRequestTermsTemplate";
import { saveSearchTermsTemplate } from "./ExcelTemplates/saveSearchTermsTemplate";
import { ImportTermsDropzone } from "./ImportTermsDropzone";
import { processRequestTermsWorkbook, processSearchTermsWorkbook } from "./ImportTermsFunctions";
import { ImportTermsWarning, renderWarnings } from "./WarningMessages";

export interface ImportTermsModalProps {
    termsTemplate: "RequestTermsTemplate" | "SearchTermsTemplate";
    open: boolean;
    onCancel: () => void;
    onAccept: (searchTermsToAdd?: string[]) => void;
    searchVersion?: SearchVersion | SearchVersionNew;
    isNewVersion?: boolean;
}

function isWorkbookValid(validImportFile: Workbook | WorkbookError | undefined): boolean {
    return validImportFile?.type === "Workbook";
}

export interface WorkbookError {
    type: "WorkbookError";
    data: {
        title: string;
        errorMessages: string[];
    };
}

export interface Workbook {
    type: "Workbook";
    data: Excel.Workbook;
    duplicateTerms: string[];
}

enum Status {
    Valid = "Valid",
    Warning = "Warning",
    Error = "Error"
}

export type FileProps = {
    name: string;
    progress: number;
};

export type WorkbookHandler = (workbook: Workbook | WorkbookError) => void;
export type FilePropsHandler = (fileProps: FileProps) => void;

export function ImportTermsModal(props: ImportTermsModalProps) {
    const [workbook, setWorkbook] = useState<Workbook | WorkbookError>();
    const [requestTerms, setRequestTerms] = useState<RequestTerm[]>();
    const [searchTerms, setSearchTerms] = useState<string[] | undefined>(undefined);
    const [warnings, setWarnings] = useState<ImportTermsWarning[]>([]);
    const [clearFile, setClearFile] = useState<boolean>(false);
    const [status, setStatus] = useState<Status | undefined>(undefined);
    const emptyFileProps = { name: "", progress: 0 };
    const [fileProperties, setFileProperties] = useState<FileProps>(emptyFileProps);
    const { isNewVersion = false } = props;

    const dispatch = useDispatch();

    //Get Lookup lists
    const affiliationList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.affiliationList;
    });
    const partyStatusList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.partyStatusList;
    });

    const validationLists = { affiliations: affiliationList, partyStatuses: partyStatusList };

    function processRequestTerms() {
        if (workbook?.type === "Workbook") {
            const result = processRequestTermsWorkbook(workbook, validationLists);
            if (result.type === "ValidRequestTerms") {
                if (result.warnings.length > 0) {
                    setStatus(Status.Warning);
                    setWarnings(result.warnings);
                } else {
                    setStatus(Status.Valid);
                }
                setRequestTerms(result.requestTerms);
            } else {
                setWorkbook(result);
            }
        }
    }

    function processSearchTerms() {
        if (workbook?.type === "Workbook") {
            const result = processSearchTermsWorkbook(workbook);
            if (result.type === "ValidSearchTerms") {
                setSearchTerms(result.searchTerms);
                if (result.warnings.length > 0) {
                    setStatus(Status.Warning);
                    setWarnings(result.warnings);
                } else {
                    setStatus(Status.Valid);
                }
            } else {
                setWorkbook(result);
            }
        }
    }

    useEffect(() => {
        if (workbook) {
            if (workbook.type === "Workbook") {
                props.termsTemplate === messages.searchTermsTemplate ? processSearchTerms() : processRequestTerms();
            } else {
                setStatus(Status.Error);
            }
        } else {
            setStatus(undefined);
        }
    }, [workbook]);

    function clearWarningsAndWorkbook() {
        setWarnings([]);
        setWorkbook(undefined);
    }

    function clearModal() {
        setClearFile(true);
        clearWarningsAndWorkbook();
    }

    const importWorkbook: WorkbookHandler = function (workbook) {
        if (workbook.type === "WorkbookError") {
            console.log("Original workbook error: ", workbook.data.errorMessages[0]);
            //Change message to generic if we receive an error from the dropzone
            workbook.data.errorMessages[0] = messages.excelErrorUploadFailure;
        }
        setWorkbook(workbook);
    };

    const updateFileProps: FilePropsHandler = function (fileProps) {
        fileProperties.name !== fileProps.name && clearWarningsAndWorkbook();
        setFileProperties(fileProps);
        fileProps.name.length && setClearFile(false);
    };

    function saveRequestTerms(requestTerms: RequestTerm[]) {
        if (props.searchVersion) {
            const termsToAdd: RequestTerm[] = [];
            requestTerms.map((requestTerm) => {
                termsToAdd.push(configureTermToAdd(requestTerm));
            });

            dispatch(
                searchActions.saveSearch({
                    saveSearchActionInput: { actionType: SaveSearchActionTypes.ADD_TERMS, payload: { searchVersion: props.searchVersion, terms: termsToAdd } },
                    doNotPersist: isNewVersion
                })
            );
        } else {
            console.log("Unable to add request terms to null search version");
        }
    }

    function onAccept() {
        if (props.termsTemplate === "SearchTermsTemplate") {
            // Send the search terms to props so the party search profile component can use them
            props.onAccept(searchTerms);
        } else {
            // Save the request terms to the search directly
            requestTerms && saveRequestTerms(requestTerms);
            setRequestTerms(undefined);
            props.onAccept();
        }
        clearModal();
    }

    function onCancel() {
        const workbookParsing = !!fileProperties.progress && fileProperties.progress < 100;
        if (workbookParsing) {
            //Do nothing while the workbook is parsing/processing.
        } else {
            clearModal();
            props.onCancel();
        }
    }

    return (
        <Dialog open={props.open} onClose={onCancel} size="sm" aria-labelledby="import-terms-id">
            <DialogTitle closeButton onClose={onCancel}>
                <DialogHeader id="import-terms-id">{messages.importTerms}</DialogHeader>
                <div style={{ position: "absolute", right: "70px", top: "18px", display: "flex", alignItems: "center" }}>
                    <div data-testid="import-terms-label" style={{ marginRight: "10px", color: conflictsPalette.text.black, fontSize: AderantTheme.typography.pxToRem(14), fontWeight: 400 }}>
                        {messages.downloadTemplate}
                    </div>
                    <Button
                        iconButton
                        rounded
                        size="small"
                        startIcon={<FileDownload />}
                        color="primary"
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            {
                                props.termsTemplate === "SearchTermsTemplate" ? saveSearchTermsTemplate() : saveRequestTermsTemplate(affiliationList, partyStatusList);
                            }
                        }}
                        aria-label={Messages.IMPORT_TERMS_BUTTON.getMessage()}
                        title={Messages.IMPORT_TERMS_BUTTON.getMessage()}
                        margin-left="10px"
                    />
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid container spacing={2} item>
                        <Grid item xs={12}>
                            <ImportTermsDropzone onFileImported={importWorkbook} clearFile={clearFile} fileProps={updateFileProps} />
                        </Grid>
                        {fileProperties.name && (
                            <Grid container spacing={2} item>
                                <Grid item xs={12} style={{ color: conflictsPalette.text.black, fontSize: AderantTheme.typography.pxToRem(16) }}>
                                    {messages.uploadedFiles}
                                </Grid>
                                <Grid item xs={11} style={{ paddingRight: "20px" }}>
                                    <div style={{ fontSize: AderantTheme.typography.pxToRem(12), display: "flex", justifyContent: "space-between" }}>
                                        <Typography style={{ color: conflictsPalette.text.disabled }}>{fileProperties.name}</Typography>
                                        {workbook && (
                                            <Typography style={{ color: conflictsPalette.text.black }}>
                                                {status === Status.Error && messages.uploadFailed}
                                                {status === Status.Warning && messages.warning}
                                                {status === Status.Valid && messages.uploadSuccessful}
                                            </Typography>
                                        )}
                                    </div>
                                    {fileProperties.progress && <LinearProgress variant="determinate" value={fileProperties.progress} style={{ marginTop: 6, borderRadius: 3, height: 6 }} />}
                                    <br />
                                    {workbook && (
                                        <>
                                            {status === Status.Warning && renderWarnings(warnings, props.termsTemplate)}
                                            {status === Status.Error && workbook.type === "WorkbookError" && convertStringWithLinebreaksToJSX(workbook?.data.errorMessages.join("\n\n"))}
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={1}>
                                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", paddingLeft: "10px", marginTop: "7px" }}>
                                        {workbook && (
                                            <>
                                                {status === Status.Error && <ErrorOutlineIcon style={{ color: conflictsPalette.status.red, width: "24px" }} />}
                                                {status === Status.Warning && <ErrorOutlineIcon style={{ color: conflictsPalette.status.orange, width: "24px" }} />}
                                                {status === Status.Valid && <CheckCircleOutlineIcon style={{ color: conflictsPalette.status.green, width: "24px" }} />}
                                            </>
                                        )}
                                        <Button
                                            variant="plain"
                                            rounded
                                            iconButton
                                            startIcon={<CloseIcon style={{ color: conflictsPalette.status.grey, width: "16px", height: "16px" }} />}
                                            aria-label="Clear file button"
                                            onClick={() => clearModal()}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogFooter>
                <>
                    <Button
                        title={Messages.IMPORT_TERMS_MODAL_CANCEL_BUTTON.getMessage()}
                        text={Messages.IMPORT_TERMS_MODAL_CANCEL_BUTTON.getMessage()}
                        color="secondary"
                        onClick={onCancel}
                        size="medium"
                        data-testid="ImportTermsModal-cancel-button"
                    />
                    <Button
                        title={Messages.IMPORT_TERMS_MODAL_IMPORT_BUTTON.getMessage()}
                        text={Messages.IMPORT_TERMS_MODAL_IMPORT_BUTTON.getMessage()}
                        disabled={!isWorkbookValid(workbook)}
                        onClick={onAccept}
                        size="medium"
                        data-testid="ImportTermsModal-import-button"
                    />
                </>
            </DialogFooter>
        </Dialog>
    );
}
