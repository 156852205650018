import { Message } from "aderant-conflicts-models";

export const Messages = {
    ADD_REQUEST_TERM_BUTTON: new Message("ADD_REQUEST_TERM_BUTTON", "Add request term"),
    ADD_SEARCH_REQUEST_BUTTON: new Message("ADD_SEARCH_REQUEST_BUTTON", "Add/Edit search terms"),
    ADD_SEARCH_TERM_BUTTON: new Message("ADD_SEARCH_TERM_BUTTON", "Add search term"),
    PARTY_SEARCH_PROFILE_SAVE_BUTTON: new Message("PARTY_SEARCH_PROFILE_SAVE_BUTTON", "Save"),
    PARTY_SEARCH_PROFILE_CANCEL_BUTTON: new Message("PARTY_SEARCH_PROFILE_CANCEL_BUTTON", "Cancel"),
    SEARCH_FAILED_WARNING: new Message("SEARCH_FAILED_WARNING", "Search failed, please search again."),
    SEARCH_FAILED_SUPPORT_CALL: new Message("SEARCH_FAILED_SUPPORT_CALL", "If the search fails several times, contact Support."),
    //Import terms
    IMPORT_TERMS_BUTTON: new Message("IMPORT_TERMS_BUTTON", "Import terms"),
    IMPORT_TERMS_LABEL: new Message("IMPORT_TERMS_LABEL", "Import Terms"),
    IMPORT_TERMS_MODAL_IMPORT_BUTTON: new Message("IMPORT_TERMS_MODAL_IMPORT_BUTTON", "Import"),
    IMPORT_TERMS_MODAL_CANCEL_BUTTON: new Message("IMPORT_TERMS_MODAL_CANCEL_BUTTON", "Cancel"),
    //Edit page containers
    NEW_SEARCH_REQUEST_PAGE_TITLE: new Message("NEW_SEARCH_REQUEST_PAGE_TITLE", "New Search Request"),
    SAVE_AS_DRAFT_PROMPT: new Message("SAVE_AS_DRAFT_PROMPT", "Do you want to save this search request in Draft?"),
    SAVE_AS_DRAFT_TITLE: new Message("SAVE_AS_DRAFT_TITLE", "Save as Draft?"),
    PENDING_CHANGES_TITLE: new Message("PENDING_CHANGES_TITLE", "Unsaved Changes"),
    PENDING_CHANGES_PROMPT: new Message("PENDING_CHANGES_PROMPT", "Changes that you made may not be saved, leave the page?"),
    COPY_FAILED_TITLE: new Message("COPY_FAILED_TITLE", "Copy failed"),
    COPY_FAILED_PROMPT: new Message("COPY_FAILED_PROMPT", "The search request copy could not be created. Please try again later"),
    NEW_SEARCH_REQUEST_QUICKSEARCH_ERROR: new Message("NEW_SEARCH_REQUEST_QUICKSEARCH_ERROR", "Search should not be a quick search"),
    NEW_SEARCH_VERSION_PAGE_TITLE: new Message("NEW_SEARCH_VERSION_PAGE_TITLE", "New Search Version"),
    NEW_SEARCH_VERSION_QUICKSEARCH_ERROR: new Message("NEW_SEARCH_VERSION_QUICKSEARCH_ERROR", "The search is a quick search which does not support new versions"),
    NEW_QUICK_SEARCH_PAGE_TITLE: new Message("NEW_QUICK_SEARCH_PAGE_TITLE", "New Quick Search"),
    DISCARD_CHANGES_TITLE: new Message("DISCARD_CHANGES_TITLE", "Leave Without Saving?"),
    DISCARD_CHANGES_PROMPT: new Message("DISCARD_CHANGES_PROMPT", "Your changes will not be saved if you leave this page."),
    SEARCH_NOT_FOUND_ERROR: new Message("SEARCH_NOT_FOUND_ERROR", "The search cannot be found."),
    //Search Edit Page State
    SEARCH: new Message("SEARCH", "Search"),
    SUBMIT: new Message("SUBMIT", "Submit"),
    EXISTING_SEARCH_REQUEST_TITLE: new Message("EXISTING_SEARCH_REQUEST_TITLE", "Search Request")
};
