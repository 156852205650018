/**
 * Provides both internal and user-facing strings.  Internal strings are here to
 * make unit testing easier, letting us compare the returned error messages properly.
 */

import { Message } from "aderant-conflicts-models";

export const messages = {
    VLD_NULL_UNDEF_ID: new Message("VLD_NULL_UNDEF_ID", "Id is null or undefined"),
    VLD_EMPTY_STRING_ID: new Message("VLD_EMPTY_STRING_ID", "Id parameter cannot be an empty string"),
    VLD_EMPTY_GUID_ID: new Message("VLD_EMPTY_GUID_ID", "Id cannot be an empty GUID"),
    VLD_NULL_ARG_BODY: new Message("VLD_NULL_ARG_BODY", "Request body is null or undefined"),

    VLD_NULL_SCH_CHANGE: new Message("VLD_NULL_SCH_CHANGE", "No search change given"),
    VLD_NULL_SCH_IDS: new Message("VLD_NULL_SCH_IDS", "No search ids given"),

    VLD_SAVE_NULL_SCH: new Message("VLD_SAVE_NULL_SCH", "Search is null or undefined"),
    VLD_SAVE_EMPTY_SCH_ID: new Message("VLD_SAVE_EMPTY_SCH_ID", "Search Id cannot be an empty GUID"),
    VLD_SAVE_NULL_TERMS: new Message("VLD_SAVE_NULL_TERMS", "Search must have a terms array"),
    VLD_SAVE_NULL_CREATEDBY: new Message("VLD_SAVE_NULL_CREATEDBY", "Missing createdBy id"),
    VLD_SAVE_EMPTY_CREATEDBY: new Message("VLD_SAVE_EMPTY_CREATEDBY", "Missing createdBy id"),
    VLD_SAVE_NULL_SEARCH_TERM: new Message("VLD_SAVE_NULL_SEARCH_TERM", "Search term is null or undefined"),
    VLD_SAVE_INVALID_SEARCH_TERM: new Message<[term: string]>("VLD_SAVE_INVALID_SEARCH_TERM", "Search term {0} is invalid"),
    VLD_SAVE_NULL_REQ_TERMS: new Message("VLD_SAVE_NULL_REQ_TERMS", "Requested terms array is null or undefined"),
    VLD_SAVE_NULL_REQ_TERM: new Message("VLD_SAVE_NULL_REQ_TERM", "Requested term is null or undefined"),
    VLD_SAVE_INVALID_REQ_TERM: new Message<[term: string]>("VLD_SAVE_INVALID_REQ_TERM", "Requested term {0} is invalid"),

    VLD_INVALID_STATUS_CHANGE: new Message<[oldStatus: string, newStatus: string]>("VLD_INVALID_STATUS_CHANGE", "Cannot change status from {0} to {1}"),
    VLD_INVALID_STATUS_NOTIFICATION_MESSAGE_TOO_LONG: new Message("VLD_INVALID_STATUS_NOTIFICATION_MESSAGE_TOO_LONG", "Status notification message must be less than 2,000 characters"),
    VLD_INVALID_REASSIGN: new Message("VLD_INVALID_REASSIGN", "User cannot reassign this search"),
    VLD_INVALID_REASSIGN_MESSAGE_TOO_LONG: new Message("VLD_INVALID_REASSIGN_MESSAGE_TOO_LONG", "Reassign message must be less than 2,000 characters"),
    VLD_SUBMIT_DATE_NOT_SET: new Message("VLD_SUBMIT_DATE_NOT_SET", "When submitting a search, submitted date must be populated"),
    VLD_SUBMIT_DATE_ON_DRAFT: new Message("VLD_SUBMIT_DATE_ON_DRAFT", "Draft searches cannot have a submitted date"),
    VLD_SUBMIT_DATE_UNSET: new Message("VLD_SUBMIT_DATE_UNSET", "Submitted date was removed"),
    VLD_SUBMITTED_SEARCH_CHANGED_BY_UNASSIGNED_USER: new Message("VLD_SUBMITTED_SEARCH_CHANGED_BY_UNASSIGNED_USER", "Submitted searches can only be altered by assigned users"),
    VLD_SAVE_SEARCH_PERFORMED_BY_UNAUTH_USER: new Message("VLD_SAVE_SEARCH_PERFORMED_BY_UNAUTH_USER", "Search can only be performed by a Conflicts user")
};
