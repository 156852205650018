import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button as MuiButton, Menu, MenuItem } from "@mui/material";
import { HitStatus, SearchVersion } from "aderant-conflicts-models";
import { AderantTheme, ComboBoxItem } from "@aderant/aderant-react-components";
import { getHitStatusDisplayName, hitStatusList } from "dataProviders/hitStatusList";
import { getHitStatusColors } from "Functions/getHitStatusColors";
import React, { useRef, useState } from "react";
import { usePermissionsContext } from '../../state/selectors/appSelectors';

export type HitStatusComboBoxProps = {
    id?: string;
    hitStatus: HitStatus;
    onItemSelected: (item: ComboBoxItem<HitStatus>) => void;
    searchVersion?: SearchVersion;
    style?: React.CSSProperties;
    className?: string;
    disabled?: boolean;
};

export function HitStatusComboBox<T>(props: HitStatusComboBoxProps): JSX.Element {
    const permissions = usePermissionsContext();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const muiButtonRef = useRef<HTMLButtonElement>(null);
    const { id, hitStatus, onItemSelected, searchVersion, style, className, disabled = false } = props;
    const hitStatusOptions = hitStatusList(permissions, searchVersion);
    const colors = getHitStatusColors(hitStatus);
    const handleSelected = (item: ComboBoxItem<HitStatus>): void => {
        if (onItemSelected) {
            onItemSelected(item);
        }
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
        setExpanded(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setExpanded(false);
    };

    const buttonWidth = muiButtonRef.current?.getBoundingClientRect().width;

    const menuItems = hitStatusOptions.map((i) => {
        return (
            <MenuItem
                key={i.displayName}
                onClick={() => {
                    handleClose();
                    handleSelected(i);
                }}
                style={{ minWidth: buttonWidth }}
            >
                {i.displayName}
            </MenuItem>
        );
    });

    const expandIcon = expanded ? <ExpandLessIcon fontSize={"small"} /> : <ExpandMoreIcon fontSize={"small"} />;

    const buttonId: string | undefined = id ? `${id}-hitstatusbutton` : undefined;
    const menuId: string | undefined = id ? `${id}-hitstatusmenu` : undefined;

    const isOpen = Boolean(anchorEl);

    return (
        <div id={id} className={className} style={style}>
            <MuiButton
                sx={{
                    ...colors.default,
                    transitionDuration: "0s",
                    ":focus-visible": {
                        boxShadow: `0 0 0 1px ${colors.hover.borderColor}`,
                        ...colors.hover
                    },
                    "@media (hover: hover)": {
                        ":hover": colors.hover
                    },
                    "@media (hover: none)": {
                        ":hover": isOpen ? colors.clicked : colors.default
                    },
                    ...(isOpen && colors.clicked),
                    ...(disabled && { opacity: 0.4 }),
                    "& .MuiButton-root": {
                        fontSize: AderantTheme.typography.pxToRem(12)
                    }                        

                }}
                onClick={handleClick}
                variant="outlined"
                ref={muiButtonRef}
                disableFocusRipple
                disableRipple
                endIcon={expandIcon}
                id={buttonId}
                aria-controls={menuId}
                aria-haspopup={true}
                aria-expanded={isOpen}
                disabled={disabled}
            >
                {getHitStatusDisplayName(hitStatus)}
            </MuiButton>
            <Menu
                id={menuId}
                aria-labelledby={buttonId}
                anchorEl={anchorEl}
                keepMounted
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                {menuItems}
            </Menu>
        </div>
    );
}

//Prop defaults.
HitStatusComboBox.defaultProps = {
    disabled: false
};
