import {
    ConflictsTenantBlobStorage,
    CurrentUserContext,
    etagMismatch,
    EtagMismatch,
    NotFound,
    ok,
    Result,
    unexpectedError,
    jsonParseError,
    JsonParseError,
    isJsonParseError,
    LogContext
} from "aderant-conflicts-models";
import { ConfigurationTemplates } from "aderant-conflicts-models/dist/ConfigurationTemplates";
import { TypeValidationMessages as messages } from "../APIs/Messages";
import { BlobStorageConnector } from "../BlobStorageConnector/BlobStorageConnector";

interface ConfigurationCache<T> {
    ConfigurationCache: Record<string, ConfigurationTemplates<T>>;
    ConfigurationCacheLastUpdated: Record<string, number>;
}

/**
 * This class allows for fetching and updating of configuration templates with caching
 */
export class ConfigurationService<T> {
    private cacheAge = 10 * 60 * 1000; //Default cache age = 10 minutes
    private cache: ConfigurationCache<T>;
    private defaultTemplates: T[];

    constructor(configurationCache: ConfigurationCache<T>, defaultTemplates: T[]) {
        this.cache = configurationCache;
        this.defaultTemplates = defaultTemplates;
    }

    /**
     * Retrieves the configuration for the given blobKey.
     * @param context: the Current Log Context
     * @param tenancyName:  The unique tenancy name for the tenant.  This is a separate param because we can call this from a userless key auth function
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param blobName: Used to determine which blob file name to retrieve. Utilise @type {ConflictsTenantBlobStorage} for ease of use.
     * @param containerName: the container to retrieve the templates from. Optional. If not specified the default is the configuration container
     * @param cacheAge Optional - Default value is 60000 (10 minutes). Will fetch templates from cache if cache age is less than this, else will fetch from blob storage.
     */
    public async getConfiguration(
        context: LogContext,
        tenancyName: string,
        blobConnector: BlobStorageConnector,
        blobName: string,
        containerName: string = ConflictsTenantBlobStorage.Configuration.containerName,
        cacheAge?: number
    ): Promise<ConfigurationTemplates<T>> {
        if (
            this.cache.ConfigurationCache[tenancyName] &&
            this.cache.ConfigurationCacheLastUpdated[tenancyName] &&
            Date.now() - this.cache.ConfigurationCacheLastUpdated[tenancyName] < (cacheAge ?? this.cacheAge)
        ) {
            context.logger.info("Retrieving %s configurations from cache.", blobName);
        } else {
            const result = await this.getCustomConfiguration(context, tenancyName, blobConnector, blobName, containerName);
            if (ok(result)) {
                context.logger.info("Custom %s configurations found,", blobName);
                this.cache.ConfigurationCache[tenancyName] = result;
                this.cache.ConfigurationCacheLastUpdated[tenancyName] = Date.now();
            } else {
                if (isJsonParseError(result)) {
                    throw unexpectedError(result.rootCause, result.contextInfo);
                } else {
                    context.logger.info("No custom %s configurations found. Using default configurations.", blobName);
                }
                this.cache.ConfigurationCache[tenancyName] = {
                    configurations: this.defaultTemplates
                };
                this.cache.ConfigurationCacheLastUpdated[tenancyName] = Date.now();
            }
        }
        return this.cache.ConfigurationCache[tenancyName];
    }

    /**
     * Retrieves the configuration for the given blobKey from the blob storage.
     * @param context: the Current Log Context
     * @param tenancyName:  The unique tenancy name for the tenant.  This is a separate param because we can call this from a userless key auth function
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param blobName: Used to determine which blob file to retrieve. Utilise @type {ConflictsTenantBlobStorage} for ease of use.
     * @param containerName: the container to retrieve the templates from. Optional. If not specified the default is the customization-wip container
     */
    public async getCustomConfiguration(
        context: LogContext,
        tenancyName: string,
        blobConnector: BlobStorageConnector,
        blobName: string,
        containerName: string = ConflictsTenantBlobStorage.CustomizationWip.containerName
    ): Promise<Result<ConfigurationTemplates<T>, NotFound | JsonParseError>> {
        context.logger.info(`Fetching custom configuration ${blobName} from blob storage container ${containerName}.`);
        const response = await this.getBlobFromBlobStorage(blobConnector, blobName, containerName);
        if (ok(response)) {
            try {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const template = JSON.parse(response.content) as T[];

                if (template) {
                    return {
                        configurations: template,
                        etag: response.etag
                    };
                }
                return jsonParseError(
                    { message: `Blobstorage returned empty/null/undefined custom configuration from ${blobName}` },
                    `Error when parsing custom configuration to JSON in getCustomConfiguration from blob ${blobName} in container ${containerName} for tenancy ${tenancyName}`
                );
            } catch (e) {
                return jsonParseError(e, `Error when parsing custom configuration to JSON in getCustomConfiguration from blob ${blobName} in container ${containerName} for tenancy ${tenancyName}`);
            }
        }
        return response;
    }

    private async getBlobFromBlobStorage(blobConnector: BlobStorageConnector, blobName: string, containerName: string): Promise<Result<{ content: string; etag: string | undefined }, NotFound>> {
        const blob = await blobConnector.getBlobContentWithEtag(containerName, blobName);
        if (ok(blob))
            return {
                content: blob.content.toString(),
                etag: blob.etag
            };
        return blob;
    }

    /**
     * Updates the configuration in the given container
     * @param context: the Current User Context
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param configurationTemplates an object containing { configurations: an array of T[] to be saved to blob storage, and an optional etag },
     * @param blobName: Used to determine which blob file name to update. Utilise @type {ConflictsTenantBlobStorage} for ease of use.
     * @param containerName: the name of the container to save to. Optional. If not specified, defaults to the customization-wip container.
     */
    public async updateConfiguration(
        context: CurrentUserContext,
        blobConnector: BlobStorageConnector,
        configurationTemplates: ConfigurationTemplates<T>,
        blobName: string,
        containerName: string = ConflictsTenantBlobStorage.CustomizationWip.containerName
    ): Promise<Result<undefined, EtagMismatch>> {
        const configurations: string = JSON.stringify(configurationTemplates.configurations, null, 2);
        try {
            //No etag checking for Phase 1, so don't pass an etag to saveBlob (built in etags don't work when moving betweeen cotainers)
            const response = await blobConnector.saveBlob(containerName, blobName, configurations);
            if (response?._response?.status === 412) {
                return etagMismatch(messages.VLD_ETAG_MISMATCH.asValidationMessage(blobName, "redownload").message);
            }
            return undefined;
        } catch (e: any) {
            if (e.statusCode == 412) {
                return etagMismatch(messages.VLD_ETAG_MISMATCH.asValidationMessage(blobName, "redownload").message);
            }
            throw unexpectedError(
                e,
                `updateConfiguration - Error saving configurations to '${blobName}' in container '${containerName}' for tenancy '${context.currentUser.tenancy.uniqueName}'`,
                e.statusCode
            );
        }
    }
}
