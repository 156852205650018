import { OmitStrict } from "aderant-web-fw-core";
import { getQuickSearchSummary, isNewSearchVersion, QuickSearch, QuickSearchSummary, SearchVersionNew, WithAudits } from ".";
import { isErroredSearch, SearchVersion } from "./SearchVersion";

export type SearchSummary = OmitStrict<SearchVersion, "requestTerms" | "searchDocumentType" | "searchId" | "_etag" | "applyFuzzySearch" | "isQuickSearch"> & {
    versionId: string;
    /** @deprecated errored is no longer used for SearchSummary, refer to errorStatus */
    errored?: boolean;
    errorStatus?: { isErrored: boolean; errorCode?: string };
    searchEtag: string;
    createdOn: Date;
    isQuickSearch?: boolean;
};

export type SearchSummaryNew = OmitStrict<SearchVersionNew, "requestTerms" | "searchDocumentType" | "searchId" | "applyFuzzySearch"> & {
    errorStatus?: { isErrored: boolean; errorCode?: string };
    /** @deprecated errored is no longer used for SearchSummary, refer to errorStatus */
    errored?: boolean;
    versionId: string;
    createdOn: Date;
};

export type SearchSummaryPersistedInCosmos = OmitStrict<SearchSummary, "lastModified"> & {
    _ts: number;
};

export function getSearchSummary(searchVersion: QuickSearch): QuickSearchSummary;
export function getSearchSummary(searchVersion: SearchVersionNew): SearchSummaryNew;
export function getSearchSummary(searchVersion: SearchVersion): SearchSummary;
export function getSearchSummary(searchVersion: SearchVersion | SearchVersionNew): SearchSummary | SearchSummaryNew;
export function getSearchSummary(searchVersion: SearchVersion | SearchVersionNew | QuickSearch): SearchSummary | SearchSummaryNew | QuickSearchSummary;
export function getSearchSummary(searchVersion: WithAudits<SearchVersion | SearchVersionNew> | QuickSearch): SearchSummary | SearchSummaryNew | QuickSearchSummary {
    let searchSummaryName: string;
    searchSummaryName = searchVersion.name;
    if (!searchSummaryName) {
        //Use comma-delimited of the first 3 request terms as name if name is empty
        searchSummaryName = searchVersion.requestTerms
            .slice(0, 3)
            .map((s) => s.term)
            .join(", ");
    }
    if (searchVersion.isQuickSearch) {
        return getQuickSearchSummary(searchSummaryName, searchVersion);
    } else if (isNewSearchVersion(searchVersion)) {
        return {
            name: searchSummaryName,
            number: searchVersion.number,
            version: searchVersion.version,
            id: searchVersion.searchId,
            versionId: searchVersion.id,
            createdByUserId: searchVersion.createdByUserId,
            requestedByUserId: searchVersion.requestedByUserId,
            status: searchVersion.status,
            isLatestVersion: searchVersion.isLatestVersion,
            editState: searchVersion.editState,
            errorStatus: getSummaryErrorStatus(searchVersion),
            searchDate: searchVersion.searchDate,
            summary: searchVersion.summary,
            apiMetadata: searchVersion.apiMetadata,
            createdOn: searchVersion.createdOn
        };
    } else {
        return {
            name: searchSummaryName,
            number: searchVersion.number,
            version: searchVersion.version,
            id: searchVersion.searchId,
            versionId: searchVersion.id,
            createdByUserId: searchVersion.createdByUserId,
            requestedByUserId: searchVersion.requestedByUserId,
            assignedToUserId: searchVersion.assignedToUserId,
            status: searchVersion.status,
            isLatestVersion: searchVersion.isLatestVersion,
            editState: searchVersion.editState,
            searchDate: searchVersion.searchDate,
            createdOn: searchVersion.createdOn,
            submittedDate: searchVersion.submittedDate,
            summary: searchVersion.summary,
            searchEtag: searchVersion._etag,
            errorStatus: getSummaryErrorStatus(searchVersion),
            lastModified: searchVersion.lastModified,
            apiMetadata: searchVersion.apiMetadata
        };
    }
}

export function isNewSearchSummary(searchSummary: SearchSummary | SearchSummaryNew | undefined): searchSummary is SearchSummaryNew {
    return searchSummary?.editState === "NEW";
}

export function isSearchingStatusSearchSummary(searchSummary: SearchSummary | SearchSummaryNew | undefined): searchSummary is SearchSummaryNew {
    return searchSummary?.status === "SEARCHING";
}

export function getSearchSummariesIds(searchSummaries: SearchSummary[]): { searchIds: string[] } {
    const searchIds = searchSummaries.map((searchSummary) => {
        return searchSummary.id;
    });
    return { searchIds };
}

export function getSummaryErrorStatus(searchVersion: SearchVersion | SearchVersionNew | QuickSearch): { isErrored: boolean; errorCode?: string } {
    const searchRequestMessages = searchVersion.searchRequestMessages;

    if (!searchRequestMessages) {
        return { isErrored: false };
    }

    const isErrored = isErroredSearch(searchRequestMessages);
    const hasInvalidSearchQuery = searchRequestMessages.find((message) => message.validationErrors?.some((error) => error.messageCode === "VLD_INVALID_SEARCH_QUERY"));

    return { isErrored, errorCode: hasInvalidSearchQuery ? "VLD_INVALID_SEARCH_QUERY" : "UNEXPECTED" };
}
