import { QuickSearch } from "./QuickSearch";
import { RequestTerm, SearchStatus, SearchStatuses, SearchVersion, getSearchStatusDisplayValue, showSearchVersionNumber } from "./SearchVersion";
import { User } from "./User";
import { EntityDocument } from "./EntityDocument";

export type PriorSearch = {
    id: string;
    name: string;
    createdByUserId: string;
    requestedByUserId: string;
    description: string;
    createdOn: Date;
    lastModified: Date;
    requestTerms: string[];
    searchTerms: string[];
    version: number;
    /** This is the most recent message that a user provided when changing the status of the search */
    comments: string;
    status: SearchStatus;
};

export type PriorSearchEntity = EntityDocument & {
    entityType: "priorsearch";
    name: string;
    description: string;
    requestedByUserId: string;
    requestedByUserName: string;
    dateRequested: Date;
    lastModified: Date;
    status: string;
    /** This is the most recent message that a user provided when changing the status of the search */
    latestStatusComment: string;
    searchRequestTerms: RequestTerm[];
};

export function mapPriorSearchIndex(search: SearchVersion | QuickSearch): PriorSearch | undefined {
    //Excluding searching statuses so failed searches are not indexed.
    if (!search || search.isQuickSearch || !search.isLatestVersion || search.status === SearchStatuses.Draft || search.status === SearchStatuses.Searching) {
        return undefined;
    }
    return {
        id: search.searchId,
        name: search.name,
        createdByUserId: search.createdByUserId,
        requestedByUserId: search.requestedByUserId,
        description: search.description ?? "",
        createdOn: search.createdOn,
        lastModified: search.lastModified,
        requestTerms: search.requestTerms.map((t) => t.term),
        searchTerms: search.requestTerms.flatMap((t) => t.searchTerms),
        version: search.version,
        comments: search.statusNotificationMessage ?? "",
        status: search.status
    };
}

export function mapPriorSearchEntity(search: SearchVersion | QuickSearch, requestedByUser: User | null): PriorSearchEntity | undefined {
    //Including searching statuses as the only hits should be new versions which have already been successfully indexed previously.
    if (!search || search.isQuickSearch || !search.isLatestVersion || search.status === SearchStatuses.Draft) {
        return undefined;
    }
    return {
        id: search.searchId,
        entityType: "priorsearch",
        name: `${search.name}${showSearchVersionNumber(search)}`,
        description: search.description ?? "",
        requestedByUserId: requestedByUser?.id ?? search.requestedByUserId,
        requestedByUserName: requestedByUser?.name ?? "",
        dateRequested: search.createdOn,
        status: getSearchStatusDisplayValue(search.status),
        latestStatusComment: search.statusNotificationMessage ?? "",
        searchRequestTerms: search.requestTerms,
        lastModified: search.lastModified
    };
}
