import React, { ChangeEvent, useEffect, useState } from "react";
import { Divider, List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getApiKeys, getIsFirmSettingsPagePersisting, usePermissionsContext } from "state/selectors";
import { adminActions } from "state/actions";
import { Assignment } from "@mui/icons-material";
import { AddIcon, Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, InputText } from "@aderant/aderant-react-components";
import { Messages } from "./Messages";
import { conflictsPalette } from "styles/conflictsPalette";
import { ConflictsAction, FirmSettings, MAX_KEY_NAME_LENGTH } from "aderant-conflicts-models";
import { RootState } from "MyTypes";
import { DataLoadedState, isAllPageDataLoadedOrNotErrored } from "state/reducers/adminReducers";

export const ApiKeyComponent = (): JSX.Element => {
    const apiKeys = useSelector(getApiKeys);
    const dispatch = useDispatch();
    const [keyNameInput, setKeyNameInput] = useState("");
    const [isKeyGenerated, setIsKeyGenerated] = useState(false);
    const [focusedKey, setFocusedKey] = useState("");
    const [isGenerateKeyDialogOpen, setIsGenerateKeyDialogOpen] = useState(false);
    const [isRevokeKeyDialogOpen, setIsRevokeKeyDialogOpen] = useState(false);
    const [isRegenerateKeyDialogOpen, setIsRegenerateKeyDialogOpen] = useState(false);
    const [isRegeneratedKey, setIsRegeneratedKey] = useState(false);
    const permissionsContext = usePermissionsContext();
    const currentUserCanRevokeKeys = permissionsContext.currentUserHasPermission(ConflictsAction.RevokeApiKeys);
    const pageName = FirmSettings.ApiKeyManagementDefinition.name;
    const isDataLoaded: DataLoadedState[] = useSelector((rootState: RootState) => rootState.admin.isLoaded[pageName]);
    const isPersisting: boolean = useSelector((rootState: RootState) => getIsFirmSettingsPagePersisting(rootState, pageName));
    const maxKeys = 20;

    useEffect(() => {
        if (!apiKeys) {
            dispatch(adminActions.fetchApiKeys());
        }
    }, []);

    const handleRevokeKey = (uniqueKeyName: string) => {
        if (currentUserCanRevokeKeys && (isKeyGenerated || focusedKey)) {
            dispatch(adminActions.revokeApiKey({ uniqueKeyName: uniqueKeyName }));
            setFocusedKey("");
            setIsRevokeKeyDialogOpen(false);
            setIsKeyGenerated(false);
        }
    };

    const keyNameInputOnChange = (e: ChangeEvent, inputValue: string | undefined | null, reason: string | undefined) => {
        if (reason === "clear") {
            setKeyNameInput("");
        } else if (reason === "blur" && inputValue) {
            setKeyNameInput(inputValue && inputValue.trim());
        } else if (reason === "onChange" && inputValue) {
            setKeyNameInput(inputValue);
        }
    };

    const handleGenerateKey = () => {
        if (keyNameInput) {
            dispatch(adminActions.generateApiKey({ uniqueKeyName: keyNameInput }));
        }
    };

    const isKeyNotUnique = () => {
        if (apiKeys && apiKeys.keys) {
            return apiKeys.keys.some((existingKey) => existingKey.uniqueKeyName.toLowerCase() === keyNameInput.toLowerCase());
        }
    };

    const isAlphaNumericWithDashes = (): boolean => {
        const alphaNumericRegex = new RegExp("^[0-9a-zA-Z -]+$");
        return alphaNumericRegex.test(keyNameInput);
    };

    const handleRegenerateKey = (uniqueKeyName: string) => {
        dispatch(adminActions.regenerateApiKey({ uniqueKeyName: uniqueKeyName }));
        setIsRegeneratedKey(true);
        setIsKeyGenerated(true);
        setIsRegenerateKeyDialogOpen(false);
        setKeyNameInput(uniqueKeyName);
        setIsGenerateKeyDialogOpen(true);
    };

    const clearGeneratedApiKey = () => {
        dispatch(adminActions.clearGeneratedApiKey());
    };

    const handleCloseGenerateDialog = () => {
        if (!isPersisting) {
            setIsGenerateKeyDialogOpen(false);
            clearGeneratedApiKey();
            setKeyNameInput("");
            setIsKeyGenerated(false);
            setIsRegeneratedKey(false);
        }
    };

    if (isAllPageDataLoadedOrNotErrored(isDataLoaded) && apiKeys && apiKeys.keys) {
        return (
            <div>
                <List
                    style={{ padding: "0" }}
                    subheader={
                        <ListSubheader style={{ padding: "0", color: conflictsPalette.text.black }}>
                            <Divider />
                            <ListItem>
                                <Button
                                    disabled={apiKeys.keys.length >= maxKeys}
                                    iconButton
                                    startIcon={<AddIcon />}
                                    aria-label="Generate Key"
                                    aria-haspopup={"true"}
                                    color="secondary"
                                    rounded={true}
                                    size="small"
                                    onClick={() => setIsGenerateKeyDialogOpen(true)}
                                    style={{ marginLeft: "10px" }}
                                />
                                {focusedKey && <Divider style={{ marginLeft: "8px", borderRightWidth: "1px" }} flexItem orientation="vertical" />}
                                {focusedKey && (
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            text="Regenerate"
                                            aria-label="Regenerate"
                                            aria-haspopup={"true"}
                                            color="primary"
                                            rounded={false}
                                            size="small"
                                            onClick={() => setIsRegenerateKeyDialogOpen(true)}
                                            style={{ marginLeft: "10px" }}
                                        />
                                        <Button
                                            text="Revoke"
                                            aria-label="Revoke"
                                            aria-haspopup={"true"}
                                            color="secondary"
                                            rounded={false}
                                            size="small"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => setIsRevokeKeyDialogOpen(true)}
                                        />
                                    </div>
                                )}
                                <ListItemText
                                    style={{ textAlign: "right" }}
                                    disableTypography
                                    primary={
                                        <Typography fontSize="12px" style={{ fontWeight: 600 }}>
                                            Keys Generated
                                        </Typography>
                                    }
                                    secondary={<Typography fontSize="12px">{`${apiKeys.keys.length} of ${maxKeys}`}</Typography>}
                                />
                            </ListItem>
                            <Divider />
                        </ListSubheader>
                    }
                >
                    {apiKeys.keys.map((key) => (
                        <div key={key.uniqueKeyName}>
                            <ListItemButton divider onClick={() => setFocusedKey(key.uniqueKeyName)} style={{ width: "100%" }} selected={key.uniqueKeyName === focusedKey}>
                                <ListItemText
                                    style={{ marginLeft: "20px" }}
                                    disableTypography
                                    primary={
                                        <Typography display="inline" style={{ fontWeight: 600 }} fontSize="15px">
                                            {key.uniqueKeyName}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography display="inline" style={{ marginLeft: "6px", fontStyle: "italic" }} color={conflictsPalette.text.grey} fontSize="12px">
                                            (Generated)
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </div>
                    ))}
                </List>
                <Dialog open={isGenerateKeyDialogOpen} onClose={() => handleCloseGenerateDialog()}>
                    <DialogTitle closeButton onClose={() => handleCloseGenerateDialog()}>
                        <DialogHeader>{isRegeneratedKey ? Messages.REGENERATE_KEY_DIALOG_TITLE.getMessage() : Messages.GENERATE_KEY_DIALOG_TITLE.getMessage()}</DialogHeader>
                    </DialogTitle>
                    <DialogContent>
                        <List>
                            <ListItem style={{ padding: 0, margin: 0 }}>
                                <ListItemText style={{ padding: 0, margin: 0 }}>{Messages.GENERATE_KEY_DIALOG_DESCRIPTION.getMessage()}</ListItemText>
                            </ListItem>
                            <ListItem style={{ padding: 0, margin: 0 }}>
                                <ListItemText style={{ padding: 0, margin: 0 }}>{Messages.WINDOW_CLOSED_DESCRIPTION_TEXT.getMessage()}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <InputText
                                    label="Name"
                                    value={keyNameInput}
                                    required
                                    disabled={isKeyGenerated || isRegeneratedKey}
                                    requiredMessage="Enter a unique key name"
                                    errorMessage={
                                        keyNameInput.length > MAX_KEY_NAME_LENGTH
                                            ? "The entered name is too long "
                                            : !isAlphaNumericWithDashes()
                                            ? "Key must only contain letters, numbers, hyphens (-) and spaces ( )."
                                            : "The entered name already exists"
                                    }
                                    autofocus
                                    hasError={(isKeyNotUnique() || !isAlphaNumericWithDashes() || keyNameInput.length > MAX_KEY_NAME_LENGTH) && !isKeyGenerated && keyNameInput.length > 0}
                                    style={{ width: "100%" }}
                                    onChange={(e: ChangeEvent, inputValue: string | undefined | null, reason: string | undefined) => keyNameInputOnChange(e, inputValue, reason)}
                                />
                            </ListItem>
                            <ListItem>
                                <InputText multiLine label="Key" value={apiKeys.generatedApiKey ?? ""} style={{ width: "100%" }} disabled />
                                {apiKeys.generatedApiKey && (
                                    <Button
                                        aria-label="Copy to Clipboard"
                                        iconButton
                                        onClick={() => apiKeys.generatedApiKey && navigator.clipboard.writeText(apiKeys.generatedApiKey)}
                                        rounded
                                        style={{ marginBottom: "16px", position: "absolute", left: "91%" }}
                                        startIcon={<Assignment style={{ maxWidth: "20px", maxHeight: "20px", minWidth: "20px", minHeight: "20px" }} />}
                                    />
                                )}
                            </ListItem>
                            <ListItem style={{ padding: 0 }}>
                                <ListItemText style={{ textAlign: "right", margin: 0, padding: 0 }}>{Messages.GENERATE_KEY_DIALOG_FOOTER_TEXT.getMessage()}</ListItemText>
                            </ListItem>
                        </List>
                    </DialogContent>
                    <DialogFooter style={{ paddingTop: 0 }}>
                        <Button
                            text={Messages.GENERATE_KEY_DIALOG_CLOSE.getMessage()}
                            color="secondary"
                            onClick={() => {
                                handleCloseGenerateDialog();
                            }}
                            disabled={isPersisting}
                        />
                        <Button
                            text={Messages.GENERATE_KEY_DIALOG_GENERATE.getMessage()}
                            disabled={isKeyNotUnique() || isPersisting || isKeyGenerated || keyNameInput.length <= 0 || keyNameInput.length > MAX_KEY_NAME_LENGTH || !isAlphaNumericWithDashes()}
                            onClick={() => {
                                setIsKeyGenerated(true);
                                handleGenerateKey();
                            }}
                        />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    open={isRevokeKeyDialogOpen}
                    onClose={() => {
                        if (!isPersisting) setIsRevokeKeyDialogOpen(false);
                    }}
                >
                    <DialogTitle
                        closeButton
                        onClose={() => {
                            if (!isPersisting) setIsRevokeKeyDialogOpen(false);
                        }}
                    >
                        <DialogHeader>{Messages.REVOKE_KEY_DIALOG_TITLE.getMessage()}</DialogHeader>
                    </DialogTitle>
                    <DialogContent>{Messages.REVOKE_KEY_DIALOG_DESCRIPTION.getMessage()}</DialogContent>
                    <DialogFooter>
                        <Button
                            text="No"
                            color="secondary"
                            onClick={() => {
                                if (!isPersisting) setIsRevokeKeyDialogOpen(false);
                            }}
                        />
                        <Button
                            text="Yes"
                            onClick={() => {
                                handleRevokeKey(focusedKey);
                            }}
                        />
                    </DialogFooter>
                </Dialog>
                <Dialog open={isRegenerateKeyDialogOpen} onClose={() => setIsRegenerateKeyDialogOpen(false)}>
                    <DialogTitle closeButton onClose={() => setIsRegenerateKeyDialogOpen(false)}>
                        <DialogHeader>{Messages.REGENERATE_KEY_CONFIRMATION_DIALOG_TITLE.getMessage()}</DialogHeader>
                    </DialogTitle>
                    <DialogContent>{Messages.REGENERATE_KEY_CONFIRMATION_DIALOG_DESCRIPTION.getMessage()}</DialogContent>
                    <DialogFooter>
                        <Button
                            text="No"
                            color="secondary"
                            onClick={() => {
                                setIsRegenerateKeyDialogOpen(false);
                            }}
                        />
                        <Button
                            text="Yes"
                            onClick={() => {
                                handleRegenerateKey(focusedKey);
                            }}
                        />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    return <></>;
};
