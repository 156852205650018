export const ConflictsTenantBlobStorage = {
    Configuration: {
        containerName: "configuration",
        indexDefinition: "IndexDefinition.json",
        entityFlyoutDefinition: "EntityFlyoutDefinition.json",
        hitResultGridDefinition: "HitResultGridDefinition.json",
        rlsVisibleWhenSecuredRows: "RLSVisibleWhenSecuredRows.json"
    },
    CustomizationWip: {
        containerName: "customization-wip",
        indexDefinition: "configuration/IndexDefinition.json",
        entityFlyoutDefinition: "configuration/EntityFlyoutDefinition.json",
        hitResultGridDefinition: "configuration/HitResultGridDefinition.json"
    },
    FirmSettings: {
        containerName: "firm-settings"
    },
    Query: {
        containerName: "query"
    },
    Schemas: {
        containerName: "schemas"
    },
    Delta: {
        containerName: "delta"
    }
} as const;
