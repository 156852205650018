import { validate as uuidValidate, NIL as nullUuid } from "uuid";
import { SearchErrors } from "../..";
import { Result } from "../Errors/ConflictsError";
import { Messages } from "./Messages";

/**
 * @param propertyName Name of property being validate examples: searchId, versionId
 * @param id Value of guid to be validated
 */
export function validateGuidId(propertyName: string, id: string | undefined): Result<string, SearchErrors.InvalidId> {
    if (id === undefined || id === null) {
        return SearchErrors.invalidId(Messages.VLD_NULL_UNDEF_ID.messageCode, Messages.VLD_NULL_UNDEF_ID.getMessage(propertyName));
    }

    if (id.trim().length === 0) {
        return SearchErrors.invalidId(Messages.VLD_EMPTY_STRING_VALUE.messageCode, Messages.VLD_EMPTY_STRING_VALUE.getMessage(propertyName));
    }

    if (!uuidValidate(id)) {
        return SearchErrors.invalidId(Messages.VLD_INVALID_GUID_VALUE.messageCode, Messages.VLD_INVALID_GUID_VALUE.getMessage(propertyName));
    }

    if (id === nullUuid) {
        return SearchErrors.invalidId(Messages.VLD_EMPTY_GUID_VALUE.messageCode, Messages.VLD_EMPTY_GUID_VALUE.getMessage(propertyName));
    }

    return id;
}
