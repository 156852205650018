import { getStatusesCurrentUserCanChangeHitsTo, HitMessages, HitStatus, HitStatuses, PermissionsContext, SearchVersion } from "aderant-conflicts-models";
import { ComboBoxItem } from "@aderant/aderant-react-components";

const getHitStatusComboBoxItem = (status: HitStatus): ComboBoxItem<HitStatus> => {
    switch (status) {
        case HitStatuses.Unactioned:
            return {
                displayName: HitMessages.HIT_STATUS_UNACTIONED.getMessage(),
                value: HitStatuses.Unactioned
            };
        case HitStatuses.NoConflict:
            return {
                displayName: HitMessages.HIT_STATUS_NOCONFLICT.getMessage(),
                value: HitStatuses.NoConflict
            };
        case HitStatuses.NotApplicable:
            return {
                displayName: HitMessages.HIT_STATUS_NOTAPPLICABLE.getMessage(),
                value: HitStatuses.NotApplicable
            };
        case HitStatuses.InProgress:
            return {
                displayName: HitMessages.HIT_STATUS_INPROGRESS.getMessage(),
                value: HitStatuses.InProgress
            };
        case HitStatuses.WaiverRequired:
            return {
                displayName: HitMessages.HIT_STATUS_WAIVERREQUIRED.getMessage(),
                value: HitStatuses.WaiverRequired
            };
        case HitStatuses.SeekingWaiver:
            return {
                displayName: HitMessages.HIT_STATUS_SEEKINGWAIVER.getMessage(),
                value: HitStatuses.SeekingWaiver
            };
        case HitStatuses.WaiverOnFile:
            return {
                displayName: HitMessages.HIT_STATUS_WAIVERONFILE.getMessage(),
                value: HitStatuses.WaiverOnFile
            };
        case HitStatuses.Conflict:
            return {
                displayName: HitMessages.HIT_STATUS_CONFLICT.getMessage(),
                value: HitStatuses.Conflict
            };
        case HitStatuses.Confirm:
            return {
                displayName: HitMessages.HIT_STATUS_CONFIRM.getMessage(),
                value: HitStatuses.Confirm
            };
    }
};

export const getHitStatusDisplayName = (status: HitStatus): string => {
    return getHitStatusComboBoxItem(status).displayName;
};

export const hitStatusList = (permissions: PermissionsContext, searchVersion?: SearchVersion): ComboBoxItem<HitStatus>[] => {
    let result: HitStatus[] = Object.values(HitStatuses);
    if (searchVersion) {
        result = getStatusesCurrentUserCanChangeHitsTo(permissions, searchVersion);
    }
    return result.map((s) => getHitStatusComboBoxItem(s));
};
