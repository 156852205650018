/*=============================================
=            FieldName Enums                  =
=============================================*/

export enum ExpertClientFieldNameEnum {
    clientName = "Client Name (Code)",
    clientDescription = "Client Description",
    clientStatus = "Client Status",
    openDate = "Open Date",
    dateClosed = "Date Closed",
    responsibleEmployee = "Responsible Employee",
    assignedEmployee = "Assigned Employee",
    billingEmployee = "Billing Employee",
    clientType = "Client Type",
    lastUpdatedDateTime = "Last Updated Date Time"
}
export enum ExpertMatterFieldNameEnum {
    matterName = "Matter Name (Matter Code)",
    clientName = "Client (Client Code)",
    matterDescription = "Matter Description",
    matterComments = "Matter Comments",
    claimNumber = "Claim Number",
    matterStatus = "Matter Status",
    openDate = "Open Date",
    responsibleEmployee = "Responsible Employee",
    assignedEmployee = "Assigned Employee",
    billingEmployee = "Billing Employee",
    matterType = "Matter Type",
    lastBillDate = "Last Bill Date",
    lastTimeEntryDate = "Last Time Entry Date",
    lastReceiptDate = "Last Receipt Date",
    wipTotal = "WIP Total",
    arTotal = "Account Receivable Total",
    lifeToDateBilled = "Life to Date Billed",
    lastUpdatedDateTime = "Last Updated Date Time",
    numberOfMatters = "# of Matters"
}
export enum ExpertClientPartyFieldNameEnum {
    partyName = "Party Name",
    affiliation = "Affiliation",
    partyStatus = "Party Status",
    clientPartyStatus = "Status of Client Party",
    dateAdded = "Date Added",
    dateClosed = "Date Closed",
    conflictingParty = "Conflicting Party",
    conflictReason = "Conflict Reason"
}
export enum ExpertMatterPartyFieldNameEnum {
    partyName = "Party Name",
    affiliation = "Affiliation",
    partyStatus = "Party Status",
    statusOfMatterParty = "Status of Matter Party",
    dateAdded = "Date Added",
    dateClosed = "Date Closed",
    conflictingParty = "Conflicting Party",
    conflictReason = "Conflict Reason"
}
export enum ExpertVendorFieldNameEnum {
    vendorName = "Vendor Name",
    status = "Status",
    address = "Address",
    parentVendor = "Parent Vendor",
    lastUpdatedDateTime = "Last Updated Date and Time"
}
export enum ExpertEmployeeFieldNameEnum {
    employeeName = "Employee Name",
    profitCenter = "Profit Center",
    department = "Department",
    office = "Office",
    status = "Status",
    hireDate = "Hire Date",
    terminationDate = "Termination Date",
    emailAddress = "Email Address",
    lastUpdatedDateTime = "Last Updated Date and Time"
}

export enum ExpertPriorSearchFieldNameEnum {
    searchName = "Search Name",
    description = "Description",
    requestedBy = "Requested By",
    dateRequested = "Date Requested",
    lastActivityDate = "Last Activity Date",
    searchStatus = "Search Status",
    latestStatusComment = "Latest Status Comment"
}
/*=====  End of FieldName Enums  ======*/
