import { RequestTerm } from "aderant-conflicts-models";
import { DataGridColumnDefinition, Dialog, DialogContent, Tab, Tabs } from "@aderant/aderant-react-components";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RequestTermSummary } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";

import { RootState } from "MyTypes";
import { fetchRequestTermsGridColumns, RequestTermsGrid } from "./RequestTermsGrid";
import uuid from "uuid";
import { messages } from "pages/SearchEditPage/SearchUtils";
import { TrimmedHeader } from "./TrimmedHeader";

export interface RequestTermsDialogProps {
    open: boolean;
    onClose: () => void;
    requestTerms: RequestTerm[];
}


export const RequestTermsDialog = ({ open, onClose, requestTerms }: RequestTermsDialogProps) => {
    //Get Lookup lists
    const affiliationList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.affiliationList;
    });
    const partyStatusList = useSelector((rootstate: RootState) => {
        return rootstate.app.lookups.partyStatusList;
    });

    const requestTermsGridColumns: DataGridColumnDefinition<RequestTermSummary>[] = useMemo(() => fetchRequestTermsGridColumns(affiliationList, partyStatusList), [affiliationList, partyStatusList]);

    const requestTermsSummary: RequestTermSummary[] = requestTerms.map((t: RequestTerm): RequestTermSummary => {
        return {
            //The id for the request term should be defined at this point
            rowId: t.id ?? uuid(),
            term: t.term,
            affiliation: t.affiliation,
            partyStatus: t.partyStatus,
            searchModifiers: "",
            searchTerms: t.searchTerms
        };
    });

    return (
        <>
            <Dialog open={open} onClose={onClose} size="lg">
                <TrimmedHeader closeButton onClose={onClose}>
                    <div style={{ display: "flex", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}></div>
                </TrimmedHeader>
                {/* Override the standard dialog padding which is "30px 30px 12px" */}
                <DialogContent style={{ padding: "30px 30px 30px" }}>
                    <div style={{ height: "100%", display: "flex", flexDirection: "column", backgroundColor: conflictsPalette.background.white, border: `1px solid ${conflictsPalette.border}` }}>
                        <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", minHeight: "500px" }}>
                            <Tabs value={messages.requestTerms} centered>
                                <Tab
                                    label={messages.requestTerms}
                                    disabled
                                    {...{ id: `search-details-tab-request-terms`, "aria-controls": `search-details-tabpanel-request-terms`, value: `${messages.requestTerms}` }}
                                />
                            </Tabs>
                            <div style={{ height: "500px" }}>
                                <RequestTermsGrid columns={requestTermsGridColumns} requestTermsSummary={requestTermsSummary} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
