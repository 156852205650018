import { AderantTheme } from "@aderant/aderant-react-components";
import { conflictsPalette } from "styles/conflictsPalette";
interface StyleTypes {
    containerStyle: React.CSSProperties;
    labelStyle: React.CSSProperties;
    valueStyle: React.CSSProperties;
}
export const styles: StyleTypes = {
    containerStyle: {
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gridColumnGap: "19px",
        marginTop: AderantTheme.typography.pxToRem(7),
        alignItems: "baseline"
    },
    labelStyle: {
        color: conflictsPalette.text.disabled
    },
    valueStyle: {
        color: conflictsPalette.text.black
    }
};

export interface StyledFieldOverrides {
    containerStyle?: React.CSSProperties;
}
