import { searchReducer, SearchState } from "./searchReducers";
import * as MyTypes from "MyTypes";
import { SearchActionType } from "state/actions";

// Understanding checkReducer:
// This is used to ensure success responses from store APIs arriving out of order does not cause local state to be set to an out of date value.

// When search edit actions are initially created a 'currentSearchActionId' GUID is generated and persisted in local state before the saga starts.
// When the response from the store API comes back the saga dispatches a success/failure action which is handled by this reducer.
// The initial Search Action ID from the pre saga reducer action is compared to the one currently in state, if they are the same then the up to date result of the save is stored in local state.
// If they are not the same then local state is not modified.

// This ensures that when multiple search edits are performed by a user, the last edit will always be the one reflected in local state regardless of the order that the save actions complete remotely.


// e.g.
// User modifies search name. (Edit 1), currentSearchActionId gets set to xxxx-xxxx-xxxx-xxx1
// User adds terms. (Edit 2), currentSearchActionId gets set to xxxx-xxxx-xxxx-xxx2
// User modifies search name again (Edit 3), currentSearchActionId gets set to xxxx-xxxx-xxxx-xxx3
// ...
// Success result for Edit 1 comes back, currentActionId === xxxx-xxxx-xxxx-xxx3 but the action that triggered the saga for Edit 1 had an actionId of xxxx-xxxx-xxxx-xxx1 so state is not updated.
// Success result for Edit 3 (note out of order) comes back, currentActionId === xxxx-xxxx-xxxx-xxx3 which is the same as the action that triggered the Edit 3 saga, so the state *is* updated.
// Success result for Edit 2 comes back, currentActionId === xxxx-xxxx-xxxx-xxx3 but the action that triggered the saga for Edit 2 had an actionId of xxxx-xxxx-xxxx-xxx2 so state is not updated.


// End result: The data in state is the result of the users most recent edit action, even though the responses from the store API did not come back in order.

// You should note that this is a similar mechanism to etags in CosmosDB (and other DB/ORM systems) - those are a similar concept and function in basically the same way.



// Please note there could be issues with any API calls that only take in part of a search object and not the entire search object as the result of that save may not contain previous edits
export function checkActionId(state: SearchState, action: MyTypes.RootSearchAction): SearchState | "STOP" {
    switch (action.type) {
        case SearchActionType.ADD_SEARCH:
        case SearchActionType.ADD_SEARCH_COPY_FROM_ID: 
        case SearchActionType.UPDATE_HITS:
        case SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO:
        case SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS:
        case SearchActionType.UPDATE_SEARCH: {
            return {
                ...state,
                currentSearchActionId: action.payload.currentSearchActionId
            }
        }
        case SearchActionType.ADD_SEARCH_SUCCESS:
        case SearchActionType.ADD_SEARCH_COPY_FROM_ID_SUCCESS:    
        case SearchActionType.UPDATE_HITS_SUCCESS:
        case SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO_SUCCESS:
        case SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS_SUCCESS:
        case SearchActionType.UPDATE_SEARCH_SUCCESS: {
            if(action.payload.initialSearchActionId !== state.currentSearchActionId) {
                return "STOP";
            } else {
                return state;
            }
        }
        default: {
            return state
        }
    }
}

export function reducerWithCheck(check: (state: SearchState, action: MyTypes.RootSearchAction) => ReturnType<typeof searchReducer> | "STOP", reducer: typeof searchReducer): typeof searchReducer {
    return (state: SearchState | undefined, action: MyTypes.RootSearchAction) => {
        if (state === undefined) {
            return reducer(state, action);
        }
        const checkResult: SearchState | "STOP" = check(state, action);
        if (checkResult === "STOP") {
            return state;
        }
        return reducer(checkResult, action);
    }
}