import { FirmEntity, Region, Result, TenancyDetails, ValidationErrors } from "aderant-conflicts-models";
import { InputValidator } from "../../../inputValidation";

function validateTenancyInput(input: unknown): Result<TenancyDetails, ValidationErrors> {
    return InputValidator.validate(input)
        .stringFieldRequired("uniqueName")
        .stringFieldOptional("displayName")
        .stringFieldRequired("tenancyResourceGroupUniqueName")
        .stringFieldRequired("tenancyResourceGroupDisplayName")
        .build();
}

export function validateTenancyInputInObject(input: unknown): Result<{ tenancy: TenancyDetails }, ValidationErrors> {
    return InputValidator.validate(input).validateFieldRequired("tenancy", validateTenancyInput).build();
}

function validateRegionInput(input: unknown): Result<Region, ValidationErrors> {
    return InputValidator.validate(input).stringFieldRequired("resourceGroupName").stringFieldRequired("displayName").build();
}

export function validateFirmEntityInput(input: unknown): Result<FirmEntity, ValidationErrors> {
    return InputValidator.validate(input).validateFieldRequired("region", validateRegionInput).validateFieldRequired("tenancy", validateTenancyInput).build();
}
