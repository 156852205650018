import { HitStatus } from "aderant-conflicts-models";
import { assertUnreachable } from "aderant-web-fw-core";
import { conflictsPalette } from "styles/conflictsPalette";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getHitStatusColors = (status: HitStatus) => {
    switch (status) {
        case "CONFLICT": {
            const defaultColors = { backgroundColor: conflictsPalette.status.redLight, borderColor: conflictsPalette.status.red, color: conflictsPalette.text.black };
            const hover = { ...defaultColors, filter: "brightness(0.98)" };
            const clicked = { backgroundColor: defaultColors.borderColor, borderColor: defaultColors.borderColor, color: conflictsPalette.text.white };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
        case "INPROGRESS": {
            const defaultColors = { backgroundColor: conflictsPalette.status.blueLight, borderColor: conflictsPalette.status.blue, color: conflictsPalette.text.black };
            const hover = { ...defaultColors, filter: "brightness(0.98)" };
            const clicked = { backgroundColor: defaultColors.borderColor, borderColor: defaultColors.borderColor, color: conflictsPalette.text.white };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
        case "NOCONFLICT": {
            const defaultColors = { backgroundColor: conflictsPalette.status.greenLight, borderColor: conflictsPalette.status.green, color: conflictsPalette.text.black };
            const hover = { ...defaultColors, filter: "brightness(0.98)" };
            const clicked = { backgroundColor: defaultColors.borderColor, borderColor: defaultColors.borderColor, color: conflictsPalette.text.white };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
        case "NOTAPPLICABLE": {
            const defaultColors = { backgroundColor: conflictsPalette.status.grey2Light, borderColor: conflictsPalette.status.grey2, color: conflictsPalette.text.black };
            const hover = { ...defaultColors, filter: "brightness(0.98)" };
            const clicked = { backgroundColor: defaultColors.borderColor, borderColor: defaultColors.borderColor, color: conflictsPalette.text.white };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
        case "SEEKINGWAIVER": {
            const defaultColors = { backgroundColor: conflictsPalette.status.yellowLight, borderColor: conflictsPalette.status.yellow, color: conflictsPalette.text.black };
            const hover = { ...defaultColors, filter: "brightness(0.98)" };
            const clicked = { backgroundColor: defaultColors.borderColor, borderColor: defaultColors.borderColor, color: conflictsPalette.text.black };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
        case "WAIVERONFILE": {
            const defaultColors = { backgroundColor: conflictsPalette.status.green2Light, borderColor: conflictsPalette.status.green2, color: conflictsPalette.text.black };
            const hover = { ...defaultColors, filter: "brightness(0.98)" };
            const clicked = { backgroundColor: defaultColors.borderColor, borderColor: defaultColors.borderColor, color: conflictsPalette.text.white };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
        case "UNACTIONED": {
            const defaultColors = { backgroundColor: conflictsPalette.status.greyLight, borderColor: conflictsPalette.status.grey, color: conflictsPalette.text.black };
            const hover = { ...defaultColors, filter: "brightness(0.98)" };
            const clicked = { backgroundColor: defaultColors.borderColor, borderColor: defaultColors.borderColor, color: conflictsPalette.text.white };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
        case "WAIVERREQUIRED": {
            const defaultColors = { backgroundColor: conflictsPalette.status.orangeLight, borderColor: conflictsPalette.status.orange, color: conflictsPalette.text.black };
            const hover = { ...defaultColors, filter: "brightness(0.98)" };
            const clicked = { backgroundColor: defaultColors.borderColor, borderColor: defaultColors.borderColor, color: conflictsPalette.text.black };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
        case "CONFIRM": {
            const defaultColors = { backgroundColor: conflictsPalette.status.blue, borderColor: conflictsPalette.status.blue, color: conflictsPalette.text.white };
            const hover = { ...defaultColors, backgroundColor: conflictsPalette.status.blueLight, color: conflictsPalette.status.blue };
            const clicked = { backgroundColor: conflictsPalette.primary.Ahsoka, borderColor: conflictsPalette.primary.Ahsoka, color: conflictsPalette.text.white };
            return { default: defaultColors, clicked: clicked, hover: hover };
        }
    }
    throw assertUnreachable(status);
};
