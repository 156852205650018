import { BlobItem } from "@azure/storage-blob";
import { BlobStorageConnector } from "../BlobStorageConnector/BlobStorageConnector";

export async function getBlobStorageConnectorMock(): Promise<BlobStorageConnector> {
    const mock = await BlobStorageConnector.open("DefaultEndpointsProtocol=https;AccountName=myaccount;AccountKey=accountKey;EndpointSuffix=core.windows.net");

    mock.getBlob = jest.fn();
    mock.uploadDataAsBuffer = jest.fn();
    mock.getBlobContentAsBuffer = jest.fn();
    mock.getBlobContentWithEtag = jest.fn();
    mock.saveBlob = jest.fn();
    mock.streamToBuffer = jest.fn();
    mock.getBlobFileHeaders = jest.fn();
    mock.deleteBlob = jest.fn();

    return mock;
}

export const blobItem1Mock: BlobItem = {
    name: "blobItem1",
    deleted: false,
    snapshot: "XXX",
    properties: {
        lastModified: new Date(),
        etag: "etag1"
    }
};

export const blobItem2Mock: BlobItem = {
    name: "blobItem2",
    deleted: false,
    snapshot: "YYY",
    properties: {
        lastModified: new Date(),
        etag: "etag1"
    }
};
