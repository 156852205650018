import { EntityIdentifier, forbidden, ForeignEntityIdentifier, isForeignEntityIdentifier, LogContext, Message } from "aderant-conflicts-models";
import { MultiResponse } from "../../../Http/HttpResponse";
import { GetEntityDocumentsResponse, GetEntityDocumentsResults } from "../Surface";
import { EntityConnector } from "./EntityConnector";
import { ConflictsContext } from "../../../ConflictsContext";
import { HeaderBuilder } from "../../../Http/HeaderBuilder";

export const Messages = {
    ENTITYDOC_READ_DENIED: new Message("ENTITYDOC_READ_DENIED", "Current user does not have permission to access entity details")
};

export async function getEntityDocumentsWrapper(
    context: ConflictsContext | LogContext,
    input: (EntityIdentifier | ForeignEntityIdentifier)[],
    connector: EntityConnector,
    userCanGetDocuments: () => boolean
): Promise<GetEntityDocumentsResponse> {
    context.logger.info("Validating request");
    if (!userCanGetDocuments()) {
        return {
            status: 207,
            body: input.map((id) => {
                return {
                    id: id.toString(),
                    status: 403,
                    body: forbidden(Messages.ENTITYDOC_READ_DENIED.getMessage())
                };
            }),
            headers: new HeaderBuilder().addNoStoreCacheHeader().build()
        };
    }
    context.logger.info(`Fetching entity documents`);

    const fetchById: EntityIdentifier[] = [];
    const fetchByForeignKey: ForeignEntityIdentifier[] = [];
    input.forEach((id) => {
        if (isForeignEntityIdentifier(id)) {
            fetchByForeignKey.push(id);
        } else {
            fetchById.push(id);
        }
    });

    let results: GetEntityDocumentsResults = [];
    if (fetchById.length > 0) {
        results = await connector.getEntityDocuments(fetchById, context);
    }
    if (fetchByForeignKey.length > 0) {
        const foreignKeysByTypeAndField: Record<string, ForeignEntityIdentifier[]> = {};
        fetchByForeignKey.forEach((key) => {
            const typeAndField = key.entityType + key.idFieldName;
            if (foreignKeysByTypeAndField[typeAndField]) {
                foreignKeysByTypeAndField[typeAndField].push(key);
            } else {
                foreignKeysByTypeAndField[typeAndField] = [key];
            }
        });

        await Promise.all(
            Object.values(foreignKeysByTypeAndField).map(async (group) => {
                const entityType = group[0].entityType;
                const documents = await connector.getEntityDocumentsByCustomProperty(
                    group[0].idFieldName,
                    group.map((id) => id.id),
                    entityType
                );
                documents.forEach((document: any) => {
                    results.push({
                        id: document.id,
                        entityType: entityType,
                        status: 200,
                        body: { ...document }
                    });
                });
            })
        );
    }

    return MultiResponse.responseWithHeaders(results, new HeaderBuilder().addNoStoreCacheHeader().build());
}
