import { ConflictsAction, EntityRelationshipConfigurationTemplate, Forbidden, NotFound, ok, Result } from "aderant-conflicts-models";
import { ClientAppContext, FunctionAppContext } from "../../ConflictsContext";
import { EntityConfigurationService } from "./EntityConfigurationService";
import { HeaderBuilder } from "../../Http/HeaderBuilder";
import { OkResponseWithHeaders, okResponseWithHeaders } from "../../Http/HttpResponse";
import { validateInput } from "./inputValidator";
import { EntityConfiguration } from "./EntityConfiguration";
import { hasPermission } from "../hasPermission";
/**
 * This function retrieves the relationship configuration for a particular entity type.
 */
export async function getEntityRelationshipConfiguration(
    context: ClientAppContext | FunctionAppContext,
    input: { entityType: string },
    entityConfigurationService: EntityConfigurationService
): Promise<NotFound | Forbidden | OkResponseWithHeaders<EntityRelationshipConfigurationTemplate>> {
    context.logger.info("Validating get entity relationship configuration request");

    const permissionValidation = await hasPermission(context, ConflictsAction.ViewAndUpdateEntityRelationshipConfiguration, `view the ${EntityConfiguration.RelationshipDefinition}`);
    if (!ok(permissionValidation)) {
        return permissionValidation;
    }

    const inputValidation = validateInput(input);
    if (!ok(inputValidation)) {
        return inputValidation;
    }
    context.logger.info(`Retrieving entity relationship configuration for ${input.entityType}.`);
    //Fetch the relationship configuration template for the specified entity type
    const entityTemplate: Result<EntityRelationshipConfigurationTemplate, NotFound> = await entityConfigurationService.getEntityRelationshipConfigurationTemplate(input.entityType);
    if (!ok(entityTemplate)) {
        return entityTemplate;
    }

    const response = okResponseWithHeaders(entityTemplate, new HeaderBuilder().addCacheHeader(600).build());
    return response;
}
