import { Container, ContainerRequest, CosmosClient, ItemDefinition, ItemResponse, OperationInput, OperationResponse, PartitionKey, RequestOptions } from "@azure/cosmos";
import { CosmosSecrets, EtagMismatch, etagMismatch, Result, unexpectedError } from "aderant-conflicts-models";
import { Logger } from "aderant-web-fw-core";
import Bluebird from "bluebird";
export class CosmosConnector {
    protected constructor(protected logger: Logger) {}

    protected static createCosmosClient(logger: Logger, firmSettings: CosmosSecrets): CosmosClient {
        logger.info("Opening connector: Cosmos");
        try {
            if (!firmSettings || !firmSettings.endpoint || !firmSettings.key || !firmSettings.databaseId) {
                const missingVars = [];
                if (!firmSettings.endpoint) {
                    missingVars.push("endpoint");
                }
                if (!firmSettings.key) {
                    missingVars.push("key");
                }
                if (!firmSettings.databaseId) {
                    missingVars.push("databaseId");
                }
                throw new Error(`Failed to get all needed firm Azure settings.  Missing: ${missingVars.join(", ")}`);
            }
            return new CosmosClient({
                endpoint: firmSettings.endpoint,
                key: firmSettings.key,
                connectionPolicy: {
                    enableEndpointDiscovery: false
                }
            });
        } catch (e) {
            throw unexpectedError(e, "Unexpected error when opening Cosmos search connector.");
        }
    }

    protected static async getContainer(client: CosmosClient, CosmosCredentials: CosmosSecrets, containerRequest: ContainerRequest): Promise<Container> {
        await client.databases.createIfNotExists({
            id: CosmosCredentials.databaseId
        });

        const response = await client.database(CosmosCredentials.databaseId).containers.createIfNotExists(containerRequest);

        return response && response.container;
    }

    protected wrapWithUnexpectedErrorAndThrow(e: any): never {
        throw unexpectedError(e, "Unexpected error encountered in Cosmos Connector.");
    }

    /**
     * This takes in an array and divides it into a list of smaller chunks.
     * @param array The array that needs to be divided into chunks.
     * @param chunkSize Defines the number of items that need to be in each chunk.
     */
    protected chunkArray<T>(array: T[], chunkSize: number): T[][] {
        const allChunks: T[][] = [];
        for (let i = 0, j = array.length; i < j; i += chunkSize) {
            allChunks.push(array.slice(i, i + chunkSize));
        }
        return allChunks;
    }

    /**
     * Sends bulk operations with no operations limit.
     * This calls items.bulk in chunks of 100 asynchronously and returns them in the same order the requests are passed in.
     * Use this method instead of items.bulk to accomodate for operations > 100 (azure/cosmos bulk api has a limit of 100 operations per bulk request).
     * @param requests An array of Operations which are typed based on what the operation does. The choices are: Create, Upsert, Read, Replace, and Delete
     */
    protected async sendBulkOperation<T extends OperationInput>(requests: T[], container: Container, options?: RequestOptions): Promise<OperationResponse[]> {
        //Divide operations into chunks of 100
        //justification: items.bulk has a limit of 100 operations
        const chunks: T[][] = this.chunkArray<T>(requests, 100);

        //Await all Bulk Replace requests
        //Promise all should return the promises in the same order they are passed in (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all)
        //There is an implicit expectation that the returned responses from items.bulk is returned in the same order as the requests based on @azure/cosmos unit tests here: https://github.com/Azure/azure-sdk-for-js/blob/master/sdk/cosmosdb/cosmos/test/public/functional/item.spec.ts
        const allResponses: OperationResponse[][] = await Bluebird.Promise.map(chunks, async (searchesChunk: T[]) => await container.items.bulk(searchesChunk, { continueOnError: true }, options), {
            concurrency: 1
        });
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const responses: OperationResponse[] = ([] as OperationResponse[]).concat(...allResponses);
        return responses;
    }

    /**
     * Replaces a cosmos document only if the _etag provided matches the current _etag of the document it's replacing.
     * @param container - cosmos container the replace will be called in
     * @param partitionKey - partition key of container
     * @param id - id of document to replace
     * @param body - document to replace with
     * @param _etag - entity tag used for concurrency control
     * @param options - request options (see {@link RequestOptions})
     * @returns
     */
    protected async cosmosReplaceWithEtag<T extends ItemDefinition>(
        container: Container,
        partitionKey: PartitionKey,
        id: string,
        body: T,
        _etag: string,
        options?: RequestOptions
    ): Promise<Result<ItemResponse<ItemDefinition>, EtagMismatch>> {
        try {
            const optionsWithEtag: RequestOptions = { ...options, accessCondition: { type: "IfMatch", condition: _etag } };
            const response = await container.item(id, partitionKey).replace(body, optionsWithEtag);
            return response;
        } catch (e: any) {
            if (e.code === 412) {
                return etagMismatch();
            }
            throw e;
        }
    }
}
