import { APIs, MultiResponse } from "aderant-conflicts-common";
import {
    Audit,
    CreateHitCommentInput,
    createSearchCopy,
    DurableHitIdentifier,
    EditHitCommentInput,
    EtagMismatch,
    Forbidden,
    Hit,
    HitComment,
    HitCommentCosmosId,
    HitIdentifier,
    makeEditable,
    NotFound,
    ok,
    QuickSearch,
    RequestTerm,
    Result,
    SearchErrors,
    SearchMassEditFields,
    SearchStatus,
    SearchStatuses,
    SearchSummary,
    SearchVersion,
    SearchVersionEdited,
    SearchVersionIdentifier,
    SearchVersionNew,
    SearchVersionUnedited,
    ValidationErrors
} from "aderant-conflicts-models";
import { InvalidSearchType } from "aderant-conflicts-models/dist/Validation/Search/Errors";
import { Logger } from "aderant-web-fw-core";

export type SearchUpdateDelta = { assignedToUserId?: string | null; status?: SearchStatus; reassignMessage?: string; statusNotificationMessage?: string };

export class SearchStorageService {
    private service: APIs.SearchStoreProxy;
    private logger: Logger;

    constructor(logger: Logger, searchStoreProxy: APIs.SearchStoreProxy) {
        this.logger = logger;
        this.service = searchStoreProxy;
    }

    async getSearchSummaries(): Promise<Result<SearchSummary[], SearchErrors.InvalidId>> {
        const result: Result<SearchSummary[], SearchErrors.InvalidId> = await this.service.getSearchSummaries();
        return result;
    }

    async getSearchSummariesById(payload: { searchIds: string[] }): Promise<Result<APIs.GetSearchSummariesByIdResults, SearchErrors.InvalidId | ValidationErrors>> {
        const result: Result<APIs.GetSearchSummariesByIdResults, SearchErrors.InvalidId | ValidationErrors> = await this.service.getSearchSummariesBySearchId(payload);
        return result;
    }

    async getLatestSearchVersion(payload: {
        searchId: string;
        fetchHitResults: boolean;
    }): Promise<Result<SearchVersionUnedited | QuickSearch, NotFound | SearchErrors.InvalidId | SearchErrors.UserCannotViewSearch>> {
        const result: Result<SearchVersionUnedited | QuickSearch, NotFound | SearchErrors.InvalidId | SearchErrors.UserCannotViewSearch> = await this.service.getLatestSearchVersion(
            payload,
            undefined
        );
        if (ok(result)) {
            return result;
        } else {
            const errorResult: NotFound | SearchErrors.InvalidId | SearchErrors.UserCannotViewSearch = result;
            return errorResult;
        }
    }

    async addSearch(searchVersion: SearchVersionNew): Promise<Result<SearchVersionUnedited, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>> {
        return await this.service.saveSearch({ searchVersion });
    }

    async copySearch(searchVersion: SearchVersion | SearchVersionNew, currentUserId: string, searchId: string): Promise<SearchVersionUnedited | SearchVersionNew> {
        const searchCopy = createSearchCopy(searchVersion, searchId, currentUserId);
        const result = await this.addSearch(searchCopy);
        if (ok(result)) {
            return result;
        } else {
            this.logger.debug("Persisting copied search failed", result); //This is expected behavior when the user is offline.
            return searchCopy;
        }
    }

    async updateUnperformedSearch(searchVersion: QuickSearch, reassignMessage?: string): Promise<Result<QuickSearch, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>>;
    async updateUnperformedSearch(
        searchVersion: SearchVersion,
        reassignMessage?: string
    ): Promise<Result<SearchVersionUnedited, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>>;
    async updateUnperformedSearch(
        searchVersion: SearchVersion | QuickSearch,
        reassignMessage?: string
    ): Promise<Result<SearchVersionUnedited | QuickSearch, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>>;
    async updateUnperformedSearch(
        searchVersion: SearchVersion | QuickSearch,
        reassignMessage?: string
    ): Promise<Result<SearchVersionUnedited | QuickSearch, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>> {
        if (searchVersion.isQuickSearch) {
            throw "Not yet implemented";
        }
        if (searchVersion.editState === "CURRENT") {
            console.warn("Skipping save on search as it is not marked as edited");
            return searchVersion; //search hasn't actually been edited
        }
        return await this.service.saveSearch({ searchVersion, reassignMessage: reassignMessage });
    }

    async submitSearch(searchVersion: SearchVersion): Promise<Result<SearchVersionUnedited, APIs.SearchNewVersionErrors | EtagMismatch | SearchErrors.InvalidSearchType>> {
        searchVersion = makeEditable(searchVersion);
        searchVersion.submittedDate = new Date();
        searchVersion.status = SearchStatuses.Submitted;

        if (searchVersion.version >= 1) {
            searchVersion.assignedToUserId = undefined;
            return await this.service.createNewSearchVersion({ searchVersion });
        } else {
            return await this.service.saveSearch({ searchVersion });
        }
    }

    async createNewVersion(searchVersion: SearchVersionEdited): Promise<Result<SearchVersionUnedited, APIs.SearchNewVersionErrors>> {
        searchVersion.submittedDate = new Date();
        searchVersion.status = SearchStatuses.Submitted;
        return await this.service.createNewSearchVersion({ searchVersion });
    }

    private async addOrUpdateSearch(
        searchVersion: SearchVersion | SearchVersionNew
    ): Promise<Result<SearchVersionUnedited, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>> {
        if (searchVersion.editState === "NEW") {
            return await this.addSearch(searchVersion);
        } else {
            return await this.updateUnperformedSearch(searchVersion);
        }
    }

    async deleteSearches(searchVersionIdentifiers: SearchVersionIdentifier[]): Promise<Result<APIs.SearchesDeleteResults, ValidationErrors | SearchErrors.InvalidId | SearchErrors.InvalidStatus>> {
        return await this.service.deleteSearches({ searchVersionIdentifiers: searchVersionIdentifiers });
    }

    async updateHits(
        payload: APIs.WithEtag<SearchVersionIdentifier & { hitIds: HitIdentifier[]; change: Partial<Hit> }>
    ): Promise<
        Result<
            SearchVersionUnedited,
            ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidId | SearchErrors.NotAssignedTo | SearchErrors.NotLatestSearchVersion | SearchErrors.InvalidSearchType
        >
    > {
        return await this.service.updateHits(payload);
    }

    async updateSearchesAssignedTo(payload: {
        searchVersionIdentifiers: APIs.WithEtag<SearchVersionIdentifier>[];
        change: Pick<SearchVersion, "assignedToUserId">;
    }): Promise<Result<APIs.SearchesUpdateResults, ValidationErrors>> {
        return await this.service.updateSearches(payload);
    }

    async updateSearchesSearchStatus(payload: {
        searchVersionIdentifiers: APIs.WithEtag<SearchVersionIdentifier>[];
        change: Pick<SearchVersion, "status">;
    }): Promise<Result<APIs.SearchesUpdateResults, ValidationErrors>> {
        return await this.service.updateSearches(payload);
    }

    async cleanUpSearchingSearches(payload: {
        searchVersionIdentifiers: APIs.WithEtag<SearchVersionIdentifier>[];
        previousStatus: Pick<SearchVersion, "status">;
        skipUnlessOlderThanHours: number;
    }): Promise<Result<APIs.SearchesUpdateResults, ValidationErrors>> {
        return await this.service.updateSearches({ searchVersionIdentifiers: payload.searchVersionIdentifiers, change: payload.previousStatus }, undefined, { skipUnlessOlderThanHours: 4 });
    }

    async updateSearch(payload: {
        searchVersionIdentifier: SearchVersionIdentifier;
        _etag: SearchVersion["_etag"];
        change: SearchUpdateDelta;
    }): Promise<
        Result<
            MultiResponse.Item<
                APIs.WithEtag<SearchMassEditFields>,
                200,
                | NotFound
                | EtagMismatch
                | SearchErrors.InvalidId
                | SearchErrors.NotLatestSearchVersion
                | SearchErrors.NotAssignedTo
                | SearchErrors.InvalidStatusChange
                | SearchErrors.InvalidReassign
                | MultiResponse.UnexpectedError
            >,
            ValidationErrors
        >
    > {
        const { searchVersionIdentifier, _etag, ...others } = payload;
        const inputArg = { ...others, searchVersionIdentifiers: [{ ...searchVersionIdentifier, _etag: _etag }] };
        const result = await this.service.updateSearches(inputArg);
        if (ok(result)) {
            return result[0];
        } else {
            const errorResult: ValidationErrors = result;
            return errorResult;
        }
    }

    async addTerm(
        searchVersion: SearchVersion | SearchVersionNew,
        term: RequestTerm
    ): Promise<Result<SearchVersionUnedited, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>> {
        searchVersion = makeEditable(searchVersion);
        searchVersion.requestTerms.push(term);
        return await this.addOrUpdateSearch(searchVersion);
    }

    async addTerms(
        searchVersion: SearchVersion | SearchVersionNew,
        terms: RequestTerm[]
    ): Promise<Result<SearchVersionUnedited, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>> {
        searchVersion = makeEditable(searchVersion);
        terms.forEach((term) => {
            searchVersion.requestTerms.push(term);
        });
        return await this.addOrUpdateSearch(searchVersion);
    }

    async updateTerm(
        searchVersion: SearchVersion | SearchVersionNew,
        term: RequestTerm
    ): Promise<Result<SearchVersionUnedited, ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType>> {
        searchVersion = makeEditable(searchVersion);
        const index = searchVersion.requestTerms.findIndex((t) => t.id === term.id);
        searchVersion.requestTerms[index] = term;
        return await this.addOrUpdateSearch(searchVersion);
    }

    async deleteTerm(
        searchVersion: SearchVersion | SearchVersionNew,
        termId: string
    ): Promise<Result<SearchVersionUnedited, NotFound | ValidationErrors | EtagMismatch | SearchErrors.InvalidSearchType>> {
        searchVersion = makeEditable(searchVersion);
        const index = searchVersion.requestTerms.findIndex((t) => t.id === termId);
        searchVersion.requestTerms.splice(index, 1);
        return await this.addOrUpdateSearch(searchVersion);
    }

    async getAudits(searchId: string): Promise<Result<Audit[], NotFound | SearchErrors.InvalidId>> {
        return await this.service.getAudits({ searchId: searchId });
    }

    async createQuickSearch(quickSearch: QuickSearch): Promise<Result<QuickSearch, SearchErrors.InvalidSearchType | NotFound | EtagMismatch | Forbidden>> {
        return await this.service.createQuickSearch({ quickSearch: quickSearch });
    }

    async saveQuickSearch(quickSearch: QuickSearch): Promise<Result<QuickSearch, ValidationErrors | NotFound | EtagMismatch | InvalidSearchType>> {
        return await this.service.saveQuickSearch({ quickSearch });
    }

    async convertQuickSearch(payload: {
        quickSearchId: string;
        newSearchId: string;
    }): Promise<Result<APIs.ConvertQuickSearchResults, ValidationErrors | NotFound | Forbidden | SearchErrors.InvalidId | SearchErrors.InvalidSearchType>> {
        return await this.service.convertQuickSearch(payload);
    }
    async getHitComments(payload: { searchId: string; hitIdentifier: DurableHitIdentifier }): Promise<Result<HitComment[], Forbidden | ValidationErrors>> {
        return await this.service.getHitComments(payload);
    }
    async createHitComment(payload: { hitComment: CreateHitCommentInput }): Promise<Result<HitComment, Forbidden | ValidationErrors | NotFound>> {
        return await this.service.createHitComment(payload);
    }
    async editHitComment(payload: { editHitCommentInput: EditHitCommentInput }): Promise<Result<HitComment, Forbidden | ValidationErrors | NotFound>> {
        return await this.service.editHitComment(payload.editHitCommentInput);
    }
    async deleteHitComment(payload: { hitCommentCosmosId: HitCommentCosmosId }): Promise<Result<undefined, Forbidden | ValidationErrors | NotFound>> {
        return await this.service.deleteHitComment(payload.hitCommentCosmosId);
    }
}
