import { PermissionsContextDirect } from "aderant-conflicts-models";
import { Logger } from "aderant-web-fw-core";
import { NoopAppInsights } from "AppInsights/AppInsightsWeb";
export function createUninitializedPermissionsContext(logger: Logger): PermissionsContextDirect {
    return {
        logger: logger,
        currentUserId: "",
        currentUserHasPermission(_action) {
            logger.error("Permissions context accessed before user login - returning false (disallow) to all queries.");
            return false;
        },
        currentUserHasAnyPermission(_actions) {
            logger.error("Permissions context accessed before user login - returning false (disallow) to all queries.");
            return false;
        },
        currentUserHasAllPermissions(_actions) {
            logger.error("Permissions context accessed before user login - returning false (disallow) to all queries.");
            return false;
        },
        currentUserIsAderantUser() {
            logger.error("Permissions context accessed before user login - returning false (disallow) to all queries.");
            return false;
        },
        appInsightsClient: new NoopAppInsights(logger)
    };
}
