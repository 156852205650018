import { Button } from "@aderant/aderant-react-components";
import Excel from "exceljs";
import { messages } from "pages/SearchEditPage/SearchUtils";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { conflictsPalette } from "styles/conflictsPalette";
import { FileProps, FilePropsHandler, WorkbookHandler } from "./ImportTermsModal";

const dropzoneStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: `${conflictsPalette.border}`,
    borderStyle: "dashed",
    backgroundColor: `${conflictsPalette.background.white}`,
    color: `${conflictsPalette.text.black}`,
    outline: "none"
};

const dropzoneActiveStyle: CSSProperties = {
    ...dropzoneStyle,
    opacity: "40%"
};

type ImportProps = {
    onFileImported: WorkbookHandler;
    clearFile: boolean;
    fileProps: FilePropsHandler;
};

export function ImportTermsDropzone({ onFileImported, clearFile = false, fileProps }: ImportProps) {
    const emptyFileProps = { name: "", progress: 0 };
    const [fileProperties, setFileProperties] = useState<FileProps>(emptyFileProps);
    const [clearFileProperties, setClearFileProperties] = useState(false);

    useEffect(() => {
        if (clearFile && fileProperties.name.length) {
            fileProps(emptyFileProps);
            setFileProperties(emptyFileProps);
            setClearFileProperties(true);
        }
    }, [clearFile, fileProperties.name]);

    // If the callback completes after the user has cleared the file, this will stop the data being passed to the Modal.
    useEffect(() => {
        !clearFileProperties && fileProps(fileProperties);
    }, [fileProperties]);

    const wb = new Excel.Workbook();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setClearFileProperties(false);
        acceptedFiles.forEach((file: File) => {
            fileProps({ name: file.name, progress: 0 });
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onprogress = (value) => {
                //Make the file upload account for 80% of the progress bar. The other 20% should be added when the workbook has completed processing.
                setFileProperties({ name: file.name, progress: (value.loaded / value.total) * 80 });
            };
            reader.onload = () => {
                if (reader.result) {
                    let buffer = reader.result;
                    if (typeof buffer === "string") {
                        buffer = Buffer.from(buffer, "utf-8");
                    }
                    wb.xlsx
                        .load(buffer)
                        .then((workbook) => {
                            setFileProperties({ name: file.name, progress: 100 });
                            onFileImported({ type: "Workbook", data: workbook, duplicateTerms: [] });
                        })
                        .catch((error: string) => {
                            setFileProperties({ name: file.name, progress: 100 });
                            onFileImported({ type: "WorkbookError", data: { errorMessages: [error], title: file.name } });
                        });
                }
            };
            reader.readAsArrayBuffer(file);
        });
    }, []);

    const { getRootProps, getInputProps, isDragReject, isDragActive } = useDropzone({ onDrop, accept: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [] }, maxFiles: 1 });

    return (
        <div style={{ background: conflictsPalette.background.white }}>
            <div style={isDragActive ? dropzoneActiveStyle : dropzoneStyle} {...getRootProps()}>
                <input {...getInputProps()} aria-label="dropzoneInput" />
                {fileProperties.name.length ? (
                    <p>{fileProperties.name}</p>
                ) : (
                    <>
                        <p>Drag a file here or</p>
                        <Button text={messages.uploadFile} size="medium" style={{ marginBottom: "20px" }} />
                    </>
                )}
                {isDragReject && <>Only a single xlsx file is supported</>}
            </div>
        </div>
    );
}
