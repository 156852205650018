import { ConflictsAction, GridColumnConfiguration, GridConfiguration, PermissionsContextDirect, Subscription, User } from "aderant-conflicts-models";
import { RootState } from "MyTypes";
import { shallowEqual, useSelector } from "react-redux";
import { AppState, GridIds } from "state/reducers/appReducers";
import { createSelector } from "reselect";

export const hasUsers = (state: AppState): number | boolean => state.users?.length ?? 0 > 0;
export const getAllUsers = (state: RootState): User[] | undefined => state.app.users;

export const getUser = (state: RootState, userId: string): User | undefined => getAllUsers(state)?.find((s) => s.id === userId);

export const getPermissionsContext = (state: RootState): PermissionsContextDirect => state.app.permissionsContext;
export const usePermissionsContext = (): PermissionsContextDirect => useSelector(getPermissionsContext);

export const getCurrentUser: (state: RootState) => User | undefined = createSelector([getPermissionsContext, getAllUsers], (permissionsContext, users) => {
    return users?.find((u) => u.id === permissionsContext.currentUserId);
});

export const getSelectedSubscription = (state: RootState): Subscription | undefined => state.app.loggedInUser?.tenancy.subscription;

export const getIsQuickSearchEnabled = createSelector([getPermissionsContext], (permissions) => {
    return permissions.currentUserHasPermission(ConflictsAction.ViewAndPerformQuickSearches);
});

export const getUserName = (userId: string | undefined, users: User[]): string | undefined => {
    if (userId) {
        const user = users.find((u) => u.id === userId);
        return user?.name ?? userId;
    }

    return userId;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getGridPreferences = (gridId: string) => (state: RootState) => {
    return (
        state.app.grids[gridId]?.preferences || {
            id: gridId,
            columnPreferences: []
        }
    );
};

export const gridConfigurationEqualityFunction = (left: GridConfiguration | undefined, right: GridConfiguration | undefined) => {
    if (left === undefined && right === undefined) {
        return true;
    }
    if (left === undefined || right === undefined) {
        return false;
    }
    return left.etag === right.etag && left.configurations.every((col: GridColumnConfiguration, index: number) => shallowEqual(col, right.configurations[index]));
};

export const getHitResultGridDefinition = (rootstate: RootState): GridConfiguration | undefined => {
    return rootstate.app.grids[GridIds.resultsPage].configuration;
};
