import { ValidationContext, ValidationMessage } from "../Validation/Errors/BasicErrors";
import { ConflictsError } from "../Validation/Errors/ConflictsError";
import { EmailMessages } from "./EmailMessages";

export interface EmailValidationContext extends ValidationContext {
    type: string;
    id: string | number;
    emailTo?: string;
}

export interface InvalidEmailMessage extends ValidationMessage, ConflictsError<400, "INVALID_EMAIL"> {
    httpStatusCode: 400;
    message: string;
    context?: EmailValidationContext;
}

export interface EmailValidationMessage extends ValidationMessage, ConflictsError<400, "INVALID_EMAIL"> {
    httpStatusCode: 400;
    message: string;
}

export interface EmailValidationErrors extends ConflictsError<400, "INVALID_EMAIL"> {
    httpStatusCode: 400;
    message: string;
    messageCode: string;
    errors: ConflictsError[];
}

export function noToEmailAddress(validationContext: EmailValidationContext | undefined): InvalidEmailMessage {
    return {
        _conflictserrortype: "INVALID_EMAIL",
        httpStatusCode: 400,
        messageCode: EmailMessages.VLD_EMAIL_NO_TO_ADDRESS.messageCode,
        message: EmailMessages.VLD_EMAIL_NO_TO_ADDRESS.getMessage(),
        context: validationContext
    };
}

export function toEmailAddressNotFound(validationContext: EmailValidationContext | undefined): InvalidEmailMessage {
    return {
        _conflictserrortype: "INVALID_EMAIL",
        httpStatusCode: 400,
        messageCode: EmailMessages.VLD_EMAIL_TO_ADDRESS_NOT_FOUND.messageCode,
        message: EmailMessages.VLD_EMAIL_TO_ADDRESS_NOT_FOUND.getMessage(validationContext?.emailTo ?? "unknown"),
        context: validationContext
    };
}

export function invalidEmails(errors?: ConflictsError[]): EmailValidationErrors {
    return {
        _conflictserrortype: "INVALID_EMAIL",
        httpStatusCode: 400,
        messageCode: EmailMessages.VLD_INVALID_EMAIL.messageCode,
        message: EmailMessages.VLD_INVALID_EMAIL.getMessage(),
        errors: errors ?? []
    };
}
