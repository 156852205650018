import {
    DiagnosticInformation,
    EntityFlyoutConfigurationTemplate,
    EntityFlyoutConfigurationTemplates,
    EtagMismatch,
    Forbidden,
    GridConfiguration,
    IntegrationStatus,
    NotFound,
    ValidationErrors,
    FirmSettings,
    EditableFieldValue,
    TenancyWithoutId
} from "aderant-conflicts-models";

import { AzureKeyAuthFunctionDefinition, AzureFunctionDefinition } from "../../AzureFunctionDefinition";
import { getFunctionAppUrl } from "../../Config/EnvironmentService";
import { TokenAuthContext, UserlessKeyAuthFunctionAppContext } from "../../ConflictsContext";
import { OkResponse, EmptyOkResponse, MultiResponse, OkCreatedResponseWithHeaders, OkResponseWithHeaders } from "../../Http/HttpResponse";
import { KeyLimitReached, KeyNameNotUnique, KeyNameTooLong } from "./Errors";

//seperate but identical named types here just for clarity + communicating that there's no actual
//requirement for them to all be the same, they just happen to be at the moment.
export type CreateKeyInput = { uniqueKeyName: string };
export type RevokeKeyInput = CreateKeyInput;
export type RegenerateKeyInput = CreateKeyInput;

export type GetKeysResponse = {
    keys: { uniqueKeyName: string }[];
};

type GetFirmSettingsByFieldPathsResponse = MultiResponse.Response<
    {
        value: EditableFieldValue;
    },
    200,
    NotFound
>;

export const Surface = {
    getEntityFlyoutConfiguration: new AzureFunctionDefinition<{ entityType: string }, EntityFlyoutConfigurationTemplate, OkResponse<EntityFlyoutConfigurationTemplate>, Forbidden | NotFound>({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND", "ACCESS_DENIED"],
        getUrlEnd: (input) => `./api/EntityFlyoutConfigurations/${input.entityType}`
    }),
    getEntityFlyoutConfigurations: new AzureFunctionDefinition<undefined, EntityFlyoutConfigurationTemplates, OkResponse<EntityFlyoutConfigurationTemplates>, Forbidden>({
        httpVerb: "GET",
        expectedErrors: ["ACCESS_DENIED"],
        getUrlEnd: () => `./api/EntityFlyoutConfigurations/`
    }),
    updateEntityFlyoutConfigurations: new AzureFunctionDefinition<EntityFlyoutConfigurationTemplates, undefined, EmptyOkResponse, ValidationErrors | EtagMismatch | Forbidden>({
        httpVerb: "PUT",
        expectedErrors: ["VALIDATION", "ETAG_MISMATCH", "ACCESS_DENIED"],
        getUrlEnd: () => `./api/EntityFlyoutConfigurations/`
    }),
    getHitResultGridConfiguration: new AzureFunctionDefinition<undefined, GridConfiguration, OkResponse<GridConfiguration>, Forbidden>({
        httpVerb: "GET",
        expectedErrors: ["ACCESS_DENIED"],
        getUrlEnd: () => `./api/HitResultGridConfiguration/`
    }),
    getFirmSettingsByFieldPaths: new AzureFunctionDefinition<{ fieldPaths: FirmSettings.FieldPath[] }, GetFirmSettingsByFieldPathsResponse, OkResponse<GetFirmSettingsByFieldPathsResponse>, never>({
        httpVerb: "POST",
        expectedErrors: [],
        getUrlEnd: () => `./api/FirmSettings/`
    }),
    getFirmSettingsPageData: new AzureFunctionDefinition<{ pageName: FirmSettings.PageDefinitionName }, Record<string, Record<string, any>>, OkResponse<Record<string, Record<string, any>>>, NotFound>(
        {
            httpVerb: "GET",
            expectedErrors: ["NOT_FOUND"],
            getUrlEnd: (input) => `./api/FirmSettings/${input.pageName}/`
        }
    ),
    getVisibleWhenSecureColumns: new AzureFunctionDefinition<undefined, string[], OkResponse<string[]>, Forbidden>({
        httpVerb: "GET",
        expectedErrors: ["ACCESS_DENIED"],
        getUrlEnd: () => `./api/RLSConfiguration/VisibleWhenSecureColumns/`
    }),
    saveFirmSettingsPageData: new AzureFunctionDefinition<
        { pageName: string; pageData: Record<string, Record<string, any>> },
        Record<string, Record<string, any>> & { etag: string },
        OkResponse<Record<string, Record<string, any>> & { etag: string }>,
        Forbidden | NotFound
    >({
        httpVerb: "PUT",
        expectedErrors: ["ACCESS_DENIED"],
        getUrlEnd: (input) => `./api/FirmSettings/${input.pageName}/`
    }),
    saveVisibleWhenSecureColumns: new AzureFunctionDefinition<string[], string[], OkResponse<string[]>, Forbidden>({
        httpVerb: "POST",
        expectedErrors: ["ACCESS_DENIED"],
        getUrlEnd: () => `./api/RLSConfiguration/VisibleWhenSecureColumns`
    }),
    getHealth: new AzureKeyAuthFunctionDefinition<
        undefined, //Input
        DiagnosticInformation, //Output
        OkResponse<DiagnosticInformation>, //Output with Http Status type
        never //possible error types
    >({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: () => "./api/Health"
    }),
    createKey: new AzureFunctionDefinition<CreateKeyInput, string, OkCreatedResponseWithHeaders<string>, KeyNameNotUnique | KeyNameTooLong | KeyLimitReached | ValidationErrors | Forbidden>({
        httpVerb: "POST",
        expectedErrors: ["VLD_KEY_LIMIT_REACHED", "VLD_STRING_TOO_LONG", "VLD_KEYNAME_NOT_UNIQUE", "VALIDATION"],
        getUrlEnd: () => "./api/ConflictsAPIAdmin/Keys"
    }),
    revokeKey: new AzureFunctionDefinition<RevokeKeyInput, undefined, EmptyOkResponse, NotFound | Forbidden>({
        httpVerb: "DELETE",
        expectedErrors: ["NOT_FOUND"],
        getUrlEnd: (input: RevokeKeyInput) => `./api/ConflictsAPIAdmin/Keys/${input.uniqueKeyName}`
    }),
    regenerateKey: new AzureFunctionDefinition<RegenerateKeyInput, string, OkResponseWithHeaders<string>, NotFound | Forbidden>({
        httpVerb: "POST",
        expectedErrors: ["NOT_FOUND"],
        getUrlEnd: (input: RegenerateKeyInput) => `./api/ConflictsAPIAdmin/Keys/${input.uniqueKeyName}/Regenerate`
    }),
    getKeys: new AzureFunctionDefinition<undefined, GetKeysResponse, OkResponse<GetKeysResponse>, Forbidden>({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: () => "./api/ConflictsAPIAdmin/Keys"
    }),
    getIngestionPipelineStatus: new AzureFunctionDefinition<undefined, IntegrationStatus[], OkResponse<IntegrationStatus[]>, NotFound | Forbidden | ValidationErrors>({
        httpVerb: "GET",
        expectedErrors: ["ACCESS_DENIED"],
        getUrlEnd: () => `./api/IngestionPipelineStatus/`
    }),
    keyAuthFunctions: {
        validateCustomizationWip: new AzureKeyAuthFunctionDefinition<{ tenancyName: string }, undefined, EmptyOkResponse, ValidationErrors>({
            httpVerb: "POST",
            expectedErrors: ["VALIDATION"],
            getUrlEnd: () => `./api/KeyAuth/CustomizationWip/Validate`
        }),
        getIngestionPipelineStatus: new AzureKeyAuthFunctionDefinition<{ tenancy: TenancyWithoutId }, IntegrationStatus[], OkResponse<IntegrationStatus[]>, NotFound | Forbidden | ValidationErrors>({
            httpVerb: "GET",
            expectedErrors: ["ACCESS_DENIED"],
            getUrlEnd: () => `./api/KeyAuth/IngestionPipelineStatus/`
        })
    }
};

export type AdminProxy = ReturnType<typeof getAdminApiProxy>;
//justification: this return type is necessarily defined by getProxy - to explicitly define it in the function signature
//would be unwieldy
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getAdminApiProxy(context: TokenAuthContext) {
    const baseUrl: URL = getFunctionAppUrl("AdminApi");

    return {
        apiKeyManagement: {
            /**Create a new key with the given name for the current tenant, the name will need
             * to be supplied alongside subsequent requests to revoke/regenerate the key.
             *
             * @returns either a raw string containing the key or
             * one of the possible error types:
             * - @type {KeyNameTooLong}: The supplied key name was longer than the limit (currently 20).
             * - @type {KeyLimitReached}: The current tenant has reached their max key limit (currently 20),
             *   they need to revoke a key before creating a new one.
             * - @type {KeyNameNotUnique}: The provided name is not unique amongst keys for the current tenant.
             * - @type {ValidationErrors}: Formatting errors with the input - incorrect types, missing values etc.
             *
             * The key length/count limits are in effect because keys are stored as one secret
             * per tenant, and this has a size limit of 25k bytes. Limiting to 20 secrets per
             * tenant with a name length limit of 20 chars gives us some leeway in case we
             * need to store more metadata alongside keys in the future (the hard max would be
             * about 125 keys per tenant).
             *
             * Note that this response is the only time Conflicts will give you the key as it
             * is only stored hashed on our backend. End users that consume them are expected
             * to store each key in the third party integration that uses it, and regenerate
             * if they lose it.
             */
            createKey: Surface.createKey.getProxy(baseUrl, context),

            /**Revokes the key with the provided name, it will no longer be usable for Conflicts API access after calling this. */
            revokeKey: Surface.revokeKey.getProxy(baseUrl, context),

            /**Revokes the existing key with the provided id, and generates a new one under the same id.
             * Integrations that were using the old key will need to be updated to use the new one.
             * (Intended use case is to have a one step process for revoking and recreating a key being used
             * for a specific integration)
             *
             * @returns the key as a raw string.
             */
            regenerateKey: Surface.regenerateKey.getProxy(baseUrl, context),

            /**Gets metadata for all keys for the current tenant
             *
             * @returns @type {GetKeysResponse}
             *
             * Note that this does not return the actual values of the keys. This is
             * intentional; keys are only stored and compared as hashed values on the backend.
             * End users that consume them are expected to store each key in the third party
             * integration that uses it, and regenerate if they lose it.
             */
            getKeyMetadata: Surface.getKeys.getProxy(baseUrl, context)
        },
        customization: {
            getEntityFlyoutConfiguration: Surface.getEntityFlyoutConfiguration.getProxy(baseUrl, context),
            //getEntityFlyoutConfigurations is set up differently here to make it clear to the client that we require no input params.
            getEntityFlyoutConfigurations: async () => {
                return Surface.getEntityFlyoutConfigurations.getProxy(baseUrl, context)(undefined);
            },
            updateEntityFlyoutConfigurations: Surface.updateEntityFlyoutConfigurations.getProxy(baseUrl, context),
            getHitResultGridConfiguration: Surface.getHitResultGridConfiguration.getProxy(baseUrl, context)
        },
        monitor: {
            getIngestionPipelineStatus: async () => {
                //getIngestionPipelineStatus is set up differently here to make it clear to the client that we require no input params.
                return Surface.getIngestionPipelineStatus.getProxy(baseUrl, context)(undefined);
            }
        },
        firmSettings: {
            getFirmSettingsByFieldPaths: Surface.getFirmSettingsByFieldPaths.getProxy(baseUrl, context),
            getFirmSettingsPageData: Surface.getFirmSettingsPageData.getProxy(baseUrl, context),
            getVisibleWhenSecureColumns: Surface.getVisibleWhenSecureColumns.getProxy(baseUrl, context),
            saveFirmSettingsPageData: Surface.saveFirmSettingsPageData.getProxy(baseUrl, context),
            saveVisibleWhenSecureColumns: Surface.saveVisibleWhenSecureColumns.getProxy(baseUrl, context)
        }
    };
}

export type AdminKeyAuthProxy = Awaited<ReturnType<typeof getAdminApiKeyAuthProxy>>;

export async function getAdminApiKeyAuthProxy(context: UserlessKeyAuthFunctionAppContext) {
    const baseURL: URL = getFunctionAppUrl("AdminApi");
    const hostKey = await context.getSharedFunctionHostKey("AdminApi");

    return {
        //getIngestionPipelineStatus is set up differently here to make it clear to the client that we require no input params.
        keyAuthGetIngestionPipelineStatus: Surface.keyAuthFunctions.getIngestionPipelineStatus.getKeyAuthProxyForMonitoring(baseURL, context.logger, hostKey),
        getHealth: Surface.getHealth.getKeyAuthProxyForMonitoring(baseURL, context.logger, hostKey)
    };
}
