import React from "react";
import { Button, MessageDialog } from "@aderant/aderant-react-components";
import { appActions } from "state/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "MyTypes";

const messages = {
    error: "Error"
};

function ErrorDialog(): JSX.Element {
    const dispatch = useDispatch();
    const isErrorDialogOpen = useSelector((rootState: RootState) => {
        return rootState.app?.isErrorDialogOpen;
    });
    const errorMessage = useSelector((rootState: RootState) => {
        return rootState.app?.errorMessage;
    });

    return (
        <MessageDialog
            open={isErrorDialogOpen}
            onClose={() => dispatch(appActions.hideError())}
            title={messages.error}
            footer={<Button text="Close" onClick={() => dispatch(appActions.hideError())} size="medium" />}
            message={errorMessage}
        />
    );
}

export default ErrorDialog;
