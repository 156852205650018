export const targetTenancyHeaderKey = "x-aderant-target-tenancyuniquename";
export const targetEnvironmentHeaderKey = "x-aderant-target-environmentuniquename";
export type TargetEnvironmentHeaders = {
    [targetTenancyHeaderKey]: string;
    [targetEnvironmentHeaderKey]?: string;
};
export type TargetEnvironment = { tenancyUniqueName: string; environmentUniqueName?: string };
export function extractTargetEnvironmentFromHeaders(headers: Record<string, string | string[] | undefined>): TargetEnvironment | undefined {
    const targetTenancy = headers[targetTenancyHeaderKey];
    if (!targetTenancy) {
        return undefined;
    }
    const targetEnvironment = headers[targetEnvironmentHeaderKey];
    if (Array.isArray(targetEnvironment) || Array.isArray(targetTenancy)) {
        return undefined;
    }
    return {
        tenancyUniqueName: targetTenancy,
        environmentUniqueName: targetEnvironment
    };
}
