import { Message } from "./Validation/Message";

export const HitMessages = {
    HIT_STATUS_NOCONFLICT: new Message("HIT_STATUS_NOCONFLICT", "No Conflict"),
    HIT_STATUS_NOTAPPLICABLE: new Message("HIT_STATUS_NOT_APPLICABLE", "Not Applicable"),
    HIT_STATUS_SEEKINGWAIVER: new Message("HIT_STATUS_SEEKINGWAIVER", "Seeking Waiver"),
    HIT_STATUS_CONFLICT: new Message("HIT_STATUS_CONFLICT", "Conflict"),
    HIT_STATUS_UNACTIONED: new Message("HIT_STATUS_UNACTIONED", "Unactioned"),
    HIT_STATUS_INPROGRESS: new Message("HIT_STATUS_INPROGRESS", "In Progress"),
    HIT_STATUS_WAIVERREQUIRED: new Message("HIT_STATUS_WAIVERREQUIRED", "Waiver Required"),
    HIT_STATUS_WAIVERONFILE: new Message("HIT_STATUS_WAIVERONFILE", "Waiver On File"),
    HIT_STATUS_CONFIRM: new Message("HIT_STATUS_CONFIRM", "Confirm")
};
