import React from "react";

export const CommentCountIcon = ({count, isSelected}): JSX.Element | undefined => {
    if (!count) {
        return undefined;
    }
    const x: string = count < 10 ? "8px" : count < 100 ? "5px" : "2px";
    const countDisplay: string = count < 100 ? count.toString() : "99+";
    return (
        <svg width="23" height="17" viewBox="0 0 23 17" fill="none" aria-label="Comment Count Icon" xmlns="http://www.w3.org/2000/svg">
            <rect width="22.103" height="16.9108" rx="4" fill={isSelected ? "#5F7286":"#1379CE" }/>
            <text x={x} y="13px" fontSize="0.8em" fill="#FFFFFF" >{countDisplay}</text>
        </svg>
    );
};