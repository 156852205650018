import { Message } from "aderant-conflicts-models";
import { Messages as HitCountTilesMessages } from "../HitCountTiles/Messages";

export const Messages = {
    TOGGLE_REFINER: new Message("TOGGLE_REFINER", "Refine"),
    CREATED_BY: new Message("CREATED_BY", "Created by"),
    REQUESTED_BY: new Message("REQUESTED_BY", "Requested by"),
    ASSIGNED_TO: new Message("ASSIGNED_TO", "Assigned to"),
    CREATED_BY_ME: new Message("CREATED_BY_ME", "Created by Me"),
    REQUESTED_BY_ME: new Message("REQUESTED_BY_ME", "Requested by Me"),
    ASSIGNED_TO_ME: new Message("ASSIGNED_TO_ME", "Assigned to Me"),
    COLUMN_PREFERENCES: new Message("COLUMN_PREFERENCES", "Column Preferences"),

    SECONDARY_TEXT_HIT_UNRESOLVED: new Message("SECONDARY_TEXT_HIT_UNRESOLVED", `(${HitCountTilesMessages.UNRESOLVED.getMessage()})`)
};
