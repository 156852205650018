import { Lookup } from "aderant-conflicts-models";

export const getAffiliationDisplayName = (affiliationCode: string, affiliationList: Lookup[]): string => {
    const affiliationIndex = affiliationList.findIndex((affiliation) => affiliation.value === affiliationCode);
    if (affiliationIndex === -1) {
        return affiliationCode;
    } else {
        return affiliationList[affiliationIndex].label;
    }
};

export const getPartyStatusDisplayName = (partyStatusCode: string, partyStatusList: Lookup[]): string => {
    const partyStatusIndex = partyStatusList.findIndex((partyStatus) => partyStatus.value === partyStatusCode);
    if (partyStatusIndex === -1) {
        return partyStatusCode;
    } else {
        return partyStatusList[partyStatusIndex].label;
    }
};
