import { AllLookups } from "../../Lookup";
import { RequestTerm } from "../../SearchVersion";
import { ValidationMessage } from "../Errors";
import { messages } from "./Messages";

export function validateLookups(lookups: AllLookups, validationErrors: ValidationMessage[], term?: RequestTerm, existingTerm?: RequestTerm) {
    validateAffiliation(lookups, validationErrors, term, existingTerm);
    validatePartyStatus(lookups, validationErrors, term, existingTerm);
}

function validateAffiliation(lookups: AllLookups, validationErrors: ValidationMessage[], term?: RequestTerm, existingTerm?: RequestTerm) {
    if (term?.affiliation) {
        if (existingTerm?.affiliation) {
            if (term.affiliation !== existingTerm.affiliation) {
                if (!lookups.affiliationList.some((affiliation) => affiliation.value === term.affiliation)) {
                    validationErrors.push(messages.VLD_LOOKUP_CODE.asValidationMessage("Affiliation", term.affiliation));
                }
            }
        } else {
            if (!lookups.affiliationList.some((affiliation) => affiliation.value === term.affiliation)) {
                validationErrors.push(messages.VLD_LOOKUP_CODE.asValidationMessage("Affiliation", term.affiliation));
            }
        }
    }
}

function validatePartyStatus(lookups: AllLookups, validationErrors: ValidationMessage[], term?: RequestTerm, existingTerm?: RequestTerm) {
    if (term?.partyStatus) {
        if (existingTerm?.partyStatus) {
            if (term.partyStatus !== existingTerm.partyStatus) {
                if (!lookups.partyStatusList.some((partyStatus) => partyStatus.value === term.partyStatus)) {
                    validationErrors.push(messages.VLD_LOOKUP_CODE.asValidationMessage("Party Status", term.partyStatus));
                }
            }
        } else {
            if (!lookups.partyStatusList.some((partyStatus) => partyStatus.value === term.partyStatus)) {
                validationErrors.push(messages.VLD_LOOKUP_CODE.asValidationMessage("Party Status", term.partyStatus));
            }
        }
    }
}
