import { ConflictsError, QueueMessage, StatusCodes } from "aderant-conflicts-models";
import { QueueMessages } from "./AzureQueueMessages";

export interface QueueValidationContext {
    id: string;
    queueName: string;
    requestingUserId: string;
    requestingUniqueTenancyName: string;
}

export interface InvalidQueueMessage extends ConflictsError<StatusCodes.Failure, "INVALID_QUEUE_MESSAGE"> {
    messageCode: string;
    message: string;
    context?: QueueValidationContext;
}

export function messageTooBig(queueMessage: QueueMessage<unknown>, queueName: string, messageSize: number): InvalidQueueMessage {
    return {
        _conflictserrortype: "INVALID_QUEUE_MESSAGE",
        httpStatusCode: 400,
        context: {
            id: queueMessage.id,
            queueName: queueName,
            requestingUserId: queueMessage.requestingUserId,
            requestingUniqueTenancyName: queueMessage.requestingUniqueTenancyName
        },
        messageCode: QueueMessages.VLD_MESSAGE_TOO_BIG.messageCode,
        message: QueueMessages.VLD_MESSAGE_TOO_BIG.getMessage(queueMessage.id, queueName, messageSize)
    };
}
