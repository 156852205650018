import { Accordion } from "@aderant/aderant-react-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "state/actions";
import { getExpertIngestionStatuses, getIsFirmSettingsPageFetching } from "state/selectors";
import { IntegrationSummary } from "./components/IntegrationSummary";
import { IntegrationTable } from "./components/IntegrationTable";
import { RootState } from "MyTypes";
import { FirmSettings } from "aderant-conflicts-models";

export function ExpertIntegrationSection () {
    const dispatch = useDispatch();
    const ingestionStatuses = useSelector(getExpertIngestionStatuses);
    const pageName = FirmSettings.IntegrationDefinition.name;
    const isPageFetching: boolean = useSelector((rootState: RootState) => getIsFirmSettingsPageFetching(rootState, pageName));
    //Currently there is only one data source - For Expert - When we have more data sources, we will have to test for each data source.
    //Then each data source should have it's own dataToLoad name and you should hide that Accordian component for that data source, if it didn't load
    const isDataLoaded = useSelector((rootState: RootState) => rootState.admin.isLoaded[pageName]);   
    console.log(JSON.stringify(isDataLoaded, null, 2));
    const isExpertDataLoaded : boolean = useSelector((rootState: RootState) => rootState.admin.isLoaded[pageName].find(d => d.dataToLoad === "INGESTION_STATUS")?.isLoaded ?? false);   

    useEffect(() => {
        //Refetch statuses everytime we open the tab
        if (!isPageFetching){
            dispatch(adminActions.fetchIngestionStatus());
        }
    }, [])

    return (
        isExpertDataLoaded && ingestionStatuses.length ?
            <div>
                <Accordion
                    title={"Expert"}
                    defaultExpanded={true}
                    details= {<IntegrationTable ingestionStatuses={ingestionStatuses}/>}
                    summary={<IntegrationSummary ingestionStatuses={ingestionStatuses}/>}
                />
            </div>
        : <></>
    )
}

