import {
    ConflictsTenantBlobStorage,
    ConnectionContext,
    CurrentUserContext,
    EtagMismatch,
    LogContext,
    Result,
    UserlessConnectionContext,
    VisibleWhenSecureFieldConfiguration
} from "aderant-conflicts-models";
import { BlobStorageConnector } from "../../BlobStorageConnector/BlobStorageConnector";
import { ConfigurationService } from "../ConfigurationService";

/**
 * This is a class that retrieves and saves a grid configuration for the specified grid
 * At this stage only caters for the Hit Results grid - but could be expanded
 */
export class RowLevelSecurityConfigurationService {
    private static ConfigurationCache: Record<string, VisibleWhenSecureFieldConfiguration> = {}; //Object where property names are tenancy unique name, and property values are the VisibleWhenSecureFieldConfiguration for the tenancy.
    private static ConfigurationCacheLastUpdated: Record<string, number> = {}; //Object where property names are tenancy unique name, and property values are DateTime the SecureFieldsConfigurationCache was last updated for that tenancy.
    private static configService: ConfigurationService<string> = new ConfigurationService<string>(
        {
            ConfigurationCache: RowLevelSecurityConfigurationService.ConfigurationCache,
            ConfigurationCacheLastUpdated: RowLevelSecurityConfigurationService.ConfigurationCacheLastUpdated
        },
        []
    );
    private static readonly BlobName: string = ConflictsTenantBlobStorage.Configuration.rlsVisibleWhenSecuredRows;
    private static readonly ContainerName: string = ConflictsTenantBlobStorage.Configuration.containerName;

    /**
     * Retrieves 'visible when secure columns' configuration for the hit result grid, for the tenancy of the current user.
     * @param context: the Current Log Context
     * @param tenancyName:  The unique tenancy name for the tenant.  This is a separate param because we can call this from a userless key auth function
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param cacheAge Optional - Default value is 60000 (10 minutes). Will fetch configuration from cache if cache age is less than this, else will fetch from blob storage.
     */
    async getVisibleWhenSecureColumns(context: LogContext, tenancyName: string, blobConnector: BlobStorageConnector): Promise<VisibleWhenSecureFieldConfiguration> {
        return RowLevelSecurityConfigurationService.configService.getConfiguration(
            context,
            tenancyName,
            blobConnector,
            RowLevelSecurityConfigurationService.BlobName,
            RowLevelSecurityConfigurationService.ContainerName,
            2000 // cache should expire after 2 seconds. We want any changes to be reflected immediately
        );
    }

    /**
     * Updates the 'visible when secure columns' configuration for the hit result grid
     * @param context: the Current User Context
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param visibleWhenSecureFieldConfiguration an object to be saved to blob storage, containing { configurations: an array of the property names of the columns that should be visible on secured rows, and an optional etag },
     */
    public async updateVisibleWhenSecureColumns(
        context: CurrentUserContext,
        blobConnector: BlobStorageConnector,
        visibleWhenSecureFieldConfiguration: VisibleWhenSecureFieldConfiguration
    ): Promise<Result<undefined, EtagMismatch>> {
        return RowLevelSecurityConfigurationService.configService.updateConfiguration(
            context,
            blobConnector,
            visibleWhenSecureFieldConfiguration,
            RowLevelSecurityConfigurationService.BlobName,
            RowLevelSecurityConfigurationService.ContainerName
        );
    }

    /**
     * Retrieves the connector to the blob storage account for the applicable tenant. This is used to retrive the configuration from blob storage.
     * @param context: the Current Log Context
     * @param tenancyName:  The unique tenancy name for the tenant.  This is a separate param because we can call this from a userless key auth functio
     */
    async getBlobConnector(context: ConnectionContext | UserlessConnectionContext, tenancyName: string): Promise<BlobStorageConnector> {
        return await BlobStorageConnector.open((await context.getBlobStorageSecrets(tenancyName)).connectionString);
    }
}
