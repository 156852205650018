import { Message } from "aderant-conflicts-models";

export const messages = {
    VLD_KEY_LIMIT_REACHED: new Message<[limit: number], "VLD_KEY_LIMIT_REACHED">(
        "VLD_KEY_LIMIT_REACHED",
        "You have reached the max number of Conflicts API keys ({0}), revoke an existing key before creating a new one."
    ),
    VLD_KEYNAME_NOT_UNIQUE: new Message<[name: string], "VLD_KEYNAME_NOT_UNIQUE">(
        "VLD_KEYNAME_NOT_UNIQUE",
        "You already have a key with the name {0}, either revoke that key or use a new name for this one."
    )
};
