/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Result } from "aderant-conflicts-models";

/**
 * Tests failure scenarios for the specified required string field.
 */
export function testFailForRequiredStringField<T extends Record<string, any>, E>(fieldName: string, validInput: T, validationFunction: (input: unknown) => Result<T | E>): void {
    test.each([
        [fieldName, "is an empty string", { ...validInput, [fieldName]: "" } as T, `Input ${fieldName} is null, undefined or empty.`],
        [fieldName, "is undefined", { ...validInput, [fieldName]: undefined } as unknown as T, `Input ${fieldName} is null or undefined.`],
        [fieldName, "is not a string", { ...validInput, [fieldName]: 1 } as unknown as T, `Expected input ${fieldName} to be a string.`],
        [fieldName, "is string with only spaces", { ...validInput, [fieldName]: "            " } as T, `Input ${fieldName} is null, undefined or empty.`]
    ])("should return error when %s %s", (_fieldName: string, _scenario: string, objectToValidate: T, errorMessage: string) => {
        const result = validationFunction({ ...objectToValidate });
        expect(result).toMatchObject({
            errors: [
                {
                    message: errorMessage
                }
            ]
        });
    });
}

/**
 * Tests the failure scenarios for a required string field that is nested in another object.
 */
export function testFailForNestedRequiredStringField<T extends Record<string, any>, E>(
    objectFieldName: string,
    nestedFieldName: string,
    validInput: T,
    validationFunction: (input: unknown) => Result<T | E>
): void {
    test.each([
        [
            nestedFieldName,
            `in ${objectFieldName}`,
            "is an empty string",
            { ...validInput, [objectFieldName]: { ...validInput[objectFieldName], [nestedFieldName]: "" } } as T,
            `Input ${nestedFieldName} is null, undefined or empty.`
        ],
        [
            nestedFieldName,
            `in ${objectFieldName}`,
            "is undefined",
            { ...validInput, [objectFieldName]: { ...validInput[objectFieldName], [nestedFieldName]: undefined } } as unknown as T,
            `Input ${nestedFieldName} is null or undefined.`
        ],
        [
            nestedFieldName,
            `in ${objectFieldName}`,
            "is not a string",
            { ...validInput, [objectFieldName]: { ...validInput[objectFieldName], [nestedFieldName]: 1 } } as unknown as T,
            `Expected input ${nestedFieldName} to be a string.`
        ],
        [
            nestedFieldName,
            `in ${objectFieldName}`,
            "is string with only spaces",
            { ...validInput, [objectFieldName]: { ...validInput[objectFieldName], [nestedFieldName]: "            " } } as T,
            `Input ${nestedFieldName} is null, undefined or empty.`
        ]
    ])("should return error when %s %s", (_fieldName: string, _objectName: string, _scenario: string, objectToValidate: T, errorMessage: string) => {
        const result = validationFunction({ ...objectToValidate });
        expect(result).toMatchObject({
            errors: [
                {
                    message: errorMessage
                }
            ]
        });
    });
}

/**
 * Tests success scenarios for an input object with all fields being required string fields.
 */
export function testValidForAllRequiredStringFields<T extends Record<string, any>, E>(validInput: T, validationFunction: (input: unknown) => Result<T | E>): void {
    test("should return ok when all fields are valid", () => {
        const result = validationFunction(validInput);
        expect(result).toMatchObject(validInput);
    });
    test("should return ok when all fields are valid even with additional spaces", () => {
        const validInputWithSpaces: T = Object.entries(validInput)
            .map(([key, value]) => [key, `   ${value}      `])
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as T;
        const result = validationFunction(validInputWithSpaces);
        expect(result).toMatchObject(validInput);
    });
}

/**
 * Tests success scenarios for an input object with all fields being required string fields and specified fields that are objects.
 */
export function testValidForAllRequiredStringFieldsAndSpecifiedObjects<T extends Record<string, any>, E>(
    validInput: T,
    validationFunction: (input: unknown) => Result<T | E>,
    objectFields: string[]
): void {
    test("should return ok when all fields are valid", () => {
        const result = validationFunction(validInput);
        expect(result).toMatchObject(validInput);
    });
    test("should return ok when all fields are valid even with additional spaces", () => {
        const validInputWithSpaces: T = Object.entries(validInput)
            .map(([key, value]) => {
                if (objectFields.includes(key)) {
                    const nestedUpdatedValue = Object.entries(value)
                        .map(([nestedKey, nestedValue]) => [nestedKey, `   ${nestedValue}      `])
                        .reduce((nestedAcc, [nestedKey, nestedValue]) => ({ ...nestedAcc, [nestedKey]: nestedValue }), {});
                    return [key, nestedUpdatedValue] as any;
                } else {
                    return [key, `   ${value}      `];
                }
            })
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as T;
        const result = validationFunction(validInputWithSpaces);
        expect(result).toMatchObject(validInput);
    });
}
