import { Lookup, RequestTerm, SearchMessages } from "aderant-conflicts-models";
import { DataGrid, DataGridColumnDefinition } from "@aderant/aderant-react-components";
import { getAffiliationDisplayName, getPartyStatusDisplayName } from "dataProviders/lookupList";
import React from "react";
import { GridIds } from "state/reducers/appReducers";
import { RequestTermSummary } from "../../state/selectors/";

const rowMetadata = {
    getRowId: (rowData: RequestTermSummary) => rowData.rowId
};

export const fetchRequestTermsGridColumns = (affiliationList: Lookup[], partyStatusList: Lookup[]): DataGridColumnDefinition<RequestTermSummary>[] => {
    return [
        {
            columnName: SearchMessages.REQUEST_TERM.getMessage(),
            allowSort: false,
            path: (originalRow: RequestTermSummary) => originalRow.term,
            id: "requestTerms",
            width: 300
        },
        {
            columnName: SearchMessages.AFFILIATION.getMessage(),
            id: "affiliation",
            path: (originalRow: RequestTermSummary) => getAffiliationDisplayName(originalRow.affiliation || "", affiliationList),
            allowSort: false,
            width: 300
        },
        {
            columnName: SearchMessages.PARTY_STATUS.getMessage(),
            id: "partyStatus",
            path: (originalRow: RequestTermSummary) => getPartyStatusDisplayName(originalRow.partyStatus || "", partyStatusList),
            allowSort: false
        }
    ];
};
export const RequestTermsGrid = (props: { requestTermsSummary: RequestTermSummary[]; columns: DataGridColumnDefinition<RequestTermSummary>[] }) => {
    return (
        <>
            <DataGrid
                data={props.requestTermsSummary}
                columnDefinitions={props.columns}
                options={{
                    allowMoveColumns: false
                }}
                gridPreferences={{
                    id: GridIds.termsGrid,
                    columnPreferences: []
                }}
                rowMetadata={rowMetadata}
            />
        </>
    );
};
