import { IntegrationStatus } from "aderant-conflicts-models";
import React from "react";
import { conflictsPalette } from "styles/conflictsPalette";
import { AderantTheme, StatusIcon } from "@aderant/aderant-react-components";
import _ from "lodash";
import { Typography } from "@mui/material";

export function IntegrationSummary (props: { ingestionStatuses: IntegrationStatus[] }) {
    const { ingestionStatuses } = props;
    const summaryStatus = getSummaryStatus(ingestionStatuses);
    const summaryLatestRun = getLatestRun(ingestionStatuses);
    return (
        <div style={{ display: "flex", columnGap: "15px", marginLeft: "auto", marginRight: "5px" }}> 
            <Typography style={{ alignSelf: "center" }} fontSize={AderantTheme.typography.pxToRem(15)}>{summaryLatestRun && `(${summaryLatestRun})`}</Typography>
            <StatusIcon style={{ alignSelf: "center", }} color={getSummaryStatusColor(summaryStatus)} aria-label={summaryStatus} />
        </div>
    )
} 

function getSummaryStatus(ingestionStatuses: IntegrationStatus[]): IntegrationStatus["status"] {
    if (ingestionStatuses.every((i) => i.status === "Succeeded")) {
        return "Succeeded";
    }

    return "Failed";
}

function getSummaryStatusColor(status: IntegrationStatus["status"]): string {
    if (status === "Succeeded") {
        return conflictsPalette.status.green;
    }

    return conflictsPalette.status.red;
}

function getLatestRun(ingestionStatuses: IntegrationStatus[]): string{
    const latestRun = _.max(ingestionStatuses.map(s => s.lastUpdateCompleted));
    if (latestRun){
        return latestRun.toLocaleString(undefined, { hour12: false })
    }

    return "";
}