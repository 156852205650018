import Avatar from "@mui/material/Avatar";
import { ConflictsAction } from "aderant-conflicts-models";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { conflictsPalette } from "styles/conflictsPalette";
import { MenuNameMap, MenuNameMessages, PathMap } from "utilities/routingPathMap";
import { getCurrentUser, usePermissionsContext, getSelectedSubscription } from "../../state/selectors/appSelectors";
import { HeaderMenu, MenuOptionType } from "../HeaderMenu/HeaderMenu";
import { isUrlParamFeatureFlagOn } from "aderant-conflicts-common";

export const getUserInitials = (userNames: string | undefined): string => {
    if (!userNames) {
        return "";
    }
    const splitNames: string[] = userNames.split(" ");
    if (splitNames.length > 1) {
        return `${splitNames[0][0].toUpperCase()}${splitNames[splitNames.length - 1][0].toUpperCase()}`;
    }
    return `${splitNames[0][0].toUpperCase()}`;
};

export const UserOptionPanel = (props: { onSignOut: () => void; style: React.CSSProperties }): JSX.Element => {
    const permissions = usePermissionsContext();
    const subscription = useSelector(getSelectedSubscription);
    const currentUser = useSelector(getCurrentUser);
    const history = useHistory();
    const isLoginHintFeatureEnabled = isUrlParamFeatureFlagOn("loginHint");

    const canViewSettings = permissions?.currentUserHasPermission(ConflictsAction.ViewSettingsPage);

    const blockStyle: React.CSSProperties = {
        paddingLeft: 7,
        paddingRight: 7,
        display: "flex",
        alignItems: "center"
    };

    const signOut = async () => {
        await props.onSignOut();
        history.push("/");
        window.location.reload();
    };

    const settingsMenuOption: MenuOptionType = { key: MenuNameMap.firmSettings, title: MenuNameMessages[MenuNameMap.firmSettings].getMessage(), path: PathMap.firmSettings };
    const signOutMenuOption: MenuOptionType = { key: MenuNameMap.logOut, title: MenuNameMessages[MenuNameMap.logOut].getMessage(), path: PathMap.logOut };
    const signedInMenuOption: MenuOptionType = {
        key: MenuNameMap.signedIn,
        title: MenuNameMessages[MenuNameMap.signedIn].getMessage(subscription?.displayName ?? "Production"),
        path: PathMap.none
    };
    const menuOptions: MenuOptionType[] = [signOutMenuOption];
    if (isLoginHintFeatureEnabled) {
        menuOptions.push(signedInMenuOption);
    }
    if (canViewSettings) {
        //Using unshift to put it at the start of the menuOptions Array as "Log Out" (signOutMenuOption) should always be last.
        menuOptions.unshift(settingsMenuOption);
    }

    useEffect(() => {
        if (canViewSettings) {
            menuOptions.unshift(settingsMenuOption);
        }
    }, [canViewSettings]);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        setOpen(true);
    };
    const handleMenuClose = () => {
        setOpen(false);
    };
    const handleMenuClick = (title: string) => {
        switch (title) {
            case MenuNameMap.logOut:
                signOut();
                handleMenuClose();
                break;
            case MenuNameMap.firmSettings:
                history.push("/settings");
                handleMenuClose();
                break;
            case MenuNameMap.signedIn:
                break;
            default:
                handleMenuClose();
                break;
        }
    };

    return (
        <div style={props.style}>
            <div style={{ ...blockStyle }}>
                <div
                    style={{
                        width: "42px",
                        height: "42px",
                        cursor: "pointer"
                    }}
                    role="button"
                    onClick={handleClick}
                    aria-label="Profile Menu"
                    aria-owns={anchorRef ? "new-options" : undefined}
                    aria-haspopup="true"
                    ref={anchorRef}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                            e.currentTarget.click();
                        }
                    }}
                >
                    <Avatar
                        sx={{
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                            width: "100%",
                            height: "100%",
                            bgcolor: open ? conflictsPalette.avatar.active : conflictsPalette.avatar.background,
                            color: open ? conflictsPalette.background : conflictsPalette.avatar.secondary,
                            border: open ? `0px` : `solid 1px ${conflictsPalette.avatar.secondary}`,
                            fontSize: "1rem",
                            ":hover": {
                                bgcolor: conflictsPalette.avatar.hover,
                                color: open ? conflictsPalette.avatar.secondary : conflictsPalette.background
                            },
                            ":focus-visible": {
                                bgcolor: conflictsPalette.avatar.hover
                            }
                        }}
                        tabIndex={menuOptions.length > 0 ? 0 : -1}
                    >
                        {getUserInitials(currentUser?.name)}
                    </Avatar>
                </div>
                <HeaderMenu open={open} anchorRef={anchorRef} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} menuOptions={menuOptions} />
            </div>
        </div>
    );
};
