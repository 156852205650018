import { DataFactorySecrets, unexpectedError } from "aderant-conflicts-models";
import { DataFactoryManagementClient, PipelineResource, PipelineRun, PipelineRunsQueryByFactoryResponse, RunFilterParameters, RunQueryOrderBy, TriggerResource } from "@azure/arm-datafactory";
import { DefaultAzureCredential } from "@azure/identity";

export class DataFactoryConnector {
    private client: DataFactoryManagementClient;
    //The name of the Azure resource group the ADF is defined in
    private resourceGroupName: string;
    //The name of the Azure Data Factory
    private dataFactoryName: string;

    protected constructor(client: DataFactoryManagementClient, resourceGroupName: string, dataFactoryName: string) {
        this.client = client;
        this.resourceGroupName = resourceGroupName;
        this.dataFactoryName = dataFactoryName;
    }

    public static async open(dataFactoryConnectionDetails: DataFactorySecrets) {
        const client = this.createDataFactoryClient(dataFactoryConnectionDetails.subscriptionId);
        return new DataFactoryConnector(client, dataFactoryConnectionDetails.resourceGroupName, dataFactoryConnectionDetails.factoryName);
    }

    /**
     * Create an intialized BlobConnector.
     *
     * @param subscriptionId The Azure subscriptionId that can access the firms ADF
     * @returns
     */
    protected static createDataFactoryClient(subscriptionId: string) {
        if (!subscriptionId) {
            throw unexpectedError("SubscriptionId was not provided", "createDataFactoryClient");
        }
        const client = new DataFactoryManagementClient(new DefaultAzureCredential(), subscriptionId);
        return client;
    }

    /**
     * Get all pipeline runs for the data factory, that matches the given filter criteria.
     * @param filterParameters
     */
    public async getPipelineRunsByFactory(filterParameters: RunFilterParameters): Promise<PipelineRun[]> {
        let moreItemsToRead = true;
        const pipelineRuns: PipelineRun[] = [];
        while (moreItemsToRead) {
            const result: PipelineRunsQueryByFactoryResponse = await this.client.pipelineRuns.queryByFactory(this.resourceGroupName, this.dataFactoryName, filterParameters);
            pipelineRuns.push(...(result?.value || []));
            //set the continuation token, so it reads the next page of results
            filterParameters.continuationToken = result.continuationToken;
            moreItemsToRead = result.continuationToken ? true : false;
        }
        return pipelineRuns;
    }

    public async getPipeline(pipelineName: string): Promise<PipelineResource> {
        const result: PipelineResource = await this.client.pipelines.get(this.resourceGroupName, this.dataFactoryName, pipelineName);
        return result;
    }

    public async getPipelinesByFactory(): Promise<PipelineResource[]> {
        const pipelineResources: PipelineResource[] = [];
        for await (const item of this.client.pipelines.listByFactory(this.resourceGroupName, this.dataFactoryName)) {
            pipelineResources.push(item);
        }
        return pipelineResources;
    }

    public async getTrigger(triggerName: string): Promise<TriggerResource> {
        const result: TriggerResource = await this.client.triggers.get(this.resourceGroupName, this.dataFactoryName, triggerName);
        return result;
    }
}
