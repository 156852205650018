import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { IntegrationStatus } from "aderant-conflicts-models";
import { AderantTheme, StatusIcon } from "@aderant/aderant-react-components";
import React from "react";
import { conflictsPalette } from "styles/conflictsPalette";

export const IntegrationTable = (props: { ingestionStatuses: IntegrationStatus[] }) => {
    const { ingestionStatuses } = props;
    return (
        <Table style={{ minWidth: 650, margin: "0px 20px", width: "auto" }}>
            <TableHead sx={{ backgroundColor: conflictsPalette.background.white, color: conflictsPalette.text.black, border: 0 }}>
                <TableRow
                    key={"ingestion-table-header"}
                    sx={{
                        th: {
                            fontSize: AderantTheme.typography.pxToRem(16),
                            color: conflictsPalette.text.black,
                            padding: "0 1rem"
                        },
                        border: 0
                    }}
                >
                    <TableCell>Entity</TableCell>
                    <TableCell>Last Successful Update Completed (Date, Time)</TableCell>
                    <TableCell>Updates</TableCell>
                    <TableCell align="center">Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>{IntegrationTableRow(ingestionStatuses)}</TableBody>
        </Table>
    );
};

function IntegrationTableRow(ingestionStatuses: IntegrationStatus[]) {
    return ingestionStatuses.map((row: IntegrationStatus) => (
        <TableRow
            key={row.entityName}
            sx={{
                fontSize: AderantTheme.typography.pxToRem(13),
                border: 0,
                td: {
                    padding: "0.2rem 1rem"
                }
            }}
        >
            <TableCell sx={{ color: conflictsPalette.text.grey }}> {row.entityName}:</TableCell>
            <TableCell>{row.lastUpdateCompleted?.toLocaleString(undefined, { hour12: false })}</TableCell>
            <TableCell>{getFrequencyDisplayText(row.updateFrequency)}</TableCell>
            <TableCell align="center">
                <StatusIcon color={getStatusColor(row.status)} aria-label={row.status} />
            </TableCell>
        </TableRow>
    ));
}

function getStatusColor(status: IntegrationStatus["status"]): string {
    switch (status) {
        case "Failed":
            return conflictsPalette.status.red;
        case "Succeeded":
            return conflictsPalette.status.green;
        case "Unknown":
            return conflictsPalette.status.red;
    }
}

function getFrequencyDisplayText(updateFrequency: IntegrationStatus["updateFrequency"]) {
    let recurrenceText;
    switch (updateFrequency?.recurrence) {
        case "Year":
        case "Month":
        case "Week":
        case "Hour":
            recurrenceText = `${updateFrequency?.interval} x ${updateFrequency?.recurrence.toLowerCase()}`;
            break;
        case "Day":
            if (updateFrequency.interval === 1) {
                recurrenceText = "Daily";
            } else {
                recurrenceText = `${updateFrequency?.interval} x day`;
            }
            break;
        case "Minute":
            recurrenceText = `${updateFrequency?.interval}min`;
            break;
        default:
            recurrenceText = "Unknown";
    }
    return recurrenceText;
}
