import { CurrencyField } from "aderant-conflicts-models";
import React from "react";
import { styles } from "./commonStyles";
interface CurrencyInformationProps {
    field: CurrencyField;
}
/**
 *
 * @param value value in number
 * @returns value in fixed point (decimal added)
 */
export const valueInFixedPoint = (value: number): ((decimalPlaces: number) => number) => (decimalPlaces: number) => +value.toFixed(decimalPlaces);

/**
 * Concatenates currency symbol and amount with the correct decimal places
 */
export const currencyValueAsString = ({ amount, decimalPlaces, symbol }: CurrencyField["value"]): string => {
    const amountInFixedPoint = valueInFixedPoint(amount)(decimalPlaces);
    return symbol + amountInFixedPoint.toString();
};

export const CurrencyInformation = (props: CurrencyInformationProps): JSX.Element => {
    const {
        field: { fieldLabel, value }
    } = props;
    return (
        <>
            <span style={styles.labelStyle}>{fieldLabel}:</span>
            <span data-testid={`${fieldLabel}`} style={styles.valueStyle}>
                {currencyValueAsString(value)}
            </span>
        </>
    );
};
