import { useRef, useEffect } from "react";

// React Hooks don't currently provide a hook to access previous props or state, but this is their documented solution. The ref is created, then useEffect is not invoked, instead the return value of the custom hook is invoked. The useEffect hook is only invoked after the component from which it is called has been rendered.

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
