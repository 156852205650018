export const secureEntityTypes = ["client", "matter", "vendor", "employee"] as const;
export type SecureEntityType = typeof secureEntityTypes[number];

/**
 * An array of these is stored as a blob for each user.
 */
export type SecureEntity = { secureEntityId: string; entityType: SecureEntityType };

export type EntityAccessList = {
    userEmail: string;
    allowedEntityIds: string[];
    deniedEntityIds: string[];
};

export type EntityAccessSets = {
    allowedEntityIds: Set<string>;
    deniedEntityIds: Set<string>;
};
