import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Checkbox } from "@aderant/aderant-react-components";
import { refinerActions } from "state/actions/RefinerActions";
import { Messages } from "./Messages";
import { ViewByNames, VIEW_BY_FILTERS } from "state/reducers/ReducerStateTypes";

const ViewByCheckboxes = ({ currentUserId, disabled }: { currentUserId: string; disabled: boolean }) => {
    const dispatch = useDispatch();

    const [numberSelected, setNumberSelected] = useState(3);
    const [state, setState] = useState({
        [ViewByNames.CREATED_BY]: true,
        [ViewByNames.REQUESTED_BY]: true,
        [ViewByNames.ASSIGNED_TO]: true
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newNumber = event.target.checked ? numberSelected + 1 : numberSelected - 1;
        const { id, checked } = event.target;
        const viewByObj = VIEW_BY_FILTERS.find((obj) => obj.name === id);
        if (newNumber > 0 && viewByObj) {
            setNumberSelected(newNumber);
            setState((prevState) => {
                return { ...prevState, [id]: checked };
            });
            dispatch(
                refinerActions.updateViewByRefiners({
                    viewByRefiner: { name: viewByObj.name, path: viewByObj.path, ids: checked ? [currentUserId] : [] }
                })
            );
        }
    };

    return (
        <div style={{ paddingLeft: "13px" }}>
            {VIEW_BY_FILTERS.map((view) => {
                const messageKey = `${view.name}_ME`;

                return <Checkbox label={Messages[messageKey].getMessage()} id={view.name} checked={state[view.name]} compact onChange={handleChange} disabled={disabled} key={view.name} />;
            })}
        </div>
    );
};

export default React.memo(ViewByCheckboxes);
