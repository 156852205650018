import { ok, Result, ValidationErrors, ValidationMessage, wrapValidationErrors } from "aderant-conflicts-models";
import { TypeValidationMessages as messages } from "./Messages";

//justification - needed to write this code ergonomically
/* eslint-disable @typescript-eslint/consistent-type-assertions */
export class InputValidator<CurrentInput extends Record<string, unknown>> {
    private value: CurrentInput;
    private validationMessages: ValidationMessage[] = [];
    private fieldNamePrefix: string;

    private constructor(value: CurrentInput, existingErrors: ValidationMessage[] = [], fieldNamePrefix = "") {
        if (!value) {
            this.validationMessages.push(messages.VLD_NULLUNDEF_BODY.asValidationMessage());
        }
        this.value = value;
        this.validationMessages.push(...existingErrors);
        this.fieldNamePrefix = fieldNamePrefix;
    }

    private formatFieldName<FieldName extends string>(name: FieldName): string {
        return `${this.fieldNamePrefix}${name}`;
    }

    //justification: need to use {} as our 'empty object' specifier as Intersecting the recommended Record<string, never> with the new field doesn't result in the correct result.
    //This doesn't cause any issues in this narrow case. Structural typing just means that primitives are assignable to {} - because they are equivalent to an object with nothing on it too.
    // eslint-disable-next-line @typescript-eslint/ban-types
    public static validate(value: unknown, fieldNamePrefix?: string): InputValidator<{}> {
        if (!value) {
            return new InputValidator({}, [messages.VLD_NULLUNDEF_BODY.asValidationMessage()], fieldNamePrefix);
        } else if (typeof value !== "object") {
            return new InputValidator({}, [messages.VLD_NOTOBJECT_BODY.asValidationMessage()], fieldNamePrefix);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-types
            return new InputValidator(value as {}, [], fieldNamePrefix);
        }
    }

    public stringFieldRequired<FieldName extends string>(fieldName: FieldName): InputValidator<CurrentInput & { [k in FieldName]: string }> {
        const toValidate = this.value ? this.value[fieldName] : undefined;
        if (toValidate === undefined || toValidate === null) {
            this.validationMessages.push(messages.VLD_NULLUNDEF_FIELD.asValidationMessage(this.formatFieldName(fieldName)));
            // `T & { [k in FieldName]: string }` just means 'T + a string field called FieldName - need to use the k in FieldName syntax because we don't know the literal field name
            // casting in the error case is safe because an errored InputValidator will never return `value` anyway, just `validationMessages`
            return this as InputValidator<CurrentInput & { [k in FieldName]: string }>;
        } else if (typeof toValidate !== "string") {
            this.validationMessages.push(messages.VLD_WRONGTYPE_FIELD.asValidationMessage(this.formatFieldName(fieldName), "string"));
            return this as InputValidator<CurrentInput & { [k in FieldName]: string }>;
        }
        const trimmedInput = toValidate.trim();
        if (trimmedInput.length === 0) {
            this.validationMessages.push(messages.VLD_NULLUNDEFEMPTY_FIELD.asValidationMessage(this.formatFieldName(fieldName)));
            return this as InputValidator<CurrentInput & { [k in FieldName]: string }>;
        }

        //cast is safe because we know the original value in this field was a string, and the new value is also a string.
        //compiler is just not smart enough to work that out.
        this.value[fieldName] = trimmedInput as CurrentInput[FieldName];

        //this cast is also safe - we've confirmed that FieldName exists and is a string.
        //we could avoid this cast by instantiating a new InputValidator here, but better
        //to avoid creating extra objects.
        return this as InputValidator<CurrentInput & { [k in FieldName]: string }>;
    }

    public stringFieldOptional<FieldName extends string>(fieldName: FieldName, nullable?: boolean): InputValidator<CurrentInput & { [k in FieldName]?: string }> {
        const toValidate = this.value ? this.value[fieldName] : undefined;
        if (typeof toValidate === "string") {
            const trimmed = toValidate.trim();
            if (trimmed.length === 0) {
                this.validationMessages.push(messages.VLD_EMPTY_OPTIONAL_FIELD.asValidationMessage(fieldName));
            }
            this.value[fieldName] = toValidate.trim() as CurrentInput[FieldName];
        } else if (nullable && toValidate === null) {
            this.value[fieldName] = null as CurrentInput[FieldName];
        } else if (toValidate !== undefined) {
            this.validationMessages.push(messages.VLD_WRONGTYPE_FIELD.asValidationMessage(this.formatFieldName(fieldName), "string or undefined"));
            return this as InputValidator<CurrentInput & { [k in FieldName]?: string }>;
        }

        return this as InputValidator<CurrentInput & { [k in FieldName]?: string }>;
    }

    public validateWholeObject<Out extends CurrentInput>(validator: (input: CurrentInput) => Result<Out, ValidationErrors>): InputValidator<Out> {
        const validated = validator(this.value);
        if (ok(validated)) {
            this.value = validated;
            return this as unknown as InputValidator<Out>;
        } else {
            this.validationMessages.push(...validated.errors);
            return this as unknown as InputValidator<Out>;
        }
    }

    public validateFieldOptional<FieldName extends string, FieldType>(
        fieldName: FieldName,
        validator: (input: CurrentInput[FieldName]) => Result<FieldType | undefined, ValidationErrors>
    ): InputValidator<CurrentInput & { [k in FieldName]: FieldType | undefined }> {
        const validated = validator(this.value[fieldName]);
        if (ok(validated)) {
            this.value[fieldName] = validated as CurrentInput[FieldName];
            return this as InputValidator<CurrentInput & { [k in FieldName]: FieldType | undefined }>;
        } else {
            this.validationMessages.push(...validated.errors);
            return this as InputValidator<CurrentInput & { [k in FieldName]: FieldType | undefined }>;
        }
    }

    public validateFieldRequired<FieldName extends string, FieldType>(
        fieldName: FieldName,
        validator: (input: CurrentInput[FieldName]) => Result<FieldType | undefined, ValidationErrors>
    ): InputValidator<CurrentInput & { [k in FieldName]: FieldType }> {
        const validated = validator(this.value[fieldName]);
        if (ok(validated)) {
            if (validated === undefined) {
                this.validationMessages.push(messages.VLD_NULLUNDEF_FIELD.asValidationMessage(fieldName));
            } else {
                this.value[fieldName] = validated as CurrentInput[FieldName];
            }
            return this as InputValidator<CurrentInput & { [k in FieldName]: FieldType }>;
        } else {
            this.validationMessages.push(...validated.errors);
            return this as InputValidator<CurrentInput & { [k in FieldName]: FieldType }>;
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public nestedObject<FieldName extends string, FieldType>(
        name: FieldName,
        // eslint-disable-next-line @typescript-eslint/ban-types
        validation: (validator: InputValidator<{}>) => Result<FieldType, ValidationErrors>
    ): InputValidator<CurrentInput & { [k in FieldName]: FieldType }> {
        const toValidate = this.value ? this.value[name] : undefined;

        if (!toValidate) {
            this.validationMessages.push(messages.VLD_NULLUNDEF_FIELD.asValidationMessage(name));
            return this as InputValidator<CurrentInput & { [k in FieldName]: FieldType }>;
        } else if (typeof toValidate !== "object") {
            this.validationMessages.push(messages.VLD_WRONGTYPE_FIELD.asValidationMessage(name, "object"));
            return this as InputValidator<CurrentInput & { [k in FieldName]: FieldType }>;
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-types
            const result = validation(new InputValidator(toValidate as {}, [], `${name}.`));
            if (ok(result)) {
                this.value[name] = result as CurrentInput[FieldName];
                return this as InputValidator<CurrentInput & { [k in FieldName]: FieldType }>;
            } else {
                this.validationMessages.push(...result.errors);
                return this as InputValidator<CurrentInput & { [k in FieldName]: FieldType }>;
            }
        }
    }

    public build(): Result<CurrentInput, ValidationErrors> {
        if (this.validationMessages.length > 0) {
            return wrapValidationErrors(this.validationMessages);
        } else {
            return this.value;
        }
    }
}
