import React from "react";
import { MenuItem, Popper, Paper, Grow, MenuList, ClickAwayListener } from "@mui/material";
import { conflictsPalette } from "styles/conflictsPalette";
import { MenuNameMap, PathMap } from "utilities/routingPathMap";

export type MenuOptionType = { key: MenuNameMap; title: string; path: PathMap };

interface HeaderMenuProps {
    open: boolean;
    anchorRef: React.MutableRefObject<null>;
    handleMenuClick: (title: string) => void;
    handleMenuClose: () => void;
    menuOptions: MenuOptionType[];
}

export const HeaderMenu = (props: HeaderMenuProps): JSX.Element => {
    const { open, anchorRef, handleMenuClick, handleMenuClose, menuOptions } = props;
    return (
        <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start" transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom-start" ? "right top" : "right bottom", transform: "translate( 0px, 3px)" }}>
                    <Paper style={{ minWidth: "100%", borderBottomLeftRadius: 3, borderBottomRightRadius: 3 }}>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList
                                sx={{
                                    fontSize: "1rem",
                                    width: "180px"
                                }}
                                disablePadding
                                id="new-options"
                            >
                                {menuOptions.map((option: MenuOptionType, i) => (
                                    <MenuItem
                                        key={option.key}
                                        disabled={option.path === PathMap.none}
                                        onClick={() => handleMenuClick(option.key)}
                                        sx={{
                                            color: conflictsPalette.text.black,
                                            minHeight: "2.5rem",
                                            padding: "11px 11px 11px 11px",
                                            width: "180px",
                                            "&:hover, &.Mui-selected": {
                                                backgroundColor: conflictsPalette.background.hoverLight
                                            },
                                            "&.Mui-disabled": { opacity: 1, color: conflictsPalette.text.disabled },
                                            overflow: "hidden",
                                            display: "inline-block",
                                            textWrap: "wrap",
                                            overflowWrap: "break-word",
                                            borderTop: `.5px solid ${conflictsPalette.primary.Ahsoka}`,
                                            borderRight: `.5px solid ${conflictsPalette.primary.Ahsoka}`,
                                            borderLeft: `.5px solid ${conflictsPalette.primary.Ahsoka}`,

                                            "&:last-of-type": {
                                                borderBottomLeftRadius: 3,
                                                borderBottomRightRadius: 3
                                            }
                                        }}
                                    >
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};
