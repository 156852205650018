import { getFunctionAppUrl, Endpoint } from "../Config/EnvironmentService";
export function initialiseRuntimeEnvironment(): void {
    //Ensure you have run .\Deployment\Development\SetEnvironmentVariables.ps1 BEFORE RUNNING INTEGRATION TESTS
    const integrationTestConfig = process.env.CONFLICTS_INTEGRATION_TEST
        ? JSON.parse(process.env.CONFLICTS_INTEGRATION_TEST)
        : {
              runtimeEnvironment: "master",
              bearerAuthToken: "hello",
              masterAppPrefix: "masterenv",
              masterFirmPrefix: "masterfirm1"
          };
    const runtimeEnvironment: string = integrationTestConfig.runtimeEnvironment ?? "master";
    process.env.TENANCY_NAME = process.env.TENANCY_NAME ?? "aderant";

    process.env.DEV_ENV = runtimeEnvironment;
    switch (runtimeEnvironment) {
        case "local":
        case "development": {
            break;
        }
        case "master": {
            process.env.CONFLICTS_APPPREFIX = integrationTestConfig.masterAppPrefix ?? "masterenv";
            process.env.CONFLICTS_FIRMPREFIX = integrationTestConfig.masterFirmPrefix ?? "masterfirm1";
            break;
        }
        case "qa": {
            process.env.CONFLICTS_APPPREFIX = integrationTestConfig.qaAppPrefix;
            process.env.CONFLICTS_FIRMPREFIX = integrationTestConfig.qaFirmPrefix;
            break;
        }
        //TODO Add a case for production, if and when we have an Aderant tenant on production for running integration tests against.
        default: {
            if (runtimeEnvironment) {
                throw `runtimeEnvironment '${runtimeEnvironment}' not implemented`;
            }
            throw "You must provide a value for integrationTest.runtimeEnvironment in EnvironmentConfig.json";
        }
    }
    if (!process.env.CONFLICTS_APPPREFIX) {
        throw "You must provide an appPrefix for the environment you are testing in EnvironmentConfig.json";
    }
    if (!process.env.CONFLICTS_FIRMPREFIX) {
        throw "You must provide a firmPrefix for the environment you are testing in EnvironmentConfig.json";
    }
    process.env.WEBSITE_RESOURCE_GROUP = process.env.CONFLICTS_APPPREFIX;
    process.env.CONFLICTS_FIRMRESOURCEGROUP = process.env.CONFLICTS_FIRMPREFIX;
    process.env.CONFLICTS_KEYVAULTNAME = `${process.env.CONFLICTS_APPPREFIX}vault`;
}

export function getServiceUrl(functionAppName: Endpoint): URL {
    return new URL(getFunctionAppUrl(functionAppName).toString().replace("localhost", "127.0.0.1"));
}

export function getBearerAuthToken(): string {
    const integrationTestConfig = JSON.parse(process.env.CONFLICTS_INTEGRATION_TEST ?? "{}");
    //TODO - Before running these integration tests locally, get a bearer token by logging in to the Conflicts web application for the environment you want to test
    //       and open the browser dev tools to copy a bearer token from the header of one of the network requests,
    //       then update it to integrationTest.bearerAuthToken in EnvironmentConfig.json, before running Deployment\Development\SetEnvironmentVariables.ps1
    if (integrationTestConfig.bearerAuthToken) {
        return integrationTestConfig.bearerAuthToken;
    }
    throw "You must provide a bearer auth token in the EnvironmentConfig.json to run integration tests.";
}
