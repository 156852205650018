import { DataProvider, DataProviderItem, DataProviderQueryResult } from "@aderant/aderant-react-components";
import { User } from "aderant-conflicts-models";

export class UserDataProvider implements DataProvider<User> {
    dataProviderItemList: DataProviderItem[] = [];
    constructor(private userList: User[]) {
        this.dataProviderItemList = this.convertUserToDataProvider(userList);
    }

    isLookup = true;
    entityDisplayName = "User";

    queryAllItems(): Promise<DataProviderQueryResult> {
        return Promise.resolve({
            items: this.dataProviderItemList,
            queryNextPage: undefined
        });
    }

    toDataProviderItem = (entityItem: User): DataProviderItem => {
        return {
            id: entityItem.id,
            code: "",
            primaryText: entityItem.name,
            secondaryText: entityItem.email
        };
    };

    async queryByText(queryText: string): Promise<DataProviderQueryResult> {
        const items = this.dataProviderItemList.filter((user) => {
            return this.isUserMatchForQuery(user, queryText);
        });

        return Promise.resolve({
            items: items,
            queryNextPage: undefined
        });
    }

    isUserMatchForQuery(user: DataProviderItem, queryText: string): boolean {
        const searchTerms = queryText.toLowerCase().split(/\W/);
        const primaryTextTerms = user.primaryText.toLowerCase().split(/\W/);
        const secondaryTextTerms = user.secondaryText?.toLowerCase().split(/\W/);
        return (
            searchTerms.every((term) => primaryTextTerms.some((primaryTerm) => primaryTerm.includes(term))) ||
            searchTerms.every((term) => secondaryTextTerms?.some((secondaryTerm) => secondaryTerm.includes(term)))
        );
    }

    async queryExactMatch(code: string): Promise<DataProviderItem | undefined> {
        const result = await this.getItemByCode(code);
        if (result) {
            return Promise.resolve(this.toDataProviderItem(result));
        }
        return Promise.resolve(undefined);
    }

    async getItemByCode(code: string): Promise<User | undefined> {
        const results = this.userList.filter((user) => user.id == code);
        if (results.length > 0) {
            return Promise.resolve(results[0]);
        }
        return Promise.resolve(undefined);
    }

    async getItemById(id: string | number): Promise<User | undefined> {
        const results = this.userList.filter((user) => user.id == id);
        if (results.length > 0) {
            return Promise.resolve(results[0]);
        }
        return Promise.resolve(undefined);
    }

    convertUserToDataProvider(users: User[]): DataProviderItem[] {
        return users.map((user) => this.toDataProviderItem(user));
    }
}
