import { ConflictsAction, EntityRelationshipConfigurationTemplate, Forbidden, ok, Result } from "aderant-conflicts-models";
import { ClientAppContext, FunctionAppContext } from "../../ConflictsContext";
import { EntityConfigurationService } from "./EntityConfigurationService";
import { HeaderBuilder } from "../../Http/HeaderBuilder";
import { OkResponseWithHeaders, okResponseWithHeaders } from "../../Http/HttpResponse";
import { EntityConfiguration } from "./EntityConfiguration";
import { hasPermission } from "../hasPermission";

/**
 * This function retrieves the relationship configurations for all entity types.
 */
export async function getEntityRelationshipConfigurations(
    context: ClientAppContext | FunctionAppContext,
    input: undefined,
    entityConfigurationService: EntityConfigurationService
): Promise<Forbidden | OkResponseWithHeaders<EntityRelationshipConfigurationTemplate[]>> {
    context.logger.info("Retrieving entity relationship configurations.");

    const permissionValidation = await hasPermission(context, ConflictsAction.ViewAndUpdateEntityRelationshipConfiguration, `view the ${EntityConfiguration.RelationshipDefinition}`);
    if (!ok(permissionValidation)) {
        return permissionValidation;
    }

    //Fetch relationship configuration template for the specified entity type
    const entityTemplates: Result<EntityRelationshipConfigurationTemplate[]> = await entityConfigurationService.getEntityRelationshipConfigurationTemplates();
    if (!ok(entityTemplates)) {
        return entityTemplates;
    }

    const response = okResponseWithHeaders(entityTemplates, new HeaderBuilder().addCacheHeader(600).build());
    return response;
}
