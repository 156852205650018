import { Clear } from "@mui/icons-material";
import { AddIcon, aderantColors, Button, Checkbox, DataGrid, DataGridColumnDefinition, Enter, Hotkey, InputText, MessageDialog } from "@aderant/aderant-react-components";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "state/actions";
import { GridIds } from "state/reducers/appReducers";
import { getIsFirmSettingsPagePersisting, getIsSynonymsToggledOn, getSynonymGroupsWithRowIds, getSynonymMap, SynonymGroupWithRowId } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import { Messages } from "./Messages";
import { SynonymsPageFooter } from "./SynonymsPageFooter";
import { SynonymTermsDialog } from "./SynonymTermsDialog";
import { RootState } from "MyTypes";
import { FirmSettings } from "aderant-conflicts-models";
import { DataLoadedState, isAllPageDataLoadedOrNotErrored } from "state/reducers/adminReducers";

const getRowId = (rowData: SynonymGroupWithRowId) => rowData.rowId;

export const SynonymMapGrid = (): JSX.Element => {
    const synonymMap = useSelector(getSynonymMap);
    const synonymGroups = useSelector(getSynonymGroupsWithRowIds);
    const dispatch = useDispatch();
    const pageName = FirmSettings.SynonymManagementDefinition.name;

    const [filterInput, setFilterInput] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogRow, setDialogRow] = useState<string[]>([]);
    const [synonymGroupIndex, setSynonymGroupIndex] = useState(-1);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [isPersistChangesDialogOpen, setIsPersistChangesDialogOpen] = useState(false);
    const [isCancelChangesDialogOpen, setIsCancelChangesDialogOpen] = useState(false);
    const [focusInput, setFocusInput] = useState(false);
    const isSynonymMapToggledOn = useSelector(getIsSynonymsToggledOn);
    const [isSynonymMapToggledOnCheckbox, setIsSynonymMapToggledOnCheckbox] = useState(isSynonymMapToggledOn);
    const isPersisting: boolean = useSelector((rootState: RootState) => getIsFirmSettingsPagePersisting(rootState, pageName));
    const isLoaded: DataLoadedState[] = useSelector((rootState: RootState) => rootState.admin.isLoaded[pageName]);
    const isSynonymMapEdited = useSelector((rootState: RootState) => rootState.admin.isEdited[pageName]);
    const isSynonymStatusEdited = useSelector((rootState: RootState) => rootState.admin.isEdited[`${pageName}:status-toggle`]);

    const isEdited = isSynonymMapEdited || isSynonymStatusEdited;

    useEffect(() => {
        if (isSynonymMapToggledOn === undefined) {
            dispatch(adminActions.fetchSynonymsStatus());
        }
        setIsSynonymMapToggledOnCheckbox(isSynonymMapToggledOn);
    }, [isSynonymMapToggledOn]);

    useMemo(() => {
        if (!synonymMap) {
            dispatch(adminActions.fetchSynonymMap());
        }
    }, [synonymMap?.synonymGroups]);

    const handleDeleteButton = (rowToDelete: number) => {
        setSynonymGroupIndex(rowToDelete);
        setIsConfirmDeleteDialogOpen(true);
    };

    const handleDeleteDispatch = () => {
        if (synonymGroupIndex >= 0) {
            dispatch(adminActions.deleteSynonymGroup(synonymGroupIndex));
            setSynonymGroupIndex(-1);
        }
    };

    const filterInputOnChange = (e: ChangeEvent, inputValue: string | undefined | null, reason: string | undefined) => {
        if (reason === "clear") {
            setFilterInput("");
        } else if (reason === "blur" && inputValue) {
            setFilterInput(inputValue && inputValue.trim());
        } else if (reason === "onChange" && inputValue) {
            setFilterInput(inputValue);
        }
    };

    const setSynonymTermsDialogOpenProps = (dialogRow: string[], groupIndex: number, focusOnInput: boolean) => {
        setDialogRow(dialogRow);
        setSynonymGroupIndex(groupIndex);
        setFocusInput(focusOnInput);
        setOpenDialog(true);
    };

    const getRowProps = useCallback(
        (row) => {
            const onClick = () => {
                setSynonymTermsDialogOpenProps(row.original.synonyms, row.index, false);
            };
            return {
                onClick: onClick
            };
        },
        [setDialogRow, setOpenDialog]
    );

    const onSynonymTermsDialogClose = () => {
        setOpenDialog(false);
        setDialogRow([]);
    };

    const onSynonymTermsDialogSave = () => {
        onSynonymTermsDialogClose();
    };

    const cancelChanges = () => {
        dispatch(adminActions.fetchSynonymMap());
        dispatch(adminActions.fetchSynonymsStatus());
        setIsSynonymMapToggledOnCheckbox(isSynonymMapToggledOn);
    };

    const persistChanges = () => {
        if (!synonymMap) {
            return;
        }
        if (isSynonymMapEdited) {
            dispatch(adminActions.updateSynonymMap({ updatedSynonymMap: synonymMap }));
        }
        if (isSynonymStatusEdited) {
            dispatch(adminActions.updateSynonymsStatus({ synonymMapToggle: isSynonymMapToggledOnCheckbox ? "ON" : "OFF" }));
        }
    };

    const synonymGroupRemoveMessage = () => {
        if (synonymMap && synonymGroupIndex >= 0 && synonymMap.synonymGroups[synonymGroupIndex].synonyms) {
            return Messages.REMOVE_SET_MESSAGE.getMessage(synonymMap.synonymGroups[synonymGroupIndex].synonyms.length, synonymMap.synonymGroups[synonymGroupIndex].synonyms.join(", "));
        }
    };

    const columnDefinitions: DataGridColumnDefinition<SynonymGroupWithRowId>[] = useMemo(
        () => [
            {
                columnName: "Terms",
                width: 250,
                path: (originalRow: SynonymGroupWithRowId) => originalRow.synonyms[0],
                Cell: (grid) => {
                    let filtered = [];
                    if (grid.state.filters.length > 0) {
                        filtered = grid.row.original.synonyms.filter((synonym) => synonym.toLowerCase().includes(grid.state.filters[0].value.toLowerCase()));
                    }
                    return filtered[0] ?? grid.row.original.synonyms[0];
                },
                id: "terms"
            },
            {
                columnName: "Synonym Set",
                id: "synonyms",
                path: (originalRow: SynonymGroupWithRowId) => originalRow.synonyms.join(", "),
                filter: "fuzzyText",
                flexGrow: 1
            },
            {
                columnName: "Remove Set",
                id: "remove-icon",
                isDataProperty: false,
                Cell: (grid) => (
                    <div onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
                        <Button
                            iconButton
                            variant={"plain"}
                            rounded
                            startIcon={<Clear fontSize="small" />}
                            onClick={() => {
                                handleDeleteButton(grid.row.index);
                            }}
                            aria-label="delete-synonym-group"
                            title="Delete synonym group"
                        />
                    </div>
                ),
                width: 100,
                allowSort: false,
                align: "center"
            }
        ],
        []
    );

    if (isAllPageDataLoadedOrNotErrored(isLoaded) && synonymMap) {
        return (
            <div style={{ height: "inherit", display: "flex", flexDirection: "column", backgroundColor: `${conflictsPalette.background.grey}` }}>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(100, 1%)", backgroundColor: `${aderantColors.Tauntaun}` }}>
                    <div style={{ display: "flex", gridColumnStart: "30", gridColumnEnd: "75" }}>
                        <div style={{ width: "85%", paddingRight: "9.1px", paddingTop: "1rem", alignSelf: "center", lineHeight: 1.6, justifyContent: "flex-start" }}>
                            <Hotkey hotkey={Enter} onDown={() => setSynonymTermsDialogOpenProps([], -1, true)}>
                                <InputText
                                    value={filterInput}
                                    onChange={(e: ChangeEvent, inputValue: string | undefined | null, reason: string | undefined) => filterInputOnChange(e, inputValue, reason)}
                                    hasError={false}
                                    disabled={false}
                                    id="input-text-for-synmaps"
                                    aria-label="syn-terms"
                                    placeholder={Messages.ENTER_TEXT_TO_FILTER_OR_ADD_NEW.getMessage()}
                                />
                            </Hotkey>
                        </div>
                        <Button
                            iconButton
                            startIcon={<AddIcon style={{ fontSize: "large", borderRadius: "50%" }} />}
                            onClick={() => setSynonymTermsDialogOpenProps([], -1, true)}
                            aria-label={"add-synonym-group"}
                            title={"add-synonym-group"}
                            color="secondary"
                            rounded
                            disabled={filterInput !== null && filterInput.length === 0}
                            style={{ alignSelf: "center", margin: "4px 17px 0 15px", height: "fit-content" }}
                        />
                    </div>

                    <div style={{ gridColumnStart: "79", gridColumnEnd: "101", justifySelf: "end", alignSelf: "center", marginRight: "2em" }}>
                        <Checkbox
                            id="toggle-synonyms-checkbox"
                            label={Messages.INCLUDE_SYNONYMS_IN_ALL_SEARCHES.getMessage()}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(adminActions.toggleSynonymStatus({ synonymMapToggle: e.currentTarget.checked }));
                                setIsSynonymMapToggledOnCheckbox(e.currentTarget.checked);
                            }}
                            checked={isSynonymMapToggledOnCheckbox}
                            disabled={isSynonymMapToggledOn === undefined}
                        />
                    </div>
                </div>

                <DataGrid<SynonymGroupWithRowId>
                    columnDefinitions={columnDefinitions}
                    data={synonymGroups}
                    options={{
                        allowMoveColumns: false
                    }}
                    filters={[{ id: "synonyms", value: filterInput }]}
                    gridPreferences={{
                        id: GridIds.synonymsGrid,
                        columnPreferences: [
                            {
                                columnId: "terms",
                                sort: "asc"
                            }
                        ]
                    }}
                    rowMetadata={{
                        getRowProps: getRowProps,
                        getRowId: getRowId
                    }}
                />
                <MessageDialog
                    open={isConfirmDeleteDialogOpen}
                    onClose={() => setIsConfirmDeleteDialogOpen(false)}
                    message={synonymGroupRemoveMessage()}
                    title={Messages.REMOVE_SET_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text="Cancel"
                                color="secondary"
                                onClick={() => {
                                    setIsConfirmDeleteDialogOpen(false);
                                    setSynonymGroupIndex(-1);
                                }}
                            />
                            <Button
                                text="Yes"
                                onClick={() => {
                                    setIsConfirmDeleteDialogOpen(false);
                                    handleDeleteDispatch();
                                }}
                            />
                        </>
                    }
                />
                <MessageDialog
                    open={isPersistChangesDialogOpen}
                    onClose={() => setIsPersistChangesDialogOpen(false)}
                    message={Messages.UPDATE_SYNONYM_MAP_MESSAGE.getMessage()}
                    title={Messages.UPDATE_SYNONYM_MAP_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text="Cancel"
                                color="secondary"
                                onClick={() => {
                                    setIsPersistChangesDialogOpen(false);
                                }}
                            />
                            <Button
                                text="Yes"
                                onClick={() => {
                                    setIsPersistChangesDialogOpen(false);
                                    persistChanges();
                                }}
                            />
                        </>
                    }
                />
                <MessageDialog
                    open={isCancelChangesDialogOpen}
                    onClose={() => setIsCancelChangesDialogOpen(false)}
                    message={Messages.CANCEL_CHANGES_MESSAGE.getMessage()}
                    title={Messages.CANCEL_CHANGES_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text="No"
                                color="secondary"
                                onClick={() => {
                                    setIsCancelChangesDialogOpen(false);
                                }}
                            />
                            <Button
                                text="Yes"
                                onClick={() => {
                                    setIsCancelChangesDialogOpen(false);
                                    cancelChanges();
                                }}
                            />
                        </>
                    }
                />

                <SynonymsPageFooter disabled={!isEdited || isPersisting} onCancel={() => setIsCancelChangesDialogOpen(true)} onSave={() => setIsPersistChangesDialogOpen(true)} />
                <SynonymTermsDialog
                    open={openDialog}
                    terms={dialogRow}
                    onCancel={onSynonymTermsDialogClose}
                    onSave={onSynonymTermsDialogSave}
                    synonymGroupIndex={synonymGroupIndex}
                    newSynonymTerm={synonymGroupIndex === -1 ? filterInput : undefined}
                    focusInput={focusInput}
                />
            </div>
        );
    }

    return <></>;
};
