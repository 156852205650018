import { ConflictsError } from "aderant-conflicts-models";
import { TypeValidationMessages } from "../Messages";
import { messages as secretMessages } from "../../SecretConnectors/FunctionKeySecret/Messages";

const messages = { ...TypeValidationMessages, ...secretMessages };

export type KeyNameTooLong = ConflictsError<400, typeof messages.VLD_STRING_TOO_LONG.messageCode>;
export function keyNameTooLong(maxLength: number): KeyNameTooLong {
    return {
        _conflictserrortype: messages.VLD_STRING_TOO_LONG.messageCode,
        httpStatusCode: 400,
        ...messages.VLD_STRING_TOO_LONG.asValidationMessage("keyName", maxLength)
    };
}

export type KeyLimitReached = ConflictsError<400, typeof messages.VLD_KEY_LIMIT_REACHED.messageCode>;
export function keyLimitReached(keyLimit: number): KeyLimitReached {
    return {
        _conflictserrortype: messages.VLD_KEY_LIMIT_REACHED.messageCode,
        httpStatusCode: 400,
        ...messages.VLD_KEY_LIMIT_REACHED.asValidationMessage(keyLimit)
    };
}

export type KeyNameNotUnique = ConflictsError<400, typeof messages.VLD_KEYNAME_NOT_UNIQUE.messageCode>;
export function keyNameNotUnique(keyName: string): KeyNameNotUnique {
    return {
        _conflictserrortype: messages.VLD_KEYNAME_NOT_UNIQUE.messageCode,
        httpStatusCode: 400,
        ...messages.VLD_KEYNAME_NOT_UNIQUE.asValidationMessage(keyName)
    };
}
