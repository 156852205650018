// Disabled due to NoOp AppInsights Client
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IAppInsights, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITraceTelemetry, SeverityLevel } from "@microsoft/applicationinsights-web";
import { AppInsightsClient, Telemetry } from "aderant-web-fw-azfunctions";
import { Logger } from "aderant-web-fw-core";

export class AppInsightsWeb implements AppInsightsClient {
    public constructor(private implementation: IAppInsights) {}
    getTelemetryCorrelationInfo(): { operationId: string; operationParentId: string } {
        throw new Error("Not supported in web code, correlation should be handled automatically for HTTP calls.");
    }

    setTelemetryCorrelationInfo(correlationInfo: { operationId: string; operationParentId: string }): void {
        throw new Error("Not supported in web code, correlation should be handled automatically for HTTP calls.");
    }

    trackPageView(telemetry: Telemetry.PageView): void {
        const azureTelemetry: IPageViewTelemetry = {
            name: telemetry.name,
            uri: telemetry.uri,
            measurements: telemetry.measurements,
            properties: { duration: telemetry.duration, ...telemetry.properties }
        };

        return this.implementation.trackPageView(azureTelemetry);
    }
    trackEvent(telemetry: Telemetry.Event): void {
        const azureTelemetry: IEventTelemetry = {
            name: telemetry.name,
            measurements: telemetry.measurements,
            properties: telemetry.properties
        };

        return this.implementation.trackEvent(azureTelemetry);
    }
    trackTrace(telemetry: Telemetry.Trace): void {
        const azureTelemetry: ITraceTelemetry = {
            message: telemetry.message,
            properties: telemetry.properties
        };

        return this.implementation.trackTrace(azureTelemetry);
    }
    trackMetric(telemetry: Telemetry.Metric): void {
        const azureTelemetry: IMetricTelemetry = {
            name: telemetry.name,
            average: telemetry.value,
            sampleCount: telemetry.count,
            min: telemetry.min,
            max: telemetry.max,
            stdDev: telemetry.stdDev,
            properties: { namespace: telemetry.namespace, kind: telemetry.kind, ...telemetry.properties }
        };

        return this.implementation.trackMetric(azureTelemetry);
    }
    trackException(telemetry: Telemetry.Exception): void {
        const azureTelemetry: IExceptionTelemetry = {
            exception: telemetry.exception,
            measurements: telemetry.measurements,
            severityLevel: telemetry.severity ? severityToAzureSeverity(telemetry.severity) : undefined,
            properties: telemetry.properties
        };

        return this.implementation.trackException(azureTelemetry);
    }
}

export class NoopAppInsights implements AppInsightsClient {
    public constructor(private logger: Logger) {}

    trackPageView(telemetry: Telemetry.PageView): void {
        this.logger.warn("AppInsights telemetry was attempted to be recorded, this is not yet implemented clientside.");
    }
    trackEvent(telemetry: Telemetry.Event): void {
        this.logger.warn("AppInsights telemetry was attempted to be recorded, this is not yet implemented clientside.");
    }
    trackTrace(telemetry: Telemetry.Trace): void {
        this.logger.warn("AppInsights telemetry was attempted to be recorded, this is not yet implemented clientside.");
    }
    trackMetric(telemetry: Telemetry.Metric): void {
        this.logger.warn("AppInsights telemetry was attempted to be recorded, this is not yet implemented clientside.");
    }
    trackException(telemetry: Telemetry.Exception): void {
        this.logger.warn("AppInsights telemetry was attempted to be recorded, this is not yet implemented clientside.");
    }
    getTelemetryCorrelationInfo(): { operationId: string; operationParentId: string } {
        throw new Error("Not supported in web code, correlation should be handled automatically for HTTP calls.");
    }
    setTelemetryCorrelationInfo(correlationInfo: { operationId: string; operationParentId: string }): void {
        throw new Error("Not supported in web code, correlation should be handled automatically for HTTP calls.");
    }
}

function severityToAzureSeverity(severity: Telemetry.SeverityLevel): SeverityLevel {
    switch (severity) {
        case "VERBOSE":
            return SeverityLevel.Verbose;
        case "INFORMATION":
            return SeverityLevel.Information;
        case "WARNING":
            return SeverityLevel.Warning;
        case "ERROR":
            return SeverityLevel.Error;
        case "CRITICAL":
            return SeverityLevel.Critical;
    }
}
