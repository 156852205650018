import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogHeader, DialogContent, DialogFooter, Button, Hotkey, AddIcon, Enter, InputText, ListBox } from "@aderant/aderant-react-components";
import { Messages } from "./Messages";
import { Messages as SharedMessages } from "../../../Shared/Messages";
import { Grid } from "@mui/material";
import { conflictsPalette } from "styles/conflictsPalette";
import { useDispatch } from "react-redux";
import { adminActions } from "../../../../state/actions/AdminActions";
import _ from "lodash";

type SynonymTermsDialogProps = {
    open: boolean;
    onCancel: () => void;
    onSave: () => void;
    terms: string[];
    synonymGroupIndex: number;
    newSynonymTerm?: string;
    focusInput: boolean;
};

const synonymInputContainer: CSSProperties = {
    backgroundColor: conflictsPalette.background.input,
    alignItems: "center",
    display: "flex",
    borderBottom: `1px solid ${conflictsPalette.border}`,
    padding: "15px 0 5px"
};

const synonymTermInput: CSSProperties = {
    flex: "1 1 auto",
    marginLeft: "8px"
};

export const arraysHaveSameValues = (firstArray: string[], secondArray: string[]): boolean => {
    const firstArrayCopy = [...firstArray];
    const secondArrayCopy = [...secondArray];
    return firstArrayCopy.sort().join(",") === secondArrayCopy.sort().join(",");
};

export const sortAlphabetically = (arrayToSort: string[]): string[] => _.sortBy(arrayToSort, (item) => item.toLowerCase());

export function SynonymTermsDialog(props: SynonymTermsDialogProps): JSX.Element {
    const [synonymTerms, setSynonymTerms] = useState<string[]>(props.terms);
    const [newSynonymTerm, setNewSynonymTerm] = useState<string>("");
    const [canAddSynonymTerm, setCanAddSynonymTerm] = useState<boolean>(false);
    const [canSaveTerms, setCanSaveTerms] = useState(false);

    const synonymTermInputRef = useRef<HTMLInputElement | null>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (synonymTerms.length >= 20) {
            setCanAddSynonymTerm(false);
        }
        if (props.synonymGroupIndex !== -1) {
            if (arraysHaveSameValues(synonymTerms, props.terms)) {
                setCanSaveTerms(false);
            } else {
                setCanSaveTerms(true);
            }
        } else {
            if (synonymTerms.length === 0) {
                setCanSaveTerms(false);
            } else {
                setCanSaveTerms(true);
            }
        }
    }, [synonymTerms]);

    useEffect(() => {
        setSynonymTerms(props.terms);
        if (props.newSynonymTerm) {
            setNewSynonymTerm(props.newSynonymTerm);
            setCanAddSynonymTerm(true);
        } else {
            setNewSynonymTerm("");
        }
    }, [props.terms, props.newSynonymTerm]);

    const onNewSynonymTermChange = (e, value): void => {
        setNewSynonymTerm(value);
        if (synonymTerms.length < 20) {
            setCanAddSynonymTerm(true);
        } else {
            setCanAddSynonymTerm(false);
        }
    };

    const onAddSynonymTerm = (): void => {
        if (canAddSynonymTerm) {
            const trimmedSynonymTerm = newSynonymTerm.trim();
            if (trimmedSynonymTerm) {
                setSynonymTerms((oldTerms) => {
                    return [trimmedSynonymTerm, ...oldTerms];
                });
            }
            setNewSynonymTerm("");
            synonymTermInputRef?.current?.focus();
        }
    };

    const handleTermUpdate = (values: string[]) => {
        setSynonymTerms(values);
    };

    const onSave = () => {
        if (synonymTerms.length === 0) {
            dispatch(adminActions.deleteSynonymGroup(props.synonymGroupIndex));
        } else {
            const sortedSynonymTerms = sortAlphabetically(synonymTerms);
            if (props.synonymGroupIndex === -1) {
                dispatch(adminActions.createSynonymGroup({ newSynonymGroup: { synonyms: sortedSynonymTerms } }));
            } else {
                dispatch(adminActions.updateSynonymGroup({ updatedSynonymGroup: { synonyms: sortedSynonymTerms }, synonymGroupIndex: props.synonymGroupIndex }));
            }
        }
        props.onSave();
    };

    return (
        <Dialog open={props.open} onClose={props.onCancel} size="sm" aria-labelledby="add-or-edit-synonyms-dialog-id">
            <DialogTitle closeButton onClose={props.onCancel}>
                <DialogHeader id="add-or-edit-synonyms-dialog-id">{Messages.ADD_AND_REMOVE_TERMS.getMessage()}</DialogHeader>
            </DialogTitle>
            <DialogContent>
                <Grid item style={{ border: `1px solid ${conflictsPalette.border}` }}>
                    <>
                        <Hotkey hotkey={Enter} onDown={onAddSynonymTerm}>
                            <div style={synonymInputContainer} className="synonym-terms-container">
                                <div style={{ width: "100%" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2px" }}>
                                        <span style={{ marginLeft: "25px" }}>
                                            {`${Messages.TERMS.getMessage()}`}{" "}
                                            <span style={{ color: conflictsPalette.primary.Galaxy }}>{`${synonymTerms.length}/20 ${Messages.MAX_TERMS.getMessage()}`}</span>
                                        </span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <InputText
                                            onChange={onNewSynonymTermChange}
                                            value={newSynonymTerm}
                                            id="synonym-edit-dialog-synonym-term"
                                            inputRef={synonymTermInputRef}
                                            aria-label="Synonym Term"
                                            style={synonymTermInput}
                                            placeholder={Messages.ENTER_NEW_SYNONYM_TERM.getMessage()}
                                            autofocus={props.focusInput}
                                        />
                                        <Button
                                            iconButton
                                            startIcon={<AddIcon style={{ fontSize: "large", borderRadius: "50%" }} />}
                                            aria-label={Messages.BUTTON_ADD_SYNONYM_TERM.getMessage()}
                                            title={Messages.BUTTON_ADD_SYNONYM_TERM.getMessage()}
                                            onClick={onAddSynonymTerm}
                                            color="primary"
                                            rounded
                                            disabled={!canAddSynonymTerm || newSynonymTerm?.trim() == ""}
                                            style={{ alignSelf: "flex-start", margin: "4px 17px 0 15px", height: "fit-content" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Hotkey>
                        <ListBox contents={synonymTerms} onChange={handleTermUpdate} style={{ height: "350px", overflowY: "auto", padding: "0 0 15px 10px" }} />
                    </>
                </Grid>
            </DialogContent>
            <DialogFooter>
                <>
                    <Button text={SharedMessages.BUTTON_CANCEL.getMessage()} title={SharedMessages.BUTTON_CANCEL.getMessage()} color="secondary" onClick={props.onCancel} size="medium" />
                    <Button
                        data-testid="SynonymTermsDialog-save-button"
                        title={SharedMessages.BUTTON_SAVE.getMessage()}
                        text={SharedMessages.BUTTON_SAVE.getMessage()}
                        onClick={onSave}
                        size="medium"
                        disabled={!canSaveTerms}
                    />
                </>
            </DialogFooter>
        </Dialog>
    );
}
