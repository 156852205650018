import { SearchVersion } from "aderant-conflicts-models";
import { HitResult } from "pages/ResultsPage/HitResult";
import { SearchRequestRow } from "pages/SearchesPage/SearchRequestRow";

export enum RefinedResults {
    SEARCHES_PAGE = "searchesPage",
    RESULTS_PAGE = "resultsPage"
}
//Make sure to update this if more pages added requiring DataGridWithRefiner component.
export type PageRefinerPayload = { results: SearchRequestRow[]; refinedResultKey: RefinedResults.SEARCHES_PAGE } | { results: HitResult[]; refinedResultKey: RefinedResults.RESULTS_PAGE };

export type ViewByRefiner = {
    name: string;
    path: string;
    ids: (string | null | undefined)[];
};

export enum ViewByNames {
    CREATED_BY = "CREATED_BY",
    REQUESTED_BY = "REQUESTED_BY",
    ASSIGNED_TO = "ASSIGNED_TO"
}

export enum ViewByPaths {
    CREATED_BY_USER_ID = "createdByUserId",
    REQUESTED_BY_USER_ID = "requestedByUserId",
    ASSIGNED_TO_USER_ID = "assignedToUserId"
}

export const VIEW_BY_FILTERS: { name: string; path: keyof SearchVersion }[] = [
    {
        name: ViewByNames.CREATED_BY,
        path: ViewByPaths.CREATED_BY_USER_ID
    },
    {
        name: ViewByNames.REQUESTED_BY,
        path: ViewByPaths.REQUESTED_BY_USER_ID
    },
    {
        name: ViewByNames.ASSIGNED_TO,
        path: ViewByPaths.ASSIGNED_TO_USER_ID
    }
];
