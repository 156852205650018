import { ValidationErrors, SearchStatus, wrapValidationErrors } from "../..";
import { isValidSearchStatus } from "../../SearchVersion";
import { Messages } from "./Messages";

export function validateSearchStatus(status: string): ValidationErrors | string {
    let message;

    if (status === null) {
        message = Messages.VLD_NULL_VALUE;
    } else if (status.trim().length === 0) {
        message = Messages.VLD_EMPTY_STRING_VALUE;
    } else if (!isValidSearchStatus(status)) {
        message = Messages.VLD_INVALID_VALUE;
    }

    if (message) {
        return wrapValidationErrors([message.asValidationMessage("Status")]);
    }

    return status;
}
