import { APIs, TokenAuthContext } from "aderant-conflicts-common";
import { SearchService } from "./searchService";
import { SearchStorageService } from "./storageService";
import { UserService } from "./userService";
import { AdminService } from "./adminService";
export type Services = {
    searchStorageService: SearchStorageService;
    searchService: SearchService;
    entityStorageService: APIs.EntityStore.EntityStoreProxy;
    userService: UserService;
    adminService: AdminService;
};

export function createServices(context: TokenAuthContext): Services {
    const fssApi = APIs.getSearchStoreProxy(context);
    const entityStoreApi = APIs.EntityStore.getEntityStoreProxy(context);
    const searchServiceApi = APIs.getSearchServiceProxy(context);
    const adminServiceApi = APIs.Admin.getAdminApiProxy(context);
    return {
        searchStorageService: new SearchStorageService(context.logger, fssApi),
        searchService: new SearchService(context.logger, searchServiceApi),
        entityStorageService: entityStoreApi,
        userService: new UserService(context),
        adminService: new AdminService(context.logger, adminServiceApi)
    };
}
