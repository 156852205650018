export function hasString<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]: string } {
    return isObject(input) && input[expectedKey] !== undefined && typeof input[expectedKey] === "string";
}

export function isString(input: unknown): input is string {
    return typeof input === "string";
}

export function isStringIfDefined<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]?: string } {
    return isObject(input) && (input[expectedKey] === undefined || typeof input[expectedKey] === "string");
}

export function hasBoolean<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]: boolean } {
    return isObject(input) && input[expectedKey] !== undefined && typeof input[expectedKey] === "boolean";
}
export function isBooleanIfDefined<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]?: boolean } {
    return isObject(input) && (input[expectedKey] === undefined || typeof input[expectedKey] === "boolean");
}

export function hasBooleanTrue<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]: true } {
    return isObject(input) && input[expectedKey] !== undefined && input[expectedKey] === true;
}
export function isBooleanTrueIfDefined<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]?: true } {
    return isObject(input) && (input[expectedKey] === undefined || input[expectedKey] === true);
}
export function hasBooleanFalse<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]: false } {
    return isObject(input) && input[expectedKey] !== undefined && input[expectedKey] === false;
}
export function isBooleanFalseIfDefined<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]?: false } {
    return isObject(input) && (input[expectedKey] === undefined || input[expectedKey] === false);
}

export function hasNumber<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]: number } {
    return isObject(input) && input[expectedKey] !== undefined && typeof input[expectedKey] === "number";
}
export function isNumberIfDefined<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]?: number } {
    return isObject(input) && (input[expectedKey] === undefined || typeof input[expectedKey] === "number");
}

export function hasArray<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]: unknown[] } {
    return isObject(input) && input[expectedKey] !== undefined && Array.isArray(input[expectedKey]);
}
export function hasArrayOfType<Key extends string, T>(input: unknown, expectedKey: Key, typeGuard: (input: unknown) => input is T): input is { [k in Key]: T[] } {
    return hasArray(input, expectedKey) && input[expectedKey].every(typeGuard);
}
export function isArrayIfDefined<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]?: unknown[] } {
    return isObject(input) && (input[expectedKey] === undefined || Array.isArray(input[expectedKey]));
}

export function hasDate<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]: Date } {
    return isObject(input) && input[expectedKey] !== undefined && isDate(input[expectedKey]);
}
export function isDateIfDefined<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]?: Date } {
    return isObject(input) && (input[expectedKey] === undefined || isDate(input[expectedKey]));
}

export function hasObject<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]: Record<string | number | symbol, unknown> } {
    return isObject(input) && input[expectedKey] !== undefined && isObject(input[expectedKey]);
}
export function isObjectIfDefined<Key extends string>(input: unknown, expectedKey: Key): input is { [k in Key]?: Record<string | number | symbol, unknown> } {
    return isObject(input) && (input[expectedKey] === undefined || isObject(input[expectedKey]));
}

export function isObject(input: unknown): input is Record<string | number | symbol, unknown> {
    return input !== null && input !== undefined && typeof input === "object";
}

export function isStringKeyedRecord(input: unknown): input is Record<string, unknown> {
    return input !== null && input !== undefined && typeof input === "object" && Object.keys(input).every(isString);
}

export function isDate(input: unknown): input is Date {
    return input instanceof Date || (typeof input === "object" && Object.prototype.toString.call(input) === "[object Date]");
}

export function isValidDateString(input: string): boolean {
    return Number.isNaN(Date.parse(input)) === false;
}
