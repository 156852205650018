import { Message } from "aderant-conflicts-models";

export const Messages = {
    HIT_INFORMATION_TILE_TITLE: new Message("HIT_INFORMATION_TILE_TITLE", "Hit Information"),
    HIT_STATUS_LABEL: new Message("HIT_STATUS_LABEL", "Hit Status"),
    HIT_OWNER_LABEL: new Message("HIT_OWNER_LABEL", "Hit Owner"),
    ASSIGNED_TO_LABEL: new Message("ASSIGNED_TO_LABEL", "Assigned To"),
    LAST_ACTIVITY_DATE_LABEL: new Message("LAST_ACTIVITY_DATE_LABEL", "Last Activity Date"),
    LAST_UPDATE_LABEL: new Message("LAST_UPDATE_LABEL", "Last Update"),
    SEARCH_TERM_LABEL: new Message("SEARCH_TERM_LABEL", "Search Term"),
    HIT_LOCATION_LABEL: new Message("HIT_LOCATION_LABEL", "Hit Location"),
    HIT_TERM_LABEL: new Message("HIT_TERM_LABEL", "Hit Term"),
    HIT_TEXT_LABEL: new Message("HIT_TEXT_LABEL", "Hit Text"),
    NO_DATA_AVAILABLE_MESSAGE: new Message("NO_DATA_AVAILABLE_MESSAGE", "No Data Available"),
    LOADING_DATA_PLEASE_WAIT_TITLE: new Message("LOADING_DATA_PLEASE_WAIT_TITLE", "Loading data"),
    EXPAND: new Message("EXPAND", "Expand"),
    COLLAPSE: new Message("COLLAPSE", "Collapse")
};
