import { SnackbarAction } from "@aderant/aderant-react-components";
import { action } from "typesafe-actions";

export const userAlertActionTypes = {
    ADD_ALERT: "ADDALERT",
    HIDE_ALERT: "HIDEALERT"
} as const;

export const userAlertActions = {
    addUserAlert: (
        message: string,
        data?: { type?: "error" | "success" | "info"; actions?: SnackbarAction[] }
    ): { type: "ADDALERT"; payload: { message: string; data?: { type?: "error" | "success" | "info"; actions?: SnackbarAction[] } } } => action(userAlertActionTypes.ADD_ALERT, { message, data }),
    hideUserAlert: (id: string): { type: "HIDEALERT"; payload: string } => action(userAlertActionTypes.HIDE_ALERT, id)
};
