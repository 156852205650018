import { ConflictsError, ok, Result, StatusCodes, unexpectedError as createUnexpectedError, UnexpectedError as UnexpectedErrorModel } from "aderant-conflicts-models";
import { OmitStrict } from "aderant-web-fw-core";
import _ from "lodash";

// we don't want the status code to be redundantly sent over the wire in the body as well as in the actual status
// so we strip it from the over the wire type
export type HttpConflictsError<Err extends ConflictsError> = OmitStrict<Err, "httpStatusCode">;

// justification: really this should be Record<string, unknown>, but interfaces are not assignable to that (see TS issue #15300: https://github.com/microsoft/TypeScript/issues/15300)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HttpBody = Record<string, any> | string | void;

/**
 * Azure functions expects {status: number, body?: any, headers?: any} but the type isn't actually explicit.
 * @param status The @type {HttpStatusCode} to send with the response
 * @param body The body object, complex types will be sent as json in the body, strings will just be inline.
 */
export type HttpResponse = {
    readonly status: number;
    readonly body?: HttpBody;
    readonly headers?: Record<string, string>;
};

export interface SuccessResponse<Body extends HttpBody = undefined> extends HttpResponse {
    status: StatusCodes.Success;
    body: Body;
}
export interface SuccessResponseWithHeaders<Body extends HttpBody = undefined> extends SuccessResponse<Body> {
    headers: Record<string, string>;
}

export interface EmptyOkResponse extends SuccessResponse<undefined> {
    status: 204;
    body: undefined;
}
export interface OkResponse<Out extends HttpBody> extends SuccessResponse<Out> {
    status: 200;
    body: Out;
}

export interface OkResponseWithHeaders<Out extends HttpBody> extends SuccessResponseWithHeaders<Out> {
    status: 200;
    body: Out;
    headers: Record<string, string>;
}
export function okResponse<Out extends HttpBody>(body: Out): OkResponse<Out> {
    return { status: 200, body: body };
}
export function okResponseWithHeaders<Out extends HttpBody>(body: Out, headers: Record<string, string>): OkResponseWithHeaders<Out> {
    return { status: 200, body: body, headers: headers };
}
export function emptyOkResponse(): EmptyOkResponse {
    return { status: 204, body: undefined };
}
export interface OkCreatedResponse<Out extends HttpBody = void> extends SuccessResponse<Out> {
    status: 201;
    body: Out;
}
export function okCreatedResponse<Out extends HttpBody = void>(body: Out): OkCreatedResponse<Out> {
    return { status: 201, body: body };
}

export interface OkCreatedResponseWithHeaders<Out extends HttpBody = void> extends SuccessResponseWithHeaders<Out> {
    status: 201;
    body: Out;
    headers: Record<string, string>;
}
export function okCreatedResponseWithHeaders<Out extends HttpBody = void>(body: Out, headers: Record<string, string>): OkCreatedResponseWithHeaders<Out> {
    return { status: 201, body: body, headers: headers };
}

export interface ErrorResponse<Err extends ConflictsError> extends HttpResponse {
    status: StatusCodes.Failure;
    body: HttpConflictsError<Err>;
}

export namespace MultiResponse {
    /**Multiresponse needs all its internal error types to have status codes so that result types are interchangeable with actual multiple separate HTTP calls.
     * So lets make a special UnexpectedError type with the response code built in.
     */
    export type UnexpectedError = UnexpectedErrorModel & { httpStatusCode: 500 };
    export function unexpectedError(cause: unknown, contextInfo: string, mostRecentStatusCode?: number | undefined): UnexpectedError {
        const error = createUnexpectedError(cause, contextInfo, mostRecentStatusCode);
        return { ...error, httpStatusCode: 500 };
    }

    export interface SuccessfulItem<Body extends HttpBody, SuccessStatusCode extends StatusCodes.Success> extends HttpResponse {
        id: string;
        status: SuccessStatusCode;
        body: Body;
        [key: string]: any;
    }

    export interface ErroredItem<ExpectedErrors extends ConflictsError> extends HttpResponse {
        id: string;
        status: ExpectedErrors["httpStatusCode"];
        body: ExpectedErrors;
        [key: string]: any;
    }

    export type Item<Body extends HttpBody, SuccessStatusCode extends StatusCodes.Success, ExpectedErrors extends ConflictsError> =
        | SuccessfulItem<Body, SuccessStatusCode>
        | ErroredItem<ExpectedErrors>;

    export function isSuccessItem<Body extends HttpBody, SuccessStatusCode extends StatusCodes.Success, ExpectedErrors extends ConflictsError>(
        item: Item<Body, SuccessStatusCode, ExpectedErrors>
    ): item is SuccessfulItem<Body, SuccessStatusCode> {
        return item.status >= 200 && item.status < 300;
    }

    //special case: when all items are initially success, there are no original errors to carry across
    export function applyToEachSuccess<Body extends HttpBody, SuccessStatus extends StatusCodes.Success, NewBody extends HttpBody, NewExpectedErrors extends ConflictsError>(
        items: SuccessfulItem<Body, SuccessStatus>[],
        func: (body: Body) => Result<NewBody, NewExpectedErrors>
    ): Array<Item<NewBody, SuccessStatus, NewExpectedErrors>>;
    //special case: when the function being applied does not add any new error types, we do not need to combine new error types with old error types
    export function applyToEachSuccess<Body extends HttpBody, SuccessStatus extends StatusCodes.Success, ExpectedErrors extends ConflictsError, NewBody extends HttpBody>(
        items: Item<Body, SuccessStatus, ExpectedErrors>[],
        func: (body: Body) => Result<NewBody, ExpectedErrors>
    ): Array<Item<NewBody, SuccessStatus, ExpectedErrors>>;
    //standard case (same signature twice as TS hides the implementation signature by default)
    export function applyToEachSuccess<
        Body extends HttpBody,
        SuccessStatus extends StatusCodes.Success,
        ExpectedErrors extends ConflictsError,
        NewBody extends HttpBody,
        NewExpectedErrors extends ConflictsError
    >(items: Item<Body, SuccessStatus, ExpectedErrors>[], func: (body: Body) => Result<NewBody, NewExpectedErrors>): Array<Item<NewBody, SuccessStatus, NewExpectedErrors | ExpectedErrors>>;
    /**
     * Apply a function that processes a single item to each successful item in an array of MultiResponse items and return the results, alongside any existing errors
     * @param items the items to process
     * @param func the function to run on each successful item
     * @returns a new set of items combining the existing failures with the successes and failures from running func on the existing successes
     */
    export function applyToEachSuccess<
        Body extends HttpBody,
        SuccessStatus extends StatusCodes.Success,
        ExpectedErrors extends ConflictsError,
        NewBody extends HttpBody,
        NewExpectedErrors extends ConflictsError
    >(items: Item<Body, SuccessStatus, ExpectedErrors>[], func: (body: Body) => Result<NewBody, NewExpectedErrors>): Array<Item<NewBody, SuccessStatus, NewExpectedErrors | ExpectedErrors>> {
        const successItems: SuccessfulItem<NewBody, SuccessStatus>[] = [];
        const errorItems: ErroredItem<NewExpectedErrors | ExpectedErrors>[] = []; //could do this with a map into one collection of items, but pushing into two arrays makes the errors easier to understand
        items.forEach((i) => {
            if (isSuccessItem(i)) {
                const result = func(i.body);
                if (ok(result)) {
                    successItems.push({
                        id: i.id,
                        status: i.status,
                        body: result
                    });
                } else {
                    const error: NewExpectedErrors = result;
                    errorItems.push({
                        id: i.id,
                        status: error.httpStatusCode,
                        body: error
                    });
                }
            } else {
                errorItems.push(i);
            }
        });
        return [...successItems, ...errorItems];
    }

    //special case: when all items are initially success, there are no original errors to carry across
    export function applyToEachSuccessAsyncSequentially<Body extends HttpBody, SuccessStatus extends StatusCodes.Success, NewBody extends HttpBody, NewExpectedErrors extends ConflictsError>(
        items: SuccessfulItem<Body, SuccessStatus>[],
        func: (body: Body) => Promise<Result<NewBody, NewExpectedErrors>>
    ): Promise<Array<Item<NewBody, SuccessStatus, NewExpectedErrors>>>;
    //special case: when the function being applied does not add any new error types, we do not need to combine new error types with old error types
    export function applyToEachSuccessAsyncSequentially<Body extends HttpBody, SuccessStatus extends StatusCodes.Success, ExpectedErrors extends ConflictsError, NewBody extends HttpBody>(
        items: Item<Body, SuccessStatus, ExpectedErrors>[],
        func: (body: Body) => Promise<Result<NewBody, ExpectedErrors>>
    ): Promise<Array<Item<NewBody, SuccessStatus, ExpectedErrors>>>;
    //standard case (same signature twice as TS hides the implementation signature by default)
    export function applyToEachSuccessAsyncSequentially<
        Body extends HttpBody,
        SuccessStatus extends StatusCodes.Success,
        ExpectedErrors extends ConflictsError,
        NewBody extends HttpBody,
        NewExpectedErrors extends ConflictsError
    >(
        items: Item<Body, SuccessStatus, ExpectedErrors>[],
        func: (body: Body) => Promise<Result<NewBody, NewExpectedErrors>>
    ): Promise<Array<Item<NewBody, SuccessStatus, NewExpectedErrors | ExpectedErrors>>>;
    /**
     * This will apply the passed in function *sequentially*. Only use this in cases where the results of the async calls made by the first iteration are cached (currently this use case is calls to PermissionContext).
     * If you instead need something that will launch each call simultaneously and then wait for all with Promise.all, consider writing a separate 'applyToEachSuccessConcurrently' version
     * and expose some way to throttle so you don't send 100 http requests to the same Azure service at the same time and just get rate limited.
     * @param items the items to process
     * @param func the (async) function to run on each successful item
     * @returns a promise resolving to new set of items combining the existing failures with the successes and failures from running func on the existing successes
     */
    export async function applyToEachSuccessAsyncSequentially<
        Body extends HttpBody,
        SuccessStatus extends StatusCodes.Success,
        ExpectedErrors extends ConflictsError,
        NewBody extends HttpBody,
        NewExpectedErrors extends ConflictsError
    >(
        items: Item<Body, SuccessStatus, ExpectedErrors>[],
        func: (body: Body) => Promise<Result<NewBody, NewExpectedErrors>>
    ): Promise<Array<Item<NewBody, SuccessStatus, NewExpectedErrors | ExpectedErrors>>> {
        const successItems: SuccessfulItem<NewBody, SuccessStatus>[] = [];
        const errorItems: ErroredItem<NewExpectedErrors | ExpectedErrors>[] = []; //could do this with a map into one collection of items, but pushing into two arrays makes the errors easier to understand
        for (const i of items) {
            if (isSuccessItem(i)) {
                const result = await func(i.body);
                if (ok(result)) {
                    successItems.push({
                        id: i.id,
                        status: i.status,
                        body: result
                    });
                } else {
                    const error: NewExpectedErrors = result;
                    errorItems.push({
                        id: i.id,
                        status: error.httpStatusCode,
                        body: error
                    });
                }
            } else {
                errorItems.push(i);
            }
        }
        return [...successItems, ...errorItems];
    }

    /**
     * Apply a function that processes multiple items to all successful items in an array of MultiResponse items and return the results, alongside any existing errors
     * @param items the items to process
     * @param func the function to apply to the successes
     * @returns a new set of items combining the existing failures with the successes and failures from running func on the existing successes
     */
    export function applyToAllSuccesses<
        Body extends HttpBody,
        SuccessStatus extends StatusCodes.Success,
        ExpectedErrors extends ConflictsError,
        NewBody extends HttpBody,
        NewExpectedErrors extends ConflictsError
    >(
        items: Item<Body, SuccessStatus, ExpectedErrors>[],
        func: (items: SuccessfulItem<Body, SuccessStatus>[]) => Item<NewBody, SuccessStatus, NewExpectedErrors>[]
    ): Array<Item<NewBody, SuccessStatus, NewExpectedErrors | ExpectedErrors>> {
        const [successes, failures] = _.partition(items, isSuccessItem);
        if (successes.length > 0) {
            const results = func(successes);
            return [...results, ...failures];
        } else {
            return failures;
        }
    }

    /**
     * Apply a function that processes multiple items to all successful items in an array of MultiResponse items and return the results, alongside any existing errors
     * @param items the items to process
     * @param func the function to apply to the successes
     * @returns a new set of items combining the existing failures with the successes and failures from running func on the existing successes
     */
    export async function applyToAllSuccessesAsync<
        Body extends HttpBody,
        SuccessStatus extends StatusCodes.Success,
        ExpectedErrors extends ConflictsError,
        NewBody extends HttpBody,
        NewExpectedErrors extends ConflictsError
    >(
        items: Item<Body, SuccessStatus, ExpectedErrors>[],
        func: (items: SuccessfulItem<Body, SuccessStatus>[]) => Promise<Item<NewBody, SuccessStatus, NewExpectedErrors>[]>
    ): Promise<Array<Item<NewBody, SuccessStatus, NewExpectedErrors | ExpectedErrors>>> {
        const [successes, failures] = _.partition(items, isSuccessItem);
        if (successes.length > 0) {
            const results = await func(successes);
            return [...results, ...failures];
        } else {
            return failures;
        }
    }

    export interface Response<Body extends HttpBody, SuccessStatusCode extends StatusCodes.Success, ExpectedErrors extends ConflictsError>
        extends SuccessResponse<Item<Body, SuccessStatusCode, ExpectedErrors>[]> {
        status: 207;
        body: Item<Body, SuccessStatusCode, ExpectedErrors>[];
    }

    export function response<Body extends HttpBody, SuccessStatus extends StatusCodes.Success, ExpectedError extends ConflictsError>(
        body: Item<Body, SuccessStatus, ExpectedError>[]
    ): Response<Body, SuccessStatus, ExpectedError> {
        return { status: 207, body: body };
    }

    export interface ResponseWithHeaders<Body extends HttpBody, SuccessStatusCode extends StatusCodes.Success, ExpectedErrors extends ConflictsError>
        extends SuccessResponse<Item<Body, SuccessStatusCode, ExpectedErrors>[]> {
        status: 207;
        body: Item<Body, SuccessStatusCode, ExpectedErrors>[];
        headers: Record<string, string>;
    }

    export function responseWithHeaders<Body extends HttpBody, SuccessStatus extends StatusCodes.Success, ExpectedError extends ConflictsError>(
        body: Item<Body, SuccessStatus, ExpectedError>[],
        headers: Record<string, string>
    ): ResponseWithHeaders<Body, SuccessStatus, ExpectedError> {
        return { status: 207, body: body, headers: headers };
    }
}
