import { getSearchStatusDisplayValue, getStatusesUsersCanManuallyChangeSearchToDirect, PermissionsContextDirect, SearchStatus, SearchSummary } from "aderant-conflicts-models";
import { ComboBox, ComboBoxItem } from "@aderant/aderant-react-components";
import { RootState } from "MyTypes";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getCreatedSearchesByIds, usePermissionsContext } from "state/selectors";

export type RequestStatusComboBoxProps = {
    text?: string;
    onItemSelected: (x: SearchStatus) => void;
    searchIds: string[];
};

const prepareRequestStatusItemsBasedOnUser = (permissions: PermissionsContextDirect, searchSummaries?: SearchSummary[]): ComboBoxItem<SearchStatus>[] => {
    const statuses: SearchStatus[] = [];

    searchSummaries?.forEach(async (summary: SearchSummary) => {
        const allowedStatuses = getStatusesUsersCanManuallyChangeSearchToDirect(permissions, summary);
        if (allowedStatuses !== statuses) {
            const statusesToAdd = allowedStatuses.filter((status) => !statuses.includes(status));
            if (statusesToAdd.length > 0) {
                statuses.push(...statusesToAdd);
            }
        }
    });
    const displayValues = statuses.map((s) => ({ value: s, displayName: getSearchStatusDisplayValue(s) }));
    return displayValues;
};

export const RequestStatusComboBox = ({ searchIds, ...props }: RequestStatusComboBoxProps): JSX.Element | null => {
    const [items, setItems] = useState<ComboBoxItem<SearchStatus>[]>([]);
    const permissions = usePermissionsContext();

    const searchSummaries: SearchSummary[] | undefined = useSelector((rootState: RootState) => {
        return getCreatedSearchesByIds(rootState, searchIds);
    }, shallowEqual);

    useEffect(() => {
        const options = prepareRequestStatusItemsBasedOnUser(permissions, searchSummaries);
        setItems(options);
    }, [searchIds]);

    const onItemSelected = (i: ComboBoxItem<SearchStatus>): void => {
        if (i.value) {
            props.onItemSelected(i.value);
        }
    };

    if (items.length > 0) {
        return <ComboBox text={props.text} items={items} onItemSelected={onItemSelected} />;
    } else {
        console.debug("Rendering nothing for RequestStatusComboBox as there are no selectable items.");
        return null;
    }
};

//Prop defaults.
RequestStatusComboBox.defaultProps = {
    text: "Request Status",
    searchIds: []
};
