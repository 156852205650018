import { AderantTheme } from "@aderant/aderant-react-components";
import React from "react";
import { conflictsPalette } from "styles/conflictsPalette";

export type DisplayTileProps = {
    text: string;
    colour: string;
    count: number;
};

export const DisplayTile = ({ count, text, colour }: DisplayTileProps): JSX.Element => {
    return (
        <div
            style={{
                backgroundColor: conflictsPalette.background.grey,
                border: `1px solid ${conflictsPalette.border}`,
                borderRadius: "6px",
                padding: "10px",
                width: "128px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "10px 15px"
            }}
        >
            {/* Have increased the font px size from design so that the calculated px / rendered rem matches the design */}
            <p style={{ fontSize: AderantTheme.typography.pxToRem(37), fontWeight: 600, margin: 0, color: colour }}>{count}</p>
            <p style={{ margin: 0, transform: `translateY(-${AderantTheme.typography.pxToRem(8)}`, fontSize: AderantTheme.typography.pxToRem(15), textAlign: "center" }}>{text}</p>
        </div>
    );
};
