import React from "react";
import { Snackbar } from "@aderant/aderant-react-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "MyTypes";
import { getVisibleUserAlerts } from "state/selectors/userAlertSelectors";
import { userAlertActions } from "state/actions/UserAlertActions";

/**
 * This control displays user alerts to the user.  Currently pops up an error type Snackbar, but could
 * display different controls for different alerts if necessary.
 * @returns
 */
export function UserAlertDisplay() {
    const dispatch = useDispatch();
    const userAlertsToShow = useSelector((rootState: RootState) => {
        return getVisibleUserAlerts(rootState.userAlert);
    });

    const alertToShow = userAlertsToShow.length > 0 ? userAlertsToShow[0] : null;

    const onClose = () => {
        if (alertToShow) {
            dispatch(userAlertActions.hideUserAlert(alertToShow.id));
        }
    };

    return (
        <Snackbar
            open={!!alertToShow}
            message={alertToShow?.message ?? ""}
            onClose={onClose}
            alertType={alertToShow?.data?.type ?? "success"}
            direction="bottom-center"
            actions={alertToShow?.data?.actions ?? null}
        />
    );
}
