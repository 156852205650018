import { UserRole } from "..";

export enum ConflictsAction {
    ViewSettingsPage,
    BypassRowLevelSecurity, //Skip checking RLS for this user.  ASSIGN WITH EXTREME CAUTION
    CanRefineSearches,
    ChangeStatusOnCompleteSearches,
    ChangeHitStatusOnOthersSearches, //noone is allowed to do this atm, but I chucked it in the list and check it in code for completions sake
    ChangeHitStatusOnOwnSearches,
    CreateSearchVersion,
    EditSubmittedSearch,
    PerformSearch, //Not fully consumed
    ReassignSearchesAssignedToOthers,
    ReassignSearchesAssignedToSelf,
    ReassignHitsAssignedToOthers,
    ReassignHitsAssignedToSelf,
    SubmitSearch,
    UpdateHitResultGridConfiguration, //Can customize the Hit Result Grid Configuration
    ViewAndEditFirmOptions,
    ViewAndPerformQuickSearches,
    ViewAndUpdateEntityFlyoutConfiguration, //Can customize the Entity flyout
    ViewAndUpdateEntityRelationshipConfiguration, //Can customize the Entity Relationship Configuration
    ViewAndUpdateSynonymMaps,
    ViewAnyEntityData, //Can query any entity via the EntityStore'
    ViewHitResultGridConfiguration, //Can retrieve the Hit Result Grid Configuration - needed for viewing the Hit Result grid
    ViewIngestionPipelineStatus,
    ViewOthersDraftSearches, //this one should never be given to anybody, it's just here for consistency with error reporting
    ViewOthersSearches,
    ViewOwnSearches,
    ViewAndUpdateRlsColumnVisibility, //Can choose fields to un-redact when an entity is secured with row level security
    /**DONT PUT THESE TWO ON ANYTHING OTHER THAN ApiAdmin PLEASE */
    ViewAndCreateApiKeys,
    RevokeApiKeys
    /**------------------------------------------------------ */
}

type UserRoleKey = keyof typeof UserRole;

export const UserPermissionsForRole: Record<UserRoleKey, ConflictsAction[]> = {
    ["Conflicts"]: [
        ConflictsAction.ViewOwnSearches,
        ConflictsAction.ViewOthersSearches,
        ConflictsAction.ReassignSearchesAssignedToSelf,
        ConflictsAction.ReassignSearchesAssignedToOthers,
        ConflictsAction.ReassignHitsAssignedToSelf,
        ConflictsAction.ReassignHitsAssignedToOthers,
        ConflictsAction.ChangeHitStatusOnOwnSearches,
        ConflictsAction.ChangeStatusOnCompleteSearches,
        ConflictsAction.SubmitSearch,
        ConflictsAction.EditSubmittedSearch,
        ConflictsAction.PerformSearch,
        ConflictsAction.CreateSearchVersion,
        ConflictsAction.ViewAnyEntityData,
        ConflictsAction.ViewHitResultGridConfiguration,
        ConflictsAction.ViewAndPerformQuickSearches
    ],
    ["Standard"]: [
        ConflictsAction.ViewOwnSearches,
        ConflictsAction.ReassignSearchesAssignedToSelf,
        ConflictsAction.ReassignHitsAssignedToSelf,
        ConflictsAction.ChangeHitStatusOnOwnSearches,
        ConflictsAction.SubmitSearch,
        ConflictsAction.EditSubmittedSearch,
        ConflictsAction.ViewAnyEntityData,
        ConflictsAction.ViewHitResultGridConfiguration
    ],
    ["Admin"]: [
        ConflictsAction.ViewSettingsPage,
        ConflictsAction.ViewAndUpdateSynonymMaps,
        ConflictsAction.ViewAndUpdateEntityFlyoutConfiguration,
        ConflictsAction.ViewAndUpdateEntityRelationshipConfiguration,
        ConflictsAction.ViewHitResultGridConfiguration,
        ConflictsAction.UpdateHitResultGridConfiguration,
        ConflictsAction.ViewIngestionPipelineStatus,
        ConflictsAction.ViewAndEditFirmOptions,
        ConflictsAction.ViewAndUpdateRlsColumnVisibility
    ],
    ["ApiAdmin"]: [ConflictsAction.ViewAndCreateApiKeys, ConflictsAction.RevokeApiKeys, ConflictsAction.ViewSettingsPage],
    ["SecurityPlanExempt"]: [ConflictsAction.BypassRowLevelSecurity],
    ["None"]: []
};
