import { Message } from "aderant-conflicts-models";

export const Messages = {
    HEADER_SEARCH_DATE: new Message("HEADER_SEARCH_DATE", "Search results as of: "),
    HEADER_REQUEST_STATUS: new Message("HEADER_REQUEST_STATUS", "Request status: "),
    HEADER_REQUESTED_BY: new Message("HEADER_REQUESTED_BY", "Requested by: "),
    HEADER_DESCRIPTION: new Message("HEADER_DESCRIPTION", "Description: "),
    NO_DATA: new Message("NO_DATA", "-"),
    PAGE: new Message("PAGE", "Page: "),
    FROM: new Message("FROM", "From: "),
    TO: new Message("TO", "To: "),
    N_A: new Message("N_A", "N/A"),
    RESOLVED: new Message("RESOLVED", "Resolved"),
    HITS: new Message("HITS", "hits"),
    TERMS: new Message("TERMS", "Terms"),
    REQUEST_TERMS: new Message("REQUEST_TERMS", "Request Terms"),
    SEARCH_TERMS: new Message("SEARCH_TERMS", "Search Terms"),
    TERM: new Message("TERM", "Term:"),
    REQUEST_TERM: new Message("REQUEST_TERM", "Request Term:"),
    SEARCH_TERM: new Message("SEARCH_TERM", "Search Term:"),
    APPLIED_REFINERS: new Message("APPLIED_REFINERS", "Applied Refiners"),
    REFINED_VALUES: new Message("REFINED_VALUES", "Refined Values"),
    REDACTED: new Message("REDACTED", "**Redacted**"),
    CONFLICTING_PARTY: new Message("CONFLICTING_PARTY", "Conflicting Party:"),
    IS_REDACTED: new Message("IS_REDACTED", "Is Redacted:"),
    NOTE: new Message("NOTE", "Note:"),
    REFINER_NOTE: new Message("REFINER_NOTE", "The results included in this report are a subset of hits based on selected refiners. The selected refiners are listed in the table below."),
    TOO_MANY_RESULTS: new Message(
        "TOO_MANY_RESULTS",
        "The report could not be printed because there are more than 4,000 hit results. Use the refiners to reduce the number of hit results and try again."
    )
};
