import { DialogTitle } from "@aderant/aderant-react-components";
import { styled } from "@mui/material";

export const TrimmedHeader = styled(DialogTitle)(({ theme }) => ({
    ["& > h2"]:  {
        textAlign: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    }
}));