import { DiagnosticInformation, EmailWithContext } from "aderant-conflicts-models";
import { OkResponse } from "../../..";
import { AzureKeyAuthFunctionDefinition, AzureQueueFunctionDefinition } from "../../AzureFunctionDefinition";

import { FunctionAppContext, UserlessKeyAuthFunctionAppContext } from "../../ConflictsContext";
import { EmailProvider } from "../../Email/EmailProvider";
import { getFunctionAppUrl } from "../../Config/EnvironmentService";

const emailQueueName = "email-queue-items";

export const EmailService = {
    queueName: emailQueueName,
    queueTriggerFunctions: {
        processEmailMessage: new AzureQueueFunctionDefinition<EmailWithContext>({ queueName: emailQueueName, bindingVariableName: "emailQueueItem" })
    },
    getHealth: new AzureKeyAuthFunctionDefinition<undefined, DiagnosticInformation, OkResponse<DiagnosticInformation>, never>({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: () => `./api/Health`
    })
};

//The EmailServiceProxy is for checking the status of the email service
//Re-enable once https://dev.azure.com/Aderant/Conflicts/_workitems/edit/8128 is completed
// export type EmailServiceProxy = Awaited<ReturnType<typeof getKeyAuthEmailServiceProxy>>;

// export async function getKeyAuthEmailServiceProxy(context: UserlessKeyAuthFunctionAppContext) {
//     const baseURL: URL = getFunctionAppUrl("EmailService");
//     const hostKey = await context.getSharedFunctionHostKey("EmailService");

//     return {
//         getHealth: EmailService.getHealth.getKeyAuthProxyForMonitoring(baseURL, context.logger, hostKey)
//     };
// }

export type EmailProviderProxy = ReturnType<typeof getEmailProviderProxy>;

//justification: return type is necessarily defined by the function itself - to explicitly define it in the function signature
//would be unnecessarily verbose
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getEmailProviderProxy(context: FunctionAppContext) {
    const enqueueEmail = await EmailService.queueTriggerFunctions.processEmailMessage.getAppEnqueueMessage(context);
    const emailProvider = new EmailProvider(context, enqueueEmail);
    return emailProvider;
}
