import {
    Audit,
    currentUserCanViewSearchDirect,
    DurableHitIdentifier,
    HitComment,
    isNewSearchSummary,
    isSearchedStatus,
    isSearchingStatusSearchSummary,
    QuickSearch,
    QuickSearchSummary,
    RequestTerm,
    SearchSummary,
    SearchSummaryNew,
    SearchVersion,
    SearchVersionNew
} from "aderant-conflicts-models";
import { RootState } from "MyTypes";
import { createSelector } from "reselect";
import { ViewByRefiner } from "state/reducers/ReducerStateTypes";
import { SearchState } from "state/reducers/searchReducers";
import { getPermissionsContext } from "./appSelectors";
import uuid from "uuid";
import _ from "lodash";

export const hasSearches = (state: SearchState): boolean => state.searchSummaries.length > 0;
export const getIsFetchingCurrentSearch = (state: RootState, searchId: string): boolean => (state.search.fetchingSearchId == searchId ? true : false);
export const getIsFetchingFlyoutEntityDetail = (state: RootState, entityId: string): boolean => (state.search.hitFlyoutDetail.requestedId === entityId ? true : false);

const isNotNewSearchSummary = (searchSummary: SearchSummary | SearchSummaryNew): searchSummary is SearchSummary => !isNewSearchSummary(searchSummary);
const isQuickSearchSummary = (searchSummary: SearchSummary | QuickSearchSummary): searchSummary is QuickSearchSummary => searchSummary.isQuickSearch === true;

const getFullSearchSummaries = (root: RootState) => root.search.searchSummaries.filter((s) => !s.isQuickSearch);
const getQuickSearchSummaries = (root: RootState): QuickSearchSummary[] => root.search.searchSummaries.filter(isNotNewSearchSummary).filter(isQuickSearchSummary);
const getLoggedInUser = (root: RootState) => root.app.loggedInUser;
export const getAllSearchSummaries = createSelector([getPermissionsContext, getFullSearchSummaries, getLoggedInUser], (permissions, summaries, user) => {
    if (user && summaries) {
        return summaries.filter((searchSummary) => (permissions ? currentUserCanViewSearchDirect(permissions, searchSummary) : false));
    } else {
        return [];
    }
});

export const getAllQuickSearchSummaries = createSelector([getPermissionsContext, getQuickSearchSummaries, getLoggedInUser], (permissions, summaries, user) => {
    if (user && summaries) {
        return summaries.filter((searchSummary) => (permissions ? permissions.currentUserId === searchSummary.createdByUserId : false));
    } else {
        return [];
    }
});

export const getAllCreatedSearchSummaries = createSelector([getAllSearchSummaries], (summaries) => summaries.filter(isNotNewSearchSummary));

const isSearchingSearchSummary = (searchSummary: SearchSummary | SearchSummaryNew): searchSummary is SearchSummary => isSearchingStatusSearchSummary(searchSummary);

export const getAllSearchingSearchSummaries = createSelector(getAllSearchSummaries, (summaries) => summaries.filter(isSearchingSearchSummary));
export const getAllSearchingQuickSearchSummaries = createSelector(getAllQuickSearchSummaries, (summaries) => summaries.filter(isSearchingSearchSummary));

export const getIsSearching = (state: RootState): boolean => (state.search.isSearching == 0 || state.search.currentSearch.searchVersion?.status === "SEARCHING" ? false : true);

export const getIsSearchLoaded = (state: SearchState, searchId: string): boolean => !!state.currentSearch.searchVersion && state.currentSearch.searchVersion.searchId === searchId;

export const getIsNewSearch = (state: RootState): boolean => state.search.currentSearch.isNewSearch;
export const getIsCurrentSearchQuickSearch = (state: RootState): boolean | undefined =>
    state.search.currentSearch.searchVersion ? state.search.currentSearch.searchVersion.isQuickSearch ?? false : undefined;

export const getIsCurrentSearchSaving = (state: RootState): boolean => (state.search.currentSearch.isSavingRefCount === 0 && !state.search.currentSearch.isPendingChanges ? false : true);

const isSearchedSearchSummary = (searchSummary: SearchSummary | SearchSummaryNew): searchSummary is SearchSummary => isSearchedStatus(searchSummary.status);

export const getAllSearchedSearchSummaries = createSelector(getAllSearchSummaries, (summaries) => summaries.filter(isSearchedSearchSummary));

export const getIsSearchedState = (state: RootState, id: string): boolean => getAllSearchedSearchSummaries(state).some((s: SearchSummary) => s.id === id);

export const getCreatedSearchesByIds = (state: RootState, ids: string[]) => getAllCreatedSearchSummaries(state).filter((s: SearchSummary) => ids.includes(s.id));

const getViewByRefiners = (state: RootState) => state.refiners.viewByRefiners;
export const getCreatedSearchesRefinedByViewRefiner = createSelector([getViewByRefiners, getAllCreatedSearchSummaries], (viewBys, searchSummaries) => {
    const activeViewBys = viewBys.filter((option: ViewByRefiner) => {
        if (option.ids.length > 0) {
            return option;
        }
    });
    let refinedSearches = searchSummaries;
    if (activeViewBys.length > 0) {
        refinedSearches = refinedSearches.filter((summary: SearchSummary) => {
            for (let i = 0; i < activeViewBys.length; i++) {
                if (activeViewBys[i].ids.includes(summary[activeViewBys[i].path])) {
                    return summary;
                }
            }
        });
    }
    return refinedSearches;
});

export type AuditWithRowId = Audit & { rowId: string };
export const getAudits = (state: RootState): Audit[] => {
    return state.search.searchAudits;
};
export const getAuditsWithRowIds = createSelector(getAudits, (audits: Audit[]): AuditWithRowId[] => audits.map((audit: Audit) => ({ ...audit, rowId: audit.id ?? uuid() })));

export const getCurrentSearch = (state: RootState): SearchVersion | SearchVersionNew | QuickSearch | undefined => {
    return state.search.currentSearch.searchVersion;
};
export const getCurrentSearchVersion = (state: RootState): SearchVersion | SearchVersionNew | undefined => {
    if (state.search.currentSearch.searchVersion && state.search.currentSearch.searchVersion?.isQuickSearch) {
        console.warn(`Returning undefined as the current search is a QuickSearch`);
        return undefined;
    }
    return state.search.currentSearch.searchVersion;
};
export const getCurrentQuickSearch = (state: RootState): QuickSearch | undefined => {
    if (state.search.currentSearch.searchVersion && !state.search.currentSearch.searchVersion?.isQuickSearch) {
        console.warn(`Returning undefined as the current search is not a QuickSearch`);
        return undefined;
    }
    return state.search.currentSearch.searchVersion;
};
export const getCurrentSearchIfNotNew = (state: RootState): SearchVersion | QuickSearch | undefined => {
    const currentSearch = state.search.currentSearch.searchVersion;
    if (!currentSearch || currentSearch.editState === "NEW") {
        return undefined;
    }
    return currentSearch;
};

export type RequestTermSummary = Pick<RequestTerm, "term" | "affiliation" | "partyStatus" | "searchTerms"> & {
    rowId: string;
    searchModifiers: string;
};

export const getCurrentSearchTerms = createSelector(getCurrentSearchIfNotNew, (search) =>
    !search
        ? []
        : search.requestTerms.map((t: RequestTerm): RequestTermSummary => {
              return {
                  //The id for the request term should be defined at this point
                  rowId: t.id ?? uuid(),
                  term: t.term,
                  affiliation: t.affiliation,
                  partyStatus: t.partyStatus,
                  searchModifiers: search.applyFuzzySearch ? "fuzzy" : "",
                  searchTerms: t.searchTerms
              };
          })
);

export const isCurrentHitCommentsLoaded = (state: RootState, durableHitIdentifier: DurableHitIdentifier): boolean => {
    if (!durableHitIdentifier.requestTermId || !durableHitIdentifier.hitEntityId || !durableHitIdentifier.hitEntityType) {
        return false;
    }
    if (_.isEqual(durableHitIdentifier, state.search.currentSearch.currentHitComments?.durableHitIdentifier)) {
        return !state.search.currentSearch.currentHitComments!.reloadComments;
    }
    return false;
};

export const getCurrentHitCommentCount = (state: RootState, durableHitIdentifier: DurableHitIdentifier | undefined): number => {
    if (durableHitIdentifier && _.isEqual(durableHitIdentifier, state.search.currentSearch.currentHitComments?.durableHitIdentifier)) {
        return state.search.currentSearch.currentHitComments!.commentCount ?? 0;
    }
    return 0;
};

export const getCurrentHitComments = (state: RootState, durableHitIdentifier: DurableHitIdentifier): HitComment[] | undefined => {
    if (_.isEqual(durableHitIdentifier, state.search.currentSearch.currentHitComments?.durableHitIdentifier)) {
        // if above if statement is true then currentHitComments is defined
        return state.search.currentSearch.currentHitComments!.comments;
    }
    return undefined;
};

export const isCommentsBeingCreated = (state: RootState, durableHitIdentifier: DurableHitIdentifier): boolean => {
    if (_.isEqual(durableHitIdentifier, state.search.currentSearch.currentHitComments?.durableHitIdentifier)) {
        // if above if statement is true then currentHitComments is defined
        return state.search.currentSearch.currentHitComments!.beingCreated.length > 0;
    }
    return false;
};

export const getCommentsBeingUpdated = (state: RootState, durableHitIdentifier: DurableHitIdentifier): string[] => {
    if (_.isEqual(durableHitIdentifier, state.search.currentSearch.currentHitComments?.durableHitIdentifier)) {
        // if above if statement is true then currentHitComments is defined
        return state.search.currentSearch.currentHitComments!.beingUpdated;
    }
    return [];
};

export const isCommentsLoadingFailed = (state: RootState, durableHitIdentifier: DurableHitIdentifier): boolean => {
    if (_.isEqual(durableHitIdentifier, state.search.currentSearch.currentHitComments?.durableHitIdentifier)) {
        // if above if statement is true then hitComments is defined
        return state.search.currentSearch.currentHitComments!.isLoadingFailed;
    }
    return false;
};
