import { ConflictsAction, Result, Forbidden, forbidden, PermissionsContext } from "aderant-conflicts-models";
import { PermissionValidationMessages } from "../APIs";

export const hasPermission = async (context: PermissionsContext, action: ConflictsAction, actionName: string): Promise<Result<boolean, Forbidden>> => {
    //Only users with permission can view and update the entity flyout configuration
    if (!(await context.currentUserHasPermission(action))) {
        return forbidden(PermissionValidationMessages.PERMISSION_ACTION_FORBIDDEN.getMessage("The current user", actionName));
    }
    return true;
};
