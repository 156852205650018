import { TableClientOptions, TableStore } from "@aderant/azure-storage";
import { SecureEntity, SecureEntityType, secureEntityTypes } from "aderant-conflicts-models";

export function getRlsTableStoreMock(securedEntitiesInput?: SecureEntity[], mockClientOptions?: TableClientOptions): Pick<TableStore, "getAccountName" | "getTableName" | "queryEntities" | "get"> {
    const tableCientOptions = mockClientOptions ?? getMockTableClientOptions();
    const mock = new TableStore(tableCientOptions);
    const securedEntities = securedEntitiesInput ?? [];
    mock.getAccountName = jest.fn(() => {
        return tableCientOptions.accountName;
    });
    mock.getTableName = jest.fn(() => {
        return tableCientOptions.tableName;
    });
    mock.queryEntities = jest.fn((query: string) =>
        Promise.resolve(
            securedEntities
                .filter((e) => query.includes(`'${e.secureEntityId}'`))
                .map((e) => {
                    return { rowKey: e.secureEntityId };
                })
        )
    );
    mock.get = jest.fn((partitionKey: string, rowKey?: string) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        if (secureEntityTypes.includes(partitionKey as SecureEntityType)) {
            if (rowKey) {
                return Promise.resolve(securedEntities.find((e) => e.secureEntityId == rowKey));
            }
            return Promise.resolve(securedEntities);
        }
        return Promise.resolve(undefined);
    });
    return mock;
}

function getMockTableClientOptions(): TableClientOptions {
    return {
        accountName: "myaccount",
        tableName: "SecureEntityList",
        useIdentity: true
    };
}
