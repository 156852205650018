import { Context } from "@azure/functions";
import { AuthenticationResult } from "@azure/msal-browser";
import {
    BlobStorageSecrets,
    ClientAppContextModel,
    CognitiveSearchSecrets,
    ConflictsAction,
    CosmosSecrets,
    DataFactorySecrets,
    FunctionAppContextModel,
    KeyAuthFunctionAppContextModel,
    UserlessKeyAuthFunctionAppContextModel,
    LoggedInUser,
    LoggedInUserId,
    NonEmptyArray,
    PermissionsConnector,
    PermissionsConnectorDirect,
    PublicKeyAuthFunctionAppContextModel,
    QueueMessage,
    QueueTriggerFunctionAppContextModel,
    RLSStorageConnectionInfo,
    unexpectedError,
    ConnectionContext
} from "aderant-conflicts-models";
import { AppInsightsClient } from "aderant-web-fw-azfunctions";
import { Logger } from "aderant-web-fw-core";
import {
    getBlobStorageSecrets,
    getRLSStorageConnectionInfo,
    getCognitiveSearchSecrets,
    getDataFactorySecrets,
    getEntityStoreCosmosSecrets,
    getFunctionHostKey,
    getSearchStoreCosmosSecrets,
    getSharedBlobStorageConnectionString
} from "..";
import { UserHeaders } from "./AzureFunctionDefinition/AzureKeyAuthFunctionDefinition";

/**
 * Context type holding ubiquitously used functionality/state - currently logged in user/tenancy information, functional security permission access and secret access.
 * Please try to use the relevant individual parts of the union type (PermissionsContext, ConnectionContext) in function signatures etc so that code is not dependent on more
 * than it needs to be.
 */
export type ConflictsContext = FunctionAppContext | QueueTriggerFunctionAppContext | KeyAuthFunctionAppContext | PublicKeyAuthFunctionAppContext | ClientAppContext;
export type TokenAuthContext = FunctionAppContext | ClientAppContext;
//Please read before modifying this:
//**Anything added to this should exist on the base type in Models if possible, so that code in Models (mostly validation logic consuming PermissionsContext) can use the stuff it needs**
//      Those are separate in Models because the implementation of this requires third party references that we don't want in models
//      but the public api/interface should be at the root level in models so everything can know about it.
//**Please don't turn this into a god object** - only things that (roughly) *everything* needs access to should live on the Context
//      If specific functionality can live in a different class/function from this and just be seeded from the context state (e.g. tenancyName, currentUserId etc)
//      it probably should be. Currently Permissions (for client and backend) and Secret access (backend only) have passed the 'should it be here' test
//      because use of them is fairly ubiquitous, and especially for Permission access we want to reduce friction for doing things the Right Way™.
//
//Be a tidy kiwi, if you need to modify/add to it then leave it easier to understand than it was when you found it, because it's part of the core infrastructure of the codebase.

export class FunctionAppContext implements FunctionAppContextModel {
    readonly currentUserId: string;

    public constructor(
        private readonly permissionsConnector: PermissionsConnector,
        public readonly logger: Logger,
        public readonly currentUser: LoggedInUser,
        public readonly azureContext: Context,
        public readonly appInsightsClient: AppInsightsClient
    ) {
        this.currentUserId = currentUser.id;
    }

    isExposedToEndUsers = true as const;
    environmentType = "AzureFunctionApp" as const;

    public async getBlobStorageSecrets(): Promise<BlobStorageSecrets> {
        return await getBlobStorageSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getDataFactorySecrets(): Promise<DataFactorySecrets> {
        return await getDataFactorySecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getEntityStoreCosmosSecrets(): Promise<CosmosSecrets> {
        return await getEntityStoreCosmosSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getSearchStoreCosmosSecrets(): Promise<CosmosSecrets> {
        return await getSearchStoreCosmosSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getCognitiveSearchSecrets(): Promise<CognitiveSearchSecrets> {
        return await getCognitiveSearchSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getRLSStorageConnectionInfo(): Promise<RLSStorageConnectionInfo> {
        return await getRLSStorageConnectionInfo(this.currentUser.tenancy.uniqueName, this.logger);
    }

    async getSharedBlobStorageConnectionString(): Promise<string> {
        return await getSharedBlobStorageConnectionString();
    }

    async getSharedFunctionHostKey(endpoint: "SearchStoreApi" | "SearchApi" | "EntityStoreApi"): Promise<string> {
        return await getFunctionHostKey(endpoint);
    }

    async userHasPermission(user: Pick<LoggedInUser, "id" | "role">, action: ConflictsAction): Promise<boolean> {
        return await this.permissionsConnector.userHasPermission(user, action);
    }
    async userHasAllPermissions(user: Pick<LoggedInUser, "id" | "role">, actions: NonEmptyArray<ConflictsAction>): Promise<boolean> {
        return await this.permissionsConnector.userHasAllPermissions(user, actions);
    }
    async userHasAnyPermission(user: Pick<LoggedInUser, "id" | "role">, actions: NonEmptyArray<ConflictsAction>): Promise<false | NonEmptyArray<ConflictsAction>> {
        return await this.permissionsConnector.userHasAnyPermission(user, actions);
    }
    async currentUserHasPermission(action: ConflictsAction): Promise<boolean> {
        return await this.permissionsConnector.userHasPermission(this.currentUser, action);
    }
    async currentUserHasAllPermissions(actions: NonEmptyArray<ConflictsAction>): Promise<boolean> {
        return await this.permissionsConnector.userHasAllPermissions(this.currentUser, actions);
    }
    async currentUserHasAnyPermission(actions: NonEmptyArray<ConflictsAction>): Promise<false | NonEmptyArray<ConflictsAction>> {
        return await this.permissionsConnector.userHasAnyPermission(this.currentUser, actions);
    }

    async currentUserIsAderantUser(): Promise<boolean> {
        return await this.permissionsConnector.userIsAderantUser(this.currentUser);
    }
}

export class QueueTriggerFunctionAppContext implements QueueTriggerFunctionAppContextModel {
    environmentType = "QueueTriggerFunction" as const;
    currentUser: LoggedInUserId;
    isExposedToEndUsers = false as const;

    public async getBlobStorageSecrets(): Promise<BlobStorageSecrets> {
        return await getBlobStorageSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getDataFactorySecrets(): Promise<DataFactorySecrets> {
        return await getDataFactorySecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getEntityStoreCosmosSecrets(): Promise<CosmosSecrets> {
        return await getEntityStoreCosmosSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getSearchStoreCosmosSecrets(): Promise<CosmosSecrets> {
        return await getSearchStoreCosmosSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getCognitiveSearchSecrets(): Promise<CognitiveSearchSecrets> {
        return await getCognitiveSearchSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getRLSStorageConnectionInfo(): Promise<RLSStorageConnectionInfo> {
        return await getRLSStorageConnectionInfo(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getSharedBlobStorageConnectionString(): Promise<string> {
        return await this.getSharedBlobStorageConnectionString();
    }

    async getSharedFunctionHostKey(endpoint: "SearchStoreApi" | "SearchApi" | "EntityStoreApi"): Promise<string> {
        return await getFunctionHostKey(endpoint);
    }

    public constructor(public readonly logger: Logger, message: QueueMessage<unknown>, public readonly azureContext: Context, public readonly appInsightsClient: AppInsightsClient) {
        this.currentUser = { id: message.requestingUserId, tenancy: { uniqueName: message.requestingUniqueTenancyName } };
    }
}

export class KeyAuthFunctionAppContext implements KeyAuthFunctionAppContextModel {
    environmentType = "KeyAuthFunction" as const;
    currentUser: LoggedInUserId;
    isExposedToEndUsers = false as const;

    public async getBlobStorageSecrets(): Promise<BlobStorageSecrets> {
        return await getBlobStorageSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getDataFactorySecrets(): Promise<DataFactorySecrets> {
        return await getDataFactorySecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getEntityStoreCosmosSecrets(): Promise<CosmosSecrets> {
        return await getEntityStoreCosmosSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getSearchStoreCosmosSecrets(): Promise<CosmosSecrets> {
        return await getSearchStoreCosmosSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getCognitiveSearchSecrets(): Promise<CognitiveSearchSecrets> {
        return await getCognitiveSearchSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getRLSStorageConnectionInfo(): Promise<RLSStorageConnectionInfo> {
        return await getRLSStorageConnectionInfo(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getSharedBlobStorageConnectionString(): Promise<string> {
        return await this.getSharedBlobStorageConnectionString();
    }

    async getSharedFunctionHostKey(endpoint: "SearchStoreApi" | "SearchApi" | "EntityStoreApi"): Promise<string> {
        return await getFunctionHostKey(endpoint);
    }

    public constructor(public readonly logger: Logger, public readonly appInsightsClient: AppInsightsClient, userHeaders: UserHeaders, public readonly azureContext: Context) {
        this.currentUser = { id: userHeaders["x-aderant-userid"], tenancy: { uniqueName: userHeaders["x-aderant-tenantuniquename"] } };
    }
}

export class PublicKeyAuthFunctionAppContext implements PublicKeyAuthFunctionAppContextModel {
    environmentType = "PublicKeyAuthFunction" as const;
    currentUser: LoggedInUser;
    readonly currentUserId: string;
    isExposedToEndUsers = true as const;

    public async getBlobStorageSecrets(): Promise<BlobStorageSecrets> {
        return await getBlobStorageSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getDataFactorySecrets(): Promise<DataFactorySecrets> {
        return await getDataFactorySecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getEntityStoreCosmosSecrets(): Promise<CosmosSecrets> {
        return await getEntityStoreCosmosSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getSearchStoreCosmosSecrets(): Promise<CosmosSecrets> {
        return await getSearchStoreCosmosSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getCognitiveSearchSecrets(): Promise<CognitiveSearchSecrets> {
        return await getCognitiveSearchSecrets(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getRLSStorageConnectionInfo(): Promise<RLSStorageConnectionInfo> {
        return await getRLSStorageConnectionInfo(this.currentUser.tenancy.uniqueName, this.logger);
    }

    public async getSharedBlobStorageConnectionString(): Promise<string> {
        return await this.getSharedBlobStorageConnectionString();
    }

    async getSharedFunctionHostKey(endpoint: "SearchStoreApi" | "SearchApi" | "EntityStoreApi"): Promise<string> {
        return await getFunctionHostKey(endpoint);
    }

    public constructor(
        private readonly permissionsConnector: PermissionsConnector,
        public readonly logger: Logger,
        public readonly appInsightsClient: AppInsightsClient,
        requestingUser: LoggedInUser,
        public readonly azureContext: Context
    ) {
        this.currentUser = requestingUser;
        this.currentUserId = requestingUser.id;
    }
    async currentUserIsAderantUser(): Promise<boolean> {
        return await this.permissionsConnector.userIsAderantUser(this.currentUser);
    }
    async currentUserHasPermission(action: ConflictsAction): Promise<boolean> {
        return await this.permissionsConnector.userHasPermission(this.currentUser, action);
    }
    async currentUserHasAllPermissions(actions: NonEmptyArray<ConflictsAction>): Promise<boolean> {
        return await this.permissionsConnector.userHasAllPermissions(this.currentUser, actions);
    }
    async currentUserHasAnyPermission(actions: NonEmptyArray<ConflictsAction>): Promise<false | NonEmptyArray<ConflictsAction>> {
        return await this.permissionsConnector.userHasAnyPermission(this.currentUser, actions);
    }
}
export class UserlessKeyAuthFunctionAppContext implements UserlessKeyAuthFunctionAppContextModel {
    environmentType = "UserlessKeyAuthFunction" as const;
    isExposedToEndUsers = false as const;

    /// ⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠ WARNING ⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠
    /// DO NOT USE THESE FUNCTIONS.  THESE ARE HERE FOR VALIDATECUSTOMIZATIONWIP AND FOR COSMOSDBTRIGGER, WHICH RECEIVES TENANCYNAME FROM A TRUSTED SOURCE
    /// THESE WILL BE REMOVED IN A FUTURE UPDATE.  DO NOT USE!!!
    /// If you find these are used when you remove the functions, comment out the calling code.  As punishment.
    /// ⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠
    public async getBlobStorageSecrets(tenancyName: string): Promise<BlobStorageSecrets> {
        return await getBlobStorageSecrets(tenancyName, this.logger);
    }

    public async getDataFactorySecrets(tenancyName: string): Promise<DataFactorySecrets> {
        return await getDataFactorySecrets(tenancyName, this.logger);
    }

    public async getEntityStoreCosmosSecrets(tenancyName: string): Promise<CosmosSecrets> {
        return await getEntityStoreCosmosSecrets(tenancyName, this.logger);
    }

    public async getSearchStoreCosmosSecrets(tenancyName: string): Promise<CosmosSecrets> {
        return await getSearchStoreCosmosSecrets(tenancyName, this.logger);
    }

    public async getCognitiveSearchSecrets(tenancyName: string): Promise<CognitiveSearchSecrets> {
        return await getCognitiveSearchSecrets(tenancyName, this.logger);
    }

    public async getRLSStorageConnectionInfo(tenancyName: string): Promise<RLSStorageConnectionInfo> {
        return await getRLSStorageConnectionInfo(tenancyName, this.logger);
    }

    public async getSharedBlobStorageConnectionString(): Promise<string> {
        return await this.getSharedBlobStorageConnectionString();
    }

    async getSharedFunctionHostKey(endpoint: "SearchStoreApi" | "SearchApi" | "EntityStoreApi" | "AdminApi"): Promise<string> {
        return await getFunctionHostKey(endpoint);
    }
    ///⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠

    public constructor(public readonly logger: Logger, public readonly appInsightsClient: AppInsightsClient, public readonly azureContext: Context) {}
}

export class ClientAppContext implements ClientAppContextModel {
    readonly currentUserId: string;
    permissionsConnector?: PermissionsConnectorDirect;

    public constructor(
        public readonly logger: Logger,
        public readonly appInsightsClient: AppInsightsClient,
        public readonly currentUser: LoggedInUser,
        public readonly acquireToken: (scopes: Array<string>) => Promise<AuthenticationResult>
    ) {
        this.currentUserId = currentUser.id;
    }

    //This needs PermissionsConnector, PermissionsConnector needs this to create proxies for the services it needs to call.
    //Gross, but avoids heavier refactoring and this is only initialized in one place
    initializePermissionsConnector(permissionsConnector: PermissionsConnectorDirect): void {
        this.permissionsConnector = permissionsConnector;
    }

    environmentType = "ClientApp" as const;
    missingPermissionsConnectorMessage = "ClientAppContext permission function called without calling initializePermissionsConnector.";

    userHasPermission(user: Pick<LoggedInUser, "id" | "role">, action: ConflictsAction): boolean {
        if (!this.permissionsConnector) {
            throw unexpectedError(this.missingPermissionsConnectorMessage, "userHasPermission");
        }
        return this.permissionsConnector.userHasPermission(user, action);
    }
    userHasAllPermissions(user: Pick<LoggedInUser, "id" | "role">, actions: NonEmptyArray<ConflictsAction>): boolean {
        if (!this.permissionsConnector) {
            throw unexpectedError(this.missingPermissionsConnectorMessage, "userHasAllPermissions");
        }
        return this.permissionsConnector.userHasAllPermissions(user, actions);
    }
    userHasAnyPermission(user: Pick<LoggedInUser, "id" | "role">, actions: NonEmptyArray<ConflictsAction>): false | NonEmptyArray<ConflictsAction> {
        if (!this.permissionsConnector) {
            throw unexpectedError(this.missingPermissionsConnectorMessage, "userHasAnyPermission");
        }
        return this.permissionsConnector.userHasAnyPermission(user, actions);
    }
    currentUserHasPermission(action: ConflictsAction): boolean {
        if (!this.permissionsConnector) {
            throw unexpectedError(this.missingPermissionsConnectorMessage, "currentUserHasPermission");
        }
        return this.permissionsConnector.userHasPermission(this.currentUser, action);
    }
    currentUserHasAllPermissions(actions: NonEmptyArray<ConflictsAction>): boolean {
        if (!this.permissionsConnector) {
            throw unexpectedError(this.missingPermissionsConnectorMessage, "currentUserHasAllPermissions");
        }
        return this.permissionsConnector.userHasAllPermissions(this.currentUser, actions);
    }
    currentUserHasAnyPermission(actions: NonEmptyArray<ConflictsAction>): false | NonEmptyArray<ConflictsAction> {
        if (!this.permissionsConnector) {
            throw unexpectedError(this.missingPermissionsConnectorMessage, "currentUserHasAnyPermission");
        }
        return this.permissionsConnector.userHasAnyPermission(this.currentUser, actions);
    }
    currentUserIsAderantUser(): boolean {
        if (!this.permissionsConnector) {
            throw unexpectedError(this.missingPermissionsConnectorMessage, "currentUserIsAderantUser");
        }
        return this.permissionsConnector.userIsAderantUser(this.currentUser);
    }
}

export function isUserlessKeyAuthFunctionAppContext(context: ConnectionContext | UserlessKeyAuthFunctionAppContext): boolean {
    //This is a type guard. We need to cast to test the type
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if ((context as UserlessKeyAuthFunctionAppContext).environmentType == "UserlessKeyAuthFunction") {
        return true;
    }
    return false;
}
