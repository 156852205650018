export type NonEmptyArray<T> = Array<T> & { 0: T };

export function isNonEmpty<T>(array: Array<T>): array is NonEmptyArray<T> {
    return array.length > 0;
}

/**
 * Derives a function type ```(...args: In) => Out``` from a function type F that extends ```(...args: In) => Promise<Out>``` or ```(...args: In) => Out```
 */
export type Synchronous<F> = F extends (...args: infer In) => Promise<infer Out> ? (...args: In) => Out : F extends (...args: infer In) => infer Out ? (...args: In) => Out : never;
/**
 * Derives a new type from T that is the same, except all async functions are converted to be synchronous.
 */
export type Direct<T extends Record<string, any>> = { [K in keyof T]: T[K] extends (...args: any) => Promise<any> ? Synchronous<T[K]> : T[K] };

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T; //this is a builtin in TS 4.5 onwards, so replace this when we update.

export function isNotNullOrUndefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
}

export type WithId<T> = Omit<T, "id"> & { id: string };

//alternative to the satisfies keyword introduced in TS 4.9
//replace usages of this with that once all of our code is using 4.9 or higher.
//see: https://stackoverflow.com/questions/56694082/as-const-combined-with-type
export function satisfies<TSatisfied>(): <T extends TSatisfied>(value: T) => T {
    return (value) => value;
}
