import {
    ConflictsAction,
    ConnectionContext,
    CurrentUserContext,
    EntityAccessList,
    EntityAccessSets,
    PermissionsContext,
    SecureEntity,
    secureEntityTypes,
    unexpectedError
} from "aderant-conflicts-models";
import { TableStore, TableClientOptions, BlobClientOptions, BlobStore } from "@aderant/azure-storage";
import _ from "lodash";
import Bluebird from "bluebird";

export type RLSConnectorContext = CurrentUserContext & ConnectionContext & PermissionsContext;

export class RowLevelSecurityConnector {
    private blobStore: Pick<BlobStore, "getBlobContent">;
    private tableStore: Pick<TableStore, "get" | "queryEntities">;
    private context: RLSConnectorContext;

    private constructor(context: RLSConnectorContext, blobStoreInput: Pick<BlobStore, "getBlobContent">, tableStoreInput: Pick<TableStore, "get" | "queryEntities">) {
        this.context = context;
        this.blobStore = blobStoreInput;
        this.tableStore = tableStoreInput;
    }

    public static async open(context: RLSConnectorContext, blobStoreInput?: Pick<BlobStore, "getBlobContent">, tableStoreInput?: Pick<TableStore, "get" | "queryEntities">) {
        const blobStore = blobStoreInput ?? (await RowLevelSecurityConnector.getBlobStorageClient(context));
        const tableStore = tableStoreInput ?? (await RowLevelSecurityConnector.getTableStorageClient(context));
        return new RowLevelSecurityConnector(context, blobStore, tableStore);
    }

    public async getEntityAccessList(useLowerCase = true): Promise<EntityAccessSets> {
        const filename = useLowerCase ? encodeURIComponent(this.context.currentUser.email.toLowerCase()) + ".json" : encodeURIComponent(this.context.currentUser.email) + ".json";
        let getResponse;
        try {
            getResponse = await this.blobStore.getBlobContent(filename);
        } catch (e: any) {
            if (e.statusCode === 404) {
                if (useLowerCase) {
                    return await this.getEntityAccessList(false);
                } else {
                    return { allowedEntityIds: new Set(), deniedEntityIds: new Set() }; //if user has no eal, they have no access to any secure entities
                }
            } else {
                throw unexpectedError(e, "getBlobContent in getEntityAccessList");
            }
        }

        //This needs to be validated when being saved. Avoiding validating on load as well for perf.
        const entityAccessList: EntityAccessList = JSON.parse(getResponse.content);

        return { allowedEntityIds: new Set(entityAccessList.allowedEntityIds), deniedEntityIds: new Set(entityAccessList.deniedEntityIds) };
    }

    public async canUserViewEntity(secureEntityId: string): Promise<boolean> {
        if (await this.context.currentUserHasPermission(ConflictsAction.BypassRowLevelSecurity)) {
            this.context.logger.info("Current user has permission to skip RLS check.  Skipping redaction");
            return true;
        }

        const splitSecureEntityIds = secureEntityId.split(";");
        const eal = await this.getEntityAccessList();
        const permission = RowLevelSecurityConnector.getUserPermission(secureEntityId, eal);
        if (permission === "ALLOW") {
            return true;
        } else if (permission === "DENY") {
            return false;
        }

        //User has no specific permission for the entity, so check if it's secured. If it's not in the securedEntities table, they have access
        const securedEntities: SecureEntity[] = (
            await Bluebird.Promise.map(
                splitSecureEntityIds,
                (entityId) => {
                    const entityIdParts = entityId.split(":");
                    return this.tableStore.get(entityIdParts[0], entityId);
                },
                { concurrency: 8 }
            )
        ).filter((x) => x != undefined);
        return securedEntities.length === 0; //entity should be visible if it and it's parent entities are all not secured
    }

    /** Returns false if the user has deny permission, true if the user has allow permission, else undefined
     **/
    public static getUserPermission(secureEntityId: string, entityAccessList: EntityAccessSets): "ALLOW" | "DENY" | "NONE" {
        const splitSecureEntityIds = secureEntityId.split(";");
        const denyPermission = splitSecureEntityIds.find((x) => entityAccessList.deniedEntityIds.has(x));
        if (denyPermission) {
            return "DENY"; //deny overrides any other allowed permission, so if it exists, they don't have permission
        }
        const allowPermission = splitSecureEntityIds.find((x) => entityAccessList.allowedEntityIds.has(x));
        if (allowPermission) {
            return "ALLOW"; //entity is secured, and on users eal so it should be visible
        }
        return "NONE"; //entity not on users eal, we still don't know if they have rights to view it - need means we must check if it's secured.
    }

    public async filterSecuredSecureEntityIds(concatenatedSecureEntityIds: string[]): Promise<{ securedIds: string[]; nonSecuredIds: string[] }> {
        const queries = this.generateEntityQueries(concatenatedSecureEntityIds);

        console.time("Fetching items");
        const results: string[] = _.flatten(await Bluebird.Promise.map(queries, (q) => this.tableStore.queryEntities(q), { concurrency: 32 })).map((entity) => entity.rowKey);
        const securedSecureEntityIds = new Set(results);

        const [securedIds, nonSecuredIds] = _.partition(concatenatedSecureEntityIds, (id) => this.isSecured(id, securedSecureEntityIds));

        return { securedIds: securedIds, nonSecuredIds: nonSecuredIds };
    }

    private isSecured(concatenatedSecureEntityId: string, securedIds: Set<string>) {
        const splitSecureEntityIds: string[] = concatenatedSecureEntityId.split(";");

        for (const secureEntityId of splitSecureEntityIds) {
            if (securedIds.has(secureEntityId)) {
                return true;
            }
        }

        return false;
    }

    private generateEntityQueries(concatenatedSecureEntityIds: string[]): string[] {
        const splitSecureEntityIds = [...new Set(concatenatedSecureEntityIds.flatMap((id) => id.split(";")))]; //stick ids in a set to filter out non unique ones
        const withExtractedEntityTypes = splitSecureEntityIds.map((id) => {
            const entityType = id.split(":")[0];
            return { id: id, entityType: entityType };
        });
        const byEntityType = _.groupBy(withExtractedEntityTypes, (id) => id.entityType);

        const queries = Object.entries(byEntityType).flatMap(([entityType, securedEntities]) => {
            if (!secureEntityTypes.find((e) => e === entityType)) {
                throw unexpectedError(
                    `Found unexpected entity type when processing secureEntityIds: example id: ${securedEntities[0].id}, extracted entityType: ${entityType} (expected format 'entityType:number')`,
                    "generateEntityQueries"
                );
            }

            const partialQueries = _.chunk(
                securedEntities.map((e) => `RowKey eq '${e.id}'`),
                100
            );

            return partialQueries.map((q) => `PartitionKey eq '${entityType}' and (${q.join(" or ")})`);
        });

        return queries;
    }

    private static async getTableStorageClient(context: ConnectionContext): Promise<TableStore> {
        const connectionInfo = await context.getRLSStorageConnectionInfo();
        const clientOptions: TableClientOptions = {
            accountName: connectionInfo.accountName,
            tableName: "SecureEntityList",
            useIdentity: true
        };
        return new TableStore(clientOptions);
    }

    private static async getBlobStorageClient(context: ConnectionContext): Promise<BlobStore> {
        const connectionInfo = await context.getRLSStorageConnectionInfo();
        const clientOptions: BlobClientOptions = {
            accountName: connectionInfo.accountName,
            containerName: "entity-access-store",
            useIdentity: true
        };
        return new BlobStore(clientOptions);
    }
}
