import { ConflictsAction, EntityFlyoutConfigurationTemplates, etagMismatch, EtagMismatch, Forbidden, ok, Result, ValidationErrors } from "aderant-conflicts-models";
import { FunctionAppContext } from "../../ConflictsContext";
import { EntityConfigurationService } from "./EntityConfigurationService";
import { BlobStorageConnector } from "../../BlobStorageConnector/BlobStorageConnector";
import { validateTemplates } from "./inputValidator";
import { emptyOkResponse, EmptyOkResponse } from "../../Http/HttpResponse";
import { EntityConfiguration } from "./EntityConfiguration";
import { hasPermission } from "../hasPermission";
/**
 * This function retrieves the flyout configurations for all entity types.
 */
export async function updateEntityFlyoutConfigurations(
    context: FunctionAppContext,
    input: EntityFlyoutConfigurationTemplates,
    entityConfigurationService: EntityConfigurationService,
    blobConnector?: BlobStorageConnector //only provide this for unit tests
): Promise<Result<EmptyOkResponse, Forbidden | ValidationErrors | EtagMismatch>> {
    context.logger.info("Updating entity flyout configurations.");

    const permissionValidation = await hasPermission(context, ConflictsAction.ViewAndUpdateEntityFlyoutConfiguration, `update the ${EntityConfiguration.FlyoutDefinition}`);
    if (!ok(permissionValidation)) {
        return permissionValidation;
    }
    const inputValidation = validateTemplates(input.configurations);
    if (!ok(inputValidation)) {
        return inputValidation;
    }

    if (!blobConnector) {
        blobConnector = await BlobStorageConnector.open((await context.getBlobStorageSecrets()).connectionString);
    }

    //Fetch flyout configuration template for all entity types
    const response = await entityConfigurationService.updateEntityFlyoutConfigurationTemplates(context, blobConnector, input);
    if (!ok(response)) {
        return response;
    }
    return emptyOkResponse();
}
