import { ClientSecretCredential } from "@azure/identity";
import { LogContext, NotFound, notFoundWithMessage, ok, Result } from "aderant-conflicts-models";
import { FunctionKeySecretConnector } from "../SecretConnectors/FunctionKeySecret/FunctionKeySecretConnector";

export const getIdentityToken = async (context: LogContext): Promise<Result<string, NotFound>> => {
    const secretConnector: FunctionKeySecretConnector = new FunctionKeySecretConnector(context.logger);
    context.logger.info("Getting Identity Token from function key keyvault.");
    const managedIdentityConfig = await secretConnector.getIdentityConfig();
    if (!ok(managedIdentityConfig)) {
        context.logger.error("Managed Identity Configuration values could not be found.");
        return notFoundWithMessage("Managed Identity Configuration values could not be found.");
    }
    const credential = new ClientSecretCredential(managedIdentityConfig.tenancyId, managedIdentityConfig.clientId, managedIdentityConfig.clientSecret);
    const token = await credential.getToken([managedIdentityConfig.scope]);
    return token.token;
};
