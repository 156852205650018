import { User } from "aderant-conflicts-models";
import { SingleSelectValue } from "@aderant/aderant-react-components";
//Get user based on userId provided
export const getUser = (users: User[], userId: string): User | undefined => users.find((u) => u.id === userId);

//Get user array as SingleSelectValue
export const getUsersAsSingleSelectValue = (users: User[]) => users.map<SingleSelectValue>((u) => ({ label: u.name, value: u.id }));

export const getAssignedToUser = (users?: User[], userId?: string | null): string => {
    if (!users || !userId) {
        return "-";
    }
    const assignedToUser = getUser(users, userId);
    return assignedToUser ? assignedToUser.name : "-";
};

export type UserNameAndId = { name?: string; id?: string };