import { Message } from "aderant-conflicts-models";

export const Messages = {
    GENERATE_KEY_DIALOG_TITLE: new Message("GENERATE_KEY_DIALOG_TITLE", "Generate New Key"),
    GENERATE_KEY_DIALOG_DESCRIPTION: new Message("GENERATE_KEY_DIALOG_DESCRIPTION", "This key allows access to the Conflicts API."),
    GENERATE_KEY_DIALOG_CLOSE: new Message("GENERATE_KEY_DIALOG_CLOSE", "Close"),
    GENERATE_KEY_DIALOG_CANCEL: new Message("GENERATE_KEY_DIALOG_CANCEL", "Cancel"),
    GENERATE_KEY_DIALOG_GENERATE: new Message("GENERATE_KEY_DIALOG_GENERATE", "Generate"),

    REVOKE_KEY_DIALOG_TITLE: new Message("REVOKE_KEY_DIALOG_TITLE", "Revoke this key?"),
    REVOKE_KEY_DIALOG_DESCRIPTION: new Message("REVOKE_KEY_DIALOG_DESCRIPTION", "This key will no longer provide access to the Conflicts API."),

    REGENERATE_KEY_DIALOG_TITLE: new Message("REGENERATE_KEY_DIALOG_TITLE", "Regenerate key"),
    REGENERATE_KEY_CONFIRMATION_DIALOG_TITLE: new Message("REGENERATE_KEY_CONFIRMATION_DIALOG_TITLE", "Are you sure you want to regenerate this key?"),

    REGENERATE_KEY_CONFIRMATION_DIALOG_DESCRIPTION: new Message(
        "REGENERATE_KEY_CONFIRMATION_DIALOG_DESCRIPTION",
        "This key will no longer provide access to the Conflicts API. Once generated, the new key must be used instead."
    ),
    WINDOW_CLOSED_DESCRIPTION_TEXT: new Message("WINDOW_CLOSED_DESCRIPTION_TEXT", "Once this window is closed, the key cannot be viewed again."),
    GENERATE_KEY_DIALOG_FOOTER_TEXT: new Message("GENERATE_KEY_DIALOG_FOOTER_TEXT", "You should save this key in a secure location.")
};
