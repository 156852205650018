import { ConnectionContext } from "./ConflictsContextModel";

export function getSynonymMapName(tenancyName: string): string;
export function getSynonymMapName(tenancyName: ConnectionContext): string;
export function getSynonymMapName(tenancyName: ConnectionContext | string): string {
    let name = "";
    if (typeof tenancyName === "string") {
        name = tenancyName;
    } else {
        name = tenancyName.currentUser.tenancy.uniqueName;
    }

    return `${name}-conflicts-synonym-map`;
}

export function getSearchIndexName(tenancyName: string): string;
export function getSearchIndexName(tenancyName: ConnectionContext): string;
export function getSearchIndexName(tenancyName: ConnectionContext | string): string {
    let name = "";
    if (typeof tenancyName === "string") {
        name = tenancyName;
    } else {
        name = tenancyName.currentUser.tenancy.uniqueName;
    }

    return `${name}-conflicts-search`;
}
