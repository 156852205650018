import * as React from "react";

export const AderantConflictsIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 152.65 151.58" {...props}>
        <defs>
            <style>
                {
                    ".cls-1{mix-blend-mode:overlay;opacity:.6;}.cls-2{fill:url(#New_Gradient_Swatch_1-2);filter:url(#drop-shadow-1);stroke:#fff;stroke-miterlimit:10;strokeWidth:2px;}.cls-3{fill:#fff;}.cls-4{fill:url(#New_Gradient_Swatch_1);}.cls-5{isolation:isolate;}"
                }
            </style>
            <linearGradient id="New_Gradient_Swatch_1" x1={87.15} y1={151.58} x2={87.15} y2={20.58} gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                <stop offset={0} stopColor="#124573" />
                <stop offset={1} stopColor="#2d78bd" />
            </linearGradient>
            <linearGradient id="New_Gradient_Swatch_1-2" x1={44.08} y1={68.6} x2={44.08} y2={5.43} xlinkHref="#New_Gradient_Swatch_1" />
            <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
                <feOffset dx={2} dy={2} />
                <feGaussianBlur result="blur" stdDeviation={2} />
                <feFlood floodColor="#000" floodOpacity={0.25} />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g className="cls-5">
            <g id="Layer_2">
                <g id="Layer_1-2">
                    <g>
                        <g>
                            <circle className="cls-4" cx={87.15} cy={86.08} r={65.5} />
                            <g className="cls-1">
                                <path
                                    className="cls-3"
                                    d="M111.3,123.68l-9.1-21.99-14.82-35.6-15.19,35.61-.66,1.57c3.16-1.66,7.77-3.61,13.46-4.66,2.77-.51,5.58-.77,8.34-.77,1.29,0,2.61,.06,3.91,.17l.47,.04-.08,2.04h-.47c-.15,0-15.59,.53-33.99,23.04l-.2,.46-.66,.08h-10.83L87.61,41.21l35.2,82.47h-11.51Z"
                                />
                                <path
                                    className="cls-3"
                                    d="M87.15,151.58c-36.12,0-65.5-29.38-65.5-65.5S51.04,20.58,87.15,20.58s65.5,29.38,65.5,65.5-29.38,65.5-65.5,65.5m0-122.6c-31.49,0-57.1,25.62-57.1,57.1s25.62,57.1,57.1,57.1,57.1-25.62,57.1-57.1-25.62-57.1-57.1-57.1"
                                />
                            </g>
                        </g>
                        <rect className="cls-2" x={5.08} y={5.43} width={78} height={63.17} rx={7} ry={7} />
                        <g>
                            <path
                                className="cls-3"
                                d="M40.87,48.03c-2.37,2.17-5.4,3.25-9.1,3.25-4.57,0-8.16-1.47-10.78-4.4-2.62-2.95-3.92-6.98-3.92-12.11,0-5.55,1.49-9.82,4.46-12.83,2.59-2.62,5.88-3.92,9.87-3.92,5.35,0,9.26,1.75,11.73,5.26,1.37,1.97,2.1,3.94,2.2,5.93h-6.64c-.43-1.52-.98-2.67-1.66-3.45-1.21-1.38-3-2.07-5.37-2.07s-4.32,.97-5.71,2.92c-1.39,1.95-2.09,4.7-2.09,8.27s.74,6.23,2.21,8.01c1.47,1.78,3.34,2.66,5.62,2.66s4.1-.76,5.32-2.28c.68-.82,1.24-2.05,1.68-3.69h6.57c-.57,3.46-2.04,6.28-4.4,8.45Z"
                            />
                            <path
                                className="cls-3"
                                d="M68.11,29.87c1.98,2.49,2.97,5.43,2.97,8.82s-.99,6.4-2.97,8.85c-1.98,2.45-4.99,3.68-9.03,3.68s-7.05-1.23-9.03-3.68c-1.98-2.45-2.97-5.4-2.97-8.85s.99-6.33,2.97-8.82c1.98-2.49,4.99-3.73,9.03-3.73s7.05,1.24,9.03,3.73Zm-9.05,1.47c-1.8,0-3.18,.64-4.15,1.91-.97,1.27-1.46,3.09-1.46,5.44s.48,4.17,1.46,5.45c.97,1.28,2.35,1.92,4.15,1.92s3.18-.64,4.14-1.92c.96-1.28,1.44-3.1,1.44-5.45s-.48-4.17-1.44-5.44c-.96-1.27-2.34-1.91-4.14-1.91Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
