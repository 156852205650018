//This will live in Conflicts
import { aderantColors } from "@aderant/aderant-react-components";

export const conflictsPalette = {
    // Primary Colours
    primary: {
        R2D2: aderantColors.R2D2,
        Galaxy: aderantColors.Galaxy,
        Ahsoka: aderantColors.Ahsoka
    },
    background: {
        grey: aderantColors.Leia,
        white: aderantColors.Stormtrooper,
        header: aderantColors.Galaxy,
        hover: aderantColors.Lightsaber,
        hoverLight: aderantColors.Lightsaber45,
        footer: aderantColors.Lightsaber45,
        input: aderantColors.Tauntaun,
        highlight: aderantColors.Rey
    },
    text: {
        black: aderantColors.DarthVader,
        grey: aderantColors.DeathStar,
        white: aderantColors.Stormtrooper,
        highlight: aderantColors.C3PO,
        hyperlink: aderantColors.R2D2,
        disabled: aderantColors.DeathStar
    },
    // Feedback colours
    status: {
        green: aderantColors.BobaFett,
        greenLight: aderantColors.Yoda,
        green2: aderantColors.JarJarBinks,
        green2Light: aderantColors.Grogu,
        red: aderantColors.DarthMaul,
        redLight: aderantColors.Sith,
        orange: aderantColors.BB8,
        orangeLight: aderantColors.Tatooine,
        yellow: aderantColors.C3PO,
        yellowLight: aderantColors.Rey,
        purple: aderantColors.Nebula,
        purpleLight: aderantColors.ANewHope,
        blue: aderantColors.R2D2,
        blueLight: aderantColors.Lightsaber,
        grey: aderantColors.DeathStar,
        greyLight: aderantColors.Tauntaun,
        grey2: aderantColors.Watto,
        grey2Light: aderantColors.Andor
    },

    border: aderantColors.TIEFighter,
    divider: aderantColors.MillenniumFalcon,
    avatar: {
        background: aderantColors.Stormtrooper,
        secondary: aderantColors.R2D2,
        hover: aderantColors.Lightsaber,
        active: aderantColors.Ahsoka
    }
} as const;
