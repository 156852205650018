import * as React from "react";

export const FailedSearchIcon = (props: { title?: string }): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="36" viewBox="0 0 20 18" aria-label="A red triangle that indicates the search request has failed and must be re-searched">
        <title>{props.title || "Search failed, please search again. If the search fails several times, contact Support."}</title>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-47 -47)">
                <g transform="translate(47 47)">
                    <path
                        fill="#C20027"
                        fillRule="nonzero"
                        d="M19.582 13.944L12 1.137A2.35 2.35 0 009.98 0a2.35 2.35 0 00-2.02 1.137L.31 14.057a2.233 2.233 0 000 2.276 2.35 2.35 0 002.02 1.137h15.302c1.286 0 2.333-1.02 2.333-2.275 0-.448-.133-.88-.385-1.251z"
                    ></path>
                    <path
                        fill="#F3CCD2"
                        fillRule="nonzero"
                        d="M2.053 16.222a.812.812 0 01-.698-.393.773.773 0 010-.787l7.928-13.4a.812.812 0 01.697-.394c.287 0 .555.15.698.393l7.87 13.306c.01.016.02.032.032.048.09.13.138.282.138.44a.798.798 0 01-.806.787H2.053z"
                    ></path>
                    <text fill="#000" fontFamily="OpenSans-Bold, Open Sans" fontSize="9" fontWeight="bold">
                        <tspan x="9.107" y="12.859">
                            !
                        </tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>
);
