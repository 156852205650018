import { BlobClientOptions, BlobStore } from "@aderant/azure-storage";
import { ConnectionContext, FirmSettings, ok, parseStorageAccountConnectionString, unexpectedError } from "aderant-conflicts-models";
import { Logger } from "aderant-web-fw-core";
import { isUserlessKeyAuthFunctionAppContext, UserlessKeyAuthFunctionAppContext } from "../ConflictsContext";
import { getSettingsByFieldPaths } from "../FirmSettingsConnector/FirmSettingsConnector";
import { MultiResponse } from "../Http/HttpResponse";

export function isUrlParamFeatureFlagOn(flagName: string, logger?: Logger): boolean {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get(flagName);
    if (logger) {
        logger.debug(`isUrlParamFeatureFlagOn: urlParams=${queryString} ${flagName}=${paramValue}`);
    }
    return paramValue === "true";
}

export async function getFirmFeatureFlag(context: ConnectionContext | UserlessKeyAuthFunctionAppContext, path: FirmSettings.FieldPath, tenancyName: string): Promise<boolean> {
    const blobStore = await getFirmSettingsBlobStorageClient(context, tenancyName);
    const fetchFirmSettingsResponse = await getSettingsByFieldPaths(context, [path], blobStore);
    if (!ok(fetchFirmSettingsResponse)) {
        return false;
    }
    const setting = fetchFirmSettingsResponse?.body?.find((r) => r.id === path);
    if (setting && MultiResponse.isSuccessItem(setting) && typeof setting.body.value === "boolean") {
        return setting.body.value;
    }
    return false;
}

export async function getFirmFeatureFlags(context: ConnectionContext | UserlessKeyAuthFunctionAppContext, paths: FirmSettings.FieldPath[], tenancyName: string): Promise<Record<string, boolean>> {
    const blobStore = await getFirmSettingsBlobStorageClient(context, tenancyName);
    const fetchFirmSettingsResponse = await getSettingsByFieldPaths(context, paths, blobStore);
    const flags: Record<string, boolean> = {};
    paths.forEach((path) => {
        flags[path] = false;
    });
    if (!ok(fetchFirmSettingsResponse)) {
        return flags;
    }

    const settings = fetchFirmSettingsResponse?.body;
    settings.forEach((setting) => {
        if (setting && MultiResponse.isSuccessItem(setting) && typeof setting.body.value === "boolean") {
            flags[setting.id] = setting.body.value;
        }
    });
    return flags;
}

//This function is private, as we don't want to encourage getting connection strings just by tenancy name (instead of by context),
//but it's ok to do it for retieving feature flags, as these should never exist for any firm in the prod environment,
//so you won't inadvertently return the data for the wrong tenant
async function getFirmSettingsBlobStorageClient(context: ConnectionContext | UserlessKeyAuthFunctionAppContext, tenancyName: string): Promise<BlobStore> {
    let connectionInfo: {
        accountName: string;
    };
    if (isUserlessKeyAuthFunctionAppContext(context)) {
        if (!tenancyName) {
            throw unexpectedError("tenancyName must be provided if the context is a UserlessKeyAuthFunctionAppContext.", "Retrieving firm feature flags.");
        }
        connectionInfo = parseStorageAccountConnectionString((await context.getBlobStorageSecrets(tenancyName)).connectionString);
    } else {
        //It has to be a ConnectionContext as it's not a UserlessKeyAuthFunctionAppContext and those are the only 2 types allowed
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        connectionInfo = parseStorageAccountConnectionString((await (context as ConnectionContext).getBlobStorageSecrets()).connectionString);
    }

    const clientOptions: BlobClientOptions = {
        accountName: connectionInfo.accountName,
        containerName: "firm-settings",
        useIdentity: true
    };
    return new BlobStore(clientOptions);
}
