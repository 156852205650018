import { Lookup, SearchMessages } from "aderant-conflicts-models";
import { DataGrid, DataGridColumnDefinition } from "@aderant/aderant-react-components";
import { getAffiliationDisplayName, getPartyStatusDisplayName } from "dataProviders/lookupList";
import React from "react";
import { GridIds } from "state/reducers/appReducers";
import { RequestTermSummary } from "../../state/selectors/";

const rowMetadata = {
    getRowId: (rowData: RequestTermSummary) => rowData.rowId
}

export const fetchTermsGridColumns = (requestTerms: RequestTermSummary[], affiliationList: Lookup[], partyStatusList: Lookup[], isQuickSearch = false): DataGridColumnDefinition<RequestTermSummary>[] => {
    return [
        {
            columnName: `${isQuickSearch ? SearchMessages.TERM.getMessage() : SearchMessages.REQUEST_TERM.getMessage()} (${requestTerms.length})`,
            allowSort: false,
            path: (originalRow: RequestTermSummary) => originalRow.term,
            id: "requestTerms"
        },
        {
            columnName: SearchMessages.AFFILIATION.getMessage(),
            id: "affiliation",
            path: (originalRow: RequestTermSummary) => getAffiliationDisplayName(originalRow.affiliation || "", affiliationList),
            allowSort: false
        },
        {
            columnName: SearchMessages.PARTY_STATUS.getMessage(),
            id: "partyStatus",
            path: (originalRow: RequestTermSummary) => getPartyStatusDisplayName(originalRow.partyStatus || "", partyStatusList),
            allowSort: false
        },
        {
            columnName: SearchMessages.SEARCH_MODIFIERS.getMessage(),
            path: (originalRow: RequestTermSummary) => originalRow.searchModifiers,
            id: "searchModifiers",
            allowSort: false
        },
        ...(isQuickSearch ? [] : [{
            columnName: SearchMessages.SEARCH_TERMS.getMessage(),
            path: (originalRow: RequestTermSummary) => originalRow.searchTerms.join(", "),
            id: "searchTerms",
            allowSort: false,
            flexGrow: 5
        }])
    ];
} 
export const TermsGrid = (props: { requestTerms: RequestTermSummary[], columns: DataGridColumnDefinition<RequestTermSummary>[] }) => {
    return (
        <>
            <DataGrid
                data={props.requestTerms}
                columnDefinitions={props.columns}
                options={{
                    allowMoveColumns: false
                }}
                gridPreferences={{
                    id: GridIds.termsGrid,
                    columnPreferences: []
                }}
                rowMetadata={rowMetadata}
            />
        </>
    );
};
