/**
 * Wrap an async operation and return how long it took to complete
 * Example: const [ response, operationTimeMs ] = LogTimeTakenForAsync<Foo>(() => client.performOperation(arg1, arg2));
 *
 * @param action
 */
export async function LogTimeTakenForAsync<T>(action: () => Promise<T>): Promise<[T, number]> {
    const start = new Date();
    const response = await action();
    const end = new Date();
    return [response, end.getTime() - start.getTime()];
}
