//todo work out how to namespace some of this stuff and refactor to make imports less... wordy (especially the Error, Response and API surface types for autocomplete friendliness)
//https://stackoverflow.com/questions/30357634/how-do-i-use-namespaces-with-typescript-external-modules <- the typescript community seems to be somewhat... opposed to non filesystem based code organization
//more reading necessary
//work item: https://dev.azure.com/Aderant/Conflicts/_workitems/edit/1257/

export * as APIs from "./FunctionApps/APIs/index";
export * from "./FunctionApps/APIs/SearchStore/Validation/SaveSearch/ValidatedSaveSearchInput";
export * from "./FunctionApps/APIs/UserManagement/Surface";
export * from "./FunctionApps/AzureCognitiveSearchClient/AzureCognitiveSearchClient";
export * from "./FunctionApps/AzureCognitiveSearchClient/AzureSettings";
export * from "./FunctionApps/AzureFunctionDefinition";
export * from "./FunctionApps/BlobStorageConnector/BlobStorageConnector";
export * from "./FunctionApps/SearchDataIngestion/LookupDataConnector";
export * from "./FunctionApps/SearchDataIngestion/SearchIndexConnector";
export * from "./FunctionApps/SearchDataIngestion/relatedEntitiesMap";
export * from "./FunctionApps/SearchDataIngestion/IngestSearchData";
export * from "./FunctionApps/SearchDataIngestion/PriorSearchesIndexer";
export * from "./FunctionApps/SearchDataIngestion/Types";
export { ConfigurationService } from "./FunctionApps/Config/ConfigurationService";
export { GridConfigurationService } from "./FunctionApps/Config/GridConfiguration/GridConfigurationService";
export * from "./FunctionApps/Config/EnvironmentService";
export * from "./FunctionApps/Config/EntityConfiguration/EntityConfigurationService";
export * from "./FunctionApps/Config/EntityConfiguration/getEntityFlyoutConfiguration";
export * from "./FunctionApps/Config/EntityConfiguration/getEntityFlyoutConfigurations";
export * from "./FunctionApps/Config/EntityConfiguration/getEntityRelationshipConfiguration";
export * from "./FunctionApps/Config/EntityConfiguration/getEntityRelationshipConfigurations";
export * from "./FunctionApps/Config/EntityConfiguration/updateEntityFlyoutConfigurations";
export * from "./FunctionApps/Config/Customization/validateCustomizationWip";
export * from "./FunctionApps/Config/featureFlag";
export * as FeatureFlagModule from "./FunctionApps/Config/featureFlag";
export * from "./FunctionApps/Config/hasPermission";
export * from "./FunctionApps/Config/RowLevelSecurityConfiguration/RowLevelSecurityConfigurationService";
export * as EnvironmentService from "./FunctionApps/Config/EnvironmentService";
export * from "./FunctionApps/ConflictsContext";
export * from "./FunctionApps/CosmosConnector/CosmosConnector";
export * from "./FunctionApps/CosmosConnector/Triggers/createAuditTrigger";
export * from "./FunctionApps/DataFactoryConnector/DataFactoryConnector";
export * as EmailBuilder from "./FunctionApps/Email/EmailBuilders";
export * from "./FunctionApps/Email/EmailProvider";
export * from "./FunctionApps/FirmSettingsConnector/FirmSettingsConnector";
export * from "./FunctionApps/GlobalUserService/GlobalUserService";
export * from "./FunctionApps/GlobalUserService/getIdentityToken";
export * from "./FunctionApps/GlobalUserService/getUser";
export * from "./FunctionApps/Http/HeaderBuilder";
export * from "./FunctionApps/Http/HttpResponse";
export * from "./FunctionApps/Http/HttpVerb";
export * from "./FunctionApps/SchemaValidation/validateSchema";
export * from "./FunctionApps/Http/transformQueryParameter";
export * from "./FunctionApps/SecretConnectors/FunctionKeySecret";
export * from "./FunctionApps/SecretConnectors/TenantSecret/TenantSecretConnector";
export * from "./FunctionApps/StorageQueues/getQueueClient";
export * from "./FunctionApps/TestUtilities/BlobStorageConnectorMock";
export * from "./FunctionApps/TestUtilities/RlsBlobStoreMock";
export * from "./FunctionApps/TestUtilities/DataFactoryConnectorMock";
export * from "./FunctionApps/TestUtilities/EmailServiceMock";
export * from "./FunctionApps/TestUtilities/FunctionAppContext";
export * from "./FunctionApps/TestUtilities/GlobalUserServiceMock";
export * from "./FunctionApps/TestUtilities/RlsTableStoreMock";
export * from "./FunctionApps/TestUtilities/SearchIndexConnectorMock";
export * from "./FunctionApps/TestUtilities/UserManagement";
export * from "./FunctionApps/TestUtilities/withEnvVar";
export * from "./FunctionApps/TestUtilities/EntityStoreTestUtilities";
export * from "./FunctionApps/TestUtilities/InputValidationTestUtilities";
export * as IntegrationTest from "./FunctionApps/TestUtilities/IntegrationTestUtilities";
export * from "./OAuth/ConflictsClaims";
export * from "./OAuth/roles";
export * from "./OAuth/TargetEnvironmentHeaders";
export * from "./Utilities/TimeLogging";
export * as RowLevelSecurity from "./FunctionApps/RLS";
