import { createRefinerOptions, getListRefinerGroups, RefinerGroup, RefinerGroupType, setupDefaultRefinerGroup } from "@aderant/aderant-react-components";
import { SearchRequestRow } from "../SearchesPage/SearchRequestRow";

export const SearchRefinerMapping = (results: SearchRequestRow[]): RefinerGroup[] => {
    const refinerGroups: RefinerGroup[] = [
        setupDefaultRefinerGroup({ name: "Created On", path: "createdOn", refinerGroupType: RefinerGroupType.DateRange }),
        setupDefaultRefinerGroup({ name: "Last Modified", path: "lastModified", refinerGroupType: RefinerGroupType.DateRange }),
        setupDefaultRefinerGroup({ name: "Request Status", path: "status", refinerGroupType: RefinerGroupType.ListRefiner }),
        setupDefaultRefinerGroup({ name: "Error", path: "isErrored", refinerGroupType: RefinerGroupType.ListRefiner })
    ];
    createRefinerOptions(getListRefinerGroups(refinerGroups), results);
    return refinerGroups;
};
