/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { actionChannel, call, take } from "redux-saga/effects";

export type Action<ActionType, Payload> = {
    actionType: ActionType;
    payload: Payload;
};

/**
 * watcher for sequentially processing actions of type ActionType
 * @param actionType the action type that this watcher should listen to (ideally an enum or union type with string values rather than a raw string so the compiler can ensure that actionType and process match)
 * @param process the function to call to process each action
 */
export function* watch<ActionType extends string, Payload>(actionType: ActionType, process: (action: Action<ActionType, Payload>) => Generator) {
    const channel = yield actionChannel(actionType);
    while (true) {
        const action = yield take(channel);
        yield call(process, action);
    }
}

type Inner<T> = T extends Promise<infer U> ? U : T;

// This type requires anys, I couldn't work out a sensibile type that is compatiable with Redux, unknowns and never did not work.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Return<T> = Inner<ReturnType<T extends (...args: any) => any ? T : any>>;

export function parseError(error: unknown): string {
    if (error instanceof Error) {
        return error.message;
    } else {
        return JSON.stringify(error);
    }
}
