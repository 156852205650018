import { isErroredSearch, QuickSearch, SearchValidationMessages, SearchVersion, SearchVersionNew } from "aderant-conflicts-models";
import { Button } from "@aderant/aderant-react-components";
import { FailedSearchIcon } from "components/Icons/FailedSearchIcon";
import React from "react";
import { useSelector } from "react-redux";
import { getIsCurrentSearchSaving, getIsSearching } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import { Messages } from "./Messages";

export type SearchEditPageFooterProps = {
    action: (e: React.MouseEvent) => void; //Either perform a search or submit it, depending on user permissions
    searchVersion?: SearchVersion | SearchVersionNew | QuickSearch;
    fieldsAreRequired: boolean;
    actionButtonText: string;
    alwaysDisabled: boolean;
};

function FailedSearch(props) {
    const searchVersion: SearchVersion | SearchVersionNew | QuickSearch = props.searchVersion;
    if (searchVersion && searchVersion.searchRequestMessages && isErroredSearch(searchVersion.searchRequestMessages)) {
        // NOTE: This code will need to be adjusted when we consider that the search can have multiple validation errors.
        // but for now we'll just take the first option, until there is a design for this.
        const messageWithValidationErrors = searchVersion.searchRequestMessages.find((message) => message.validationErrors);
        let validationMessage: string[] = [];
        if (messageWithValidationErrors && messageWithValidationErrors.validationErrors) {
            //Convert validation error if it is a quicksearch
            if (searchVersion.isQuickSearch && messageWithValidationErrors.validationErrors[0].messageCode === "VLD_INVALID_SEARCH_QUERY") {
                //Split the search message to get the failing search term.
                const splitSearchMessage = messageWithValidationErrors.validationErrors[0].message.split('"');
                const quickSearchMessage = SearchValidationMessages.Messages.VLD_INVALID_QUICK_SEARCH_QUERY.getMessage(splitSearchMessage[1]);
                validationMessage = quickSearchMessage.split("\n");
            } else {
                validationMessage = messageWithValidationErrors.validationErrors[0].message.split("\n");
            }
        }

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginTop: "4px" }}>
                    <FailedSearchIcon title={validationMessage[0] && validationMessage[1] ? validationMessage[0] + " " + validationMessage[1] : undefined} />
                </div>
                <div style={{ padding: "10px" }}>
                    <div>{validationMessage[0] ?? Messages.SEARCH_FAILED_WARNING.getMessage()}</div>
                    <div>{validationMessage[1] ?? Messages.SEARCH_FAILED_SUPPORT_CALL.getMessage()}</div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default function SearchEditPageFooter({ action: pageFooterAction, searchVersion, fieldsAreRequired, actionButtonText, alwaysDisabled }: SearchEditPageFooterProps): JSX.Element {
    const isCurrentSearchSaving: boolean = useSelector(getIsCurrentSearchSaving);
    const isSearching: boolean = useSelector(getIsSearching);
    const isFooterButtonDisabled =
        alwaysDisabled || !searchVersion || searchVersion.editState === "NEW" || searchVersion.status === "SEARCHING" || isCurrentSearchSaving || isSearching || fieldsAreRequired;

    return (
        <div className="page-footer" style={{ backgroundColor: conflictsPalette.background.footer, alignItems: "center" }}>
            <FailedSearch searchVersion={searchVersion} />
            <Button size="medium" color="primary" onClick={pageFooterAction} text={actionButtonText} disabled={isFooterButtonDisabled} className="footer-button search-button" />
        </div>
    );
}
