import { Telemetry, AppInsightsClient } from "aderant-web-fw-azfunctions";

export class TestAppInsightsClient implements AppInsightsClient {
    trackPageView(telemetry: Telemetry.PageView): void {}
    trackEvent(telemetry: Telemetry.Event): void {}
    trackTrace(telemetry: Telemetry.Trace): void {}
    trackMetric(telemetry: Telemetry.Metric): void {}
    trackException(telemetry: Telemetry.Exception): void {}
    getTelemetryCorrelationInfo(): { operationId: string; operationParentId?: string } {
        return { operationId: "testOperationId" };
    }
    setTelemetryCorrelationInfo(correlationInfo: { operationId: string; operationParentId: string }): void {}
}
