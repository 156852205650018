import { basicConflictsErrorTypes, ConflictsError, isConflictsError, NotFound, UnexpectedError, unexpectedError, ValidationErrors } from "aderant-conflicts-models";
import { HttpConflictsError, HttpResponse } from "../../..";

export function isExpectedError<Err extends ConflictsError>(error: unknown, knownErrorTypes: Set<Err["_conflictserrortype"]>): error is Err {
    return error !== null && error !== undefined && isConflictsError(error) && knownErrorTypes.has(error._conflictserrortype);
}

export function narrowError<Err extends ConflictsError>(error: unknown, statusCode: number | undefined, knownErrorTypes: Set<Err["_conflictserrortype"]>, context: string): Err | UnexpectedError {
    if (isExpectedError(error, knownErrorTypes)) {
        return error;
    } else {
        return unexpectedError(error, context, statusCode);
    }
}

export function httpResponseFromError<Err extends ConflictsError>(err: Err): HttpResponse {
    const body: HttpConflictsError<Err> = { ...err, httpStatusCode: undefined };

    return {
        status: err.httpStatusCode,
        body: body
    };
}

//need this here because we don't have a concrete set of possible error types, probably not useful outside of this internal code
export function isBasicConflictsError(err: ConflictsError<any>): err is ValidationErrors | NotFound {
    const errorTypes: string[] = Object.values(basicConflictsErrorTypes);
    return errorTypes.includes(err._conflictserrortype);
}
