import { ConflictsContext, UserlessKeyAuthFunctionAppContext } from "../../../ConflictsContext";

export type FunctionAppDependency<T, Context extends ConflictsContext | UserlessKeyAuthFunctionAppContext> = ((context: Context) => Promise<T>) | ((context: Context) => T);

/**
 * This type turns any type ```[A, B, C...]``` into
 *
 * ```[A | FunctionAppDependency<A>, B | FunctionAppDependency<B>, C | FunctionAppDependency<C>...]```.
 *
 * Use case: we want to take dependencies that require a Context to construct without having to make a duplicate Context in the caller.
 *
 * The ? never stuff is to avoid the ambiguity when a dependency is itself a function, this forces you to always provide a builder func (```FunctionAppDependency```) when the dependency is a func (i.e. ```() => () => {...}``` instead of ```() => {...}```)
 */
export type Unbuilt<Values extends Array<unknown>, Context extends ConflictsContext | UserlessKeyAuthFunctionAppContext> = {
    // justification: we want to use Function here to catch *all* functions so that they can't be used as values, as that would be ambiguous with FunctionAppDependency, which is also a function.
    // eslint-disable-next-line @typescript-eslint/ban-types
    [P in keyof Values]: (Values[P] extends Function ? never : Values[P]) | FunctionAppDependency<Values[P], Context>;
};

export async function buildDependencies<Values extends Array<unknown>, Context extends ConflictsContext | UserlessKeyAuthFunctionAppContext>(
    unbuiltDependencies: Unbuilt<Values, Context>,
    context: Context
): Promise<Values> {
    const dependencyPromises: Promise<unknown>[] = unbuiltDependencies.map((d) => (typeof d === "function" ? d(context) : d));

    const built: unknown[] = await Promise.all(dependencyPromises);

    //Justification:
    //Values looks like [foo, bar, baz]
    //Unbuilt<Values> looks like [foo | () => Promise<foo> | () => foo, bar...]
    //Array.map and Promise.all both retain order, so we know once we're done we must have gone from Unbuilt<Values> to Values.
    //(There's likely a clever way to avoid this cast, but I haven't thought of it yet)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return built as Values;
}
