/**Try to extract search and version ids from arbitrary function input/queue message input.
 * This will look for search and version ids using all of the commonly used structures.
 * Ideally update this function rather than handling it manually in a specific implementation to avoid
 * making business logic more complicated.
 */
export function extractSearchLogContext(input: unknown): { sId?: string; vId?: string } {
    const result: { sId?: string; vId?: string } = {};
    if (!input || typeof input !== "object") {
        return result;
    }

    //SearchVersionIdentifier type
    if (hasObject(input, "searchId")) {
        if (hasString(input.searchId, "searchId")) {
            result.sId = shortenGuid(input.searchId.searchId);
        }
        if (hasString(input.searchId, "versionId")) {
            result.vId = shortenGuid(input.searchId.versionId);
        }
        //standard searchVersion input
    } else if (hasObject(input, "searchVersion")) {
        if (hasString(input.searchVersion, "searchId")) {
            result.sId = input.searchVersion.searchId;
        }
        if (hasString(input.searchVersion, "id")) {
            result.vId = input.searchVersion.id;
        }
        //ValidatedSaveSearchInput
    } else if (hasObject(input, "search") && hasObject(input.search, "searchVersion")) {
        if (hasString(input.search.searchVersion, "searchId")) {
            result.sId = input.search.searchVersion.searchId;
        }
        if (hasString(input.search.searchVersion, "id")) {
            result.vId = input.search.searchVersion.id;
        }
        //id fields on base input
    } else {
        if (hasString(input, "searchId")) {
            result.sId = shortenGuid(input.searchId);
        } else if (hasObject(input, "searchVersion") && hasString(input.searchVersion, "searchId")) {
            result.sId = shortenGuid(input.searchVersion.searchId);
        } else if (hasObject(input, "quickSearch") && hasString(input.quickSearch, "searchId")) {
            result.sId = shortenGuid(input.quickSearch.searchId);
        }

        if (hasString(input, "versionId")) {
            result.vId = shortenGuid(input.versionId);
        } else if (hasObject(input, "searchVersion") && hasString(input.searchVersion, "versionId")) {
            result.vId = shortenGuid(input.searchVersion.versionId);
        } else if (hasObject(input, "quickSearch") && hasString(input.quickSearch, "versionId")) {
            result.vId = shortenGuid(input.quickSearch.versionId);
        }
    }

    return result;
}

//these functions are safe to call on arbitrary values - addressing by string on null/undefined/primitive just returns undefined rather than blowing up.
function hasObject<Key extends string>(input: any, expectedKey: Key): input is { [k in Key]: Record<string, any> } {
    return input[expectedKey] !== undefined && typeof input[expectedKey] === "object";
}

function hasString<Key extends string>(input: any, expectedKey: Key): input is { [k in Key]: string } {
    return input[expectedKey] !== undefined && typeof input[expectedKey] === "string";
}

function shortenGuid(guid: string | undefined): string | undefined {
    if (guid) {
        return `${guid.split("-")[0]}...`;
    } else {
        return guid;
    }
}
