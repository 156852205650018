import React from "react";

export const SecuredRowPadlockIcon = (): JSX.Element => {
    return (
        <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.625 6H8.9375V3.57143C8.9375 1.6 7.3975 0 5.5 0C3.6025 0 2.0625 1.6 2.0625 3.57143V6H1.375C0.61875 6 0 6.64286 0 7.42857V13.5714C0 14.3571 0.61875 15 1.375 15H9.625C10.3813 15 11 14.3571 11 13.5714V7.42857C11 6.64286 10.3813 6 9.625 6ZM3.4375 6V3.57143C3.4375 2.38571 4.35875 1.42857 5.5 1.42857C6.64125 1.42857 7.5625 2.38571 7.5625 3.57143V6H3.4375Z"
                fill="#798592"
            />
        </svg>
    );
};
