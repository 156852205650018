import { Person } from "@mui/icons-material";
import { Button } from "@aderant/aderant-react-components";
import { Header } from "components/Header/Header";
import SettingsMenu from "components/SettingsMenu/SettingsMenu";
import React from "react";

const messages = {
    signOut: "Sign Out"
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Page(props: React.ComponentPropsWithRef<any>): JSX.Element {
    return (
        <div className="page">
            <div>
                <Header onSignOut={props.onSignOut} />
                {/* ToDo:Hiding these for now, revisit this in future */}
                <div style={{ alignSelf: "center", display: "none", flexDirection: "row" }}>
                    <Button iconButton onClick={props.onSignOut} title={messages.signOut} startIcon={<Person />} aria-label="Log out" />
                    <SettingsMenu />
                </div>
            </div>
            {props.children}
        </div>
    );
}
