import { Message } from "aderant-conflicts-models";
/** All strongly typed path maps for different pages and paths*/
export enum PathMap {
    home = "/",
    searchRequest = "/search/",
    searchRequests = "/search-requests",
    quickSearch = "/quick-search",
    results = "/results/",
    quickSearches = "/quick-searches",
    indexMaintainance = "/indexmaintenance",
    newVersion = "/newversion",
    firmSettings = "/settings",
    logOut = "/",
    none = ""
}
export enum TabNameMap {
    searchRequests = "SEARCH_REQUESTS",
    quickSearches = "QUICK_SEARCHES",
    new = "New"
}

export const TabNameMessages = {
    SEARCH_REQUESTS: new Message("SEARCH_REQUESTS", "Search Requests"),
    QUICK_SEARCHES: new Message("QUICK_SEARCHES", "Quick Searches"),
    NEW: new Message("NEW", "New")
};

export enum MenuNameMap {
    searchRequest = "NEW_SEARCH_REQUEST",
    quickSearch = "NEW_QUICK_SEARCH",
    firmSettings = "FIRM_SETTINGS",
    logOut = "LOG_OUT",
    signedIn = "SIGNED_INTO"
}

export const MenuNameMessages = {
    NEW_SEARCH_REQUEST: new Message("SEARCH_REQUEST", "New Search Request"),
    NEW_QUICK_SEARCH: new Message("QUICK_SEARCH", "New Quick Search"),
    FIRM_SETTINGS: new Message("FIRM_SETTINGS", "Firm Settings"),
    LOG_OUT: new Message("LOG_OUT", "Log Out"),
    SIGNED_INTO: new Message<[string]>("SIGNED_INTO", "Signed into {0}")
};
