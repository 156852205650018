import { getSynonymMapName } from "./AzureSearchNames";

// Represents a SynonymMap in cognitive search
export type SynonymGroup = {
    synonyms: string[];
};

export type SynonymMap = {
    synonymGroups: SynonymGroup[]; // list of synonym groups
    etag: string;
};

export type SynonymMapAzureRequest = {
    name: string;
    synonyms: string[];
    etag?: string;
};

export function convertToSynonymMapRequest(synonymMap: SynonymMap, tenancyName: string): SynonymMapAzureRequest {
    return {
        name: getSynonymMapName(tenancyName),
        synonyms: synonymMap.synonymGroups.map((group) => group.synonyms.join(", ")),
        etag: synonymMap.etag
    };
}

export function convertFromSynonymMapRequest(synonymMapRequest: SynonymMapAzureRequest): SynonymMap {
    return {
        synonymGroups: synonymMapRequest.synonyms.map((group) => {
            const synonymGroup: SynonymGroup = {
                synonyms: group.split(", ")
            };
            return synonymGroup;
        }),
        etag: synonymMapRequest.etag || ""
    };
}
