import {
    Audit,
    Awaited,
    BasicCurrentUserContext,
    ConnectionContext,
    DiagnosticInformation,
    DurableHitIdentifier,
    EtagMismatch,
    Forbidden,
    Hit,
    HitComment,
    CreateHitCommentInput,
    EditHitCommentInput,
    HitIdentifier,
    NotFound,
    PriorSearchEntity,
    QuickSearch,
    SearchErrors,
    SearchMassEditFields,
    SearchSummary,
    SearchVersionEdited,
    SearchVersionEtag,
    SearchVersionIdentifier,
    SearchVersionNew,
    SearchVersionUnedited,
    ValidationErrors,
    HitCommentCosmosId
} from "aderant-conflicts-models";
import { ValidatedSaveSearchInput } from "../../..";
import { AzureFunctionDefinition, AzureKeyAuthFunctionDefinition } from "../../AzureFunctionDefinition";
import { getFunctionAppUrl } from "../../Config/EnvironmentService";
import { TokenAuthContext, UserlessKeyAuthFunctionAppContext } from "../../ConflictsContext";
import { EmptyOkResponse, MultiResponse, OkCreatedResponseWithHeaders, OkResponse, OkResponseWithHeaders } from "../../Http/HttpResponse";

export type GetSearchReqBody =
    | (SearchVersionIdentifier & { versionNumber?: undefined; fetchHitResults: boolean })
    | {
          searchId: string;
          versionId?: undefined;
          versionNumber: number;
          fetchHitResults: boolean;
      };

export const SearchStore = {
    get: new AzureFunctionDefinition<
        GetSearchReqBody, //                        Input type
        SearchVersionUnedited, //                   Output type
        OkResponseWithHeaders<SearchVersionUnedited>, //       Possible Http Response codes of Output (union for multiple)
        NotFound | SearchErrors.InvalidId | SearchErrors.InvalidVersionNumber | SearchErrors.UserCannotViewSearch //    Possible error types (union for multiple)
    >({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND", "INVALID_VERSION_NUMBER", "INVALID_ID", "USER_CANNOT_VIEW_SEARCH"],
        getUrlEnd: (input) =>
            "versionId" in input
                ? `./api/Searches/${input.searchId}/Versions/${input.versionId}?fetchHitResults=${input.fetchHitResults}`
                : `./api/Searches/${input.searchId}/Versions?version=${input.versionNumber}&fetchHitResults=${input.fetchHitResults}`
    }),
    getQuickSearch: new AzureFunctionDefinition<
        GetSearchReqBody, //                        Input type
        QuickSearch, //                   Output type
        OkResponseWithHeaders<QuickSearch>, //       Possible Http Response codes of Output (union for multiple)
        NotFound | SearchErrors.InvalidId | SearchErrors.UserCannotViewSearch //    Possible error types (union for multiple)
    >({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND", "INVALID_ID", "USER_CANNOT_VIEW_SEARCH"],
        getUrlEnd: (input) => `./api/QuickSearches/${input.searchId}/Versions/${input.versionId}?fetchHitResults=${input.fetchHitResults}`
    }),
    getLatestSearchVersion: new AzureFunctionDefinition<
        { searchId: string; fetchHitResults: boolean }, //                    Input type
        SearchVersionUnedited | QuickSearch, //                   Output type
        OkResponseWithHeaders<SearchVersionUnedited> | OkResponseWithHeaders<QuickSearch>, //       Possible Http Response codes of Output (union for multiple)
        NotFound | SearchErrors.InvalidId | SearchErrors.UserCannotViewSearch //    Possible error types (union for multiple)
    >({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND", "INVALID_ID", "USER_CANNOT_VIEW_SEARCH"],
        getUrlEnd: (input) => `./api/Searches/${input.searchId}/LatestVersion?fetchHitResults=${input.fetchHitResults}`
    }),
    getPriorSearchEntity: new AzureFunctionDefinition<
        { searchId: string }, //                    Input type
        PriorSearchEntity, //                   Output type
        OkResponseWithHeaders<PriorSearchEntity>, //       Possible Http Response codes of Output (union for multiple)
        NotFound | SearchErrors.InvalidId | SearchErrors.InvalidSearchType | SearchErrors.InvalidStatus //    Possible error types (union for multiple)
    >({
        httpVerb: "GET",
        expectedErrors: ["NOT_FOUND", "INVALID_ID", "INVALID_SEARCH_TYPE", "INVALID_STATUS"],
        getUrlEnd: (input) => `./api/Searches/${input.searchId}/PriorSearch`
    }),
    generateNumber: new AzureFunctionDefinition<
        { searchId: string }, //     Input type
        string, //                   Output type
        OkResponse<string>, //       Possible Http Response codes of Output (union for multiple)
        NotFound | SearchErrors.InvalidId //    Possible error types (union for multiple)
    >({
        httpVerb: "POST",
        expectedErrors: ["NOT_FOUND", "INVALID_ID"],
        getUrlEnd: (input) => `./api/Searches/${input.searchId}/GenerateNumber`
    }),
    getSearchSummaries: new AzureFunctionDefinition<undefined, SearchSummary[], OkResponseWithHeaders<SearchSummary[]>, SearchErrors.InvalidId>({
        httpVerb: "GET",
        expectedErrors: ["INVALID_ID"],
        getUrlEnd: () => `./api/SearchSummaries`
    }),
    getSearchSummariesBySearchId: new AzureFunctionDefinition<{ searchIds: string[] }, GetSearchSummariesByIdResults, GetSearchSummariesByIdResponse, SearchErrors.InvalidId | ValidationErrors>({
        httpVerb: "POST",
        expectedErrors: ["INVALID_ID", "VALIDATION"],
        getUrlEnd: () => `./api/SearchSummaries`
    }),
    saveSearch: new AzureFunctionDefinition<
        { searchVersion: SearchVersionNew | WithEtag<SearchVersionEdited>; audits?: Audit[]; reassignMessage?: string },
        SearchVersionUnedited,
        OkResponseWithHeaders<SearchVersionUnedited> | OkCreatedResponseWithHeaders<SearchVersionUnedited>,
        ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType
    >({
        httpVerb: "POST",
        expectedErrors: ["VALIDATION", "ETAG_MISMATCH", "NOT_FOUND", "INVALID_SEARCH_TYPE"],
        getUrlEnd: () => `./api/Searches/`
    }),
    saveQuickSearch: new AzureFunctionDefinition<
        { quickSearch: QuickSearch | WithEtag<QuickSearch> },
        QuickSearch,
        OkResponseWithHeaders<QuickSearch>,
        ValidationErrors | NotFound | EtagMismatch | SearchErrors.InvalidSearchType
    >({
        httpVerb: "POST",
        expectedErrors: ["INVALID_SEARCH_TYPE", "ETAG_MISMATCH", "NOT_FOUND"],
        getUrlEnd: () => `./api/QuickSearches/`
    }),
    createNewSearchVersion: new AzureFunctionDefinition<
        { searchVersion: SearchVersionEdited },
        SearchVersionUnedited,
        OkResponseWithHeaders<SearchVersionUnedited> | OkCreatedResponseWithHeaders<SearchVersionUnedited>,
        SearchNewVersionErrors
    >({
        httpVerb: "POST",
        expectedErrors: ["NOT_FOUND", "VALIDATION", "INVALID_ID", "NOT_ASSIGNED_TO", "NOT_LATEST_SEARCH_VERSION", "INVALID_STATUS"],
        getUrlEnd: (input) => `./api/Searches/${input.searchVersion.searchId}/Versions/`
    }),
    createQuickSearch: new AzureFunctionDefinition<{ quickSearch: QuickSearch }, QuickSearch, OkCreatedResponseWithHeaders<QuickSearch>, SearchErrors.InvalidSearchType | EtagMismatch | Forbidden>({
        httpVerb: "POST",
        expectedErrors: ["ETAG_MISMATCH", "ACCESS_DENIED"],
        getUrlEnd: (input) => `./api/QuickSearches/${input.quickSearch.searchId}`
    }),
    deleteSearches: new AzureFunctionDefinition<
        { searchVersionIdentifiers: SearchVersionIdentifier[]; force?: boolean },
        SearchesDeleteResults,
        SearchesDeleteResponse,
        ValidationErrors | SearchErrors.InvalidId | SearchErrors.InvalidStatus
    >({
        httpVerb: "DELETE",
        expectedErrors: ["VALIDATION", "INVALID_ID", "INVALID_STATUS"],
        getUrlEnd: () => `./api/Searches`
    }),
    updateHits: new AzureFunctionDefinition<
        WithEtag<SearchVersionIdentifier & { hitIds: HitIdentifier[]; change: Partial<Hit> }>,
        SearchVersionUnedited,
        OkResponseWithHeaders<SearchVersionUnedited> | OkCreatedResponseWithHeaders<SearchVersionUnedited>,
        NotFound | EtagMismatch | SearchErrors.InvalidId | ValidationErrors | SearchErrors.NotLatestSearchVersion | SearchErrors.NotAssignedTo | SearchErrors.InvalidSearchType
    >({
        httpVerb: "PATCH",
        expectedErrors: ["VALIDATION", "NOT_FOUND", "INVALID_ID", "NOT_ASSIGNED_TO", "ETAG_MISMATCH", "INVALID_SEARCH_TYPE"],
        getUrlEnd: (input) => `./api/Searches/${input.searchId}/Versions/${input.versionId}/Hits`
    }),
    updateSearches: new AzureFunctionDefinition<
        { searchVersionIdentifiers: WithEtag<SearchVersionIdentifier>[]; change: SearchMassEditFields },
        SearchesUpdateResults,
        SearchesUpdateResponse,
        ValidationErrors,
        { skipUnlessOlderThanHours?: number } // optional request params
    >({
        httpVerb: "PATCH",
        expectedErrors: ["VALIDATION"],
        getUrlEnd: () => `./api/Searches/`
    }),
    getAudits: new AzureFunctionDefinition<{ searchId: string }, Audit[], OkResponseWithHeaders<Audit[]>, NotFound | SearchErrors.InvalidId>({
        httpVerb: "GET",
        expectedErrors: ["INVALID_ID"],
        getUrlEnd: (input) => `./api/Searches/${input.searchId}/Audits`
    }),
    getHealth: new AzureKeyAuthFunctionDefinition<undefined, DiagnosticInformation, OkResponse<DiagnosticInformation>, never>({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: () => `./api/Health`
    }),
    convertQuickSearch: new AzureFunctionDefinition<
        { quickSearchId: string; newSearchId: string },
        ConvertQuickSearchResults,
        ConvertQuickSearchResponse,
        NotFound | Forbidden | SearchErrors.InvalidId | SearchErrors.InvalidSearchType | ValidationErrors
    >({
        httpVerb: "POST",
        expectedErrors: ["NOT_FOUND", "VALIDATION", "INVALID_ID"],
        getUrlEnd: (input) => `./api/QuickSearches/${input.quickSearchId}/ConvertToSearchRequest`
    }),
    createHitComment: new AzureFunctionDefinition<{ hitComment: CreateHitCommentInput }, HitComment, OkCreatedResponseWithHeaders<HitComment>, Forbidden | NotFound | ValidationErrors>({
        httpVerb: "POST",
        expectedErrors: ["ACCESS_DENIED", "NOT_FOUND", "VALIDATION"],
        getUrlEnd: () => `./api/HitComments`
    }),
    getHitComments: new AzureFunctionDefinition<{ searchId: string; hitIdentifier: DurableHitIdentifier }, HitComment[], OkResponseWithHeaders<HitComment[]>, Forbidden | ValidationErrors>({
        httpVerb: "GET",
        expectedErrors: ["ACCESS_DENIED", "VALIDATION"],
        getUrlEnd: (input) =>
            `./api/HitComments?searchId=${input.searchId}&requestTermId=${input.hitIdentifier.requestTermId}&hitEntityId=${input.hitIdentifier.hitEntityId}&hitEntityType=${input.hitIdentifier.hitEntityType}`
    }),
    editHitComment: new AzureFunctionDefinition<EditHitCommentInput, HitComment, OkResponseWithHeaders<HitComment>, Forbidden | NotFound | ValidationErrors>({
        httpVerb: "POST",
        expectedErrors: ["ACCESS_DENIED", "NOT_FOUND", "VALIDATION"],
        getUrlEnd: (input) => `./api/HitComments/searchId/${input.searchId}/hitCommentId/${input.hitCommentId}`
    }),
    deleteHitComment: new AzureFunctionDefinition<HitCommentCosmosId, undefined, EmptyOkResponse, Forbidden | NotFound | ValidationErrors>({
        httpVerb: "DELETE",
        expectedErrors: ["ACCESS_DENIED", "NOT_FOUND", "VALIDATION"],
        getUrlEnd: (input) => `./api/HitComments/searchId/${input.searchId}/hitCommentId/${input.hitCommentId}`
    }),
    keyAuthFunctions: {
        get: new AzureKeyAuthFunctionDefinition<
            GetSearchReqBody, //                        Input type
            SearchVersionUnedited, //                   Output type
            OkResponseWithHeaders<SearchVersionUnedited>, //       Possible Http Response codes of Output (union for multiple)
            NotFound | SearchErrors.InvalidId | SearchErrors.InvalidVersionNumber //    Possible error types (union for multiple)
        >({
            httpVerb: "GET",
            expectedErrors: ["NOT_FOUND", "INVALID_VERSION_NUMBER", "INVALID_ID"],
            getUrlEnd: (input) =>
                "versionId" in input
                    ? `./api/KeyAuth/Searches/${input.searchId}/Versions/${input.versionId}?fetchHitResults=${input.fetchHitResults}`
                    : `./api/KeyAuth/Searches/${input.searchId}/Versions?version=${input.versionNumber}&fetchHitResults=${input.fetchHitResults}`
        }),
        getLatestSearchVersion: new AzureKeyAuthFunctionDefinition<
            { searchId: string; fetchHitResults: boolean }, //                    Input type
            SearchVersionUnedited | QuickSearch, //                   Output type
            OkResponseWithHeaders<SearchVersionUnedited> | OkResponseWithHeaders<QuickSearch>, //       Possible Http Response codes of Output (union for multiple)
            NotFound | SearchErrors.InvalidId //    Possible error types (union for multiple)
        >({
            httpVerb: "GET",
            expectedErrors: ["NOT_FOUND", "INVALID_ID"],
            getUrlEnd: (input) => `./api/KeyAuth/Searches/${input.searchId}/LatestVersion?fetchHitResults=${input.fetchHitResults}`
        }),
        getPriorSearchEntity: new AzureKeyAuthFunctionDefinition<
            { searchId: string }, //                    Input type
            PriorSearchEntity, //                   Output type
            OkResponseWithHeaders<PriorSearchEntity>, //       Possible Http Response codes of Output (union for multiple)
            NotFound | SearchErrors.InvalidId | SearchErrors.InvalidSearchType | SearchErrors.InvalidStatus //    Possible error types (union for multiple)
        >({
            httpVerb: "GET",
            expectedErrors: ["NOT_FOUND", "INVALID_ID", "INVALID_SEARCH_TYPE", "INVALID_STATUS"],
            getUrlEnd: (input) => `./api/KeyAuth/Searches/${input.searchId}/PriorSearch`
        }),
        getQuickSearch: new AzureKeyAuthFunctionDefinition<
            GetSearchReqBody, //                        Input type
            QuickSearch, //                   Output type
            OkResponseWithHeaders<QuickSearch>, //       Possible Http Response codes of Output (union for multiple)
            NotFound | SearchErrors.InvalidId //    Possible error types (union for multiple)
        >({
            httpVerb: "GET",
            expectedErrors: ["NOT_FOUND", "INVALID_ID"],
            getUrlEnd: (input) => `./api/KeyAuth/QuickSearches/${input.searchId}/Versions/${input.versionId}?fetchHitResults=${input.fetchHitResults}`
        }),
        saveSearch: new AzureKeyAuthFunctionDefinition<
            ValidatedSaveSearchInput,
            SearchVersionUnedited,
            OkResponseWithHeaders<SearchVersionUnedited> | OkCreatedResponseWithHeaders<SearchVersionUnedited>,
            EtagMismatch | SearchErrors.InvalidSearchType
        >({
            httpVerb: "POST",
            expectedErrors: ["ETAG_MISMATCH", "INVALID_SEARCH_TYPE"],
            getUrlEnd: () => `./api/KeyAuth/Searches/`
        }),
        saveQuickSearch: new AzureKeyAuthFunctionDefinition<ValidatedSaveSearchInput, QuickSearch, OkResponseWithHeaders<QuickSearch>, EtagMismatch | SearchErrors.InvalidSearchType>({
            httpVerb: "POST",
            expectedErrors: ["ETAG_MISMATCH", "INVALID_SEARCH_TYPE"],
            getUrlEnd: () => `./api/KeyAuth/QuickSearches/`
        }),
        createNewSearchVersion: new AzureKeyAuthFunctionDefinition<
            { searchVersion: SearchVersionEdited; allowWhenNotAssignedToCurrentUser?: boolean },
            SearchVersionUnedited,
            OkResponseWithHeaders<SearchVersionUnedited> | OkCreatedResponseWithHeaders<SearchVersionUnedited>,
            SearchNewVersionErrors
        >({
            httpVerb: "POST",
            expectedErrors: ["NOT_FOUND", "VALIDATION", "INVALID_ID", "NOT_ASSIGNED_TO", "NOT_LATEST_SEARCH_VERSION", "INVALID_STATUS", "INVALID_SEARCH_TYPE"],
            getUrlEnd: (input) => `./api/KeyAuth/Searches/${input.searchVersion.searchId}/Versions/`
        })
    }
};

export type WithEtag<In> = In extends ValidEtagInputs ? In : In & SearchVersionEtag;
type ValidEtagInputs = SearchVersionEtag | { searchVersion: SearchVersionNew | SearchVersionEdited };

export type ConvertQuickSearchResponse = MultiResponse.ResponseWithHeaders<
    SearchVersionUnedited | void,
    200 | 201 | 204,
    MultiResponse.UnexpectedError | SearchErrors.InvalidId | NotFound | Forbidden | SearchErrors.InvalidStatus
>;
export type ConvertQuickSearchResults = MultiResponse.Item<
    SearchVersionUnedited | void,
    200 | 201 | 204,
    MultiResponse.UnexpectedError | SearchErrors.InvalidId | NotFound | Forbidden | SearchErrors.InvalidStatus
>[];

export type SearchNewVersionErrors =
    | ValidationErrors
    | NotFound
    | SearchErrors.InvalidId
    | SearchErrors.NotLatestSearchVersion
    | SearchErrors.NotAssignedTo
    | SearchErrors.InvalidStatus
    | SearchErrors.InvalidSearchType;

export type SearchesDeleteResponse = MultiResponse.Response<void, 204, NotFound | Forbidden | MultiResponse.UnexpectedError | SearchErrors.InvalidId | SearchErrors.InvalidStatus>;
export type SearchesDeleteResults = MultiResponse.Item<void, 204, NotFound | Forbidden | MultiResponse.UnexpectedError | SearchErrors.InvalidId | SearchErrors.InvalidStatus>[];

export type SearchesUpdateResponse = MultiResponse.ResponseWithHeaders<
    WithEtag<SearchMassEditFields>,
    200,
    | NotFound
    | EtagMismatch
    | SearchErrors.InvalidId
    | SearchErrors.NotLatestSearchVersion
    | SearchErrors.NotAssignedTo
    | SearchErrors.InvalidStatusChange
    | SearchErrors.InvalidReassign
    | MultiResponse.UnexpectedError
>;
export type SearchesUpdateResults = MultiResponse.Item<
    WithEtag<SearchMassEditFields>,
    200,
    | NotFound
    | EtagMismatch
    | SearchErrors.InvalidId
    | SearchErrors.NotLatestSearchVersion
    | SearchErrors.NotAssignedTo
    | SearchErrors.InvalidStatusChange
    | SearchErrors.InvalidReassign
    | MultiResponse.UnexpectedError
>[];

export type GetSearchSummariesByIdResponse = MultiResponse.ResponseWithHeaders<SearchSummary, 200, NotFound | SearchErrors.InvalidId | MultiResponse.UnexpectedError | Forbidden>;
export type GetSearchSummariesByIdResults = MultiResponse.Item<SearchSummary, 200, NotFound | SearchErrors.InvalidId | MultiResponse.UnexpectedError | Forbidden>[];

export type SearchStoreProxy = ReturnType<typeof getSearchStoreProxy>;

//justification: this return type is necessarily defined by getProxy - to explicitly define it in the function signature
//would be unwieldy
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getSearchStoreProxy(context: TokenAuthContext) {
    const baseURL: URL = getFunctionAppUrl("SearchStoreApi");
    return {
        get: SearchStore.get.getProxy(baseURL, context),
        getLatestSearchVersion: SearchStore.getLatestSearchVersion.getProxy(baseURL, context),
        getPriorSearchEntity: SearchStore.getPriorSearchEntity.getProxy(baseURL, context),
        getQuickSearch: SearchStore.getQuickSearch.getProxy(baseURL, context),
        generateNumber: SearchStore.generateNumber.getProxy(baseURL, context),
        //getSearchSummaries is set up differently here to make it clear to the client that we require no input params.
        getSearchSummaries: async () => {
            return SearchStore.getSearchSummaries.getProxy(baseURL, context)(undefined);
        },
        getSearchSummariesBySearchId: SearchStore.getSearchSummariesBySearchId.getProxy(baseURL, context),
        saveSearch: SearchStore.saveSearch.getProxy(baseURL, context),
        saveQuickSearch: SearchStore.saveQuickSearch.getProxy(baseURL, context),
        deleteSearches: SearchStore.deleteSearches.getProxy(baseURL, context),
        updateHits: SearchStore.updateHits.getProxy(baseURL, context),
        updateSearches: SearchStore.updateSearches.getProxy(baseURL, context),
        getAudits: SearchStore.getAudits.getProxy(baseURL, context),
        createNewSearchVersion: SearchStore.createNewSearchVersion.getProxy(baseURL, context),
        createQuickSearch: SearchStore.createQuickSearch.getProxy(baseURL, context),
        convertQuickSearch: SearchStore.convertQuickSearch.getProxy(baseURL, context),
        createHitComment: SearchStore.createHitComment.getProxy(baseURL, context),
        getHitComments: SearchStore.getHitComments.getProxy(baseURL, context),
        editHitComment: SearchStore.editHitComment.getProxy(baseURL, context),
        deleteHitComment: SearchStore.deleteHitComment.getProxy(baseURL, context)
    };
}

export type SearchStoreKeyAuthProxy = Awaited<ReturnType<typeof getSearchStoreKeyAuthProxy>>;

//justification: this return type is necessarily defined by getProxy - to explicitly define it in the function signature
//would be unwieldy
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getSearchStoreKeyAuthProxy(context: ConnectionContext & BasicCurrentUserContext) {
    const baseURL: URL = getFunctionAppUrl("SearchStoreApi");
    const hostKey = await context.getSharedFunctionHostKey("SearchStoreApi");

    return {
        saveSearch: SearchStore.keyAuthFunctions.saveSearch.getProxy(baseURL, context, hostKey),
        saveQuickSearch: SearchStore.keyAuthFunctions.saveQuickSearch.getProxy(baseURL, context, hostKey),
        createNewSearchVersion: SearchStore.keyAuthFunctions.createNewSearchVersion.getProxy(baseURL, context, hostKey),
        get: SearchStore.keyAuthFunctions.get.getProxy(baseURL, context, hostKey),
        getLatestSearchVersion: SearchStore.keyAuthFunctions.getLatestSearchVersion.getProxy(baseURL, context, hostKey),
        getQuickSearch: SearchStore.keyAuthFunctions.getQuickSearch.getProxy(baseURL, context, hostKey)
    };
}

export type SearchStoreUserlessKeyAuthProxy = Awaited<ReturnType<typeof getSearchStoreUserlessKeyAuthProxy>>;

//justification: this return type is necessarily defined by getProxy - to explicitly define it in the function signature
//would be unwieldy
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getSearchStoreUserlessKeyAuthProxy(context: UserlessKeyAuthFunctionAppContext) {
    const baseURL: URL = getFunctionAppUrl("SearchStoreApi");
    const hostKey = await context.getSharedFunctionHostKey("SearchStoreApi");

    return {
        getHealth: SearchStore.getHealth.getKeyAuthProxyForMonitoring(baseURL, context.logger, hostKey)
    };
}
