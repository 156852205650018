import {
    ConflictsTenantBlobStorage,
    CurrentUserContext,
    GridConfiguration,
    GridColumnConfiguration,
    EtagMismatch,
    NotFound,
    Result,
    JsonParseError,
    unexpectedError,
    LogContext,
    DefaultHitResultGridColumnConfigurations
} from "aderant-conflicts-models";
import { BlobStorageConnector } from "../../BlobStorageConnector/BlobStorageConnector";
import { ConfigurationService } from "../ConfigurationService";

/**
 * This is a class that retrieves and saves a grid configuration for the specified grid
 * At this stage only caters for the Hit Results grid - but could be expanded
 */
export class GridConfigurationService {
    private static ConfigurationCache: Record<string, GridConfiguration> = {}; //Object where property names are tenancy unique name, and property values are the GridConfiguration of the Hit Reslts grid for the tenancy.
    private static ConfigurationCacheLastUpdated: Record<string, number> = {}; //Object where property names are tenancy unique name, and property values are DateTime the HitResultGridConfigurationCache was last updated for that tenancy.
    private static configService: ConfigurationService<GridColumnConfiguration> = new ConfigurationService(
        {
            ConfigurationCache: GridConfigurationService.ConfigurationCache,
            ConfigurationCacheLastUpdated: GridConfigurationService.ConfigurationCacheLastUpdated
        },
        DefaultHitResultGridColumnConfigurations
    );

    /**
     * Retrieves the hit result grid configuration for the tenancy of the current user.
     * @param context: the Current Log Context
     * @param tenancyName:  The unique tenancy name for the tenant.  This is a separate param because we can call this from a userless key auth function
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param cacheAge Optional - Default value is 60000 (10 minutes). Will fetch configuration from cache if cache age is less than this, else will fetch from blob storage.
     */
    async getHitResultGridConfiguration(context: LogContext, tenancyName: string, blobConnector: BlobStorageConnector, cacheAge?: number): Promise<GridConfiguration> {
        return GridConfigurationService.configService.getConfiguration(
            context,
            tenancyName,
            blobConnector,
            ConflictsTenantBlobStorage.Configuration.hitResultGridDefinition,
            ConflictsTenantBlobStorage.Configuration.containerName,
            cacheAge
        );
    }

    /**
     * Retrieves the grid configuration template for the given blob name from the given container.
     * @param context: the Current Log Context
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param blobName: the name of the blob file that holds the custom configuration you want to retrieve. By providing thisa s a param, rather than hard-coding it, we can use this method for other grids in the future too.
     * @param containerName: the container to retrieve the configurations from. Optional. If not specified the default is the customization-wip container
     */
    async getCustomGridConfiguration(
        context: LogContext,
        tenancyName: string,
        blobConnector: BlobStorageConnector,
        blobName: string = ConflictsTenantBlobStorage.CustomizationWip.hitResultGridDefinition, //setting this as default as we do not yet have other grid configurations
        containerName: string = ConflictsTenantBlobStorage.CustomizationWip.containerName
    ): Promise<Result<GridConfiguration, NotFound | JsonParseError>> {
        return GridConfigurationService.configService.getCustomConfiguration(context, tenancyName, blobConnector, blobName, containerName);
    }

    /**
     * Updates the Hit Results grid configuration
     * @param context: the Current User Context
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param hitResultGridConfiguration a GridConfiguration object containing the Hit Results grid definition to be saved,
     * @param containerName: the name of the container to save to. Optional. If not specified, saves to the customization-wip container.
     */
    async updateHitResultGridConfiguration(
        context: CurrentUserContext,
        blobConnector: BlobStorageConnector,
        hitResultGridConfiguration: GridConfiguration,
        containerName: string = ConflictsTenantBlobStorage.CustomizationWip.containerName
    ): Promise<Result<undefined, EtagMismatch>> {
        const blobName = this.getHitResultGridBlobName(containerName);
        return GridConfigurationService.configService.updateConfiguration(context, blobConnector, hitResultGridConfiguration, blobName, containerName);
    }

    private getHitResultGridBlobName(containerName: string): string {
        let blobName = "";
        if (containerName == ConflictsTenantBlobStorage.CustomizationWip.containerName) {
            blobName = ConflictsTenantBlobStorage.CustomizationWip.hitResultGridDefinition;
        } else if (containerName == ConflictsTenantBlobStorage.Configuration.containerName) {
            blobName = ConflictsTenantBlobStorage.Configuration.hitResultGridDefinition;
        }

        if (!blobName) {
            throw unexpectedError(`Blob name not found for container ${containerName}.`, "GridConfigurationService.getHitResultGridBlobName");
        }

        return blobName;
    }
}
