import { action } from "typesafe-actions";
import { IntegrationStatus, SynonymGroup, SynonymMap, FirmSettings, EditableFieldValue } from "aderant-conflicts-models";
import { AppFetchedFirmSetting } from "App";

export const AdminActionType = {
    FETCH_SYNONYM_MAP: "FETCH_SYNONYM_MAP",
    FETCH_SYNONYM_MAP_SUCCESS: "FETCH_SYNONYM_MAP_SUCCESS",

    DELETE_SYNONYM_GROUP: "DELETE_SYNONYM_GROUP",

    UPDATE_SYNONYM_GROUP: "UPDATE_SYNONYM_GROUP",

    CREATE_SYNONYM_GROUP: "CREATE_SYNONYM_GROUP",

    UPDATE_SYNONYM_MAP: "UPDATE_SYNONYM_MAP",
    UPDATE_SYNONYM_MAP_SUCCESS: "UPDATE_SYNONYM_MAP_SUCCESS",

    FETCH_SYNONYMS_STATUS: "FETCH_SYNONYMS_STATUS",
    FETCH_SYNONYMS_STATUS_SUCCESS: "FETCH_SYNONYMS_STATUS_SUCCESS",

    FETCH_FIRM_SETTINGS: "FETCH_FIRM_SETTINGS",
    FETCH_FIRM_SETTINGS_SUCCESS: "FETCH_FIRM_SETTINGS_SUCCESS",

    UPDATE_SYNONYMS_STATUS: "UPDATE_SYNONYMS_STATUS",
    UPDATE_SYNONYMS_STATUS_SUCCESS: "UPDATE_SYNONYMS_STATUS_SUCCESS",

    TOGGLE_SYNONYM_STATUS: "TOGGLE_SYNONYM_STATUS",

    IS_PERSISTING: "IS_PERSISTING",
    IS_FETCHING: "IS_FETCHING",

    FETCH_API_KEYS: "FETCH_API_KEYS",
    FETCH_API_KEYS_SUCCESS: "FETCH_API_KEYS_SUCCESS",

    GENERATE_API_KEY: "GENERATE_API_KEY",
    GENERATE_API_KEY_SUCCESS: "GENERATE_API_KEY_SUCCESS",

    REVOKE_API_KEY: "REVOKE_API_KEY",
    REVOKE_API_KEY_SUCCESS: "REVOKE_API_KEY_SUCCESS",

    REGENERATE_API_KEY: "REGENERATE_API_KEY",
    REGENERATE_API_KEY_SUCCESS: "REGENERATE_API_KEY_SUCCESS",

    CLEAR_GENERATED_API_KEY: "CLEAR_GENERATED_API_KEY",
    SAVE_RLS_COLUMN_VISIBILITY: "SAVE_RLS_COLUMN_VISIBILITY",
    SAVE_RLS_COLUMN_VISIBILITY_SUCCESS: "SAVE_RLS_COLUMN_VISIBILITY_SUCCESS",

    FETCH_RLS_COLUMN_VISIBILITY: "FETCH_RLS_COLUMN_VISIBILITY",
    FETCH_RLS_COLUMN_VISIBILITY_SUCCESS: "FETCH_RLS_COLUMN_VISIBILITY_SUCCESS",

    FETCH_FIRM_SETTINGS_PAGE_DATA: "FETCHFIRMSETTINGSPAGEDATA",
    FETCH_FIRM_SETTINGS_PAGE_DATA_SUCCESS: "FETCHFIRMSETTINGSPAGEDATASUCCESS",

    SAVE_FIRM_SETTINGS_PAGE_DATA: "SAVEFIRMSETTINGSPAGEDATA",
    SAVE_FIRM_SETTINGS_PAGE_DATA_SUCCESS: "SAVEFIRMSETTINGSPAGEDATASUCCESS",

    SET_IS_PAGE_EDITED: "SETISPAGEEDITED",

    FETCH_INGESTION_STATUS: "FETCH_INGESTION_STATUS",
    FETCH_INGESTION_STATUS_SUCCESS: "FETCH_INGESTION_STATUS_SUCCESS",

    ADMIN_FETCH_FAILURE: "ADMIN_FETCH_FAILURE",

    ADMIN_FAILURE: "ADMIN_FAILURE"
} as const;

const adminFetchFailure = <T extends FirmSettings.PageDefinition>(payload: { pageName: T["name"]; dataName: T["dataToLoad"][number]; error: string }) =>
    action(AdminActionType.ADMIN_FETCH_FAILURE, payload);

export const adminActions = {
    fetchSynonymMap: (): { type: "FETCH_SYNONYM_MAP" } => action(AdminActionType.FETCH_SYNONYM_MAP),
    fetchSynonymMapSuccess: (synonymMap: SynonymMap): { type: "FETCH_SYNONYM_MAP_SUCCESS"; payload: SynonymMap } => action(AdminActionType.FETCH_SYNONYM_MAP_SUCCESS, synonymMap),

    deleteSynonymGroup: (synonymGroupIndex: number): { type: "DELETE_SYNONYM_GROUP"; payload: number } => action(AdminActionType.DELETE_SYNONYM_GROUP, synonymGroupIndex),

    updateSynonymGroup: (payload: {
        updatedSynonymGroup: SynonymGroup;
        synonymGroupIndex: number;
    }): { type: "UPDATE_SYNONYM_GROUP"; payload: { updatedSynonymGroup: SynonymGroup; synonymGroupIndex: number } } => action(AdminActionType.UPDATE_SYNONYM_GROUP, payload),

    createSynonymGroup: (payload: { newSynonymGroup: SynonymGroup }): { type: "CREATE_SYNONYM_GROUP"; payload: { newSynonymGroup: SynonymGroup } } =>
        action(AdminActionType.CREATE_SYNONYM_GROUP, payload),

    updateSynonymMap: (payload: { updatedSynonymMap: SynonymMap }): { type: "UPDATE_SYNONYM_MAP"; payload: { updatedSynonymMap: SynonymMap } } => action(AdminActionType.UPDATE_SYNONYM_MAP, payload),
    updateSynonymMapSuccess: (updatedSynonymMap: SynonymMap): { type: "UPDATE_SYNONYM_MAP_SUCCESS"; payload: SynonymMap } => action(AdminActionType.UPDATE_SYNONYM_MAP_SUCCESS, updatedSynonymMap),

    fetchSynonymsStatus: (): { type: "FETCH_SYNONYMS_STATUS" } => action(AdminActionType.FETCH_SYNONYMS_STATUS),
    fetchSynonymsStatusSuccess: (synonymMapToggle: "ON" | "OFF"): { type: "FETCH_SYNONYMS_STATUS_SUCCESS"; payload: "ON" | "OFF" } =>
        action(AdminActionType.FETCH_SYNONYMS_STATUS_SUCCESS, synonymMapToggle),

    updateSynonymsStatus: (payload: { synonymMapToggle: "ON" | "OFF" }): { type: "UPDATE_SYNONYMS_STATUS"; payload: { synonymMapToggle: "ON" | "OFF" } } =>
        action(AdminActionType.UPDATE_SYNONYMS_STATUS, payload),
    updateSynonymsStatusSuccess: (synonymMapToggle: "ON" | "OFF"): { type: "UPDATE_SYNONYMS_STATUS_SUCCESS"; payload: "ON" | "OFF" } =>
        action(AdminActionType.UPDATE_SYNONYMS_STATUS_SUCCESS, synonymMapToggle),

    toggleSynonymStatus: (payload: { synonymMapToggle: boolean }): { type: "TOGGLE_SYNONYM_STATUS"; payload: { synonymMapToggle: boolean } } => action(AdminActionType.TOGGLE_SYNONYM_STATUS, payload),

    fetchApiKeys: (): { type: "FETCH_API_KEYS" } => action(AdminActionType.FETCH_API_KEYS),
    fetchApiKeysSucess: (payload: { keys: { uniqueKeyName: string }[] }): { type: "FETCH_API_KEYS_SUCCESS"; payload: { keys: { uniqueKeyName: string }[] } } =>
        action(AdminActionType.FETCH_API_KEYS_SUCCESS, payload),

    revokeApiKey: (payload: { uniqueKeyName: string }): { type: "REVOKE_API_KEY"; payload: { uniqueKeyName: string } } => action(AdminActionType.REVOKE_API_KEY, payload),
    revokeApiKeySuccess: (uniqueKeyName: string) => action(AdminActionType.REVOKE_API_KEY_SUCCESS, uniqueKeyName),

    fetchFirmSettings: (settingsFieldPaths: readonly AppFetchedFirmSetting[] | AppFetchedFirmSetting[]) => action(AdminActionType.FETCH_FIRM_SETTINGS, settingsFieldPaths),
    fetchFirmSettingsSuccess: (firmSettings: Record<AppFetchedFirmSetting, { value: EditableFieldValue }>) => action(AdminActionType.FETCH_FIRM_SETTINGS_SUCCESS, { firmSettings: firmSettings }),

    generateApiKey: (payload: { uniqueKeyName: string }): { type: "GENERATE_API_KEY"; payload: { uniqueKeyName: string } } => action(AdminActionType.GENERATE_API_KEY, payload),
    generateApiKeySuccess: (payload: { uniqueKeyName: string; generatedApiKey: string }): { type: "GENERATE_API_KEY_SUCCESS"; payload: { uniqueKeyName: string; generatedApiKey: string } } =>
        action(AdminActionType.GENERATE_API_KEY_SUCCESS, payload),

    regenerateApiKey: (payload: { uniqueKeyName: string }): { type: "REGENERATE_API_KEY"; payload: { uniqueKeyName: string } } => action(AdminActionType.REGENERATE_API_KEY, payload),
    regenerateApiKeySuccess: (payload: { uniqueKeyName: string; generatedApiKey: string }): { type: "REGENERATE_API_KEY_SUCCESS"; payload: { uniqueKeyName: string; generatedApiKey: string } } =>
        action(AdminActionType.REGENERATE_API_KEY_SUCCESS, payload),

    clearGeneratedApiKey: () => action(AdminActionType.CLEAR_GENERATED_API_KEY),
    saveRlsColumnVisibility: (payload: { rlsVisibleColumns: string[] }): { type: "SAVE_RLS_COLUMN_VISIBILITY"; payload: { rlsVisibleColumns: string[] } } =>
        action(AdminActionType.SAVE_RLS_COLUMN_VISIBILITY, payload),
    saveRlsColumnVisibilitySuccess: (payload: { rlsVisibleColumns: string[] }): { type: "SAVE_RLS_COLUMN_VISIBILITY_SUCCESS"; payload: { rlsVisibleColumns: string[] } } =>
        action(AdminActionType.SAVE_RLS_COLUMN_VISIBILITY_SUCCESS, payload),
    fetchRlsColumnVisibility: (): { type: "FETCH_RLS_COLUMN_VISIBILITY" } => action(AdminActionType.FETCH_RLS_COLUMN_VISIBILITY),
    fetchRlsColumnVisibilitySuccess: (payload: { rlsVisibleColumns: string[] }): { type: "FETCH_RLS_COLUMN_VISIBILITY_SUCCESS"; payload: { rlsVisibleColumns: string[] } } =>
        action(AdminActionType.FETCH_RLS_COLUMN_VISIBILITY_SUCCESS, payload),
    fetchFirmSettingsPageData: (payload: { pageName: FirmSettings.PageDefinitionName }) => action(AdminActionType.FETCH_FIRM_SETTINGS_PAGE_DATA, payload),
    fetchFirmSettingsPageDataSuccess: (payload: { pageName: FirmSettings.PageDefinitionName; pageData: Record<string, Record<string, any>> }) =>
        action(AdminActionType.FETCH_FIRM_SETTINGS_PAGE_DATA_SUCCESS, payload),
    saveFirmSettingsPageData: (payload: { pageName: string; pageData: Record<string, any> }) => action(AdminActionType.SAVE_FIRM_SETTINGS_PAGE_DATA, payload),
    saveFirmSettingsPageDataSuccess: (payload: { pageName: string; pageData: Record<string, any> }) => action(AdminActionType.SAVE_FIRM_SETTINGS_PAGE_DATA_SUCCESS, payload),
    setIsPageEdited: (payload: { pageName: string; isPageEdited: boolean }) => action(AdminActionType.SET_IS_PAGE_EDITED, payload),
    /**
     * ####################################################################################################
     *
     * IMPORTANT: If the action is fetching data for a firm settings page, use adminFetchFailure instead!!!
     *
     * ####################################################################################################
     */
    adminFailure: (error: string): { type: "ADMIN_FAILURE"; payload: string } => action(AdminActionType.ADMIN_FAILURE, error),
    /**
     * Use this as the failure action when fetching data for a firm settings page
     * @param dataName string used to identify what data is being loaded (e.g. "SYNONYM_MAP")
     */
    adminFetchFailure: adminFetchFailure,
    isPersisting: (payload: { pageName: FirmSettings.PageDefinitionName; isPersisting: boolean }) => action(AdminActionType.IS_PERSISTING, payload),
    isFetching: (payload: { pageName: FirmSettings.PageDefinitionName; dataName: FirmSettings.PageDefinition["dataToLoad"][number]; isFetching: boolean }) =>
        action(AdminActionType.IS_FETCHING, payload),
    fetchIngestionStatus: (): { type: "FETCH_INGESTION_STATUS" } => action(AdminActionType.FETCH_INGESTION_STATUS),
    fetchIngestionStatusSuccess: (ingestionStatus: IntegrationStatus[]): { type: "FETCH_INGESTION_STATUS_SUCCESS"; payload: IntegrationStatus[] } =>
        action(AdminActionType.FETCH_INGESTION_STATUS_SUCCESS, ingestionStatus)
};
