import { ConfigurationTemplates } from "../ConfigurationTemplates";

export const DisplayFormatTypes = ["String", "Number", "Boolean", "Date", "DateTime", "Currency", "Highlight", "User", "UserWithAvatar"] as const;
export type DisplayFormat = typeof DisplayFormatTypes[number];

export interface GridColumnConfiguration {
    columnName: string;
    propertyName: string;
    isVisible?: boolean;
    isVisibleOnRedactedRows?: boolean;
    align?: "left" | "right" | "center";
    displayFormat?: DisplayFormat;
}

export type GridConfiguration = ConfigurationTemplates<GridColumnConfiguration>;
