import { StringField } from "aderant-conflicts-models";
import React from "react";
import { styles } from "./commonStyles";
interface StringInformationProps {
    field: StringField;
}
export const StringInformation = (props: StringInformationProps) => {
    const {
        field: { fieldLabel, value }
    } = props;
    return (
        <>
            <span style={styles.labelStyle}>{fieldLabel}:</span>
            <span data-testid={`${fieldLabel}`} style={styles.valueStyle}>
                {value}
            </span>
        </>
    );
};
