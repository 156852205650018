import { EntityFlyoutConfigurationTemplates, ok, Result, Forbidden, ConflictsAction } from "aderant-conflicts-models";
import { FunctionAppContext } from "../../ConflictsContext";
import { EntityConfigurationService } from "./EntityConfigurationService";
import { HeaderBuilder } from "../../Http/HeaderBuilder";
import { OkResponseWithHeaders, okResponseWithHeaders } from "../../Http/HttpResponse";
import { BlobStorageConnector } from "../../BlobStorageConnector/BlobStorageConnector";
import { EntityConfiguration } from "./EntityConfiguration";
import { hasPermission } from "../hasPermission";

/**
 * This function retrieves the flyout configurations for all entity types.
 */
export async function getEntityFlyoutConfigurations(
    context: FunctionAppContext,
    input: undefined,
    entityConfigurationService: EntityConfigurationService
): Promise<Forbidden | OkResponseWithHeaders<EntityFlyoutConfigurationTemplates>> {
    context.logger.info("Retrieving entity flyout configurations.");

    const permissionValidation = await hasPermission(context, ConflictsAction.ViewAndUpdateEntityFlyoutConfiguration, `view the ${EntityConfiguration.FlyoutDefinition}`);
    if (!ok(permissionValidation)) {
        return permissionValidation;
    }

    const blobConnector = await BlobStorageConnector.open((await context.getBlobStorageSecrets()).connectionString);

    //Fetch flyout configuration template for all entity types
    const entityTemplates: Result<EntityFlyoutConfigurationTemplates> = await entityConfigurationService.getEntityFlyoutConfigurationTemplates(
        context,
        context.currentUser.tenancy.uniqueName,
        blobConnector
    );
    if (!ok(entityTemplates)) {
        return entityTemplates;
    }

    const response = okResponseWithHeaders(entityTemplates, new HeaderBuilder().addCacheHeader(600).build());
    return response;
}
