import { AllLookups, LookupType, RawLookup, validateLookupList } from "aderant-conflicts-models";

export interface LookupConnector {
    getLookup(lookupType: LookupType): Promise<RawLookup[]>;
}

export async function getAllLookups(lookupConnector: LookupConnector): Promise<AllLookups> {
    const affiliationLookups = await lookupConnector.getLookup("Affiliation");
    const validAffiliations = validateLookupList(affiliationLookups);

    const partyStatusLookups = await lookupConnector.getLookup("PartyStatus");
    const validPartyStatuses = validateLookupList(partyStatusLookups);

    const lookups: AllLookups = {
        affiliationList: validAffiliations,
        partyStatusList: validPartyStatuses
    };

    return lookups;
}
