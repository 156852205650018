import { Entity } from "aderant-conflicts-models";
import React from "react";
import { Tile } from ".";
import { messages } from "pages/SearchEditPage/SearchUtils";
import { conflictsPalette } from "styles/conflictsPalette";

export const SecondaryInformationTiles = (props: { entityDetails?: Entity; toggleRequestTermsDialog: () => void }): JSX.Element | null => {
    const { entityDetails, toggleRequestTermsDialog } = props;
    const result: JSX.Element[] = [];
    if (!entityDetails) {
        return null;
    }

    const requestTermsDialogLink = (): JSX.Element => {
        return (
            <span
                onClick={toggleRequestTermsDialog}
                style={{ color: conflictsPalette.text.hyperlink, textDecoration: "underline", cursor: "pointer" }}
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                        toggleRequestTermsDialog();
                    }
                }}
            >
                {messages.seeAll}
            </span>
        );
    };

    //Keep rendering tiles as long as there's a parent Entity
    const renderEntities = (entity: Entity) => {
        const fields = [...entity.fields];
        //Add the request terms link to the flyout information tile if there are some.
        if (entity.searchRequestTerms) {
            fields.push({
                fieldLabel: messages.requestTerms,
                displayFormat: "Custom",
                value: requestTermsDialogLink()
            });
        }
        result.push(<Tile key={entity.name} title={`${entity.name} Information`} fields={fields} />);

        if (entity.parent) {
            renderEntities(entity.parent);
        }
    };

    renderEntities(entityDetails);
    return <>{result}</>;
};
