import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import { SaveSearchActionTypes, searchActions } from "../../state/actions";
import { InputText, Checkbox } from "@aderant/aderant-react-components";
import { useDispatch } from "react-redux";
import { getNumberDisplayValue, isSearchedStatus, makeEditable, SearchMessages, SearchStatuses, SearchVersion, SearchVersionNew, User } from "aderant-conflicts-models";
import { messages } from "pages/SearchEditPage/SearchUtils";
import { Logger } from "aderant-web-fw-core";
import { UserPicklist } from "components/UserPicklist/UserPicklist";
import { usePrevious } from "components/hooks/usePrevious";
import { AssignedToUserPickList } from "components/AssignedToUserPickList/AssignedToUserPickList";
import { ReassignUserModal } from "components/ReassignUserModal/ReassignUserModal";
import { useFirmSetting } from "state/selectors";

export type SearchEditPageDetailsProps = {
    searchVersion?: SearchVersion | SearchVersionNew;
    logger: Logger;
    disableFuzzySearch?: boolean;
    readonly?: boolean;
    isNewVersion?: boolean;
    userCanReassign: boolean;
    suppressUnsavedDataHandling: React.MutableRefObject<boolean>;
};

export default function SearchEditPageDetails(props: SearchEditPageDetailsProps): JSX.Element {
    const { searchVersion, isNewVersion = false, suppressUnsavedDataHandling } = props;
    const [searchName, setSearchName] = useState<string>(searchVersion?.name ?? "");
    const [requestedByUserId, setRequestedByUserId] = useState<string>(searchVersion?.requestedByUserId ?? "");
    const [assignedToUserId, setAssignedToUserId] = useState<string | null | undefined>(searchVersion?.assignedToUserId);
    const [approverUserId, setApproverUserId] = useState<string | undefined>(searchVersion?.approverUserId);
    const [updatedAssignedToUserId, setUpdatedAssignedToUserId] = useState<string | null | undefined>(searchVersion?.assignedToUserId);
    const [isReassignDialogOpen, setIsReassignDialogOpen] = useState<boolean>(false);
    const [reassignMessage, setReassignMessage] = useState<string>("");
    const [searchDescription, setSearchDescription] = useState<string>(searchVersion?.description ?? "");
    const [applyFuzzySearch, setApplyFuzzySearch] = useState<boolean>(searchVersion?.applyFuzzySearch ?? false);
    const dispatch = useDispatch();

    const isSearched = isNewVersion ? false : searchVersion?.status && isSearchedStatus(searchVersion?.status);

    const displayApproverField = useFirmSetting("firm-options/general/isApproverFieldEnabled", false);

    const updateDetails = (updatedValue: string, setState: React.Dispatch<React.SetStateAction<string>>) => {
        if (searchVersion) {
            setState(updatedValue);
            //dispatch(searchActions.updateDetails({ ...search, name: name })); //TODO (1021)
        }
    };

    //using a Ref here as the cleanup function underneath this needs to only run once on component unload
    //and this was the only way I could think of to make sure it always uses an up to date version of the details fields.
    const persistChanges = useRef<() => void>(() => undefined);

    const searchRequestNumber = searchVersion ? getNumberDisplayValue(searchVersion, isNewVersion) : "";
    const previousSearchVersionId = usePrevious(searchVersion?.id);

    useEffect(() => {
        persistChanges.current = () => {
            // only call dispatch if the searchVersion Id is the same as the previous searchVersion id, ie. don't call on initial load of the page with a new search.
            if (searchVersion && previousSearchVersionId === searchVersion.id && searchVersion != undefined) {
                const editableSearch = makeEditable(searchVersion);
                dispatch(
                    searchActions.saveSearch({
                        saveSearchActionInput: {
                            actionType: SaveSearchActionTypes.UPDATE_DETAILS,
                            payload: {
                                searchVersion: {
                                    ...editableSearch,
                                    name: searchName,
                                    requestedByUserId: requestedByUserId,
                                    assignedToUserId: assignedToUserId,
                                    description: searchDescription,
                                    applyFuzzySearch: applyFuzzySearch,
                                    approverUserId: approverUserId
                                },
                                reassignMessage: reassignMessage
                            }
                        },
                        doNotPersist: isNewVersion
                    })
                );
                setReassignMessage("");
            }
        };
    }, [searchVersion, searchName, requestedByUserId, searchDescription, applyFuzzySearch, assignedToUserId, approverUserId, previousSearchVersionId]);

    useEffect(() => {
        //cleanup function, will only run once on component unmount
        //this is to ensure that changes get persisted even when user leaves page before the input timer expires
        return () => {
            if (!suppressUnsavedDataHandling.current) {
                persistChanges.current();
                dispatch(searchActions.isCurrentSearchPendingChanges(false));
            }
        };
    }, []);

    useEffect(() => {
        //cleanup function in case the copy dialog was shown and not cleared previously. Will only run once on component mount
        dispatch(searchActions.closeCopyFailureDialog());
    }, []);

    //TODO (1021): This useEffect is a temporary solution until a timer is applied on the onChange of the AutoComplete component
    useEffect(() => {
        const timer = setTimeout(() => {
            persistChanges.current();
            dispatch(searchActions.isCurrentSearchPendingChanges(false));
        }, 2000);
        return () => {
            clearTimeout(timer);
        };
    }, [searchName, requestedByUserId, searchDescription, applyFuzzySearch, assignedToUserId, approverUserId]);

    //search usually hasn't actually been loaded on first render, so this will update the details once it's there, and again if the searchId changes
    //having search as the dependency instead of the id doesn't work as every time the value is updated in the store from a save completing or rest params it's considered a new object
    useEffect(() => {
        setSearchName(searchVersion?.name ?? "");
        setRequestedByUserId(searchVersion?.requestedByUserId ?? "");
        setSearchDescription(searchVersion?.description ?? "");
        setApplyFuzzySearch(searchVersion?.applyFuzzySearch ?? false);
        setAssignedToUserId(searchVersion?.assignedToUserId);
        setUpdatedAssignedToUserId(searchVersion?.assignedToUserId);
        setApproverUserId(searchVersion?.approverUserId);
    }, [searchVersion?.id]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (e: ChangeEvent, inputValue: any, reason: string | undefined, setState: React.Dispatch<React.SetStateAction<string>>) => {
        if (inputValue === null) {
            inputValue = "";
        }
        if (reason === "clear") {
            updateDetails("", setState);
            dispatch(searchActions.isCurrentSearchPendingChanges(true));
        } else if (reason === "blur") {
            updateDetails(inputValue && inputValue.trim(), setState);
        } else if (reason === "onChange") {
            updateDetails(inputValue, setState);
            dispatch(searchActions.isCurrentSearchPendingChanges(true));
        }
    };

    const fieldStyle = { flex: "45.5%", width: "100%", marginLeft: "20px", marginRight: "20px" };

    return (
        <div style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "30px", alignSelf: "center" }}>
            <ReassignUserModal
                open={isReassignDialogOpen}
                onCancel={() => {
                    setUpdatedAssignedToUserId(assignedToUserId);
                    setIsReassignDialogOpen(false);
                }}
                onAccept={(message: string, assignedToUserId?: string | null) => {
                    setUpdatedAssignedToUserId(assignedToUserId);
                    setAssignedToUserId(assignedToUserId);
                    setReassignMessage(message);
                    dispatch(searchActions.isCurrentSearchPendingChanges(true));
                    setIsReassignDialogOpen(false);
                }}
                assignedToUserId={updatedAssignedToUserId}
            />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <InputText
                    label={messages.name}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(e: ChangeEvent, inputValue: any, reason: string | undefined) => onChange(e, inputValue, reason, setSearchName)}
                    disabled={isSearched || props.readonly}
                    value={searchName || ""}
                    required
                    requiredMessage={messages.enterSearchName}
                    autofocus={!searchVersion?.name}
                    style={fieldStyle}
                    id="details-page-search-name"
                />
                <UserPicklist
                    label={messages.requestedBy}
                    disabled={props.readonly}
                    onUserChanged={(user: User | undefined) => {
                        user ? setRequestedByUserId(user.id) : setRequestedByUserId("");
                        dispatch(searchActions.isCurrentSearchPendingChanges(true));
                    }}
                    required
                    requiredMessage={messages.enterRequestedByUser}
                    selectedUserId={requestedByUserId}
                    style={fieldStyle}
                />
                <InputText label={messages.searchRequestNumber} value={searchRequestNumber} style={fieldStyle} disabled={true} />
                {displayApproverField && (
                    <UserPicklist
                        label={SearchMessages.APPROVER.getMessage()}
                        disabled={props.readonly}
                        onUserChanged={(user: User | undefined) => {
                            setApproverUserId(user?.id);
                            dispatch(searchActions.isCurrentSearchPendingChanges(true));
                        }}
                        selectedUserId={approverUserId}
                        style={fieldStyle}
                    />
                )}
                <AssignedToUserPickList
                    disabled={!props.userCanReassign || isNewVersion}
                    label={SearchMessages.ASSIGNED_TO.getMessage()}
                    style={{ ...fieldStyle, display: !searchVersion || searchVersion?.status === SearchStatuses.Draft || isNewVersion ? "none" : "inherit" }}
                    onUserChanged={(user?: User | null) => {
                        user ? setUpdatedAssignedToUserId(user.id) : setUpdatedAssignedToUserId(undefined);
                        setIsReassignDialogOpen(true);
                    }}
                    selectedUserId={assignedToUserId}
                />
                <div style={fieldStyle}></div> {/* empty div for alignment */}
            </div>
            <div>
                <InputText
                    multiLine={true}
                    label={messages.description}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(e: ChangeEvent, inputValue: any, reason: string | undefined) => onChange(e, inputValue, reason, setSearchDescription)}
                    labelProps={{ position: "top" }}
                    value={searchDescription || ""}
                    disabled={props.readonly}
                    maxLines={10}
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                />
            </div>
            <div>
                {!props.disableFuzzySearch && (
                    <Checkbox
                        id="apply-fuzzy-checkbox"
                        label={messages.fuzzySearch}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setApplyFuzzySearch(e.currentTarget.checked);
                            dispatch(searchActions.isCurrentSearchPendingChanges(true));
                        }}
                        checked={applyFuzzySearch}
                        disabled={props.readonly}
                        style={{ marginLeft: "23px" }}
                    />
                )}
            </div>
        </div>
    );
}
