//this is split into a separate file to make it clear which environment state is *only* available on backend function apps.
export function getKeyVaultName(): string {
    return tryGetEnvVariable("KEYVAULT_NAME");
}

export function getFunctionKeyKeyVaultName(): string {
    return tryGetEnvVariable("CONFLICTS_FUNCTIONKEY_KEYVAULTNAME");
}

function tryGetEnvVariable(name: string): string {
    const value = process.env[name];
    if (!value) {
        throw new Error(`${name} environment variable did not exist.`);
    }
    return value;
}
