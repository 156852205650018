import { Message } from "../Message";

export const Messages = {
    VLD_NULL_UNDEF_ID: new Message<[value: string]>("VLD_NULL_UNDEF_ID", "{0} provided is null or undefined"),
    VLD_NULL_ARG_BODY: new Message("VLD_NULL_ARG_BODY", "Request body is null or undefined"),

    VLD_INVALID_VALUE: new Message<[value: string]>("VLD_INVALID_VALUE", "{0} provided is invalid"),
    VLD_NULL_VALUE: new Message<[value: string]>("VLD_NULL_VALUE", "{0} cannot be null"),
    VLD_EMPTY_STRING_VALUE: new Message<[value: string]>("VLD_EMPTY_STRING_VALUE", "{0} cannot be an empty string"),
    VLD_INVALID_GUID_VALUE: new Message<[value: string]>("VLD_INVALID_GUID_VALUE", "{0} is not a valid GUID"),
    VLD_EMPTY_GUID_VALUE: new Message<[value: string]>("VLD_EMPTY_GUID_VALUE", "{0} cannot be an empty GUID"),
    VLD_ASSIGNED_TO_USER: new Message("VLD_ASSIGNED_TO_USER", "The current user is not assigned to the search"),
    VLD_INVALID_STATUS_CHANGE: new Message<[oldStatus: string, newStatus: string]>("VLD_INVALID_STATUS_CHANGE", "The requested status change is not allowed. (Current Status: {0}, New Status: {1})"),
    VLD_INVALID_SEARCH_VERSION: new Message("VLD_SEARCH_VERSION", "Invalid request on a previous search version. A later version of the provided search was found."),
    VLD_USER_CANNOT_VIEW_SEARCH: new Message("VLD_USER_CANNOT_VIEW_SEARCH", "Invalid get request on search. The user does not have permission to view the requested search information."),
    VLD_INVALID_REASSIGN: new Message("VLD_INVALID_REASSIGN", "The current user may not reassign this search."),
    VLD_INVALID_STATUS: new Message<[status: string]>("VLD_INVALID_STATUS", "Invalid request on search, search cannot be in {0} status."),
    VLD_INVALID_VERSION_NUMBER: new Message("VLD_INVALID_VERSION_NUMBER", "Invalid version number provided, correct request format: /api/Searches/{searchId}/Versions?version={versionNumber}"),
    VLD_INVALID_SEARCH_TYPE: new Message<[searchType: string]>("VLD_INVALID_SEARCH_TYPE", "Invalid request on search. Invalid search type: {0}"),
    VLD_INVALID_SEARCH_QUERY: new Message<[searchQuery: string]>("VLD_INVALID_SEARCH_QUERY", `Search failed because of incorrect syntax.\nFix the search term "{0}" and search again.`),
    VLD_INVALID_QUICK_SEARCH_QUERY: new Message<[searchQuery: string]>("VLD_INVALID_SEARCH_QUERY", `Search failed because of incorrect syntax.\nFix the term "{0}" and search again.`)
};
