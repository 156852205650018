import { ConflictsAction, EntityFlyoutConfigurationTemplate, Forbidden, NotFound, ok, Result } from "aderant-conflicts-models";
import { FunctionAppContext } from "../../ConflictsContext";
import { EntityConfigurationService } from "./EntityConfigurationService";
import { HeaderBuilder } from "../../Http/HeaderBuilder";
import { OkResponseWithHeaders, okResponseWithHeaders } from "../../Http/HttpResponse";
import { BlobStorageConnector } from "../../BlobStorageConnector/BlobStorageConnector";
import { validateInput } from "./inputValidator";
import { EntityConfiguration } from "./EntityConfiguration";
import { hasPermission } from "../hasPermission";

/**
 * This function retrieves the flyout configuration for a particular entity type.
 */
export async function getEntityFlyoutConfiguration(
    context: FunctionAppContext,
    input: { entityType: string },
    entityConfigurationService: EntityConfigurationService
): Promise<NotFound | Forbidden | OkResponseWithHeaders<EntityFlyoutConfigurationTemplate>> {
    context.logger.info("Validating get entity flyout configuration request");

    const permissionValidation = await hasPermission(context, ConflictsAction.ViewAndUpdateEntityFlyoutConfiguration, `view the ${EntityConfiguration.FlyoutDefinition}`);
    if (!ok(permissionValidation)) {
        return permissionValidation;
    }

    const inputValidation = validateInput(input);
    if (!ok(inputValidation)) {
        return inputValidation;
    }
    const blobConnector = await BlobStorageConnector.open((await context.getBlobStorageSecrets()).connectionString);

    context.logger.info(`Retrieving entity flyout configuration for ${input.entityType}.`);
    //Fetch the flyout configuration template for the specified entity type
    const entityTemplate: Result<EntityFlyoutConfigurationTemplate, NotFound> = await entityConfigurationService.getEntityFlyoutConfigurationTemplate(
        context,
        context.currentUser.tenancy.uniqueName,
        blobConnector,
        input.entityType
    );
    if (!ok(entityTemplate)) {
        return entityTemplate;
    }

    const response = okResponseWithHeaders(entityTemplate, new HeaderBuilder().addCacheHeader(600).build());
    return response;
}
