import { BlobClientOptions, BlobStore } from "@aderant/azure-storage";

export function getRlsBlobStoreMock(
    data?: Record<string, { allowedEntityIds: string[]; deniedEntityIds: string[] }>,
    mockClientOptions?: BlobClientOptions
): Pick<BlobStore, "getAccountName" | "getContainerName" | "getBlobContent"> {
    const blobCientOptions = mockClientOptions ?? getMockClientOptions();
    const mock = new BlobStore(blobCientOptions);

    mock.getAccountName = jest.fn(() => {
        return blobCientOptions.accountName;
    });
    mock.getContainerName = jest.fn(() => {
        return blobCientOptions.containerName;
    });
    mock.getBlobContent = jest.fn((path: string) => {
        //blob file name has email encoded, but data does not, so we need to decode it
        //blob file name has .json on the end, but data does not, so drop that
        const email = decodeURIComponent(path).replace(".json", "");
        if (data && data[email]) {
            return Promise.resolve({ content: JSON.stringify({ allowedUser: email, allowedEntityIds: data[email].allowedEntityIds, deniedEntityIds: data[email].deniedEntityIds }) });
        } else {
            throw { statusCode: 404 };
        }
    });
    return mock;
}

function getMockClientOptions(): BlobClientOptions {
    return {
        accountName: "myaccount",
        containerName: "entity-access-store",
        useIdentity: true
    };
}
