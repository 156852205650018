import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function PageContent(props: React.ComponentPropsWithRef<any>): JSX.Element {
    return (
        <div className="page-content" style={props?.style}>
            {props.children}
        </div>
    );
}
