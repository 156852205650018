import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@aderant/aderant-react-components";

export default function SettingsMenu(): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const history = useHistory();

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openIndexMaintenance = () => {
        history.push("/indexmaintenance");
        handleClose();
    };

    const divStyle = {
        display: "contents"
    };

    return (
        <div style={divStyle}>
            <Button iconButton onClick={handleMenuClick} startIcon={<SettingsIcon />} aria-label="Settings menu" />

            <Menu id="settings-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={openIndexMaintenance}>Index Maintenance</MenuItem>
            </Menu>
        </div>
    );
}
