import { AccountInfo, AuthenticationResult, BrowserAuthError, BrowserAuthOptions, InteractionRequiredAuthError, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { EnvironmentService, getAllConflictsRoleScopes } from "aderant-conflicts-common";
import { ConsoleLogger } from "aderant-web-fw-applications";
import { assertUnreachable } from "aderant-web-fw-core";

const consoleLogger = new ConsoleLogger();

const auth: BrowserAuthOptions = {
    clientId: EnvironmentService.getAuthSettings().clientId,
    authority: EnvironmentService.getAuthSettings().authority,
    knownAuthorities: [EnvironmentService.getAuthSettings().knownAuthority],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
};

export const msalApp: PublicClientApplication = new PublicClientApplication({
    auth: auth,
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true //seems like this is needed because of some issues with Edge/IE11 specifically, but this link might be a bit outdated - cant find more up to date info: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser
        //(note that this still doesn't store enough sensitive information in the cookie to be a security risk, just some extra metadata that makes Edge/IE11 work properly)
    },
    system: {
        navigateFrameWait: 0,
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        consoleLogger.error(message);
                        break;
                    case LogLevel.Warning:
                        consoleLogger.warn(message);
                        break;
                    case LogLevel.Info:
                        consoleLogger.info(message);
                        break;
                    case LogLevel.Verbose:
                    case LogLevel.Trace:
                        consoleLogger.debug(message);
                        break;
                    default:
                        assertUnreachable(level);
                }
            },
            piiLoggingEnabled: false
        }
    }
});

export async function acquireToken(scopes: Array<string>): Promise<AuthenticationResult> {
    const activeAccount = msalApp.getActiveAccount();
    if (!activeAccount) {
        throw new Error("Attempted to acquire token with no active account, Ensure login has happened (see AuthProvider).");
    }

    const request = {
        scopes: scopes,
        account: activeAccount
    };

    try {
        return await msalApp.acquireTokenSilent(request);
    } catch (error) {
        // acquireTokenSilent should fail inside a hidden iframe and return an InteractionRequiredAuthError
        // in the case of the iframe being blocked, a BrowserAuthError is thrown instead
        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
            if (error instanceof BrowserAuthError) {
                console.error("BrowserAuthError: ", error);
            }
            await msalApp.acquireTokenRedirect(request);
        } else {
            //presumably this doesn't error for other reasons very often?
            console.error("Non-interactive error:", error);
        }
        throw error;
    }
}

export function createLoginRequest(input: { account?: AccountInfo | null; username?: string | undefined }) {
    return {
        scopes: getAllConflictsRoleScopes(),
        loginHint: input.username ?? undefined,
        account: input.account ?? undefined
    };
}
