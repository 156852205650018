import { IntegrationStatus, SynonymGroup, SynonymMap, FirmSettings } from "aderant-conflicts-models";
import { AppFetchedFirmSetting } from "App";
import { RootState } from "MyTypes";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { DataLoadedState, PageEditState } from "state/reducers/adminReducers";
import uuid from "uuid";

export type SynonymGroupWithRowId = SynonymGroup & { rowId: string };

const sortExpertIngestionStatuses = (a: IntegrationStatus, b: IntegrationStatus): number => {
    //The requirement specifies to display the pipelines in this order based on importance
    const orderedEntityNames = ["Client/Client Party", "Matter/Matter Party", "Secured Entity Information", "Employee", "Vendor", "Affiliation", "Party Status"];
    if (!a.entityName) {
        if (!b.entityName) {
            return 0;
        }
        return -1;
    } else if (!b.entityName) {
        return 1;
    }

    if (orderedEntityNames.indexOf(a.entityName) < orderedEntityNames.indexOf(b.entityName)) {
        return -1;
    } else if (orderedEntityNames.indexOf(a.entityName) > orderedEntityNames.indexOf(b.entityName)) {
        return 1;
    } else {
        return 0;
    }
};

export const getExpertIngestionStatuses = (state: RootState): IntegrationStatus[] =>
    state.admin.integration.ingestionStatuses.filter((s) => s.systemName.toLowerCase() === "expert").sort(sortExpertIngestionStatuses);

export const getSynonymMap = (state: RootState): SynonymMap | undefined => state.admin.synonymMap;

export const getApiKeys = (state: RootState): { keys?: { uniqueKeyName: string }[] | undefined; generatedApiKey?: string | undefined } | undefined => state.admin.apiKeys;

export const getSynonymGroupsWithRowIds = createSelector(getSynonymMap, (synonymMap: SynonymMap | undefined): SynonymGroupWithRowId[] =>
    synonymMap ? synonymMap.synonymGroups.map((g: SynonymGroup) => ({ ...g, rowId: uuid() })) : []
);

export const getIsFirmSettingsPagePersisting = (state: RootState, pageName: FirmSettings.PageDefinitionName): boolean => state.admin.isPersisting[pageName];

export const getIsFirmSettingsPageFetching = (state: RootState, pageName: FirmSettings.PageDefinitionName): boolean => state.admin.isFetching[pageName];

export const getIsFirmSettingsPageLoaded = (state: RootState, pageName: FirmSettings.PageDefinitionName): DataLoadedState[] => state.admin.isLoaded[pageName];

export const getIsSynonymsToggledOn = (state: RootState): boolean | undefined => state.admin.isSynonymsToggledOn;

export const getIsFeatureFlagEnabled = (state: RootState, flagPath: FirmSettings.FieldPath): boolean | undefined => {
    const fieldPathsParts = FirmSettings.getFirmSettingsPathParts(flagPath);
    return state.admin.firmSettings[fieldPathsParts.page]?.[fieldPathsParts.section]?.[fieldPathsParts.field];
};

export const getFirmSettingsData = (state: RootState): Record<string, Record<string, Record<string, any>>> => state.admin.firmSettings;

//using a symbol rather than a const string for this to avoid bad ergonomics when the type of the config value is also string.
export const notLoadedYet = Symbol();

/**
 * Consume a firm setting.
 * @param settingPath The path of the setting @type {AppFetchedFirmSetting} derived
 * in App.tsx from array of paths loaded at app startup.
 * @param defaultIfNotLoaded (optional) The value that should be returned if the
 * setting has not yet loaded. @type {AppFetchedFirmSetting}
 * derived in App.tsx from array of paths loaded at app startup.
 *
 * @returns The value of the setting if load is complete, otherwise either the provided
 * default or notLoadedYet if no default provided (type is derived from the page definition).
 */
export function getFirmSetting<Path extends AppFetchedFirmSetting>(state: RootState, settingPath: Path): FirmSettings.FieldType<Path> | typeof notLoadedYet;
export function getFirmSetting<Path extends AppFetchedFirmSetting>(state: RootState, settingPath: Path, defaultIfNotLoaded: FirmSettings.FieldType<Path>): FirmSettings.FieldType<Path>;
export function getFirmSetting<Path extends AppFetchedFirmSetting>(
    state: RootState,
    settingPath: Path,
    defaultIfNotLoaded?: FirmSettings.FieldType<Path>
): FirmSettings.FieldType<Path> | typeof notLoadedYet {
    const [page, section, field] = settingPath.split("/");

    if (!state.admin.firmSettings || !state.admin.firmSettings[page] || !state.admin.firmSettings[page][section] || !(field in state.admin.firmSettings[page][section])) {
        if (defaultIfNotLoaded === undefined) {
            return notLoadedYet;
        } else {
            return defaultIfNotLoaded;
        }
    }

    return state.admin.firmSettings[page][section][field];
}

/**
 * Consume a firm setting.
 * @param settingPath The path of the setting @type {AppFetchedFirmSetting} derived
 * in App.tsx from array of paths loaded at app startup.
 * @param defaultIfNotLoaded (optional) The value that should be returned if the
 * setting has not yet loaded. @type {AppFetchedFirmSetting}
 * derived in App.tsx from array of paths loaded at app startup.
 *
 * @returns The value of the setting if load is complete, otherwise either the provided
 * default or notLoadedYet if no default provided (type is derived from the page definition).
 *
 * UX should behave in an ergonomic way when these config values are not loaded yet.
 * For a lot of cases this is going to be as simple as defaulting to the least permissive
 * value for firm settings that control access to functionality so the functionality
 * becomes available once the client knows it's turned on. The intent of the 'notLoadedYet'
 * return value and the optional default is to enable consuming code to make these
 * decisions in a low friction way.
 */
export function useFirmSetting<Path extends AppFetchedFirmSetting>(settingPath: Path): FirmSettings.FieldType<Path> | typeof notLoadedYet;
export function useFirmSetting<Path extends AppFetchedFirmSetting>(settingPath: Path, defaultIfNotLoaded: FirmSettings.FieldType<Path>): FirmSettings.FieldType<Path>;
export function useFirmSetting<Path extends AppFetchedFirmSetting>(settingPath: Path, defaultIfNotLoaded?: FirmSettings.FieldType<Path>): FirmSettings.FieldType<Path> | typeof notLoadedYet {
    if (defaultIfNotLoaded === undefined) {
        return useSelector((state: RootState) => getFirmSetting(state, settingPath));
    } else {
        return useSelector((state: RootState) => getFirmSetting(state, settingPath, defaultIfNotLoaded));
    }
}
export const isFirmSettingsPageEdited = (editState: PageEditState, pageName: string) => {
    let isEdited = editState[pageName] ?? false;

    if (pageName === FirmSettings.SynonymManagementDefinition.name) {
        isEdited = isEdited || (editState[`${FirmSettings.SynonymManagementDefinition.name}:status-toggle`] ?? false);
    }

    return isEdited;
};

export const getIsFirmSettingsPageEdited = (state: RootState, pageName: FirmSettings.PageDefinitionName): boolean => {
    return isFirmSettingsPageEdited(state.admin.isEdited, pageName);
};

export const getVisibleWhenSecureColumns = (rootstate: RootState): string[] | undefined => {
    return rootstate.admin.rlsColumnVisibilityConfig;
};
