import { Context, HttpRequestHeaders } from "@azure/functions";
import { ConflictsError, forbidden, Forbidden, ok, Result, Unauthorized, unauthorized, isConflictsError, LogContext, HardcodedPermissionsConnector } from "aderant-conflicts-models";
import { AzureFunctionLogger, AppInsightsClient } from "aderant-web-fw-azfunctions";
import { getIdentityToken, GlobalUserService, HttpBody, HttpVerb, PublicKeyAuthFunctionAppContext, SuccessResponse } from "../..";
import { callImplementationAndHandleHttpResult } from "./shared/server/callImplementationAndHandleHttpResult";
import { buildDependencies, Unbuilt } from "./shared/server/FunctionAppDependency";
import { httpResponseFromError } from "./shared/errorHandling";
import { Logger } from "aderant-web-fw-core";
import { KeyTenantInfo, validateConflictsAPIKey } from "../SecretConnectors/FunctionKeySecret/validateConflictsAPIKey";
import { FunctionKeySecretConnector } from "../SecretConnectors/FunctionKeySecret/FunctionKeySecretConnector";
import { getUserManagementProxy } from "../APIs/UserManagement/Surface";
import { getUser } from "../GlobalUserService/getUser";
import { extractSearchLogContext } from "./shared/server/extractSearchLogContext";

type AuthHeaders = { "x-functions-key": string; "x-aderant-functions-key": string; "x-aderant-requestedbyemail": string };

//We don't care about x-functions-key on the receiving end because Azure checks it for us.
//We just want to validate x-aderant-functions-key.
function isApiKeyHeaders(headers: HttpRequestHeaders | undefined): headers is Pick<AuthHeaders, "x-aderant-functions-key"> {
    return !!headers && !!headers["x-aderant-functions-key"] && typeof headers["x-aderant-functions-key"] === "string";
}

function isRequestedByEmailHeaders(headers: HttpRequestHeaders | undefined): headers is Pick<AuthHeaders, "x-aderant-requestedbyemail"> {
    return !!headers && !!headers["x-aderant-requestedbyemail"] && typeof headers["x-aderant-requestedbyemail"] === "string";
}

function validateRequestedByEmail(headers?: HttpRequestHeaders): Result<string, Forbidden> {
    if (!isRequestedByEmailHeaders(headers)) {
        return forbidden("Requested by email address was not provided");
    }
    return headers["x-aderant-requestedbyemail"];
}

async function validateAuthHeaders(logger: Logger, secretConnector: FunctionKeySecretConnector, headers: HttpRequestHeaders | undefined): Promise<Result<KeyTenantInfo, Unauthorized>> {
    if (!isApiKeyHeaders(headers)) {
        logger.error("Missing x-aderant-functions-key header or it is not a string, returning unauthorized.");
        return unauthorized();
    }

    const validatedAPIKey = await validateConflictsAPIKey(logger, secretConnector, headers["x-aderant-functions-key"]);

    if (!ok(validatedAPIKey)) {
        return validatedAPIKey;
    }

    return validatedAPIKey;
}

export class AzurePublicApiKeyAuthFunctionDefinition<In extends HttpBody, Out extends HttpBody, Success extends SuccessResponse<Out>, Err extends ConflictsError, Verb extends HttpVerb> {
    public readonly httpVerb: Verb;
    public readonly successStatusCode: Success["status"];
    public readonly headers?: Record<string, any>;

    constructor(input: { httpVerb: Verb; successStatusCode: Success["status"]; headers?: Record<string, any> }) {
        this.httpVerb = input.httpVerb;
        this.successStatusCode = input.successStatusCode;
        this.headers = input.headers;
    }

    /**
     * Function to wrap a business logic implementation with necessary Azure Functions result handling, instrumentation etc
     * @param azureContext Azure context from your Azure Function entry point
     * @param internalImpl ```(context: ConflictsContext, input: In, ...dependencies: Deps) => Promise<Success | Err>``` - the implementation of your business logic.
     * @returns ```{ impl: (input: In, ...dependencies: Deps) => Promise<void>, validatedInput: KeyAuthFunctionInput<unknown>```
     * The impl function should be called in your Azure Function entry point, handles setting Azure context response, instrumentation etc for you.
     * validatedInput contains the input you should pass in to impl after performing type validation yourself in the httpTrigger.
     */
    public getImplementation<Deps extends Array<unknown>>(
        azureContext: Context,
        appInsightsClient: AppInsightsClient,
        internalImpl: (context: PublicKeyAuthFunctionAppContext, input: In, ...dependencies: Deps) => Promise<Out | Err>,
        secretConnector?: FunctionKeySecretConnector,
        logContextInfo: Record<string, string> = {}
    ): (input: In, ...dependencies: Unbuilt<Deps, PublicKeyAuthFunctionAppContext>) => Promise<void> {
        return async (input: In, ...dependencies: Unbuilt<Deps, PublicKeyAuthFunctionAppContext>) => {
            const logger = new AzureFunctionLogger(azureContext.log, appInsightsClient);
            logger.setLogProperties(logContextInfo); //Set initial properties, tenancyName comes after we parse it

            const headers = azureContext.req?.headers;
            const validatedHeaders = await validateAuthHeaders(logger, secretConnector ?? new FunctionKeySecretConnector(logger), headers);
            if (!ok(validatedHeaders)) {
                azureContext.res = httpResponseFromError(validatedHeaders);
                return;
            }
            logger.setLogProperty("tenancy", validatedHeaders.uniqueTenantName);
            logger.setLogProperties(extractSearchLogContext(input));
            const validatedRequestedByEmail = validateRequestedByEmail(headers);
            if (!ok(validatedRequestedByEmail)) {
                logger.error("Missing x-aderant-requestedbyemail header or it is not a string, returning forbidden.");
                azureContext.res = httpResponseFromError(validatedRequestedByEmail);
                return;
            }

            //TODO: We should cache users for 15 mins or so + store in a map keyed by email for perf (Not possible with current implementation but could boost performance)
            const logContext: LogContext = {
                logger: logger,
                appInsightsClient: appInsightsClient
            };
            const token = await getIdentityToken(logContext);
            if (!ok(token)) {
                throw token;
            }
            logger.info("Instantiating GlobalUserService");
            const userService = new GlobalUserService(logContext, getUserManagementProxy(token, logger));
            const user = await getUser(userService, { id: validatedHeaders.tenantId, uniqueName: validatedHeaders.uniqueTenantName }, validatedRequestedByEmail, "requestedByUserEmail");
            if (!ok(user)) {
                logger.error(user.message);
                azureContext.res = httpResponseFromError(user);
                return;
            }

            const context: PublicKeyAuthFunctionAppContext = new PublicKeyAuthFunctionAppContext(new HardcodedPermissionsConnector(), logger, appInsightsClient, user, azureContext);

            const successStatusCode = this.successStatusCode;
            const implWithStatusCode = async () => {
                const result = await internalImpl(context, input, ...(await buildDependencies(dependencies, context)));
                if (!isConflictsError(result)) {
                    return { status: successStatusCode, body: result, headers: this.headers };
                } else {
                    return result;
                }
            };

            await callImplementationAndHandleHttpResult(context.azureContext, context.logger, implWithStatusCode);
        };
    }
}
