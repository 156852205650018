/*
 * @function
 * Creates an audit document for each audit in lastAudits array on SearchVersion.
 */
//eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

//If any changes are made to the function below, rename the function. Otherwise, the updated implementation will not be persisted in the cosmos db and it will continue to use the previous function implementation.
export function createAudit() {
    const request = getContext().getRequest();
    const collection = getContext().getCollection();
    const collectionLink = collection.getSelfLink();

    const document = request.getBody();

    document.lastAudits.forEach((audit) => {
        const accept = collection.createDocument(collectionLink, audit, function (err) {
            if (err) throw new Error("Error in creating audit:" + err.message);
        });

        if (!accept) throw new Error("Error: Unable to queue create audit");
    });
    return;
}
