import React from "react";

export type HighlighterProps = {
    text: string;
};

interface Plain {
    text: string;
    kind: "plain";
    index: number;
}

interface Highlight {
    highlightText: string;
    kind: "highlight";
    index: number;
}

type Text = Plain | Highlight;

export const Highlighter = ({ text }: HighlighterProps): JSX.Element => {
    const splits: Text[] = [];

    const pairs: string[] = text.split("</em>");
    let index = 0;
    pairs.forEach((pair) => {
        const plainAndHighlightPair = pair.split("<em>");
        if (plainAndHighlightPair[0]) {
            splits.push({
                kind: "plain",
                index: index,
                text: plainAndHighlightPair[0]
            });
            index++;
        }
        if (plainAndHighlightPair[1]) {
            splits.push({
                kind: "highlight",
                index: index,
                highlightText: plainAndHighlightPair[1]
            });
            index++;
        }
    });

    const splitRenders = splits.map((split: Text) => {
        if (split.kind === "highlight") {
            return <strong key={split.index}>{split.highlightText}</strong>;
        }
        return <span key={split.index}>{split.text}</span>;
    });

    return <span>{splitRenders}</span>;
};
