import {
    ConflictsAction,
    currentUserCanChangeAssignedTo,
    getStatusesUsersCanChangeSearchTo,
    hasSearchBusinessDataBeenModified,
    ok,
    PermissionsContext,
    RequestTerm,
    Result,
    SearchStatuses,
    SearchVersion,
    SearchVersionEdited,
    SearchVersionNew,
    SearchVersionUnedited,
    SearchVersionChanges,
    addValidationContext,
    logValidationError,
    validationErrors,
    ValidationErrors,
    ValidationMessage,
    AllLookups,
    validateLookups,
    QuickSearch
} from "aderant-conflicts-models";
import { NIL as nullUuid } from "uuid";
import { messages } from "./Messages";

function validateSearchTerm(term?: string): ValidationMessage | null {
    if (term?.trim() === "") {
        return messages.VLD_SAVE_INVALID_SEARCH_TERM.asValidationMessage(term);
    }

    if (!term) {
        return messages.VLD_SAVE_NULL_SEARCH_TERM.asValidationMessage();
    }

    return null;
}

function validateSearchRequestTerm(validationErrors: ValidationMessage[], lookups: AllLookups, term?: RequestTerm, existingTerm?: RequestTerm) {
    if (!term) {
        validationErrors.push(messages.VLD_SAVE_NULL_REQ_TERM.asValidationMessage());
        return;
    }

    if (!term.term || term.term.trim() === "") {
        validationErrors.push(messages.VLD_SAVE_INVALID_REQ_TERM.asValidationMessage(term.term));
    }

    if (!term.searchTerms) {
        validationErrors.push(messages.VLD_SAVE_NULL_TERMS.asValidationMessage());
        return;
    }

    //Lookup Validation
    validateLookups(lookups, validationErrors, term, existingTerm);

    for (let i = 0; i < term.searchTerms.length; i++) {
        const errMessage = validateSearchTerm(term.searchTerms[i]);
        if (errMessage) {
            errMessage.context = {
                type: "searchTerm",
                id: term.searchTerms[i]
            };
            validationErrors.push(errMessage);
        }
    }

    return null;
}

export async function validateForExistingSave(
    context: PermissionsContext,
    lookups: AllLookups,
    existingSearchVersion: SearchVersionUnedited | QuickSearch,
    updatedSearchVersion: SearchVersionEdited | QuickSearch,
    reassignMessage?: string,
    statusNotificationMessage?: string,
    options?: { allowStatusChangeWhenCurrentUserIsNotAssigned?: boolean }
): Promise<Result<SearchVersionChanges, ValidationErrors>> {
    const fieldHasChanged = (field: keyof SearchVersion) => {
        return existingSearchVersion[field] !== updatedSearchVersion[field];
    };

    const searchVersionChanges = new SearchVersionChanges();

    const validatedUpdatedSearch = validateCore(lookups, updatedSearchVersion, existingSearchVersion);
    if (!ok(validatedUpdatedSearch)) {
        const validationErrors: ValidationErrors = validatedUpdatedSearch;
        return validationErrors;
    }

    const validationResult: ValidationErrors = validationErrors();

    //Validate state transition
    if (fieldHasChanged("status")) {
        const allowedStatusChanges = await getStatusesUsersCanChangeSearchTo(context, existingSearchVersion, null, options);
        if (!allowedStatusChanges.some((s) => s === updatedSearchVersion.status)) {
            validationResult.errors.push(messages.VLD_INVALID_STATUS_CHANGE.asValidationMessage(existingSearchVersion.status, updatedSearchVersion.status));
        } else {
            searchVersionChanges.status = true;
        }
        if (statusNotificationMessage && statusNotificationMessage.length > 2000) {
            const validationErr = addValidationContext(messages.VLD_INVALID_STATUS_NOTIFICATION_MESSAGE_TOO_LONG.asValidationMessage(), "Search request", updatedSearchVersion.searchId);
            logValidationError(validationErr, context.logger);
            validationResult.errors.push(validationErr);
        }
    }

    if (fieldHasChanged("assignedToUserId")) {
        if (!(await currentUserCanChangeAssignedTo(context, existingSearchVersion))) {
            validationResult.errors.push(messages.VLD_INVALID_REASSIGN.asValidationMessage());
        } else {
            searchVersionChanges.assignedToUserId = true;
        }
        if (reassignMessage && reassignMessage.length > 2000) {
            const validationErr = addValidationContext(messages.VLD_INVALID_REASSIGN_MESSAGE_TOO_LONG.asValidationMessage(), "Search request", updatedSearchVersion.searchId);
            logValidationError(validationErr, context.logger);
            validationResult.errors.push(validationErr);
        }
    }

    //Submitted search requests can only be modified by the assigned to user
    if (
        hasSearchBusinessDataBeenModified(existingSearchVersion, updatedSearchVersion) &&
        updatedSearchVersion.status === SearchStatuses.Submitted &&
        existingSearchVersion.status === SearchStatuses.Submitted &&
        (context.currentUserId !== updatedSearchVersion.assignedToUserId || !(await context.currentUserHasPermission(ConflictsAction.EditSubmittedSearch)))
    ) {
        validationResult.errors.push(messages.VLD_SUBMITTED_SEARCH_CHANGED_BY_UNASSIGNED_USER.asValidationMessage());
    }

    //When changing from DRAFT to SUBMIT, check that the submitted date is updated
    if (existingSearchVersion.status === SearchStatuses.Draft && updatedSearchVersion.status === SearchStatuses.Submitted && !updatedSearchVersion.submittedDate) {
        validationResult.errors.push(messages.VLD_SUBMIT_DATE_NOT_SET.asValidationMessage());
    }
    //Cannot remove submitted date if present, cannot have a submitted date on a draft search
    if (existingSearchVersion.submittedDate && !updatedSearchVersion.submittedDate) {
        validationResult.errors.push(messages.VLD_SUBMIT_DATE_UNSET.asValidationMessage());
    } else if (updatedSearchVersion.status === SearchStatuses.Draft && updatedSearchVersion.submittedDate) {
        validationResult.errors.push(messages.VLD_SUBMIT_DATE_ON_DRAFT.asValidationMessage());
    }

    //Only assigned Conflicts users can perform a search on a submitted search
    if (updatedSearchVersion.status === SearchStatuses.Searched && existingSearchVersion.status !== SearchStatuses.Searched && !context.currentUserHasPermission(ConflictsAction.PerformSearch)) {
        validationResult.errors.push(messages.VLD_SAVE_SEARCH_PERFORMED_BY_UNAUTH_USER.asValidationMessage());
    }

    if (updatedSearchVersion.status === SearchStatuses.Searched && existingSearchVersion.status === SearchStatuses.Submitted && context.currentUserId !== updatedSearchVersion.assignedToUserId) {
        validationResult.errors.push(messages.VLD_SAVE_SEARCH_PERFORMED_BY_UNAUTH_USER.asValidationMessage());
    }

    if (validationResult.errors.length > 0) {
        return validationResult;
    } else {
        context.logger.debug(`input validator returning searchVersionChanges: ${JSON.stringify(searchVersionChanges, null, 2)}`);
        return searchVersionChanges;
    }
}

export function validateCore(
    lookups: AllLookups,
    searchVersion?: SearchVersionNew | SearchVersionEdited | QuickSearch,
    existingVersion?: SearchVersionUnedited | QuickSearch
): Result<SearchVersionChanges, ValidationErrors> {
    const validationResult: ValidationErrors = validationErrors();
    const errors = validationResult.errors;
    if (searchVersion == null || searchVersion == undefined) {
        errors.push(messages.VLD_SAVE_NULL_SCH.asValidationMessage());
        return validationResult;
    }

    if (!searchVersion.requestTerms) {
        errors.push(messages.VLD_SAVE_NULL_REQ_TERMS.asValidationMessage());
        return validationResult; //no point in validating any further if we have no search or terms;
    }

    if (searchVersion.id && searchVersion.id == nullUuid) {
        errors.push(messages.VLD_SAVE_EMPTY_SCH_ID.asValidationMessage());
    }

    if (!searchVersion.createdByUserId) {
        errors.push(messages.VLD_SAVE_NULL_CREATEDBY.asValidationMessage());
    }

    if (searchVersion.createdByUserId && searchVersion.createdByUserId == nullUuid) {
        errors.push(messages.VLD_SAVE_EMPTY_CREATEDBY.asValidationMessage());
    }

    const existingVersionRequestTermsMap = new Map(existingVersion?.requestTerms.map((r) => [r.id, r]));
    for (let i = 0; i < searchVersion.requestTerms.length; i++) {
        validateSearchRequestTerm(errors, lookups, searchVersion.requestTerms[i], existingVersionRequestTermsMap.get(searchVersion.requestTerms[i].id));
    }

    if (validationResult.errors.length > 0) {
        return validationResult;
    } else {
        return new SearchVersionChanges();
    }
}
