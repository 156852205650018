import { EditableBooleanFieldDefinition } from "aderant-conflicts-models";
import React from "react";
import { FieldProps } from "./Types/FieldProps";
import { Checkbox } from "@aderant/aderant-react-components";

type YesNoFieldProps = {
    field: EditableBooleanFieldDefinition;
} & FieldProps;

export function YesNoField(props: YesNoFieldProps): JSX.Element {
    return (
        <Checkbox
            id={`${props.field.name}-checkbox`}
            key={props.field.name}
            label={props.field.label}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.onChange(e.currentTarget.checked);
            }}
            checked={props.data}
            style={{ marginLeft: "23px" }}
            disabled={props.isPagePersisting}
        />
    );
}
