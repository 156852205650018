import { EditableBooleanFieldDefinition } from "../../Fields/EditableFields/FieldDefinitions";
import { ConflictsAction } from "../../Permissions";
import { satisfies } from "../../UtilityTypes";
import { Messages } from "../Messages";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./InternalTypes/FirmSettingsPageDefinition";

const isApproverFieldEnabled = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "isApproverFieldEnabled",
    label: Messages.ALLOW_APPROVERS_TO_BE_DESIGNATED.getMessage()
} as const);

const allowStandardUsersToPerformQuickSearch = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "allowStandardUsersToPerformQuickSearch",
    label: Messages.ALLOW_STANDARD_USERS_TO_USE_QUICK_SEARCH.getMessage()
} as const);

const enableFuzzySearchOnQuickSearches = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "enableFuzzySearchOnQuickSearches",
    label: Messages.ENABLE_FUZZY_ON_QUICK_SEARCHES.getMessage()
} as const);

const includeDataFromPriorSearches = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "includeDataFromPriorSearches",
    label: Messages.INCLUDE_DATA_FROM_PRIOR_SEARCHES.getMessage()
} as const);

const GeneralSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "general",
    displayName: Messages.GENERAL.getMessage(),
    fields: [isApproverFieldEnabled],
    type: "basic",
    internalUsersOnly: false
} as const);

const QuickSearchSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "quicksearch",
    displayName: Messages.QUICK_SEARCHES.getMessage(),
    fields: [enableFuzzySearchOnQuickSearches, allowStandardUsersToPerformQuickSearch],
    type: "basic",
    internalUsersOnly: false
} as const);

const SearchDefaultsSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "searchDefaults",
    displayName: Messages.SEARCH_DEFAULTS.getMessage(),
    fields: [includeDataFromPriorSearches],
    type: "basic",
    internalUsersOnly: false
} as const);

export const FirmOptionsDefinition = satisfies<FirmSettingsPageDefinition>()({
    name: "firm-options",
    displayName: Messages.FIRM_OPTIONS.getMessage(),
    sections: [GeneralSection, QuickSearchSection, SearchDefaultsSection],
    requiredPermission: ConflictsAction.ViewAndEditFirmOptions,
    internalUsersOnly: false,
    dataToLoad: ["FIRM_SETTINGS_PAGE_DATA"]
} as const);
