import { Message } from "aderant-conflicts-models";

export const Messages = {
    PROFILE_DIALOG_HEADER: new Message("PROFILE_DIALOG_HEADER", "Report"),
    PROFILE_DIALOG_HEADER_RESOLVED: new Message("PROFILE_DIALOG_HEADER_RESOLVED", "All hits have been resolved"),
    PROFILE_DIALOG_TABS_AL: new Message("PROFILE_DIALOG_TABS_AL", "Search Report Details"),
    PROFILE_DIALOG_TAB_DETAILS: new Message("PROFILE_DIALOG_TAB_DETAILS", "Details"),
    PROFILE_DIALOG_TAB_TERMS: new Message("PROFILE_DIALOG_TAB_TERMS", "Terms"),
    PROFILE_DIALOG_TAB_AUDIT: new Message("PROFILE_DIALOG_TAB_AUDIT", "Audit"),
    PROFILE_DIALOG_CLOSE_BUTTON: new Message("PROFILE_DIALOG_CLOSE_BUTTON", "Close"),
    PROFILE_DIALOG_SAVE_BUTTON: new Message("PROFILE_DIALOG_SAVE_BUTTON", "Save"),
    PROFILE_DIALOG_SAVE_SEND_BUTTON: new Message("PROFILE_DIALOG_SAVE_SEND_BUTTON", "Save & Send"),
    SEARCH_RESULTS: new Message("SEARCH_RESULTS", "Search Results as of"),
    SEARCH_RESULTS_SHORT: new Message("SEARCH_RESULTS_SHORT", "Results as of"),
    DISCARD_UNSAVED_CHANGES: new Message("DISCARD_UNSAVED_CHANGES", "Do you want to discard unsaved changes?"),
    DISCARD_UNSAVED_CHANGES_TITLE: new Message("DISCARD_UNSAVED_CHANGES_TITLE", "Discard unsaved changes?"),

    // Descriptions for audit
    CREATED_SEARCH_REQUEST: new Message<[string]>("CREATED_SEARCH_REQUEST", "Created search request {0}"),
    SUBMITTED_SEARCH_REQUEST: new Message<[string]>("SUBMITTED_SEARCH_REQUEST", "Submitted search request {0}"),
    SEARCHED_SEARCH_REQUEST: new Message<[string]>("SEARCHED_SEARCH_REQUEST", "Searched search request {0}"),
    UPDATED_HIT_RES_STATUS_MANY: new Message<[number, string]>("UPDATED_HIT_RES_STATUS", "Updated {0} hit statuses to {1}"),
    UPDATED_HIT_RES_STATUS_ONE: new Message<[number, string]>("UPDATED_HIT_RES_STATUS", "Updated {0} hit status to {1}"),
    UPDATED_REQUEST_STATUS_TO_FROM: new Message<[string, string]>("UPDATED_REQUEST_STATUS_TO_FROM", "Updated Request Status from {0} to {1}"),
    UPDATED_REQUEST_STATUS_TO_FROM_WITH_MESSAGE: new Message<[string, string, string]>("UPDATED_REQUEST_STATUS_TO_FROM_WITH_MESSAGE", "Updated Request Status from {0} to {1} with message: {2}"),
    REASSIGNED_TO: new Message<[string]>("REASSIGNED_TO", "Reassigned to {0}"),
    REASSIGNED_TO_WITH_MESSAGE: new Message<[string, string]>("REASSIGNED_TO_WITH_MESSAGE", "Reassigned to {0} with message: {1}"),
    UNASSIGNED_FROM: new Message<[string]>("UNASSIGNED_FROM", "Unassigned from {0}"),
    UNASSIGNED_WITH_MESSAGE: new Message<[string, string]>("UNASSIGNED_WITH_MESSAGE", "Unassigned from {0} with message: {1}"),
    ADDED_HIT_COMMENT: new Message<[string]>("ADDED_HIT_COMMENT", "Added a comment to a hit: {0}"),
    EDITED_HIT_COMMENT: new Message<[string]>("EDITED_HIT_COMMENT", "Edited a comment on a hit. The comment is now: {0}"),
    DELETED_HIT_COMMENT: new Message<[string]>("DELETED_HIT_COMMENT", "Deleted a comment on a hit: {0}"),
    CREATED_NEW_SEARCH_VERSION: new Message<[string]>("CREATED_NEW_SEARCH_VERSION", "New search version created {0}"),
    ADDED_NEW_REQUEST_TERM_ONE: new Message<[string]>("ADDED_NEW_REQUEST_TERM_ONE", "Request term added to search request {0}: "),
    REMOVED_REQUEST_TERM_ONE: new Message<[string]>("REMOVED_REQUEST_TERM_ONE", "Request term removed from {0}: "),
    ADDED_NEW_SEARCH_TERM_ONE: new Message<[number, string]>("ADDED_NEW_SEARCH_TERM_ONE", "{0} search term added to {1}"),
    REMOVED_SEARCH_TERM_ONE: new Message<[number, string]>("REMOVED_SEARCH_TERM_ONE", "{0} search term removed from {1}"),
    ADDED_NEW_REQUEST_TERM_MANY: new Message<[string]>("ADDED_NEW_REQUEST_TERM_MANY", "Request terms added to search request {0}: "),
    REMOVED_REQUEST_TERM_MANY: new Message<[string]>("REMOVED_REQUEST_TERM_MANY", "Request terms removed from {0}: "),
    ADDED_NEW_SEARCH_TERM_MANY: new Message<[number, string]>("ADDED_NEW_SEARCH_TERM_MANY", "{0} search terms added to {1}"),
    REMOVED_SEARCH_TERM_MANY: new Message<[number, string]>("REMOVED_SEARCH_TERM_MANY", "{0} search terms removed from {1}"),
    ON_SEARCH_REQUEST: new Message<[string]>("ON_SEARCH_REQUEST", "on search request {0}"),
    UPDATED_NAME_OF_SEARCH: new Message<[string]>("UPDATE_NAME_OF_SEARCH", "Updated name of search request {0}"),
    UPDATED_DESC_OF_SEARCH: new Message<[string]>("UPDATE_DESC_OF_SEARCH", "Updated description of search request {0}"),
    SEARCH_ERROR_STATUS_CHANGE: new Message<[string]>("SEARCH_ERROR_STATUS_CHANGE", "The search process for the new version failed, so status has been set to Submitted {0}"),
    UPDATED_APPROVER: new Message<[searchRequestNumber: string, approverUserName: string]>("UPDATED_APPROVER", "Changed Approver of search request {0} to {1}"),
    REMOVED_APPROVER: new Message<[removedApproverUserName: string, searchRequestNumber: string]>("REMOVED_APPROVER", "Removed {0} as Approver of search request {1}")
};
