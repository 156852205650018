import { ConflictsAction, Hit, HitLocation, QuickSearch, SearchVersionUnedited } from "aderant-conflicts-models";
import { BlobStorageConnector } from "../BlobStorageConnector/BlobStorageConnector";
import { getWhiteList } from "./HitResultDataMapping";
import _ from "lodash";
import { RowLevelSecurityConfigurationService } from "../Config/RowLevelSecurityConfiguration/RowLevelSecurityConfigurationService";
import { RLSConnectorContext, RowLevelSecurityConnector } from "./RowLevelSecurityConnector";
import { LogTimeTakenForAsync } from "../../Utilities/TimeLogging";

export async function redactHitResults(context: RLSConnectorContext, search: SearchVersionUnedited | QuickSearch, rlsConnector?: RowLevelSecurityConnector, blobConnector?: BlobStorageConnector) {
    const [result, redactTime] = await LogTimeTakenForAsync<void>(async () => {
        context.logger.info("Checking row level security and redacting secured hits that current user does not have access to.");
        const rowLevelSecurityConnector = rlsConnector ?? (await RowLevelSecurityConnector.open(context));
        if (await context.currentUserHasPermission(ConflictsAction.BypassRowLevelSecurity)) {
            context.logger.info("Current user has permission to skip RLS check.  Skipping redaction");
            return;
        }

        const rowLevelSecurityConfigurationService = new RowLevelSecurityConfigurationService();
        const blobStorageConnector = blobConnector ?? (await rowLevelSecurityConfigurationService.getBlobConnector(context, context.currentUser.tenancy.uniqueName));
        const visibleWhenSecureColumns = await rowLevelSecurityConfigurationService.getVisibleWhenSecureColumns(context, context.currentUser.tenancy.uniqueName, blobStorageConnector);
        const firmWhitelist: string[] = visibleWhenSecureColumns.configurations;
        const hitsBySecureEntityId = search.requestTerms
            .flatMap((r) => r.hits ?? [])
            .reduce((dict: Record<string, Hit[]>, hit: Hit) => {
                //we can safely ignore hits with no secure entity id as they are from searches before RLS support was added - no need to redact anything.
                if (hit.sourceData.secureEntityId) {
                    let hits: Hit[] | undefined = dict[hit.sourceData.secureEntityId];
                    if (!hits) {
                        hits = [];
                    }
                    hits.push(hit);
                    dict[hit.sourceData.secureEntityId] = hits;
                }
                return dict;
            }, {});

        const [userEntityAccessList, fetchTimeUserEntityAccessList] = await LogTimeTakenForAsync<any>(async () => rowLevelSecurityConnector.getEntityAccessList());
        context.appInsightsClient.trackMetric({ name: "redactHits-fetch-entity-access-list-time", value: fetchTimeUserEntityAccessList });

        //Group the secureEntityIds by the user's permission to view them - DENY, ALLOW or NONE.
        const groups = _.groupBy(Object.keys(hitsBySecureEntityId), (id) => RowLevelSecurityConnector.getUserPermission(id, userEntityAccessList));

        //The hits that returned NONE will either be secured (and therefore redacted for this user) or not secured (and therefore visible for this user).
        //We need to differentiate between these two via checking if each entity is secured or not.
        const [{ securedIds: redactedSecuredIds, nonSecuredIds }, fetchTimeSecuredEntities] = await LogTimeTakenForAsync<{
            securedIds: string[];
            nonSecuredIds: string[];
        }>(async () => await rowLevelSecurityConnector.filterSecuredSecureEntityIds(groups.NONE ?? []));
        context.appInsightsClient.trackMetric({ name: "redactHits-fetch-secured-entity-ids-for-user", value: fetchTimeSecuredEntities });

        const deniedHits = (groups.DENY ?? []).flatMap((id) => hitsBySecureEntityId[id]);
        const redactedSecuredHits = redactedSecuredIds.flatMap((id) => hitsBySecureEntityId[id]);
        //Now redact the DENY group and the secured hits from the NONE group
        const hitsToRedact = [...deniedHits, ...redactedSecuredHits];
        context.logger.info(
            "Found %i secured ids that should be redacted, and %i hits that are secured but visible. This results in %i redacted hits on the search.",
            redactedSecuredIds.length + (groups.DENY?.length ?? 0),
            groups.ALLOW?.length ?? 0,
            hitsToRedact.length
        );
        hitsToRedact.forEach((hit) => redactHit(hit, firmWhitelist));
    });
    context.appInsightsClient.trackMetric({ name: "redactHits-total-time-taken", value: redactTime });
}

function redactHit(hit: Hit, firmWhitelist: string[]) {
    const fieldWhitelist = new Set(getWhiteList(firmWhitelist, hit));
    const redactedSourceData = Object.fromEntries(
        Object.entries(hit.sourceData).map((entry) => {
            const [name, _] = entry;
            if (fieldWhitelist.has(name)) {
                return entry;
            } else {
                return [name, "**Redacted**"];
            }
        })
    );
    const redactedLocationData = hit.hitLocations.map((hitLocation: HitLocation) => {
        if (!fieldWhitelist.has(hitLocation.location)) {
            return { ...hitLocation, highlights: hitLocation.highlights.map((highlight: string) => "**Redacted**") };
        }
        return hitLocation;
    });

    hit.sourceData = redactedSourceData;
    hit.hitLocations = redactedLocationData;
    hit.isRedacted = true;
    return hit;
}
