import { SearchIndexer } from "./SearchIndexConnector";
import { createEntityRelationships } from "./relatedEntitiesMap";
import { Logger } from "aderant-web-fw-core";
import { LookupDataConnector } from "./LookupDataConnector";
import { EntityDocument, unexpectedError } from "aderant-conflicts-models";
import { DeltaType, IngestedEntityDocument, RecordBatch } from "./Types";

/**
 * This function creates a set of BATCH_LIMIT_SIZE records with the same operation.  These are then parceled out by Bluebird at the end of the read to limit point load on
 * the endpoints.  There is an assumption that these are all created in the same order every time (that the read is always sequential), as duplicate checking will fail if this is not
 * the case.  //*TODO need to test this
 */
export function createRecordBatch(
    action: DeltaType,
    records: any[],
    entityType: string,
    indexFieldsForEntityType: string[],
    searchableIndexFieldsForEntityType: string[],
    logger: Logger
): RecordBatch {
    const { lookupRecords, searchRecords } = createLookupAndSearchRecords(records, entityType, indexFieldsForEntityType);
    const updateSearchIndex = isEntitySearchable(entityType, searchableIndexFieldsForEntityType);
    if (!updateSearchIndex) {
        logger.debug("Skipping update of search index, as this entity is not searchable");
    }

    return {
        entityType: entityType,
        action: action,
        lookupRecords: lookupRecords,
        searchRecords: updateSearchIndex ? searchRecords : []
    };
}

export async function actionRecordBatch(recordBatch: RecordBatch, cogSearchIndexer: SearchIndexer, tenancyIndexName: string, logger: Logger, cosmosConnector?: LookupDataConnector) {
    const { entityType, action, lookupRecords, searchRecords } = recordBatch;
    if (entityType !== "priorsearch" && !cosmosConnector) {
        throw unexpectedError("No cosmosConnector provided for ingesting entity type " + entityType, "Performing actionRecordBatch during firm data ingestion.");
    }

    logger.debug("Actioning record batch of type " + action);

    switch (action) {
        case DeltaType.Add:
            await actionCreateBatch(entityType, lookupRecords, searchRecords, cogSearchIndexer, tenancyIndexName, logger, cosmosConnector);
            break;
        case DeltaType.Update:
            await actionUpdateBatch(entityType, lookupRecords, searchRecords, cogSearchIndexer, tenancyIndexName, cosmosConnector);
            break;
        case DeltaType.Remove:
            await actionRemoveBatch(entityType, lookupRecords, searchRecords, cogSearchIndexer, tenancyIndexName, logger, cosmosConnector);
            break;
        default:
            throw unexpectedError("Missing action", "");
    }
    logger.debug("Finished actioning record batch of type " + action);
}

async function actionCreateBatch(
    entityType: string,
    lookupRecords: EntityDocument[],
    searchRecords: IngestedEntityDocument[],
    cogSearchIndexer: SearchIndexer,
    tenancyIndexName: string,
    logger: Logger,
    cosmosConnector?: LookupDataConnector
) {
    logger.debug("Checking if batch has already been actioned");
    if (entityType === "priorsearch") {
        searchRecords.length > 0 && (await cogSearchIndexer.indexRecords(searchRecords, tenancyIndexName));
    } else {
        if (!cosmosConnector) {
            throw unexpectedError("No cosmosConnector provided for ingesting entity type " + entityType, "Performing actionCreateBatch during firm data ingestion.");
        }
        const createBatchAlreadyProcessedCosmos = await cosmosConnector.doesEntityExist(lookupRecords[0].id, entityType); //Exists == true
        if (!createBatchAlreadyProcessedCosmos) {
            searchRecords.length > 0 && (await cogSearchIndexer.indexRecords(searchRecords, tenancyIndexName));
            await cosmosConnector.createEntities(lookupRecords, entityType);
        } else {
            logger.warn("Batch has already been created");
        }
    }
}

async function actionUpdateBatch(
    entityType: string,
    lookupRecords: EntityDocument[],
    searchRecords: IngestedEntityDocument[],
    cogSearchIndexer: SearchIndexer,
    tenancyIndexName: string,
    cosmosConnector?: LookupDataConnector
) {
    searchRecords.length > 0 && (await cogSearchIndexer.indexRecords(searchRecords, tenancyIndexName));
    if (cosmosConnector) {
        await cosmosConnector.updateEntities(lookupRecords, entityType);
    } else {
        if (entityType != "priorsearch") {
            throw unexpectedError("No cosmosConnector provided for ingesting entity type " + entityType, "Performing actionUpdateBatch during firm data ingestion.");
        }
    }
}

async function actionRemoveBatch(
    entityType: string,
    lookupRecords: EntityDocument[],
    searchRecords: IngestedEntityDocument[],
    cogSearchIndexer: SearchIndexer,
    tenancyIndexName: string,
    logger: Logger,
    cosmosConnector?: LookupDataConnector
) {
    //Not the same thing, searchIds contain the entity type
    const lookupIds = lookupRecords.map((lookupRecord: EntityDocument) => lookupRecord.id);
    const searchIds = searchRecords.map((searchRecord: IngestedEntityDocument) => searchRecord.id);

    if (entityType === "priorsearch") {
        throw unexpectedError("Delete of prior searches from the search index has not been implemented.", "This was called from actionRemoveBatch in IngestSearchData.");
        //To implement this function:
        //uncomment below line, which has not been tested, so you need to test it, including what happens if the data doesn't exist in the index.
        //searchRecords.length > 0 && (await cogSearchIndexer.removeIndexedRecords(searchIds, tenancyIndexName));
    } else {
        if (!cosmosConnector) {
            throw unexpectedError("No cosmosConnector provided for ingesting entity type " + entityType, "Performing actionRemoveBatch during firm data ingestion.");
        }
        logger.debug("Checking if batch has already been actioned");
        const deleteBatchAlreadyProcessedCosmos = !(await cosmosConnector.doesEntityExist(lookupIds[0], entityType)); //Doesn't exist == true

        if (!deleteBatchAlreadyProcessedCosmos) {
            searchRecords.length > 0 && (await cogSearchIndexer.removeIndexedRecords(searchIds, tenancyIndexName));
            await cosmosConnector?.deleteEntities(lookupIds, entityType);
        } else {
            logger.warn(`Batch has already been deleted`);
        }
    }
}

function isEntitySearchable(entityType: string, searchableIndexFieldsForEntityType: string[]): boolean {
    if (entityType.toLowerCase().endsWith("lookup")) {
        return false; //Lookup entities are used in the UI for dropdown controls. They are not intended to be searched. (even if they have a fieldName shared with the searchable fields (e.g. "name"))
    }
    //ToDo(5328) This should be a list of searchable index fields for the specific entity type, but is currently a list of all searchable index fields, irrespective of entity type
    //When this is fixed, you should remove the above if statement
    return searchableIndexFieldsForEntityType.length > 0; //If there are no indexable fields, don't update the search engine
}

function createLookupAndSearchRecords(
    records: IngestedEntityDocument[],
    entityType: string,
    indexFieldsForEntityType: string[]
): {
    lookupRecords: EntityDocument[];
    searchRecords: IngestedEntityDocument[];
} {
    const result: {
        lookupRecords: EntityDocument[];
        searchRecords: IngestedEntityDocument[];
    } = {
        lookupRecords: [],
        searchRecords: []
    };

    records.forEach((record: IngestedEntityDocument) => {
        const { lookupRecord, searchRecord } = createLookupAndSearchRecord(record, entityType, indexFieldsForEntityType);
        result.lookupRecords.push(lookupRecord);
        result.searchRecords.push(searchRecord);
    });

    return result;
}

export function createLookupAndSearchRecord(
    record: IngestedEntityDocument,
    entityType: string,
    indexFieldsForEntityType: string[]
): {
    lookupRecord: EntityDocument;
    searchRecord: IngestedEntityDocument;
} {
    // EntityDocument was changed to make last modified optional for this.
    // Every EntityDocument should have a lastModified, but it does not exist before being saved into cosmosDB.
    // It is set to the timestamp on the cosmosDB record when retrieved from cosmosDB
    const lookupRecord: EntityDocument = { id: "", lastModified: undefined, entityType: entityType };
    const searchRecord: IngestedEntityDocument = { entityType: entityType };

    if (record.id === null || record.id === undefined) {
        throw unexpectedError(`Id on entity is null or undefined`, JSON.stringify(record));
    }

    Object.keys(record).forEach((key: string) => {
        //IDs need to be strings in Cosmos and CogSearch
        if (key === "id") {
            // When we introduce customizable fields, we will need to consider that ID / EntityType may not be unique.
            lookupRecord.id = record.id.toString();
            searchRecord.id = `${entityType}-${record.id.toString()}`;
        } else {
            let recordValue = record[key];
            if (indexFieldsForEntityType.some((fieldName: string) => fieldName === key)) {
                searchRecord[key] = recordValue;
            }

            // When we interface with other systems, we need to consider that this check is here.
            if (key.endsWith("Id")) {
                //ID values need to be strings when saved to work with Conflicts queries in cosmos.
                recordValue = recordValue?.toString() ?? "";
            }
            lookupRecord[key] = recordValue;
        }
    });

    lookupRecord.entityRelationships = createEntityRelationships(lookupRecord);
    searchRecord.entityRelationships = createEntityRelationships(searchRecord);

    return {
        lookupRecord: lookupRecord,
        searchRecord: searchRecord
    };
}
