import {
    ClaimValidationError,
    ClientAppContext,
    ConflictsClaims,
    ProductEnvironment,
    getAuthTenancy,
    isUrlParamFeatureFlagOn,
    rolesArrayToUserRole,
    validateKnownClaimsFromLoggedInAccount
} from "aderant-conflicts-common";
import {
    EditableFieldValue,
    Forbidden,
    HardcodedPermissionsConnector,
    LoggedInUser,
    ok,
    Result,
    UnexpectedError,
    unexpectedError,
    FirmSettings,
    satisfies,
    aderantTenants,
    forbidden
} from "aderant-conflicts-models";
import { ConsoleLogger } from "aderant-web-fw-applications";
import Page from "components/PageContainer/Page";
import { UserAlertDisplay } from "components/UserAlertDisplay/UserAlertDisplay";
import ErrorDialog from "ErrorDialog";
import { RootState } from "MyTypes";
import { FirmSettingsPage } from "pages/FirmSettingsPage/FirmSettingsPage";
import LoginPage, { LoginMessages } from "pages/LoginPage/LoginPage";
import ResultsPage from "pages/ResultsPage/ResultsPage";
import NewSearchVersionEditPageContainer from "pages/SearchEditPage/NewSearchVersionEditPageContainer";
import QuickSearchEditPageContainer from "pages/SearchEditPage/QuickSearchEditPageContainer";
import SearchEditPageContainer from "pages/SearchEditPage/SearchEditPageContainer";
import SearchesPage from "pages/SearchesPage/SearchesPage";
import React, { Dispatch, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import SearchRoute from "SearchRoute";
import { Services, createServices } from "services/Services";
import { adminActions, appActions, searchActions } from "state/actions";
import store, { initializeAppContext } from "state/store/store";
import { PathMap } from "./utilities/routingPathMap";
import { NoopAppInsights } from "./AppInsights/AppInsightsWeb";
import QuickSearchesPage from "pages/SearchesPage/QuickSearchesPage";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { acquireToken, createLoginRequest } from "AuthUtils";
import { getAuthorisedSubscriptions } from "utilities/subscriptions/subscription";

interface StateProps {
    hasConflictsSagaContext: boolean;
    hasSearches: boolean;
}
const mapStateToProps = (state: RootState): StateProps => {
    return {
        hasConflictsSagaContext: !!state.app.conflictsSagaContext,
        hasSearches: state.search.searchSummaries.length > 0
    };
};

// as paths are generated from page definition, we know they only include the valid FieldPaths
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const firmOptionsPaths: FirmSettings.FieldPath[] = FirmSettings.getBasicPagePaths(FirmSettings.FirmOptionsDefinition) as FirmSettings.FieldPath[];
const firmSettingsToFetch = satisfies<readonly FirmSettings.FieldPath[]>()(firmOptionsPaths);

//this type is generated to use in the selector to ensure settings must be fetched on app startup in order to be queried.
export type AppFetchedFirmSetting = (typeof firmSettingsToFetch)[number];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        fetchLookups: () => dispatch(appActions.fetchLookups()),
        fetchGridPreferences: () => dispatch(appActions.fetchGridPreferences()),
        fetchSearchSummaries: () => dispatch(searchActions.fetchSearchSummaries()),
        fetchUsers: () => dispatch(appActions.fetchUsers()),
        fetchHitResultGridConfiguration: () => dispatch(appActions.fetchHitResultGridConfiguration()),
        fetchFirmSettings: (firmSettings?: Record<AppFetchedFirmSetting, { value: EditableFieldValue }>) => {
            if (firmSettings) {
                //this will add the firm settings to state
                dispatch(adminActions.fetchFirmSettingsSuccess(firmSettings));
            } else {
                dispatch(adminActions.fetchFirmSettings(firmSettingsToFetch));
            }
        }
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentProps = RouteComponentProps<any, any> & StateProps & ReturnType<typeof mapDispatchToProps>;

const logger = new ConsoleLogger();

function App(props: ComponentProps) {
    // Auth hooks and state
    const { accounts, inProgress, instance } = useMsal();
    // Storing the current account in useState to trigger a re-render when the account changes (doesn't trigger a re-render when the account is updated in the msal instance)
    const [currentAccount, setCurrentAccount] = useState<AccountInfo | undefined>(accounts[0]);
    const [isTokenAcquiredSilently, setIsTokenAcquiredSilently] = useState<boolean>(false);
    const [error, setError] = useState<UnexpectedError | Forbidden | undefined>(undefined);
    const isLoginHintFeatureEnabled = isUrlParamFeatureFlagOn("loginHint");
    const [authorisedSubscriptions, setAuthorisedSubscriptions] = useState<ProductEnvironment[] | undefined>(getAuthorisedSubscriptions(currentAccount));
    const [selectedSubscription, setSelectedSubscription] = useState<ProductEnvironment | undefined>();
    const [validatedClaims, setValidatedClaims] = useState<Result<ConflictsClaims, Forbidden | ClaimValidationError> | undefined>(undefined);

    const urlParams = new URLSearchParams(window.location.search);
    const login_hint = urlParams.get("login_hint");

    // App Startup
    useEffect(() => {
        // Set auth redirect promise handler
        // Setting it here instead of in AuthUtils.ts so we have access to the current account state
        instance
            .handleRedirectPromise()
            .then((response) => {
                if (response && response.account) {
                    instance.setActiveAccount(response.account);
                    setCurrentAccount(response.account);
                    setAuthorisedSubscriptions(getAuthorisedSubscriptions(response.account));
                    store.dispatch(appActions.login(response.account.localAccountId));
                    setIsTokenAcquiredSilently(false);
                }
            })
            .catch((error) => {
                console.error("Login redirect error:", error);
                setError(unexpectedError(error, "Login redirect error"));
            });

        // If there is an account, get the token silently on app launch to avoid token expiry issues
        if (currentAccount) {
            instance
                .acquireTokenSilent(createLoginRequest({ account: currentAccount }))
                .then((response) => {
                    if (response && response.account) {
                        instance.setActiveAccount(response.account);
                        setCurrentAccount(response.account);
                        setAuthorisedSubscriptions(getAuthorisedSubscriptions(response.account));
                        store.dispatch(appActions.login(response.account.localAccountId));
                        setIsTokenAcquiredSilently(true);
                    }
                })
                .catch((error) => {
                    console.error("Silent token acquisition failed:", error);
                });
        }
    }, []);

    //Set validated claims on login
    useEffect(() => {
        if (currentAccount) {
            if (isLoginHintFeatureEnabled) {
                if (selectedSubscription && currentAccount.idTokenClaims) {
                    const authTenancy = getAuthTenancy(currentAccount.idTokenClaims);
                    if (!ok(authTenancy)) {
                        console.error("Error extracting auth tenancy from token:", authTenancy);
                        setError(unexpectedError(authTenancy, "Error getting auth tenancy from logged in user."));
                        return;
                    }
                    const validatedClaims = validateKnownClaimsFromLoggedInAccount(currentAccount, selectedSubscription.uniqueName);
                    setValidatedClaims(validatedClaims);
                }
            } else {
                const validatedClaims = validateKnownClaimsFromLoggedInAccount(currentAccount);
                setValidatedClaims(validatedClaims);
            }
        }
    }, [currentAccount, selectedSubscription]);

    //firm settings state
    const [firmSettings, setFirmSettings] = useState<Record<AppFetchedFirmSetting, { value: EditableFieldValue }> | undefined>(undefined);
    const [isFetchingAllowStandardUsersToPerformQuickSearch, setIsFetchingAllowStandardUsersToPerformQuickSearch] = useState<boolean>(true);

    // Initialize app context
    useEffect(() => {
        async function update() {
            const { fetchLookups, fetchGridPreferences, fetchHitResultGridConfiguration, fetchSearchSummaries, fetchFirmSettings, fetchUsers, hasConflictsSagaContext, hasSearches } = props;
            if (!currentAccount) {
                return;
            }
            if (isLoginHintFeatureEnabled && !selectedSubscription) {
                return;
            }
            if (!hasConflictsSagaContext && validatedClaims) {
                const error = await initializeContext(validatedClaims);
                if (error) {
                    console.error("Error validating claims for current user:", error);
                    // So we don't set the page in error state if a user refreshes the page with an outdated token
                    if (!isTokenAcquiredSilently) {
                        setError(error);
                    }
                    return;
                }

                fetchLookups();
                fetchHitResultGridConfiguration();
                fetchGridPreferences();
                //don't fetch firm settings again - pass in previously fetched settings so they can be updated to app state
                fetchFirmSettings(firmSettings);
                fetchUsers();

                if (!hasSearches) {
                    fetchSearchSummaries();
                }
            }
        }

        update();
    }, [props.hasConflictsSagaContext, props.hasSearches, currentAccount, selectedSubscription, validatedClaims]);

    async function fetchInitialFirmOptions(
        services: Services,
        user: LoggedInUser
    ): Promise<
        Record<
            FirmSettings.FieldPath,
            {
                value: EditableFieldValue;
            }
        >
    > {
        const defaultFirmSettings = FirmSettings.getBasicPageDefaultSettings(FirmSettings.FirmOptionsDefinition);
        try {
            if (aderantTenants.some((tenantUniqueName) => tenantUniqueName === user.tenancy.uniqueName)) {
                FirmSettings.featureFlagFieldPaths.map((path: FirmSettings.FieldPath) => {
                    firmSettingsToFetch.push(path);
                });
            }
            const fetchedFirmSettings = await services.adminService.getFirmSettingsByFieldPaths(firmSettingsToFetch);
            if (ok(fetchedFirmSettings)) {
                setFirmSettings(fetchedFirmSettings);
                return fetchedFirmSettings;
            } else {
                adminActions.adminFailure(`Unable to fetch firm settings: ${fetchedFirmSettings.message}`);
                appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_LOAD_FAILED.getMessage());
                setFirmSettings(defaultFirmSettings);
                return defaultFirmSettings;
            }
        } catch (error) {
            adminActions.adminFailure(`Unable to fetch firm settings: ${error}.`);
            appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_LOAD_FAILED.getMessage());
            setFirmSettings(defaultFirmSettings);
            return defaultFirmSettings;
        }
    }

    async function initializeContext(validatedClaims: Result<ConflictsClaims, Forbidden | ClaimValidationError>): Promise<Forbidden | UnexpectedError | undefined> {
        if (!ok(validatedClaims)) {
            switch (validatedClaims._conflictserrortype) {
                case "ACCESS_DENIED": {
                    return validatedClaims;
                }
                case "CLAIM_VALIDATION": {
                    //we've already successfully authenticated at this point, so if expected claims are not in the account lets just blow up - something has gone wrong.
                    return unexpectedError(validatedClaims, "Claims in logged in user were not as expected when initializing app.");
                }
            }
        }
        const user: LoggedInUser = {
            id: validatedClaims.userId,
            name: validatedClaims.displayName,
            email: validatedClaims.email,
            role: rolesArrayToUserRole(validatedClaims.roles),
            tenancy: validatedClaims.tenancy
        };

        //Create ClientAppContext without PermissionsConnector as we first need to retrieve allowStandardUsersToPerformQuickSearch firm setting to construct HardcodedPermissionsConnector
        const contextForAPIs: ClientAppContext = new ClientAppContext(logger, new NoopAppInsights(logger), user, acquireToken);
        const services = createServices(contextForAPIs);
        const settings = await fetchInitialFirmOptions(services, user);
        const allowStandardUsersToPerformQuickSearch = fetchAllowStandardUsersToPerformQuickSearch(settings);
        //Set a PermissionsConnector in ClientAppContext
        contextForAPIs.initializePermissionsConnector(new HardcodedPermissionsConnector(allowStandardUsersToPerformQuickSearch));
        initializeAppContext({ logger: logger, services: services }, user, contextForAPIs);
    }

    function fetchAllowStandardUsersToPerformQuickSearch(settings: Record<AppFetchedFirmSetting, { value: EditableFieldValue }> | undefined): boolean {
        setIsFetchingAllowStandardUsersToPerformQuickSearch(true);
        const allowQSForStandardUsersPath = "firm-options/quicksearch/allowStandardUsersToPerformQuickSearch";
        if (settings) {
            const settingType = typeof settings[allowQSForStandardUsersPath].value;
            if (settingType !== "boolean") {
                logger.warn(
                    `Unexpected type of result returned when fetching firm setting: allowStandardUsersToPerformQuickSearch. Expected a boolean but received a ${settingType}. Setting firm setting to false by default.`
                );
                setIsFetchingAllowStandardUsersToPerformQuickSearch(false);
                return false;
            }
            setIsFetchingAllowStandardUsersToPerformQuickSearch(false);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return settings[allowQSForStandardUsersPath].value as boolean;
        } else {
            logger.warn(`Firm setting 'allowStandardUsersToPerformQuickSearch' not found. Setting firm setting to false by default.`);
            setIsFetchingAllowStandardUsersToPerformQuickSearch(false);
            return false;
        }
    }

    const isAppLoading =
        currentAccount && (isLoginHintFeatureEnabled ? selectedSubscription : true) ? !validatedClaims || !props.hasConflictsSagaContext || isFetchingAllowStandardUsersToPerformQuickSearch : false;

    const LoginPageWithProps = () => (
        <LoginPage
            onSignIn={(loginId?: string) => {
                instance.loginRedirect(createLoginRequest({ username: loginId }));
            }}
            lastSignInError={error ?? undefined}
            isAcquiringToken={inProgress !== InteractionStatus.None} // When inProgress becomes None, token acquisition hasn't started or has completed
            isAppLoading={isAppLoading}
            setErrorMessage={(message: string) => {
                // If the web components login component internally sets the error and it's not the known authorisation error, we should treat it as an unexpected error
                message.includes(LoginMessages.UNAUTHORISED_ERROR_USER_TEXT.getMessage()) ? setError(forbidden(message)) : setError(unexpectedError(new Error(message), message));
            }}
            subscriptions={authorisedSubscriptions?.map((sub) => sub.displayName) ?? []}
            onSelectSubscription={(subscription: string) => {
                const selectedSubscription = authorisedSubscriptions?.find((sub) => sub.displayName === subscription);
                setSelectedSubscription(selectedSubscription);
            }}
            selectedSubscription={selectedSubscription?.displayName ?? ""}
            isLoggedIn={!!currentAccount}
            loginHint={currentAccount || error ? undefined : login_hint ?? undefined} //If there is an currentAccount, ignore login_hint. If there is an error, don't pass the login hint to the component as it will cause an infinite redirect loop
        />
    );

    return (
        <React.Fragment>
            <UnauthenticatedTemplate>
                <LoginPageWithProps />
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                {/* checking selectedSubscription hasn't been set so that the login component will
                display the subscription picker */}
                {isAppLoading || (isLoginHintFeatureEnabled ? !selectedSubscription : false) ? (
                    <LoginPageWithProps />
                ) : (
                    <Page onSignOut={() => instance.logoutRedirect({ account: currentAccount })}>
                        <Switch location={props.location}>
                            <Route key={`${PathMap.home}`} path={`${PathMap.home}`} render={() => <SearchesPage />} exact />
                            <SearchRoute
                                key={`${PathMap.searchRequest}:id${PathMap.newVersion}`}
                                path={`${PathMap.searchRequest}:id${PathMap.newVersion}`}
                                render={(props) => <NewSearchVersionEditPageContainer {...props} logger={logger} />}
                            />
                            <SearchRoute
                                key={`${PathMap.searchRequest}:id${PathMap.quickSearch}`}
                                path={`${PathMap.searchRequest}:id${PathMap.quickSearch}`}
                                render={(props) => <QuickSearchEditPageContainer {...props} logger={logger} />}
                            />
                            <SearchRoute key={`${PathMap.searchRequest}:id`} path={`${PathMap.searchRequest}:id`} render={(props) => <SearchEditPageContainer {...props} logger={logger} />} />
                            <SearchRoute key={`${PathMap.results}:id`} path={`${PathMap.results}:id`} render={() => <ResultsPage />} />
                            <Route key={`${PathMap.searchRequests}`} path={`${PathMap.searchRequests}`} render={() => <SearchesPage />} />
                            <Route key={`${PathMap.quickSearches}`} path={`${PathMap.quickSearches}`} render={() => <QuickSearchesPage />} />
                            <Route key={`${PathMap.firmSettings}`} path={`${PathMap.firmSettings}`} render={() => <FirmSettingsPage />} />
                        </Switch>
                    </Page>
                )}
                <ErrorDialog />
                <UserAlertDisplay />
            </AuthenticatedTemplate>
        </React.Fragment>
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
