import { EntityFlyoutConfigurationTemplate } from "..";
import {
    ExpertClientFieldNameEnum as ClientFieldNameEnum,
    ExpertClientPartyFieldNameEnum as ClientPartyFieldNameEnum,
    ExpertEmployeeFieldNameEnum as EmployeeFieldNameEnum,
    ExpertMatterFieldNameEnum as MatterFieldNameEnum,
    ExpertMatterPartyFieldNameEnum as MatterPartyFieldNameEnum,
    ExpertVendorFieldNameEnum as VendorFieldNameEnum,
    ExpertPriorSearchFieldNameEnum as PriorSearchFieldNameEnum
} from "./ExpertFieldNameEnums";

export const DefaultEntityFlyoutConfigurationTemplates: EntityFlyoutConfigurationTemplate[] = [
    {
        entityName: "client",
        entityDisplayName: "Client",
        children: [
            {
                entityName: "matter",
                foreignKey: "clientId",
                fields: [
                    {
                        displayFormat: "Number",
                        propertyLabel: MatterFieldNameEnum.numberOfMatters,
                        propertyName: "active",
                        aggregateType: "count"
                    },
                    {
                        displayFormat: "Date",
                        propertyLabel: MatterFieldNameEnum.lastBillDate,
                        propertyName: "lastBillDate",
                        aggregateType: "max"
                    },
                    {
                        displayFormat: "Date",
                        propertyLabel: MatterFieldNameEnum.lastTimeEntryDate,
                        propertyName: "lastTimeEntryDate",
                        aggregateType: "max"
                    },
                    {
                        displayFormat: "Date",
                        propertyLabel: MatterFieldNameEnum.lastReceiptDate,
                        propertyName: "lastReceiptDate",
                        aggregateType: "max"
                    },
                    {
                        displayFormat: "Currency",
                        propertyLabel: MatterFieldNameEnum.wipTotal,
                        propertyName: "totalWIP",
                        symbol: "arCurrencySymbol",
                        decimalPlaces: "arCurrencyDecimalPlaces",
                        aggregateType: "sum"
                    },
                    {
                        displayFormat: "Currency",
                        propertyLabel: MatterFieldNameEnum.arTotal,
                        propertyName: "totalAR",
                        symbol: "arCurrencySymbol",
                        decimalPlaces: "arCurrencyDecimalPlaces",
                        aggregateType: "sum"
                    },
                    {
                        displayFormat: "Currency",
                        propertyLabel: MatterFieldNameEnum.lifeToDateBilled,
                        propertyName: "totalBilled",
                        symbol: "arCurrencySymbol",
                        decimalPlaces: "arCurrencyDecimalPlaces",
                        aggregateType: "sum"
                    }
                ]
            }
        ],
        fields: [
            {
                displayFormat: "String",
                propertyLabel: ClientFieldNameEnum.clientName,
                propertyName: "nameAndCode"
            },
            {
                displayFormat: "String",
                propertyLabel: ClientFieldNameEnum.clientDescription,
                propertyName: "note"
            },
            {
                displayFormat: "String",
                propertyLabel: ClientFieldNameEnum.clientStatus,
                propertyName: "status"
            },
            {
                displayFormat: "Date",
                propertyLabel: ClientFieldNameEnum.openDate,
                propertyName: "openDate"
            },
            {
                displayFormat: "User",
                propertyLabel: ClientFieldNameEnum.responsibleEmployee,
                userName: "responsibleEmployee",
                userId: "responsibleEmployeeId"
            },
            {
                displayFormat: "User",
                propertyLabel: ClientFieldNameEnum.assignedEmployee,
                userName: "assignedEmployee",
                userId: "assignedEmployeeId"
            },
            {
                displayFormat: "User",
                propertyLabel: ClientFieldNameEnum.billingEmployee,
                userName: "billingEmployee",
                userId: "billingEmployeeId"
            },
            {
                displayFormat: "String",
                propertyLabel: ClientFieldNameEnum.clientType,
                propertyName: "type"
            },
            //Connector pull date/Time
            {
                displayFormat: "DateTime",
                propertyLabel: ClientFieldNameEnum.lastUpdatedDateTime,
                propertyName: ""
            }
        ]
    },
    {
        entityName: "clientparty",
        entityDisplayName: "Client Party",
        parent: { entityName: "client", foreignKey: "clientId" },
        fields: [
            {
                displayFormat: "String",
                propertyLabel: ClientPartyFieldNameEnum.partyName,
                propertyName: "name"
            },
            {
                displayFormat: "String",
                propertyLabel: ClientPartyFieldNameEnum.affiliation,
                propertyName: "type"
            },
            {
                displayFormat: "String",
                propertyLabel: ClientPartyFieldNameEnum.partyStatus,
                propertyName: "partyStatus"
            },
            {
                displayFormat: "String",
                propertyLabel: ClientPartyFieldNameEnum.clientPartyStatus,
                propertyName: "status"
            },
            {
                displayFormat: "Date",
                propertyLabel: ClientPartyFieldNameEnum.dateAdded,
                propertyName: "startDate"
            },
            {
                displayFormat: "Date",
                propertyLabel: ClientPartyFieldNameEnum.dateClosed,
                propertyName: "endDate"
            },
            {
                displayFormat: "Boolean",
                propertyLabel: ClientPartyFieldNameEnum.conflictingParty,
                propertyName: "conflict"
            },
            {
                displayFormat: "String",
                propertyLabel: ClientPartyFieldNameEnum.conflictReason,
                propertyName: "conflictReason"
            }
        ]
    },
    {
        entityName: "employee",
        entityDisplayName: "Employee",
        fields: [
            {
                displayFormat: "String",
                propertyLabel: EmployeeFieldNameEnum.employeeName,
                propertyName: "nameAndCode"
            },
            {
                displayFormat: "String",
                propertyLabel: EmployeeFieldNameEnum.profitCenter,
                propertyName: "profitCenter"
            },
            {
                displayFormat: "String",
                propertyLabel: EmployeeFieldNameEnum.department,
                propertyName: "department"
            },
            {
                displayFormat: "String",
                propertyLabel: EmployeeFieldNameEnum.office,
                propertyName: "office"
            },
            {
                displayFormat: "String",
                propertyLabel: EmployeeFieldNameEnum.status,
                propertyName: "status"
            },
            {
                displayFormat: "Date",
                propertyLabel: EmployeeFieldNameEnum.hireDate,
                propertyName: "hireDate"
            },
            {
                displayFormat: "Date",
                propertyLabel: EmployeeFieldNameEnum.terminationDate,
                propertyName: "terminateDate"
            },
            {
                displayFormat: "String",
                propertyLabel: EmployeeFieldNameEnum.emailAddress,
                propertyName: "email"
            },
            //Connector Pull date and time.
            {
                displayFormat: "DateTime",
                propertyLabel: EmployeeFieldNameEnum.lastUpdatedDateTime,
                propertyName: "" // TODO check this
            }
        ]
    },
    {
        entityName: "matter",
        entityDisplayName: "Matter",
        fields: [
            {
                displayFormat: "String",
                propertyLabel: MatterFieldNameEnum.matterName,
                propertyName: "nameAndCode"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterFieldNameEnum.clientName,
                propertyName: "client"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterFieldNameEnum.matterDescription,
                propertyName: "longName"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterFieldNameEnum.matterComments,
                propertyName: "comments"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterFieldNameEnum.claimNumber,
                propertyName: "claimNumber"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterFieldNameEnum.matterStatus,
                propertyName: "status"
            },
            {
                displayFormat: "Date",
                propertyLabel: MatterFieldNameEnum.openDate,
                propertyName: "openDate"
            },
            {
                displayFormat: "User",
                propertyLabel: MatterFieldNameEnum.responsibleEmployee,
                userId: "responsibleEmployeeId",
                userName: "responsibleEmployee"
            },
            {
                displayFormat: "User",
                propertyLabel: MatterFieldNameEnum.assignedEmployee,
                userId: "assignedEmployeeId",
                userName: "assignedEmployee"
            },
            {
                displayFormat: "User",
                propertyLabel: MatterFieldNameEnum.billingEmployee,
                userId: "billingEmployeeId",
                userName: "billingEmployee"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterFieldNameEnum.matterType,
                propertyName: "type"
            },
            {
                displayFormat: "Date",
                propertyLabel: MatterFieldNameEnum.lastBillDate,
                propertyName: "lastBillDate"
            },
            {
                displayFormat: "Date",
                propertyLabel: MatterFieldNameEnum.lastTimeEntryDate,
                propertyName: "lastTimeEntryDate"
            },
            {
                displayFormat: "Date",
                propertyLabel: MatterFieldNameEnum.lastReceiptDate,
                propertyName: "lastReceiptDate"
            },
            {
                displayFormat: "Currency",
                propertyLabel: MatterFieldNameEnum.wipTotal,
                propertyName: "totalWIP",
                symbol: "arCurrencySymbol",
                decimalPlaces: "arCurrencyDecimalPlaces"
            },
            {
                displayFormat: "Currency",
                propertyLabel: MatterFieldNameEnum.arTotal,
                propertyName: "totalAR",
                symbol: "arCurrencySymbol",
                decimalPlaces: "arCurrencyDecimalPlaces"
            },
            {
                displayFormat: "Currency",
                propertyLabel: MatterFieldNameEnum.lifeToDateBilled,
                propertyName: "totalBilled",
                symbol: "arCurrencySymbol",
                decimalPlaces: "arCurrencyDecimalPlaces"
            },
            //From Connector
            {
                displayFormat: "DateTime",
                propertyLabel: MatterFieldNameEnum.lastUpdatedDateTime,
                propertyName: ""
            }
        ]
    },
    {
        entityName: "matterparty",
        entityDisplayName: "Matter Party",
        parent: { entityName: "matter", foreignKey: "matterId" },
        fields: [
            {
                displayFormat: "String",
                propertyLabel: MatterPartyFieldNameEnum.partyName,
                propertyName: "name"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterPartyFieldNameEnum.affiliation,
                propertyName: "type"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterPartyFieldNameEnum.partyStatus,
                propertyName: "partyStatus"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterPartyFieldNameEnum.statusOfMatterParty,
                propertyName: "status"
            },
            {
                displayFormat: "Date",
                propertyLabel: MatterPartyFieldNameEnum.dateAdded,
                propertyName: "startDate"
            },
            {
                displayFormat: "Date",
                propertyLabel: MatterPartyFieldNameEnum.dateClosed,
                propertyName: "endDate"
            },
            {
                displayFormat: "Boolean",
                propertyLabel: MatterPartyFieldNameEnum.conflictingParty,
                propertyName: "conflict"
            },
            {
                displayFormat: "String",
                propertyLabel: MatterPartyFieldNameEnum.conflictReason,
                propertyName: "conflictReason"
            }
        ]
    },
    {
        entityName: "vendor",
        entityDisplayName: "Vendor",
        fields: [
            {
                displayFormat: "String",
                propertyLabel: VendorFieldNameEnum.vendorName,
                propertyName: "nameAndCode"
            },
            {
                displayFormat: "String",
                propertyLabel: VendorFieldNameEnum.status,
                propertyName: "status"
            },
            {
                displayFormat: "String",
                propertyLabel: VendorFieldNameEnum.address,
                propertyName: "address"
            },
            {
                displayFormat: "String",
                propertyLabel: VendorFieldNameEnum.parentVendor,
                propertyName: "parentVendor"
            },
            //From connector pull schedule
            {
                displayFormat: "DateTime",
                propertyLabel: VendorFieldNameEnum.lastUpdatedDateTime,
                propertyName: ""
            }
        ]
    },
    {
        entityName: "priorsearch",
        entityDisplayName: "Prior Search",
        fields: [
            {
                displayFormat: "String",
                propertyLabel: PriorSearchFieldNameEnum.searchName,
                propertyName: "name"
            },
            {
                displayFormat: "String",
                propertyLabel: PriorSearchFieldNameEnum.description,
                propertyName: "description"
            },
            {
                displayFormat: "User",
                propertyLabel: PriorSearchFieldNameEnum.requestedBy,
                userId: "requestedByUserId",
                userName: "requestedByUserName"
            },
            {
                displayFormat: "Date",
                propertyLabel: PriorSearchFieldNameEnum.dateRequested,
                propertyName: "dateRequested"
            },
            {
                displayFormat: "Date",
                propertyLabel: PriorSearchFieldNameEnum.lastActivityDate,
                propertyName: "lastModified"
            },
            {
                displayFormat: "String",
                propertyLabel: PriorSearchFieldNameEnum.searchStatus,
                propertyName: "status"
            },
            {
                displayFormat: "String",
                propertyLabel: PriorSearchFieldNameEnum.latestStatusComment,
                propertyName: "latestStatusComment"
            }
        ]
    }
];
