import React from "react";
import { FirmSettings, PermissionsContextDirect } from "aderant-conflicts-models";
import { FirmSettingsSection, FirmSettingsSectionComponent } from "./FirmSettingsSection";
import { SynonymMapGrid } from "./SynonymMapSection/SynonymMapGrid";
import { ApiKeyComponent } from "./ApiKeySection/ApiKeyComponent";
import { InformationBarriersComponent } from "./InformationBarriersSection/InformationBarriersComponent";
import { YesNoField } from "components/DynamicFields/EditableFields/YesNoField";
import { ExpertIntegrationSection } from "./IntegrationSection/ExpertIntegrationSection";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildPage(
    pageDefinition: FirmSettings.PageDefinition,
    onSectionChange: (pageName: string, sectionName: string, newData: Record<string, any>) => void,
    permissions: PermissionsContextDirect
): FirmSettingsSection[] {
    const sections: FirmSettingsSection[] = [];
    //Check all page data is loaded, before displaying data
    for (const section of pageDefinition.sections) {
        if (section.internalUsersOnly) {
            permissions.currentUserIsAderantUser() && sections.push(buildFirmSettingsSection(pageDefinition, section, (newData) => onSectionChange(pageDefinition.name, section.name, newData)));
        } else {
            sections.push(buildFirmSettingsSection(pageDefinition, section, (newData) => onSectionChange(pageDefinition.name, section.name, newData)));
        }
    }
    return sections;
}

export function buildFirmSettingsSection(
    pageDefinition: FirmSettings.PageDefinition,
    sectionDefinition: FirmSettings.SectionDefinition,
    onSectionChange: (newData: Record<string, any>) => void
): FirmSettingsSection {
    switch (sectionDefinition.type) {
        case "basic": {
            return buildBasicFirmSettingsSection(pageDefinition, sectionDefinition, onSectionChange);
        }
        case "handwritten": {
            return buildHandwrittenFirmSettingsSection(pageDefinition, sectionDefinition, onSectionChange);
        }
    }
}

function buildHandwrittenFirmSettingsSection(
    pageDefinition: FirmSettings.PageDefinition,
    sectionDefinition: FirmSettings.HandwrittenSectionDefinition,
    onSectionChange: (newData: FirmSettings.HandwrittenSectionDefinition["defaultValue"]) => void
): FirmSettingsSection {
    return {
        id: { pageName: pageDefinition.name, sectionName: sectionDefinition.name },
        sectionType: sectionDefinition.type,
        displayName: sectionDefinition.name,
        component: getComponentForHandwrittenSection(sectionDefinition),
        onSectionChange: onSectionChange
    };
}

function getComponentForHandwrittenSection<Section extends FirmSettings.HandwrittenSectionDefinition>(sectionDefinition: Section): FirmSettingsSectionComponent<Section["defaultValue"]> {
    //It is VERY important to return the correct type from this function.  You MUST return only the component, like we do here.  If it is wrapped in a function,
    //React is unable to resolve the type of the component, instead parsing it as an Anonymous component, which plays havoc with React state management when loaded.
    switch (sectionDefinition.name) {
        case "api-key-management": {
            return ApiKeyComponent;
        }
        case "synonym-management": {
            return SynonymMapGrid;
        }
        case "information-barriers": {
            return InformationBarriersComponent;
        }
        case "expert-integration": {
            return ExpertIntegrationSection;
        }
    }
}

function buildBasicFirmSettingsSection(
    pageDefinition: FirmSettings.PageDefinition,
    sectionDefinition: FirmSettings.BasicSectionDefinition,
    onSectionChange: (newData: Record<string, any>) => void
): FirmSettingsSection {
    return {
        id: { pageName: pageDefinition.name, sectionName: sectionDefinition.name },
        sectionType: sectionDefinition.type,
        displayName: sectionDefinition.name,
        component: createComponentForBasicSection(sectionDefinition),
        onSectionChange: onSectionChange
    };
}

// TODO
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createComponentForBasicSection(sectionDefinition: FirmSettings.BasicSectionDefinition): FirmSettingsSectionComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (props: { data: Record<string, any>; onSectionChange: (newData: Record<string, any>) => void; isPagePersisting: boolean }) => {
        // if (sectionDefinition.fields.length === 0) {
        //     return null;
        // }

        const fields: JSX.Element[] = [];
        for (const field of sectionDefinition.fields) {
            //Typescript won't let you use map on a type like [a] | [b] | [c], because the func being passed in to the map call can't accept a union with no commonality... or something.
            //We want to merge the changes made to a field along with the entire section data here.
            //This is so that onSectionChange has the complete set of updated data within that section
            fields.push(createField(field, props.data[field.name], (value) => props.onSectionChange({ ...props.data, ...value }), props.isPagePersisting));
        }

        return (
            <div key={sectionDefinition.name} style={{ margin: "0.313rem", paddingBottom: "3.031rem" }}>
                <div style={{ marginTop: "3.031rem", marginLeft: "3.266rem", fontSize: 18, fontWeight: 600 }}>{FirmSettings.getDefinitionDisplayName(sectionDefinition)}</div>
                <div style={{ marginLeft: "1.326rem" }}>{fields}</div>
            </div>
        );
    };
}

function createField(field: FirmSettings.FieldDefinition, data: any, onFieldChange: (newData: Record<string, any>) => void, isPagePersisting: boolean): JSX.Element {
    switch (field.type) {
        case "boolean": {
            return YesNoField({ field: field, data: data, onChange: (checked: boolean) => onFieldChange({ [field.name]: checked }), isPagePersisting: isPagePersisting });
        }
        //These can be uncommented when implementation is required
        // case "string": {
        //     return TextInputField({ field: field, data: data, onChange: onChange });
        // }
        // case "integer": {
        //     return IntegerField({field: field, data: data, onChange: onChange});
        // }
        // case "options": {
        //     return OptionsField({field: field, data: props.data, onChange: props.onChange});
        // }
    }
}
