import { Location, Action, History } from "history";
import { MutableRefObject } from "react";

export const navigateWithoutPrompt = ({ location, action }: { location: Location; action: Action }, history: History<unknown>, shouldNavigateAway: MutableRefObject<boolean>): void => {
    shouldNavigateAway.current = true;
    switch (action) {
        case "POP":
            history.goBack();
            return;
        case "REPLACE":
            history.replace(location);
            break;
        case "PUSH":
            history.push(location);
            break;
        default:
            history.push(location);
    }
    shouldNavigateAway.current = false;
};
