import { AccountInfo } from "@azure/msal-browser";
import { ProductEnvironment, getAuthTenancy, getProductEnvironments } from "aderant-conflicts-common";
import { ok } from "aderant-conflicts-models";

export function getAuthorisedSubscriptions(currentAccount: AccountInfo | undefined): ProductEnvironment[] {
    if (!currentAccount || !currentAccount.idTokenClaims) {
        return [];
    }
    const authTenancy = getAuthTenancy(currentAccount.idTokenClaims);
    if (!ok(authTenancy)) {
        return [];
    }
    const productEnvironments = getProductEnvironments(currentAccount.idTokenClaims, authTenancy);
    if (!ok(productEnvironments)) {
        return [];
    }
    return productEnvironments;
}
