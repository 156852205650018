import { Backdrop, CircularProgress } from "@mui/material";
import { ConflictsAction, makeEditable, SearchVersion, SearchVersionNew, userCanEditSearch } from "aderant-conflicts-models";
import { Button, MessageDialog } from "@aderant/aderant-react-components";
import { Logger } from "aderant-web-fw-core";
import { Action, Location } from "history";
import { RootState } from "MyTypes";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { SaveSearchActionTypes, searchActions } from "state/actions";
import { getIsFetchingCurrentSearch, getIsSearching, getIsSearchLoaded, getCurrentSearchVersion } from "state/selectors";
import { conflictsStyles } from "styles/conflictsStyles";
import { usePermissionsContext } from "../../state/selectors/appSelectors";
import { Messages } from "./Messages";
import SearchEditPage, { SearchEditPageDesktopProps } from "./SearchEditPage";

export default function NewSearchVersionEditPageContainer(props: { logger: Logger }): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const searchVersion: SearchVersion | SearchVersionNew | undefined = useSelector((rootState: RootState) => getCurrentSearchVersion(rootState), shallowEqual);
    const isCurrentSearchLoaded: boolean = useSelector((rootState: RootState) => getIsSearchLoaded(rootState.search, id));
    const isFetchingCurrentSearch: boolean = useSelector((rootState: RootState) => getIsFetchingCurrentSearch(rootState, id));
    const isSearching: boolean = useSelector(getIsSearching);
    const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] = useState(false);
    const history = useHistory();
    const shouldNavigateAway = useRef(false);
    const navLocationAction = useRef<{ location: Location; action: Action }>({ location: history.location, action: history.action });
    const permissions = usePermissionsContext();
    const suppressUnsavedDataHandling = useRef(false);

    const initialSearchVersion = useRef(searchVersion);

    useEffect(() => {
        //The searchVersion is initially undefined while it is waiting for getCurrentSearch to complete.
        initialSearchVersion.current === undefined && (initialSearchVersion.current = searchVersion);
    }, [searchVersion]);

    useEffect(() => {
        if (!isCurrentSearchLoaded && !isFetchingCurrentSearch) {
            dispatch(searchActions.fetchLatestSearchVersion({ searchId: id, fetchHitResults: false }));
        }
    }, [id]);

    const searchAction = (searchVersion, history, updateIsSearchingWhenDone) => {
        if (!searchVersion || searchVersion.editState !== "UNSAVED") {
            return;
        }
        if (userCanEditSearch(searchVersion, permissions.currentUserId)) {
            if (permissions?.currentUserHasPermission(ConflictsAction.PerformSearch)) {
                dispatch(searchActions.createAndPerformNewVersion({ searchVersion, history, updateIsSearchingWhenDone }));
            } else {
                dispatch(searchActions.submitSearch({ searchVersion, history }));
            }
        }
    };

    const addTermAndThenPeformSearchAction = (searchVersion, history, requestTerm, updateIsSearchingWhenDone) => {
        if (!searchVersion) {
            return;
        }

        if (userCanEditSearch(searchVersion, permissions.currentUserId)) {
            dispatch(searchActions.saveSearch({ saveSearchActionInput: { actionType: SaveSearchActionTypes.ADD_TERM, payload: { searchVersion, term: requestTerm } }, doNotPersist: true }));
            if (permissions?.currentUserHasPermission(ConflictsAction.PerformSearch)) {
                dispatch(searchActions.createAndPerformNewVersion({ searchVersion, history, updateIsSearchingWhenDone }));
            } else {
                dispatch(searchActions.submitSearch({ searchVersion, history }));
            }
        }
    };

    const onNavigateAway: (location: Location, action: Action) => boolean = (location: Location, action: Action) => {
        if (location.pathname.endsWith(`/results/${searchVersion?.searchId}`) || location.pathname.endsWith(`/search-requests/`) || shouldNavigateAway.current) {
            return true;
        } else {
            navLocationAction.current = { location, action };
            initialSearchVersion.current && dispatch(searchActions.updateSearchSummaries(initialSearchVersion.current)); // resetting to persisted state if unsaved changes are cancelled
            setIsDiscardChangesDialogOpen(true);
            return false;
        }
    };

    const navigateWithoutPrompt = ({ location, action }: { location: Location; action: Action }) => {
        shouldNavigateAway.current = true;
        switch (action) {
            case "POP":
                history.goBack();
                return;
            case "REPLACE":
                history.replace(location);
                break;
            case "PUSH":
                history.push(location);
                break;
            default:
                history.push(location);
        }
        shouldNavigateAway.current = false;
    };

    const searchEditPageProps: SearchEditPageDesktopProps = {
        searchVersion: searchVersion ? makeEditable(searchVersion) : searchVersion,
        logger: props.logger,
        pageDetails: {
            pageTitle: Messages.NEW_SEARCH_VERSION_PAGE_TITLE.getMessage(),
            isNewVersion: true,
            searchAction: searchAction,
            addTermThenPerformSearchAction: addTermAndThenPeformSearchAction,
            suppressUnsavedDataHandling: suppressUnsavedDataHandling
        }
    };

    return (
        <>
            <SearchEditPage {...searchEditPageProps}>
                <Prompt when={true} message={onNavigateAway} />
                <MessageDialog
                    open={isDiscardChangesDialogOpen}
                    onClose={() => setIsDiscardChangesDialogOpen(false)}
                    message={Messages.DISCARD_CHANGES_PROMPT.getMessage()}
                    title={Messages.DISCARD_CHANGES_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text="Cancel"
                                color="secondary"
                                onClick={() => {
                                    setIsDiscardChangesDialogOpen(false);
                                }}
                            />
                            <Button
                                text="Leave"
                                onClick={() => {
                                    dispatch(searchActions.clearCurrentSearch()); //So the changes here do not persist if the page is opened again immediately.
                                    navigateWithoutPrompt(navLocationAction.current);
                                    setIsDiscardChangesDialogOpen(false);
                                }}
                            />
                        </>
                    }
                />
            </SearchEditPage>
            <Backdrop style={conflictsStyles.backdropStyling} open={isSearching || isFetchingCurrentSearch}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
