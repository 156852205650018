import { ConflictsAction, isIncompleteSearchedStatus, QuickSearch, SearchStatuses, SearchVersion, SearchVersionNew, userCanEditSearch } from "aderant-conflicts-models";
import { usePermissionsContext } from "state/selectors";
import { Messages } from "./Messages";

export interface SearchEditPageState {
    pageFooterAction: "SEARCH" | "SUBMIT" | "DISABLED";
    pageTitle: (searchVersion?: SearchVersion | SearchVersionNew) => string;
    pageFooterButtonText: string;
    isPageFooterButtonEnabled: boolean;
    isReadOnly: boolean;
    isFuzzySearchButtonVisible: boolean;
}

const SearchableSubmitted: SearchEditPageState = {
    pageFooterAction: "SEARCH",
    pageTitle: (searchVersion?: SearchVersion | SearchVersionNew) => `${Messages.EXISTING_SEARCH_REQUEST_TITLE.getMessage()} ${searchVersion?.version || ""}`,
    pageFooterButtonText: Messages.SEARCH.getMessage(),
    isPageFooterButtonEnabled: true,
    isReadOnly: false,
    isFuzzySearchButtonVisible: true
};

const NonSearchableSubmitted: SearchEditPageState = {
    pageFooterAction: "DISABLED",
    pageTitle: (searchVersion?: SearchVersion | SearchVersionNew) =>
        `${searchVersion && searchVersion.version > 0 ? Messages.NEW_SEARCH_VERSION_PAGE_TITLE.getMessage() : Messages.EXISTING_SEARCH_REQUEST_TITLE.getMessage()} ${searchVersion?.version || ""}`,
    pageFooterButtonText: Messages.SEARCH.getMessage(),
    isPageFooterButtonEnabled: false,
    isReadOnly: false,
    isFuzzySearchButtonVisible: false
};

const SubmittedReadOnly: SearchEditPageState = {
    pageFooterAction: "DISABLED",
    pageTitle: (searchVersion?: SearchVersion | SearchVersionNew) => `${Messages.EXISTING_SEARCH_REQUEST_TITLE.getMessage()} ${searchVersion?.version || ""}`,
    pageFooterButtonText: Messages.SEARCH.getMessage(),
    isPageFooterButtonEnabled: false,
    isReadOnly: true,
    isFuzzySearchButtonVisible: false
};

const SubmittableState: SearchEditPageState = {
    pageFooterAction: "SUBMIT",
    pageTitle: () => Messages.NEW_SEARCH_REQUEST_PAGE_TITLE.getMessage(),
    pageFooterButtonText: Messages.SUBMIT.getMessage(),
    isPageFooterButtonEnabled: true,
    isReadOnly: false,
    isFuzzySearchButtonVisible: false
};

const SearchableDraftState: SearchEditPageState = {
    pageFooterAction: "SEARCH",
    pageTitle: () => Messages.NEW_SEARCH_REQUEST_PAGE_TITLE.getMessage(),
    pageFooterButtonText: Messages.SEARCH.getMessage(),
    isPageFooterButtonEnabled: true,
    isReadOnly: false,
    isFuzzySearchButtonVisible: true
};

const UneditableState: SearchEditPageState = {
    pageFooterAction: "DISABLED",
    pageTitle: () => Messages.NEW_SEARCH_REQUEST_PAGE_TITLE.getMessage(),
    pageFooterButtonText: Messages.SEARCH.getMessage(),
    isPageFooterButtonEnabled: true,
    isReadOnly: false,
    isFuzzySearchButtonVisible: true
};

const quickSearchState: SearchEditPageState = {
    pageFooterAction: "SEARCH",
    pageTitle: () => Messages.NEW_QUICK_SEARCH_PAGE_TITLE.getMessage(),
    pageFooterButtonText: Messages.SEARCH.getMessage(),
    isPageFooterButtonEnabled: true,
    isReadOnly: false,
    isFuzzySearchButtonVisible: false
};

export const getSearchEditPageState = (searchVersion: SearchVersion | SearchVersionNew | QuickSearch | undefined) => {
    const permissions = usePermissionsContext();
    const currentUserCanPerformSearch = permissions?.currentUserHasPermission(ConflictsAction.PerformSearch);

    if (!searchVersion || searchVersion.status === SearchStatuses.Searching) {
        return UneditableState;
    } else if (searchVersion.isQuickSearch) {
        return quickSearchState;
    } else if (searchVersion.status === SearchStatuses.Draft && currentUserCanPerformSearch) {
        return SearchableDraftState;
    } else if (searchVersion.status === SearchStatuses.Draft) {
        return SubmittableState;
    } else if (isIncompleteSearchedStatus(searchVersion.status) && !currentUserCanPerformSearch) {
        return SubmittableState; //Non conflicts users can submit new versions.
    } else if (userCanEditSearch(searchVersion, permissions.currentUserId)) {
        if (currentUserCanPerformSearch) {
            return SearchableSubmitted;
        }
        return NonSearchableSubmitted;
    } else {
        return SubmittedReadOnly;
    }
};
