import { Logger } from "aderant-web-fw-core";
import { EnvironmentService } from "../../..";
import { AzureFunctionDefinition } from "../../AzureFunctionDefinition/AzureFunctionDefinition";
import { TokenAuthContext } from "../../ConflictsContext";
import { OkResponse } from "../../Http/HttpResponse";

export type UserResponse = {
    displayName: string;
    id: string;
    /**
     * For most accounts this is an internal email for User Management that you probably don't want.
     */
    userEmail: string;
    /**
     * For accounts invited to User Management from a separate idp this will be their "real" home idp email.
     * Accounts that only exist on user management and weren't invited from elsewhere will not have one of these.
     */
    alternateEmail?: string;
    userApplications: { applicationName: string; roles: { value: string }[] | null }[];
};

export const UserManagement = {
    getUsers: new AzureFunctionDefinition<
        { tenancyId: string }, //     Input type
        UserResponse[], //                   Output type
        OkResponse<UserResponse[]>, //       Possible Http Response codes of Output (union for multiple)
        never //    Possible error types (union for multiple)
    >({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: (input: { tenancyId: string }) => `./Tenants/ListUsers?tenantId=${input.tenancyId}`
    })
};

/**Note: unlike the rest of the AzureFunctionDefinition surfaces this is *not* one of the internal apis that solely exists in the Conflicts repo.
 * It's been wrapped like this regardless because it uses the same UserManagement auth as everything else, and is a fairly standard HTTP API.
 * We might find that we need to do something a bit more non standard with it at some point, and this approach may no longer be applicable,
 * but for now it's nice as it means less code.
 */
export type UserManagementProxy = ReturnType<typeof getUserManagementProxy>;

/**Note: unlike the rest of the AzureFunctionDefinition surfaces this is *not* one of the internal apis that solely exists in the Conflicts repo.
 * It's been wrapped like this regardless because it uses the same UserManagement auth as everything else, and is a fairly standard HTTP API.
 * We might find that we need to do something a bit more non standard with it at some point, and this approach may no longer be applicable,
 * but for now it's nice as it means less code.
 */
export function getUserManagementProxy(context: TokenAuthContext | string, logger: Logger) {
    //Context can be TokenAuthContext or a Bearer token
    if (typeof context === "string") {
        return {
            getUsers: UserManagement.getUsers.getProxy(new URL(EnvironmentService.getApiUrls().userManagement), context, logger)
        };
    }
    return {
        getUsers: UserManagement.getUsers.getProxy(new URL(EnvironmentService.getApiUrls().userManagement), context)
    };
}
