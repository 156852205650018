import { Grid } from "@mui/material";
import { SearchMessages, User } from "aderant-conflicts-models";
import { AderantTheme, Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, InputText } from "@aderant/aderant-react-components";
import { AssignedToUserPickList } from "components/AssignedToUserPickList/AssignedToUserPickList";
import { messages } from "pages/SearchEditPage/SearchUtils";
import React, { useEffect, useState } from "react";
import { conflictsPalette } from "styles/conflictsPalette";
import { isMessageInvalid } from "./ReassignUser";

export interface ReassignUserModalProps {
    open: boolean;
    onCancel: () => void;
    onAccept: (message: string, assignedToUserId?: string | null) => void;
    assignedToUserId?: string | null;
}

export function ReassignUserModal(props: ReassignUserModalProps): JSX.Element {
    const [assignedToUserId, setAssignedToUserId] = useState<string | null | undefined>(props.assignedToUserId);
    const [message, setMessage] = useState<string>("");

    const onAccept = () => {
        const msg = message;
        setMessage("");
        props.onAccept(msg, assignedToUserId);
    };

    const onCancel = () => {
        setMessage("");
        props.onCancel();
    };

    useEffect(() => {
        setAssignedToUserId(props.assignedToUserId);
    }, [props.assignedToUserId]);

    return (
        <Dialog open={props.open} onClose={props.onCancel} size="sm">
            <DialogTitle onClose={props.onCancel}>
                <DialogHeader>{messages.reassignUser}</DialogHeader>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: AderantTheme.typography.pxToRem(14) }}>{messages.areYouSureYouWantToReassign}</div>
                    </Grid>
                    <Grid container item style={{ background: "white", border: `1px solid ${conflictsPalette.border}`, padding: "25px 10px" }}>
                        <Grid item xs={12}>
                            <AssignedToUserPickList
                                label={SearchMessages.ASSIGNED_TO.getMessage()}
                                style={{ flex: "1 1 45%" }}
                                onUserChanged={(user?: User | null) => {
                                    setAssignedToUserId(user?.id);
                                    setMessage("");
                                }}
                                selectedUserId={assignedToUserId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputText
                                label={messages.message}
                                value={message}
                                multiLine
                                hasError={isMessageInvalid(message)}
                                maxLines={6}
                                errorMessage={isMessageInvalid(message) ? messages.messageTooLong : ""}
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onChange={(event: any, value?: string | null) => setMessage(value || "")}
                                placeholder={assignedToUserId ? messages.enterMessageToBeEmailedOnReassign : messages.enterMessageToBeSavedToAudit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogFooter>
                <Grid container>
                    <Grid item xs={12}>
                        <p style={{ textAlign: "end", fontSize: "12px" }}>{messages.reassignWarnThatUserCannotEditAfter}</p>
                    </Grid>
                    <Grid container item xs={12} justifyContent={"flex-end"} spacing={1}>
                        <Grid item xs={"auto"}>
                            <Button text={messages.cancel} variant={"outlined"} onClick={onCancel} size="medium" style={{ marginRight: "15px" }} />
                        </Grid>
                        <Grid item xs={"auto"}>
                            <Button disabled={isMessageInvalid(message)} text={messages.yes} onClick={onAccept} size="medium" />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogFooter>
        </Dialog>
    );
}
