import { SearchSummary } from "aderant-conflicts-models";
import { useCallback, useEffect, useRef } from "react";

export function usePolling(data: SearchSummary[] | number[], pollingFunction: (data: SearchSummary[] | number[]) => void, interval: number, maxAttempts: number, shouldContinueToPoll: boolean): void {
    const attemptsRef = useRef(0);
    const polling = useRef<NodeJS.Timeout>();

    //Ensure pollingAction is up to date with the latest pollingFunction
    const pollingActionRef = useRef(pollingFunction);
    useEffect(() => {
        pollingActionRef.current = pollingFunction;
    }, [pollingFunction]);

    const dataRef = useRef(data);
    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    const poll = useCallback(() => {
        if (shouldContinueToPoll && attemptsRef.current < maxAttempts) {
            //console.debug("Polling for searching summaries");
            // eslint-disable-next-line no-inner-declarations
            pollingActionRef.current(dataRef.current);
            attemptsRef.current += 1;
        }
    }, [maxAttempts, shouldContinueToPoll]);

    useEffect(() => {
        if (polling?.current) {
            clearInterval(polling.current);
            polling.current = undefined;
            attemptsRef.current = 0;
        }
        polling.current = setInterval(poll, interval);
    }, [interval, poll]);

    useEffect(() => {
        return () => {
            if (polling.current) {
                clearInterval(polling.current);
                polling.current = undefined;
                attemptsRef.current = 0;
            }
        };
    }, []);
}
