import { CognitiveSearchSecrets } from "aderant-conflicts-models";

export class AzureSettings {
    constructor(private connectionDetails: CognitiveSearchSecrets) {}

    getBaseUrl(): string {
        return `https://${this.connectionDetails.name}.search.windows.net/`;
    }

    getIndexesUrl(): string {
        return `${this.getBaseUrl()}indexes/`;
    }

    getQueryApiKey(): string {
        const key = this.connectionDetails.queryKey;
        if (!key) {
            throw new Error(`Search query API key was not retrieved from the key vault.`);
        }
        return key;
    }

    getAdminApiKey(): string {
        const key = this.connectionDetails.adminKey;
        if (!key) {
            throw new Error(`Search admin API key was not retrieved from the key vault.`);
        }
        return key;
    }

    getSearchServiceName(): string | undefined {
        const key = this.connectionDetails.name;
        if (!key) {
            throw new Error(`Search service name was not retrieved from the key vault.`);
        }
        return key;
    }

    getApiVersion(): string {
        return "2020-06-30";
    }
}
