import {
    Audit,
    RequestTerm,
    RequestTermAddAudit,
    RequestTermRemoveAudit,
    SearchDetailsUpdateAudit,
    SearchErrorStatusChangeAudit,
    SearchPerformAudit,
    SearchReassignAudit,
    SearchSubmitAudit,
    SearchTermAddAudit,
    SearchTermRemoveAudit,
    SearchTermUpdate,
    SearchVersionEdited,
    SearchVersionNew,
    SearchVersionUnedited,
    unexpectedError
} from "..";
import { SearchStatuses } from "../SearchVersion";

export function generateDetailsAudits(oldSearch: SearchVersionUnedited, newSearch: SearchVersionEdited, currentUserId: string): Audit[] {
    const audits: Audit[] = [];
    if (newSearch.name !== oldSearch.name) {
        const updatedNameAudit: SearchDetailsUpdateAudit = {
            actionType: "SEARCHDETAILSUPDATE",
            searchId: newSearch.searchId,
            searchVersionNumber: newSearch.version,
            performedByUserId: currentUserId,
            searchDocumentType: "Audit",
            createdDate: new Date(),
            name: newSearch.name
        };
        audits.push(updatedNameAudit);
    }

    if (newSearch.description !== oldSearch.description) {
        const updatedDescAudit: SearchDetailsUpdateAudit = {
            actionType: "SEARCHDETAILSUPDATE",
            searchId: newSearch.searchId,
            searchVersionNumber: newSearch.version,
            performedByUserId: currentUserId,
            searchDocumentType: "Audit",
            createdDate: new Date(),
            description: newSearch.description
        };
        audits.push(updatedDescAudit);
    }

    if (newSearch.approverUserId !== oldSearch.approverUserId) {
        const updatedApproverAudit: SearchDetailsUpdateAudit = {
            actionType: "SEARCHDETAILSUPDATE",
            searchId: newSearch.searchId,
            searchVersionNumber: newSearch.version,
            performedByUserId: currentUserId,
            searchDocumentType: "Audit",
            createdDate: new Date(),
            originalApproverUserId: oldSearch.approverUserId ?? undefined,
            newApproverUserId: newSearch.approverUserId
        };
        audits.push(updatedApproverAudit);
    }
    return audits;
}

export function generateTermAudits(oldSearch: SearchVersionUnedited, newSearch: SearchVersionEdited, currentUserId: string): Audit[] {
    const removedRequestTerms: RequestTerm[] = [];
    const addedSearchTerms: SearchTermUpdate[] = [];
    const removedSearchTerms: SearchTermUpdate[] = [];
    const addedRequestTerms: RequestTerm[] =
        newSearch.requestTerms.filter((newRequestTerm) => {
            return !oldSearch.requestTerms.some((oldRequestTerm) => {
                return newRequestTerm.id === oldRequestTerm.id;
            });
        }) || [];

    // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const newRequestTermMap = new Map<string, RequestTerm>(newSearch.requestTerms.map((requestTerm) => [requestTerm.id, requestTerm] as [string, RequestTerm]));
    oldSearch.requestTerms.forEach((requestTerm) => {
        const newRequestTermExists = newRequestTermMap.get(requestTerm.id!); // TODO: both searches are persisted so should not be undefined BUG 2236
        if (newRequestTermExists) {
            if (newRequestTermExists.term !== requestTerm.term) {
                addedRequestTerms.push(newRequestTermExists);
                removedRequestTerms.push({ ...requestTerm, hits: [] });
            }
            const oldSearchTermSet = new Set(requestTerm.searchTerms);
            const newSearchTermSet = new Set(newRequestTermExists.searchTerms);

            const removedSearchTermCheck: string[] = requestTerm.searchTerms.filter((searchTerm) => !newSearchTermSet.has(searchTerm));
            if (removedSearchTermCheck.length > 0) {
                removedSearchTerms.push({ requestTerm: { id: requestTerm.id || "", term: requestTerm.term }, searchTerms: removedSearchTermCheck });
            }

            const addedSearchTermCheck: string[] = newRequestTermExists.searchTerms.filter((searchTerm) => !oldSearchTermSet.has(searchTerm));
            if (addedSearchTermCheck.length > 0) {
                addedSearchTerms.push({ requestTerm: { id: requestTerm.id || "", term: requestTerm.term }, searchTerms: addedSearchTermCheck });
            }
        } else {
            removedRequestTerms.push({ ...requestTerm, hits: [] });
        }
    });

    const addedRequestTermsWithoutHits = addedRequestTerms.map((requestTerm) => {
        return { ...requestTerm, hits: [] };
    });
    const searchId = newSearch.searchId;
    const audits: Audit[] = [];

    if (removedSearchTerms.length > 0) {
        const searchTermRemovedAudit: SearchTermRemoveAudit = {
            actionType: "SEARCHTERMREMOVE",
            searchId: searchId,
            performedByUserId: currentUserId,
            searchVersionNumber: newSearch.version,
            searchDocumentType: "Audit",
            terms: removedSearchTerms,
            createdDate: new Date()
        };
        audits.push(searchTermRemovedAudit);
    }

    if (addedSearchTerms.length > 0) {
        const searchTermAddedAudit: SearchTermAddAudit = {
            actionType: "SEARCHTERMADD",
            searchId: searchId,
            performedByUserId: currentUserId,
            searchVersionNumber: newSearch.version,
            searchDocumentType: "Audit",
            terms: addedSearchTerms,
            createdDate: new Date()
        };
        audits.push(searchTermAddedAudit);
    }

    if (removedRequestTerms.length > 0) {
        const requestTermRemovedAudit: RequestTermRemoveAudit = {
            actionType: "REQUESTTERMREMOVE",
            searchId: searchId,
            performedByUserId: currentUserId,
            searchVersionNumber: newSearch.version,
            searchDocumentType: "Audit",
            requestTerms: removedRequestTerms,
            createdDate: new Date()
        };
        audits.push(requestTermRemovedAudit);
    }

    if (addedRequestTerms.length > 0) {
        const requestTermAddedAudit: RequestTermAddAudit = {
            actionType: "REQUESTTERMADD",
            searchId: searchId,
            performedByUserId: currentUserId,
            searchVersionNumber: newSearch.version,
            searchDocumentType: "Audit",
            requestTerms: addedRequestTermsWithoutHits,
            createdDate: new Date()
        };
        audits.push(requestTermAddedAudit);
    }

    return audits;
}

export function generateSaveAudits(originalSearch: SearchVersionUnedited, updatedSearch: SearchVersionNew | SearchVersionEdited, currentUserId: string, reassignMessage?: string): Audit[] {
    const audits: Audit[] = [];
    if (originalSearch.assignedToUserId != null && updatedSearch.assignedToUserId == null) {
        const unassignAudit: SearchReassignAudit = {
            searchId: updatedSearch.searchId,
            searchVersionNumber: updatedSearch.version,
            performedByUserId: currentUserId,
            createdDate: new Date(),
            searchDocumentType: "Audit",
            actionType: "SEARCHREASSIGN",
            reassignType: "UNASSIGN",
            originalUserId: originalSearch.assignedToUserId ?? undefined,
            message: reassignMessage
        };
        audits.push(unassignAudit);
    }

    if (updatedSearch.assignedToUserId != null && updatedSearch.assignedToUserId != originalSearch.assignedToUserId) {
        const assignAudit: SearchReassignAudit = {
            searchId: updatedSearch.searchId,
            searchVersionNumber: updatedSearch.version,
            performedByUserId: currentUserId,
            createdDate: new Date(),
            searchDocumentType: "Audit",
            actionType: "SEARCHREASSIGN",
            reassignType: "ASSIGN",
            originalUserId: originalSearch.assignedToUserId ?? undefined,
            newUserId: updatedSearch.assignedToUserId,
            message: reassignMessage
        };
        audits.push(assignAudit);
    }

    if (originalSearch.status !== SearchStatuses.Submitted && updatedSearch.status === SearchStatuses.Submitted) {
        const submitAudit: SearchSubmitAudit = {
            searchId: updatedSearch.searchId,
            searchVersionNumber: updatedSearch.version,
            performedByUserId: currentUserId,
            createdDate: new Date(),
            searchDocumentType: "Audit",
            actionType: "SEARCHSUBMIT"
        };
        audits.push(submitAudit);
    }

    if (originalSearch.status === SearchStatuses.Searching && updatedSearch.status === SearchStatuses.Submitted) {
        audits.push(generateFailedSearchAudit(originalSearch, updatedSearch, currentUserId));
    }

    return audits;
}

export function generatePerformSearchAudit(originalSearch: SearchVersionUnedited, updatedSearch: SearchVersionNew | SearchVersionEdited, currentUserId: string): SearchPerformAudit {
    if (
        (originalSearch.status === SearchStatuses.Draft || originalSearch.status === SearchStatuses.Submitted || originalSearch.status === SearchStatuses.Searching) &&
        updatedSearch.status === SearchStatuses.Searched
    ) {
        if (!updatedSearch.searchDate) {
            throw unexpectedError("searchDate was not found on the search while creating a SEARCHPERFORM audit.", "generateSaveAudits");
        } else if (originalSearch.searchDate?.toString() !== updatedSearch.searchDate.toString()) {
            throw unexpectedError("originalSearch.searchDate and updatedSearch.searchDate does not match. A search's searchDate should not change in between saves.", "generateSaveAudits");
        }

        const searchPerformAudit: SearchPerformAudit = {
            searchId: updatedSearch.searchId,
            searchVersionNumber: updatedSearch.version,
            performedByUserId: currentUserId,
            // This is a special case as perform search is a long running process and we want the createdDate for the audit to represent when
            // the user started the perform search process rather than when it was finished - which is when this piece runs.
            createdDate: updatedSearch.searchDate,
            searchDocumentType: "Audit",
            actionType: "SEARCHPERFORM"
        };
        return searchPerformAudit;
    } else {
        throw unexpectedError("Attempted to generate a performSearchAudit for a search that has not been performed", "generatePerformSearchAudit");
    }
}

export function generateFailedSearchAudit(originalSearch: SearchVersionUnedited, updatedSearch: SearchVersionNew | SearchVersionEdited, currentUserId: string): SearchErrorStatusChangeAudit {
    if (originalSearch.status === SearchStatuses.Searching && updatedSearch.status === SearchStatuses.Submitted) {
        const failedSearchAudit: SearchErrorStatusChangeAudit = {
            searchId: updatedSearch.searchId,
            searchVersionNumber: updatedSearch.version,
            performedByUserId: currentUserId,
            createdDate: new Date(),
            searchDocumentType: "Audit",
            actionType: "SEARCHERRORSTATUSCHANGE"
        };
        return failedSearchAudit;
    } else {
        throw unexpectedError("Attempted to generate a failed search audit for a search that has not failed", "generateFailedSearchAudit");
    }
}
