import { DiagnosticInformation, FirmEntity, Forbidden, FunctionAppStatus, IntegrationStatus, NotFound, RegionAndTenancies, ValidationErrors } from "aderant-conflicts-models";
import { OkResponse } from "../../..";
import { AzureKeyAuthFunctionDefinition } from "../../AzureFunctionDefinition";

//IMPORTANT:
//Whenever changes are made to this file, ensure that it does not break the service in Support Portal

export const Surface = {
    getHealth: new AzureKeyAuthFunctionDefinition<
        undefined, //Input
        DiagnosticInformation, //Output
        OkResponse<DiagnosticInformation>, //Output with Http Status type
        never //possible error types
    >({
        httpVerb: "GET",
        expectedErrors: [],
        getUrlEnd: () => "./api/Health"
    }),
    keyAuthFunctions: {
        getIngestionStatus: new AzureKeyAuthFunctionDefinition<FirmEntity, IntegrationStatus[], OkResponse<IntegrationStatus[]>, NotFound | ValidationErrors | Forbidden>({
            httpVerb: "POST",
            expectedErrors: ["NOT_FOUND", "VALIDATION", "ACCESS_DENIED"],
            getUrlEnd: () => `./api/KeyAuth/Monitoring/IngestionStatus`
        }),
        getFunctionAppStatuses: new AzureKeyAuthFunctionDefinition<FirmEntity, FunctionAppStatus[], OkResponse<FunctionAppStatus[]>, NotFound | ValidationErrors | Forbidden>({
            httpVerb: "POST",
            expectedErrors: ["NOT_FOUND", "VALIDATION", "ACCESS_DENIED"],
            getUrlEnd: () => `./api/MonitorApi/{region}/GetFunctionAppStatuses`
        }),
        getConflictsRegions: new AzureKeyAuthFunctionDefinition<undefined, { regions: RegionAndTenancies[] }, OkResponse<{ regions: RegionAndTenancies[] }>, NotFound | Forbidden>({
            httpVerb: "POST",
            expectedErrors: ["ACCESS_DENIED", "NOT_FOUND"],
            getUrlEnd: () => `./api/ConflictsRegions`
        })
    }
};
