import {
    ConflictsTenantBlobStorage,
    CurrentUserContext,
    DefaultEntityFlyoutConfigurationTemplates,
    EntityRelationshipConfigurationTemplate,
    EntityFlyoutConfigurationTemplate,
    EntityFlyoutConfigurationTemplates,
    EtagMismatch,
    ExpertEntityRelationshipConfigurationTemplates,
    NotFound,
    notFoundWithMessage,
    Result,
    JsonParseError,
    unexpectedError,
    LogContext
} from "aderant-conflicts-models";
import { BlobStorageConnector } from "../../BlobStorageConnector/BlobStorageConnector";
import { ConfigurationService } from "../ConfigurationService";

/**
 * This is a temporary class that returns a set of hardcoded templates based on Expert models/data structures
 */
export class EntityConfigurationService {
    private static EntityRelationshipConfigurationTemplates: EntityRelationshipConfigurationTemplate[] = ExpertEntityRelationshipConfigurationTemplates;
    private static ConfigurationCache: Record<string, EntityFlyoutConfigurationTemplates> = {}; //Object where property names are tenancy unique name, and property values are the EntityFlyoutConfigurationTemplates for the tenancy.
    private static ConfigurationCacheLastUpdated: Record<string, number> = {}; //Object where property names are tenancy unique name, and property values are DateTime the EntityFlyoutConfigurationCache was last updated for that tenancy.
    private static configService: ConfigurationService<EntityFlyoutConfigurationTemplate> = new ConfigurationService(
        {
            ConfigurationCache: EntityConfigurationService.ConfigurationCache,
            ConfigurationCacheLastUpdated: EntityConfigurationService.ConfigurationCacheLastUpdated
        },
        DefaultEntityFlyoutConfigurationTemplates
    );

    /**
     * Retrieves the entity relationship configuration template for the given configId
     * @param configId a string identifier that uniquely points to a configuration template
     */
    public async getEntityRelationshipConfigurationTemplate(configId: string): Promise<Result<EntityRelationshipConfigurationTemplate, NotFound>> {
        //This is currently set up to be an async function as I expect this to be an async call later on
        const configurationTemplate = EntityConfigurationService.EntityRelationshipConfigurationTemplates.find((tem: EntityRelationshipConfigurationTemplate) => tem.entityName === configId);
        if (configurationTemplate) {
            return configurationTemplate;
        } else {
            return notFoundWithMessage(`The entity relationship configuration template for the given entityType: ${configId} cannot be found.`);
        }
    }

    /**
     * Retrieves the entity relationship configuration templates for all the entities.
     */
    public async getEntityRelationshipConfigurationTemplates(): Promise<EntityRelationshipConfigurationTemplate[]> {
        //This is currently set up to be an async function as I expect this to be an async call later on
        return EntityConfigurationService.EntityRelationshipConfigurationTemplates;
    }

    /**
     * Retrieves the flyout configuration template for the given configId
     * @param tenancyName:  The unique tenancy name for the tenant.  This is a separate param because we can call this from a userless key auth function
     * @param configId a string identifier that uniquely points to a configuration template
     */
    public async getEntityFlyoutConfigurationTemplate(
        context: CurrentUserContext,
        tenancyName: string,
        blobConnector: BlobStorageConnector,
        configId: string
    ): Promise<Result<EntityFlyoutConfigurationTemplate, NotFound>> {
        const configurationTemplates = await this.getEntityFlyoutConfigurationTemplates(context, tenancyName, blobConnector);
        //This is currently set up to be an async function as I expect this to be an async call later on
        const configurationTemplate = configurationTemplates.configurations.find((tem: EntityFlyoutConfigurationTemplate) => tem.entityName === configId);
        if (configurationTemplate) {
            return configurationTemplate;
        } else {
            return notFoundWithMessage(`The flyout configuration template for the given entityType: ${configId} cannot be found.`);
        }
    }

    /**
     * Retrieves the flyout configuration templates for all the entity types.
     * @param context: the Current Log Context
     * @param tenancyName:  The unique tenancy name for the tenant.  This is a separate param because we can call this from a userless key auth function
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param cacheAge Optional - Default value is 60000 (10 minutes). Will fetch templates from cache if cache age is less than this, else will fetch from blob storage.
     */
    public async getEntityFlyoutConfigurationTemplates(context: LogContext, tenancyName: string, blobConnector: BlobStorageConnector, cacheAge?: number): Promise<EntityFlyoutConfigurationTemplates> {
        return EntityConfigurationService.configService.getConfiguration(
            context,
            tenancyName,
            blobConnector,
            ConflictsTenantBlobStorage.Configuration.entityFlyoutDefinition,
            ConflictsTenantBlobStorage.Configuration.containerName,
            cacheAge
        );
    }

    /**
     * Retrieves the flyout configuration templates for all the entity types from the given container.
     * @param context: the Current Log Context
     * @param tenancyName:  The unique tenancy name for the tenant.  This is a separate param because we can call this from a userless key auth function
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param containerName: the container to retrieve the configurations from. Optional. If not specified the default is the customization-wip container
     */
    public async getCustomEntityFlyoutConfigurationTemplates(
        context: LogContext,
        tenancyName: string,
        blobConnector: BlobStorageConnector,
        containerName: string = ConflictsTenantBlobStorage.CustomizationWip.containerName
    ): Promise<Result<EntityFlyoutConfigurationTemplates, NotFound | JsonParseError>> {
        const blobName: string = this.getEntityFlyoutBlobName(containerName);
        return EntityConfigurationService.configService.getCustomConfiguration(context, tenancyName, blobConnector, blobName, containerName);
    }

    /**
     * Updates the flyout configuration templates
     * @param context: the Current User Context
     * @param blobConnector: a connector to the blob storage account for the applicable tenant
     * @param entityFlyoutConfigurationTemplates an object containing { configurations: an array of EntityFlyoutConfigurationTemplate[] to be saved to blob storage, and an optional etag },
     * @param containerName: the name of the container to save to. Optional. If not specified, defaults to the customization-wip container.
     */
    public async updateEntityFlyoutConfigurationTemplates(
        context: CurrentUserContext,
        blobConnector: BlobStorageConnector,
        entityFlyoutConfigurationTemplates: EntityFlyoutConfigurationTemplates,
        containerName: string = ConflictsTenantBlobStorage.CustomizationWip.containerName
    ): Promise<Result<undefined, EtagMismatch>> {
        const blobName: string = this.getEntityFlyoutBlobName(containerName);
        return EntityConfigurationService.configService.updateConfiguration(context, blobConnector, entityFlyoutConfigurationTemplates, blobName, containerName);
    }

    private getEntityFlyoutBlobName(containerName: string): string {
        let blobName = "";
        if (containerName == ConflictsTenantBlobStorage.CustomizationWip.containerName) {
            blobName = ConflictsTenantBlobStorage.CustomizationWip.entityFlyoutDefinition;
        } else if (containerName == ConflictsTenantBlobStorage.Configuration.containerName) {
            blobName = ConflictsTenantBlobStorage.Configuration.entityFlyoutDefinition;
        }

        if (!blobName) {
            throw unexpectedError(`Blob name not found for container ${containerName}.`, "EntityConfigurationService.getEntityFlyoutBlobName");
        }

        return blobName;
    }
}
