import { ValidationErrors, wrapValidationErrors } from "../..";
import { validate as uuidValidate, NIL as nullUuid } from "uuid";
import { Messages } from "./Messages";

export function validateAssignedToUserId(assignedToUserId: string | null): ValidationErrors | string | null {
    let message;
    if (assignedToUserId === null) {
        return assignedToUserId;
    } else if (assignedToUserId.trim().length === 0) {
        message = Messages.VLD_EMPTY_STRING_VALUE;
    } else if (!uuidValidate(assignedToUserId)) {
        message = Messages.VLD_INVALID_GUID_VALUE;
    } else if (assignedToUserId === nullUuid) {
        message = Messages.VLD_EMPTY_GUID_VALUE;
    }

    if (message) {
        return wrapValidationErrors([message.asValidationMessage("AssignedToUserId")]);
    }

    return assignedToUserId;
}
