import { User } from "aderant-conflicts-models";
import { MultiSelectValue, PickList, PickListProps, SingleSelectValue } from "@aderant/aderant-react-components";
import { OmitForUnion } from "aderant-web-fw-core";
import { getUserSelectOptions, unassignedOption } from "components/UserPicklist/UserPicklist";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getAllUsers, getCurrentUser } from "state/selectors/appSelectors";

export type AssignedToUserPickListProps = OmitForUnion<PickListProps, "selectOptions" | "onChange" | "value"> & onUserChangeProps;

type onUserChangeProps =
    | {
          multiSelect: false;
          onUserChanged: (user?: User | null) => void;
          selectedUserId?: string | null;
          unassignedWhenEmpty: boolean;
      }
    | {
          multiSelect: true;
          onUserChanged: (users?: (string | null)[] | null) => void;
          selectedUserIds?: (string | null | undefined)[];
      };

AssignedToUserPickList.defaultProps = {
    multiSelect: false,
    unassignedWhenEmpty: true,
    disabled: false,
    required: false,
    labelProps: {
        width: "180px",
        position: "left"
    },
    hasError: false
};

export function AssignedToUserPickList(props: AssignedToUserPickListProps) {
    const users = useSelector(getAllUsers, shallowEqual);
    const currentUser = useSelector(getCurrentUser);

    const onChange = (event: React.ChangeEvent<HTMLElement>, value?: MultiSelectValue | SingleSelectValue | null): void => {
        if (!currentUser) {
            props.onUserChanged(undefined);
        } else if (Array.isArray(value) && props.multiSelect) {
            props.onUserChanged(value?.filter((o) => o).map((obj) => obj.value) ?? []);
        } else if (!props.multiSelect && users) {
            // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const singleSelectValue = value as SingleSelectValue;
            if (singleSelectValue === null || singleSelectValue.value === null) {
                props.onUserChanged(null);
            } else {
                // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                props.onUserChanged(users.find((u) => u.id == (value as SingleSelectValue)?.value ?? value));
            }
        }
    };

    const selectOptions = getUserSelectOptions(users || [], currentUser);

    if (props.multiSelect) {
        return <PickList {...props} value={selectOptions.filter((o) => props.selectedUserIds?.includes(o.value))} multiSelect onChange={onChange} selectOptions={selectOptions} />;
    } else {
        const value = selectOptions.find((o) => o.value === props.selectedUserId);
        return <PickList {...props} value={props.unassignedWhenEmpty ? value ?? unassignedOption : value ?? null} onChange={onChange} selectOptions={selectOptions} />;
    }
}
